/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { ApiRequest } from "../../../../../../ApiManager.tsx";
import PayrollEntry from "../../../Models/PayrollEntry.js";
import CustomButton from "../../../../../../components/CustomButton.js";
import { toast } from "react-toastify";

export default function RegeneratePayrollEntry({entry, payrollPeriod, handleReplacePayrollEntry, hideModal}) {
    const [isLoading, setIsLoading] = useState(false);

    function handleRegenerateEntry() {
        new ApiRequest('payroll', 'recalculateEntry', setIsLoading, (response) => {
            const payrollEntry = new PayrollEntry(response.payrollEntry);
            handleReplacePayrollEntry(payrollEntry);
            hideModal();
            toast.success(`Successfully regenerated entry for ${entry.firstName} ${entry.lastName}`);
        }).withNoAlertOnSuccess().withData({companyUserIdentifier: entry.companyUserIdentifier, payrollIdentifier: payrollPeriod.uid}).send()
    }


    return (
        <>
            <Modal.Header closeButton>
                <Modal.Title>{`Regenerate Entry for ${entry.firstName} ${entry.lastName}?`}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                Any preexisting data for this employee will be overriden with the data in the system.
            </Modal.Body>
            <Modal.Footer>
                <CustomButton isLoading={isLoading} onClick={handleRegenerateEntry}>Proceed</CustomButton>
            </Modal.Footer>
        </>
    )
}