/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React from "react";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import PayrollPeriodTable from "../PayrollPeriod/PayrollPeriodTable";
import { formatDateRange } from "../../../../tools";
import PayrollPeriodCsv from "../PayrollPeriod/PayrollPeriodCsv";
import PayrollPeriodPdf from "../PayrollPeriod/PayrollPeriodPdf";
import { useModalToggle } from "../../../../hooks.tsx";

export default function PayrollPeriodPreview({entries, payrollPeriod}) {
    const [modalIsVisible, showModal, hideModal] = useModalToggle();


    function downloadCsv() {
        const str = PayrollPeriodCsv(entries, payrollPeriod);
        const blob = new Blob([str], { type: 'text/csv;charset=utf-8,' });
        const elem = window.document.createElement('a');
        elem.href = window.URL.createObjectURL(blob);
        elem.download = payrollPeriod.companyName + ' ' + formatDateRange(payrollPeriod.periodStart, payrollPeriod.periodEnd) + ' Payroll.csv';
        document.body.appendChild(elem);
        elem.click();
        document.body.removeChild(elem);
    }

    return (
        <>
            <Modal.Header closeButton>
                <Modal.Title>
                    {`Payroll for ${payrollPeriod.companyName} Between ${formatDateRange(payrollPeriod.periodStart, payrollPeriod.periodEnd)}`}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <PayrollPeriodTable entries={entries}/> 
            </Modal.Body>
            <Modal.Footer>
                <Button variant="outline-primary" onClick={downloadCsv}>Download Spreadsheet</Button>
                <Button variant="outline-primary" onClick={showModal}>Download PDF</Button>
            </Modal.Footer>
            <Modal show={modalIsVisible} onHide={hideModal} fullscreen>
                <PayrollPeriodPdf entries={entries} payrollPeriod={payrollPeriod}/>
            </Modal>
        </>
    )
}