/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React from 'react';
import { faUser } from '@fortawesome/free-regular-svg-icons';
import { faRightFromBracket, faBars } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import logo from '../../assets/TTA-Logo-150.png';
import CompanySelector from '../CompanySelector';
import { Dropdown, InputGroup} from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import NavigationBarDropdownItem from './NavigationBarDropdownItem.js';

export default function NavigationBarMobile({auth, userData, companies, setShowLogoutModal, handleStopShadowing, routes}) {
    const location = useLocation();

    const currentRoute = routes.find(r => r.path == location.pathname);

    const mobileNavBarCompanySections = ['ISP Management', 'Operations', 'Finance'].filter(c => routes.some(r => r.category == c)).map((category, index) => {
        const buttons = routes.filter(r => r.category == category).map((route) => {
            
            return (
                <NavigationBarDropdownItem key={route.name} route={route}/>
            )
        })
        return (
            <React.Fragment key={category}>
                { index > 0 && 
                    <Dropdown.Divider/>
                }
                <Dropdown.Header>{category}</Dropdown.Header>
                {buttons}
            </React.Fragment>
        )
    })

    const accountButtons = routes.filter(r => r.category == 'Account').map((route) => {
        return (
            <NavigationBarDropdownItem key={route.name} route={route}/>
        )
    })

    return (
        <div className='navbar-top navbar-top-mobile'>
            <img src={logo} alt={'TTA LOGO'} id='logo' style={{height: '100%', width: 'auto', margin: 0}}/>
            <InputGroup style={{flex: 0, flexWrap: 'nowrap'}}>
                <Dropdown>
                    <Dropdown.Toggle variant='outline-primary'>
                        <FontAwesomeIcon icon={faBars}/>
                    </Dropdown.Toggle>
                    <Dropdown.Menu style={{whiteSpace: 'nowrap', minWidth: 200}}>
                        {mobileNavBarCompanySections}
                    </Dropdown.Menu>
                </Dropdown>
                <Dropdown>
                    <Dropdown.Toggle variant='outline-primary'>
                        <FontAwesomeIcon icon={faUser}/>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        <Dropdown.ItemText style={{color: 'var(--bs-primary)', textAlign: 'center', fontSize: 15, fontWeight: 'bold'}}>{`${userData.firstName} ${userData.lastName}`}</Dropdown.ItemText>
                        { auth.companyUserIdentifier &&
                            <Dropdown.ItemText style={{fontSize: 12, display: 'flex', alignItems: 'center', gap: 4, color: 'var(--bs-primary)'}}>
                                <CompanySelector companies={companies}/>
                            </Dropdown.ItemText>
                        }
                        <Dropdown.Divider/>
                        { accountButtons.length > 0 && 
                            <>
                                {accountButtons}
                                <Dropdown.Divider/>
                            </>
                        }
                        <Dropdown.Item onClick={()=>{setShowLogoutModal(true)}} style={{display: 'flex', alignItems: 'center', gap: 6, color: 'red'}}>
                            <span>Logout</span>
                            <FontAwesomeIcon icon={faRightFromBracket}/>
                        </Dropdown.Item>
                        { auth.adminIdentifier && auth.companyUserIdentifier &&
                            <Dropdown.Item onClick={handleStopShadowing} style={{display: 'flex', alignItems: 'center', gap: 6, color: 'red'}}>
                                <span>Stop Shadowing</span>
                                <FontAwesomeIcon icon={faRightFromBracket}/>
                            </Dropdown.Item>
                        }
                    </Dropdown.Menu>
                </Dropdown>
            </InputGroup>
        </div>
    )
}