/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Card, Col, Container, ListGroup, Modal, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import LoadingWrapper from '../../../components/LoadingWrapper.js';
import { OnboardingDocumentsReadOnly } from '../../../tools.js';
import { ApiRequest } from '../../../ApiManager.tsx';
import KeyValueRow from '../../../components/KeyValueRow.js';
import { useStateWithModal } from '../../../hooks.tsx';
import PdfModal from './PdfModal.js';

export default function MyDocuments() {
    const [isLoading, setIsLoading] = useState(false);
    const [companyDocuments, setCompanyDocuments] = useState([]);
    const [edDocumentRequests, setEdDocumentRequests] = useState([]);
    const [selectedCompanyDocument, setSelectedCompanyDocument, showModal, dismissModal] = useStateWithModal(undefined);

    useEffect(() => {
        loadData();
    }, [])
    
    function loadData(){
        new ApiRequest('myDocuments', 'getAll', setIsLoading, (response) => {
            setCompanyDocuments(response.acknowledgedDocuments);
            setEdDocumentRequests(response.documentRequests);
        }).withNoAlertOnSuccess().send();
    }

    const employeeUncompletedOnboardingDocumentElements = edDocumentRequests.filter(eDoc=> Object.values(!eDoc.documents).reduce((acc, el)=>{
        if(!el.employeeCompleted){
            acc = false;
        }
        return acc;
    }, true)).map(eDoc=>{
        return (
            <ListGroup.Item key={eDoc.uid} onClick={() => window.location.replace(`https://mytacticalrecruiting.com/employee-onboarding/${eDoc.employeeToken}`)} action>
                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: 8, flexWrap: 'wrap', gap: 4}}>
                    <h6 style={{margin: 0, marginRight: 8}}>{moment(eDoc.dateCreated).format('MMMM D, YYYY')}</h6>
                    <span style={{color: 'var(--bs-danger'}}>Requires Completion <FontAwesomeIcon icon={faTriangleExclamation} color='var(--bs-danger)'/></span>
                </div>
                <span style={{fontSize: 14,opacity: 0.5}}>
                    {Object.entries(eDoc.documents).map(([key, value])=>{
                        return OnboardingDocumentsReadOnly[key]
                    }).join(', ')}
                </span>
            </ListGroup.Item>
        )
    })

    const employeeCompletedOnboardingDocumentElements = edDocumentRequests.filter(eDoc=> Object.values(eDoc.documents).reduce((acc, el)=>{
        if(!el.employeeCompleted){
            acc = false;
        }
        return acc;
    }, true)).map(eDoc=>{
        return (
            <ListGroup.Item key={eDoc.uid} onClick={() => window.location.replace(`https://mytacticalrecruiting.com/employee-onboarding/${eDoc.employeeToken}`)} action>
                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: 8, flexWrap: 'wrap', gap: 4}}>
                    <h6 style={{margin: 0, marginRight: 8}}>{moment(eDoc.dateCreated).format('MMMM D, YYYY')}</h6>
                    <span style={{color: 'var(--bs-success'}}>Finished <FontAwesomeIcon icon={faCheck} color='var(--bs-success)'/></span>
                </div>
                <span style={{fontSize: 14, opacity: 0.5}}>
                    {Object.entries(eDoc.documents).map(([key, value])=>{
                        return OnboardingDocumentsReadOnly[key]
                    }).join(', ')}
                </span>
            </ListGroup.Item>
        )
    })

    const companyDocumentElements = companyDocuments.map((cDoc) => {
        return (
            <CompanyDocumentElement  key={cDoc.fileIdentifier} cDoc={cDoc} setSelectedCompanyDocument={setSelectedCompanyDocument}/>
        )
    })


    return (
        <LoadingWrapper isLoading={isLoading}>
            <div style={{padding: 16, gap: 12, width: '100%', display: 'flex', flexDirection: 'column', gap: 12}}>
                <Card style={{flex: 1}}>
                    <Card.Body style={{display: 'flex', flexDirection: 'column', height: '100%'}}>
                        <Card.Title style={{marginBottom: 12}}>Employee Onboarding Document Requests</Card.Title>
                        { employeeUncompletedOnboardingDocumentElements.length > 0 || employeeCompletedOnboardingDocumentElements.length > 0 ?
                            <div style={{flex: 1, overflowY: 'auto'}}>
                                <ListGroup>
                                    {employeeUncompletedOnboardingDocumentElements}
                                    {employeeCompletedOnboardingDocumentElements}
                                </ListGroup>
                            </div>
                        :    
                            <div style={{height: '100%', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: 24, opacity: 0.5, fontWeight: 'bold'}}>None</div>
                        }
                    </Card.Body>
                </Card>
                <Card style={{flex: 1, overflowY: 'hidden'}}>
                    <Card.Body style={{display: 'flex', flexDirection: 'column', height: '100%'}}>
                        <Card.Title style={{marginBottom: 12}}>Acknowledged Company Documents</Card.Title>
                        { companyDocumentElements.length > 0 ?
                            <div style={{flex: 1, overflowY: 'auto'}}>
                                <ListGroup>{companyDocumentElements}</ListGroup>
                            </div>
                        :
                            <div style={{height: '100%', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: 24, opacity: 0.5, fontWeight: 'bold'}}>None</div>
                        }
                    </Card.Body>
                </Card>
                <Modal show={showModal} onHide={dismissModal} fullscreen>
                    <PdfModal base64={selectedCompanyDocument?.base64} name={selectedCompanyDocument?.name}/>
                </Modal>
            </div>
        </LoadingWrapper>
    )
}

function CompanyDocumentElement({cDoc, setSelectedCompanyDocument}) {
    const [isLoading, setIsLoading] = useState(false);

    function handleDownloadCompanyDocument() {
        if (cDoc.base64) {
            setSelectedCompanyDocument(cDoc);
        } else {
            new ApiRequest('myDocuments', 'downloadAcknowledgedDocument', setIsLoading, (response) => {
                cDoc.base64 = response.base64;
                setSelectedCompanyDocument(cDoc);
            }).withData({fileIdentifier: cDoc.fileIdentifier}).withNoAlertOnSuccess().send();
        }
    }

    return (
        <LoadingWrapper isLoading={isLoading} height={50}>
            <KeyValueRow title={cDoc.name} value={moment(cDoc.dateApproved).format('[Acknowledged on] MMM D, YYYY [at] h:mm A')} isListItem action onClick={handleDownloadCompanyDocument}/>
        </LoadingWrapper>
    )
}