/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import { useEffect, useMemo, useReducer, useState } from "react";
import { useLocation } from "react-router-dom";

export function useStateWithModal(initialState: any) {
    const [value, setValue] = useState(initialState);
    const [showModal, setShowModal] = useState(initialState ? true : false);

    function handleSetValue(newValue: any) {
        setValue(newValue);
        if (newValue) {
            setShowModal(true);
        }
    }

    function dismissModal() {
        setShowModal(false);
    }

    return [value, handleSetValue, showModal, dismissModal];
}

export function useModalToggle() {
    const [isVisible, setIsVisible] = useState(false);

    function show() {
        setIsVisible(true);
    }

    function hide() {
        setIsVisible(false);
    }

    return [isVisible, show, hide];
}

export function useModalSwitch() {
    const [modalSwitch, setModalSwitch] = useState('');

    function hide() {
        setModalSwitch('');
    }

    return [modalSwitch, setModalSwitch, hide];
}

export function useLoadData(api: () => void) {

    useEffect(() => {
        api();
    }, []);
}

export function useStateObjectWithValidation(initialState: any, keysAndValidation: object) {
    const [value, setValue] = useState(initialState);

    const validationMessages = useMemo(() => {
        const vMessages = {}
        Object.entries(keysAndValidation).forEach(([key, validationFunc]) => {
            vMessages[key] = validationFunc(value[key]);
        })
        return vMessages;
    }, [value, keysAndValidation])

    const isValid = useMemo(() => {
        return Object.entries(keysAndValidation).reduce((prev, [key, validationFunc]) => {
            if(Array.isArray(value[key])){
                return prev && validationFunc(value[key]).reduce((acc, el) => {
                    return acc && el == undefined;
                }, true)
            }
            return prev && (validationFunc(value[key]) == undefined)
        }, true)
    }, [value, keysAndValidation])

    function handleSetKeyValue(key: string, newValue: any) {
        setValue((prevState: any) => {
            if (value.duplicate) {
                const newObject = prevState.duplicate();
                newObject[key] = newValue;
                return newObject;
            } else {
                const newObject = structuredClone(prevState);
                newObject[key] = newValue;
                return newObject;
            }
        });
    }

    return [value, setValue, handleSetKeyValue, validationMessages, isValid];
}


export function useStateObjectArray(initialState: object[], idKey: string) {
    const [value, setValue] = useState(initialState);

    function handleSetKeyValue(id: any, key: string, newValue: any) {
        setValue((prevState) => {
            const newArray = Array.from(prevState);
            const obj = newArray.find(e => e[idKey] == id);
            if (obj) {
                if (obj[key] != newValue) {
                    obj[key] = newValue;
                    return newArray;
                }
            }
            return prevState;
        });
    }

	return [value, setValue, handleSetKeyValue];
}

export function useIdentifiableStateArray(initialState: any[], idKey: string) {
    const [value, dispatch] = useReducer(reducer, initialState);

    function reducer(state: any[], action: any) {
        if (action.type == 'set') {
            return action.value;
        } else if (action.type == 'push') {
            const newArray = Array.from(state);
            newArray.push(action.value);
            return newArray;
        } else if (action.type == 'remove') {
            return state.filter(e => e[idKey] != action.uid);
        } else if (action.type == 'replace') {
            const newArr = Array.from(state);
            const index = newArr.findIndex(e => e[idKey] == action.uid);
            if (index >= 0) {
                newArr.splice(index, 1, action.value);
                return newArr;
            }
            throw Error('invalid id for useIdentifiableStateArray remove action');
        } else if (action.type == 'unshift') {
            const newArray = Array.from(state);
            newArray.unshift(action.value);
            return newArray;
        } else if (action.type == 'sort') {
            const newArray = Array.from(state);
            newArray.sort(action.fn);
            return newArray;
        } else {
            throw Error('Unknown action for useIdentifiableStateArray');
        }
    }

    return [value, dispatch]
}

export function useStateArray(initialState: any[]) {
    const [value, setValue] = useState(initialState);

    function push(element: any) {
        setValue((prevState) => {
            const newArray = Array.from(prevState);
            newArray.push(element);
            return newArray;
        })
    }

    function remove(index: number) {
        setValue((prevState) => {
            const newArray = Array.from(prevState);
            newArray.splice(index, 1);
            return newArray;
        })
    }

    function replace(index: number, element: any) {
        setValue((prevState) => {
            const newArray = Array.from(prevState);
            newArray.splice(index, 1, element);
            return newArray;
        })
    }

    function unshift(element: any) {
        setValue((prevState) => {
            const newArray = Array.from(prevState);
            newArray.unshift(element);
            return newArray;
        })
    }

    function sort(fn: (a: any, b: any) => number) {
        setValue((prevState) => {
            const newArray = Array.from(prevState);
            newArray.sort(fn);
            return newArray;
        })
    }

    return [value, setValue, {push: push, unshift: unshift, sort: sort, replace: replace, remove: remove}]
}

export function useStateToggleArray(initialState: any[]) {
    const [value, setValue] = useState(initialState);

    function toggle(element: any) {
        setValue((prevState) => {
            const newArray = Array.from(prevState);
            if (newArray.includes(element)) {
                return newArray.filter(e => e != element);
            } else {
                newArray.push(element);
                return newArray;
            }
        })
    }

    return [value, setValue, toggle]
}


export function useToggle(initialState: boolean) {
    const [value, setValue] = useState(initialState);

    function toggle() {
        setValue(!value);
    }

    return [value, toggle];
}

export function useCloseWarning() {
    useEffect(() => {
        window.addEventListener("beforeunload", handleBeforeUnload);
        return () => {
            window.removeEventListener("beforeunload", handleBeforeUnload);
        }
    }, [])

    function handleBeforeUnload(event: any) {
        event.preventDefault();
        return (event.returnValue = "");
    }
}


export function useQuery() {
    const { search } = useLocation();

    return useMemo(() => new URLSearchParams(search), [search]);
}