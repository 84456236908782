/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import { faChevronCircleDown, faChevronCircleUp, faCircleArrowDown, faCircleArrowUp, faLock, faTrashCan, faTriangleExclamation, faUnlock } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dropdown, Form, ListGroup, ListGroupItem, OverlayTrigger, Popover } from "react-bootstrap";
import { useDrag } from "react-dnd";
import { convertTime, intToTime, stringTimeToInt } from "../../../../tools.js";
import './DraggableComponents.css'
import RouteStop from "../Models/RouteStop.js";
import {useCallback, useEffect, useRef, useState} from 'react'
import { height, width } from "@fortawesome/free-regular-svg-icons/faAddressBook";
import Client from "../../MyCompany/Models/Client.js";
import Terminal from "../../MyCompany/Models/Terminal.js";

export function DraggableStopRow({stop, setIsDraggingClientOrTerminal, index, handleSetRouteStop, allStops, conflicts}){
    const [ref, dimensions, setDimensions] = useElementDimensions()
    const [{isDragging}, drag] = useDrag(() => ({
        type: 'stop',
        item: () => {
            if(allStops.length > 7){
                return;
            }
            setTimeout(() => setIsDraggingClientOrTerminal(true), 15); 
            if (ref.current) { // Ensure dimensions are updated during drag
                const { width, height } = ref.current.getBoundingClientRect();
                setDimensions({ width, height });
                return { stop, index, dimensions: { width, height }, content: getContent() };
            }
            return { stop, dimensions: { width: dimensions.width, height: dimensions.height }, content: getContent() };
        },
        end: () => setIsDraggingClientOrTerminal(false),
        collect: (monitor) => ({ 
            isDragging: monitor.isDragging()
        })
    }))

    const [{isDraggingMobile}, dragMobile] = useDrag(() => ({
        type: 'stop',
        item: () => {
            if(allStops.length > 7){
                return;
            }
            setTimeout(() => setIsDraggingClientOrTerminal(true), 15); 
            if (ref.current) { // Ensure dimensions are updated during drag
                const { width, height } = ref.current.getBoundingClientRect();
                setDimensions({ width, height });
                return { stop, index, dimensions: { width, height }, content: getContent() };
            }
            return { stop, dimensions: { width: dimensions.width, height: dimensions.height }, content: getContent() };
        },
        end: () => setIsDraggingClientOrTerminal(false),
        collect: (monitor) => ({ 
            isDraggingMobile: monitor.isDragging()
        })
    }))
 
    useDisableTextSelection(isDragging)

    ///////////////////
    /// CONTENT
    ///////////////////
    let start = (index !== 0 && allStops[index-1].endTime) ? Math.ceil(stringTimeToInt(allStops[index-1].endTime)/15)*15 : 0;
    const conflictSeverity = conflicts.stops[index].reduce((accumulator, currentValue) => Math.max(accumulator, currentValue.severity), 0);
    const conflictListItems = conflicts.stops[index].map((element, index) => {
        return (
            <div key={index} style={{display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 8}}>
                <FontAwesomeIcon icon={faTriangleExclamation} style={{color: element.severity === 0 ? 'lightgreen' : element.severity === 1 ? 'yellow' : element.severity === 2 ? 'orange' : 'red'}}/>
                <p style={{margin: 0,}}>{element.message}</p>
            </div>
        )
    });

    const popover = (
        <Popover style={{position:'fixed'}}>
            <Popover.Header></Popover.Header>
            <Popover.Body>
                {conflictListItems?.length > 0 ? conflictListItems : <></>}
            </Popover.Body>
        </Popover>
    )

    const getContent = () => (
        <div ref={ref}>
            <div className="desktop-route-card-draggable-row draggable-stop-row" style={{alignItems: 'center', cursor: 'pointer', opacity: isDragging ? .2 : 1}}>
                <div style={{display: 'flex', alignItems: 'center', border: '1px solid gray', flex: 14, overflow: 'hidden', height: 50, paddingLeft: 8}}>
                    <div style={{textOverflow: 'ellipsis', overflow: "hidden", whiteSpace: 'nowrap'}}>
                        {!conflictSeverity ? <></> :
                            <OverlayTrigger placement='top' overlay={popover}>
                                <FontAwesomeIcon icon={faTriangleExclamation} style={{color: conflictSeverity === 1 ? 'gold' : conflictSeverity === 2 ? 'orange' : 'red', marginRight: 8}}/>
                            </OverlayTrigger>
                        }
                        <span>{stop.name}</span>
                    </div>
                </div>
                <CustomRouteTimePicker value={stop.startTime} setValue={(value) => {handleSetRouteStop(null, 'startTime', value, index); handleSetRouteStop(null, 'startTimeIsLocked', true, index)}} start={start} step={15} style={{height: 50, display: 'flex', justifyContent: 'center', alignItems: 'center', border: '1px solid gray', height: 50, borderRadius: 0}}/>
                <Form.Check checked={stop.startTimeIsLocked} onChange={() => handleSetRouteStop(null, 'startTimeIsLocked', !stop.startTimeIsLocked, index)} style={{border: '1px solid gray', height: 50, display: 'flex', alignItems: 'center', justifyContent: 'center', width: 30}}/>
                <CustomRouteTimePicker value={stop.endTime} setValue={(value) => {handleSetRouteStop(null, 'endTime', value, index); handleSetRouteStop(null, 'endTimeIsLocked', true, index)}} start={stop.startTime ? stringTimeToInt(stop.startTime) : null}  step={15} disabled={!stop.startTime} style={{height: 50, display: 'flex', justifyContent: 'center', alignItems: 'center', flex: 4, border: '1px solid gray', height: 50, borderRadius: 0}}/>
                <Form.Check checked={stop.endTimeIsLocked} onChange={() => handleSetRouteStop(null, 'endTimeIsLocked', !stop.endTimeIsLocked, index)} style={{border: '1px solid gray', height: 50, display: 'flex', alignItems: 'center', justifyContent: 'center', width: 30}}/>
                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', width: 30, border: '1px solid gray', height: 50}}><FontAwesomeIcon onClick={() => handleSetRouteStop('stopRemove', null, null, index)} color="red" icon={faTrashCan}/> </div>
            </div>
            <div className="mobile-route-card-draggable-row"  ref={dragMobile} style={{cursor: 'pointer', border: '1px solid gray', opacity: isDraggingMobile ? .2 : 1}}>
                <div style={{borderRight: '1px solid gray', width: 30, display: 'flex', flexDirection: 'column', justifyContent: 'space-around', alignItems: 'center', backgroundColor: 'rgb(240, 240, 240)'}}>
                    {index !== 0 ? <FontAwesomeIcon onClick={() => {handleSetRouteStop('stop', null, stop, index-1, index)}} style={{width: 22, height: 22}} color='var(--bs-primary)' icon={faChevronCircleUp}/> : <div></div>}
                    <span style={{fontSize: 22, fontWeight: 'bolder'}}>{index+1}</span>
                    {index !== allStops.length-1 ? <FontAwesomeIcon style={{width: 22, height: 22}} onClick={() => {handleSetRouteStop('stop', null, stop, index+1, index)}} color='var(--bs-primary)' icon={faChevronCircleDown}/> : <div></div>}
                </div>
                <ListGroup style={{display: 'flex', flexDirection: 'column', flex: 1}}>
                    <ListGroup.Item style={{fontSize: 16, display: 'flex', justifyContent: 'space-between'}}>
                        <b>{stop.name}</b>
                        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', width: 25, height: 25}}><FontAwesomeIcon onClick={() => handleSetRouteStop('stopRemove', null, null, index)} color="red" icon={faTrashCan}/> </div>
                    </ListGroup.Item>
                    <ListGroup.Item style={{display: 'flex', flexDirection: 'column'}}>
                        <div style={{display: 'flex', alignItems: 'center', gap: 12}}>
                            <b style={{textAlign: 'left', width: 64, fontSize: 14}}>Time In</b>
                            <CustomRouteTimePicker value={stop.startTime} setValue={(value) => handleSetRouteStop(null, 'startTime', value, index)} start={start} step={15} style={{fontSize: 14, height: 40, display: 'flex', justifyContent: 'center', alignItems: 'center', border: '1px solid lightgray', height: 50, borderRadius: 6}}/>
                            <div style={{display: 'flex', alignItems: 'center', gap: 6}}>
                                <FontAwesomeIcon onClick={() => handleSetRouteStop(null, 'startTimeIsLocked', !stop.startTimeIsLocked, index)} color={stop.startTimeIsLocked ? 'var(--bs-primary)' : 'lightgray'} icon={stop.startTimeIsLocked ? faLock : faUnlock}/>
                            </div>
                        </div>
                    </ListGroup.Item>
                    <ListGroup.Item style={{display: 'flex', flexDirection: 'column'}}>
                        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 12}}>
                            <b style={{textAlign: 'left', width: 64, fontSize: 14}}>Time Out</b>
                            <CustomRouteTimePicker value={stop.endTime} setValue={(value) => handleSetRouteStop(null, 'endTime', value, index)} start={stop.startTime ? stringTimeToInt(stop.startTime) : null}  step={15} disabled={!stop.startTime} style={{fontSize: 14, height: 40, borderRadius: 6, display: 'flex', justifyContent: 'center', alignItems: 'center', flex: 4, border: '1px solid lightgray', height: 50}}/>
                            <div style={{display: 'flex', alignItems: 'center', gap: 6}}>
                                <FontAwesomeIcon onClick={() =>  handleSetRouteStop(null, 'endTimeIsLocked', !stop.endTimeIsLocked, index)} color={stop.endTimeIsLocked ? 'var(--bs-primary)' : 'lightgray'} icon={stop.endTimeIsLocked ? faLock : faUnlock}/>
                            </div>
                        </div> 
                    </ListGroup.Item>
                </ListGroup>
            </div>
        </div>
    )

    return (
        <div ref={(node) => {
            ref.current = node; // Assign ref to keep track of the element
            drag(node); // Connect the node to the drag source
        }}>
            {getContent()}
        </div>
    );
}

function CustomRouteTimePicker({style, start, step, value, setValue, disabled}){

    let count = start + 0;
    const arrayTimes = [];
    for(let i = count; i < 1440; i += step){
        arrayTimes.push(intToTime(i));
    }
 
    return (
        <>
            <Dropdown style={{flex: 6, width: '100%'}}>
                <Dropdown.Toggle variant="" disabled={disabled} bsPrefix="" style={{...style, width: '100%'}}>
                    <span style={{opacity: disabled ? .3 : 1}}>{value ? convertTime(value) : 'Select Time'}</span>
                </Dropdown.Toggle>
                <Dropdown.Menu style={{maxHeight: 300, overflow: 'auto', width: '100%'}}>
                    {arrayTimes.map(t => {
                        return <Dropdown.Item key={t} onClick={() => setValue(t)}>{convertTime(t)}</Dropdown.Item>
                    })}
                </Dropdown.Menu>
            </Dropdown>
        </>
    )
}

export function DraggableVehicle({vehicle, isMobile, handleSetRoute}){
    const [ref, dimensions, setDimensions] = useElementDimensions()
    const [{isDragging}, drag] = useDrag(() => ({
        type: 'vehicle',
        item: () => {
            if (ref.current) { // Ensure dimensions are updated during drag
                const { width, height } = ref.current.getBoundingClientRect();
                setDimensions({ width, height });
                return { ...vehicle, dimensions: { width, height }, content: getContent() };
            }
            return { ...vehicle, dimensions: { width: dimensions.width, height: dimensions.height }, content: getContent() };
        },
        collect: (monitor) => ({
            isDragging: monitor.isDragging()
        })
    }))

    
    useDisableTextSelection(isDragging)

    ///////////////////
    /// CONTENT
    ///////////////////
    const conflictSeverity = vehicle.conflicts.reduce((accumulator, currentValue) => Math.max(accumulator, currentValue.severity), 0);
    const conflictListItems = vehicle.conflicts.map((element, index) => {
        return (
            <div key={index} style={{display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 8}}>
                <FontAwesomeIcon icon={faTriangleExclamation} style={{color: element.severity === 0 ? 'lightgreen' : element.severity === 1 ? 'yellow' : element.severity === 2 ? 'orange' : 'red'}}/>
                <p style={{margin: 0,}}>{element.message}</p>
            </div>
        )
    });

    const popover = (
        <Popover style={{position:'fixed'}}>
            <Popover.Header></Popover.Header>
            <Popover.Body>
                {conflictListItems?.length > 0 ? conflictListItems : <></>}
            </Popover.Body>
        </Popover>
    )

    const getContent = () => (
        <div ref={ref} className="draggable-item" onClick={isMobile ? () => {handleSetRoute('vehicle', vehicle)} : undefined} style={{fontWeight: 'bold', cursor: 'pointer', padding: 8, display: 'flex', minWidth: 260, justifyContent: 'space-between', alignItems: 'center', border: '2px solid var(--bs-primary)', borderRadius: 6, fontSize: 14, gap: 8}}>
            <span>{vehicle.name}</span>
            {conflictListItems.length === 0 ? <></> :
                <OverlayTrigger placement='top' overlay={popover}>
                    <FontAwesomeIcon icon={faTriangleExclamation} style={{color: conflictSeverity === 1 ? 'gold' : conflictSeverity === 2 ? 'orange' : 'red'}}/>
                </OverlayTrigger>
            }
        </div>
    )
    
    return (
        <div ref={(node) => {
            ref.current = node; // Assign ref to keep track of the element
            drag(node); // Connect the node to the drag source
        }}>
            {getContent()}
        </div>
    );
}

export function DraggableUser({ user, isMobile, handleSetRoute }) {
    const [ref, dimensions, setDimensions] = useElementDimensions()
    const [{ isDragging }, drag] = useDrag(() => ({
        type: 'user',
        item: () => {
            if (ref.current) {
                const { width, height } = ref.current.getBoundingClientRect();
                setDimensions({ width, height });
                return { ...user, dimensions: { width, height }, content: getContent() };
            }
            return { ...user, dimensions: { width: dimensions.width, height: dimensions.height }, content: getContent() };
        },
        collect: (monitor) => ({
            isDragging: monitor.isDragging()
        })
    }), [dimensions, user]);

    useDisableTextSelection(isDragging)

    ///////////////////
    /// CONTENT
    ///////////////////
    const getContent = () => (
        <div ref={ref} className="draggable-item" onClick={isMobile ? () => { handleSetRoute('user', user) } : undefined} style={{ fontWeight: 'bold', cursor: 'pointer', padding: 8, display: 'flex', justifyContent: 'space-between', alignItems: 'center', border: '2px solid var(--bs-primary)', gap: 8, borderRadius: 6, fontSize: 13 }}>
            <div>
                <p style={{ margin: 0 }}>{`${user.firstName} ${user.middleName ? `${user.middleName} ` : ''}${user.lastName}`}</p>
                <p style={{ margin: 0, opacity: .5 }}>{user.title}</p>
            </div>
            {user.conflicts.length > 0 &&
                <OverlayTrigger placement='top' overlay={
                    <Popover style={{ position: 'fixed' }}>
                        <Popover.Header></Popover.Header>
                        <Popover.Body>
                            {user.conflicts.map((element, index) => (
                                <div key={index} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 8 }}>
                                    <FontAwesomeIcon icon={faTriangleExclamation} style={{ color: element.severity === 0 ? 'lightgreen' : element.severity === 1 ? 'yellow' : element.severity === 2 ? 'orange' : 'red' }} />
                                    <p style={{ margin: 0 }}>{element.message}</p>
                                </div>
                            ))}
                        </Popover.Body>
                    </Popover>
                }>
                    <FontAwesomeIcon icon={faTriangleExclamation} style={{ color: user.conflicts.reduce((max, conflict) => Math.max(max, conflict.severity), 0) === 1 ? 'gold' : user.conflicts.reduce((max, conflict) => Math.max(max, conflict.severity), 0) === 2 ? 'orange' : 'red' }} />
                </OverlayTrigger>
            }
        </div>
    );

    return (
        <div ref={(node) => {
            ref.current = node; // Assign ref to keep track of the element
            drag(node); // Connect the node to the drag source
        }}>
            {getContent()}
        </div>
    );
}

export function DraggableTerminal({terminal, setIsDraggingClientOrTerminal, isMobile, handleSetRouteStop, stops}){
    const [ref, dimensions, setDimensions] = useElementDimensions()
    const [{ isDragging }, drag] = useDrag(() => ({
        type: 'stop',
        item: () => {
            setIsDraggingClientOrTerminal(true);
            if (ref.current) { // Ensure dimensions are updated during drag
                const { width, height } = ref.current.getBoundingClientRect();
                setDimensions({ width, height });
                return { stop: terminal, dimensions: { width, height }, content: getContent() };
            }
            return { stop: terminal, dimensions: { width: dimensions.width, height: dimensions.height }, content: getContent() };
        },
        end: () => setIsDraggingClientOrTerminal(false),
        collect: (monitor) => ({
            isDragging: monitor.isDragging()
        })
    }));

    useDisableTextSelection(isDragging)

    ///////////////////
    /// CONTENT
    ///////////////////
    const getContent = () => (
        <div ref={ref} className="draggable-item" onClick={isMobile && stops.length < 8 ? () => {handleSetRouteStop('stop', null, RouteStop.initFromRouteOrTerminal(terminal), -1)} : undefined} style={{fontWeight: 'bold', cursor: 'pointer', padding: 8, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', border: '2px solid var(--bs-primary)', minWidth: 260, borderRadius: 6, fontSize: 14}}>
            {terminal.name}
        </div>
    )
    
    return (
        <div ref={(node) => {
            ref.current = node; // Assign ref to keep track of the element
            drag(node); // Connect the node to the drag source
        }}>
            {getContent()}
        </div>
    );
}


export function DraggableClient({ client, setIsDraggingClientOrTerminal, isMobile, handleSetRouteStop, stops}) {
    const [ref, dimensions, setDimensions] = useElementDimensions()
    const [{ isDragging }, drag] = useDrag(() => ({
        type: 'stop',
        item: () => {
            setIsDraggingClientOrTerminal(true);
            if (ref.current) { // Ensure dimensions are updated during drag
                const { width, height } = ref.current.getBoundingClientRect();
                setDimensions({ width, height });
                return { stop: client, dimensions: { width, height }, content: getContent() };
            }
            return { stop: client, dimensions: { width: dimensions.width, height: dimensions.height }, content: getContent() };
        },
        end: () => setIsDraggingClientOrTerminal(false),
        collect: (monitor) => ({
            isDragging: monitor.isDragging()
        })
    }));

    useDisableTextSelection(isDragging)

    ///////////////////
    /// CONTENT
    ///////////////////
    const getContent = () => (
        <div
            ref={ref}
            className="draggable-item"
            onClick={isMobile && stops.length < 8 ? () => { handleSetRouteStop('stop', null, RouteStop.initFromRouteOrTerminal(client), -1) } : undefined}
            style={{ fontWeight: 'bold', cursor: 'pointer', padding: 8, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', border: '2px solid var(--bs-primary)', minWidth: 260, borderRadius: 6, fontSize: 14 }}
        >
            {client.name}
        </div>
    )

    return (
        <div ref={(node) => {
            ref.current = node; // Assign ref to keep track of the element
            drag(node); // Connect the node to the drag source
        }}>
            {getContent()}
        </div>
    );
}

//////////////////////////
/// CUSTOM HOOKS
//////////////////////////

function useDisableTextSelection(isDragging) {
    useEffect(() => {
        document.body.style.userSelect = isDragging ? 'none' : '';
        return () => {
            document.body.style.userSelect = '';
        };
    }, [isDragging]);
}

function useElementDimensions(){
    const ref = useRef(null);
    const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

    useEffect(() => {
        const updateDimensions = () => {
            if (ref.current) {
                const { width, height } = ref.current.getBoundingClientRect();
                setDimensions({ width, height });
            }
        };
        updateDimensions();
    }, []);

    return [ref, dimensions, setDimensions];
};