/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, { useState } from "react";
import Button from 'react-bootstrap/Button';
import CustomControl from "../../../../../../../components/CustomControl.js";
import Modal from 'react-bootstrap/Modal';
import { holidayUnitDictionary} from "../../../../payrollTools.js";
import Picker from "../../../../../../../components/Picker.js";
import { getFakeUID } from "../../../../../../../tools.js";
import Holiday from "../../../../Models/Holiday.js";
import { Validation, useStateWithValidation, useValidationReducer } from "../../../../../../../validation.tsx";
import { InputGroup } from "react-bootstrap";
import PayrollMoneyControl from "../../../PayrollMoneyControl.js";
import PayrollDecimalControl from "../../../PayrollDecimalControl.js";
import PayrollIntegerControl from "../../../PayrollIntegerControl.js";

export default function AddHolidayForm({handleAddHoliday, qualifiesForPtoAndHolidays, pay}) {
    const defaultPayType = pay.reduce((prev, curr) => {
        if (prev == 'fl' && ['ph', 'pd'].includes(curr.payType)) {
            return curr.payType;
        } else {
            return prev;
        }
    }, 'fl');
    const defaultPayRate = pay.reduce((prev, curr) => {
        if (prev == '0.00' && ['ph', 'pd'].includes(curr.payType)) {
            return curr.payRate;
        } else {
            return prev;
        }
    }, '0.00');


    const [payType, setPayType, payTypeValidationMessage] = useStateWithValidation(defaultPayType, Validation.nonEmptyString);
    const [payRate, setPayRate, payRateValidationMessage] = useStateWithValidation(defaultPayRate, Validation.greaterThanZero);
    const [unitsWorked, setUnitsWorked, unitsWorkedValidationMessage] = useStateWithValidation('', payType == 'fl' ? Validation.none : Validation.greaterThanZero);
    
    const isValid = useValidationReducer([payTypeValidationMessage, payRateValidationMessage, unitsWorkedValidationMessage]);

    const handleSubmit = () => {
        handleAddHoliday(new Holiday(getFakeUID(), payType, payRate, unitsWorked));
    }

    const content = (
        <>
            <Modal.Header closeButton>
                <Modal.Title>Adding Holiday</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{display: 'flex', flexDirection: 'column', gap: 6}}>
                <InputGroup>
                    <PayrollMoneyControl lhDecimalPlaces={4} validate validationMessage={payRateValidationMessage} value={payRate} setValue={setPayRate} title='Pay Rate'/>
                    <Picker title='Pay Type' validate validationMessage={payTypeValidationMessage} selection={payType} setSelection={(value) => {setPayType(value)}} itemNames={Object.values(Holiday.holidayPayTypes)} itemValues={Object.keys(Holiday.holidayPayTypes)}/>
                </InputGroup>
                {payType != 'fl' && 
                    <PayrollIntegerControl title={holidayUnitDictionary[payType] ?? 'Units Worked'} value={unitsWorked} setValue={setUnitsWorked} validate max={255} validationMessage={unitsWorkedValidationMessage}/>
                }
            </Modal.Body>
            <Modal.Footer style={{display: 'flex', justifyContent: 'flex-end'}}>
                <Button style={{color: 'white'}} disabled={!isValid} onClick={handleSubmit}>Submit</Button>
            </Modal.Footer>
        </>
    )

    return qualifiesForPtoAndHolidays ? content : (
        <span style={{fontStyle: 'italic', color: 'gray', textAlign: 'center', margin: 8}}>Salaried employees do not recieve pay for holidays and pto</span>
    )
            
}