/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import { encodeDecimal, validateDecimal } from "../../../../tools";

export default class Loan {
    loanIdentifier;
    isTicket;
    name;
    amount;
    isEnabled;

    constructor(loanIdentifier, isTicket, name, amount, isEnabled) {
        this.loanIdentifier = loanIdentifier;
        this.isTicket = isTicket;
        this.name = name;
        this.amount = amount;
        this.isEnabled = isEnabled;
    }

    static decode(obj) {
        return new Loan(obj.loanIdentifier, obj.isTicket, obj.name, obj.amount, !!obj.isEnabled);
    }

    encode() {
        return {
            loanIdentifier: this.loanIdentifier,
            isTicket: this.isTicket,
            name: this.name,
            amount: encodeDecimal(this.amount, 5, 2, true),
            isEnabled: this.isEnabled,
        }
    }

    duplicate() {
        return new Loan(this.loanIdentifier, this.isTicket, this.name, this.amount, this.isEnabled);
    }

    getAmount() {
        return this.isEnabled ? validateDecimal(this.amount) : 0;
    }
}