/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, { useEffect, useState } from "react";
import Button from 'react-bootstrap/Button';
import SearchBar from "../../../../components/SearchBar.js";
import Card from 'react-bootstrap/Card';
import Modal from 'react-bootstrap/Modal';
import CompanyFileUploadControl from "../../../../components/CompanyFileUploadControl.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getFileIcon } from "../../../../tools.js";
import FileDownloadButton from "../../../../components/FileDownloadButton.js";
import moment from "moment";
import DocumentPreview from "../../../../components/DocumentPreview.js";
import { faBars, faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { ApiRequest } from "../../../../ApiManager.tsx";
import LoadingWrapper from "../../../../components/LoadingWrapper.js";
import AlertConfirmation from "../../../../components/AlertModals/AlertConfirmation.js";
import DocumentEditorModal from "./DocumentEditorModal.js";
import './MyCompanyDocuments.css';
import { Dropdown, ListGroup } from "react-bootstrap";
import QuickTable from "../../../../components/QuickTable.js";

export default function MyCompanyDocuments({}){
    const [isLoading, setIsLoading] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const [documents, setDocuments] = useState([]);
    const [filter, setFilter] = useState('');
    const [selectedDoc, setSelectedDoc] = useState(undefined);
    const [modalSwitch, setModalSwitch] = useState('');
    const [showAcknowledgements, setShowAcknowledgements] = useState(false);
    const [showPreview, setShowPreview] = useState(false);
    const [selectedDocumentAcknowledgements, setSelectedDocumentAcknowledgements] = useState([]);
    const [numberOfUsers, setNumberOfUsers] = useState(0);

    useEffect(()=>{
        loadData();
    }, [])

    function loadData(){
        new ApiRequest('myCompany', 'getCompanyDocuments', setIsLoading, (response) => {
            setDocuments(response.documents);
            setNumberOfUsers(response.numberOfUsers);
        }).withNoAlertOnSuccess().send()
    }

    function handleGetCompanyAcknowledgements(fileIdentifier){
        new ApiRequest('myCompany', 'getCompanyAcknowledgements', setIsLoading, (response) => {
            setSelectedDocumentAcknowledgements(response.acknowledgements.sort((a, b) => {
                return `${a.user.lastName}${a.user.firstName}${a.user.middleName}`.localeCompare(`${b.user.lastName}${b.user.firstName}${b.user.middleName}`);
            }))
            setShowAcknowledgements(true);
        }).withUid(fileIdentifier).withNoAlertOnSuccess().send();
    }

    function handleDeleteDocument(){
        new ApiRequest('myCompany', 'deleteCompanyDocument', setIsDeleting, () => {
            const newArray = Array.from(documents).filter(r => r.fileIdentifier != selectedDoc.fileIdentifier);
            newArray.sort((a, b) => a.name.localeCompare(b.name));
            setDocuments(newArray);
            setSelectedDoc(undefined);
            setModalSwitch('');
        }).withData({fileIdentifier: selectedDoc.fileIdentifier}).send()
    }

    function handleUploadDocument(docObj) {
        const newArray = Array.from(documents);
        newArray.push(docObj);
        newArray.sort((a, b) => a.name.localeCompare(b.name));
        setDocuments(newArray);
        setModalSwitch('')
    }

    function handleEditDocument(document) {
        const newArray = Array.from(documents.filter(r => r.fileIdentifier !== document.fileIdentifier));
        newArray.push(document);
        newArray.sort((a, b)=>a.name.localeCompare(b.name));
        setDocuments(newArray);
        setModalSwitch('')
    }

    function handlePreviewButtonClick(doc){
        setSelectedDoc(doc);
        setShowPreview(true);
    }

    const filteredDocuments = documents.filter((doc) => {
        return doc.name.toLowerCase().includes(filter.toLowerCase());
    });

    const documentsElements = filteredDocuments.map((doc) => {
        const isPreviewable = (doc.type === 'jpeg' || doc.type === 'png' || doc.type === 'jpg' || doc.type === 'pdf');
        return (
            <Card key={doc.fileIdentifier} style={{display: 'flex', flexDirection: 'row', flex: 1}}>
                <div style={{display: 'flex', height: '100%', padding: 18, gap: 12, borderTopLeftRadius: 6, borderBottomLeftRadius: 6, backgroundColor: 'var(--bs-primary)', alignItems: 'center', flexShrink: 0}}>
                    <FontAwesomeIcon icon={getFileIcon(doc.type)} style={{color: 'white'}}/>
                </div>
                <div style={{borderTopRightRadius: 6, borderBottomRightRadius: 6, border: '2px solid var(--bs-primary)', padding: '0px 8px 0px 8px', borderLeft: 'none', flex: 1, alignItems: 'center', justifyContent: 'space-between', display: 'flex'}}>
                    <span style={{whiteSpace: 'nowrap', textOverflow: 'ellipsis', width: '20%'}}>{doc.name}</span>
                    <div className="my-company-document-buttons-dekstop" >
                        { (doc.isRequired == '1' || doc.acknowledgements > 0) && 
                            <Button variant={'outline-primary'} style={{flexShrink: 0}} onClick={() => handleGetCompanyAcknowledgements(doc.fileIdentifier)}>{'Acknowledgements (' + doc.acknowledgements + '/' + numberOfUsers +')'}</Button>
                        }
                        <Button variant={'outline-primary'} style={{flexShrink: 0}} onClick={() => {setSelectedDoc(doc); setModalSwitch('edit')}}>Edit</Button>
                        {isPreviewable && <Button variant={'outline-primary'} onClick={() => handlePreviewButtonClick(doc)}><FontAwesomeIcon size='xl' icon={faMagnifyingGlass}/></Button>}
                        <FileDownloadButton  data={{fileIdentifier: doc.fileIdentifier}} type='myCompany' target='getCompanyDocument' doc={doc}/>
                        <Button variant={'outline-primary'} style={{flexShrink: 0}} onClick={() => {setSelectedDoc(doc); setModalSwitch('delete')}}>Delete</Button>                            
                    </div>
                    <div className="my-company-document-buttons-mobile">
                        <Dropdown>
                            <Dropdown.Toggle variant="outline-primary"><FontAwesomeIcon icon={faBars}/></Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Header>{'Acknowledgements (' + doc.acknowledgements + '/' + numberOfUsers +')'}</Dropdown.Header>
                                <Dropdown.Item onClick={() => handleGetCompanyAcknowledgements(doc.fileIdentifier)}>View Acknowledgements</Dropdown.Item>
                                <Dropdown.Divider/>
                                <Dropdown.Header>Options</Dropdown.Header>
                                <Dropdown.Item onClick={() => {setSelectedDoc(doc); setModalSwitch('edit')}}>Edit</Dropdown.Item>
                                {isPreviewable && <Dropdown.Item  onClick={() => handlePreviewButtonClick(doc)}>Preview</Dropdown.Item>}
                                <FileDownloadButton data={{fileIdentifier: doc.fileIdentifier}} type='myCompany' target='getCompanyDocument' label="Download" isDropdownItem doc={doc}/>
                                <Dropdown.Item  onClick={() => {setSelectedDoc(doc); setModalSwitch('delete')}}>Delete</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                </div>
            </Card>
        )
    });

    const content = (
        <div style={{height: '100%'}}>
            <div style={{display: 'flex', flex: 1, flexDirection: 'row', gap: 8, alignItems: 'center', flexWrap: 'wrap-reverse'}}>
                <div style={{flex: 1, minWidth: 310}}>
                    <SearchBar label='Search for a document by title' value={filter} setValue={(value) => setFilter(value)}/>
                </div>
                <Button variant={'outline-primary'} style={{flexShrink: 0, marginLeft: 'auto'}} onClick={() => setModalSwitch('upload')}>Upload Document</Button>
            </div>
            <div style={{display: 'flex', flex: 1, flexDirection: 'column', gap: 12, marginTop: 12}}>
                {documentsElements}
            </div>
        </div>
    )

    const acknowledgements = selectedDocumentAcknowledgements.map((doc, index)=>{
        return (
            <tr key={index}>
                <td>{doc.user.lastName + ', ' + doc.user.firstName + ' ' + doc.user.middleName}</td>
                <td>{moment(doc.dateTime).format('MMM D, YYYY [at] h:mm A')}</td>
            </tr>
        )
    });

    return (
        <>
        <LoadingWrapper isLoading={isLoading}>
            {content}
        </LoadingWrapper>
            <Modal show={modalSwitch === 'upload'} onHide={() => setModalSwitch('')} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Upload Document</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <CompanyFileUploadControl handleUploadDocument={handleUploadDocument}/>
                </Modal.Body>
            </Modal>
            <Modal centered size='lg' fullscreen='sm-down' show={modalSwitch === 'edit'} onHide={() => setModalSwitch('')}>
                <DocumentEditorModal document={selectedDoc} handleEditDocument={handleEditDocument}/>
            </Modal>
            <Modal centered show={modalSwitch === 'delete'} onHide={() => {setSelectedDoc(undefined); setModalSwitch('')}} >
                <AlertConfirmation 
                    isLoading={isDeleting}
                    variant='outline-danger'
                    title='Deletion Confirmation' 
                    message='Permanently delete document?' 
                    buttonLabel='Delete' 
                    callBack={handleDeleteDocument}
                />
            </Modal>
            <Modal centered size='xl' fullscreen='sm-down' show={showAcknowledgements} onHide={()=> setShowAcknowledgements(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Acknowledged Users</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <QuickTable headers={['Employee', 'Date Acknowledged']} rows={acknowledgements}/>
                </Modal.Body>
            </Modal>
            <Modal show={showPreview} onHide={() => setShowPreview(false)} size='xl' fullscreen='xl-down'>
                <DocumentPreview type='myCompany' target='getCompanyDocument' document={selectedDoc}/>
            </Modal>
        </>
    )
}