/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, { useEffect, useState } from "react";
import moment from "moment";

export default function TimeClockTicker({fontSize}){
    const [timestamp, setTimeStamp] = useState(moment());

    useEffect(() => {
        const timerID = setInterval(() => setTimeStamp(moment()), 1000);

        return () => {
            clearInterval(timerID);
        }
    })

    return (
        <p style={{margin: 0, fontSize: fontSize, whiteSpace: 'nowrap'}}>{timestamp.format('h:mm:ss A')}</p>
    )
}