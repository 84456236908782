/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import { validateBig } from "../payrollTools";
import { encodeDecimal, encodeNumber, validateInteger } from "../../../../tools";

export default class Pto {
    ptoIdentifier;
    payRate;
    hours;
    date;

    constructor(ptoIdentifier, payRate, hours, date) {
        this.ptoIdentifier = ptoIdentifier;
        this.payRate = payRate;
        this.hours = hours;
        this.date = date;
    }

    static decode(obj) {
        return new Pto(obj.ptoIdentifier, obj.payRate, obj.hours, obj.date);
    }

    encode() {
        return {
            ptoIdentifier: this.ptoIdentifier,
            payRate: encodeDecimal(this.payRate, 3, 2, true),
            hours: encodeNumber(this.hours, 0, 255),
        }
    }

    getPtoPay() {
        return validateBig(validateInteger(this.hours)).times(validateBig(this.payRate));
    }

    // static decodeFromScheduleItem(json, payJson) {
    //     const decoder = new Decoder(json);
    //     const uid = decoder.decode('uid', Decoder.Uid);
    //     const payRate = !payJson ? 0.0 : payJson.payType === 'ph' ? validateDecimal(payJson.payRate) : payJson.payType === 'pd' ? validateUsd(payJson.payRate / 8.0) : validateDecimal(payJson.hourlyWage);
    //     const hours = decoder.decode('number', Decoder.Integer);
    //     const date = decoder.decode('date', Decoder.StringStrict);
    //     if (decoder.checkForErrors()) {
    //         return new Pto(uid, payRate, hours, date);
    //     } else {
    //         return Pto.initDefault();
    //     }
    // }
}