/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import Big from "big.js";

export const adminMode = false;

export const payTypeDictionary = {
    ph: 'Per Hour',
    pd: 'Per Day',
    py: 'Per Year',
    pm: 'Per Mile',
    xd: 'Extra Day Wage',
    iw: 'Incentive Wage',
    sw: 'Stand-By Wage',
}
export const payTypeDictionaryWithOt = {
    ...payTypeDictionary,
    ot: 'Overtime'
}
export const payRateDictionary = {
    ph: 'Hourly Rate',
    pd: 'Daily Rate',
    py: 'Salary',
    pm: 'Pay Per Mile',
    ps: 'Pay Per Stop',
    xd: 'Extra Day Rate'
}
export const wageDictionary = {
    ph: 'Hourly Wages',
    pd: 'Regular Wages',
    py: 'Regular Wages',
    pm: 'Mile Wages',
    ps: 'Stop Wages',
    xd: 'Extra Day Wages',
    iw: 'Incentive Wages',
    sw: 'Stand-By Wages',
}

export const unitsWorkedDictionary = {
    ph: 'Hours Worked',
    pd: 'Days Worked',
    pm: 'Miles',
    ps: 'Stops',
    xd: 'Extra Days Worked',
    iw: 'Days Worked',
}

export const holidayUnitDictionary = {
    ph: 'Number of Hours',
    pd: 'Number of Days',
}

export function validateBig(value, defaultValue = new Big('0.0')) {
    try {
        return new Big(value);
    } catch {
        return defaultValue;
    }
}

export function bigToUsd(value) {
    return '$' + value.toFixed(2)
}

export function validateUsd(value) {
    return isNaN(parseFloat(value)) ? 0.0 : parseFloat((parseFloat(value)).toFixed(2));
}

export function getPayrollVersionType(version, payrollPeriod) {
    const isSentBack = !version.companyUserIdentifier && !version.adminIdentifier && !!version.gross;
    const isSystemGenerated = !isSentBack && !version.companyUserIdentifier && !version.adminIdentifier;
    const isTtaSubmission = !isSystemGenerated && payrollPeriod.ttaSubmission == version.uid;
    const isTtaSave = !isTtaSubmission && version.adminIdentifier;
    const isClientSubmission = !isTtaSave && payrollPeriod.clientSubmission == version.uid;
    const isClientSave = !isClientSubmission && !version.isBc;
    const isBcSubmission = !isClientSave && payrollPeriod.bcSubmission == version.uid || payrollPeriod.terminalSubmissions?.some(ts => ts.uid == version.uid);
    const isBcSave = !isBcSubmission;
    const type = 
        isSentBack ? 'Sent Back for Review'
        : isSystemGenerated ? 'System Generated' 
        : isTtaSubmission ? 'TTA Submission'
        : isTtaSave ? 'TTA Save'
        : isClientSubmission ? 'Company Submission'
        : isClientSave ? 'Company Save'
        : isBcSubmission ? 'BC Submission'
        : isBcSave ? 'BC Save'
        : ''
    ;
    return type;
}

export function getPayrollStatus(payrollPeriod, terminalCount) {
    if (payrollPeriod.clientSubmission || payrollPeriod.versions.some(v => v.adminIdentifier)) {
        return 'Awaiting TTA Approval';
    } else if (payrollPeriod.bcSubmission || payrollPeriod.versions.some(v => v.companyUserIdentifier && !v.isBc) || (payrollPeriod.terminalSubmissions?.length > 0 && payrollPeriod['terminalIdentifier'])) {
        return 'Awaiting AO Approval';
    } else if (payrollPeriod.terminalSubmissions?.length == terminalCount) {
        return 'All Terminals Submitted - Awaiting AO Approval'
    } else if (payrollPeriod.terminalSubmissions?.length > 0) {
        return `${payrollPeriod.terminalSubmissions.length}/${terminalCount} Terminals Submitted`
    } else if (payrollPeriod.bcPayrollEnabled && payrollPeriod.restrictBcPayrollToTerminals && !payrollPeriod['terminalIdentifier']) {
        return 'Awaiting BC Submissions'
    } else if (payrollPeriod.bcPayrollEnabled) {
        return 'Awaiting BC Submission'
    } else {
        return 'Awaiting Company Submission'
    }
}