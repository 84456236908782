/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import { useEffect, useState } from "react"
import { ApiRequest } from "../../../../ApiManager.tsx";
import LoadingWrapper from "../../../../components/LoadingWrapper.js";
import { Button, Dropdown, ListGroup, Modal } from "react-bootstrap";
import QuickTable from "../../../../components/QuickTable.js";
import DropdownItemWithCheck from "../../../../components/DropdownItemWithCheck.js";
import DeviceEditorModal from "./DeviceEditorModal.js";
import QRCode from "react-qr-code";
import SearchBar from "../../../../components/SearchBar.js";
import './MyCompanyDevices.css';
import KeyValueRow from "../../../../components/KeyValueRow.js";
import CustomButton from "../../../../components/CustomButton.js";
import AlertConfirmation from "../../../../components/AlertModals/AlertConfirmation.js";

export default function MyCompanyDevices({}){
    const [isLoading, setIsLoading] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const [devices, setDevices] = useState([]);
    const [selectedDevice, setSelectedDevice] = useState(null);
    const [deviceToken, setDeviceToken] = useState('');
    const [terminals, setTerminals] = useState([]);
    const [selectedTerminals, setSelectedTerminals] = useState([]);
    const [search, setSearch] = useState('');
    const [filterByRegistered, setFilterByRegistered] = useState(true);
    const [filterByUnregistered, setFilterByUnregistered] = useState(true);
    const [showModal, setShowModal] = useState(false);
    const [showQRModal, setShowQRModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);

    useEffect(() => {
        loadData();
    }, [])

    function loadData(){
        new ApiRequest('myCompany', 'getDevices', setIsLoading, (response) => {
            setDevices(response.devices);
            setTerminals(response.terminals);
            setSelectedTerminals(response.terminals);
        }).withNoAlertOnSuccess().send()
    }

    function handleDeleteDevice(){
        new ApiRequest('myCompany', 'deleteDevice', setIsDeleting, () => {
            setDevices(devices.filter(d => d.uid !== selectedDevice.uid));
            setShowDeleteModal(false);
            setShowQRModal(false)
        }).withUid(selectedDevice.uid).send();
    }

    function handleSelectTerminal(uid){
        let newArr = Array.from(selectedTerminals);
        if(newArr.find((t)=>t.uid === uid)){
            newArr = newArr.filter((t)=> t.uid !== uid);
        } else {
            newArr.push(terminals.find(t => t.uid === uid));
        }
        setSelectedTerminals(newArr);
    }

    const filterDropdownMenu = (
        <Dropdown autoClose='outside'>
            <Dropdown.Toggle variant="outline-primary">
                Filter
            </Dropdown.Toggle>
            <Dropdown.Menu>
                <Dropdown.Header>Filter by Terminal</Dropdown.Header>
                {terminals.map((terminal) => {
                    return <DropdownItemWithCheck key={terminal.uid} label={terminal.name} active={selectedTerminals.map(t => t.uid).includes(terminal.uid)} onClick={() => handleSelectTerminal(terminal.uid)} />
                })}
                <Dropdown.Divider/>
                <Dropdown.Header>Filter by Status</Dropdown.Header>
                <DropdownItemWithCheck label='Filter by Registered' active={filterByRegistered} onClick={() => setFilterByRegistered(!filterByRegistered)}/>
                <DropdownItemWithCheck label='Filter by Unregistered' active={filterByUnregistered} onClick={() => setFilterByUnregistered(!filterByUnregistered)}/>
            </Dropdown.Menu>
        </Dropdown>
    )

    ///////////////////
    /// DESKTOP
    ///////////////////

    const deviceRows = devices.filter(device => {
        if(!filterByRegistered && device.vendorId)
            return false;
        if(!filterByUnregistered && !device.vendorId)
            return false;

        return device.name.toLocaleLowerCase().includes(search.toLocaleLowerCase()) && selectedTerminals.map(t => t.uid).includes(device.terminalIdentifier);
    }).map(device => {
        return (
            <tr key={device.uid} style={{cursor: 'pointer'}} onClick={() => {
                if(device.vendorId){
                    setSelectedDevice(device);
                    setShowModal(true);
                }else{
                    setDeviceToken(device.deviceToken)
                    setSelectedDevice(device);
                    setShowQRModal(true);
                }
            }}>
                <td>{device.name}</td>
                <td>{terminals.find(t => t.uid === device.terminalIdentifier).name}</td>
                <td>{device.vendorId ? 'Yes' : 'No'}</td>
            </tr>
        )
    })

    ///////////////////
    /// MOBILE
    ///////////////////

    const deviceMobileItems = devices.filter(device => {
        if(!filterByRegistered && device.vendorId)
            return false;
        if(!filterByUnregistered && !device.vendorId)
            return false;

        return device.name.toLocaleLowerCase().includes(search.toLocaleLowerCase()) && selectedTerminals.map(t => t.uid).includes(device.terminalIdentifier);
    }).map(device => {
        return (
            <ListGroup.Item key={device.uid} action onClick={() => {
                if(device.vendorId){
                    setSelectedDevice(device);
                    setShowModal(true);
                }else{
                    setDeviceToken(device.deviceToken)
                    setSelectedDevice(device);
                    setShowQRModal(true);
                }
            }}>
                <h6 style={{fontWeight: 'bold'}}>{device.name}</h6>
                <KeyValueRow title={'Terminal'} value={terminals.find(t => t.uid === device.terminalIdentifier).name}/>
                <KeyValueRow title={'Registered'} value={device.vendorId ? 'Yes' : 'No'}/>
            </ListGroup.Item>
        )
    })

    return (
        <LoadingWrapper isLoading={isLoading}>
            <div style={{display: 'flex', marginBottom: 12, justifyContent: 'flex-end', gap: 6, alignItems: 'center'}}>
                 <div style={{display: 'flex', gap: 6}}>
                    {filterDropdownMenu}
                </div>
                <div style={{display: 'flex', gap: 6}}>
                    <Button variant={'outline-primary'} onClick={() => {setSelectedDevice(null); setShowModal(true);}}>Add Device</Button>
                </div>
            </div>
            <div style={{marginBottom: 12}}>
                <SearchBar label='Search by name' value={search} setValue={setSearch}/>
            </div>
            <div className="device-list-desktop">
            <QuickTable hover headers={['Name', 'Terminal', 'Registered']} rows={deviceRows}/>
            </div>
            <div className="device-list-mobile">
                <ListGroup >
                    {deviceMobileItems}
                </ListGroup>
            </div>
            <Modal centered size='lg' show={showModal} onHide={() => setShowModal(false)}>
                <DeviceEditorModal  selectedDevice={selectedDevice} setDeviceToken={setDeviceToken} terminals={terminals} devices={devices} setDevices={setDevices} setShowQRModal={setShowQRModal} hideModal={() => setShowModal(false)}/>
            </Modal>
            <Modal centered show={showQRModal} onHide={() => setShowQRModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Scan Code</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div style={{ height: "auto", margin: "0 auto", padding: '12px 0', maxWidth: 256, width: "100%"}}>
                        <QRCode
                            value={deviceToken}
                            size={256}
                            style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                            viewBox={`0 0 256 256`}
                        />
                    </div>
                    <h5 style={{textAlign:'center', marginTop: 16, marginBottom: 10}}>{deviceToken}</h5>
                </Modal.Body>
                <Modal.Footer>
                    <p style={{textAlign: 'center'}}>Open the My Tactical Linehaul App on your device and scan the QR code or type in the token to register your device.</p>
                </Modal.Footer>
                <Modal.Footer>
                    <CustomButton onClick={() => setShowDeleteModal(true)} variant='outline-danger'>Delete Device</CustomButton>
                </Modal.Footer>
            </Modal>
            <Modal centered show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
                <AlertConfirmation variant='outline-danger' isLoading={isDeleting} callBack={handleDeleteDevice} title='Delete Device' message='Are you sure you want to delete this device?' buttonLabel='Delete'/>
            </Modal>
        </LoadingWrapper>
    )
}
