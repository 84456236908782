/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, {useState} from 'react';
import moment from 'moment';
import Modal from 'react-bootstrap/Modal';
import Dropdown from 'react-bootstrap/Dropdown';
import Spinner from 'react-bootstrap/Spinner';

export default function HRUserBWCVersionHeader({versions, selectedVersion, setSelectedVersion, isLoading}) {
    const versionElements = versions.map((version) => {
        let authorString;
        if(version?.author){
            authorString = `${version.author.firstName} ${version.author.lastName}`;
        } else if(version?.adminIdentifier) {
            authorString = 'TTA Admin';
        } else{
            authorString = 'Driver-Generated';
        }
        return (
            <Dropdown.Item key={version.uid} onClick={() => setSelectedVersion(version)}>
                {moment(version.submissionDateTime).format('MMM D, YYYY') + ' - ' + authorString + (version.uid === versions[0].uid ? ' (latest)' : '')}
            </Dropdown.Item>
        )
    });

    let authorString;
    if(selectedVersion?.author){
        authorString = `${selectedVersion.author.firstName} ${selectedVersion.author.lastName}`;
    } else if(selectedVersion?.adminIdentifier) {
        authorString = 'TTA Admin';
    } else{
        authorString = 'Driver-Generated';
    }

    return (
        <Modal.Header>
            <Modal.Title style={{fontSize: 18}}>
                {
                    `${selectedVersion?.author ? 'Authored by' : ''} ${authorString} on ${moment(selectedVersion.submissionDateTime).format('MMM D, YYYY')} ${(selectedVersion.uid === versions[0].uid ? ' (LATEST VERSION)' : ' (HISTORIC)')}`
                }
            </Modal.Title>
            <Dropdown> 
                <Dropdown.Toggle variant={'outline-primary'} disabled={isLoading}>
                    {isLoading ? <Spinner size='sm'/> : 'Change Version'}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                    {versionElements}
                </Dropdown.Menu>
            </Dropdown>
        </Modal.Header>
    )
}