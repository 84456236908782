/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React from "react";
import InputGroup from 'react-bootstrap/InputGroup';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashCan } from "@fortawesome/free-solid-svg-icons";
import CustomToolTip from "../../../../../../../components/CustomToolTip.js";
import AdditionalPay from "../../../../Models/AdditionalPay.js";
import { Dropdown, Modal } from "react-bootstrap";
import PayrollMoneyControl from "../../../PayrollMoneyControl.js";
import { usdFormatter } from "../../../../../../../tools.js";
import PayrollEntryPaySection from "../PayrollEntryPaySection.js";
import { useModalToggle } from "../../../../../../../hooks.tsx";
import AddAdditionalPayForm from "./AddAdditionalPayForm.js";
import PayrollAdditionalPayItem from "./PayrollAdditionalPayItem.js";
import QuickTable from "../../../../../../../components/QuickTable.js";

export default function PayrollEmployeeAdditionalPayEditor({entry, handleSetWeek, handleAddItemToAllEntries}) {
    const [modalIsVisible, showModal, hideModal] = useModalToggle();

    const handleAddAdditionalPay = (additionalPay, applyToAll) => {
        if (applyToAll) {
            handleAddItemToAllEntries('additionalPay', additionalPay, entry.weekIndex);
        } else {
            const newArr = Array.from(entry.weeks[entry.weekIndex].additionalPay);
            newArr.push(additionalPay);
            handleSetWeek(entry.weekIndex, 'additionalPay', newArr);
        }
        hideModal();
    }

    const removeAdditionalPay = (uid) => {
        const newArr = entry.weeks[entry.weekIndex].additionalPay.filter((element) => {
            return element.uid !== uid;
        });
        handleSetWeek(entry.weekIndex, 'additionalPay', newArr);
    }

    const handleSetAdditionalPay = (uid, key, value) => {
        const newArr = Array.from(entry.weeks[entry.weekIndex].additionalPay);
        newArr.find(r => r.uid === uid)[key] = value;
        handleSetWeek(entry.weekIndex, 'additionalPay', newArr);
    }

    const additionalPayRows = entry.weeks[entry.weekIndex].additionalPay.map((ap) => {
        return (
            <PayrollAdditionalPayItem key={ap.uid} ap={ap} handleSetAdditionalPay={handleSetAdditionalPay} removeAdditionalPay={removeAdditionalPay}/>
        )
    });

    const reoccurringHeader = (
        <div style={{display: 'flex', gap: 6, alignItems: 'center'}}>
            <span>Reoccurring</span>
            <CustomToolTip text={'Next payroll period, this additional pay will be automatically added to this employee'}/>
        </div>
    )

    return (
        <PayrollEntryPaySection title={'ADDITIONAL PAY'} showModal={showModal}>
            { entry.weeks[entry.weekIndex].additionalPay.length > 0 &&  
                <div style={{display: 'flex', flexDirection: 'column', gap: 6}}>
                    <div style={{overflowX: 'auto'}}>
                        <QuickTable noMargin noWrap size='sm' rows={additionalPayRows} headers={['Type', 'Amount', reoccurringHeader, '']} widths={[1, null, 1, 1]}/>
                    </div>
                    <div style={{display: 'flex', justifyContent: 'flex-end', fontWeight: 'bold', gap: 6}}>
                        <span>Total:</span>
                        <span style={{color: 'green', }}>{usdFormatter.format(entry.weeks[entry.weekIndex].getAdditionalPay().toNumber())}</span>
                    </div>
                </div>
            }
            <Modal show={modalIsVisible} onHide={hideModal} fullscreen='lg-down'>
                <AddAdditionalPayForm handleAddAdditionalPay={handleAddAdditionalPay}/>
            </Modal>
        </PayrollEntryPaySection>
    )
}