/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, { useState } from "react";
import Button from 'react-bootstrap/Button';
import { Card, Col, Container, Modal, Table, Row, ListGroup } from "react-bootstrap";
import moment from "moment";
import CustomButton from "../../../components/CustomButton";
import KeyValueRow from "../../../components/KeyValueRow";
import { addressToString, formatPhoneNumber } from "../../../tools";
import { ApiRequest } from "../../../ApiManager.tsx";
import { Validation } from "../../../validation.tsx";
import CustomControl from "../../../components/CustomStateControls/CustomControl.js";

export default function PersonalInfo({user}){
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [isChangingPassword, setIsChangingPassword] = useState(false);

    function handlePasswordChange(){
        new ApiRequest('user', 'changePassword', setIsChangingPassword, () => {
            setOldPassword('');
            setNewPassword('');
            setConfirmPassword('');
            setShowModal(false);
        }, () => setOldPassword('')).withData({currentPassword: oldPassword, newPassword: newPassword}).send(0)
    }


    const emergencyContacts = user.eContacts.map((contact, index) => {
        return (
            <Card key={index} style={{marginBottom: 12}}>
                <Card.Body style={{paddingBottom:30}}>
                    <Card.Title style={{textAlign:'center', paddingBottom:10}}>Emergency Contact #{index+1}</Card.Title>
                    <ListGroup>
                        <KeyValueRow isListItem title={'First Name'} value={contact.firstName}/>
                        <KeyValueRow isListItem title={'Last Name'} value={contact.lastName}/>
                        <KeyValueRow isListItem title={'Phone Number'} value={formatPhoneNumber(contact.phoneNumber)}/>
                        <KeyValueRow isListItem title={'Email Address'} value={contact.email}/>
                        <KeyValueRow isListItem title={'Relationship'} value={contact.relationship}/>
                        <KeyValueRow isListItem title={'Address'} value={addressToString(contact.address)}/>   
                    </ListGroup>
                </Card.Body>
            </Card>

        )
    })
    
    return (
        <div style={{display: 'flex', flexDirection: 'column', gap: 12}}>
            <p style={{textAlign: 'center', margin: 0, fontSize: 24, fontWeight: 'bold', padding: 10}}>Personal Details</p>
            <Card>
                <Card.Body style={{display:'flex', flexDirection:'column'}}>
                    <div>
                        <Button variant={'outline-primary'} style={{marginBottom: 6}} onClick={() => setShowModal(true)}>Change Password</Button>
                        <Card.Title style={{textAlign:'center', paddingBottom:10}}>Contact Information</Card.Title>
                    </div>
                    <ListGroup>
                        <KeyValueRow isListItem title={'First Name'} value={user.firstName} />
                        <KeyValueRow isListItem title={'Middle Name'} value={user.middleName} />
                        <KeyValueRow isListItem title={'Last Name'} value={user.lastName}/>
                        <KeyValueRow isListItem title={'Email Address'} value={user.email}/>
                        <KeyValueRow isListItem title={'Phone Number'} value={formatPhoneNumber(user.phoneNumber)}/>
                        <KeyValueRow isListItem title={'Address'} value={addressToString(user.address)} />
                        <KeyValueRow isListItem title={'Date of Birth'} value={moment(user.dateOfBirth).format('MMMM D, YYYY')}/>
                    </ListGroup>
                </Card.Body>
            </Card>
            {emergencyContacts}

            <Modal show={showModal} centered onHide={() => {setShowModal(false); setOldPassword(''); setNewPassword(''); setConfirmPassword('');}}>
                <Modal.Header closeButton>
                    <Modal.Title>Change Password</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{display: 'flex', flexDirection: 'column', gap: 12}}>
                    <CustomControl floatingLabel type="password" label='Old Password' validation={Validation.nonEmptyString} value={oldPassword} setValue={(value) => {setOldPassword(value)}}/>
                    <CustomControl floatingLabel type="password" label='New Password' validation={Validation.nonEmptyString} value={newPassword} setValue={(value) => {setNewPassword(value)}}/>
                    <CustomControl floatingLabel type="password" label='Confirm New Password' validation={Validation.nonEmptyString} value={confirmPassword} setValue={(value) => {setConfirmPassword(value)}}/>
                </Modal.Body>
                <Modal.Footer>
                    <CustomButton disabled={Validation.nonEmptyString(oldPassword) || Validation.nonEmptyString(newPassword) || Validation.nonEmptyString(confirmPassword)} isLoading={isChangingPassword} onClick={handlePasswordChange}>Submit</CustomButton>
                </Modal.Footer>
            </Modal>
        </div>
    )
}