import React, { useEffect, useState } from "react";
import { ApiRequest } from "../../../ApiManager.tsx";
import { Button, Card, ListGroup } from "react-bootstrap";
import moment from "moment";
import { formatDateRange, usdFormatter } from "../../../tools.js";
import Wizard from "../../../components/Wizard/Wizard.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { useHistory } from "react-router-dom";
import LoadingWrapper from "../../../components/LoadingWrapper.js";
import { getPayrollStatus, getPayrollVersionType } from "./payrollTools.js";
import { useLoadData } from "../../../hooks.tsx";

export default function Payroll() {
    const history = useHistory();

    const [isLoading, setIsLoading] = useState(false);

    const [editablePayroll, setEditablePayroll] = useState([]);
    const [historicPayroll, setHistoricPayroll] = useState([]);
    const [terminalCount, setTerminalCount] = useState([]);

    useLoadData(() => {
        new ApiRequest('payroll', 'getAll', setIsLoading, (response) => {
            setEditablePayroll(response.editablePayroll);
            setHistoricPayroll(response.historicPayroll);
            setTerminalCount(response.terminalCount);
        }).withNoAlertOnSuccess().send();
    })


    const editablePayrollPeriods = editablePayroll.map((p) => {
        return (
            <PayrollPeriodItem key={p.uid} payrollPeriod={p} terminalCount={terminalCount} history={history}/>
        )
    })

    const activePayrollPeriods = historicPayroll.filter(p => !p.ttaSubmission).map((p) => {
        return (
            <PayrollPeriodItem key={p.uid} payrollPeriod={p} terminalCount={terminalCount} history={history}/>
        )
    })

    const historicPayrollElements = historicPayroll.filter(p => p.ttaSubmission).map((payrollPeriod) => {
        return (
            <ListGroup.Item key={payrollPeriod.uid} action onClick={() => {history.push(`/payroll/${payrollPeriod.uid}`)}}>
                <div style={{padding: '4px 8px', backgroundColor: 'var(--bs-card-cap-bg)', borderTopLeftRadius: 6, borderTopRightRadius: 6, fontSize: 18}}>
                    <div style={{display:'flex', justifyContent:'space-between', gap: 6, flexWrap: 'wrap'}}>
                        <span style={{fontWeight: 'bold'}}>{`${formatDateRange(payrollPeriod.periodStart, payrollPeriod.periodEnd)} ${!!payrollPeriod.isCustom ? ' (Custom)' : ''}`}</span>
                        <span>{payrollPeriod.terminalName ?? 'All Terminals'}</span>
                    </div>
                </div>
            </ListGroup.Item>
        )
    });


    return (
        <div style={{padding: 12, flex: 1, display: 'flex', flexDirection: 'column', gap: 12, overflowY: 'auto'}}>
            <LoadingWrapper isLoading={isLoading}>
                { editablePayrollPeriods.length > 0 &&
                    <>
                        <p style={{opacity: 0.5, fontWeight: 'bold', fontSize: 14, marginBottom: 6}}>EDITABLE PAYROLL PERIODS</p>
                        <ListGroup style={{gap: 8}}>{editablePayrollPeriods}</ListGroup>
                    </>
                }
                { activePayrollPeriods.length > 0 &&
                    <>
                        <p style={{opacity: 0.5, fontWeight: 'bold', fontSize: 14, marginBottom: 6}}>ACTIVE PAYROLL PERIODS</p>
                        <ListGroup>{activePayrollPeriods}</ListGroup>
                    </>  
                }
                { historicPayrollElements.length > 0 &&
                    <>
                        <p style={{opacity: 0.5, fontWeight: 'bold', fontSize: 14, marginBottom: 6}}>HISTORIC PAYROLL PERIODS</p>
                        <ListGroup>{historicPayrollElements}</ListGroup>
                    </>
                }
                { editablePayrollPeriods.length == 0 && activePayrollPeriods.length == 0 && historicPayrollElements.length == 0 &&
                    <div style={{display: 'flex', flex: 1, height: '100%', justifyContent: 'center', alignItems: 'center'}}>
                        <h2 style={{fontWeight: 'bold', opacity: 0.5}}>Your company does not have payroll data to display</h2>
                    </div>
                }
            </LoadingWrapper>
        </div>
    )
}


function PayrollPeriodItem({payrollPeriod, history, terminalCount}) {
    return (
        <Card>
            <Card.Header style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexWrap: 'wrap', gap: 6}}>
                <Card.Title style={{margin: 0}}>{`${payrollPeriod.terminalName ? `${payrollPeriod.terminalName} | ` : ''}${formatDateRange(payrollPeriod.periodStart, payrollPeriod.periodEnd)}`}</Card.Title>
                { !!payrollPeriod.isCustom && 
                    <span style={{fontWeight: 'bold'}}>*Custom Payroll*</span>
                }
                <Button variant='outline-primary' onClick={() => {history.push(`/payroll/${payrollPeriod.uid}`)}}>View</Button>
            </Card.Header>
            <Card.Body style={{padding: 12}}>
                <div style={{display: 'flex', flexWrap: 'wrap', whiteSpace: 'nowrap', alignItems: 'center', gap: 6}}>
                    <span>Status:</span>
                    <div style={{borderTop: '2px dotted lightgray', flex: 1, marginTop: 'auto', paddingBottom: 6}}/>
                    <span style={{fontWeight: 'bold'}}>{getPayrollStatus(payrollPeriod, terminalCount)}</span>
                </div>
                { !!payrollPeriod.gross && 
                    <div style={{display: 'flex', flexWrap: 'wrap', whiteSpace: 'nowrap', alignItems: 'center', gap: 6}}>
                        <span>Gross:</span>
                        <div style={{borderTop: '2px dotted lightgray', flex: 1, marginTop: 'auto', paddingBottom: 6}}/>
                        <span style={{fontWeight: 'bold', color: 'green'}}>{usdFormatter.format(payrollPeriod.gross)}</span>
                    </div>
                }
            </Card.Body>
        </Card>
    )
}