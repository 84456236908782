/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import { validateUsd } from "../payrollTools";
import { encodeDecimal, getFakeUID } from "../../../../tools";

export default class AdditionalPay {

    uid;
    type;
    amount;
    isReoccurring;
    isFromPay;

    constructor(uid, type, amount = 0, isReoccurring, isFromPay = false) {
        this.uid = uid;
        this.type = type;
        this.amount = amount;
        this.isReoccurring = isReoccurring;
        this.isFromPay = isFromPay
    }

    static decode(obj) {
        return new AdditionalPay(obj.uid ?? getFakeUID(), obj.type, obj.amount, !!obj.isReoccurring, !!obj.isFromPay);
    }

    encode() {
        return {
            type: this.type,
            amount: encodeDecimal(this.amount, 3, 2, true),
            isReoccurring: this.isReoccurring,
            isFromPay: this.isFromPay,
        }
    }

    duplicate() {
        return new AdditionalPay(getFakeUID(), this.type, this.amount, this.isReoccurring);
    }

    getAmount() {
        return validateUsd(this.amount);
    }
    
    static additionalPayTypes = [
        'Per Diem',
        'Expense Reimbursement',
        'Extra Day Wage',
        'Backpay'
    ]
}