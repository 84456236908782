/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import { Button, Dropdown, ListGroup, Modal, Row } from "react-bootstrap";
import DropdownItemWithCheck from "../../../../components/DropdownItemWithCheck";
import { useReducer, useState } from "react";
import SwitchControl from "../../../../components/SwitchControl";
import CustomTextArea from "../../../../components/CustomStateControls/CustomTextArea";
import ControlStateProps from "../../../../state/ControlStateProps.tsx";
import { Validation } from "../../../../validation.tsx";
import MyCompanyOfferLetters from './MyCompanyOfferLetters';

export default function OnboardingSettings({settings, setSettingsKey, originalSettings, offerLetters, setOfferLetters}){
    const [showModal, setShowModal] = useState(false)

    return (
        <div>
            <Row>
                <SwitchControl
                    noWrap={false}
                    breakpoints={{lg: 12}}
                    title='Require employees to provide at least one emergency contact when onboarding'
                    originalValue={originalSettings.require1EContact}
                    value={settings.require1EContact}
                    setValue={(value) => setSettingsKey('require1EContact', value)}
                />
            </Row>
            <div style={{paddingTop: 16}}>
                <div style={{display: 'flex', alignItems: 'center', gap: 6}}>
                    <h4 style={{textAlign: 'center', marginLeft: 8}}>Offer Letters</h4>
                    <Button style={{marginBottom: 12, marginLeft: 'auto', display: 'block'}} variant={'outline-primary'} onClick={() => setShowModal(true)}>View/Edit Offer Letter Templates</Button>
                </div>
                <ListGroup>
                    <ListGroup.Item style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: 8, flexWrap: 'wrap'}}>
                        <p style={{margin: 0}}>Default For Driver</p>
                        <Dropdown>
                            <Dropdown.Toggle style={{whiteSpace: 'pre-wrap'}} variant="outline-primary">
                                {offerLetters.find(ol=>ol.uid === settings.defaultOfferLetterForDriver)?.name ?? 'None'}
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                            <DropdownItemWithCheck label='None' active={!settings.defaultOfferLetterForDriver} onClick={() => setSettingsKey('defaultOfferLetterForDriver', null)}/>
                            {offerLetters.map((ol)=>{
                            const isSelected = ol.uid == settings.defaultOfferLetterForDriver;
                            return <DropdownItemWithCheck key={ol.uid} label={ol.name} active={isSelected}  onClick={() => setSettingsKey('defaultOfferLetterForDriver', ol.uid)}/>
                        })}
                        </Dropdown.Menu>
                    </Dropdown> 
                    </ListGroup.Item>         
                    <ListGroup.Item style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: 8, flexWrap: 'wrap'}}>
                        <p style={{margin: 0}}>Default For BC</p>
                        <Dropdown>
                            <Dropdown.Toggle style={{whiteSpace: 'pre-wrap'}} variant="outline-primary">
                                {offerLetters.find(ol=>ol.uid === settings.defaultOfferLetterForBc)?.name ?? 'None'}
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                            <DropdownItemWithCheck label='None' active={!settings.defaultOfferLetterForBc} onClick={() => setSettingsKey('defaultOfferLetterForBc', null)}/>
                            {offerLetters.map((ol)=>{
                                const isSelected = ol.uid == settings.defaultOfferLetterForBc;
                                return <DropdownItemWithCheck key={ol.uid} label={ol.name} active={isSelected}  onClick={() => setSettingsKey('defaultOfferLetterForBc', ol.uid)}/>
                            })}
                            </Dropdown.Menu>
                        </Dropdown> 
                    </ListGroup.Item>      
                    <ListGroup.Item style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: 8, flexWrap: 'wrap'}}>
                        <p style={{margin: 0}}>Default For Admin</p>
                        <Dropdown>
                            <Dropdown.Toggle style={{whiteSpace: 'pre-wrap'}} variant="outline-primary">
                                {offerLetters.find(ol=>ol.uid === settings.defaultOfferLetterForAdmin)?.name ?? 'None'}
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                            <DropdownItemWithCheck label='None' active={!settings.defaultOfferLetterForAdmin} onClick={() => setSettingsKey('defaultOfferLetterForAdmin', null)}/>
                            {offerLetters.map((ol)=>{
                                const isSelected = ol.uid == settings.defaultOfferLetterForAdmin;
                                return <DropdownItemWithCheck key={ol.uid} label={ol.name} active={isSelected}  onClick={() => setSettingsKey('defaultOfferLetterForAdmin', ol.uid)}/>
                            })}
                            </Dropdown.Menu>
                        </Dropdown> 
                    </ListGroup.Item>      
                </ListGroup>
            </div>
            <div style={{margin: 18}}></div>
            <CustomTextArea rows={6} noAsterisk title='Benefits' breakpoints={{lg: 12}} stateProps={new ControlStateProps('benefits', settings, Validation.none)}/>
            <Modal centered size='lg' show={showModal} onHide={() => setShowModal(false)}>
                <MyCompanyOfferLetters offerLetters={offerLetters} setOfferLetters={setOfferLetters} settings={settings} setSettingsKey={setSettingsKey}/>
            </Modal>
        </div>
    )
}