/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import { useReducer, useState } from "react"
import Client from "../Models/Client.js"
import { Button, Col, Modal, Row } from "react-bootstrap"
import CustomControl from "../../../../components/CustomStateControls/CustomControl.js"
import ControlStateProps from "../../../../state/ControlStateProps.tsx"
import { Validation } from "../../../../validation.tsx"
import AddressAutoFill from "../../../../components/CustomStateControls/AddressAutoFill.js"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faMinus, faPlus, faTrash } from "@fortawesome/free-solid-svg-icons"
import CustomButton from "../../../../components/CustomButton.js"
import { ApiRequest } from "../../../../ApiManager.tsx"
import Address from "../../../../models/Address.js"
import StateObject from "../../../../state/StateObject.tsx"
import AlertConfirmation from "../../../../components/AlertModals/AlertConfirmation.js"

export default function ClientEditorModal({setClients, clients, selectedClient, hideModal}){
    const [client, _setClient] = useState(selectedClient ? new Client(selectedClient.uid, selectedClient.companyIdentifier, selectedClient.name, Address.decodeNonStrict(selectedClient.address), selectedClient.defaultLoadTime) : null);
    const [formObject, _setFormObject] = useState(() => new StateObject({clientsToCreate : [Client.initDefault()]}));
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [_, forceUpdate] = useReducer(x => x + 1, 0);

    function handleCreateClients(){
        new ApiRequest('myCompany', 'createClients', setIsSubmitting, (response) => {
            let count = 0;
            formObject['clientsToCreate'].forEach(t => t.setState('uid', response.uids[count++]));
            setClients([...clients, ...formObject['clientsToCreate']]);
            hideModal();
        }).withData({clients: formObject['clientsToCreate'].map(t => t.encode())}).send()
    }

    function handleUpdateClient(){
        new ApiRequest('myCompany', 'updateClient', setIsSubmitting, () => {
            const newArray = Array.from(clients);
            newArray[newArray.findIndex(t => t.uid === selectedClient.uid)] = client;
            setClients(newArray);
            hideModal();
        }).withData({client: client.encode()}).send();
    }

    function handleDeleteClient(){
        new ApiRequest('myCompany', 'deleteClient', setIsDeleting, () => {
            setClients(clients.filter(t => t.uid !== client.uid));
            setShowDeleteModal(false);
            hideModal();
        }).withUid(client.uid).send();
    }

    function handleAddClient(){
        formObject.setState('clientsToCreate', [...formObject['clientsToCreate'], Client.initDefault()]);
        forceUpdate();
    }

    function handleRemoveClient(index){
        const newArray = Array.from(formObject['clientsToCreate']);
        const newClients = newArray.toSpliced(index, 1);
        formObject.setState('clientsToCreate', newClients);
        forceUpdate();
    }

    const clientList = formObject['clientsToCreate'].map((client, index) => {
        return (
            <div key={index} style={{marginBottom: 32, padding: 10, border: '1px solid lightgray', borderRadius: 6}}>
                <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                    <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', fontWeight: 'bolder', margin: 0, height: 30, width: 30, marginBottom: 8, color: 'white', borderRadius: '100%', padding: 12, backgroundColor: 'var(--bs-primary)'}}>{index+1}</div>
                    <Button variant="outline-danger" style={{borderRadius: '100%', height: 30, width: 30, display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: 8}} onClick={() => handleRemoveClient(index)}><FontAwesomeIcon icon={faTrash}/></Button>
                </div>
                <Row>
                    <CustomControl breakpoints={{lg: 6}} floatingLabel maxLength={30} label={'Name'} stateProps={new ControlStateProps('name', client, Validation.nonEmptyString)}/>
                    <CustomControl breakpoints={{lg: 6}} floatingLabel type='number' maxInt={300} label={'Default Load Time (minutes)'} stateProps={new ControlStateProps('defaultLoadTime', client, Validation.nonEmptyString)}/>
                    <AddressAutoFill label='Address' stateProps={new ControlStateProps('address', client, Validation.notUndefined)}/>
                </Row>
            </div>
        )
    })

    return (
        <>
        {client ? 
            <>
                <Modal.Header closeButton>
                    <Modal.Title>Edit Client</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <CustomControl breakpoints={{lg: 6}} maxLength={30} floatingLabel label={'Name'} stateProps={new ControlStateProps('name', client, Validation.nonEmptyString)}/>
                        <CustomControl breakpoints={{lg: 6}} floatingLabel type='number' maxInt={300} label={'Default Load Time (minutes)'} stateProps={new ControlStateProps('defaultLoadTime', client, Validation.nonEmptyString)}/>
                        <AddressAutoFill label='Address' stateProps={new ControlStateProps('address', client, Validation.notUndefined)}/>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <button onClick={() => setShowDeleteModal(true)} style={{marginRight: 'auto',border: 'none', marginRight: 2, boxShadow: 'none', color: 'red', backgroundColor: 'transparent'}}>Delete Client</button>
                    <CustomButton isLoading={isSubmitting} variant='outline-primary' stateObject={client} onClick={handleUpdateClient}>Save Changes</CustomButton>
                </Modal.Footer>
                <Modal centered show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
                    <AlertConfirmation variant='outline-danger' isLoading={isDeleting} callBack={handleDeleteClient} title='Delete Client' message='Deleting this client will delete stops on all routes associated with this client. Are you sure you want to delete this client?' buttonLabel='Delete'/>
                </Modal>
            </>
        : 
            <>
                <Modal.Header closeButton>
                    <Modal.Title>Add Client(s)</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {clientList}
                    <Button variant="outline-primary" style={{marginTop: 12, marginLeft: 'auto', display: 'block'}} onClick={handleAddClient}><FontAwesomeIcon icon={faPlus}/> Add Client</Button>
                </Modal.Body>
                <Modal.Footer>
                    <CustomButton isLoading={isSubmitting} variant='outline-primary' stateObject={formObject} disabled={formObject['clientsToCreate'].length < 1} onClick={handleCreateClients}>Submit</CustomButton>
                </Modal.Footer>
            </>
        }
        </>
    )
}