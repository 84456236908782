/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import { validateBig } from "../payrollTools";
import { encodeDecimal, getFakeUID, toFixedMax } from "../../../../tools";
import moment from "moment";
import Big from "big.js";

export default class Pay {
    uid;
    payStartIndex;
    payEndIndex;
    payType;
    payRate;
    unitsWorked;

    constructor(uid, payStartIndex, payEndIndex, payType, payRate, unitsWorked) {
        this.uid = uid;
        this.payStartIndex = payStartIndex;
        this.payEndIndex = payEndIndex;
        this.payType = payType;
        this.payRate = payRate;
        this.unitsWorked = unitsWorked;
    }

    static decode(obj) {
        return new Pay(obj.uid ?? getFakeUID(), obj.payStartIndex, obj.payEndIndex, obj.payType, obj.payRate, toFixedMax(obj.unitsWorked, 5));
    }

    encode() {
        return {
            payStartIndex: this.payStartIndex,
            payEndIndex: this.payEndIndex,
            payType: this.payType,
            payRate: encodeDecimal(this.payRate, 6, 2, true),
            unitsWorked: ['py', 'sw'].includes(this.payType) ? null : encodeDecimal(this.unitsWorked, 3, 5, true),
        }
    }

    duplicate() {
        return new Pay(getFakeUID(), this.payStartIndex, this.payEndIndex, this.payType, this.payRate, this.unitsWorked);
    }

    getPayPeriodsPerYear() {
        return 52 / ((this.payEndIndex - this.payStartIndex + 1) / 7);
    }


    getStartDate(periodStart, weekIndex) {
        return moment(periodStart).add(weekIndex, 'weeks').add(this.payStartIndex, 'days').format('YYYY-MM-DD');
    }

    getEndDate(periodStart, weekIndex) {
        return moment(periodStart).add(weekIndex, 'weeks').add(this.payEndIndex, 'days').format('YYYY-MM-DD');
    }

    getWages(qualifiesForFLSA) {
        switch (this.payType) {
            case 'pd': case 'ph': case 'pm': case 'xd': //disabled if recieving OT
                return qualifiesForFLSA ? new Big('0.0') : validateBig(this.payRate).times(validateBig(this.unitsWorked));
            case 'py': //disabled if recieving OT
                return qualifiesForFLSA ? new Big('0.0') : validateBig(this.payRate).div(this.getPayPeriodsPerYear());
            case 'sw': case 'ot':
                return validateBig(this.payRate);
            default: //iw, nd, wd
                return validateBig(this.payRate).times(validateBig(this.unitsWorked));
        }
    }
}