/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, { useState } from "react";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { useEffect } from "react";
import QuickTable from "../../../../components/QuickTable.js";
import { Dropdown, ListGroup, Modal } from "react-bootstrap";
import DropdownItemWithCheck from "../../../../components/DropdownItemWithCheck.js";
import LoadingWrapper from "../../../../components/LoadingWrapper.js";
import { ApiRequest } from "../../../../ApiManager.tsx";
import { naturalSort } from "../../../../tools.js";
import VehicleEditorModal from "./VechicleEditorModal.js";
import SearchBar from "../../../../components/SearchBar.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import './MyCompanyVehicles.css'
import KeyValueRow from "../../../../components/KeyValueRow.js";

export default function MyCompanyVehicles({}) {
    const [showModal, setShowModal] = useState(false);
    const [selectedVehicle, setSelectedVehicle] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [vehicles, setVehicles] = useState([]);
    const [terminals, setTerminals] = useState([]);
    const [selectedTerminals, setSelectedTerminals] = useState([]);
    const [search, setSearch] = useState('');

    useEffect(() => {
        loadData();
    }, [])

    function loadData(){
        new ApiRequest('myCompany', 'getVehicles', setIsLoading, (response) => {
            setTerminals(response.terminals);
            setVehicles(naturalSort(response.vehicles, 'name'));
            setSelectedTerminals(response.terminals);
        }).withNoAlertOnSuccess().send();
    }

    function handleSelectTerminal(uid){
        let newArr = Array.from(selectedTerminals);
        if(newArr.find((t)=>t.uid === uid)){
            newArr = newArr.filter((t)=> t.uid !== uid);
        } else {
            newArr.push(terminals.find(t => t.uid === uid));
        }
        setSelectedTerminals(newArr);
    }

    const terminalDropdownMenu = (
        <Dropdown autoClose='outside'>
            <Dropdown.Toggle variant="outline-primary">
                Filter
            </Dropdown.Toggle>
            <Dropdown.Menu>
            <Dropdown.Header>Filter by Terminals</Dropdown.Header>
            {terminals.map((terminal) => {
                return <DropdownItemWithCheck key={terminal.uid} label={terminal.name} active={selectedTerminals.map(t => t.uid).includes(terminal.uid)} onClick={() => handleSelectTerminal(terminal.uid)} />
            })}
            </Dropdown.Menu>
        </Dropdown>
    )

    ///////////////////
    /// DESKTOP
    ///////////////////

    const vehicleRows = vehicles.filter(v => {
       return v.name.toLocaleLowerCase().includes(search.toLocaleLowerCase()) && selectedTerminals.map(t => t.uid).includes(v.terminalIdentifier)
    }).map((vehicle) => {
        return (
            <tr key={vehicle.uid} style={{cursor: 'pointer'}} onClick={() => {setSelectedVehicle(vehicle); setShowModal(true)}}>
                <td>{vehicle.name}</td>
                <td>{terminals.find(t=> t.uid === vehicle.terminalIdentifier).name}</td>
            </tr>
        )
    });

    ///////////////////
    /// MOBILE
    ///////////////////

    const mobileVehicleItems = vehicles.filter(v => {
        return v.name.toLocaleLowerCase().includes(search.toLocaleLowerCase()) && selectedTerminals.map(t => t.uid).includes(v.terminalIdentifier)
     }).map(vehicle => {
        return (
            <ListGroup.Item action key={vehicle.uid} onClick={() => {setSelectedVehicle(vehicle); setShowModal(true)}}>
                <h6 style={{fontWeight: 'bold'}}>{vehicle.name}</h6>
                <KeyValueRow title={'Terminal'} value={terminals.find(t=> t.uid === vehicle.terminalIdentifier).name}/>
            </ListGroup.Item>
        )
    })

    const content = (
        <div style={{height: '100%'}}>
            <div style={{display: 'flex', marginBottom: 12, justifyContent: 'flex-end', alignItems: 'center', gap: 6}}>
                 <div style={{display: 'flex', gap: 6}}>
                    {terminalDropdownMenu}
                </div>
                <div style={{display: 'flex', gap: 6}}>
                    <Button variant={'outline-primary'} onClick={() => {setSelectedVehicle(undefined); setShowModal(true);}}>Add Vehicle(s)</Button>
                </div>
            </div>
            <div style={{marginBottom: 12}}>
                <SearchBar label='Search by name' value={search} setValue={setSearch}/>
            </div>
            <div className="vehicle-list-desktop">
                <QuickTable hover headers={['Name', 'Terminal']} rows={vehicleRows}/>
            </div>
            <div className="vehicle-list-mobile">
                <ListGroup >
                    {mobileVehicleItems}
                </ListGroup>
            </div>
            <Modal size="lg" centered show={showModal} onHide={() => setShowModal(false)}>
                <VehicleEditorModal vehicles={vehicles} terminals={terminals} setVehicles={setVehicles} selectedVehicle={selectedVehicle} hideModal={() => setShowModal(false)}/>
            </Modal>
        </div>
    )

    return (
        <LoadingWrapper isLoading={isLoading}>
            {content}
        </LoadingWrapper>
    )
}