/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import Decoder from "../../../../decoding";
import StateObject from "../../../../state/StateObject.tsx";

export default class Vehicle extends StateObject{
    uid;
    terminalIdentifier;
    name;

    constructor(uid, terminalIdentifier, name){
        super();
        this.uid = uid;
        this.terminalIdentifier = terminalIdentifier;
        this.name = name;
    }

    static initDefault(){
        return new Vehicle(
            -1,
            -1,
            ''
        )
    }

    static decode(json){
        const decoder = new Decoder(json);

        const uid = decoder.decode('uid', Decoder.Uid);
        const terminalIdentifier = decoder.decode('terminalIdentifier', Decoder.Uid);
        const name = decoder.decode('name', Decoder.String);

        if(decoder.checkForErrors()){
            return new Vehicle(uid, terminalIdentifier, name);
        } else {
            return Vehicle.initDefault();
        }
    }

    encode(){
        return {
            uid: this.uid,
            terminalIdentifier: this.terminalIdentifier,
            name: this.name
        }
    }
}