/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import { Row } from "react-bootstrap"
import SwitchControl from "../../../../components/SwitchControl"
import CustomControl from "../../../../components/CustomControl"

export default function TimeClockSettings({settings, setSettingsKey, originalSettings}){
    return (
        <div style={{overflow: "hidden"}}>
            <Row style={{padding: 12}}>
                <SwitchControl
                    breakpoints={{lg: 12}}
                    title='Send employees a text reminder to clock in after their shift starts if they are not already clocked in'
                    originalValue={originalSettings.clockInReminder != null}
                    value={settings.clockInReminder != null}
                    setValue={() => setSettingsKey('clockInReminder', settings.clockInReminder || settings.clockInReminder === '08:00' ? null : '08:00')}
                />
                { settings.clockInReminder != null && 
                    <CustomControl
                        breakpoints={{lg: 12}}
                        type='time'
                        title='Time of day which clock-in reminders will be sent out to employees' 
                        originalValue={originalSettings.clockInReminder}
                        value={settings.clockInReminder}
                        setValue={(value) => setSettingsKey('clockInReminder', value)} 
                    />
                }
                <SwitchControl
                    breakpoints={{lg: 12}}
                    title='Enable Geofencing (require employees to be within radius of terminal to clock in)'
                    originalValue={originalSettings.enableGeofencing}
                    value={settings.enableGeofencing}
                    setValue={(value) => setSettingsKey('enableGeofencing', value)} 
                />
                <SwitchControl
                    breakpoints={{lg: 12}}
                    title='Include company address as a valid clock-in location for employees' 
                    originalValue={originalSettings.companyAddressAllowClockIn}
                    value={settings.companyAddressAllowClockIn} 
                    setValue={(value) => setSettingsKey('companyAddressAllowClockIn', value)}
                />
                { settings.companyAddressAllowClockIn &&
                    <CustomControl
                        max={1000000}
                        min={0}
                        breakpoints={{lg: 12}}
                        type='number' 
                        title='Company address clock-in radius (in miles)' 
                        originalValue={originalSettings.companyAddressAllowClockInRadius}
                        value={settings.companyAddressAllowClockInRadius} 
                        setValue={(value) => setSettingsKey('companyAddressAllowClockInRadius', value)}
                    />
                }
            </Row>
        </div>
    )
}