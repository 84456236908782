/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import { React, useState } from "react";
import { Card, Container, Row, Col } from "react-bootstrap";
import CustomControl from "../components/CustomControl";
import logo from '../assets/TTA-Logo-150-white.png'
import CustomButton from "../components/CustomButton";
import { ApiRequest } from "../ApiManager.tsx";
import { Validation, useStateWithValidation, useValidationReducer } from "../validation.tsx";
import { useHistory } from "react-router-dom";

export default function PasswordReset({match, history}) {
    const [newPassword, setNewPassword, newPasswordVM] = useStateWithValidation('', Validation.nonEmptyString);
    const [confirmPassword, setConfirmPassword, confirmPasswordVM] = useStateWithValidation('', Validation.nonEmptyString);
    const [isLoading, setIsLoading] = useState(false);


    const isValid = useValidationReducer([newPasswordVM, confirmPasswordVM]);
    const passwordResetToken = match.params.passwordResetToken;

    function handleSubmit(){
        new ApiRequest('passwordReset', 'resetPassword', setIsLoading, () => history.push('/')).withData({passwordResetToken, newPassword: confirmPassword}).send()
    }

    return(
        <div style={{display: 'flex', flexDirection: 'column', position: 'relative', justifyContent: 'center', alignItems: 'center', height: '100vh', width: '100%', padding: 8}}>
            <div className='login-background' style={{position: 'absolute', width: '100%', height: '100%', opacity: 0.03}}>
            </div>
            <Card style={{maxWidth: '100%', display: 'flex', flexDirection: 'row', width: 800, minHeight: 600, border: '1px solid var(--bs-primary)', borderRadius: 8}}>
                <Container fluid>
                    <Row style={{height: '100%'}}>
                        <Col md={6} style={{padding: 0}}>
                            <Card.Body className='login-lhs-card' style={{padding: 32, height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between', backgroundColor: 'var(--bs-primary)', textAlign: 'center'}}>
                                <img src={logo} style={{padding: 8, maxWidth: '100%'}}/>
                                <span style={{color: 'white', opacity: 0.9, fontWeight: 'bold'}}>A comprehensive solution to your delivery business needs</span>
                            </Card.Body>
                        </Col>
                        <Col md={6} style={{padding: 0}}> 
                            <Card.Body style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-around', gap: 12, height: '100%', padding: 32}}>
                                <div style={{textAlign: 'center'}}>
                                    <h1>Password Reset</h1>
                                    <span style={{color: 'gray'}}>Please enter and confirm your new password below:</span>
                                </div>
                                <div style={{display: 'flex', flexDirection: 'column', gap: 12}}>
                                    <CustomControl validate validationMessage={newPasswordVM} type='password' title='New Password' value={newPassword} setValue={setNewPassword}/>
                                    <CustomControl validate validationMessage={confirmPasswordVM} type='password' title='Confirm Password' value={confirmPassword} setValue={setConfirmPassword}/>
                                </div>
                                <div style={{display: 'flex', flexDirection: 'column', gap: 6, textAlign: 'center'}}>
                                    <CustomButton isLoading={isLoading} disabled={!isValid} outline label='Reset Password' onClick={handleSubmit}>Reset Password</CustomButton>
                                </div>
                            </Card.Body>
                        </Col>
                    </Row>
                </Container>
            </Card>
        
        </div>
    )
}