/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import { encodeDecimal, validateDecimal } from "../../../../tools";
import { getFakeUID } from "../../../../tools";

export default class Deduction {
    uid;
    type;
    amount;
    isReoccurring;
    isFromPay;

    constructor(uid, type, amount, isReoccurring, isFromPay = false) {
        this.uid = uid;
        this.type = type;
        this.amount = amount;
        this.isReoccurring = isReoccurring;
        this.isFromPay = isFromPay
    }

    static decode(obj) {
        return new Deduction(obj.uid ?? getFakeUID(), obj.type, obj.amount, !!obj.isReoccurring, !!obj.isFromPay);
    }

    encode() {
        return {
            type: this.type,
            amount: encodeDecimal(this.amount, 3, 2, true),
            isReoccurring: this.isReoccurring,
            isFromPay: this.isFromPay,
        }
    }

    duplicate() {
        return new Deduction(getFakeUID(), this.type, this.amount, this.isReoccurring);
    }

    getAmount() {
        return validateDecimal(this.amount);
    }

    isPercentage() {
        return this.type === '401K (% of Gross)' || this.type === 'Other (% of Gross)';
    }

    is401KPercentage() {
        return this.label === '401K (% of Gross)' || this.type === '401K (% of Gross)';
    }
    isOtherPercentage(){
        return this.label === 'Other (% of Gross)' || this.type === 'Other (% of Gross)';
    }

    static automaticDeductionTypes() {
        return [
            '401k (Flat Rate)',
            '401k (% of Gross)',
            'Other (Flat Rate)',
            'Other (% of Gross)'
        ]
    }
    
    static deductionTypes = [
        '401k (Flat Rate)',
        '401K (% of Gross)',
        'Garnishments',
        'Other (Flat Rate)',
        'Other (% of Gross)',
        'Advance',
    ]
}