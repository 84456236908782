/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import moment from "moment";
import { useEffect, useState } from "react";
import { Button, ButtonGroup, Col, Container, Dropdown, InputGroup, Modal, OverlayTrigger, Popover, Row } from "react-bootstrap";
import SchedulingDatePicker from "./SchedulingDatePicker.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faPencil, faPlus, faTrash, faTriangleExclamation, faUserTag, faUserTie, faWarehouse } from "@fortawesome/free-solid-svg-icons";
import { ApiRequest } from "../../../ApiManager.tsx";
import Terminal from "../MyCompany/Models/Terminal";
import Client from "../MyCompany/Models/Client";
import Vehicle from "../MyCompany/Models/Vehicle.js";
import Route from "./Models/Route.js";
import { getFakeUID, getStartOfWeek, stringTimeToInt } from "../../../tools.js";
import LoadingWrapper from "../../../components/LoadingWrapper.js";
import RouteEditor from "./RouteEditor/RouteEditor.js";
import AlertConfirmation from "../../../components/AlertModals/AlertConfirmation.js";
import PDFSchedule from "./PDFSchedule.js";
import { PDFViewer } from "@react-pdf/renderer";
import CustomDatePicker from "../../../components/CustomDatePicker/CustomDatePicker.js";
import SchedulingTemplateList from "./SchedulingTemplateList.js";
import CustomControl from "../../../components/CustomStateControls/CustomControl.js";
import { Validation } from "../../../validation.tsx";
import CustomButton from "../../../components/CustomButton.js";
import './Scheduling.css';
import ManagerOnDutyEditor from "./ManagerOnDutyEditor.js";
import SwitchControl from "../../../components/SwitchControl.js";

export default function Scheduling({}){
    const [isLoading, setIsLoading] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [datePointer, setDatePointer] = useState(moment().day() == 6 ? moment() : moment().startOf('week').subtract(1, 'days'));
    const [selectedDate, setSelectedDate] = useState(moment().format('YYYY-MM-DD'));
    const [modalSwitch, setModalSwitch] = useState('');
    const [showRouteEditor, setShowRouteEditor] = useState(false);
    const [selectedRoute, setSelectedRoute] = useState(null);
    const [routes, setRoutes] = useState([]);
    const [templateRoutes, setTemplateRoutes] = useState([]);
    const [clients, setClients] = useState([]);
    const [terminals, setTerminals] = useState([]);
    const [users, setUsers] = useState([]);
    const [vehicles, setVehicles] = useState([]);
    const [startDate, setStartDate] = useState(moment(datePointer).format('YYYY-MM-DD'));
    const [endDate, setEndDate] = useState(moment(datePointer).add(6, 'days').format('YYYY-MM-DD'));
    const [selectedTemplate, setSelectedTemplate] = useState(null);
    const [templateName, setTemplateName] = useState('');
    const [templateMode, setTemplateMode] = useState(false);
    const [managersOnDuty, setManagersOnDuty] = useState([]);
    const [selectedManagerOnDuty, setSelectedManagerOnDuty] = useState(null);
    const [showOnlyConflicts, setShowOnlyConflicts] = useState(false);
    const dateString = `${moment(datePointer).format('MMM Do')} - ${moment(datePointer).add(6, 'days').format('MMM Do')}`;


    useEffect(() => {
        loadData();
    }, [])

    function loadData(startOfWeek = getStartOfWeek().format('YYYY-MM-DD')) {
        new ApiRequest('scheduling', 'get', setIsLoading, (response) => {
            setDatePointer(moment(startOfWeek));
            setClients(response.clients.map(c => Client.decode(c)));
            setTerminals(response.terminals.map(t => Terminal.decode(t)));
            setVehicles(response.vehicles.map(v => Vehicle.decode(v)));
            setUsers(response.employees);
            setRoutes(response.routes.map(r => Route.decode(r)));
            setManagersOnDuty(response.managersOnDuty)
        }).withData({startDate: startOfWeek, endDate: moment(startOfWeek).add(6, 'days').format('YYYY-MM-DD')}).withNoAlertOnSuccess().send()
    }

    function deleteAllRouteForWeek(){
        const endDate = moment(datePointer).add(6, 'days').format('YYYY-MM-DD');
        new ApiRequest('scheduling', 'deleteAllRouteForWeek', setIsDeleting, () => {
            setRoutes([]);
            setManagersOnDuty([]);
            setShowRouteEditor(false);
            setSelectedRoute(null);
            hideModal();
        }).withData({startDate: datePointer.format('YYYY-MM-DD'), endDate: endDate}).send()
    }

    function createTemplate(){
        new ApiRequest('scheduling', 'createTemplate', setIsSubmitting, (response) => {
            const newTemplate = {};
            newTemplate.name = templateName;
            newTemplate.uid = response.uid;
            newTemplate.lastModified = moment().format('YYYY-MM=DD');
            setSelectedTemplate(newTemplate)
            setTemplateMode(true);
            setTemplateName('');
            setTemplateRoutes([])
            setShowRouteEditor(false);
            hideModal();
        }).withData({name: templateName}).send()
    }

    function createTemplateFromWeek(){
        new ApiRequest('scheduling', 'createTemplateFromWeek', setIsSubmitting, () => {setTemplateName(''); hideModal()}).withData({name: templateName, routes: routes.map(r => r.encode())}).send()
    }

    function updateTemplateName(){
        new ApiRequest('scheduling', 'updateTemplateName', setIsSubmitting, () => {
            setSelectedTemplate({...selectedTemplate, name: templateName})
            hideModal();
        }).withData({uid: selectedTemplate.uid, name: templateName}).send();
    }

    function handleRoutesCrud(type, value, isTemplate){ 
        let newRoutes = isTemplate ? Array.from(templateRoutes) : Array.from(routes);
        switch(type){
            case 'create':
                newRoutes.unshift(value);
                setSelectedRoute(value);
                break;
            case 'update':
                const routeToUpdateIndex = newRoutes.findIndex(r => r.uid === value.uid);
                newRoutes[routeToUpdateIndex] = value;
                break;
            case 'delete':
                newRoutes = newRoutes.filter(r => r.uid !== value);
                setSelectedRoute(null);
                setShowRouteEditor(false);
                break;
        }
        isTemplate ? setTemplateRoutes(newRoutes) : setRoutes(newRoutes);
    }

    function handleManagerOnDutyCrud(type, value){
        let newMods = Array.from(managersOnDuty);
        switch(type){
            case 'create':
                newMods.push(value);
                break;
            case 'update':
                const modToUpdateIndex = newMods.findIndex(m => m.uid === value.uid);
                newMods[modToUpdateIndex] = value;
                break;
            case 'delete':
                newMods = newMods.filter(m => m.uid !== value);
                break;
        }
        setManagersOnDuty(newMods);
        setModalSwitch(''); 
    }

    function hideModal(){
        setModalSwitch('');
    }

    const datePickerCard = (
        <Button variant={'outline-primary'} style={{padding: '6px 10px'}} onClick={() => setModalSwitch('datePicker')}>
            {datePointer.format('YYYY') + ` | ${dateString}`}
        </Button>
    );

    const toolsDropdownMenu = (
        <Dropdown>
            <Dropdown.Toggle variant="outline-primary">
                {<FontAwesomeIcon icon={faBars}/>}
            </Dropdown.Toggle>
            <Dropdown.Menu>
                <Dropdown.Header>Templates</Dropdown.Header>
                <Dropdown.Item onClick={() => setModalSwitch('viewTemplates')}>View Templates</Dropdown.Item>
                {!templateMode && <Dropdown.Item onClick={() => setModalSwitch('createTemplate')}>Create New Template</Dropdown.Item>}
                {!templateMode && <Dropdown.Item onClick={() => setModalSwitch('createTemplateFromWeek')}>Create Template (Current Week)</Dropdown.Item>}
                {templateMode && <Dropdown.Item onClick={() => {setTemplateName(selectedTemplate.name);setModalSwitch('templateNameEditor')}}>Edit Template Name</Dropdown.Item>}
                {templateMode && <Dropdown.Item onClick={() => {setTemplateMode(false); setSelectedTemplate(null); setTemplateRoutes([]); setShowRouteEditor(false)}}>Exit Template Viewer/Editor</Dropdown.Item>}
                <Dropdown.Divider/>
                {!templateMode &&
                    <>
                    <Dropdown.Header>Routes</Dropdown.Header>
                    <Dropdown.Item onClick={() => setModalSwitch('deleteAllRoutes')}>Delete All Routes</Dropdown.Item>
                    <Dropdown.Divider/> 
                    </>
                }
                <Dropdown.Header>PDF</Dropdown.Header>
                <Dropdown.Item onClick={() => setModalSwitch('previewSchedule')}>Preview/Download Schedule</Dropdown.Item>
            </Dropdown.Menu>
        </Dropdown>
    );

    const weekDays = ['Saturday', 'Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'];
    const weekdaysButtonGroup = (
        <ButtonGroup style={{flex: 1}}>
            {weekDays.map((d, i) => {
                return <Button key={d} style={moment(selectedDate).day() === (i === 0 ? 6 : i-1)%7 ? {color: 'white'} : {color: 'var(--bs-primary)', backgroundColor: 'white'}} onClick={() =>
                     {
                         const startOfWeek = moment(datePointer);
                         setSelectedDate(startOfWeek.add(i, 'days').format('YYYY-MM-DD'));
                         setSelectedRoute(null);
                         setShowRouteEditor(false);
                     }}
                >{templateMode ? moment(datePointer).add(i, 'days').format('dddd') : moment(datePointer).add(i, 'days').format('dddd Do')}</Button>
            })}
        </ButtonGroup>
    )

    /////////////////////
    /// Mobile
    /////////////////////

    const mobileWeekdaysButtonGroup = (
        <ButtonGroup style={{flex: 1}}>
            {weekDays.map((d, i) => {
                return <Button key={d} style={moment(selectedDate).day() === (i === 0 ? 6 : i-1)%7 ? {color: 'white'} : {color: 'var(--bs-primary)', backgroundColor: 'white'}} onClick={() =>
                     {
                         const startOfWeek = moment(datePointer);
                         setSelectedDate(startOfWeek.add(i, 'days').format('YYYY-MM-DD'));
                         setSelectedRoute(null);
                         setShowRouteEditor(false);
                     }}
                >{templateMode ? moment(datePointer).add(i, 'days').format('dd') : moment(datePointer).add(i, 'days').format('dd D')}</Button>
            })}
        </ButtonGroup>
    )

    const routesType = templateMode ? templateRoutes : routes;
    const routesForDay = routesType.filter(route=> moment(route.date).isSame(moment(selectedDate)));

    routesForDay.forEach(route => {
        route.conflicts = getConflicts(route, routesForDay, [], selectedDate, templateMode)
    })

    return (
        <LoadingWrapper isLoading={isLoading}>
            <div style={{display: 'flex', flex: 1, flexDirection: 'column', overflowX: "hidden"}}>
                {!showRouteEditor &&
                    <div>
                        {templateMode && 
                            <div>
                                <h5 style={{color: 'red', opacity: .5, width: '100%', margin: 0, textAlign: 'center', paddingTop: 18, marginBottom: 8}}>Viewing / Editing Template: {selectedTemplate.name}</h5>
                            </div>
                        }   
                        <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding:  templateMode ? '8px 18px 0 18px' : '18px 18px 0 18px', gap: 12, flexWrap: 'wrap'}}>
                            <h2 style={{margin: 0, fontWeight: 'bold'}}>Scheduling</h2>
                            <div style={{marginLeft: 'auto'}}>
                                <InputGroup style={{justifyContent: 'right'}}>
                                    {!templateMode && datePickerCard}
                                    {toolsDropdownMenu}
                                </InputGroup>
                            </div>
                        </div>
                        <div className="desktop-scheduling-button-group" style={{justifyContent: 'center', marginTop: 16, padding: '0 18px'}}>
                            {weekdaysButtonGroup}
                        </div>
                        <div className="mobile-scheduling-button-group" style={{justifyContent: 'center', marginTop: 16, padding: '0 18px'}}>
                            {mobileWeekdaysButtonGroup}
                        </div>
                        <hr/>
                        {!templateMode && 
                            <div style={{padding: '0 20px'}}>
                                <div style={{display: 'flex', justifyContent: 'space-between', gap: 12, alignItems: 'center', marginBottom: 12, flexWrap: 'wrap'}}>
                                    <h4 style={{margin: 0}}>Managers On Duty</h4>
                                    <Button onClick={() => {setSelectedManagerOnDuty(null); setModalSwitch('managerOnDuty')}} variant="outline-primary">Add Manager On Duty</Button>
                                </div>
                                <div style={{display: 'flex', padding: '0 18px', gap: 16, flexWrap: 'wrap'}}>
                                    {managersOnDuty.filter(mod => mod.date === selectedDate).map(mod => {
                                        const manager = users.find(u => u.companyUserIdentifier === mod.companyUserIdentifier);
                                        const name = `${manager.firstName} ${manager.lastName}`
                                        const terminaName = terminals.find(t => t.uid === mod.terminalIdentifier)?.name;
                                        return (
                                            <div key={mod.uid} style={{display: 'flex', justifyContent: 'space-between', background: 'white', padding: 8, gap: 24, borderRadius: 6, border: '1px solid gray', minWidth: 300}}>
                                                <div>
                                                    <p style={{margin: 0}}><FontAwesomeIcon color="var(--bs-primary)" style={{width: 14, height: 14}} icon={faUserTie}/> &nbsp;{name}</p>
                                                    <p style={{margin: 0}}><FontAwesomeIcon color="var(--bs-primary)" style={{width: 14, height: 14}} icon={faWarehouse}/> &nbsp;{terminaName}</p>
                                                </div>
                                                <Button onClick={() => {setModalSwitch('managerOnDuty'); setSelectedManagerOnDuty(mod)}}>
                                                    <FontAwesomeIcon style={{cursor: 'pointer', color: 'white'}} icon={faPencil}/>
                                                </Button>
                                            </div>
                                        )
                                    })}
                                </div>
                                <hr/>
                            </div>
                        }
                    </div>
                }
                {showRouteEditor ?
                    <RouteEditor 
                        selectedRoute={selectedRoute} 
                        terminals={terminals} clients={clients}
                        users={users} vehicles={vehicles} 
                        routesForDay={routesForDay} 
                        selectedDate={selectedDate} 
                        templateMode={templateMode}
                        selectedTemplate={selectedTemplate}
                        setSelectedRoute={setSelectedRoute} 
                        setShowRouteEditor={setShowRouteEditor} 
                        handleRoutesCrud={handleRoutesCrud}
                    />
                :
                    <>
                        <div style={{display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap', alignItems: 'center', gap: 12, padding: '4px 20px 16px 20px'}}>
                            <h4 style={{margin: 0}}>Routes</h4>
                            <div style={{display: 'flex', gap: 8, alignItems: 'center'}}>
                                <SwitchControl style={{minHeight: undefined, backgroundColor: undefined, color: 'var(--bs-primary)', border: '1px solid var(--bs-primary)', padding: 6}} value={showOnlyConflicts} setValue={setShowOnlyConflicts} title='Only conflicts'/>
                                <Button onClick={() => setShowRouteEditor(true)} style={{ display: 'block', padding: 6}} variant="outline-primary">
                                    Add New Route
                                </Button>
                            </div>
                        </div>
                        <Row style={{padding: '0 18px', flex: 1}}>
                            {routesForDay.filter(route => !showOnlyConflicts ? true : (route.conflicts.user.length || route.conflicts.vehicle.length)).map((route, index) => <RouteCardElement key={route.uid} index={index} setShowRouteEditor={setShowRouteEditor} setSelectedRoute={setSelectedRoute} route={route} routesForDay={routesForDay} users={users}/>)}
                        </Row>
                    </>
                }
            </div>
            <Modal show={modalSwitch == 'datePicker'} onHide={hideModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Select Week</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <SchedulingDatePicker
                        onSubmit={(startOfWeek) => {setSelectedDate(moment(startOfWeek).format('YYYY-MM-DD')); loadData(startOfWeek);}} 
                        hideModal={hideModal} 
                        date={datePointer}
                    />
                </Modal.Body>
            </Modal>
            <Modal size='lg' centered show={modalSwitch === 'managerOnDuty'} onHide={() => {setModalSwitch(''); setSelectedManagerOnDuty(null)}}>
                <ManagerOnDutyEditor selectedManagerOnDuty={selectedManagerOnDuty} selectedDate={selectedDate} handleManagerOnDutyCrud={handleManagerOnDutyCrud} users={users} terminals={terminals}/>
            </Modal>
            <Modal size="lg" centered show={modalSwitch === 'viewTemplates'} onHide={hideModal}>
                <SchedulingTemplateList setTemplateMode={setTemplateMode} hideModal={hideModal} setTemplateRoutes={setTemplateRoutes} selectedTemplate={selectedTemplate} setShowRouteEditor={setShowRouteEditor} setSelectedTemplate={setSelectedTemplate} setRoutes={setRoutes} startDate={datePointer}/>
            </Modal>
            <Modal centered show={modalSwitch === 'createTemplate'} onHide={hideModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Create Template</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <CustomControl floatingLabel label={'Template Name'} value={templateName} setValue={setTemplateName} maxLength={30} validation={Validation.nonEmptyString}/>
                </Modal.Body>
                <Modal.Footer>
                    <CustomButton disabled={Validation.nonEmptyString(templateName)} isLoading={isSubmitting} onClick={createTemplate}>Submit</CustomButton>
                </Modal.Footer>
            </Modal>
            <Modal centered show={modalSwitch === 'createTemplateFromWeek'} onHide={hideModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Create Template From Week</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <CustomControl floatingLabel label={'Template Name'} value={templateName} setValue={setTemplateName} maxLength={30} validation={Validation.nonEmptyString}/>
                </Modal.Body>
                <Modal.Footer>
                    <CustomButton disabled={Validation.nonEmptyString(templateName)} isLoading={isSubmitting} onClick={createTemplateFromWeek}>Submit</CustomButton>
                </Modal.Footer>
            </Modal>
            <Modal centered show={modalSwitch === 'templateNameEditor'} onHide={() => {hideModal(); setTemplateName('')}}>
                <Modal.Header closeButton>
                    <Modal.Title>Edit Template Name</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <CustomControl floatingLabel label='Name' maxLength={30} validation={Validation.nonEmptyString} value={templateName} setValue={setTemplateName}/>
                </Modal.Body>
                <Modal.Footer>
                    <CustomButton onClick={updateTemplateName} disabled={Validation.nonEmptyString(templateName)}>Submit</CustomButton>
                </Modal.Footer>
            </Modal>
            <Modal centered show={modalSwitch === 'deleteAllRoutes'} onHide={hideModal}>
                <AlertConfirmation isLoading={isDeleting} callBack={deleteAllRouteForWeek} variant='outline-danger' title='Delete All Routes' buttonLabel='Delete' message='Are you sure you want to delete all routes for this week? This will also delete all manager on duty for the week.'/>
            </Modal>
            <Modal fullscreen show={modalSwitch === 'previewSchedule'} onHide={hideModal}>
                <Modal.Header style={{justifyContent: 'center', display: 'flex'}} closeButton>
                    <Modal.Title>Preview Schedule</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{overflowY: 'hidden'}}>
                    <div style={{marginBottom: 12}}>
                        <CustomDatePicker 
                            title='Date Range' 
                            minDate={datePointer.format('YYYY-MM-DD')} 
                            maxDate={moment(datePointer).add(6, 'days').format('YYYY-MM-DD')} 
                            startDate={startDate} 
                            setStartDate={setStartDate} 
                            endDate={endDate} 
                            setEndDate={setEndDate}
                        />
                    </div>
                    <PDFViewer style={{width: '100%', height: '100%'}}>
                        <PDFSchedule routes={routesType} startDate={startDate} endDate={endDate}/>
                    </PDFViewer>
                </Modal.Body>
            </Modal>
        </LoadingWrapper>
    )
}

function RouteCardElement({route, index, setSelectedRoute, setShowRouteEditor}){

    const middleName = route?.user?.middleName ? `${route.user?.middleName} ` : ''
    const name = `${route?.user?.firstName} ${middleName}${route?.user?.lastName}`
    const firstStopStartTime = `${route.date} ${route.stops?.[0]?.startTime}`;
    const lastStopEndTime = `${route.date} ${route.stops?.[route.stops.length-1]?.endTime}`;

    const userConflictSeverity = route.conflicts.user.reduce((accumulator, currentValue) => Math.max(accumulator, currentValue.severity), 0);
    const userConflictListItems = route.conflicts.user.map((element, index) => {
        return (
            <div key={index} style={{display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 8}}>
                <FontAwesomeIcon icon={faTriangleExclamation} style={{color: element.severity === 0 ? 'lightgreen' : element.severity === 1 ? 'yellow' : element.severity === 2 ? 'orange' : 'red'}}/>
                <p style={{margin: 0,}}>{element.message}</p>
            </div>
        )
    });

    const vehicleConflictSeverity = route.conflicts.vehicle.reduce((accumulator, currentValue) => Math.max(accumulator, currentValue.severity), 0);
    const vehicleConflictListItems = route.conflicts.vehicle.map((element, index) => {
        return (
            <div key={index} style={{display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 8}}>
                <FontAwesomeIcon icon={faTriangleExclamation} style={{color: element.severity === 0 ? 'lightgreen' : element.severity === 1 ? 'yellow' : element.severity === 2 ? 'orange' : 'red'}}/>
                <p style={{margin: 0,}}>{element.message}</p>
            </div>
        )
    });

    const userPopover = (
        <Popover style={{position:'fixed'}}>
            <Popover.Header></Popover.Header>
            <Popover.Body>
                {userConflictListItems?.length > 0 ? userConflictListItems : <></>}
            </Popover.Body>
        </Popover>
    )

    const vehiclePopover = (
        <Popover style={{position:'fixed'}}>
            <Popover.Header></Popover.Header>
            <Popover.Body>
                {vehicleConflictListItems?.length > 0 ? vehicleConflictListItems : <></>}
            </Popover.Body>
        </Popover>
    )

    return (
        <Col md={12} lg={6} xl={4} style={{marginBottom: 36}}>
            <div style={{backgroundColor: 'white'}}>
                <div style={{height: 40, display: 'flex', alignItems: 'center', padding: '0 12px', border: '1px solid gray', justifyContent: 'space-between', fontSize: 16}}>
                    <b>Route #{index+ 1}</b>
                    <div>
                        <Button onClick={() => {setSelectedRoute(route); setShowRouteEditor(true)}} style={{width: 28, height: 28, display: "flex", alignItems: 'center', justifyContent: 'center'}}><FontAwesomeIcon style={{color: "white"}} icon={faPencil}/></Button>
                    </div>
                </div>
                <div style={{height: 40, display: 'flex', alignItems: 'center', padding: '0 12px', border: '1px solid gray', gap: 8, fontSize: 14}}>
                    <b>Driver:</b> 
                    <span>{route?.user ? name : 'None'}</span>
                    {!route.conflicts.user.length ? <></> :
                        <OverlayTrigger placement='top' overlay={userPopover}>
                            <FontAwesomeIcon icon={faTriangleExclamation} style={{color: userConflictSeverity === 1 ? 'gold' : userConflictSeverity === 2 ? 'orange' : 'red', marginRight: 0}}/>
                        </OverlayTrigger>
                    }
                </div>
                <div style={{height: 40, display: 'flex', alignItems: 'center', padding: '0 12px', border: '1px solid gray', gap: 8, fontSize: 14}}>
                    <b>Truck:</b>
                    <span>{route?.vehicle ? route.vehicle.name : 'None'}</span>
                    {!route.conflicts.vehicle.length ? <></> :
                        <OverlayTrigger placement='top' overlay={vehiclePopover}>
                            <FontAwesomeIcon icon={faTriangleExclamation} style={{color: vehicleConflictSeverity === 1 ? 'gold' : vehicleConflictSeverity === 2 ? 'orange'  : 'red', marginRight: 0}}/>
                        </OverlayTrigger>
                    }
                </div>
                <div style={{display: "flex", backgroundColor: 'gold'}}>
                    <div className='route-card-stop-header' style={{justifyContent: 'left', flex: 3, paddingLeft: 12}}>Run</div>
                    <div className="route-card-stop-header">Time In</div>
                    <div className="route-card-stop-header">Time Out</div>
                </div>
                <div>
                    {route.stops.map(stop => {
                        const startTime = moment(`${route.date} ${stop.startTime}`).format('LT');
                        const endTime = moment(`${route.date} ${stop.endTime}`).format('LT');
                        
                        return (
                            <div key={getFakeUID()} style={{display: "flex", backgroundColor: 'white'}}>
                                <div className='route-card-stop-row' style={{justifyContent: 'left', flex: 3, paddingLeft: 12}}>{stop.name}</div>
                                <div className="route-card-stop-row">{startTime}</div>
                                <div className="route-card-stop-row">{endTime}</div>
                            </div>
                        )
                    })}
                </div>
                <div style={{height: 40, display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '0 12px', border: '1px solid gray', gap: 8, fontSize: 14}}>
                    <b>Total Hours:</b> 
                    <span>{moment(lastStopEndTime).diff(moment(firstStopStartTime), 'minutes')/60}</span>
                </div>
            </div>
        </Col>
    )
}

export function getConflicts(route, routesForDay, stemTimes, selectedDate, isTemplate){
    const conflicts = {user: [], vehicle: [], stops: [], dateOverflow: []};

    if(route.user){
        // availability conflict
        if(!parseInt(route.user.availability[(moment(selectedDate).day() + 1)%7])){
            conflicts.user.push({
                severity: 1,
                message: 'Current day falls ouside of user\'s availability.',
            })
        }

        //terminated conflict
        if(!route.user.title){
            if(isTemplate || moment(selectedDate).isSameOrAfter(moment())){
                conflicts.user.push({
                    severity: 3,
                    message: 'User is terminated.',
                })
            }else{
                conflicts.user.push({
                    severity: 1,
                    message: 'User is terminated (Historic).',
                })
            }
        }

        // timeOff conflict
        if(!isTemplate){
            let selectedTimeOff = route?.user?.timeOff?.find(to => moment(route.date).isBetween(moment(to.startDate), moment(to.endDate), undefined, '[]') && to.status !== -1)
            if(selectedTimeOff){
                const timeOffString = selectedTimeOff.isPto ? 'paid time-off' : selectedTimeOff.status === 1 ? 'unpaid time-off' : 'a pending time-off request';
                conflicts.user.push({
                    severity: 2,
                    message: `This user has ${timeOffString} on this date.`
                })
            }
        }

        // user already scheduled conflict
        if(routesForDay.reduce((acc, el) => {if(el.user.companyUserIdentifier === route?.user?.companyUserIdentifier && el.uid !== route.uid){return acc+1}return acc}, 0) > 0){
            conflicts.user.push({
                severity: 2,
                message: 'This user is already scheduled for this date on another route.'
            })
        }
    }else{
        conflicts.user.push({
            severity: 3,
            message: 'A driver is required.',
        })
    }

    if(route.vehicle){
         // vehicle already scheduled conflict
         if(routesForDay.reduce((acc, el) => {if(el?.vehicle?.uid == route?.vehicle?.uid && el.uid !== route.uid){return acc+1}return acc}, 0) > 0){
            conflicts.vehicle.push({
                severity: 2,
                message: 'This vehicle is already scheduled for this date on another route.'
            })
        }

    }else{
        conflicts.vehicle.push({
            severity: 3,
            message: 'A vehicle is required.',
        })
    }
    

    for(let i = 0; i < route.stops.length; i++){
        conflicts.stops.push([]);
        if((route.stops[i].startTime && route.stops[i].endTime) && stringTimeToInt(route.stops[i].startTime) > stringTimeToInt(route.stops[i].endTime)){
            conflicts.stops[i].push({
                severity: 3,
                message: 'Time-In must not come after Time-Out.',
            }) 
        }

        if(route.stops[0]?.startTime > route.stops[i]?.startTime){
            conflicts.dateOverflow.push({
                severity: 3,
                message: 'Final Time-Out is on the next day. Two day shifts are not supported.',
            })
        }

        if(i !== 0 ){
            if((route.stops[i].startTime && route.stops[i-1].endTime)){
                // Use existing stem time in state or set to 15
                const stemTime = stemTimes.find(s => (s.startAddressIdentifier === route.stops[i-1].address.uid && s.endAddressIdentifier === route.stops[i].address.uid))?.stemTime ?? 0;
                if(stringTimeToInt(route.stops[i-1].endTime) > stringTimeToInt(route.stops[i].startTime)){
                    conflicts.stops[i].push({
                        severity: 3,
                        message: 'This Time-In cannot come before the previous stop\'s Time-Out.',
                    })
                } else if(stringTimeToInt(route.stops[i-1].endTime) > stringTimeToInt(route.stops[i].startTime) - stemTime){
                    conflicts.stops[i].push({
                        severity: 1,
                        message: `This Time-In may be too early, based on the previous stop\'s Time-Out and the calculated stem time which is currently ${stemTime} ${stemTime === 1 ? 'minute' : 'minutes'}.`,
                    })
                }    
            }

            if(route.stops[i].address.uid === route.stops[i-1].address.uid){
                conflicts.stops[i].push({
                    severity: 2,
                    message: 'This is the same stop as the previous stop.',
                })
            }
        }

        // if(route.stops.length > 1 && route.stops.length !== i -1){
            if(!route.stops[i].startTime){
                conflicts.stops[i].push({
                    severity: 3,
                    message: 'This stop requires a Time-In.',
                })
            }
            if(!route.stops[i].endTime){
                conflicts.stops[i].push({
                    severity: 3,
                    message: 'This stop requires a Time-Out.',
                })
            }
    }
    return conflicts;
}