/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Button } from "react-bootstrap";

export default function PayrollEntryPaySection({title, children, showModal}) {
    return (
        <div>
            <div style={{display: 'flex', gap: 6, marginBottom: 6, alignItems: 'center'}}>
                <span style={{opacity: 0.5, fontWeight: 'bold', fontSize: 14}}>{title}</span>
                { showModal && 
                    <Button style={{color: 'white', borderRadius: 14, padding: '0px 5px 0px 5px', fontSize: 14}} onClick={showModal}>
                        <FontAwesomeIcon icon={faPlus} style={{color: 'white'}}/>
                    </Button>
                }
            </div>
            <div style={{borderLeft: '1px solid lightgray', paddingLeft: 6, marginLeft: 6}}>
                {children}
            </div>
        </div>
    )
}