/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import { useEffect, useState } from "react";
import { Button, Form, InputGroup, Modal } from "react-bootstrap";
import { downloadBase64 } from "../../../../tools";
import LoadingWrapper from "../../../../components/LoadingWrapper";
import heic2any from "heic2any";

export default function OldAttachmentPreview({document}) {
    const [isLoading, setIsLoading] = useState(true);
    const [zoom, setZoom] = useState(1);
    const [base64, setBase64] = useState(undefined);

    useEffect(() => {
        handleDownloadOldAttachment();
    }, []);

    const handleDownloadOldAttachment = async () => {
        setIsLoading(true);
        if (document.base64.includes('image/heic')) {
            const fetchResponse = await fetch(document.base64);
            const fblob = await fetchResponse.blob();
            const valueToRead = await heic2any({blob: fblob, toType: 'image/jpeg', quality: 0.5});
            const fileReaderResult = await new Promise((resolve, reject) => {
                var reader = new FileReader();
                reader.onloadend = () => {
                    resolve(reader.result);
                }
                reader.readAsDataURL(valueToRead); 
            })
            setBase64(fileReaderResult);
        } else {
            setBase64(document.base64);
        }
        setIsLoading(false);
    }

    return (
        <>
            <Modal.Header closeButton>
                <div style={{display: 'flex', flexWrap: 'wrap', gap: 12}}>
                    <InputGroup style={{width: 'min-content', flexWrap: 'nowrap'}}>
                        <InputGroup.Text>Zoom</InputGroup.Text>
                        <InputGroup.Text>
                            <Form.Range style={{width: 100}} value={zoom} onChange={(event) => {setZoom(event.target.value)}} min={0.1} max={3} step={0.1}/>
                        </InputGroup.Text>
                        <InputGroup.Text style={{width: 68}}>{`${parseInt(zoom * 100)}%`}</InputGroup.Text>
                    </InputGroup>
                    <Button variant="outline-primary" onClick={() => {downloadBase64(document.base64, document.title)}}>Download</Button>
                </div>
            </Modal.Header>
            <Modal.Body style={{overflow: 'auto', zoom: zoom, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                <LoadingWrapper isLoading={isLoading}>
                    { base64 && 
                        <img src={base64}/>
                    }
                </LoadingWrapper>
            </Modal.Body>
        </>
    )
}