/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, { useState } from 'react';
import Form from 'react-bootstrap/Form';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import OptionalCol from '../../../../components/OptionalCol';
import { encodeDecimal, usdFormatter, validateDecimal } from '../../../../tools';
import { InputGroup } from 'react-bootstrap';

export default function PayrollMoneyControl({title, value, setValue, lhDecimalPlaces, breakpoints, validate, validationMessage, ...props}) {
    const [isFocused, setIsFocused] = useState(false);
    const [localValue, setLocalValue] = useState(value);

    const validateValue = () => {
        let newValue = encodeDecimal(localValue, lhDecimalPlaces, 2, true).toString();
        setValue(newValue);
        setLocalValue(newValue);
    }

    const isInvalid = validate ? validationMessage != undefined : undefined;
    const isValid = validate ? validationMessage == undefined : undefined;

    const displayValue = usdFormatter.format(value);

    const control = (
        <Form.Control type={isFocused ? 'number' : 'text'} style={{lineHeight: 1}} onKeyDown={(e) => {if (e.key == 'Enter') {validateValue()}}} isInvalid={isInvalid} isValid={isValid} value={isFocused ? localValue : displayValue} onFocus={() => {setIsFocused(true)}} onBlur={() => {setIsFocused(false); validateValue();}} onChange={(e) => {setLocalValue(e.target.value)}} {...props}/>
    )

    return (
        <OptionalCol breakpoints={breakpoints}>
            { title ? 
                <FloatingLabel label={title}>{control}</FloatingLabel>
            : control
            }
        </OptionalCol>
    )
}