/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, { useState } from "react";
import Modal from 'react-bootstrap/Modal';
import CustomButton from "../../../../components/CustomButton.js";
import { ApiRequest } from "../../../../ApiManager.tsx";
import moment from "moment";
import { getPayrollVersionType } from "../payrollTools.js";

export default function SendBackToCompanyModal({payrollVersion, payrollPeriod, callback}) {
    const [isSubmitting, setIsSubmitting] = useState(false);

    function handleSubmit() {
        new ApiRequest('payroll', 'sendBackToCompany', setIsSubmitting, () => {
            callback();
        }).withData({payrollVersionIdentifier: payrollVersion.uid}).send()
    }

    const type = getPayrollVersionType(payrollVersion, payrollPeriod);

    return (
        <>
            <Modal.Header closeButton>
                <Modal.Title>{`Send Payroll Version back to Company?`}</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{display: 'flex', flexDirection: 'column', gap: 12, overflow: 'hidden'}}>
                <div style={{border: '1px solid lightgray', borderRadius: 6, padding: 4}}>
                    <div style={{display:'flex', justifyContent:'space-between', gap: 12}}>
                        <span>
                            {
                                payrollVersion.companyUserIdentifier ? `${payrollVersion.user.firstName} ${payrollVersion.user.middleName ? `${payrollVersion.user.middleName.substring(0, 1)}. ` : ''}${payrollVersion.user.lastName}` 
                                : payrollVersion.adminIdentifier ? 'TTA Admin'
                                : 'TTA Linehaul'
                            }
                        </span>
                        <span style={{whiteSpace: 'nowrap'}}>{type}</span>
                    </div>
                    <div style={{display:'flex', justifyContent:'space-between', gap: 12, fontSize: 12}}>
                        <span>{payrollVersion.terminalName ?? 'All Terminals'}</span>
                        <span>{moment(payrollVersion.dateTime).format('MMM D [at] h:mm:ss A')}</span>
                    </div>
                </div>
            
                This will delete any payroll versions which were created by TTA and then create a new editable version using the payroll entries from the selected payroll version
            </Modal.Body>
            <Modal.Footer>
                <CustomButton isLoading={isSubmitting} onClick={handleSubmit}>Submit</CustomButton>
            </Modal.Footer>
        </>
    )
}

