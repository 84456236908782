import React, { useState } from "react"
import { Modal} from "react-bootstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons"
import CustomControl from "../../components/CustomControl"
import CustomButton from "../../components/CustomButton"
import { ApiRequest } from "../../ApiManager.tsx"
import { Validation, useStateWithValidation } from "../../validation.tsx"

export default function ForgotPassword({hideModal}){
    const [phoneNumber, setPhoneNumber, phoneNumberVM] = useStateWithValidation('', Validation.phoneNumber);
    const [isLoading, setIsLoading] = useState(false);

    function handleRequestPasswordReset(){
        new ApiRequest('passwordReset', 'requestReset', setIsLoading, () => hideModal()).withData({phoneNumber}).send();
    }
    
    return(
        <>
            <Modal.Header closeButton>
                <Modal.Title>Forgot Password</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                    <CustomControl validate validationMessage={phoneNumberVM} title='Phone Number' maxLength={10} value={phoneNumber} setValue={(value) => setPhoneNumber(value)}/>
            </Modal.Body>
            <Modal.Footer>
                <div style={{display: 'flex', alignItems: 'center', gap: 12, color: 'gray'}}>
                    <FontAwesomeIcon icon={faInfoCircle}/>
                    <span>After submitting you will receive a text with a link to reset your password</span>
                </div>
                <CustomButton isLoading={isLoading} disabled={phoneNumberVM} variant={'outline-primary'} onClick={()=>handleRequestPasswordReset()}>Submit</CustomButton>
            </Modal.Footer>
        </>
    )
}