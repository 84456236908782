/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, {useEffect, useState} from 'react';
import moment from 'moment';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Spinner from 'react-bootstrap/Spinner';
import CustomControl from '../../../../components/CustomControl';
import { GoogleMap, LoadScript, Marker, useJsApiLoader } from '@react-google-maps/api';
import APIModal from '../../../../components/APIModal';
import Cookies from 'universal-cookie';
import ApiManager, { ApiRequest } from '../../../../ApiManager.tsx';
import DateTimePicker from '../../../../components/DateTimePicker.js';
import { ListGroup } from 'react-bootstrap';
import KeyValueRow from '../../../../components/KeyValueRow.js';
import AlertConfirmation from '../../../../components/AlertModals/AlertConfirmation.js';

const libraries =  ['geometry', 'drawing'];

function HRUserTimesheetEditor({companyUserIdentifier, selectedEntry, hideModal, handleAddEntry, handleUpdateEntry, handleDeleteEntry, handleJumpToBwcRecord}) {
    const [inTime, setInTime] = useState(selectedEntry ? selectedEntry.inTime : moment().format('YYYY-MM-DD HH:mm'));
    const [outTime, setOutTime] = useState(selectedEntry?.outTime ? selectedEntry.outTime : '');
    const [isLoading, setIsLoading] = useState(false);
    const [isDownloading, setIsDownloading] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const [inPicture, setInPicture] = useState(undefined);
    const [outPicture, setOutPicture] = useState(undefined);
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

    const {isLoaded} = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: 'AIzaSyAWI7AlWJrd7l_ARor6Hgo0C4TYxcru_Fw',
        libraries: libraries,
    })

    useEffect(() => {
        if(selectedEntry){
            if (selectedEntry.inPicture) {
                handleDownloadFile(selectedEntry.inPicture).then((response) => {
                    setInPicture(response);
                })
            }
            if (selectedEntry.outPicture) {
                handleDownloadFile(selectedEntry.outPicture).then((response) => {
                    setOutPicture(response);
                })
            }
        }
    }, []);

    function handleSubmit(){
        const newEntry = {
            uid: selectedEntry ? selectedEntry.uid : undefined,
            companyUserIdentifier: companyUserIdentifier,
            inTime: moment(inTime).format('YYYY-MM-DD HH:mm'),
            outTime: outTime ? moment(outTime).format('YYYY-MM-DD HH:mm') : undefined,
            inLat: selectedEntry ? selectedEntry.inLat : undefined,
            inLong: selectedEntry ? selectedEntry.inLong : undefined,
            outLat: selectedEntry ? selectedEntry.outLat : undefined,
            outLong: selectedEntry ? selectedEntry.outLong : undefined
        }

        if(selectedEntry){
            new ApiRequest('hr', 'updateTimesheet', setIsLoading, () => {
                handleUpdateEntry(newEntry);
                hideModal();
            }).withData({entry: newEntry}).send();
        } else{
            new ApiRequest('hr', 'createTimesheet', setIsLoading, (response) => {
                newEntry.uid = response.uid;
                handleAddEntry(newEntry);
                hideModal();
            }).withData({entry: newEntry}).send();
        }
    }

    function handleDelete(){
        if(selectedEntry){
            new ApiRequest('hr', 'deleteTimesheet', setIsDeleting, () => {
                handleDeleteEntry(selectedEntry.uid);
                hideModal();
            }).withUid(selectedEntry.uid).send()
        }
    }

    function handleDownloadFile(uid){
        let base64 = undefined;
        new ApiRequest('hr', 'downloadCustomDocument', setIsDownloading, (response) => base64 = response.base64).withUid(uid).withNoAlertOnSuccess().send();  
        return base64;
    }
    
    return (
        <>
            <Modal.Header closeButton>
                <Modal.Title>{selectedEntry ? 'Editing Timesheet Entry' : 'Creating Timesheet Entry'}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container fluid>        
                    <Row>
                        <Col lg={6} style={{marginBottom: 8}}>
                            <div style={{display: 'flex', justifyContent: 'center'}}>
                                <DateTimePicker title='In Time' type='dateTime' value={inTime} setValue={setInTime}/>
                            </div>
                        </Col>
                        <Col lg={6} style={{marginBottom: 8}}>
                            <div style={{display: 'flex', justifyContent: 'center'}}>
                                <DateTimePicker title='Out Time' type='dateTime' value={outTime} setValue={setOutTime} optional defaultDate={inTime}/>
                            </div>
                        </Col>
                        { selectedEntry && 
                            <>
                                { inPicture || outPicture ?
                                    <>
                                        <Col lg={6} style={{marginBottom: 8}}>
                                            {inPicture &&   
                                            <div style={{width: '100%', backgroundColor: 'black', borderRadius: 6}}>
                                                <img src={inPicture} style={{objectFit: 'contain', width: '100%', aspectRatio: 1}}/>
                                            </div>
                                               
                                            }
                                        </Col>
                                        <Col lg={6} style={{marginBottom: 8}}>
                                            {outPicture &&
                                            <div style={{width: '100%', backgroundColor: 'black', borderRadius: 6}}>
                                                <img src={outPicture} style={{objectFit: 'contain', width: '100%', aspectRatio: 1}}/>
                                            </div>
                                            }
                                        </Col> 
                                    </>
                                    :
                                    <>
                                        <Col lg={6} style={{marginBottom: 8}}>
                                            {  selectedEntry.inLat && selectedEntry.inLong && isLoaded ?    
                                                    <GoogleMap mapContainerStyle={{width: '100%', aspectRatio: 1}} center={{lat: parseFloat(selectedEntry.inLat), lng: parseFloat(selectedEntry.inLong)}} zoom={16}>
                                                        <Marker label='In' position={{lat: parseFloat(selectedEntry.inLat), lng: parseFloat(selectedEntry.inLong)}}/>
                                                    </GoogleMap>
                                                : 
                                                <div style={{width: '100%', aspectRatio: 1, display: 'flex', fontSize: 20, justifyContent: 'center', alignItems: 'center', border: '1px solid lightgray', borderRadius: 6}}>
                                                    Clock-In Location Unknown
                                                </div>
                                            }
                                        </Col>
                                        <Col lg={6} style={{marginBottom: 8}}>
                                            { selectedEntry.outLat && selectedEntry.outLong && isLoaded ?
                                                    <GoogleMap mapContainerStyle={{width: '100%', aspectRatio: 1}} center={{lat: parseFloat(selectedEntry.inLat), lng: parseFloat(selectedEntry.inLong)}} zoom={16}>
                                                        <Marker label='Out' position={{lat: parseFloat(selectedEntry.inLat), lng: parseFloat(selectedEntry.inLong)}}/>
                                                    </GoogleMap>
                                                :
                                                <div style={{width: '100%', aspectRatio: 1, display: 'flex', fontSize: 20, justifyContent: 'center', alignItems: 'center', border: '1px solid lightgray', borderRadius: 6}}>
                                                    Clock-Out Location Unknown
                                                </div>
                                            }
                                        </Col> 
                                    </>

                                }
                                <ListGroup>
                                    {selectedEntry?.events?.map((e) => {
                                        return <KeyValueRow key={e.uid} isListItem title={e.type} valueStyle={e.bwcIdentifier ? {opacity: 1} : undefined} value={e.bwcIdentifier ? <Button variant='outline-primary' style={{padding: '0 7px'}} onClick={() => {hideModal(); handleJumpToBwcRecord(e.bwcIdentifier)}}>Go To BWC Record</Button> : e.statement}/>
                                    })}
                                </ListGroup>
                            </>
                        }
                    </Row>
                </Container>
            </Modal.Body>
            <Modal.Footer>
                { selectedEntry &&
                    <Button onClick={() => setShowDeleteConfirmation(true)} style={{color: 'red', backgroundColor: 'transparent', border: 'none', boxShadow: 'none', padding: 0, marginRight: 8}}>
                        Delete Timesheet Entry
                    </Button>
                }
                <Button variant={'outline-primary'} disabled={isLoading} onClick={handleSubmit}>
                    {isLoading ? <Spinner size='sm'/> : selectedEntry ? 'Save Changes' : 'Submit'}
                </Button>
                
            </Modal.Footer>
            <Modal show={showDeleteConfirmation} onHide={() => {setShowDeleteConfirmation(false)}} centered>
                <AlertConfirmation isLoading={isDeleting} title={'Delete Confirmation'} variant={'outline-danger'} message={'Permanently delete this timesheet entry?'} buttonLabel={'Delete'} callBack={handleDelete}/>
            </Modal>
        </>
    )
}

export default HRUserTimesheetEditor;