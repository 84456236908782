/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, { useEffect, useState } from "react";
import { ApiRequest } from "../../../ApiManager.tsx";
import CustomButton from "../../../components/CustomButton.js";
import LoadingWrapper from "../../../components/LoadingWrapper.js";
import Cookies from "universal-cookie";
import CustomCheck from "../../../components/CustomStateControls/CustomCheck.js";

export default function NotificationSettings({}){
    const namesOfSettings = ['timeOff', 'payrollReady', 'incidents', 'onlyBcTimeOff', 'onlyMyTerminalTimeOff', 'bcSubmittedPayroll', 'newHireAndTermination']
    const [isLoading, setIsLoading] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [settings, setSettings] = useState({
        'timeOff': ['textEnabled'],
        'payrollReady': ['emailEnabled'],
        'incidents': ['textEnabled'],
        'onlyBcTimeOff': [],
        'onlyMyTerminalTimeOff': ['textEnabled'],
        'bcSubmittedPayroll': ['emailEnabled'],
        'newHireAndTermination': ['emailEnabled'],
    });
    
    const [originalSettings, setOriginalSettings] = useState(settings);
    const title = new Cookies().get('userData')['title'];

    useEffect(() => {
        loadData();
    }, [])

    function loadData(){
        new ApiRequest('notificationSettings', 'get', setIsLoading, (response) => {
            setSettings((prevstate) => {
                const newSettings = structuredClone(prevstate);
                response.settings.forEach(s=>{
                    newSettings[s.name] = convertToArrayOfStrings(s)
                })

                return newSettings;
            })
            setOriginalSettings((prevstate) => {
                const newSettings = structuredClone(prevstate);
                response.settings.forEach(s=>{
                    newSettings[s.name] = convertToArrayOfStrings(s)
                })
                return newSettings;
            })
        }).withNoAlertOnSuccess().send();
    }

    function handleSaveSettings(){
        const settingsToSend = namesOfSettings.map(name=>{return {name: name, textEnabled: settings[name].includes('textEnabled'), emailEnabled: settings[name].includes('emailEnabled')}});
        new ApiRequest('notificationSettings', 'update', setIsSubmitting, () => {
            setOriginalSettings(structuredClone(settings));
        }).withData({settings: settingsToSend}).send()
    }

    function handleSetSettings(key, value) {
        const newSettings = structuredClone(settings);
        newSettings[key] = value;
        setSettings(newSettings);
    }

    function convertToArrayOfStrings(setting){
        const array = [];
        if(setting.textEnabled)
            array.push('textEnabled')
        if(setting.emailEnabled)
            array.push('emailEnabled')
        
        return array;
    }

    const content = !settings ? '' : (
        <div style={{display: 'flex', flexDirection: 'column', gap: 16, padding: '8px 12px', flex: 1}}>
            <div style={{padding: 5}}>
                <p style={{textAlign: 'center', margin: 0, fontSize: 24, fontWeight: 'bold', padding: 10}}>Notification Settings</p>
            </div>
                <CustomCheck title={'Receive notifications for time off requests'} inline selection={settings.timeOff} originalSelection={originalSettings.timeOff} setSelection={(value)=>handleSetSettings('timeOff', value)} items={{'textEnabled': 'Send Text'}}/>
                {settings.timeOff.length > 0 &&
                    <div style={{width: '100%', paddingLeft: 24, borderLeft: '3px solid lightgray'}}>
                        <CustomCheck title={'Only receive time off notifications from my terminal'} inline selection={settings.onlyMyTerminalTimeOff} originalSelection={originalSettings.onlyMyTerminalTimeOff} setSelection={(value)=>handleSetSettings('onlyMyTerminalTimeOff', value)} items={{'textEnabled': 'Send Text'}}/>
                        {title == 'AO' && <CustomCheck title={'Only receive time off notifications from BCs'} inline selection={settings.onlyBcTimeOff} originalSelection={originalSettings.onlyBcTimeOff} setSelection={(value)=>handleSetSettings('onlyBcTimeOff', value)} items={{'textEnabled': 'Send Text'}}/>}
                    </div>
                }
                <CustomCheck title={'Receive notifications when payroll is ready to review'} inline selection={settings.payrollReady} originalSelection={originalSettings.payrollReady} setSelection={(value)=>handleSetSettings('payrollReady', value)} items={{'emailEnabled': 'Send Email'}}/>
                {title === 'AO' && <CustomCheck title={'Receive notifcations for when BC has submitted payroll'} inline selection={settings.bcSubmittedPayroll} originalSelection={originalSettings.bcSubmittedPayroll} setSelection={(value)=>handleSetSettings('bcSubmittedPayroll', value)} items={{'emailEnabled': 'Send Email'}}/>}
                <CustomCheck title={'Receive notifications for incidents, injuries or accidents'} inline selection={settings.incidents} originalSelection={originalSettings.incidents} setSelection={(value)=>handleSetSettings('incidents', value)} items={{'textEnabled': 'Send Text'}}/>
                <CustomCheck title={'Receive notifications when an employee is hired or terminated'} inline selection={settings.newHireAndTermination} originalSelection={originalSettings.newHireAndTermination} setSelection={(value)=>handleSetSettings('newHireAndTermination', value)} items={{'emailEnabled': 'Send Email'}}/>
                <CustomButton isLoading={isSubmitting} variant={'outline-primary'}  onClick={handleSaveSettings}>Save Changes</CustomButton>
        </div>
    )

    return (
        <LoadingWrapper isLoading={isLoading}>
            {content}
        </LoadingWrapper>
    )
}