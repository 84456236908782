/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/
import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { ApiRequest } from '../../../../ApiManager.tsx';
import CustomButton from '../../../../components/CustomButton.js';
import CustomControl from '../../../../components/CustomStateControls/CustomControl.js';

export default function HRUserDocumentEditor({document, handleEditHrDocumentName}){
    const [documentName, setDocumentName] = useState(document.name ? document.name : '');
    const [isLoading, setIsLoading] = useState(false);

    function handleEditDocument(){
        const updatedDocument = { 
            ...document,
            name: documentName
        };
        new ApiRequest('hr', 'updateCustomDocument', setIsLoading, () => handleEditHrDocumentName(updatedDocument)).withData({document: updatedDocument}).send();
    }

    return (
        <>
            <Modal.Header closeButton>
                <Modal.Title>Edit Document Name</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <CustomControl floatingLabel label='Name' type='text' maxLength={100} value={documentName} setValue={setDocumentName}/>
            </Modal.Body> 
            <Modal.Footer>
                <CustomButton variant={'outline-primary'} isLoading={isLoading} onClick={handleEditDocument}>Submit</CustomButton>
            </Modal.Footer>
        </>

    )
}