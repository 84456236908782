/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/


import { PDFViewer } from "@react-pdf/renderer";
import React, { useEffect, useState } from "react";
import Modal from 'react-bootstrap/Modal';
import { ApiRequest } from '../../../../ApiManager.tsx';
import LoginReportPdf from "./LoginReportPdf.js";
import DateRangeSelector from "../../../../components/DateRangeSelector.js";
import DropdownControl from "../../../../components/DropdownControl.js";
import { Button, Col, Row } from "react-bootstrap";
import { dateRangeToString } from "../../../../tools.js";

export default function LoginReport() {
    const [showModal, setShowModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [users, setUsers] = useState([]);
    const [logins, setLogins] = useState([]);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [selectedUser, setSelectedUser] = useState(undefined);

    useEffect(()=>{
       loadData();
    },[]);

    useEffect(() => {
        if(selectedUser && startDate && endDate){
            handleGetLogins();
        }
    }, [selectedUser, startDate, endDate])

    function loadData(){
        new ApiRequest('reports', 'getLoginReportResources', setIsLoading, (response) => {
            setUsers(response.users.sort((a,b)=>a.lastName.localeCompare(b.lastName)));
        }).withNoAlertOnSuccess().send();
    }

    function handleGetLogins(){
        new ApiRequest('reports', 'getLoginReport', setIsLoading, (response) => {
            setLogins(response.logins)
        }).withData({
            companyUserIdentifier: selectedUser,
            startDate: startDate,
            endDate: endDate
        }).withNoAlertOnSuccess().send()
    }

    function handleSetSelectedUser(companyUserIdentifier){
        setSelectedUser(users.find((u)=>u.companyUserIdentifier == companyUserIdentifier));
    }

    const userItemNames = users.map((u)=>{
        return u.lastName + ', ' + u.firstName + ' ' + u.middleName.substring(0,1);
    });

    const userItemValues = users.map((u)=>{
        return u.companyUserIdentifier;
    });

    return (
        <>
            <Modal.Header closeButton>
                <Modal.Title>Login Reports</Modal.Title>
            </Modal.Header>
            <Modal.Header style={{flexWrap: 'wrap', gap: 16}}>
                <div style={{flex: 1, minWidth: 300}}>
                    <DropdownControl title='Employee Selection' selection={selectedUser} setSelection={handleSetSelectedUser} itemNames={userItemNames} itemValues={userItemValues}/>
                </div>
                <div style={{display: 'flex', gap: 8}}>
                    <Button variant={'outline-primary'} onClick={()=>setShowModal(true)}>{!startDate || !endDate ? 'Select Date Range' : dateRangeToString(startDate, endDate)}</Button>
                    <Button disabled={!selectedUser || !startDate || !endDate} variant="outline-primary" onClick={handleGetLogins}>Generate Login Report</Button>
                </div>
                <DateRangeSelector show={showModal} onHide={() => setShowModal(false)} handleSetDateRange={(startDate, endDate) => {setStartDate(startDate); setEndDate(endDate)}} presetOptions={['pastMonth', 'pastWeek', 'yesterday']}/>
            </Modal.Header>
            <Modal.Body>
                {startDate && selectedUser && !isLoading && 
                <PDFViewer style={{width: '100%', height: '99%'}}>
                   <LoginReportPdf logins={logins} startDate={startDate} endDate={endDate} selectedUser={selectedUser} />
                </PDFViewer>}
            </Modal.Body>
        </>
    )
}