/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import CustomButton from "../CustomButton";
import { ApiRequest } from "../../ApiManager.tsx";

export default function LogoutModal({handleCheckAuthentication}) {
    const [isLoading, setIsLoading] = useState(false);

    const handleLogout = () => {
        new ApiRequest('authentication', 'logout', setIsLoading, () => {
            handleCheckAuthentication();
        }).send();
    }

    return (
        <>
            <Modal.Header closeButton>
                <Modal.Title>Are You Sure You Want to Logout?</Modal.Title>
            </Modal.Header>
            <Modal.Footer>
                <CustomButton variant='outline-primary' onClick={handleLogout} isLoading={isLoading}>Logout</CustomButton>
            </Modal.Footer>
        </>
    )
}
