/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, {useState} from 'react';
import moment from 'moment';
import CalendarClassic from '../../../components/CalendarClassic/CalendarClassic';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown, faArrowUp, faRotateLeft } from '@fortawesome/free-solid-svg-icons';
import {Form, Button} from 'react-bootstrap';

export default function SchedulingDatePicker({onSubmit, hideModal, date = moment()}) {
    const [datePointer, setDatePointer] = useState(date);
    const startOfWeek = datePointer.day() === 6 ? datePointer : datePointer.startOf('week').subtract(1, 'days');

    function submitForm(){
        if (datePointer.isValid()) {
            hideModal();
            onSubmit(moment(startOfWeek).format('YYYY-MM-DD'));
        }
    }

    return (
        <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly'}}>
            <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%', justifyContent: 'center'}}>
                <div>
                    <Form.Control style={{marginBottom: 8}} type='month' value={datePointer.format('YYYY-MM')} onChange={(event) => {setDatePointer(moment(event.target.value).startOf('month'))}}/>
                    <CalendarClassic datePointer={datePointer.isValid() ? datePointer : moment()} setDatePointer={setDatePointer}/>
                </div>
                <div style={{marginLeft: 6, display: 'flex', flexDirection: 'column'}}>
                    <Button variant={'outline-primary'} onClick={() => setDatePointer(moment(datePointer).subtract(7, 'days'))}>
                        <FontAwesomeIcon icon={faArrowUp}/>
                    </Button>
                    <Button variant={'outline-primary'} onClick={() => setDatePointer(moment())}>
                        <FontAwesomeIcon icon={faRotateLeft}/>
                    </Button>
                    <Button variant={'outline-primary'} onClick={() => setDatePointer(moment(datePointer).add(7, 'days'))}>
                        <FontAwesomeIcon icon={faArrowDown}/>
                    </Button>
                </div>
            </div>                
            <Button disabled={!datePointer.isValid()} variant={'outline-primary'} style={!datePointer.isValid() ? {backgroundColor: 'rgba(0, 0, 0, 0.3)'} : {}} onClick={submitForm}>
                {
                    !datePointer.isValid() ?
                    'Please select a date' :
                    'View ' + 
                    moment(startOfWeek).format('MMM D') + 
                    ' - ' + 
                    moment(startOfWeek).add(6, 'days').format('MMM D')
                }
            </Button>
        </div>
    )
}