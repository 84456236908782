/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, {useEffect, useState} from 'react';
import moment from 'moment';
import Modal from 'react-bootstrap/Modal';
import CustomButton from '../../../../components/CustomButton';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { toFixedMax } from '../../../../tools.js';
import { ApiRequest } from '../../../../ApiManager.tsx';
import AlertConfirmation from '../../../../components/AlertModals/AlertConfirmation.js';

export default function HRUserSchedulePTODaySelector({timeOff, notes, handleCrud, selectedCompany}) {
    const [isLoading, setIsLoading] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [ptoDays, setPtoDays] = useState([]);
    const [ptoBalance, setPtoBalance] = useState(0);
    const [showAlert, setShowAlert] = useState(false);
    const [allowNegativePto, setAllowNegativePto] = useState(1);

    useEffect(() => { 
        loadData();
    }, []);

    function loadData(){
        new ApiRequest('hr', 'getPtoBalance', setIsLoading, (response)=>{
            setPtoBalance(response.ptoBalance);
            setAllowNegativePto(response.allowNegativePto);
        }).withData({companyUserIdentifier: timeOff.companyUserIdentifier, companyIdentifier: selectedCompany}).withNoAlertOnSuccess().send()
    }

    function handleCreatePTO(){
        const timeOffToSend = {
            ...timeOff,
            notes: notes
        }
        new ApiRequest('hr', 'acceptTimeOff', setIsSubmitting, (response) => {
            if(handleCrud){
                handleCrud(response, timeOff, ptoDays)
            }
        }).withData({timeOff: timeOffToSend, ptoDays: ptoDays}).send();
    }

    const handleSelectDay = (momentDate) => {
        const date = momentDate.format('YYYY-MM-DD');
        let newPtoDays = Array.from(ptoDays);
        const index = newPtoDays.findIndex((el) => el.date === date);
        if(index !== -1){
            if(newPtoDays[index].hours === 2){
                newPtoDays = newPtoDays.filter(pto => pto.date !== date);
            }else{
                newPtoDays[index].hours = newPtoDays[index].hours - 2;;
            }
        } else{
            newPtoDays.push({date: date, hours: 8});
        }
        setPtoDays(newPtoDays);
    }

    const days = [];
    const firstDay = moment(timeOff.startDate).startOf('week');
    const lastDay = moment(timeOff.endDate).endOf('week');

    for (let i = 0; i < lastDay.diff(firstDay, 'days') + 1; i++) {
        days.push(moment(firstDay).add(i, 'days'));
    }

    const dayElements = days.map((day) => {
        const isInRange = moment(day).isBetween(moment(timeOff.startDate), moment(timeOff.endDate), 'days', '[]');
        const hoursOnDay = ptoDays.find(d => day.format('YYYY-MM-DD') === moment(d.date).format('YYYY-MM-DD')) ? ptoDays.find(d => day.format('YYYY-MM-DD') === moment(d.date).format('YYYY-MM-DD')).hours : 0;

        const selectionOverlay = (
            <CircularProgressbar value={Math.abs(hoursOnDay)} maxValue={8} strokeWidth={50} styles={buildStyles({strokeLinecap: 'butt', pathColor: "yellow",
            trailColor: "white"})}/>
        )

        return (
            <div 
                key={day.format('YYYY-MM-DD')} 
                onClick={isInRange ? () => {handleSelectDay(day)} : () => {}} 
                className='cursor-pointer' 
                style={{position: 'relative', display: 'flex', backgroundColor: 'white', flexDirection: 'column', opacity: isInRange ? 1 : 0.2, justifyContent: 'center', alignItems: 'center', border: '2px var(--bs-primary) solid', width: 58, height: 58, borderRadius: 29, padding: 8}}
                >
                <div style={{position: 'absolute', zIndex: 0}}>
                    {selectionOverlay}
                </div>
                <p className='unselectable-text' style={{margin: 0, zIndex: 1, fontSize: 15}}>{day.format('D')}</p>
                <p className='unselectable-text' style={{margin: 0, zIndex: 1, fontSize: 15}}>{day.format('ddd')}</p>
                
            </div>
        )
    });

    const ptoHoursSelected = ptoDays.reduce((acc, el) => {return acc + el.hours}, 0.0);
    const remainingBalance = toFixedMax(ptoBalance - ptoHoursSelected, 3);
    const disableButtonNegativeBalance = !allowNegativePto && remainingBalance < 0 && ptoHoursSelected > 0;

    return (
        <>
            <Modal.Header closeButton>
                <Modal.Title>Select Days for PTO</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                Select the days for which the employee will recieve paid time off from the requested date range:
                <p style={{textAlign: 'center', fontWeight: 'bold'}}>{moment(timeOff.startDate).format('MMM D, YYYY') + ' - ' + moment(timeOff.endDate).format('MMM D, YYYY')}</p>
                <div style={{display: 'flex', flexWrap: 'wrap', gap: 8}}>
                    {dayElements}
                </div>
            </Modal.Body>
            <Modal.Footer style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', fontSize: 15}}>
                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                    <span>PTO Hours Selected</span>
                    <span style={{fontWeight: 'bold'}}>{ptoHoursSelected}</span>
                </div>
                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                    <span>PTO Hours Available</span>
                    <span style={{fontWeight: 'bold'}}>{toFixedMax(ptoBalance, 3)}</span>
                </div>
                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                    <span>Remaining Balance</span>
                    <span style={{fontWeight: 'bold'}}>{remainingBalance}</span>
                </div>
            </Modal.Footer>
            <Modal.Footer style={{display: 'flex', alignItems: 'center', justifyContent: 'end', gap: 8}}>
                {disableButtonNegativeBalance ? <p style={{margin: 0, color: 'red', fontSize: 12}}>Your company does not allow PTO approval with a negative balance</p> 
                : <CustomButton disabled={showAlert || disableButtonNegativeBalance} isLoading={isLoading} onClick={()=>{setShowAlert(true)}}>Submit</CustomButton>}
            </Modal.Footer>
            <Modal centered show={showAlert} onHide={()=>setShowAlert(false)}>
                <AlertConfirmation isLoading={isSubmitting} title='Are you sure you want to submit?' callBack={handleCreatePTO} buttonLabel='Submit' message='If your employee would like to use PTO please make sure you have selected PTO days and hours.'/>
            </Modal>
        </>
    )
}