/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, { useState } from "react";
import Modal from 'react-bootstrap/Modal';
import CustomButton from "../../../components/CustomButton";
import CustomRadio from "../../../components/CustomStateControls/CustomRadio";
import ControlStateProps from "../../../state/ControlStateProps.tsx";
import { Validation } from "../../../validation.tsx";
import SubscriberWrapper from "../../../components/SubscriberWrapper.js";
import CustomTextArea from "../../../components/CustomStateControls/CustomTextArea.js";

export default function TimeClockQuestionnaire({incidentObj, handleQuestionnaireSubmission}) {

    const incidentElements = incidentObj.incidents.map((i) => {

        return (
            <div key={i.type}>
                <CustomRadio title={`Did you have any ${i.getPluralLabel()} during your shift?`} stateProps={new ControlStateProps('didOccur', i, Validation.notUndefined)} itemValues={[false, true]} itemNames={['No', 'Yes']}/>
                <SubscriberWrapper stateProps={new ControlStateProps('didOccur', i, undefined, 'statementWrapper')} render={() => {
                    return i.didOccur ? (
                        <CustomTextArea rows={5} max={300} title={`Statement of ${i.type}`} stateProps={new ControlStateProps('statement', i, Validation.nonEmptyString)}/>
                    ) : `${i.removeValidation('statement') ? '' : ''}`;
                }}/>
            </div>
        )
    })

    return (
        <>
            <Modal.Header closeButton>
                <Modal.Title>Clock-Out Questionnaire</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{display: 'flex', flexDirection: 'column', gap: 12}}>
                <span style={{fontWeight: 'bold'}}>All drivers are required to answer the following questions upon ending their shift.</span>
                {incidentElements}
            </Modal.Body>
            <Modal.Footer>
                <CustomButton variant={'outline-primary'} stateObject={incidentObj} onClick={handleQuestionnaireSubmission}>Submit and Clock Out</CustomButton>
            </Modal.Footer>
        </>
    )
}