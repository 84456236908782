/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import moment from "moment";
import Big from "big.js";
import { validateBig } from "../payrollTools";
import { encodeDecimal, validateDecimal, validateInteger } from "../../../../tools";
import PayrollEntryWeek from "./PayrollEntryWeek";
import Loan from "./Loan";
import Deduction from "./Deduction";
import NDBonus from "./NDBonus";

export default class PayrollEntry {
	uid;
	payrollVersionIdentifier;
	companyUserIdentifier;
	firstName;
	middleName;
	lastName;
	title;
	terminalIdentifier;
	terminalName;
	medical;
	dental;
	vision;
    bwcCode;
	ptoAccrual;
	ptoAccrualType;
	notes;
	reoccurringNotes;
	approved;
	isNew;

    childSupport; //[string]
    deductions; //[Deductions]
    loans; //[Loan]
    weeks; //[PayrollEntryWeek]

    periodStart;
    periodEnd;
    previousEntry;

    touched = false;
    weekIndex = 0;

    // ptoBank; //integer
   


    constructor(payrollEntry) {
        this.uid = payrollEntry.uid;
        this.payrollVersionIdentifier = payrollEntry.payrollVersionIdentifier;
        this.companyUserIdentifier = payrollEntry.companyUserIdentifier;
        this.firstName = payrollEntry.firstName;
        this.middleName = payrollEntry.middleName;
        this.lastName = payrollEntry.lastName;
        this.title = payrollEntry.title;
        this.terminalIdentifier = payrollEntry.terminalIdentifier;
        this.terminalName = payrollEntry.terminalName;
        this.medical = payrollEntry.medical;
        this.dental = payrollEntry.dental;
        this.vision = payrollEntry.vision;
        this.bwcCode = payrollEntry.bwcCode;
        this.ptoAccrual = payrollEntry.ptoAccrual;
        this.ptoAccrualType = payrollEntry.ptoAccrualType;
        this.notes = payrollEntry.notes;
        this.reoccurringNotes = !!payrollEntry.reoccurringNotes;
        this.approved = !!payrollEntry.approved;
        this.isNew = !!payrollEntry.isNew;
        this.childSupport = payrollEntry.childSupport;
        this.deductions = payrollEntry.deductions.map(el => Deduction.decode(el));
        this.loans = payrollEntry.loans.map(el => Loan.decode(el));
        this.weeks = payrollEntry.weeks.map(el => PayrollEntryWeek.decode(el));

        this.periodStart = payrollEntry.periodStart;
        this.periodEnd = payrollEntry.periodEnd;
        if (payrollEntry.previousEntry) {
            this.previousEntry = new PayrollEntry(payrollEntry.previousEntry);
        }
    }

    encode() {
        return {
            companyUserIdentifier: this.companyUserIdentifier,
            firstName: this.firstName,
            middleName: this.middleName,
            lastName: this.lastName,
            title: this.title,
            terminalIdentifier: this.terminalIdentifier,
            terminalName: this.terminalName,
            medical: encodeDecimal(this.medical, 4, 2, true),
            dental: encodeDecimal(this.dental, 4, 2, true),
            vision: encodeDecimal(this.vision, 4, 2, true),
            bwcCode: this.bwcCode,
            ptoAccrual: encodeDecimal(this.ptoAccrual, 2, 3, true),
            ptoAccrualType: this.ptoAccrualType,
            notes: this.notes,
            reoccurringNotes: this.reoccurringNotes,
            approved: this.approved,
            isNew: this.isNew,
            childSupport: this.childSupport.map(cs => encodeDecimal(cs, 4, 2, true)),
            deductions: this.deductions.map(el => el.encode()),
            loans: this.loans.map(el => el.encode()),
            weeks: this.weeks.map(el => el.encode()),
        }
    }

    static encodeArray(entriesArray) {
        const encodedArray = entriesArray.map(entry => entry.encode());
        return JSON.stringify(encodedArray);
    }

    static sort(a, b) {
        if (a.terminalName != b.terminalName) {
            return a.terminalName < b.terminalName ? -1 : 1;
        } else if (!!a.previousEntry != !!b.previousEntry) {
            return !!a.previousEntry ? -1 : 1;
        } else {
            return a.name() < b.name() ? -1 : a.name() > b.name() ? 1 : 0;
        }
    }

    name() {
        return this.lastName + ', ' + this.firstName + ' ' + this.middleName;
    }
    
    totalPtoHours() {
        return this.weeks.reduce((prev, curr) => { return prev + curr.totalPtoHours() }, 0);
    }


    qualifiesForPtoAndHolidays() {
        return !this.weeks.some((week) => week.pay.find((pay) => pay.payType === 'py'));
    }

    totalPtoWages() {
        const ptoWages = this.weeks.reduce((prev, curr)=>{
            return prev.plus(curr.totalPtoWages());
        }, new Big('0.00'));
        return ptoWages;
    }
    
    totalHolidayWages() {
        const holidayWages = this.weeks.reduce((prev, curr)=>{
            return prev.plus(curr.totalHolidayWages());
        }, new Big('0.00'));
        return holidayWages;
    }

    nighttimeDiffWages() {
        return this.weeks.reduce((prev, curr) => {
            return prev.plus(curr.nighttimeDiffWages());
        }, new Big('0.0'));
    }

    weekendDiffWages() {
        return this.weeks.reduce((prev, curr) => {
            return prev.plus(curr.weekendDiffWages());
        }, new Big('0.0'));
    }
    
    totalChildSupport() {
        return this.childSupport.reduce((prev, curr) => {return prev + validateDecimal(curr)}, 0.0);
    }

    totalIncentiveWages() {
        return this.weeks.reduce((prev, curr) => {
            return prev.plus(curr.totalIncentiveWages());
        }, new Big('0.0'));
    }
    
    totalStandByWages() {
        return this.weeks.reduce((prev, curr) => {
            return prev.plus(curr.totalStandByWages());
        }, new Big('0.0'));
    }

    totalAutoOvertimeWages() {
        return this.weeks.reduce((prev, curr) => {
            return prev.plus(curr.totalAutoOvertimeWages());
        }, new Big('0.0'));
    }

    getEnabledLoans() {
        return this.loans.filter(l => l.isEnabled);
    }
    
    gross() {
        return this.weeks.reduce((prev, curr) => prev.plus(curr.getSubGross()), new Big('0.00'));
    }

    getColumnInclusion() {
        const weeklyColumnInclusion = this.weeks.map(w => w.getColumnInclusion());

        const entryColumnInclusion = {
            includeMedical: validateDecimal(this.medical) !== 0.0,
            includeDental: validateDecimal(this.dental) !== 0.0,
            includeVision: validateDecimal(this.vision) !== 0.0,
            includeLoans: this.loans.length > 0,
        }

        const types = ['includeSalary', 'includeDaily', 'includeOvertimeColumns', 'includeHourly', 'includeMile', 'includeExtraDay', 'includeIncentiveWages', 'includeStandByWages', 'includeAutoOvertime', 'includePto', 'includeRegularWages', 'includeHolidayWages', 'includeNighttimeDif', 'includeWeekendDif'];
        types.forEach((type) => {
            entryColumnInclusion[type] = weeklyColumnInclusion.some(ci => ci[type]);
        })

        return entryColumnInclusion;
    }

    getAllAdditionalPay() {
        return this.weeks.reduce((prev, curr) => {
            return prev.concat(curr.additionalPay);
        }, []);
    }

}









