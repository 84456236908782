/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, {useState} from 'react';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import { validateDecimal, validateInteger } from '../tools';

export default function CustomTextArea({type, title, originalValue, value, onChange, min, max, step, xs, sm, md, lg, xl, xxl, disabled, validator, maxLength, maxInt, maxDecimal, rows}) {
    const [touched, setTouched] = useState(false);

    const handleOnChange = (event) => {
        if (maxLength) {
            event.target.value = event.target.value.slice(0, maxLength);
        } else if (maxInt && validateInteger(event.target.value) > maxInt) {
            event.target.value = maxInt.toString();
        } else if (maxDecimal && validateDecimal(event.target.value) > maxDecimal) {
            event.target.value = maxDecimal.toString();
        } else if (min != undefined && validateDecimal(event.target.value) < min) {
            event.target.value = min;
        }
        onChange(event);
    }

    const content = (
        <div style={{position: 'relative'}}>
            {title && <Form.Label>{title}</Form.Label>}
            <Form.Control 
                onBlur={() => {setTouched(true)}} 
                isInvalid={validator && !validator.isValid()} 
                isValid={validator && validator.isValid()} 
                disabled={disabled}
                as='textarea'
                rows={rows}
                min={min} 
                max={type === 'number' ? undefined : max} 
                step={step} 
                type={type} 
                placeholder=' ' 
                value={value === undefined || value === null ? '' : value} 
                onChange={handleOnChange} 
                style={originalValue !== undefined && originalValue !== null && value && value.toString() !== originalValue.toString() ? {backgroundColor: '#faffe3'} : {}}
                />
            <div style={{position: 'absolute', fontSize: 11, bottom: 2, right: 4, display: 'flex', alignItems: 'center', justifyContent: 'flex-end', gap: 6, paddingRight: 10}}>
                { maxLength ?
                    <div>
                        <span>{value?.length ?? 0}</span>
                        <span>/</span>
                        <span>{maxLength}</span>
                    </div>
                    : maxInt ?
                    <span style={{color: validateInteger(value) == maxInt ? 'red' : 'black'}}>{`max: ${maxInt}`}</span>
                    : maxDecimal ?
                    <span style={{color: validateDecimal(value) == maxDecimal ? 'red' : 'black'}}>{`max: ${maxDecimal}`}</span>
                    :
                    ''
                }
            </div>
            { validator && <Form.Control.Feedback type='invalid'>{validator.getInvalidMessage()}</Form.Control.Feedback> }
            { validator && <Form.Control.Feedback style={{height: 21}} type='valid'>{validator.getValidMessage()}</Form.Control.Feedback> }
        </div>

    )

    const contentWrapped = (
        <Col xs={xs} sm={sm} md={md} lg={lg} xl={xl} xxl={xxl} style={{marginBottom: 8}}>
            {content}
        </Col>
    )
    
    return (
        <>
            {xs || sm || md || lg || xl || xxl ? contentWrapped : content}
        </>
    )
}
