/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, { useState } from 'react';
import moment from 'moment';
import Modal from 'react-bootstrap/Modal';
import CustomButton from '../../../../components/CustomButton';
import { validateDecimal } from '../../../../tools';
import { ApiRequest } from '../../../../ApiManager.tsx';
import AlertConfirmation from '../../../../components/AlertModals/AlertConfirmation.js';
import CustomTextArea from '../../../../components/CustomStateControls/CustomTextArea.js';
import CustomControl from '../../../../components/CustomStateControls/CustomControl.js';

export default function HRUserPtoModal({companyUserIdentifier, selectedTransaction, handleTransactionCrud}) {
    const [notes, setNotes] = useState(selectedTransaction?.notes ?? '');
    const [ptoAmount, setPtoAmount] = useState(selectedTransaction?.amount ?? '');
    const [isLoading, setIsLoading] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const [showDelete, setShowDelete] = useState(false);

    function handleSubmit(){
        const transaction = {
            ...selectedTransaction,
            companyUserIdentifier: companyUserIdentifier,
            amount: validateDecimal(ptoAmount),
            notes: notes,
        };

        if(selectedTransaction){
            new ApiRequest('hr', 'updatePto', setIsLoading, () => handleTransactionCrud('update', transaction)).withData({pto: transaction}).send();
        } else {
            new ApiRequest('hr', 'createPto', setIsLoading, (response) => {transaction.uid = response.uid; handleTransactionCrud('create', transaction)})
            .withData({pto: transaction}).send();
        }
    }

    function handleDelete(){
        new ApiRequest('hr', 'deletePto', setIsDeleting, () => handleTransactionCrud('delete', selectedTransaction)).withUid(selectedTransaction.uid).send();
    }

    return (
        <>
            <Modal.Header closeButton style={{gap: 12}}>
                <Modal.Title>{selectedTransaction ? 'Edit PTO Transaction' : 'Create PTO Transaction'}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                    <CustomControl floatingLabel label='PTO Hours' type='number' maxDecimal={999.999} value={ptoAmount} setValue={setPtoAmount}/>
                    <div style={{margin: '12px 0'}}>
                        <CustomTextArea placeholder={'Notes'} value={notes} setValue={setNotes} max={300} rows={5}/>
                    </div>
            </Modal.Body>
            <Modal.Footer>
                {selectedTransaction && <button onClick={() => setShowDelete(true)} style={{marginRight: 'auto',border: 'none', marginRight: 2, boxShadow: 'none', color: 'red', backgroundColor: 'transparent'}}>Delete Transaction</button>}
                <CustomButton onClick={handleSubmit} disabled={ptoAmount < -999.999} isLoading={isLoading}>{selectedTransaction ? 'Save Changes' : 'Submit'}</CustomButton>
            </Modal.Footer>
            <Modal centered show={showDelete} onHide={() => {setShowDelete(false)}}>
                <AlertConfirmation isLoading={isDeleting} callBack={handleDelete} variant='outline-danger' title='Delete PTO Transaction?' message='Are you sure you want to delete this pto transaction?' buttonLabel='Delete Transaction'/>
            </Modal>
        </>
    );
}
