/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, { useState } from "react";
import Modal from 'react-bootstrap/Modal';
import HRUserTimesheet from "../../../../HumanResources/HRUserTimesheet/HRUserTimesheet";

export default function PayrollEmployeeTimesheetInfo({entry, payrollPeriod}) {
    const [selectedEmployee, _] = useState({companyUserIdentifier: entry.companyUserIdentifier});

    return (
        <>
            <Modal.Header closeButton>
                <Modal.Title>{`Timesheets for ${entry.firstName} ${entry.lastName}`}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <HRUserTimesheet selectedEmployee={selectedEmployee} defaultStartDate={payrollPeriod.periodStart} defaultEndDate={payrollPeriod.periodEnd}/>
            </Modal.Body>
        </>
    )
}