/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, { useEffect, useState } from 'react';
import { Accordion, Col, Container, Form, Modal, Row} from 'react-bootstrap';
import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup';
import { GoogleMap, InfoWindow, LoadScript, Marker, useJsApiLoader} from '@react-google-maps/api';
import moment from 'moment';
import { faArrowsRotate, faChevronRight, faTruck} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NavLink } from 'react-router-dom';
import QuickTable from '../../components/QuickTable';
// import HRUserScheduleModal from '../HumanResources/HRUserSchedule/HRUserScheduleModal';
import PageSpinner from '../../components/PageSpinner';
import Cookies from 'universal-cookie';
import { faPerson } from '@fortawesome/free-solid-svg-icons';
import ApiManager, { ApiRequest } from '../../ApiManager.tsx';
import HRUserScheduleTimeOffModal from '../Operations/HumanResources/HRUserSchedule/HRUserScheduleTimeOffModal.js';
import CustomButton from '../../components/CustomButton.js';
import KeyValueRow from '../../components/KeyValueRow.js';
import { checkPermission } from '../../tools.js';
import { faBuilding, faCalendar, faClock, faFolderOpen, faUser } from '@fortawesome/free-regular-svg-icons';

const libraries = ['geometry', 'drawing'];

export default function Dashboard({}){
    const [users, setUsers] = useState([]);
    const [selectedRequestOff, setSelectedRequestOff] = useState(undefined);
    const [showScheduleModal, setShowScheduleModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [numberOfConflicts, setNumberOfConflicts] = useState(0);
    const [pendingRequests, setPendingRequests] = useState([]);
    const [timesheetEntries, setTimesheetEntries] = useState([]);
    const [upcomingTimeOff, setUpcomingTimeOff] = useState([]);
    const [upcomingTimeOffEndDate, setUpcomingTimeOffEndDate] = useState(moment().add(1, 'month').format('YYYY-MM-DD'));
    const [isSettingEndDate, setIsSettingEndDate] = useState(false);
    const [isRefreshing, setIsRefreshing] = useState(false);

    const [vehicleCoordinates, setVehicleCoordinates] = useState([]);
    const cookieMonster = new Cookies();

    const {isLoaded} = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: 'AIzaSyAWI7AlWJrd7l_ARor6Hgo0C4TYxcru_Fw',
        libraries: libraries,
    })

    const isAo = cookieMonster.get('userData').title === 'AO';
    const isBc = cookieMonster.get('userData').title === 'BC';

    useEffect(()=>{
        if((isAo || isBc) && checkPermission('hrTimesheet')){
            loadData();
        }
    }, [])

    useEffect(() => {
        if((isAo || isBc) && checkPermission('hrTimesheet')){
            const intervalId = setInterval(handleRefreshVehicleTracking, 10000);
            return () => {
                clearInterval(intervalId);
            }
        }
    }, [])

    function loadData(){
        new ApiRequest('dashboard', 'getDashboard', setIsLoading, (response) => {
            setUsers(response.users);
            setNumberOfConflicts(response.numberOfConflicts);
            setPendingRequests(response.pendingRequests);
            setTimesheetEntries(response.timesheetEntries.sort(sortTimesheetEntries));
            setUpcomingTimeOff(response.upcomingTimeOff);
            setVehicleCoordinates(response.vehicleCoordinates);
        }).withNoAlertOnSuccess().send()
    }

    function handleSetUpcomingTimeOffEndDate(date){
        if(date){
            new ApiRequest('dashboard', 'getUpcomingTimeOff', setIsSettingEndDate, (response) => {setUpcomingTimeOffEndDate(date); setUpcomingTimeOff(response.upcomingTimeOff)}).withData({endDate: date}).withNoAlertOnSuccess().send();
        }
    }

    function handleRefreshVehicleTracking(){
        new ApiRequest('dashboard', 'refreshVehicleTracking', setIsRefreshing, (response) => {
            setVehicleCoordinates(response.vehicleCoordinates);
        }).sendInBackground();
    }

    function handleScheduleItemsCrud(_type, _target, uid, value, isPto){        
        setPendingRequests((prevState) => prevState.filter((item => item.uid !== uid)));
        if(value.status !== -1 && moment(value.date).isSameOrBefore(moment(upcomingTimeOffEndDate))){
            value.isPto = isPto;
            setUpcomingTimeOff((prevState) => [...prevState, value].sort((a, b) => a.startDate > b.startDate ? 1 : a.startDate < b.startDate ? -1 : 0));
        }
        setShowScheduleModal(false);
    }

    function sortTimesheetEntries(a, b) {
        if (a.date < b.date) {
            return -1;
        } else if (a.date > b.date) {
            return 1;
        }
        return 0;
    }

    ////////////////////
    /// DESKTOP ELEMENTS
    ////////////////////

    const pendingRequestElements = pendingRequests.map((item) => {
        const employee = users.find(user => user.companyUserIdentifier == item.companyUserIdentifier);
        return (
            <tr style={{ cursor: 'pointer' }} key={item.uid} onClick={() => {setSelectedRequestOff(item); setShowScheduleModal(true)}}>
                <td>{`${employee.lastName}, ${employee.firstName} ${employee?.middleName.substring(0, 1)}`}</td>
                <td>{moment(item.startDate).format('MMM D, YYYY')}</td>
                <td>{moment(item.endDate).format('MMM D, YYYY')}</td>
                <td>{moment(item.submissionDate).format('MMM D, YYYY')}</td>
            </tr>
        )
    });

    const upcomingTimeOffElements = upcomingTimeOff.map((item) => {
        const employee = users.find(user => user.companyUserIdentifier == item.companyUserIdentifier);
        return (
            <tr key={item.uid}>
                <td>{`${employee.lastName}, ${employee.firstName} ${employee?.middleName.substring(0, 1)}`}</td>
                <td>{Math.abs(item.ptoHours)}</td>
                <td>{moment(item.startDate).format('MMM D, YYYY')}</td>
                <td>{moment(item.endDate).format('MMM D, YYYY')}</td>
                <td>{moment(item.submissionDate).format('MMM D, YYYY')}</td>
            </tr>
        )
    });

    /////////////////////
    /// MOBILE ELEMENTS 
    /////////////////////

    const mobilePendingRequestElements = pendingRequests.map((item) => {
        const employee = users.find(user => user.companyUserIdentifier == item.companyUserIdentifier);
        return (
            <ListGroup.Item key={item.uid} action onClick={() => {setSelectedRequestOff(item); setShowScheduleModal(true)}}>
                <h6 style={{fontWeight: 'bold'}}>{`${employee.lastName}, ${employee.firstName} ${employee?.middleName.substring(0, 1)}`}</h6>
                <KeyValueRow title={'Start Date'} value={moment(item.startDate).format('MMM D, YYYY')}/>
                <KeyValueRow title={'End Date'} value={moment(item.endDate).format('MMM D, YYYY')}/>
                <KeyValueRow title={'Submission Date'} value={moment(item.submissionDate).format('MMM D, YYYY')}/>
            </ListGroup.Item>
        )
    });

    const mobileUpcomingTimeOffElements = upcomingTimeOff.map((item) => {
        const employee = users.find(user => user.companyUserIdentifier == item.companyUserIdentifier);
        return (
            <ListGroup.Item key={item.uid}>
                <h6 style={{fontWeight: 'bold'}}>{`${employee.lastName}, ${employee.firstName} ${employee?.middleName.substring(0, 1)}`}</h6>
                <KeyValueRow title={'PTO Hours Used'} value={Math.abs(item.ptoHours)}/>
                <KeyValueRow title={'Start Date'} value={moment(item.startDate).format('MMM D, YYYY')}/>
                <KeyValueRow title={'End Date'} value={moment(item.endDate).format('MMM D, YYYY')}/>
                <KeyValueRow title={'Submission Date'} value={moment(item.submissionDate).format('MMM D, YYYY')}/>
            </ListGroup.Item>
        )
    });

    /////////////////////
    /// OTHER ELEMENTS 
    /////////////////////
    

    const featureOverviewItems = [
        {
            name: 'Profile',
            description: 'View personal information and pay information. Change your password.',
            icon: faUser
        },
        {
            name: 'Time Clock',
            description: 'View personal timesheet records. Clock in and out.',
            icon: faClock
        },
        {
            name: 'My Schedule',
            description: 'View personal calendar of scheduled routes and time off. Request time off.',
            icon: faCalendar
        },
        {
            name: 'My Documents',
            description: 'View acknowledged company documents. View/access document requests',
            icon: faFolderOpen
        },
        {
            name: 'Company Selector',
            description: 'Page content will only include information associated with the currently selected company displayed on the navigation bar',
            icon: faBuilding
        }
    ];

    const featureOverviewElements = featureOverviewItems.map((obj) => {
        return (
            <Col xl={6} style={{marginBottom: 12}} key={obj.name}>
                <Card>
                    <Card.Header>
                        <Card.Title>
                            <FontAwesomeIcon icon={obj.icon} style={{marginRight: 6}}/>
                            {obj.name}
                        </Card.Title>
                    </Card.Header>
                    <Card.Body>
                        {obj.description}
                    </Card.Body>
                </Card>
            </Col>
        )
    });

    const timeDataWithCoords = timesheetEntries.filter((time) => time.inLat && time.inLong);
    const googleMapClockIns = timeDataWithCoords.map((time)=>{
        const employee = users.find((user) => {return user.companyUserIdentifier === time.companyUserIdentifier});
        return(  
            <Marker key={time.uid} icon={{path:faPerson.icon[4],  fillColor:'#1DA1F2', fillOpacity:1, strokeWeight:1, scale:0.055}}  position={{lat: parseFloat(time.inLat), lng: parseFloat(time.inLong)}}>
                <InfoWindow  position={{lat: parseFloat(time.inLat), lng: parseFloat(time.inLong)+0.0000055}}>
                    <div style={{width:'75px', fontSize:11}}>{(employee.lastName ? employee.lastName : '') + ', ' + (employee.firstName ? employee.firstName : '') + (employee.middleName ? (' ' + employee.middleName.substring(0,1)) : '')}</div>
                </InfoWindow>
            </Marker>
        )
    });

    const recentVehicleLocations = vehicleCoordinates.map(v => {
        return( 
            <Marker key={v.routeIdentifier} icon={{path: faTruck.icon[4],  fillColor:'#1DA1F2', fillOpacity:1, strokeWeight:1, scale:0.055}}  position={{lat: parseFloat(v.latitude), lng: parseFloat(v.longitude)}}>
                <InfoWindow position={{lat: parseFloat(v.latitude), lng: parseFloat(v.longitude)+0.0000055}}>
                    <div>
                        <div style={{width: '75px', fontSize: 11, fontWeight: 'bold', textAlign: 'center'}}>{v.vehicleName}</div>
                        <hr style={{margin: 2}}/>
                        <div style={{width: '75px', fontSize: 11, textAlign: 'center'}}>{moment(v.timestamp).format('LTS')}</div>
                    </div>
                </InfoWindow>
            </Marker>
        )
    })

    const vehicleLatSum = vehicleCoordinates.reduce((acc, el) => acc + parseFloat(el.latitude), 0);
    const vehicleLngSum = vehicleCoordinates.reduce((acc, el) => acc + parseFloat(el.longitude), 0);
    const vehicleAvgLat = vehicleLatSum / vehicleCoordinates.length;
    const vehicleAvgLng = vehicleLngSum / vehicleCoordinates.length;
    
    const latSum = timeDataWithCoords.reduce((sum, time) => sum + parseFloat(time.inLat), 0);
    const lngSum = timeDataWithCoords.reduce((sum, time) => sum + parseFloat(time.inLong), 0);
    const avgLat = latSum / timeDataWithCoords.length;
    const avgLng = lngSum / timeDataWithCoords.length;

    const clockedIn = users.filter((u) => {
        const filteredTmesheetEntries = timesheetEntries.filter(t => t.companyUserIdentifier == u.companyUserIdentifier);
        return filteredTmesheetEntries.length > 0;
    })
    const scheduled = users.filter(u => u.isScheduled);

    let clockedInColor = 'green';
    if (clockedIn.length > scheduled.length) {
        clockedInColor = 'orange';
    } else if (clockedIn.length < scheduled.length) {
        clockedInColor = 'red';
    }

    return (
        <div style={{display: 'flex', flexDirection: 'column', height: '100%', overflowY: 'auto', flex: 1}}>
            {(!isAo && !isBc) || !checkPermission('hrTimesheet') ? 
                <>
                    <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', position: 'relative', backgroundColor: 'var(--bs-primary)', padding: 6}}>
                        <div className='truck-tiles-background' style={{opacity: 0.05}}/>
                        <h1 style={{color: 'white'}}>Welcome to the MTT Web Application</h1>
                    </div>
                        <h2 style={{marginTop: 12, fontWeight: 'bold', textAlign: 'center'}}>Overview of Features</h2>
                    <Container style={{padding: 12}}>
                        <Row>
                            {featureOverviewElements}
                        </Row>
                    </Container>
                </>
            : isLoading ? <PageSpinner/> :
                <>
                    <div style={{padding:20}}>
                        <ListGroup>
                            <ListGroup.Item style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                                <p style={{ fontSize: 18, fontWeight: 'bold', marginBottom: 0 }}>Clocked-In Vs. Scheduled: <span style={{ color: clockedInColor }}>{clockedIn.length}</span><span style={{ color: clockedInColor }}>{' '+ '/' + ' '}</span><span style={{color:clockedInColor}}>{scheduled.length}</span></p>
                                <div style={{paddingRight: 8, paddingLeft: 8}}>
                                    <NavLink style={{color: 'var(--bs-primary)'}} className={({isActive}) => isActive ? 'nav-bar-link nav-bar-link-active' : 'nav-bar-link'} to={'/human-resources'}>
                                        <p style={{fontSize: 18, margin: 0, }}> {'View in Human Resources'}</p>
                                        <FontAwesomeIcon icon={faChevronRight} style={{marginLeft: 8}}/>
                                    </NavLink>
                                </div>
                            </ListGroup.Item>
                            <ListGroup.Item style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                                <p style={{fontSize:18, fontWeight:'bold', marginBottom: 0 }}>Schedule Conflicts For This Week: <span style={{ color: numberOfConflicts === 0 ? 'green' : '#EF3C39' }}>{numberOfConflicts}</span></p>
                                <div style={{paddingRight: 8, paddingLeft: 8}}>
                                    <NavLink style={{color: 'var(--bs-primary)'}} className={({isActive}) => isActive ? 'nav-bar-link nav-bar-link-active' : 'nav-bar-link'} to={'/scheduling'}>
                                        <p style={{fontSize: 18, margin: 0, }}> {'View Scheduling'}</p>
                                        <FontAwesomeIcon icon={faChevronRight} style={{marginLeft: 8}}/>
                                    </NavLink>
                                </div>
                            </ListGroup.Item>
                        </ListGroup>
                        <div style={{marginTop:20}}>
                            <Card>
                                {pendingRequestElements.length > 0 ?
                                <>
                                    <Accordion defaultActiveKey='0'>
                                        <Accordion.Item eventKey='0'>
                                            <Accordion.Header >
                                                <b>Pending Time-Off Requests</b>
                                            </Accordion.Header>
                                            <Accordion.Body>
                                                <div className='desktop-block'>
                                                    <QuickTable hover striped responsive headers={['Employee', 'Start Date', 'End Date', 'Submission Date']} rows={pendingRequestElements}/>
                                                </div>
                                                <div className='mobile-block'>
                                                    <ListGroup>
                                                        {mobilePendingRequestElements}
                                                    </ListGroup>
                                                </div>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    </Accordion>
                                </>
                                :
                                <div style={{marginLeft:15, marginTop:10}}>
                                    <p style={{fontSize:18, fontWeight:'bold'}}>No Pending Time-Off Requests</p>
                                </div>
                                }
                            </Card>
                        </div>
                        <div style={{marginTop:20}}>
                            <Card>
                                {upcomingTimeOffElements.length > 0 ?
                                    <>
                                        <Accordion defaultActiveKey='0'>
                                            <Accordion.Item eventKey='0'>
                                                <Accordion.Header>
                                                    <b>Upcoming Time-Off</b>
                                                </Accordion.Header>
                                                <Accordion.Body>
                                                    <Card.Body style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                                        <span>Displaying Upcoming Time-Off Through: </span>
                                                        <Form.Control style={{maxWidth: 200, marginLeft: 8}} min={moment().format('YYYY-MM-DD')} disabled={isSettingEndDate} type='date' value={upcomingTimeOffEndDate} onChange={(event) => handleSetUpcomingTimeOffEndDate(event.target.value)}/>
                                                    </Card.Body>
                                                    <div className='desktop-block'>
                                                        <QuickTable striped responsive headers={['Employee', 'PTO Hours Used', 'Start Date', 'End Date', 'Submission Date']} rows={upcomingTimeOffElements}/>
                                                    </div>
                                                    <div className='mobile-block'>
                                                        <ListGroup>
                                                            {mobileUpcomingTimeOffElements}
                                                        </ListGroup>
                                                    </div>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        </Accordion>
                                    </>
                                    :
                                    <div style={{marginLeft:15, marginTop:10}}>
                                        <p style={{fontSize:18, fontWeight:'bold'}}>No Upcoming Time-Off</p>
                                    </div>
                                }
                            </Card>
                        </div>
                        {/* {!ApiManager.DEV_MODE && */}
                            <>
                                <div style={{marginTop:20}}>
                                    <Card>
                                        <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '18px'}}>
                                            <h6 style={{marginLeft: 10, margin: 0, fontWeight: 'bold'}}>TODAY'S VEHICLE TRACKING</h6>
                                            {/* <CustomButton isLoading={isRefreshing} onClick={handleRefreshVehicleTracking}>    
                                                Refresh <FontAwesomeIcon style={{cursor: 'pointer'}} size='1x' icon={faArrowsRotate}/>
                                            </CustomButton> */}
                                        </div>
                                        {isLoaded && vehicleCoordinates.length > 0 ?
                                            <GoogleMap mapContainerStyle={{width: '100%', height:'600px', aspectRatio: 1}} center={{lat: vehicleAvgLat, lng: vehicleAvgLng}} zoom={12}>
                                                {recentVehicleLocations}
                                            </GoogleMap>
                                        :
                                            <div style={{marginLeft:15, marginTop:10}}>
                                                <p style={{fontSize:18, fontWeight:'bold'}}>No vehicle tracking locations to display</p>
                                            </div>
                                        }
                                    </Card>
                                </div>
                                <div style={{marginTop:20}}>
                                    <Card>
                                        <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '18px'}}>
                                            <h6 style={{marginLeft: 10, margin: 0, fontWeight: 'bold'}}>TODAY'S EMPLOYEE CLOCK-IN LOCATIONS</h6>
                                        </div>
                                        {isLoaded && googleMapClockIns.length > 0?
                                            <GoogleMap mapContainerStyle={{width: '100%', height:'600px', aspectRatio: 1}} center={{lat: avgLat, lng: avgLng}} zoom={18}>
                                                {googleMapClockIns}
                                            </GoogleMap>
                                        :
                                            <div style={{marginLeft:15, marginTop:10}}>
                                                <p style={{fontSize:18, fontWeight:'bold'}}>No clock-in locations to display</p>
                                            </div>
                                        }
                                    </Card>
                                </div>
                            </>
                        {/* } */}
                    </div>
                    <Modal show={showScheduleModal} onHide={() => setShowScheduleModal(false)}>
                        <HRUserScheduleTimeOffModal timeOff={selectedRequestOff} handleScheduleItemsCrud={handleScheduleItemsCrud} hideNotes/>
                    </Modal>
                </>
            }
        </div>
    )
}