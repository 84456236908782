/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, {useEffect, useState} from 'react';
import Modal from 'react-bootstrap/Modal';
import { Button } from 'react-bootstrap';
import moment from 'moment';
import CustomButton from '../../../../components/CustomButton.js';
import HRUserSchedulePTODaySelector from './HRUserSchedulePTODaySelector.js';
import HRTimeOffModal from './HRTimeOffModal.js';
import CustomDatePicker from '../../../../components/CustomDatePicker/CustomDatePicker.js';
import CustomTextArea from '../../../../components/CustomStateControls/CustomTextArea.js';

export default function HRCreateTimeOffModal({companyUserIdentifier, timeOff, handleCrud, selectedCompany}) {
    const [startDate, setStartDate] = useState(undefined);
    const [endDate, setEndDate] = useState(undefined);
    const [reason, setReason] = useState('');
    const [newTimeOff, setNewTimeOff] = useState({});
    const [showPtoSelector, setShowPtoSelector] = useState(false);
    const [showTimeOffModal, setShowTimeOffModal] = useState(false);

    function handleCreatingScheduleItem(){
        const newTimeOff = {
            companyUserIdentifier: companyUserIdentifier,
            startDate: startDate,
            endDate: endDate,
            reason: reason,
        };

        setNewTimeOff(newTimeOff);
        setShowPtoSelector(true);
    }

    const disabledDates = timeOff.reduce((prev, curr) => { {
        const dates = [];
        const numberOfDays = moment(curr.endDate).diff(curr.startDate, 'days') + 1;
        for (let i = 0; i < numberOfDays; i++) {
            dates.push(moment(curr.startDate).add(i, 'days').format('YYYY-MM-DD'));
        }
        return prev.concat(dates);
    }}, []);

    return (
        <>
            <Modal.Header closeButton>
                <Modal.Title>Schedule Time Off</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div style={{display: 'flex', justifyContent: 'flex-end', marginBottom: 12}}>
                    <Button variant='outline-primary' onClick={() => {setShowTimeOffModal(true)}}>View Upcoming Pending & Accepted Time Off</Button>
                </div>
                <CustomDatePicker title={'Date Range'} startDate={startDate} setStartDate={setStartDate} endDate={endDate} setEndDate={setEndDate} disabledDates={disabledDates}/>
                <div style={{padding: '12px 0'}}>
                    <CustomTextArea placeholder={'Reason'} value={reason} setValue={setReason} rows={4} max={200}/>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <CustomButton onClick={handleCreatingScheduleItem} label={'Submit'}>Submit</CustomButton>
            </Modal.Footer>
            <Modal centered show={showPtoSelector} onHide={()=>setShowPtoSelector(false)}>
                <HRUserSchedulePTODaySelector selectedCompany={selectedCompany} timeOff={newTimeOff} handleCrud={handleCrud}/>
            </Modal>
            <Modal centered show={showTimeOffModal} onHide={() => {setShowTimeOffModal(false)}} size='lg' fullscreen='sm-down'>
                <HRTimeOffModal selectedCompany={selectedCompany} startDate={moment().format('YYYY-MM-DD')} endDate={moment().add(1, 'year').format('YYYY-MM-DD')}/>
            </Modal>
        </>
    )
}