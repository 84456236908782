/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import AddressAutoFill from "../../../../components/CustomStateControls/AddressAutoFill.js";
import ControlStateProps from "../../../../state/ControlStateProps.tsx";
import { Validation } from "../../../../validation.tsx";
import CustomControl from "../../../../components/CustomControl.js";

export default function ProfileSettings({settings, setSettingsKey, settingsVMs, originalSettings}){

    return (
        <div>
            <Container fluid>
                <Row>
                    <CustomControl title='Ein' type='number' max={9} clampAsText breakpoints={{lg: 12}} validationMessage={settingsVMs.ein} originalValue={originalSettings.ein} value={settings.ein} setValue={(value) => setSettingsKey('ein', value)}/>
                    <AddressAutoFill label='Company Address' stateProps={new ControlStateProps('address', settings, Validation.notUndefined)}/>
                </Row>
            </Container>
        </div>
    )
}