/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, { useState } from 'react';
import { ApiRequest } from '../../../../ApiManager.tsx';
import CustomControl from '../../../../components/CustomStateControls/CustomControl.js';
import { Modal } from 'react-bootstrap';
import CustomButton from '../../../../components/CustomButton.js';
import moment from 'moment';
import { Validation, useStateWithValidation, useValidationReducer } from '../../../../validation.tsx';

export default function HRUserLoanInstallmentModal({selectedLoan, handleCreateInstallment}) {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [amount, setAmount, amountVM] = useStateWithValidation(0, Validation.greaterThanZero);
    const [date, setDate, dateVM] = useStateWithValidation(moment().format('YYYY-MM-DD'), Validation.date);
    const isValid = useValidationReducer([amountVM, dateVM])

    function handleSubmit(){
        const installment = {
            loanIdentifier: selectedLoan.uid,
            amount: amount,
            date: date
        }
        new ApiRequest('hr', 'createLoanInstallment', setIsSubmitting, (response) => {installment.uid = response.uid; handleCreateInstallment(installment)}).withData({loanInstallment: installment}).send();
    }


    return (
        <>
            <Modal.Header closeButton>
                <Modal.Title>Create Manual Installment</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{display: 'flex', flexDirection: 'column', gap: 12}}>
                <CustomControl validationStateMessage={amountVM} maxDecimal={99999.99} type='number' value={amount} setValue={setAmount} floatingLabel label='Payment Amount'/>
                <CustomControl validationStateMessage={dateVM} max={moment().format('YYYY-MM-DD')} type='date' value={date} setValue={setDate} floatingLabel label='Payment Date'/>
            </Modal.Body>
            <Modal.Footer>
                <CustomButton isLoading={isSubmitting} disabled={!isValid} label='Submit' onClick={handleSubmit}>Submit</CustomButton>
            </Modal.Footer>
        </>
    )
}