/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, { useState } from "react";
import Modal from 'react-bootstrap/Modal';
import CustomButton from "../../../../components/CustomButton.js";
import CustomControl from '../../../../components/CustomControl.js';
import SwitchControl from "../../../../components/SwitchControl.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { ApiRequest } from "../../../../ApiManager.tsx";
import { Validation, useStateWithValidation } from "../../../../validation.tsx";
import { Row } from "react-bootstrap";

export default function DocumentEditorModal({document, handleEditDocument}) {
    const [isLoading, setIsLoading] = useState(false);
    const [name, setName, nameVM] = useStateWithValidation(document.name, Validation.nonEmptyString);
    const [isRequired, setIsRequired] = useState(document.isRequired);

    function handleSubmit(){
        const newDocument = {
            ...document,
            name: name,
            isRequired: isRequired,
        }
        new ApiRequest('myCompany', 'editCompanyDocument', setIsLoading, () =>  handleEditDocument(newDocument)).withData({document: newDocument}).send()
    }

    return (
        <>
            <Modal.Header closeButton>
                <Modal.Title>Editing Company Document</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{display: 'flex', flexDirection: 'column', gap: 12}}>
                <Row>
                    <CustomControl breakpoints={{xl: 12}} validate validationMessage={nameVM} disabled={isLoading} value={name} max={100} setValue={setName} originalValue={document.name} title='Document Name'/>
                    <SwitchControl breakpoints={{xl: 12}} style={{opacity: document.type === 'pdf' ? 1 : .6}} disabled={isLoading || document.type !== 'pdf'} value={isRequired} setValue={setIsRequired} originalValue={document.isRequired} title='Require Employees to Acknowledge Document'/>
                </Row>
                    <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 12, color: 'gray'}}>
                        <FontAwesomeIcon icon={faInfoCircle}/> {document.type === 'pdf' ? 'Make sure document name is correct if enabling acknowledgements' : 'Only .pdf files are eligible for this feature'}
                    </div>
            </Modal.Body>
            <Modal.Footer>
                <CustomButton isLoading={isLoading} disabled={!name} variant={`outline-primary`} onClick={handleSubmit}>Save Changes</CustomButton>
            </Modal.Footer>
        </>
    )
}