/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, { useState, useRef } from 'react';
import { InputGroup } from 'react-bootstrap';
import CustomButton from './CustomButton';
import { ApiRequest } from '../ApiManager.tsx';
import CustomControl from './CustomStateControls/CustomControl.js';

export default function CompanyFileUploadControl({handleUploadDocument}) { 
    const [isLoading, setIsLoading] = useState(false);
    const [documentName, setDocumentName] = useState('');
    const [documentType, setDocumentType] = useState('');
    const ref = useRef();

    function handleOnChange(){
        const file = ref.current.files[0];
        const indexOfDot = file.name.lastIndexOf('.');
        setDocumentName(file.name.slice(0, indexOfDot));
        setDocumentType(file.name.slice(indexOfDot + 1));
    }

    function handleUpload(){
        const file = ref.current.files[0];
        if (file) {
            setIsLoading(true);
            const reader = new FileReader();
    
            reader.onerror = () => {
                setIsLoading(false);
            }
    
            reader.onload = () => {
                const indexOfDot = file.name.lastIndexOf('.');
                const fileObj = {
                    base64: reader.result,
                    name: documentName,
                    type: file.name.slice(indexOfDot + 1),
                };

                new ApiRequest('myCompany', 'createCompanyDocument', setIsLoading, (response) => {
                    fileObj.fileIdentifier = response.fileIdentifier;
                    handleUploadDocument(fileObj);
                }).withData({document: fileObj}).send();
            }
            reader.readAsDataURL(file);
        }
    }

    return (
        <>
            <input style={{width: 0, height: 0, opacity: 0}} ref={ref} type='file' onChange={handleOnChange}/>
            <InputGroup>
                <CustomButton onClick={() => {ref.current.click()}} isLoading={isLoading}>{ref.current && ref.current.files[0] ? 'Change File' : 'Choose File'}</CustomButton>
                <CustomControl floatingLabel noValidationLabel type='text' label='File to Upload' placeholder=' ' max={100} maxLength={100} value={documentName} setValue={setDocumentName}/>
                { ref.current && ref.current.files[0] ?
                    <>
                        <InputGroup.Text>{documentType}</InputGroup.Text>
                        <CustomButton variant={'primary'} style={{color: 'white'}} isLoading={isLoading} onClick={handleUpload}>Upload</CustomButton>
                    </>
                : ''}
            </InputGroup>
        </>
    )

}