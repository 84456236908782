/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, { useState } from "react";
import Modal from 'react-bootstrap/Modal';
import CustomButton from "../../../../components/CustomButton.js";
import { useLoadData, useStateToggleArray } from "../../../../hooks.tsx";
import { ApiRequest } from "../../../../ApiManager.tsx";
import { ListGroup } from "react-bootstrap";
import { formatDateRange } from "../../../../tools.js";
import LoadingWrapper from "../../../../components/LoadingWrapper.js";
import SearchBar from "../../../../components/SearchBar.js";

export default function CreateCustomPayrollModal({payrollPeriod, callback}) {
    const [isLoading, setIsLoading] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [employees, setEmployees] = useState([]);
    const [selectedCompanyUserIdentifiers, _, toggleSelectedCompanyUserIdentifier] = useStateToggleArray([]);
    const [search, setSearch] = useState('');

    useLoadData(() => {
        new ApiRequest('payroll', 'getEmployees', setIsLoading, (response) => {
            setEmployees(response.employees);
        }).withData({companyIdentifier: payrollPeriod.companyIdentifier}).withNoAlertOnSuccess().send();
    })

    function handleSubmit() {
        new ApiRequest('payroll', 'createCustom', setIsSubmitting, (response) => {
            callback(response.uid);
        }).withData({payrollIdentifier: payrollPeriod.uid, companyUserIdentifiers: selectedCompanyUserIdentifiers}).send()
    }

    const employeeElements = employees.filter(employee => `${employee.lastName}, ${employee.firstName}${employee.middleName ? ` ${employee.middleName.substring(0, 1)}` : ''}`.toLowerCase().includes(search.toLowerCase())).map((employee) => {
        const isSelected = selectedCompanyUserIdentifiers.includes(employee.companyUserIdentifier);
        return (
            <ListGroup.Item key={employee.companyUserIdentifier} style={{ display: 'flex', justifyContent: 'space-between', gap: 6, flexWrap: 'wrap'}} action active={isSelected} onClick={() => {toggleSelectedCompanyUserIdentifier(employee.companyUserIdentifier)}}>
                <span>{`${employee.lastName}, ${employee.firstName}${employee.middleName ? ` ${employee.middleName.substring(0, 1)}` : ''} (${employee.title ?? 'Terminated'})`}</span>
                <span>{employee.terminalName}</span>
            </ListGroup.Item>
        )
    })

    return (
        <>
            <Modal.Header closeButton>
                <div>
                    <Modal.Title>{`Creating Custom Payroll`}</Modal.Title>
                    <span style={{fontSize: 15}}>{`${payrollPeriod.companyName} | ${formatDateRange(payrollPeriod.periodStart, payrollPeriod.periodEnd)}`}</span>
                </div>
            </Modal.Header>
            <Modal.Body style={{display: 'flex', flexDirection: 'column', gap: 12, overflow: 'hidden'}}>
                <LoadingWrapper isLoading={isLoading} height={300}>
                    <p style={{textAlign: 'center', marginBottom: 0, fontWeight: 'bold'}}>Select the employees to include in the custom payroll</p>
                    <SearchBar value={search} setValue={setSearch} label='Search Employee Name...'/>
                    <ListGroup style={{flex: 1, overflowY: 'auto'}}>{employeeElements}</ListGroup>
                </LoadingWrapper>
            </Modal.Body>
            <Modal.Footer>
                <span style={{opacity: 0.5}}>{`${selectedCompanyUserIdentifiers.length} employee(s) selected`}</span>
                <CustomButton isLoading={isSubmitting} disabled={selectedCompanyUserIdentifiers.length == 0 || isLoading} onClick={handleSubmit}>Submit</CustomButton>
            </Modal.Footer>
        </>
    )
}