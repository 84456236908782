/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, { useRef, useState } from 'react';
import { 
    faRightFromBracket,
    faCaretLeft,
    faCaretRight,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import logo from '../../assets/TTA-Logo-150.png';
import CompanySelector from '../CompanySelector';
import moment from 'moment';
import { Button } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';

export default function NavigationBarLarge({auth, userData, companies, setShowLogoutModal, handleStopShadowing, routes}) {
    const ref = useRef();
    const [showCanvas, setShowCanvas] = useState(true);

    const largeNavBarSections = ['Account', 'ISP Management', 'Operations', 'Finance'].filter(c => routes.some(r => r.category == c)).map((category) => {
        const buttons = routes.filter(r => r.category == category).map((route) => {
            
            return (
                <NavLink key={route.name} className={(isActive) => isActive ? 'nav-bar-link nav-bar-link-active' : 'nav-bar-link'} to={route.path}>
                    <div style={{width: 25, display: 'flex', justifyContent: 'center'}}>
                        <FontAwesomeIcon icon={route.icon} style={{marginRight: 8}}/>
                    </div>
                    <p style={{fontSize: 18, margin: 0, }}> {route.name}</p>
                </NavLink>
            )
        })
        return (
            <div key={category}>
                <div style={{display: 'flex', alignItems: 'center', gap: 8, width: '100%', justifyContent: 'center', color: 'gray'}}>
                    <div style={{backgroundColor: 'gray', height: 1, flex: 1, marginLeft: 12}}/>
                    <span>{category}</span>
                    <div style={{backgroundColor: 'gray', height: 1, flex: 1, marginRight: 12}}/>
                </div>
                {buttons}
            </div>
        )
    })

    return (
        <div ref={ref} className='navbar-large'>
            <img src={logo} alt={'TTA LOGO'} style={{width: '100%', height: 'auto'}}/>
            <div style={{width: '100%', flex: 1, overflowY: 'auto'}}>
                <div style={{display: 'flex', flexDirection: 'column', gap: 12}}>
                    <hr style={{margin: 0}}/>
                    <div style={{display: 'flex', flexDirection: 'column', gap: 4}}>
                        <span style={{fontSize: 15, overflow: 'hidden', textAlign: 'center', textOverflow: 'ellipsis', fontWeight: 'bold', color: 'var(--bs-primary)', whiteSpace: 'nowrap'}}>{`${userData.firstName} ${userData.lastName}`}</span>
                        { auth.companyUserIdentifier && 
                            <CompanySelector companies={companies}/>
                        }
                    </div>
                    <hr style={{margin: 0}}/>
                    {largeNavBarSections}
                    <hr style={{margin: 0}}/>
                    <button className='nav-bar-link' style={{border: 'none', width: '100%'}} onClick={() => {setShowLogoutModal(true)}}>
                        <div style={{width: 25, display: 'flex', justifyContent: 'center'}}>
                            <FontAwesomeIcon icon={faRightFromBracket} style={{marginRight: 8}}/>
                        </div>
                        <p style={{fontSize: 18, margin: 0, }}>Logout</p>
                    </button>
                    { auth.adminIdentifier && auth.companyUserIdentifier && 
                        <button className='nav-bar-link' style={{border: 'none', width: '100%', color: 'red'}} onClick={handleStopShadowing}>
                            <div style={{width: 25, display: 'flex', justifyContent: 'center'}}>
                                <FontAwesomeIcon icon={faRightFromBracket} style={{marginRight: 8}}/>
                            </div>
                            <p style={{fontSize: 18, margin: 0}}>Stop Shadowing</p>
                        </button>
                    }
                </div>
            </div>
            <div style={{textAlign: 'center', fontSize: 12}}>
                <p style={{margin: 0}}>{`Copyright © ${moment().format('YYYY')}`}</p>
                <p style={{margin: 0}}>Tactical Transportation Advisors</p>
                <p style={{margin: 0}}>{`Version 1.0.1`}</p>
            </div>
            <Button onClick={() => {ref.current.classList.toggle('navbar-large-transition'); setShowCanvas(!showCanvas)}} style={{color:'white', position: 'absolute', zIndex: 1, bottom: '45%', left: 250, borderTopLeftRadius: 0, borderBottomLeftRadius: 0, padding: 2, height: 100}}>
                <FontAwesomeIcon icon={showCanvas ? faCaretLeft : faCaretRight}/>
            </Button>
        </div>
    )
}