/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, { useState } from 'react';
import CustomButton from './CustomButton';
import { ApiRequest } from '../ApiManager.tsx';
import { Dropdown } from 'react-bootstrap';

export default function FileDownloadButton({label = 'Download', doc, type, target, data, isDropdownItem, style}){
    const [isLoading, setIsLoading] = useState();
    const [base64, setBase64] = useState(doc && doc.base64 ? doc.base64 : undefined);

    async function downloadDoc (docObject){
        const base64 = docObject.base64 ?? docObject?.document?.base64
        setBase64(base64);
        const fetchResponse = await fetch(base64);
        const blob = await fetchResponse.blob();
        const elem = window.document.createElement('a');
        elem.href = window.URL.createObjectURL(blob);
        if(docObject.document){
            elem.download = docObject.document.name + '.' + docObject.document.type;        
        }else{
            elem.download = docObject.name + '.' + docObject.type;        
        }
        document.body.appendChild(elem);
        elem.click();
        document.body.removeChild(elem);
    }

    function handleDownload(){
        if(base64){
            downloadDoc({base64: base64, document: doc});
        }else{
            new ApiRequest(type, target, setIsLoading, async (response) => {
                downloadDoc({...doc, ...response});
            }).withData(data).withNoAlertOnSuccess().send();
        }
    }

    return (
        isDropdownItem ? <Dropdown.Item style={{...style}} onClick={handleDownload}>{label}</Dropdown.Item>
        : <CustomButton style={{...style}} variant={'outline-primary'} isLoading={isLoading} onClick={handleDownload}>{label}</CustomButton>
    )
}