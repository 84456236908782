/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__∂
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Button from 'react-bootstrap/Button';
import Cookies from 'universal-cookie';
import { ApiRequest } from '../../../../ApiManager.tsx';
import moment from 'moment';
import FileDownloadButton from '../../../../components/FileDownloadButton.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Card } from 'react-bootstrap';
import { OnboardingDocumentsReadOnly, getFileIcon, taxDocumentDictionary } from '../../../../tools.js';
import { faDownload, faMagnifyingGlass, faPencil, faSearch, faTrashCan, faUpload } from '@fortawesome/free-solid-svg-icons';
import QuickTable from '../../../../components/QuickTable.js';
import LoadingWrapper from '../../../../components/LoadingWrapper.js';
import FileUploadControl from '../../../../components/FileUploadControl.js';
import AlertConfirmation from '../../../../components/AlertModals/AlertConfirmation.js';
import PDFMerger from 'pdf-merger-js';
import CustomButton from '../../../../components/CustomButton.js';
import DocumentRequestModal from './DocumentRequestModal.js';
import DocumentRequestDetails from './DocumentRequestDetails.js';
import HRUserDocumentEditor from './HRUserDocumentEditor.js';
import DocumentPreview from '../../../../components/DocumentPreview.js';
import SearchBar from '../../../../components/SearchBar.js';
import HRDocumentsUploadModal from './HRDocumentsUploadModal.js';

export default function HRUserDocuments({selectedEmployee, selectedCompany}){
    const [isLoading, setIsLoading] = useState(true);
    const [documents, setDocuments] = useState([]);
    const [search, setSearch] = useState('');
    const [showUploadModal, setShowUploadModal] = useState(false);
    const [docToDelete, setDocToDelete] = useState(undefined);
    const [showEditModal, setShowEditModal] = useState(false);
    const [selectedDoc, setSelectedDoc] = useState(undefined);
    const [showPreviewModal, setShowPreviewModal] = useState(false);
    const [edDocumentRequests, setEdDocumentRequests] = useState([]);
    const [showDocumentRequestModal, setShowDocumentRequestModal] = useState(false);
    const [showDocumentRequestDetails, setShowDocumentRequestDetails] = useState(false);
    const [selectedEdDocumentRequest, setSelectedEdDocumentRequest] = useState(undefined);
    const [isDeleting, setIsDeleting] = useState(undefined);

    useEffect(() => {
        loadData();
    }, [selectedEmployee])

    function loadData(){
        new ApiRequest('hr', 'getAllDocuments', setIsLoading, (response) => {
            setDocuments(response.customDocuments);
            setEdDocumentRequests(response.edDocumentRequests);
        }).withData({companyUserIdentifier: selectedEmployee.companyUserIdentifier}).withNoAlertOnSuccess().send();
    }

    function handleDeleteDocument(){
        new ApiRequest('hr', 'deleteCustomDocument', setIsDeleting, () => {
            const newArray = Array.from(documents).filter(r => r.fileIdentifier != docToDelete);
            setDocuments(newArray);
            setDocToDelete(undefined);
        }).withData({fileIdentifier: docToDelete}).send();
    }

    function handleUploadDocumentsCrud(docObjs){
        const newArray = Array.from(documents);
        docObjs.forEach((obj)=>{
            newArray.push(obj);
        })
        setDocuments(newArray);
        setShowUploadModal(false);
    }

    function handleRemoveEdDocument(documentRequestIdentifier, type) {
        const newArray = Array.from(edDocumentRequests);
        const request = newArray.find(r => r.uid == documentRequestIdentifier);
        delete request.documents[type];
        setEdDocumentRequests(newArray);
    }

    function handleSetEDDocumentIncomplete(documentRequestIdentifier, type) {
        const newArray = Array.from(edDocumentRequests);
        const request = newArray.find(r => r.uid == documentRequestIdentifier);
        request.documents[type].employeeCompleted = null;
        setEdDocumentRequests(newArray);
    }

    function handleEditButtonClick(doc){
        setSelectedDoc(doc);
        setShowEditModal(true);
    }

    function handleEditHrDocumentName(document){
        const newArray = documents.map((doc)=>{
            if(doc.fileIdentifier == document.fileIdentifier){
                return document;
            }
            return doc;
        });
        setDocuments(newArray);
        setShowEditModal(false);
    }

   function handlePreviewButtonClick(doc){
        setSelectedDoc(doc)
        setShowPreviewModal(true);
   }

    const filteredDocuments = documents.filter((doc) => {
        return doc.name.toLowerCase().includes(search.toLowerCase());
    });

    const documentsElements = filteredDocuments.sort((a, b) => moment(b.timestamp).valueOf() - moment(a.timestamp).valueOf()).map((doc) => {
        return (
        <Card key={doc.fileIdentifier} style={{ display: 'flex', flexDirection: 'column', marginBottom: 12 }}>
            <div style={{ display: 'flex', flexDirection:'row', padding: '12px 18px', backgroundColor: 'var(--bs-primary)', alignItems: 'center', borderTopLeftRadius: 6, borderTopRightRadius: 6 }}>
            <FontAwesomeIcon icon={getFileIcon(doc.type)} style={{ color: 'white', marginRight: 8 }} />
            {doc.dateTime ? (
                <span style={{ color: 'white', fontSize: '0.8rem' }}>
                {moment(doc.dateTime).format('MM/DD/YYYY hh:mm A')}
                </span>
            ) : (
                <span style={{ color: 'white', fontSize: '0.8rem' }}>
                {moment().format('MM/DD/YYYY hh:mm A')}
                </span>
            )}
            </div>
            <div style={{display: 'flex', alignItems: 'center', padding: '8px 18px', border: '2px solid var(--bs-primary)', borderBottomLeftRadius: 6,borderBottomRightRadius: 6, flexWrap: 'wrap', gap: 12}}>
                <div style={{ flex: 1, minWidth: 150}}>
                    <p style={{ fontSize: '1rem', margin: 0}}>{doc.name}</p>
                </div>
                <div style={{ display: 'flex', gap: 8 }}>
                    {doc.type === 'pdf' &&<Button variant={'outline-primary'} style={{fontSize: '0.8rem', padding: '4px 8px' }} onClick={() => handlePreviewButtonClick(doc)}><FontAwesomeIcon size='xl' icon={faMagnifyingGlass}/></Button>}
                {(doc.type === 'jpeg' || doc.type === 'png' || doc.type === 'jpg') && <Button variant={'outline-primary'} style={{fontSize: '0.8rem', padding: '4px 8px' }} onClick={() => handlePreviewButtonClick(doc)}><FontAwesomeIcon size='xl' icon={faMagnifyingGlass}/></Button>}
                    <FileDownloadButton doc={doc} type='hr' target='downloadCustomDocument' data={{fileIdentifier: doc.fileIdentifier}}label={<FontAwesomeIcon size='lg' icon={faDownload}/>}/>
                    <Button variant={'outline-primary'} style={{fontSize: '0.8rem', padding: '4px 8px' }} onClick={() => handleEditButtonClick(doc)}><FontAwesomeIcon size='xl' icon={faPencil}/></Button>
                    <Button variant={'outline-primary'} style={{fontSize: '0.8rem', padding: '4px 8px' }} onClick={() => setDocToDelete(doc.fileIdentifier)}><FontAwesomeIcon size='xl' icon={faTrashCan}/></Button>
                </div>
            </div>
        </Card>
        );
    });

    const edRequestElements = edDocumentRequests.map((request) => {
        return (
            <DocumentRequestElement key={request.uid} request={request} selectedEmployee={selectedEmployee} setSelectedEdDocumentRequest={setSelectedEdDocumentRequest} setShowDocumentRequestDetails={setShowDocumentRequestDetails}/>
        )
    })

    const content = (
        <div style={{display: 'flex', flexDirection: 'column', gap: 12, height: '100%', overflowY: 'hidden'}}>
            <div style={{display: 'flex', alignItems: 'center', gap: 12, flexWrap: 'wrap'}}>
                <div style={{flex: 1, minWidth: 300}}>
                    <SearchBar label={'Search for a document by title'} value={search} setValue={setSearch} />
                </div>
                { (selectedEmployee.title || selectedEmployee.firstPayDateEffective) &&
                    <div style={{display: 'flex', alignItems: 'center', gap: 8, width: 'fit-content', flexShrink: 0, marginLeft: 'auto'}}>
                        <Button variant={'outline-primary'} onClick={() => setShowUploadModal(true)}><FontAwesomeIcon size='xl' icon={faUpload}/></Button>
                        <Button variant='outline-primary' onClick={() => setShowDocumentRequestModal(true)}>Send Employee Document Request</Button>
                    </div>
                }
            </div>
            <div style={{flex: 1, overflowY: 'auto'}}>
                { edRequestElements.length > 0 && 
                    <QuickTable noWrap title='Document Requests' headers={['Date Created', 'Documents Requested', 'Status', '']} rows={edRequestElements} size='sm' hover/>
                }
                { documentsElements.length > 0 && 
                    <div style={{display: 'flex', flexDirection: 'column', gap: 12, backgroundColor: 'rgb(240, 240, 240)', padding: 8, borderRadius: 6, marginTop: 8, maxHeight: '70vh', overflowY: 'auto'}}>
                        {documentsElements}
                    </div>
                }
            </div>
        </div>
    );

    return (
        <>
            <LoadingWrapper isLoading={isLoading}>{content}</LoadingWrapper>
            <Modal size='lg' show={showUploadModal} onHide={() => setShowUploadModal(false)} centered>
                <HRDocumentsUploadModal companyUserIdentifier={selectedEmployee.companyUserIdentifier} handleUploadDocumentsCrud={handleUploadDocumentsCrud}/>
            </Modal>
            <Modal centered show={docToDelete} onHide={() => setDocToDelete(undefined)}>
                <AlertConfirmation isLoading={isDeleting} variant={'outline-danger'} title='Deletion Confirmation' message='Permanently delete document?' buttonLabel='Delete' callBack={handleDeleteDocument}/>
            </Modal>
            <Modal show={showEditModal} onHide={() => setShowEditModal(false)} centered>
                <HRUserDocumentEditor document={selectedDoc} handleEditHrDocumentName={handleEditHrDocumentName}/>  
            </Modal>
            <Modal size='xl' show={showPreviewModal} onHide={() => setShowPreviewModal(false)}>
                <DocumentPreview type='hr' target='downloadCustomDocument' document={selectedDoc} hr={true}/>
            </Modal>
            <Modal size='xl' fullscreen='xl-down' show={showDocumentRequestDetails} onHide={() => setShowDocumentRequestDetails(false)}>
                <DocumentRequestDetails selectedEmployee={selectedEmployee} selectedCompany={selectedCompany} documentRequest={selectedEdDocumentRequest} handleRemoveEdDocument={handleRemoveEdDocument} handleSetEDDocumentIncomplete={handleSetEDDocumentIncomplete}/>
            </Modal>
            <Modal size='xl' fullscreen='xl-down' show={showDocumentRequestModal} onHide={() => setShowDocumentRequestModal(false)}>
                <DocumentRequestModal companyUserIdentifier={selectedEmployee.companyUserIdentifier} selectedCompany={selectedCompany} hideModal={() => {setShowDocumentRequestModal(false); loadData()}}/>
            </Modal>
        </>
    )

}


function DocumentRequestElement({request, selectedEmployee, setSelectedEdDocumentRequest, setShowDocumentRequestDetails}) {
    const [isDownloadingRequest, setIsDownloadingRequest] = useState(undefined);

    function handleDownloadDocumentRequest(documentRequest){
        new ApiRequest('hr', 'downloadDocumentRequest', setIsDownloadingRequest, async (response) => {
            const merger = new PDFMerger();
            const sortedTypes = ['offerLetter', 'i9', 'w4', ...Object.keys(taxDocumentDictionary), 'dd', 'mdd', 'f8850'].filter(d => Object.keys(response.documents).includes(d));

            for (let i = 0; i < sortedTypes.length; i++) {
                const document = response.documents[sortedTypes[i]];
                if (document.base64) {
                    const fetchResponse = await fetch(document.base64);
                    const blob = await fetchResponse.blob();
                    await merger.add(blob, null);
                }
            }
    
            const mergedPdf = await merger.saveAsBlob();
            const elem = window.document.createElement('a');
            elem.target = '_blank';
            elem.href = window.URL.createObjectURL(mergedPdf);
            elem.download = `${selectedEmployee?.firstName ?? ''} ${selectedEmployee?.lastName ?? ''} Document Request ${moment(documentRequest.dateCreated).format('MMM D, YYYY')}`;        
            document.body.appendChild(elem);
            elem.click();
            document.body.removeChild(elem);
        }).withUid(documentRequest.uid).withNoAlertOnSuccess().send()
    }

    const includedDocuments =  Object.keys(request.documents);
    let documents = ['offerLetter', 'i9', 'w4', ...Object.keys(taxDocumentDictionary), 'dd', 'f8850'].filter(d => includedDocuments.includes(d)).map(d => OnboardingDocumentsReadOnly[d]);

    if(request.documents.i9){
        documents.push(`Driver's License`)
        documents.push('Social Security Card')
    }
    documents = documents.join(', ');

    const status = Object.keys(request.documents).reduce((prev, curr) => {
        if (prev === 'Awaiting Employee') {
            return prev;
        } else {
            if (!request.documents[curr].employeeCompleted && !request.documents[curr].archived) {
                return 'Awaiting Employee';
            } else if ('employerCompleted' in request.documents[curr] && !request.documents[curr].employerCompleted && !request.documents[curr].archived) {
                return 'Awaiting Employer';
            } else if((request.i9 && !request.dlDataIdentifier) || (request.i9 && !request.ssnDataIdentifier)){
                return 'Awaiting Employee';
            } else {
                return prev;
            }
        }
    }, 'Complete');
    
    return (
        <tr className='cursor-pointer' key={request.uid} onClick={() => {setSelectedEdDocumentRequest(request); setShowDocumentRequestDetails(true)}}>
            <td>{moment(request.dateCreated).format('MMM D, YYYY')}</td>
            <td>{documents}</td>
            <td>{status}</td>
            <td>
                <div onClick={(e) => e.stopPropagation()}>
                    <CustomButton title='Download' style={{padding: '0px 6px 0px 6px'}} isLoading={isDownloadingRequest} onClick={() => handleDownloadDocumentRequest(request)}>
                        <FontAwesomeIcon icon={faDownload}/>
                    </CustomButton>
                </div>
            </td>
        </tr>
    )
}