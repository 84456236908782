/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/


import React, { useState } from "react";
import moment from "moment";
import { HRUserPayPreview, HRUserTerminationPreview } from "../../../../HumanResources/HRUserPay/HRUserPay.js";
import { Dropdown, Modal } from "react-bootstrap";
import { formatDateRange } from "../../../../../../tools.js";
import { useLoadData } from "../../../../../../hooks.tsx";
import { ApiRequest } from "../../../../../../ApiManager.tsx";
import LoadingWrapper from "../../../../../../components/LoadingWrapper.js";

export default function PayInfo({entry, payrollPeriod}) {
    const [pay, setPay] = useState([]);
    const [selectedPay, setSelectedPay] = useState(undefined);
    const [isLoading, setIsLoading] = useState(false);

    useLoadData(() => {
        new ApiRequest('payroll', 'getEmployeePay', setIsLoading, (response) => {
            const payAndTerminations = response.pay.concat(response.terminations).sort((a, b) => {
                return a.dateEffective < b.dateEffective ? -1 : a.dateEffective < b.dateEffective ? 1 : 0;
            });
            setPay(payAndTerminations);
            setSelectedPay(response.pay[0]);
        }).withNoAlertOnSuccess().withData({companyUserIdentifier: entry.companyUserIdentifier, payrollIdentifier: payrollPeriod.uid}).send()
    })

    const payElements = pay.map((p) => {
        return (
            <Dropdown.Item key={`${p.uid}${p.title ?? ''}`} onClick={() => {setSelectedPay(p)}} active={selectedPay == p}>
                {`${moment(p.dateEffective).format('MMM D, YYYY')} - ${p.title ?? 'Termination'}`}
            </Dropdown.Item>
        )
    })

    return (
        <>
            <Modal.Header closeButton>
                <Modal.Title>{`Effective Pay For ${entry.firstName} ${entry.lastName} Between ${formatDateRange(payrollPeriod.periodStart, payrollPeriod.periodEnd)}`}</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{display: 'flex', flexDirection: 'column', gap: 12}}>
                <LoadingWrapper isLoading={isLoading}>
                    { selectedPay ?
                        <>
                            { pay.length > 1 && 
                                <Dropdown style={{alignSelf: 'flex-end'}}>
                                    <Dropdown.Toggle variant="outline-primary">{`${moment(selectedPay.dateEffective).format('MMM D, YYYY')} - ${selectedPay.title ?? 'Termination'}`}</Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        {payElements}
                                    </Dropdown.Menu>
                                </Dropdown>
                            }
                            { selectedPay.title ?
                                <HRUserPayPreview pay={selectedPay}/>
                            :
                                <HRUserTerminationPreview termination={selectedPay}/>
                            }
                        </>

                    :
                        <span style={{textAlign: 'center', fontSize: 20, fontWeight: 'bold', opacity: 0.5}}>None</span>
                    }
                </LoadingWrapper>
            </Modal.Body>
        </>
    )
}