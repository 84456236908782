/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import { usePreview } from 'react-dnd-preview';

export default function CustomPreviews() {
    const { display, style, itemType, item } = usePreview();
    if (!display) {
        return null;
    }

    const getPreviewStyle = () => {
        return { ...style, zIndex: 1000000, width: item.dimensions.width, height: item.dimensions.height };
    };

    return (
        <div style={getPreviewStyle()}>
            {item.content} {/* Use the content from the item */}
        </div>
    );
}