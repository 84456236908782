/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React from 'react'
import KeyValueRow from '../../../components/KeyValueRow'
import { Card, Col, Container, ListGroup, Row } from 'react-bootstrap'
import { availabilityToString, payTypeDictionary, usdFormatter, validateDecimal } from "../../../tools";
import { toFixedMax } from '../../../tools';
import moment from 'moment';

export default function PositionInfo({user}) {

    const pay = user.pay;

    return (
        <div style={{display: 'flex', flexDirection: 'column', gap: 12}}>
            <p style={{textAlign: 'center', margin: 0, fontSize: 24, fontWeight: 'bold', padding:10}}>Position at {user.companyName}</p>
            <Card>
                <Card.Body style={{display:'flex', flexDirection:'column'}}>
                    <Card.Title style={{textAlign:'center', paddingBottom:10}}>Employment Details</Card.Title>
                    <ListGroup>
                        <KeyValueRow isListItem title={'Effective Date'} value={moment(pay.dateEffective).format('MMM D, YYYY')} />
                        <KeyValueRow isListItem title={'Title'} value={pay.title} />
                        <KeyValueRow isListItem title={'Employee Type'} value={pay.fulltime == 1 ? 'Full-Time' : 'Part-Time'}/>
                        <KeyValueRow isListItem title={'Expected Availability'} value={availabilityToString(user.availability)}/>
                    </ListGroup>
                </Card.Body>
            </Card>
            <Card>
                <Card.Body style={{display:'flex', flexDirection:'column'}}>
                    <Card.Title style={{textAlign:'center', paddingBottom:10}}>Pay Earnings</Card.Title>
                    <ListGroup>
                        <KeyValueRow isListItem title={'Pay Type'} value={payTypeDictionary[pay.payType]} />
                        <KeyValueRow isListItem title={'Pay Rate'} value={usdFormatter.format(pay.payRate)} />
                        <KeyValueRow isListItem title={`PTO Accrual Per ${pay.ptoAccrualType == 0 ? '40 Hours' : 'Pay Period'}`} value={`${toFixedMax(pay.ptoAccrual, 3)} Hour(s)`}/>
                    </ListGroup>
                </Card.Body>
            </Card>
            <Card>
                <Card.Body style={{display:'flex', flexDirection:'column'}}>
                    <Card.Title style={{textAlign:'center', paddingBottom:10}}>Pay Deductions</Card.Title>
                    <ListGroup>
                        <KeyValueRow isListItem title={'Medical'} value={usdFormatter.format(pay.medical)} />
                        <KeyValueRow isListItem title={'Dental'} value={usdFormatter.format(pay.dental)} />
                        <KeyValueRow isListItem title={'Vision'} value={usdFormatter.format(pay.vision)}/>
                        <KeyValueRow isListItem title={'Child Support'} value={usdFormatter.format(pay.childSupport.reduce((acc, el) => {return acc + validateDecimal(el)}, 0))}/>
                    </ListGroup>
                </Card.Body>
            </Card>
        </div>
    )
}
