/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React from "react";
import { usdFormatter } from "../../../../../tools.js";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { Card, ButtonGroup, Dropdown, Modal } from "react-bootstrap";
import CustomToolTip from "../../../../../components/CustomToolTip.js";
import PayrollEmployeeDeductionsEditor from "./Deductions/PayrollEmployeeDeductionsEditor.js";
import { useModalSwitch } from "../../../../../hooks.tsx";
import PayrollEmployeePayEditor from "./Pay/PayrollEmployeePayEditor.js";
import CustomTextArea from "../../../../../components/CustomTextArea.js";
import PayInfo from "./Modals/PayInfo.js";
import PayrollEmployeeTimesheetInfo from "./Modals/PayrollEmployeeTimesheetInfo.js";
import PreviousEntryModal from "./Modals/PreviousEntryModal.js";
import RegeneratePayrollEntry from "./Modals/RegeneratePayrollEntryModal.js";

export default function PayrollEntryEditor({payrollPeriod, entry, setEntryProp, handleProceed, handleAddItemToAllEntries, handleReplacePayrollEntry}) {
    const [modalSwitch, setModalSwitch, hideModal] = useModalSwitch();

    const handleSetWeek = (index, key, value) => {
        const weeks = Array.from(entry.weeks);
        weeks[index][key] = value;
        setEntryProp(entry.companyUserIdentifier, 'weeks', weeks);
    }

    const handleToggleApproved = () => {
        if (!entry.approved) {
            handleProceed();
        }
        setEntryProp(entry.companyUserIdentifier, 'approved', !entry.approved);
    }

    const toolMenuMobile = (
        <Dropdown className="mobile-block">
            <Dropdown.Toggle variant={'outline-primary'}>Tools</Dropdown.Toggle>
            <Dropdown.Menu>
                <Dropdown.Item onClick={() => {setModalSwitch('previousEntry')}} disabled={!entry.previousEntry}>Previous Entry</Dropdown.Item>
                <Dropdown.Item onClick={() => {setModalSwitch('timesheet')}}>Timesheets</Dropdown.Item>
                <Dropdown.Item onClick={() => {setModalSwitch('payInfo')}}>Pay Info</Dropdown.Item>
                <Dropdown.Item onClick={() => {setModalSwitch('regenerateEntry')}}>Regenerate Entry</Dropdown.Item>
            </Dropdown.Menu>
        </Dropdown>
    )
    const toolMenuDesktop = (
        <ButtonGroup className="desktop-inline-flex">
            <Button variant="outline-primary" style={{padding: '0px 6px'}} onClick={() => {setModalSwitch('previousEntry')}} disabled={!entry.previousEntry}>Previous Entry</Button>
            <Button variant="outline-primary" style={{padding: '0px 6px'}} onClick={() => {setModalSwitch('timesheet')}}>Timesheets</Button>
            <Button variant="outline-primary" style={{padding: '0px 6px'}} onClick={() => {setModalSwitch('payInfo')}}>Pay Info</Button>
            <Button variant="outline-primary" style={{padding: '0px 6px'}} onClick={() => {setModalSwitch('regenerateEntry')}}>Regenerate Entry</Button>
        </ButtonGroup>
    )

    return (
        <Card style={{display: 'flex', flexDirection: 'column', overflowY: 'hidden', flex: 1}}>
            <Card.Header style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: 12}}>
                <Card.Title style={{fontWeight: 'bold', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>{`${entry.firstName} ${entry.middleName} ${entry.lastName}`}</Card.Title>
                {toolMenuMobile}
                {toolMenuDesktop}
                <Card.Title style={{fontWeight: 'bold', color: 'green'}}>{usdFormatter.format(entry.gross())}</Card.Title>
            </Card.Header>

            <div style={{overflowY: 'auto', flex: 1}}>
                <PayrollEmployeePayEditor 
                    entry={entry} 
                    handleSet={setEntryProp} 
                    handleSetWeek={handleSetWeek} 
                    handleAddItemToAllEntries={handleAddItemToAllEntries}
                    payrollPeriod={payrollPeriod}
                />
                <hr style={{margin: 0}}/>
                <PayrollEmployeeDeductionsEditor entry={entry} handleSet={setEntryProp} payrollPeriod={payrollPeriod} handleAddItemToAllEntries={handleAddItemToAllEntries}/>
                <hr style={{margin: 0}}/>
                <div style={{padding: 6, display: 'flex', flexDirection: 'column', gap: 6, backgroundColor: 'white'}}>
                    <div style={{display: 'flex', gap: 12, alignItems: 'center', flexWrap: 'wrap'}}>
                        <span style={{fontSize: 25, fontWeight: 'bold'}}>Notes</span>
                        { entry.previousEntry &&
                            <Button style={{padding: 4}} variant="outline-primary" onClick={() => {setEntryProp(entry.companyUserIdentifier, 'notes', entry.previousEntry.notes)}}>
                                Import from Previous Payroll Period
                            </Button>
                        }
                        <div style={{display: 'flex', alignItems: 'center', gap: 12, border: '1px solid lightgray', borderRadius: 6, padding: '3px 8px 3px 8px'}}>
                            <Form.Check type='switch' label='Reoccurring' checked={entry.reoccurringNotes} onChange={() => {setEntryProp(entry.companyUserIdentifier, 'reoccurringNotes', !entry.reoccurringNotes)}}/>
                            <CustomToolTip text={'Next payroll period, these notes will be automatically added to this employee'}/>
                        </div>
                    </div>
                    <CustomTextArea rows={3} maxLength={300} value={entry.notes} onChange={(event) => {setEntryProp(entry.companyUserIdentifier, 'notes', event.target.value)}}/>
                </div>
            </div>
            <Card.Footer style={{display: 'flex', justifyContent: 'flex-end'}}>
                <Button variant={entry.approved ? 'outline-danger' : 'outline-success'} style={{fontWeight: 'bold'}} onClick={handleToggleApproved}>{entry.approved ? 'Undo Approval' : 'Approve'}</Button>
            </Card.Footer>
            
            
            <Modal show={modalSwitch === 'timesheet'} onHide={hideModal} size='xl' fullscreen='xl-down'>
                <PayrollEmployeeTimesheetInfo entry={entry} payrollPeriod={payrollPeriod}/>
            </Modal>
            <Modal show={modalSwitch === 'payInfo'} onHide={hideModal} size='xl' fullscreen='xl-down'>
                <PayInfo entry={entry} payrollPeriod={payrollPeriod}/>
            </Modal>
            <Modal show={modalSwitch === 'previousEntry'} onHide={hideModal} size='xl' fullscreen='xl-down'>
                <PreviousEntryModal entry={entry.previousEntry}/>
            </Modal>
            <Modal show={modalSwitch === 'regenerateEntry'} onHide={hideModal}>
                <RegeneratePayrollEntry entry={entry} payrollPeriod={payrollPeriod} handleReplacePayrollEntry={handleReplacePayrollEntry} hideModal={hideModal}/>
            </Modal>
        </Card>

    )

}