/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import { useState } from "react"
import { ListGroup, Modal } from "react-bootstrap";
import CustomButton from "../../../../components/CustomButton";
import KeyValueRow from "../../../../components/KeyValueRow";
import moment from "moment";
import CustomTextArea from "../../../../components/CustomStateControls/CustomTextArea";
import AlertConfirmation from "../../../../components/AlertModals/AlertConfirmation";
import { ApiRequest } from "../../../../ApiManager.tsx";
import HRUserSchedulePTODaySelector from "./HRUserSchedulePTODaySelector.js";

export default function HRUserScheduleTimeOffModal({timeOff, handleScheduleItemsCrud, hideNotes, selectedCompany}){
    const [isUpdating, setIsUpdating] = useState(false)
    const [isDeleting, setIsDeleting] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [modalSwitch, setModalSwitch] = useState('');
    const [notes, setNotes] = useState(timeOff.notes ?? '');
    
    function updateTimeOffRequest(){
        new ApiRequest('hr', 'updateTimeOff', setIsUpdating, () => {
            const newTimeOff = structuredClone(timeOff);
            newTimeOff.notes = notes;
            handleScheduleItemsCrud('timeOff', 'update', newTimeOff.uid, newTimeOff)
        }).withData({uid: timeOff.uid, notes: notes}).send()
    }

    function rejectTimeOffRequest(){
        const timeOffToSend = {
            ...timeOff,
            notes: notes
        }
        new ApiRequest('hr', 'rejectTimeOff', setIsDeleting, () => {
            const newTimeOff = structuredClone(timeOffToSend);
            newTimeOff.status = -1;
            handleScheduleItemsCrud('timeOff', 'update', newTimeOff.uid, newTimeOff)
        }).withData({timeOff: timeOffToSend}).send()
    }

    function deleteTimeOffRequest(){
        new ApiRequest('hr', 'deleteTimeOff', setIsDeleting, () => {
            handleScheduleItemsCrud('timeOff', 'delete', timeOff.uid)
        }).withUid(timeOff.uid).send()
    }

    function handleCrud(_response, _timeOff, ptoDays){
        const newTimeOff = {...timeOff, notes: notes};
        newTimeOff.status = 1;
        handleScheduleItemsCrud('timeOff', 'update', newTimeOff.uid, newTimeOff, Boolean(ptoDays?.length))
    }

    function hideModal(){
        setModalSwitch('')
    }

    return (
        <>
            <Modal.Header closeButton>
                <Modal.Title>Viewing {timeOff.status == 1 ? 'Rejected' : timeOff.status == -1 ? 'Accepted' : 'Pending'} Time Off Request</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <ListGroup>
                    <KeyValueRow isListItem title={'Start Date'} value={moment(timeOff.startDate).format('MMM D, YYYY')}/>
                    <KeyValueRow isListItem title={'End Date'} value={moment(timeOff.endDate).format('MMM D, YYYY')}/>
                    <KeyValueRow isListItem title={'Submission Date'} value={moment(timeOff.submissionDate).format('MMM D, YYYY')}/>
                    <KeyValueRow isListItem title={'Request As PTO'} value={timeOff.requestedAsPto ? 'Yes' : 'No'}/>
                    <KeyValueRow isListItem title={'Reason'} value={timeOff.reason}/>
                </ListGroup>
                { !hideNotes &&
                    <div style={{padding: 12}}>
                        <CustomTextArea placeholder={'Notes'} value={notes} setValue={setNotes} rows={3} max={250}/>
                       {timeOff.status != 0 && <CustomButton isLoading={isUpdating} onClick={updateTimeOffRequest} style={{display: 'block', marginLeft: 'auto', marginTop: 12}}>Update Notes</CustomButton>}
                    </div>
                }
            </Modal.Body>
            <Modal.Footer>
                {timeOff.status !== 0 ? <CustomButton onClick={()=> setModalSwitch('delete')} variant='outline-danger'>Delete Request</CustomButton>
                :
                    <>
                        <CustomButton onClick={()=> setModalSwitch('reject')} variant='outline-danger'>Reject Request</CustomButton>
                        <CustomButton onClick={()=> setModalSwitch('accept')} variant='outline-primary'>Accept Request</CustomButton>
                    </>
                }
            </Modal.Footer>
            <Modal centered show={modalSwitch === 'delete'} onHide={hideModal}>
                <AlertConfirmation isLoading={isDeleting} callBack={deleteTimeOffRequest} title='Delete Request' variant='outline-danger' buttonLabel={'Delete'} message={`Are you sure you want to delete this time off item? This will also delete any PTO transactions that exist for this item.`}/>
            </Modal>
            <Modal centered show={modalSwitch === 'reject'} onHide={hideModal}>
                <AlertConfirmation isLoading={isDeleting} callBack={rejectTimeOffRequest} title='Reject Request' variant='outline-danger' buttonLabel={'Reject'} message={`Are you sure you want to reject this time off item?`}/>
            </Modal>
            <Modal centered show={modalSwitch === 'accept'} onHide={hideModal}>
                <HRUserSchedulePTODaySelector selectedCompany={selectedCompany} timeOff={timeOff} notes={notes} handleCrud={handleCrud}/>
            </Modal>
        </>
    )
}