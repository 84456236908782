/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React from "react";
import Modal from 'react-bootstrap/Modal';
import PayrollDBonusesModal from "./PayrollDBonusesModal.js";
import { bigToUsd } from "../../../../payrollTools.js";
import { useModalToggle } from "../../../../../../../hooks.tsx";
import PayrollEntryPaySection from "../PayrollEntryPaySection.js";
import PayrollDBonusItem from "./PayrollDBonusItem.js";
import QuickTable from "../../../../../../../components/QuickTable.js";

export default function PayrollEmployeeDiscretionaryBonuses({entry, handleSetWeek, handleAddItemToAllEntries}) {
    const [modalIsVisible, showModal, hideModal] = useModalToggle();

    const handleRemoveDBonus = (uid) => {
        const newArr = entry.weeks[entry.weekIndex].dBonuses.filter((element) => {
            return element.uid !== uid;
        });
        handleSetWeek(entry.weekIndex, 'dBonuses', newArr);
    }

    const handleSetDBonus = (uid, key, value) => {
        const newArr = Array.from(entry.weeks[entry.weekIndex].dBonuses);
        newArr.find(r => r.uid === uid)[key] = value;
        handleSetWeek(entry.weekIndex, 'dBonuses', newArr);
    }

    const handleAddDBonus = (dBonus, applyToAll) => {
        if (applyToAll) {
            handleAddItemToAllEntries('dBonuses', dBonus, entry.weekIndex);
        } else {
            const newArr = Array.from(entry.weeks[entry.weekIndex].dBonuses);
            newArr.push(dBonus);
            handleSetWeek(entry.weekIndex, 'dBonuses', newArr);
        }
        hideModal();
    }

    const dBonusRows = entry.weeks[entry.weekIndex].dBonuses.map((dBonus) => {
        return (
            <PayrollDBonusItem key={dBonus.uid} dBonus={dBonus} handleSetDBonus={handleSetDBonus} handleRemoveDBonus={handleRemoveDBonus}/>
        )
    });

    return (
        <PayrollEntryPaySection title={'DISCRETIONARY BONUSES'} showModal={showModal}>
            { entry.weeks[entry.weekIndex].dBonuses.length > 0 &&  
                <div style={{display: 'flex', flexDirection: 'column', gap: 6}}>
                    <div style={{overflowX: 'auto'}}>
                        <QuickTable noMargin noWrap size='sm' rows={dBonusRows} headers={['Type', 'Amount', '']} widths={[1, null, 1]}/>
                    </div>
                    <div style={{display: 'flex', justifyContent: 'flex-end', fontWeight: 'bold', gap: 6}}>
                        <span>Total:</span>
                        <span style={{color: 'green', }}>{bigToUsd(entry.weeks[entry.weekIndex].totalDBonuses())}</span>
                    </div>
                </div>
            }
            <Modal show={modalIsVisible} onHide={hideModal} fullscreen='lg-down'>
                <PayrollDBonusesModal handleAddDBonus={handleAddDBonus}/>
            </Modal>
        </PayrollEntryPaySection>
    )
}