/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, { useMemo, useState } from 'react';
import Modal from "react-bootstrap/Modal";
import { Validation, ValidationGroup, useStateWithValidation, useValidationReducer } from '../../../validation.tsx';
import moment from 'moment';
import CustomButton from '../../../components/CustomButton.js';
import { Dropdown } from 'react-bootstrap';
import DropdownItemWithCheck from '../../../components/DropdownItemWithCheck.js';
import { ApiRequest } from '../../../ApiManager.tsx';
import CustomDatePicker from '../../../components/CustomDatePicker/CustomDatePicker.js';
import TextAreaControl from '../../../components/TextAreaControl.js';
import CustomCheck from '../../../components/CustomCheck.js';
import RadioControl from '../../../components/RadioControl.js';

export default function RequestOffModal({handleAddRequestOff}) {
    const [startDate, setStartDate, startDateVM] = useStateWithValidation('', Validation.nonEmptyString);
    const [endDate, setEndDate, endDateVM] = useStateWithValidation('', Validation.nonEmptyString);
    const [requestedAsPto, setRequestedAsPto, requestedAsPtoVM] = useStateWithValidation(undefined, Validation.notUndefined);
    const [reason, setReason, reasonVM] = useStateWithValidation('', Validation.nonEmptyString);
    const isValid = useValidationReducer([startDateVM, endDateVM, requestedAsPtoVM, reasonVM]);

    const [isLoading, setIsLoading] = useState(false);

    const minDate = moment().add(1, 'days').format('YYYY-MM-DD');


    function handleSubmit() {
        const requestOff = {
            startDate: startDate,
            endDate: endDate,
            reason: reason,
            requestedAsPto: requestedAsPto,
            status: 0
        };

        new ApiRequest('mySchedule', 'requestOff', setIsLoading, (response) => {
            requestOff.uid = response.uid;
            handleAddRequestOff(requestOff);
        }).withData({requestOff: requestOff}).send();
    }


    return (
        <>
            <Modal.Header closeButton>
                <Modal.Title>Request Off</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{display: 'flex', flexDirection: 'column', gap: 12}}>
                <CustomDatePicker title='Date Range' startDate={startDate} endDate={endDate} setStartDate={setStartDate} setEndDate={setEndDate} minDate={minDate} validate validationMessage={startDateVM ?? endDateVM}/>
                <RadioControl title={'Request As'} inline selection={requestedAsPto} setSelection={setRequestedAsPto} optionValues={[true, false]} optionNames={['Paid Time Off', 'Unpaid Time Off']} validate validationMessage={requestedAsPtoVM}/>
                <TextAreaControl value={reason} setValue={setReason} title='Reason' rows={4} validate validationMessage={reasonVM}/>
            </Modal.Body>
            <Modal.Footer>
                <CustomButton onClick={handleSubmit} disabled={!isValid} isLoading={isLoading}>Submit</CustomButton>
            </Modal.Footer>
        </>
    )
}