/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React from "react";
import { usdFormatter } from "../../../../../../../tools.js";
import { Form } from "react-bootstrap";

export default function PayrollLoanItem({loan, handleSetLoan}) {
    return (
        <tr style={{verticalAlign: 'middle', whiteSpace: 'nowrap'}}>
            <td style={{opacity: loan.isEnabled ? 1 : 0.5, fontWeight: 'bold'}}>{loan.isTicket ? 'Fine Ticket Damage' : 'Loan'}</td>
            <td style={{opacity: loan.isEnabled ? 1 : 0.5}}>{loan.name}</td>
            <td style={{color: loan.isEnabled ? 'red' : 'gray'}}>{`${usdFormatter.format(loan.amount)}`}</td>
            <td>
                <Form.Check type='switch' checked={loan.isEnabled} onChange={() => {handleSetLoan(loan.loanIdentifier, 'isEnabled', !loan.isEnabled)}} disabled={!handleSetLoan}/>
            </td>
        </tr>
    )
}