/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React from "react";
import InputGroup from 'react-bootstrap/InputGroup';
import Button from 'react-bootstrap/Button';
import Dropdown from 'react-bootstrap/Dropdown';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { bigToUsd } from "../../../../payrollTools.js";
import Holiday from "../../../../Models/Holiday.js";
import PayrollIntegerControl from "../../../PayrollIntegerControl.js";
import PayrollMoneyControl from "../../../PayrollMoneyControl.js";

export default function PayrollHolidayItem({holiday, handleSetHoliday, removeHoliday}) {

    const payTypeDropDownItems = Object.entries(Holiday.holidayPayTypes).map(([key, label]) =>{
        return (
            <Dropdown.Item key={key} active={holiday.payType === key} onClick={() => {handleSetHoliday(holiday.uid, 'payType', key)}}>
                <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                    <p style={{marginBottom: 0, marginRight: 8}}>{label}</p> 
                </div>
            </Dropdown.Item>
        )
    });

    return (
        <tr style={{verticalAlign: 'middle', textAlign: 'center'}}>
            <td>
                <PayrollMoneyControl style={{minWidth: 100, height: 32}} lhDecimalPlaces={4} value={holiday.payRate} setValue={(newValue) => {handleSetHoliday(holiday.uid, 'payRate', newValue)}} disabled={!handleSetHoliday}/>
            </td>
            <td>
                <Dropdown>
                    <Dropdown.Toggle style={{padding: '3px 8px'}} variant="outline-primary" disabled={!handleSetHoliday}>{Holiday.holidayPayTypes[holiday.payType]}</Dropdown.Toggle>
                    <Dropdown.Menu className="position-fixed">
                        {payTypeDropDownItems}
                    </Dropdown.Menu>
                </Dropdown>
            </td>
            <td>
                { holiday.payType !== 'fl' && 
                    <PayrollIntegerControl style={{minWidth: 100, height: 32}} max={255} value={holiday.unitsWorked} setValue={(newValue) => {handleSetHoliday(holiday.uid, 'unitsWorked', newValue)}} disabled={!handleSetHoliday}/>
                }
            </td>
            <td style={{color: 'green', fontWeight: 'bold'}}>
                {bigToUsd(holiday.holidayPay())}
            </td>
            <td>
                { removeHoliday && 
                    <Button variant="outline-danger" style={{padding: '3px 8px'}} onClick={() => removeHoliday(holiday.uid)}>
                        <FontAwesomeIcon icon={faTrashCan}/>
                    </Button>
                }
            </td>
        </tr>
    )
}