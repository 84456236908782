/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, { useState } from 'react';
import Form from 'react-bootstrap/Form';


export default function TextAreaControl({value, title, setValue, originalValue, validate, validationMessage, ...props}) {

    const isInvalid = validate ? validationMessage != undefined : undefined;
    const isValid = validate ? validationMessage == undefined : undefined;

    const style = {backgroundColor: originalValue !== undefined && originalValue !== value ? '#faffe3' : ''};

    return (
        <Form.Group>
            <Form.Label>{title}</Form.Label>
            <Form.Control as='textarea' value={value} onChange={(event) => {setValue(event.target.value)}} isInvalid={isInvalid} isValid={isValid} style={style} {...props}/>
            { validationMessage && <Form.Control.Feedback type='invalid'>{validationMessage}</Form.Control.Feedback> }
        </Form.Group>
    )
}