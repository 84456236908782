/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Col from 'react-bootstrap/Col';
import CustomButton from './CustomButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLockOpen } from '@fortawesome/free-solid-svg-icons';
import Button from 'react-bootstrap/Button';
import { ApiRequest } from '../ApiManager.tsx';
import ControlStateProps from '../state/ControlStateProps.tsx';
import { Validation } from '../validation.tsx';
import CustomControl from './CustomControl.js';
import OptionalCol from './OptionalCol.js';

export default function PasswordProtectedControl({companyUserIdentifier, user, originalUser, setValue, setOriginalValue, type, disabled, title, breakpoints, max, clampAsText}) {
    const [unlocked, setUnlocked] = useState(false);
    const [password, setPassword] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false)

    function handleGetSSN() {
        new ApiRequest('hr', 'ssn', setIsLoading, (response) =>{
            setValue(response.ssn.toString());
            setOriginalValue(response.ssn.toString())
            setShowModal(false);
            setUnlocked(true);
        }).withData({companyUserIdentifier: companyUserIdentifier, password: password}).withNoAlertOnSuccess().send();
    }

    const content = (
        <div style={{ position: 'relative' }}>
            <CustomControl
                type={unlocked ? 'number' : 'password'}
                disabled={disabled || !unlocked}
                value={user?.ssn ?? ''}
                originalValue={originalUser?.ssn ?? ''}
                setValue={setValue}
                title={title}
                max={max}
                clampAsText={clampAsText}
            />
            { !unlocked && 
                <Button variant={'outline-primary'} style={{ position: 'absolute', right: 8, top: 16, padding: '0px 4px 0px 4px' }} onClick={() => setShowModal(true)}>
                    <FontAwesomeIcon icon={faLockOpen} size='xs' />
                </Button>
            }
            <Modal show={showModal} onHide={() => setShowModal(false)} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Password Verification</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group>
                        <Form.Label>Please enter the password for your account to access this field</Form.Label>
                        <FloatingLabel label='Password'>
                            <Form.Control type='password' disabled={isLoading} value={password} onChange={(event) => { setPassword(event.target.value) }} placeholder=' ' />
                        </FloatingLabel>
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <CustomButton onClick={handleGetSSN} isLoading={isLoading}>Submit</CustomButton>
                </Modal.Footer>
            </Modal>
        </div>
    );

    return (
        <OptionalCol breakpoints={breakpoints}>
            {content}
        </OptionalCol>
    )
}