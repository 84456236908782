/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import moment from "moment";
import { useMemo, useState } from "react";
// import { States } from "./enums";

export function useStateWithValidation(initialState: any, validationFunction: any) {
    const [value, setValue] = useState(initialState);

    const validationMessage = useMemo(() => {
        return validationFunction(value);
    }, [value, validationFunction])
  
    return [value, setValue, validationMessage];
}

export function useValidationReducer(validationMessages: [string | undefined]) {
    const isValid = useMemo(() => {
        return validationMessages.reduce((prev: any, curr: any) => {
            return prev && curr == undefined;
        }, true);
    }, validationMessages);
  
    return isValid;
} 

export function useStateWithValidationAndButton(initialState: any, validationFunction: any) {
    const [value, setValue] = useState(initialState);
    const [isShowingValidation, setIsShowingValidation] = useState(false);
  
    const validationMessage = useMemo(() => {
        return validationFunction(value);
    }, [value, validationFunction])

    function showValidation() {
        setIsShowingValidation(true);
    }
  
    return [value, setValue, {message: validationMessage, isVisible: isShowingValidation}, showValidation];
}

export function useShowValidationReducer(initialState: [() => void]) {
    const [callbacks, _] = useState(initialState);
  
    function showValidation() {
        callbacks.forEach(e => e());
    }
    return [showValidation];
}

export class Validation {

    static nonEmptyString(value: any) {
        if (!value && value != 0) {
            return 'Required';
        }
        let str = value as string;
        return str !== '' ? undefined : 'Required';
    }

    static date(value: any){
        if(!moment(value).isValid()){
            return 'Invalid date';
        }
    }

    static phoneNumber(value: any) {
        if (!value) {
            return 'Required';
        } else if (!/^1?\d{10}$/g.test(value)) {
            return 'Invalid Phone Number';
        }
    }

    static email(value: any) {
        if (!value) {
            return 'Required';
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(value)) {
            return 'Invalid email address';
        }
    }

    static ssn(value: any) {
        if (!value) {
            return 'Required';
        } else if (!/^\d{9}$/g.test(value)) {
            return 'Invalid SSN';
        }
    }

    static ein(value: any) {
        if (!value) {
            return 'Required';
        } else if (!/^\d{9}$/g.test(value)) {
            return 'Invalid EIN';
        }
    }

    static availability(value: any) {
        if (!(value as any[]).includes(true)) {
            return 'You must select at least one day';
        }
    }


    static nonEmptyArray(value: any) {
        if ((value as any[]).length === 0) {
            return 'You must select at least one item';
        }
    }

    static pickerHas2Options(value: any) {
        if ((value as any[]).length < 2) {
            return 'Must have at least 2 options';
        }
    }

    static postalCode(value: any) {
        if (!value) {
            return 'Required';
        } else if (value && !/^\d{5,9}$/g.test(value)) {
            return 'Invalid Postal Code';
        }
    }
    
    static streetAddress1(value: any) {
        if (!value) {
            return 'Required';
        } else if (!/\w+(\s\w+){2,}/g.test(value)) {
            return 'Invalid Street Address 1';
        }
    }
    static streetAddress2(value: any) {
        if (value && !/^[\w\s]{1,}\s{1}\d{1,}$/g.test(value)) {
            return 'Invalid Street Address 2'
        }
    }


    static notUndefined(value: any) {
        if (value === undefined || value === null) {
            return 'Required';
        }
    }

    static greaterThanZero(value: any) {
        if (value === undefined || value === '') {
            return 'Required';
        } else if (isNaN(value)) {
            return 'Must be a number';
        } else if (parseFloat(value) <= 0) {
            return 'Must be greater than zero';
        }
    }

    
    static undefinedOrNonEmptyString(value: any) {
        if (value === '') {
            return 'Required';
        }
    }

    static validCustomQuestionOptions(value: any) {
        const array = value as string[];
        if (array.length < 2) {
            return 'Must have at least 2 options'
        } else if (array.find(r => r === '') !== undefined) {
            return 'Each option must have a label'
        } else {
            for(let i = 0; i < array.length; i++) {
                for(let j = i + 1; j < array.length; j++) {
                    if (array[i].toLowerCase() === array[j].toLowerCase()) {
                        return 'Each option must be unique'
                    }
                }
            }
        }
    }

    static knockoutQuestionHasRequiredAnswer(value: any) {
        if (value == null) {
            return 'Must define a required answer';
        }
    }

    static none(_: any) {
        return undefined;
    }

    static true(value: any) {
        if (value !== true) {
            return 'Required';
        };
    }

    static reduceValidation(validationMessages: [string | undefined]) {
        return validationMessages.reduce((prev: any, curr: any) => {
            return prev && curr == undefined;
        }, true);
    }

}