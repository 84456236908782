/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import Decoder from "../../../../decoding.js";
import Address from "../../../../models/Address.js";
import StateObject from "../../../../state/StateObject.tsx";
import { getFakeUID } from "../../../../tools.js";
import Client from "../../MyCompany/Models/Client.js";
import Terminal from "../../MyCompany/Models/Terminal.js";

export default class RouteStop extends StateObject{
    uid;
    name;
    startTime;
    endTime;
    defaultLoadTime;
    address;
    clientIdentifier;
    terminalIdentifier;

    constructor(uid, name, startTime, endTime, defaultLoadTime, address, clientIdentifier, terminalIdentifier){
        super();
        this.uid = uid;
        this.name = name;
        this.startTime = startTime;
        this.endTime = endTime;
        this.defaultLoadTime = defaultLoadTime;
        this.address = address;
        this.clientIdentifier = clientIdentifier;
        this.terminalIdentifier = terminalIdentifier;
        this.initChildren();
    }

    static initDefault(){
        return new RouteStop(
            getFakeUID(),
            '',
            '00:00:00',
            '00:00:00',
            0,
            Address.initDefault(),
            null,
            null
        )
    }

    static initFromRouteOrTerminal(obj){
        return new RouteStop(
            getFakeUID(),
            obj.name,
            obj?.startTime,
            obj?.endTime,
            obj.defaultLoadTime ?? 0,
            obj.address,
            obj instanceof Client ? obj.uid : obj instanceof RouteStop ? obj?.clientIdentifier : null,
            obj instanceof Terminal ? obj.uid : obj instanceof RouteStop ? obj?.terminalIdentifier : null,
        )
    }

    static decode(json){
        const decoder = new Decoder(json);

        const uid = decoder.decode('uid', Decoder.Uid);
        const name = decoder.decode('name', Decoder.String);
        const startTime = decoder.decode('startTime', Decoder.String);
        const endTime = decoder.decode('endTime', Decoder.String);
        const defaultLoadTime = decoder.decode('defaultLoadTime', Decoder.Integer);
        const address = Address.decode(json.address);
        const clientIdentifier = decoder.decode('clientIdentifier', Decoder.Uid, {default: null, warn: false});
        const terminalIdentifier = decoder.decode('terminalIdentifier', Decoder.Uid, {default: null, warn: false});

        if(decoder.checkForErrors()){
            return new RouteStop(uid, name, startTime, endTime, defaultLoadTime, address, clientIdentifier, terminalIdentifier);
        } else {
            return RouteStop.initDefault();
        }
    }

    encode(){
        return {
            uid: this.uid,
            name: this.name,
            startTime: this.startTime,
            endTime: this.endTime,
            defaultLoadTime: this.defaultLoadTime,
            address: this.address.encode(),
            clientIdentifier: this.clientIdentifier,
            terminalIdentifier: this.terminalIdentifier,
        }
    }
}