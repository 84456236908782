/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, { useState } from 'react';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock, faExclamationTriangle, faPersonCircleQuestion, faPhone, faTruck} from '@fortawesome/free-solid-svg-icons';
import { Button, ListGroup, Modal, OverlayTrigger, Popover } from 'react-bootstrap';
import { checkPermission, formatTime, getTimeOnDayAsMoment, timeOffDictionary } from '../../../../tools';
import { faIdBadge} from '@fortawesome/free-regular-svg-icons';
import { faWarehouse } from '@fortawesome/free-solid-svg-icons/faWarehouse';
import HRTimePunchModal from './HRTimePunchModal';

export default function MobileHumanResourcesUserRow({user, timeData, selectedDate, terminals, setSelectedEmployee, handleSetUserTimeDataForSelectedDate}) {
    const [showTimesheetModal, setShowTimesheetModal] = useState(false);

    const timeDataForUser = timeData.filter((t) => t.companyUserIdentifier == user.companyUserIdentifier);
    const timeDataOnSelectedDate = timeDataForUser.filter((t) => moment(t.inTime).isSame(moment(selectedDate), 'day'));

    const totalHours = timeDataForUser.reduce((prev, curr) => {
        return curr.outTime ? (prev + moment(curr.outTime).diff(moment(curr.inTime), 'hours', true)) : prev;
    }, 0);

    const earliestInTime = timeDataOnSelectedDate.reduce((prev, curr) => {
        return (!prev || curr.inTime < prev) ? curr.inTime : prev;
    }, '')

    const latestOutTime = timeDataOnSelectedDate.reduce((prev, curr) => {
        return curr.outTime && curr.outTime > prev ? curr.outTime : prev;
    }, '')
    
    const hasDifferentDayOutTime = latestOutTime && earliestInTime.substring(0, 11) != latestOutTime.substring(0, 11);
    const earliestSubRoute = user.route && [...user.route?.clientRoutes, ...user.route?.terminalRoutes].reduce((acc, el) => {if(el.startTime < acc.startTime){return el} return acc});
    const shouldBeClockedIn = user.route && (earliestSubRoute.startTime < moment().format('HH:mm') || selectedDate != moment().format('YYYY-MM-DD'));
    const clockedInLateOrEarly = shouldBeClockedIn && earliestInTime && Math.abs(getTimeOnDayAsMoment(earliestSubRoute.startTime, selectedDate).diff(moment(earliestInTime), 'minutes')) >= 30;

    let errorColor = '';
    let message = '';

    if (shouldBeClockedIn) {
        if (earliestInTime) {
            if (clockedInLateOrEarly) {
                errorColor = 'gold';
                message = 'Employee did not clock in at the correct scheduled time';
            }
        } else {
            errorColor = 'red';
            message = 'Employee did not clock in';
        }
    } else {
        if (earliestInTime) {
            errorColor = 'gold';
            message = 'Employee clocked in but is not scheduled';
        }
    }

    const absenceTags = user.absences.map(a => {
        return (
            <div key={a.uid} style={{fontWeight: 600, margin: 0, padding: '2px 6px 2px 6px', backgroundColor: a.notifiedManager ? '#bc96ff' : '#a992b0' , borderRadius: 6}}>
                <span style={{color: 'black', opacity: 0.7}}>{a.notifiedManager ? 'Call Off' : 'No-Show'}</span>
                <FontAwesomeIcon style={{marginLeft: 6, marginRight: 4, color: 'black', opacity: 0.5}} icon={a.notifiedManager ? faPhone : faPersonCircleQuestion}/>
            </div>
        )
    }) 

    const timeOffTags = user.timeOff.map((item) => {
        const status = item.status.toString();
        return (
            <div key={item.uid} style={{fontWeight: 600, margin: 0, padding: '2px 6px 2px 6px', backgroundColor: timeOffDictionary[status].color, color: 'white', borderRadius: 6}}>
                <span style={{color: 'black', opacity: 0.7}}>{timeOffDictionary[status].label}</span>
                <FontAwesomeIcon style={{marginLeft: 6, marginRight: 4, color: 'black', opacity: 0.5}} icon={timeOffDictionary[status].icon}/>
            </div>
        )
    });

    const routeItemTag = user.route && (
        <div style={{fontWeight: 600, margin: 0, padding: '2px 6px 2px 6px', backgroundColor: '#ffa496', color: 'white', borderRadius: 6}}>
            <span style={{color: 'black', opacity: 0.7}}>Route</span>
            { earliestSubRoute &&
                <span style={{color: 'black', opacity: 0.7}}>{' @ ' + formatTime(earliestSubRoute.startTime, 'h:mm A')}</span>
            }
            <FontAwesomeIcon style={{marginLeft: 6, marginRight: 4, color: 'black', opacity: 0.5}} icon={faTruck}/>
        </div>
    )

    const scheduleItems = [...timeOffTags, ...absenceTags];
    if(user.route){
        scheduleItems.unshift(routeItemTag);
    }

    const terminal = terminals.find((t)=> {
        return t.uid === user.terminalIdentifier         
    })

    const suffixString = !user.title ? user.firstPayDateEffective ? `(Active ${moment(user.firstPayDateEffective).format('LL')})` : '(Terminated)' : '';
    
    return (
        <ListGroup.Item action as='div' style={{cursor: 'pointer'}} onClick={() => {setSelectedEmployee(user)}}>
            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                <h6 style={{fontWeight: 'bold', opacity: user.title ? 1 : .6 }}>{`${user.lastName}, ${user.firstName} ${user.middleName.substring(0, 1)} ${suffixString}`}</h6>
                <h6 style={{fontSize: 13}}>Hours Worked: {totalHours.toFixed(2)}</h6>
            </div>
            <div style={{display: 'flex', alignItems: 'center', gap: 12}}>
                <h6><FontAwesomeIcon style={{color: 'var(--bs-primary)', width: 16, height: 16}} icon={faIdBadge}/> {user.title}</h6>
                <h6><FontAwesomeIcon style={{color: 'var(--bs-primary)', width: 16, height: 16}} icon={faWarehouse}/> {terminal.name}</h6>
            </div>
            <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: 8, flexWrap: 'wrap'}}>
                <div style={{whiteSpace: 'nowrap'}}>
                    { scheduleItems.length > 0 ?
                        <div style={{display: 'flex', alignItems: 'center', gap: 4}}>
                            {scheduleItems[0]}
                            { scheduleItems.length > 1 &&
                                <OverlayTrigger overlay={
                                    <Popover style={{position: 'fixed'}}>
                                        <Popover.Header>Additional Items</Popover.Header>
                                        <Popover.Body style={{display: 'flex', flexDirection: 'column', gap: 4}}>
                                            {scheduleItems.slice(1)}
                                        </Popover.Body>
                                    </Popover>
                                }>
                                    <div>
                                        <Button variant='outline-primary' style={{height: 28, padding: '0px 5px 0px 5px'}}>
                                            {`+${scheduleItems.length - 1}`}
                                        </Button>
                                    </div>
                                </OverlayTrigger>
                            }
                        </div>
                    :
                        <span style={{opacity: 0.5, fontWeight: 600}}>Not Scheduled</span>
                    }
                </div>
                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end', gap: 8}}>
                    { earliestInTime && 
                        <div style={{display: 'flex', flexDirection: 'column', padding: 2, borderRadius: 6, whiteSpace: 'nowrap'}}>
                            <span style={{opacity: 0.65, fontSize: 13}}>In-Punch</span>
                            <span style={{fontSize: 15, fontWeight: 'bold'}}>
                                {moment(earliestInTime).format('h:mm A')}
                            </span>
                        </div>
                    }
                    { latestOutTime && 
                        <div style={{display: 'flex', flexDirection: 'column', padding: 2, borderRadius: 6, whiteSpace: 'nowrap'}}>
                            <span style={{opacity: 0.65, fontSize: 13}}>Out-Punch</span>
                            <span style={{fontSize: 15, fontWeight: 'bold'}}>
                                {moment(latestOutTime).format('h:mm A')}
                                { hasDifferentDayOutTime && 
                                    <span style={{color: 'red'}}>{moment(latestOutTime).format(' (MMM D)')}</span>
                                }
                            </span>
                        </div>
                    }
                    { message && 
                        <OverlayTrigger overlay={
                            <Popover style={{position: 'fixed'}}>
                                <Popover.Body>{message}</Popover.Body>
                            </Popover>
                        }>
                            <FontAwesomeIcon icon={faExclamationTriangle} style={{color: errorColor}} size='lg'/>
                        </OverlayTrigger>
                    }
                    {checkPermission('hrTimesheet') &&
                        <div onClick={(e) => {e.stopPropagation()}}>
                            <Button variant='outline-primary' disabled={user.title === 'Terminated'} onClick={() => {setShowTimesheetModal(true)}}>
                                <FontAwesomeIcon icon={faClock}/>
                            </Button>
                            <Modal centered size='lg' show={showTimesheetModal} onHide={() => setShowTimesheetModal(false)}>
                                <HRTimePunchModal 
                                    selectedDate={selectedDate}
                                    handleSetUserTimeDataForSelectedDate={handleSetUserTimeDataForSelectedDate} 
                                    usersTimeData={timeDataOnSelectedDate}
                                    userToEditTime={user}
                                    hideModal={() => {setShowTimesheetModal(false)}}
                                    />
                            </Modal>
                        </div>
                    }
                </div>
            </div>
        </ListGroup.Item>
    )
}