/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import { Document, Page, Text, View } from "@react-pdf/renderer";
import moment from "moment/moment";
import Cookies from "universal-cookie";
import { getFakeUID } from "../../../tools";

export default function PDFSchedule({routes, startDate, endDate}){
    const companyName = new Cookies().get('companyName');

    const dates = [];
    let i = 0;
    while(dates[dates.length-1] !== endDate && i < 7){
        dates.push(moment(startDate).add(i, 'days').format('YYYY-MM-DD'));
        i++;
    }
    
    return (
        <>
            <Document>
                <Page orientation='landscape' size='LETTER' style={{padding: '0.5in', flexDirection: 'column'}}>
                    <View style={{textAlign: 'center'}}>
                        <Text style={{fontSize: 16}}>{companyName}</Text>
                    </View>
                    {dates.map(date => {
                        const filteredRoutes = routes.filter(r=> r.date === date);
                        return (
                            <View key={date} wrap={false}>
                                <View style={{textAlign: 'center', marginBottom: 12, marginTop: 12}}>
                                    <Text style={{fontSize: 12}}>{moment(date).format('dddd, ll')}</Text>
                                </View>
                                {filteredRoutes.length === 0 
                                    ?  <View style={{textAlign: 'center', marginBottom: 14}}>
                                            <Text style={{fontSize: 11, fontFamily: 'Helvetica-Bold'}}>No Routes</Text>
                                        </View>
                                    :
                                        <View style={{display: 'flex', flexDirection: 'row', flexWrap: 'wrap', gap: 12}}>
                                            {filteredRoutes.map((route, index) =>{
                                                    const middleName = route?.user?.middleName ? `${route.user?.middleName} ` : ''
                                                    const name = `${route?.user?.firstName} ${middleName}${route?.user?.lastName}`
                                                    const firstStopStartTime = `${route.date} ${route.stops?.[0]?.startTime}`;
                                                    const lastStopEndTime = `${route.date} ${route.stops?.[route.stops.length-1]?.endTime}`;
                                                    const emptyStops = [];
                                                    for (let i = route.stops.length; i < 8; i++) {
                                                        emptyStops.push(
                                                            <View key={i} style={{display: 'flex', alignItems: 'center'}}>
                                                                <View style={{display: 'flex', justifyContent: 'center', alignItems: 'center', border: '1px solid gray', height: 19, width: 171}}></View>
                                                            </View>
                                                        );
                                                    }
                                                return (
                                                    <View key={index} style={{width: 171}}>
                                                        <View style={{display: 'flex', flexDirection: 'row', alignItems: 'center', padding: '0 3px', height: 19, border: '1px solid gray', gap: 4, fontSize: 7}}>
                                                            <Text style={{fontFamily: 'Helvetica-Bold'}}>Driver: </Text>
                                                            <Text>{route?.user ? name : 'None'}</Text>
                                                        </View>
                                                        <View style={{display: 'flex', flexDirection: 'row', alignItems: 'center', padding: '0 3px', height: 19, border: '1px solid gray', gap: 4, fontSize: 7}}>
                                                            <Text style={{fontFamily: 'Helvetica-Bold'}}>Truck: </Text>
                                                            <Text style={{fontFamily: 'Helvetica'}}>{route?.vehicle ? route.vehicle.name : 'None'}</Text>
                                                        </View>
                                                        <View style={{display: "flex", backgroundColor: 'gold', flexDirection: 'row', height: 16, border: '1px solid gray'}}>
                                                            <View style={{display: 'flex', flexDirection: 'row', justifyContent: 'left', alignItems: 'center', paddingLeft: 3, flex: 3, border: '1px solid gray', fontWeight: 'bold'}}><Text style={{fontSize: 7, fontFamily: 'Helvetica-Bold'}}>Run</Text></View>
                                                            <View style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', flex: 1, border: '1px solid gray', fontWeight: 'bold'}}><Text style={{fontSize: 7, fontFamily: 'Helvetica-Bold'}}>Time In</Text></View>
                                                            <View style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', flex: 1, border: '1px solid gray', fontWeight: 'bold'}}><Text style={{fontSize: 7, fontFamily: 'Helvetica-Bold'}}>Time Out</Text></View>
                                                        </View>
                                                        {route.stops.map((stop, i) => {
                                                            const startTime = moment(`${route.date} ${stop.startTime}`).format('LT');
                                                            const endTime = moment(`${route.date} ${stop.endTime}`).format('LT');
                                                            
                                                            return (
                                                                <View key={i} style={{display: "flex",  flexDirection: 'row', height: 19}}>
                                                                    <View style={{display: 'flex', flexDirection: 'row', justifyContent: 'left', alignItems: 'center', flex: 3, border: '1px solid gray', paddingLeft: 3}}><Text style={{fontSize: 6}}>{stop.name}</Text></View>
                                                                    <View style={{display: 'flex', justifyContent: 'center', alignItems: 'center', flex: 1, border: '1px solid gray'}}><Text style={{fontSize: 7}}>{startTime}</Text></View>
                                                                    <View style={{display: 'flex', justifyContent: 'center', alignItems: 'center', flex: 1, border: '1px solid gray'}}><Text style={{fontSize: 7}}>{endTime}</Text></View>
                                                                </View>
                                                            )
                                                        })}
                                                        {emptyStops}
                                                            <View style={{height: 19, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', border: '1px solid gray', gap: 8, fontSize: 7}}>
                                                                <Text style={{fontFamily: 'Helvetica-Bold'}}>Total Hours: </Text>
                                                                <Text>{(route.stops?.[0]?.startTime && route.stops?.[route.stops.length-1]?.endTime) ? moment(lastStopEndTime).diff(moment(firstStopStartTime), 'minutes')/60 : 0}</Text>
                                                            </View>
                                                    </View>
                                                )
                                            })}
                                        </View>
                                }
                            </View>
                        )})}
                </Page>
            </Document>
        </>
    )
}