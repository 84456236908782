/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/
import { Card, Form, Button, Container, Row, Col } from 'react-bootstrap';
import './Login.css';
import { useState } from 'react';
import logo from '../../assets/TTA-Logo-150-white.png';
import React from 'react';
import Cookies from 'universal-cookie';
import MFA from './MFA';
import Modal from 'react-bootstrap/Modal';
import ForgotPassword from './ForgotPassword';
import CustomControl from '../../components/CustomControl';
// import { ValidationGroup, validateEmail, validateExistence } from '../../validation';
import CustomButton from '../../components/CustomButton';
import ApiManager, { ApiRequest } from '../../ApiManager.tsx';

export default function Login() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [showMFA, setShowMFA] = useState(false);
    const [tokenIdentifier, setTokenIdentifier] = useState(undefined);
    const [showForgotPassword, setShowForgotPassword] = useState(false);
    // const validationGroup = new ValidationGroup();

    function handleSubmit(event) {
        event?.preventDefault();
        new ApiRequest('authentication', 'login', setIsSubmitting, (response) => {
            new Cookies().set('lhToken', response.token, {path: '/'});
            if (response.tokenIdentifier) {
                setTokenIdentifier(response.tokenIdentifier);
                setShowMFA(true);
            } else {
                ApiManager.instance.handleCheckAuthentication();
            }
        }).withData({email, password, isMobile: false}).withNoAlertOnSuccess().send();
    }

    return (
        <div style={{display: 'flex', flexDirection: 'column', position: 'relative', justifyContent: 'center', alignItems: 'center', height: '100vh', width: '100%', padding: 8}}>
            <div className='login-background' style={{position: 'absolute', width: '100%', height: '100%', opacity: 0.03}}></div>
            <Card style={{maxWidth: '100%', display: 'flex', flexDirection: 'row', width: 800, minHeight: 600, border: '1px solid var(--bs-primary)', borderRadius: 8}}>
                <Container fluid>
                    <Row style={{height: '100%'}}>
                        <Col md={6} style={{padding: 0}}>
                            <Card.Body className='login-lhs-card' style={{padding: 32, height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between', backgroundColor: 'var(--bs-primary)', textAlign: 'center'}}>
                                <img src={logo} style={{padding: 8, maxWidth: '100%'}}/>
                                <span style={{color: 'white', opacity: 0.9, fontWeight: 'bold'}}>A comprehensive solution to your delivery business needs</span>
                            </Card.Body>
                        </Col>
                        <Col md={6} style={{padding: 0}}> 
                            <Card.Body style={{height: '100%'}}>
                                <form onSubmit={handleSubmit} style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-around', gap: 12, height: '100%', padding: 32}}>
                                    <div style={{textAlign: 'center'}}>
                                        <h1>Hello!</h1>
                                        <span style={{color: 'gray'}}>Please login below.</span>
                                    </div>
                                    <div style={{display: 'flex', flexDirection: 'column', gap: 12}}>
                                        <CustomControl title='Email Address' value={email} setValue={setEmail}/>
                                        <CustomControl type='password' title='Password' value={password} setValue={setPassword}/>
                                    </div>
                                    <button type='submit' style={{display: 'none'}}/>
                                    <div style={{display: 'flex', flexDirection: 'column', gap: 6, textAlign: 'center'}}>
                                        <CustomButton disabled={!email || !password} outline isLoading={isSubmitting} onClick={handleSubmit}>Login</CustomButton>
                                    </div>
                                    <Button style={{background:'none', backgroundColor:'none', boxShadow:'none', border:'none', color: 'var(--bs-primary)'}} onClick={()=>setShowForgotPassword(true)}>Forgot Password?</Button>
                                </form>
                            </Card.Body>
                        </Col>
                    </Row>
                </Container>
            </Card>
            <Modal show={showMFA} backdrop='static' centered size='lg'>
                <MFA tokenIdentifier={tokenIdentifier}/>
            </Modal>
            <Modal show={showForgotPassword} onHide={()=>{setShowForgotPassword(false)}}>
                <ForgotPassword hideModal={()=>{setShowForgotPassword(false)}}/>
            </Modal>
        </div>
    )
}