/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, {useEffect, useReducer} from 'react';
import Form from 'react-bootstrap/Form';
import { faPlus, faTrash, faTrashCan, faX } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Dropdown, InputGroup } from 'react-bootstrap';
import OptionalCol from './OptionalCol';
import CustomControl from './CustomControl';

export default function AutomaticDeductionControl({value, setValue, breakpoints, validationMessages}) {

    const handleSetAutomaticDeduction = (index, key, newValue) => {
        const newAutomaticDeductions = Array.from(value);
        newAutomaticDeductions[index][key] = newValue;
        setValue(newAutomaticDeductions);
    }

    const handleAddAutomaticDeduction = () => {
        const newAutomaticDeductions = Array.from(value);
        newAutomaticDeductions.push({type: '', amount: ''});
        setValue(newAutomaticDeductions);
    }

    const handleRemoveAutomaticDeduction = (index) => {
        setValue(value.toSpliced(index, 1));
    }

    const automaticDeductionTypes = [
        '401k (Flat Rate)',
        '401K (% of Gross)',
        'Other (Flat Rate)',
        'Other (% of Gross)'
    ]

    const adElements = value.map((ad, index) => {

        const typeItems = automaticDeductionTypes.map((item) => {
            return (
                <Dropdown.Item key={item} active={ad.label === item} onClick={() => {handleSetAutomaticDeduction(index, 'type', item)}}>{item}</Dropdown.Item>
            )
        })

        return (
            <InputGroup key={index} style={{flexWrap: 'nowrap'}}>
                <Dropdown>
                    <Dropdown.Toggle variant='outline-primary' style={{display: 'flex', alignItems: 'center'}}>
                        <span style={{maxWidth: 90, minWidth: 90, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', display: 'block', fontSize: 13}}>{ad.type ? ad.type : 'Select Type'}</span>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        {typeItems}
                    </Dropdown.Menu>
                </Dropdown>
                <CustomControl validate type='number' title='Amount' value={ad.amount} max={999.99} validationMessage={validationMessages[index]} setValue={(value) => handleSetAutomaticDeduction(index, 'amount', value)} style={{borderRadius: 0, minWidth: 85}}/>
                <Button variant='outline-danger'  onClick={() => {handleRemoveAutomaticDeduction(index)}}>
                    <FontAwesomeIcon icon={faTrashCan}/>
                </Button>
            </InputGroup>

        )
    })

    return (
        <OptionalCol breakpoints={breakpoints}>
            <div style={{border: '1px solid lightgray', borderRadius: 6, padding: 12}}>
                <p style={{marginBottom: 4, fontSize: 14, opacity: 0.65}}>Automatic Deductions</p>
                <div style={{display: 'flex', flexWrap: 'wrap', gap: 12}}>
                    {adElements}
                    <Button variant='outline-primary' onClick={handleAddAutomaticDeduction}><FontAwesomeIcon icon={faPlus}/></Button>
                </div>
            </div>
        </OptionalCol>
    )
}