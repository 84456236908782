/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import Decoder from "../decoding";
import StateObject from "../state/StateObject.tsx";

export default class Address extends StateObject {
    uid; //: number;
    thoroughfare; //: string;
    premise; //: string;
    locality; //: string;
    administrativeArea; //: string;
    postalCode; //: string;
    latitude;
    longitude;

    constructor(
        uid,
        thoroughfare,
        premise,
        locality,
        administrativeArea,
        postalCode,
        latitude,
        longitude
    ) {
        super();
        this.uid = uid;
        this.thoroughfare = thoroughfare;
        this.premise = premise;
        this.locality = locality;
        this.administrativeArea = administrativeArea;
        this.postalCode = postalCode;
        this.latitude = latitude;
        this.longitude = longitude;
        this.initChildren();
    }

    static initDefault() {
        const address = new Address(
            -1,
            '',
            '',
            '',
            '',
            '',
            null,
            null
        );
        return address;
    }

    static decode(json, optional = false, optionalUid) {
        const decoder = new Decoder(json, optional);
        const uid = decoder.decode('uid', Decoder.Uid, {default: -1, warn: false});
        const thoroughfare = decoder.decode('thoroughfare', Decoder.StringStrict);
        const premise = decoder.decode('premise', Decoder.StringStrict, {defaultValue: '', warn: false});
        const locality = decoder.decode('locality', Decoder.StringStrict);
        const administrativeArea = decoder.decode('administrativeArea', Decoder.StringStrict);
        const postalCode = decoder.decode('postalCode', Decoder.Integer);
        const latitude = decoder.decode('latitude', Decoder.Decimal);
        const longitude = decoder.decode('longitude', Decoder.Decimal);
        if (decoder.checkForErrors()) {
            return new Address(optionalUid ?? uid, thoroughfare, premise, locality, administrativeArea, postalCode, latitude, longitude);
        } else if (optional) {
            return undefined;
        } else {
            return Address.initDefault();
        }
    }

    static decodeNonStrict(address = {}){
        return new Address(address.uid ?? -1, address.thoroughfare ?? '', address.premise ?? '', address.locality ?? '', address.administrativeArea ?? '', address.postalCode ?? '', address.latitude ?? null, address.longitude ?? null);
    }

    encode() {
        return {
            uid: this.uid,
            thoroughfare: this.thoroughfare,
            premise: this.premise,
            locality: this.locality,
            administrativeArea: this.administrativeArea,
            postalCode: this.postalCode,
            latitude: this.latitude,
            longitude: this.longitude,
        }
    }  
}