/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import { useReducer, useState } from "react"
import Terminal from "../Models/Terminal.js"
import { Button, Col, Modal, Row } from "react-bootstrap"
import CustomControl from "../../../../components/CustomStateControls/CustomControl.js"
import ControlStateProps from "../../../../state/ControlStateProps.tsx"
import { Validation } from "../../../../validation.tsx"
import AddressAutoFill from "../../../../components/CustomStateControls/AddressAutoFill.js"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faMinus, faPlus, faTrash } from "@fortawesome/free-solid-svg-icons"
import CustomButton from "../../../../components/CustomButton.js"
import { ApiRequest } from "../../../../ApiManager.tsx"
import Address from "../../../../models/Address.js"
import StateObject from "../../../../state/StateObject.tsx"
import AlertConfirmation from "../../../../components/AlertModals/AlertConfirmation.js"

export default function TerminalEditorModal({setTerminals, terminals, selectedTerminal, hideModal}){
    const [terminal, _setTerminal] = useState(selectedTerminal ? new Terminal(selectedTerminal.uid, selectedTerminal.companyIdentifier, selectedTerminal.name, Address.decodeNonStrict(selectedTerminal.address), selectedTerminal.defaultLoadTime, selectedTerminal.clockInRadius) : null);
    const [formObject, _setFormObject] = useState(() => new StateObject({terminalsToCreate : [Terminal.initDefault()]}));
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [_, forceUpdate] = useReducer(x => x + 1, 0);

    function handleCreateTerminals(){
        new ApiRequest('myCompany', 'createTerminals', setIsSubmitting, (response) => {
            let count = 0;
            formObject['terminalsToCreate'].forEach(t => t.setState('uid', response.uids[count++]));
            // formObject
            setTerminals([...terminals, ...formObject['terminalsToCreate'].map(t => t.encode())]);
            hideModal();
        }).withData({terminals: formObject['terminalsToCreate'].map(t => t.encode())}).send()
    }

    function handleUpdateTerminal(){
        new ApiRequest('myCompany', 'updateTerminal', setIsSubmitting, () => {
            const newArray = Array.from(terminals);
            newArray[newArray.findIndex(t => t.uid === selectedTerminal.uid)] = terminal;
            setTerminals(newArray);
            hideModal();
        }).withData({terminal: terminal.encode()}).send();
    }

    function handleDeleteTerminal(){
        new ApiRequest('myCompany', 'deleteTerminal', setIsDeleting, () => {
            setTerminals(terminals.filter(t => t.uid !== terminal.uid));
            setShowDeleteModal(false);
            hideModal();
        }).withUid(terminal.uid).send();
    }

    function handleAddTerminal(){
        formObject.setState('terminalsToCreate', [...formObject['terminalsToCreate'], Terminal.initDefault()]);
        forceUpdate();
    }

    function handleRemoveTerminal(index){
        const newArray = Array.from(formObject['terminalsToCreate']);
        const newTerminals = newArray.toSpliced(index, 1);
        formObject.setState('terminalsToCreate', newTerminals);
        forceUpdate();
    }

    const terminalList = formObject['terminalsToCreate'].map((terminal, index) => {
        return (
            <div key={index} style={{marginBottom: 32, padding: 10, border: '1px solid lightgray', borderRadius: 6}}>
                <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                    <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', fontWeight: 'bolder', margin: 0, height: 30, width: 30, marginBottom: 8, color: 'white', borderRadius: '100%', padding: 12, backgroundColor: 'var(--bs-primary)'}}>{index+1}</div>
                    <Button variant="outline-danger" style={{borderRadius: '100%', height: 30, width: 30, display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: 8}} onClick={() => handleRemoveTerminal(index)}><FontAwesomeIcon icon={faTrash}/></Button>
                </div>
                <Row>
                    <CustomControl breakpoints={{lg: 6}} floatingLabel maxLength={30} label={'Name'} stateProps={new ControlStateProps('name', terminal, Validation.nonEmptyString)}/>
                    <CustomControl breakpoints={{lg: 6}} floatingLabel type='number' maxInt={300} label={'Default Load Time (minutes)'} stateProps={new ControlStateProps('defaultLoadTime', terminal, Validation.nonEmptyString)}/>
                    <CustomControl breakpoints={{lg: 6}} floatingLabel type='number' maxDecimal={9.9999} label={'Clock-In Radius (miles)'} stateProps={new ControlStateProps('clockInRadius', terminal, Validation.greaterThanZero)}/>
                    <AddressAutoFill label='Address' stateProps={new ControlStateProps('address', terminal, Validation.notUndefined)}/>
                </Row>
            </div>
        )
    })

    return (
        <>
        {terminal ? 
            <>
                <Modal.Header closeButton>
                    <Modal.Title>Edit Terminal</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <CustomControl breakpoints={{lg: 6}} maxLength={30} floatingLabel label={'Name'} stateProps={new ControlStateProps('name', terminal, Validation.nonEmptyString)}/>
                        <CustomControl breakpoints={{lg: 6}} floatingLabel type='number' maxInt={300} label={'Default Load Time (minutes)'} stateProps={new ControlStateProps('defaultLoadTime', terminal, Validation.nonEmptyString)}/>
                        <CustomControl breakpoints={{lg: 12}} floatingLabel type='number' maxDecimal={99999.99} label={'Clock-In Radius (miles)'} stateProps={new ControlStateProps('clockInRadius', terminal, Validation.greaterThanZero)}/>
                        <AddressAutoFill label='Address' stateProps={new ControlStateProps('address', terminal, Validation.notUndefined)}/>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <button onClick={() => setShowDeleteModal(true)} style={{marginRight: 'auto',border: 'none', marginRight: 2, boxShadow: 'none', color: 'red', backgroundColor: 'transparent'}}>Delete Terminal</button>
                    <CustomButton isLoading={isSubmitting} variant='outline-primary' stateObject={terminal} onClick={handleUpdateTerminal}>Save Changes</CustomButton>
                </Modal.Footer>
                <Modal centered show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
                    <AlertConfirmation variant='outline-danger' isLoading={isDeleting} callBack={handleDeleteTerminal} title='Delete Terminal' message='This terminal cannot be deleted if there are any users or vehicles that are associated with it. It also cannot be deleted if it has been associated with a payroll submission. Furthermore, deleting this terminal will also delete stops on all routes associated with this terminal. Are you sure you want to delete this terminal?' buttonLabel='Delete'/>
                </Modal>
            </>
        : 
            <>
                <Modal.Header closeButton>
                    <Modal.Title>Add Terminal(s)</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {terminalList}
                    <Button variant="outline-primary" style={{marginTop: 12, marginLeft: 'auto', display: 'block'}} onClick={handleAddTerminal}><FontAwesomeIcon icon={faPlus}/> Add Terminal</Button>
                </Modal.Body>
                <Modal.Footer>
                    <CustomButton isLoading={isSubmitting} variant='outline-primary' stateObject={formObject} disabled={formObject['terminalsToCreate'].length < 1} onClick={handleCreateTerminals}>Submit</CustomButton>
                </Modal.Footer>
            </>
        }
        </>
    )
}