/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, { useState } from "react";
import InputGroup from 'react-bootstrap/InputGroup';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { usdFormatter } from "../../../../../../../tools";
import CustomToolTip from "../../../../../../../components/CustomToolTip";
import PayrollAdditionalDeductionsModal from "./PayrollAdditionalDeductionsModal";
import PayrollMoneyControl from "../../../PayrollMoneyControl";
import PayrollEntryPaySection from "../../Pay/PayrollEntryPaySection";
import Deduction from "../../../../Models/Deduction";
import { Dropdown } from "react-bootstrap";
import PayrollAdditionalDeductionItem from "./PayrollAdditionalDeductionItem";
import QuickTable from "../../../../../../../components/QuickTable";


export default function PayrollAdditionalDeductionsEditor({entry, handleSet, handleAddItemToAllEntries}) {
    const [showModal, setShowModal] = useState(false);

    const handleAddDeduction = (deduction, applyToAll) => {
        if (applyToAll) {
            handleAddItemToAllEntries('deductions', deduction);
        } else {
            const newArr = Array.from(entry.deductions);
            newArr.push(deduction);
            handleSet(entry.companyUserIdentifier, 'deductions', newArr);
        }
        setShowModal(false);
    }
    const handleSetDeduction = (uid, key, value) => {
        const newArr = Array.from(entry.deductions);
        newArr.find(r => r.uid === uid)[key] = value;
        handleSet(entry.companyUserIdentifier, 'deductions', newArr);
    }
    const removeDeduction = (uid) => {
        const newArr = entry.deductions.filter((element) => {
            return element.uid !== uid;
        });
        handleSet(entry.companyUserIdentifier, 'deductions', newArr);
    }
    
    const deductionRows = entry.deductions.map((deduction) => {
        return (
            <PayrollAdditionalDeductionItem key={deduction.uid} deduction={deduction} handleSetDeduction={handleSetDeduction} removeDeduction={removeDeduction}/>
        )
    });

    const reoccurringHeader = (
        <div style={{display: 'flex', gap: 6, alignItems: 'center'}}>
            <span>Reoccurring</span>
            <CustomToolTip text={'Next payroll period, this deduction will be automatically added to this employee'}/>
        </div>
    )

    return (
        <>
            <PayrollEntryPaySection title='ADDITIONAL DEDUCTIONS' showModal={() => {setShowModal(true)}}>
                { entry.deductions.length > 0 && 
                    <div style={{overflowX: 'auto'}}>
                        <QuickTable noMargin noWrap size='sm' rows={deductionRows} headers={['Type', 'Amount', reoccurringHeader, '']} widths={[1, null, 1, 1]}/>
                    </div>
                }
            </PayrollEntryPaySection>
            <Modal show={showModal} onHide={() => {setShowModal(false)}} fullscreen='lg-down'>
                <PayrollAdditionalDeductionsModal handleAddDeduction={handleAddDeduction}/>
            </Modal>
        </>
    )
}

