/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, { useEffect, useState } from 'react';
import QuickTable from '../../../../components/QuickTable.js';
import { bigToUsd, validateBig, validateUsd } from '../../Payroll/payrollTools.js';
import { ApiRequest } from '../../../../ApiManager.tsx';
import { Col, Container, ListGroup, Modal, Row } from 'react-bootstrap';
import AlertConfirmation from '../../../../components/AlertModals/AlertConfirmation.js';
import LoadingWrapper from '../../../../components/LoadingWrapper.js';
import moment from 'moment';
import { usdFormatter, validateDecimal } from '../../../../tools.js';
import CustomButton from '../../../../components/CustomButton.js';
import SwitchControl from '../../../../components/SwitchControl.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle, faTrashCan } from '@fortawesome/free-solid-svg-icons';
import Big from "big.js";
import HRUserLoanInstallmentModal from './HRUserLoanInstallmentModal.js';
import { Validation, useStateWithValidation, useValidationReducer } from '../../../../validation.tsx';
import KeyValueRow from '../../../../components/KeyValueRow.js';
import CustomControl from '../../../../components/CustomControl.js';
import CustomToolTip from '../../../../components/CustomToolTip.js';

export default function HRUserLoanEditor({companyUserIdentifier, selectedLoan, handleLoanCrud, isTicketProp = false}) {
    const [isLoading, setIsLoading] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [name, setName, nameVM] = useStateWithValidation('', Validation.nonEmptyString);
    const [amount, setAmount, amountVM] = useStateWithValidation('', Validation.greaterThanZero);
    const [installment, setInstallment, installmentVM] = useStateWithValidation('', Validation.greaterThanZero);
    const [isEnabled, setIsEnabled] = useState(true);
    const [installments, setInstallments] = useState([]);
    const [selectedInstallment, setSelectedInstallment] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showDeleteInstallmentModal, setShowDeleteInstallmentModal] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const [isDeletingInstallment, setIsDeletingInstallment] = useState(false);
    const [isTicket, setIsTicket] = useState(isTicketProp)
    const isValid = useValidationReducer([nameVM, amountVM, installmentVM]);

    useEffect(() => {
        if (selectedLoan) {
            loadData();
        }
    }, []);

    function loadData(){
        new ApiRequest('hr', 'getLoan', setIsLoading, (response) => {
            setName(response.loan.name);
            setAmount(response.loan.amount);
            setIsEnabled(!!response.loan.isEnabled)
            setInstallment(response.loan.installment);
            setInstallments(response.loan.installments.sort(sortInstallments));
            setIsTicket(response.loan.isTicket);
        }).withUid(selectedLoan.uid).withNoAlertOnSuccess().send()
    }

    function handleSubmit(){
        const loan = {
            uid: selectedLoan?.uid,
            name: name,
            companyUserIdentifier: companyUserIdentifier,
            amount: validateUsd(amount),
            installment: validateUsd(installment),
            isTicket: isTicket,
            isEnabled: isEnabled,
            installmentSum: 0,
        }

        if(selectedLoan){
            new ApiRequest('hr', 'updateLoan', setIsSubmitting, () => {handleLoanCrud('update', loan, isTicket)}).withData({loan: loan}).send();
        } else {
            new ApiRequest('hr', 'createLoan', setIsSubmitting, (response) => {loan.uid = response.uid; handleLoanCrud('create', loan, isTicket)}).withData({loan: loan}).send();
        }
    }

    function handleDeleteLoan(){
        new ApiRequest('hr', 'deleteLoan', setIsDeleting, () => handleLoanCrud('delete', selectedLoan.uid, isTicket)).withUid(selectedLoan.uid).send();
    }

    function handleDeleteLoanInstallment(){
        new ApiRequest('hr', 'deleteLoanInstallment', setIsDeletingInstallment, () => {
            handleRemoveLoanInstallment();
        }).withUid(selectedInstallment.uid).send();
    }

    function handleRemoveLoanInstallment(){
        const newArray = Array.from(installments.filter(i => i.uid !== selectedInstallment.uid));
        setInstallments(newArray.sort(sortInstallments));
        setShowDeleteInstallmentModal(false);
        handleLoanCrud('installmentRemove', selectedInstallment, isTicket);
    }

    const handleCreateInstallment = (installment) => {
        const newArray = Array.from(installments);
        newArray.push(installment);
        setInstallments(newArray.sort(sortInstallments));
        setShowModal(false);
        handleLoanCrud('installment', installment, isTicket);
    }

    const sortInstallments = (a, b) => {
        if (a.payrollPeriod?.periodStart ?? a.date < b.payrollPeriod?.periodStart ?? b.date) {
            return -1;
        } else if(a.payrollPeriod?.periodStart ?? a.date > b.payrollPeriod?.periodStart ?? b.date) {
            return 1;
        } else
        return 0;
    }

    ///////////////////
    ///DESKTOP
    ///////////////////

    const payments = installments.map((i, index) => {
        let totalPaid = 0;
        for (let i = 0; i <= index; i++) {
            totalPaid += validateUsd(installments[i].amount);
        }
        let totalRemaining = validateUsd(amount) - totalPaid;
        return (
            <tr key={i.uid}>
                <td>{i.date ? moment(i.date).format('MMM D, YYYY') : moment(i.payrollPeriod.periodStart).format('MMM D, YYYY') + ' - ' + moment(i.payrollPeriod.periodEnd).format('MMM D, YYYY')}</td>
                <td>{usdFormatter.format(i.amount)}</td>
                <td>{usdFormatter.format(totalPaid)}</td>
                <td>{usdFormatter.format(totalRemaining)}</td>
                <td>
                    {!i.payrollIdentifier && <FontAwesomeIcon style={{cursor: 'pointer', color: 'var(--bs-danger)'}} onClick={() => {setShowDeleteInstallmentModal(true); setSelectedInstallment(i)}} icon={faTrashCan}/>}
                </td>
            </tr>
        )
    });

    ///////////////////
    ///MOBILE
    ///////////////////

    const mobilePayments = installments.map((i, index) => {
        let totalPaid = 0;
        for (let i = 0; i <= index; i++) {
            totalPaid += validateUsd(installments[i].amount);
        }
        let totalRemaining = validateUsd(amount) - totalPaid;
        return (
            <ListGroup.Item key={i.uid}>
                <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                    <h6 style={{fontWeight: 'bold', textAlign: 'center', margin: 0}}>{i.date ? moment(i.date).format('MMM D, YYYY') : moment(i.payrollPeriod.periodStart).format('MMM D, YYYY') + ' - ' + moment(i.payrollPeriod.periodEnd).format('MMM D, YYYY')}</h6>
                    {!i.payrollIdentifier && <FontAwesomeIcon style={{cursor: 'pointer', color: 'var(--bs-danger)'}} onClick={() => {setShowDeleteInstallmentModal(true); setSelectedInstallment(i)}} icon={faTrashCan}/>}
                </div>
                <hr/>
                <KeyValueRow titleStyle={{fontWeight: 500}} title='Payment:' value={usdFormatter.format(i.amount)}/>
                <KeyValueRow titleStyle={{fontWeight: 500}} title='Running Total:' value={usdFormatter.format(totalPaid)}/>
                <KeyValueRow titleStyle={{fontWeight: 500}} title='Running Ticket Balance:' value={usdFormatter.format(totalRemaining)}/>
            </ListGroup.Item>
        )
    });

    const amountPaid = installments.reduce((prev, curr) => prev.plus(new Big(curr.amount)), new Big('0.00'));
    const amountRemaining = validateBig(amount).sub(installments.reduce((prev, curr) => prev.plus(new Big(curr.amount)), new Big('0.00')));

    const ticketOrLoanString = isTicket ? 'Ticket' : 'Loan';

    return (
        <>
            <Modal.Header closeButton>
                <Modal.Title>{selectedLoan ? `View/Edit ${ticketOrLoanString}` : `Create ${ticketOrLoanString}`}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <LoadingWrapper isLoading={isLoading}>
                    <Container fluid>
                        <Row>
                            <CustomControl validate validationMessage={nameVM} breakpoints={{lg: 6}} type='text' title={`${ticketOrLoanString} Name`} max={30} value={name} setValue={setName}/>
                            <Col lg={6} style={{display: 'flex', alignItems: 'center', gap: 4}}>
                                <SwitchControl style={{flex: 1}} title='Enabled' value={isEnabled} setValue={setIsEnabled}/>
                                <CustomToolTip text='If enabled, the loan will appear as a deduction on the payroll reporting form for this employee.'/>
                            </Col>
                            <CustomControl validate validationMessage={amountVM} breakpoints={{lg: 6}} type='number' title='Amount Borrowed' max={99999.99} value={amount} setValue={setAmount}/>
                            <CustomControl validate validationMessage={installmentVM} breakpoints={{lg: 6}} type='number' title='Installment Amount' max={99999.99} value={installment} setValue={setInstallment}/>
                            {validateDecimal(installment) > validateDecimal(amount) && <span style={{color: 'red', textAlign: 'center'}}>Installment amount cannot be greater than amount borrowed.</span>}
                        </Row>
                    </Container>
                    { selectedLoan &&
                        <div>
                            <ListGroup style={{margin: '12px 0'}}>
                                <KeyValueRow isListItem title='Total Amount Paid' value={bigToUsd(amountPaid)}/>
                                <KeyValueRow isListItem title='Total Amount Remaining' value={bigToUsd(amountRemaining)}/>
                            </ListGroup>
                            <div className='hr-loan-desktop-block'>
                                <QuickTable title={`${ticketOrLoanString} Payments`} headers={['Payroll Period/Date', 'Payment', 'Running Total', `Running ${ticketOrLoanString} Balance`, '']} rows={payments} size='sm'/>
                            </div>
                            <div className='hr-loan-mobile-block'>
                                <h5 style={{textAlign: 'center', margin: '24px 0 16px 0'}}>{ticketOrLoanString} Payments</h5>
                                <ListGroup>
                                    {mobilePayments}
                                </ListGroup>
                            </div>
                        </div> 
                    }
                </LoadingWrapper> 
            </Modal.Body>
            <Modal.Footer>
            { isLoading ? <></> : 
                <>
                    { selectedLoan && 
                        <CustomButton disabled={!isValid} isLoading={isLoading} onClick={() => {setShowModal(true)}}>Create Manual Installment</CustomButton>
                    }
                    <CustomButton disabled={!isValid || validateDecimal(installment) > validateDecimal(amount)} isLoading={isSubmitting || isLoading} onClick={handleSubmit}>{selectedLoan ? 'Save Changes' : `Create ${ticketOrLoanString}`}</CustomButton>
                </>
            }   
            </Modal.Footer>
            {selectedLoan && 
                <Modal.Footer>
                    <CustomToolTip color='var(--bs-danger)' text='Cannot be deleted while there are installments.'/>
                    <CustomButton variant='outline-danger' disabled={installments.length} onClick={()=>setShowDeleteModal(true)}>{`Delete ${ticketOrLoanString}`}</CustomButton>
                </Modal.Footer>
            }
            <Modal centered show={showDeleteModal} onHide={() => {setShowDeleteModal(false)}} >
                <AlertConfirmation 
                    isLoading={isDeleting}
                    variant='outline-danger'
                    title={`Delete ${ticketOrLoanString}?`} 
                    message={`This ${ticketOrLoanString.toLocaleLowerCase()} data will not be recoverable upon deletion. Are you sure you want to delete this ${ticketOrLoanString.toLocaleLowerCase()}?`} 
                    callBack={handleDeleteLoan}
                    buttonLabel={`Delete ${ticketOrLoanString}`}
                />
            </Modal>
            <Modal centered show={showDeleteInstallmentModal} onHide={() => {setShowDeleteInstallmentModal(false)}} >
                <AlertConfirmation 
                    isLoading={isDeletingInstallment}
                    variant='outline-danger'
                    title={`Delete Installment`} 
                    message={`Are you sure you want to delete this ${ticketOrLoanString.toLocaleLowerCase()} installment?`} 
                    callBack={handleDeleteLoanInstallment}
                    buttonLabel={`Delete Installment`}
                />
            </Modal>
            <Modal show={showModal} onHide={() => {setShowModal(false)}}>
                <HRUserLoanInstallmentModal selectedLoan={selectedLoan} handleCreateInstallment={handleCreateInstallment}/>
            </Modal>
        </>
    );
}