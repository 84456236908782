/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, { useEffect, useState } from "react";
import Modal from 'react-bootstrap/Modal';
import { PDFViewer } from "@react-pdf/renderer";
import { ApiRequest } from '../../../../ApiManager.tsx';
import { Button, Dropdown } from "react-bootstrap";
import LoadingWrapper from "../../../../components/LoadingWrapper.js";
import { dateRangeToString } from "../../../../tools.js";
import DateRangeSelector from "../../../../components/DateRangeSelector.js";
import CustomButton from "../../../../components/CustomButton.js";
import PDFTimesheet from "./PDFTimesheet.js";

export default function TimesheetReport() {
    const [isLoading, setIsLoading] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false)  
    const [data, setData] = useState(undefined);
    const [showModal, setShowModal] = useState('');
    const [terminals, setTerminals] = useState([]);
    const [selectedTerminals, setSelectedTerminals] = useState([]);
    const [startDate, setStartDate] = useState(undefined);
    const [endDate, setEndDate] = useState(undefined);

    useEffect(() => {
        loadData();
    }, []);

    function loadData(){
        new ApiRequest('reports', 'getTimesheetReportResources', setIsLoading, (response) => {
            setTerminals(response.terminals);
            setSelectedTerminals(response.terminals);
        }).withNoAlertOnSuccess().send();
    }

    function handleGetTimesheet(){
        new ApiRequest('reports', 'getTimesheetReport', setIsSubmitting, (response) => {
            setData({
                terminals: selectedTerminals,
                users: response.users,
                timesheets: response.timesheetEntries,
                startDate: startDate,
                endDate: endDate
            });
        }).withData({startDate: startDate, endDate: endDate}).withNoAlertOnSuccess().send();
    }

    function handleSelectTerminal(terminal){
        if (selectedTerminals.find(t => t.uid == terminal.uid)) {
            setSelectedTerminals(selectedTerminals.filter(t => t.uid != terminal.uid));
        } else {
            setSelectedTerminals([...selectedTerminals, terminal])
        }
    }

    const terminalElements = terminals.map((terminal) => {
        return (
            <Dropdown.Item key={terminal.uid} active={selectedTerminals.find(t => t.uid == terminal.uid)} onClick={() => {handleSelectTerminal(terminal)}}>{terminal.name}</Dropdown.Item>
        )
    })

    return (
        <>
        <Modal.Header closeButton>
            <Modal.Title>Timesheet Reports</Modal.Title>
        </Modal.Header>
            <Modal.Header>
                <LoadingWrapper isLoading={isLoading}>
                    <div style={{display: 'flex', gap: 12, alignItems: 'center', flexWrap: 'wrap'}}>
                        <Button variant={'outline-primary'} onClick={()=>setShowModal(true)}>{!startDate || !endDate ? 'Select Date Range' : dateRangeToString(startDate, endDate)}</Button>
                        <DateRangeSelector show={showModal} onHide={()=>setShowModal(false)} handleSetDateRange={(startDate, endDate) => {setStartDate(startDate); setEndDate(endDate)}} presetOptions={['pastYear', 'pastMonth', 'pastWeek']}/>  
                        <Dropdown autoClose='outside'>
                            <Dropdown.Toggle variant="outline-primary">Filter By Terminal</Dropdown.Toggle>
                            <Dropdown.Menu>
                                {terminalElements}
                            </Dropdown.Menu>
                        </Dropdown>
                        <CustomButton disabled={!startDate || !endDate} onClick={handleGetTimesheet} isLoading={isSubmitting}>Generate Timesheet Report</CustomButton>
                    </div>
                </LoadingWrapper>
            </Modal.Header>
            <Modal.Body>
                {!isSubmitting && data &&
                <PDFViewer style={{width: '100%', height: '99%'}}>
                    <PDFTimesheet data={data} selectedTerminals={selectedTerminals}/>
                </PDFViewer>  
            }
            </Modal.Body>
        </>
    )
}