/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, {useState} from "react";
import { Modal, Button, Form } from "react-bootstrap";
import NDBonus from "../../../../Models/NDBonus.js";
import RadioControl from "../../../../../../../components/RadioControl.js";
import CustomControl from "../../../../../../../components/CustomControl.js";
import SwitchControl from "../../../../../../../components/SwitchControl.js";
import { Validation, useStateWithValidation, useValidationReducer } from "../../../../../../../validation.tsx";
import { getFakeUID } from "../../../../../../../tools.js";
import PayrollTooltip from "../../PayrollTooltip.js";
import { useToggle } from "../../../../../../../hooks.tsx";
import PayrollMoneyControl from "../../../PayrollMoneyControl.js";

export default function AddNDBonusForm({handleAddNonDiscretionaryBonus}) {
    const [amount, setAmount, amountValidationMessage] = useStateWithValidation('', Validation.greaterThanZero);
    const [type, setType, typeValidationMessage] = useStateWithValidation('', Validation.nonEmptyString);
    const [isReoccurring, toggleIsReoccurring] = useToggle(false);
    const [applyToAll, toggleApplyToAll] = useToggle(false);

    const isValid = useValidationReducer([amountValidationMessage, typeValidationMessage]);


    const handleSubmit = () => {
        handleAddNonDiscretionaryBonus(new NDBonus(getFakeUID(), type, amount, isReoccurring), applyToAll);
    }

    
    return (
        <>
            <Modal.Header closeButton>
                <Modal.Title>Adding Nondiscretionary Bonus</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{display: 'flex', flexDirection: 'column', gap: 6}}>
                <RadioControl title='Type' validate validationMessage={typeValidationMessage} inline selection={type} setSelection={setType} optionNames={NDBonus.ndBonusTypes} optionValues={NDBonus.ndBonusTypes}/>
                <div style={{display: 'flex', gap: 6}}>
                    <PayrollMoneyControl validate validationMessage={amountValidationMessage} lhDecimalPlaces={3} value={amount} setValue={setAmount} title='Amount'/>
                    <SwitchControl value={isReoccurring} setValue={toggleIsReoccurring} title={'Reoccurring'}/>
                </div>
                <PayrollTooltip boldText='Non-discretionary Bonuses' text={'Non-discretionary bonuses are incorporated into the FLSA overtime rate for employees who qualify for overtime.'}/>                
                <PayrollTooltip boldText='Reoccuring' text={' - Next payroll period, this Additional Pay will be automatically added to the employee(s)'}/>                
            </Modal.Body>
            <Modal.Footer style={{display: 'flex', justifyContent: 'flex-end'}}>
                <Form.Check type='switch' checked={applyToAll} onChange={toggleApplyToAll} label='Add bonus to all employees'/>
                <Button style={{color: 'white'}} disabled={!isValid} onClick={handleSubmit}>Submit</Button>
            </Modal.Footer>
        </>
    )
}
