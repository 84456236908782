/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, { useEffect, useState } from 'react';
import { Col, Container, Dropdown, ListGroup, Modal, Row } from 'react-bootstrap';
import ApiManager, { ApiRequest } from '../../ApiManager.tsx';
import DropdownControl from '../../components/DropdownControl.js';
import QuickTable from '../../components/QuickTable.js';
import LoadingWrapper from '../../components/LoadingWrapper.js';
import SearchBar from '../../components/SearchBar.js';
import DropdownItemWithCheck from '../../components/DropdownItemWithCheck.js';
import './Shadow.css'
import { validateInteger } from '../../tools.js';
import AlertConfirmation from '../../components/AlertModals/AlertConfirmation.js';
import { useLoadData } from '../../hooks.tsx';

export default function Shadow({}) {
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingUsers, setIsLoadingUsers] = useState(false);
    const [isShadowing, setIsShadowing] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [companies, setCompanies] = useState([]);
    const [selectedCompany, setSelectedCompany] = useState(null);
    const [selectedCompanyUser, setSelectedCompanyUser] = useState(null)
    const [users, setUsers] = useState([]);
    const [search, setSearch] = useState('');
    const [titleFilter, setTitleFilter] = useState(null);
    const [terminalFilter, setTerminalFilter] = useState(null);
    const [terminatedFilter, setTerminatedFilter] = useState(true);
    const allTitles = ['Admin', 'AO', 'BC', 'Driver'];
    const allTerminals = [...new Set(users.map(u => u.terminalName))].sort((a, b) => a.localeCompare(b));
    const numberFiltersActive = (titleFilter ? 1 : 0) + (terminalFilter ? 1 : 0) + (terminatedFilter ? 1 : 0);

    useEffect(()=>{
        if(selectedCompany){
            handleGetCompanyUsers();
        }
    }, [selectedCompany])

    useLoadData(() => {
        new ApiRequest('admin', 'getCompanies', setIsLoading, (response) => setCompanies(response.companies)).withNoAlertOnSuccess().send();
    })

    function handleGetCompanyUsers(){
        new ApiRequest('admin', 'getCompanyUsers', setIsLoadingUsers, (response) => {
            setUsers(response.users.sort((a, b) => {
                if (!!a.title != !!b.title) {
                    return a.title ? -1 : 1;
                } else {
                    return `${a.firstName}${a.lastName}` < `${b.firstName}${b.lastName}` ? -1 : 1;
                }
            }));
        }).withUid(selectedCompany).withNoAlertOnSuccess().send()
    }

    function handleShadowUser(companyUserIdentifier) {
        new ApiRequest('admin', 'shadow', setIsShadowing, () => {
            ApiManager.instance.handleCheckAuthentication();
        }).withData({companyUserIdentifier: companyUserIdentifier}).send();
    }

    ////////////////////////////
    /// DESKTOP QUICKTABLE ROWS
    ////////////////////////////

    const companyUserRows = users.filter(user => {
        if(titleFilter && user.title !== titleFilter)
            return false;
        if(terminalFilter && user.terminalName !== terminalFilter)
            return false;
        if(!terminatedFilter && (user.isEnabled == 0 || user.companyUserIsEnabled == 0)){
            return false;
        }

        const fullName = `${user.firstName}${user.lastName}`;
        const fullNameReversed = `${user.lastName}${user.firstName}`;

        return (user.firstName.toLocaleLowerCase().includes(search.toLocaleLowerCase()) 
            || user.lastName.toLocaleLowerCase().includes(search.toLocaleLowerCase())
            || fullName.toLocaleLowerCase().includes(search.replaceAll(' ','').toLocaleLowerCase()))
            || fullNameReversed.toLocaleLowerCase().includes(search.replaceAll(' ','').toLocaleLowerCase())

    }).map((user) => {
        return (
            <tr style={{cursor: 'pointer'}} key={user.companyUserIdentifier} onClick={() => {handleShadowUser(user.companyUserIdentifier)}}>
                <td>{`${user.firstName} ${user.lastName}${user.isEnabled == 0 ? ' (Disabled)' : !user.title ? ' (Terminated)' : ''}`}</td>
                <td>{user.title}</td>
                <td>{user.terminalName}</td>
            </tr>
        )
    })

    /////////////////////////////
    /// LIST GROUP ITEMS MOBILE
    /////////////////////////////

    const companyUserRowsMobile = users.filter(user=>{
        if(titleFilter && user.title !== titleFilter)
            return false;
        if(terminalFilter && user.terminalName !== terminalFilter)
            return false;
        if(!terminatedFilter && (user.isEnabled == 0 || user.companyUserIsEnabled == 0)){
            return false;
        }

        const fullName = `${user.firstName}${user.lastName}`;
        const fullNameReversed = `${user.lastName}${user.firstName}`;

        return (user.firstName.toLocaleLowerCase().includes(search.toLocaleLowerCase()) 
            || user.lastName.toLocaleLowerCase().includes(search.toLocaleLowerCase())
            || fullName.toLocaleLowerCase().includes(search.replaceAll(' ','').toLocaleLowerCase()))
            || fullNameReversed.toLocaleLowerCase().includes(search.replaceAll(' ','').toLocaleLowerCase())

    }).map((user) => {
        return (
            <ListGroup.Item className='cursor-pointer' action key={user.companyUserIdentifier} onClick={() => {handleShadowUser(user.companyUserIdentifier)}}>
                <div style={{display: 'flex', flexDirection: 'column', padding: 8}}>
                        <h4 style={{fontWeight: 'bold', marginBottom: 12}}>{`${user.firstName} ${user.lastName}${user.isEnabled == 0 ? ' (Disabled)' : user.companyUserIsEnabled == 0 ? ' (Terminated)' : ''}`}</h4>
                        <h6 style={{marginBottom: 6}}><strong>Job Title: </strong>{user.title}</h6>
                        <h6 style={{marginBottom: 6}}><strong>Terminal: </strong>{user.terminalName}</h6>
                </div>
            </ListGroup.Item>
        )
    })

    /////////////////////////////
    /// Filter Dropdown Element
    ////////////////////////////

    const filter = (
        <Dropdown autoClose='outside' >
            <Dropdown.Toggle style={{display: 'block', marginLeft: 'auto', width: 117}} variant='outline-primary'>Filter(s) {numberFiltersActive != 0 && `+${numberFiltersActive}`}</Dropdown.Toggle>
            <Dropdown.Menu>
                <Dropdown.Header>Show Terminated/Disabled</Dropdown.Header>
                <DropdownItemWithCheck label='Show Terminated and Disabled Users' active={terminatedFilter} onClick={() => setTerminatedFilter(!terminatedFilter)}/>
                <Dropdown.Divider/>
                <Dropdown.Header>Filter by Title</Dropdown.Header>
                {allTitles.map((title) => {
                    return <DropdownItemWithCheck key={title} label={title} active={titleFilter === title} onClick={() => title === titleFilter ? setTitleFilter(null) : setTitleFilter(title)}/>
                })}
                <Dropdown.Divider/>
                <Dropdown.Header>Filter by Terminal</Dropdown.Header>
                {allTerminals.map((terminal) => {
                   return  <DropdownItemWithCheck key={terminal} label={terminal} active={terminalFilter === terminal} onClick={() => terminal === terminalFilter ? setTerminalFilter(null) : setTerminalFilter(terminal)}/>
                })}
            </Dropdown.Menu>
        </Dropdown>
    )

    return (
        <LoadingWrapper isLoading={isLoading}>
            <div style={{display: 'flex', flexDirection: 'column', gap: 12, flex: 1, padding: 12, overflowY: 'auto'}}>
                <DropdownControl title='Select Company' selection={selectedCompany} setSelection={setSelectedCompany} itemNames={companies.map(c=>c.name)} itemValues={companies.map(c=>c.uid)}/>
                <LoadingWrapper isLoading={isLoadingUsers}>
                    { !!selectedCompany &&
                        <>
                            <hr style={{margin: 0}}/>
                            { users.length > 0 ? 
                                <Row>
                                    <Col lg={12} className='search-bar-filter'>
                                        <SearchBar label={`Search`} value={search} setValue={setSearch}/>
                                        {filter}
                                    </Col>
                                    { companyUserRows.length > 0 ?
                                        <>
                                            <div className='desktop-shadow-company-users'>
                                                <QuickTable
                                                    responsive={false}
                                                    hover
                                                    headers={['Name', 'Job Title', 'Terminal']}
                                                    rows={[companyUserRows]}
                                                />
                                            </div>
                                            <div className='mobile-shadow-company-users'>
                                                <ListGroup>
                                                    {companyUserRowsMobile}
                                                </ListGroup>
                                            </div>
                                        </>
                                    : <h4 style={{textAlign: 'center'}}>No users in this company with the applied filters/search.</h4>
                                    }
                                </Row>
                            : 
                                <h4 style={{textAlign: 'center'}}>No users in this company.</h4>
                            }
                        </>
                    }
                </LoadingWrapper>
            </div>
        </LoadingWrapper>
    )
}