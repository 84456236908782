/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import '../HumanResources.css';
import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import moment from 'moment';
import QuickTable from '../../../../components/QuickTable';
import { Accordion, Button, Dropdown, ListGroup } from 'react-bootstrap';
import HRUserPtoModal from '../HRUserPTO/HRUserPtoModal';
import DateRangeSelector from '../../../../components/DateRangeSelector';
import { dateIsInRange, dateRangeToString } from '../../../../tools';
import { toFixedMax } from '../../../../tools';
import { ApiRequest } from '../../../../ApiManager.tsx';
import LoadingWrapper from '../../../../components/LoadingWrapper';
import HRCreateTimeOffModal from '../HRUserSchedule/HRCreateTimeOffModal.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import KeyValueRow from '../../../../components/KeyValueRow.js';

export default function HRUserPto({selectedEmployee, startDateProp, endDateProp, handleRecalculate}){
    const [isLoading, setIsLoading] = useState(false);
    const [modalSwitch, setModalSwitch] = useState('');
    const [transactions, setTransactions] = useState([]);
    const [selectedTransaction, setSelectedTransaction] = useState(undefined);
    const [startDate, setStartDate] = useState(startDateProp);
    const [endDate, setEndDate] = useState(endDateProp);
    const [showDateRangeSelector, setShowDateRangeSelector] = useState(false);
    const [timeOff, setTimeOff] = useState([]);

    useEffect(() => {
        loadData();
    }, [selectedEmployee])

    function loadData(){
        new ApiRequest('hr', 'getPto', setIsLoading, (response) => {
            setTransactions(response.transactions);
            setTimeOff(response.timeOff);
        }).withData({companyUserIdentifier: selectedEmployee.companyUserIdentifier}).withNoAlertOnSuccess().send();
    }

    function sortTransactions(a, b) {
        if (a.dateCreated < b.dateCreated) {
            return -1;
        } else if (a.dateCreated > b.dateCreated) {
            return 1;
        }
        return 0;
    }

    function sortScheduledTransactions(a, b) {
        if (a.timeOffDate < b.timeOffDate) {
            return -1;
        } else if (a.timeOffDate > b.timeOffDate) {
            return 1;
        }
        return 0;
    }

    function sortAccruedTransactions(a, b) {
        if (a.payrollPeriod.periodStart < b.payrollPeriod.periodStart) {
            return 1;
        } else if (a.payrollPeriod.periodStart > b.payrollPeriod.periodStart) {
            return -1;
        }
        return 0;
    }

    function handleTransactionCrud(type, data) {
        let newArray = Array.from(transactions);
        if (type === 'create') {
            newArray.push(data);
        } else if (type === 'update') {
            newArray = newArray.filter(t => t.uid != data.uid);
            newArray.push(data);
        } else if (type === 'delete') {
            newArray = newArray.filter(t => t.uid != data.uid);
        }

        if (handleRecalculate) {
            handleRecalculate();
        }

        setModalSwitch('');
        setTransactions(newArray);
    }

    function handleAddTimeOffPtoCrud(response, _, ptoDays){
        const ptoArray = []
        ptoDays.forEach((pto, i) => {
            const newPto = {};
            newPto.uid = response.ptoUids[i];
            newPto.dateCreated = moment().format('YYYY-MM-DD');
            newPto.timeOffDate = pto.date;
            newPto.amount = pto.hours;
            newPto.timeOffIdentifier = response.uid;
            ptoArray.push(newPto)
        })

        setTransactions((prevState) => [...prevState, ...ptoArray]);
        setModalSwitch('');
    }

    function handleSetSelectedDateRange(startDate, endDate) {
        setStartDate(startDate);
        setEndDate(endDate);
    }

    const balance = transactions.reduce((prev, curr) => {
        return prev + parseFloat(curr.amount);
    }, 0); 

    ////////////////
    /// DEKSTOP
    ////////////////
    const scheduledPtoRows = transactions.filter(i => i.timeOffIdentifier && dateIsInRange(i.timeOffDate, startDate, endDate)).sort(sortScheduledTransactions).map((item) => {
        return (
            <tr className='cursor-pointer' key={item.uid} onClick={() => {setSelectedTransaction(item); setModalSwitch('transactionEditor')}}>

                <td>{moment(item.timeOffDate).format('MMM D, YYYY')}</td>
                <td>{moment(item.dateCreated).format('MMM D, YYYY')}</td>
                <td style={{color: item.amount > 0 ? 'green' : 'red'}}>{item.amount > 0 ? `+${item.amount}` : item.amount}</td>
                <td>{item.payrollPeriod && `${moment(item.payrollPeriod.periodStart).format('MMM D, YYYY')} - ${moment(item.payrollPeriod.periodEnd).format('MMM D, YYYY')}`}</td>
                <td style={{whiteSpace: 'nowrap', textOverflow: 'ellipsis', maxWidth: 200, overflowX: 'hidden'}}>{item.notes}</td>
            </tr>
        );
    });

    const manualPtoRows = transactions.filter(i => !i.timeOffIdentifier && !i.payrollPeriod).sort(sortTransactions).map((item) => {
        return (
            <tr className='cursor-pointer' key={item.uid} onClick={() => {setSelectedTransaction(item); setModalSwitch('transactionEditor')}}>
                <td>{moment(item.dateCreated).format('MMM D, YYYY')}</td>
                <td style={{color: item.amount > 0 ? 'green' : 'red'}}>{item.amount > 0 ? `+${item.amount}` : item.amount}</td>
                <td style={{whiteSpace: 'nowrap', textOverflow: 'ellipsis', maxWidth: 200, overflowX: 'hidden'}}>{item.notes}</td>
            </tr>
        );
    });

    const accruedPtoRows = transactions.filter(i => !i.timeOffIdentifier && i.payrollPeriod).sort(sortAccruedTransactions).map((item) => {
        return (
            <tr key={item.uid}>
                <td>{`${moment(item.payrollPeriod.periodStart).format('MMM D, YYYY')} - ${moment(item.payrollPeriod.periodEnd).format('MMM D, YYYY')}`}</td>
                <td style={{color: 'green'}}>{`+${item.amount}`}</td>
            </tr>
        );
    })

    ////////////////
    /// MOBILE
    ////////////////
    const mobileScheduledPtoRows = (
        <ListGroup>
            {transactions.filter(i => i.timeOffIdentifier && dateIsInRange(i.timeOffDate, startDate, endDate)).sort(sortScheduledTransactions).map((item) => {
                return (
                    <ListGroup.Item action key={item.uid} onClick={() => {setSelectedTransaction(item); setModalSwitch('transactionEditor')}}>
                        <h6 style={{fontWeight: 'bold'}}>{moment(item.timeOffDate).format('MMM D, YYYY')}</h6>
                        <KeyValueRow title={'Date Approved'} value={moment(item.dateCreated).format('MMM D, YYYY')}/>
                        <KeyValueRow title={'Amount (Hours)'} valueStyle={{color: item.amount > 0 ? 'green' : 'red'}} value={item.amount}/>
                        <KeyValueRow title={'Payroll Period Paid'} value={item.payrollPeriod ? `${moment(item.payrollPeriod.periodStart).format('MMM D, YYYY')} - ${moment(item.payrollPeriod.periodEnd).format('MMM D, YYYY')}` : 'N/A'}/>
                        <KeyValueRow title={'Notes'} value={item.notes}/>
                    </ListGroup.Item>
                )
            })}
        </ListGroup>
    )   

    const mobileManualPtoRows = (
        <ListGroup>
            {transactions.filter(i => !i.timeOffIdentifier && !i.payrollPeriod).sort(sortTransactions).map((item) => {
                return (
                    <ListGroup.Item action key={item.uid} onClick={() => {setSelectedTransaction(item); setModalSwitch('transactionEditor')}}>
                        <h6 style={{fontWeight: 'bold'}}>{moment(item.dateCreated).format('MMM D, YYYY')}</h6>
                        <KeyValueRow title={'Amount (Hours)'} valueStyle={{color: item.amount > 0 ? 'green' : 'red'}} value={item.amount}/>
                        <KeyValueRow title={'Notes'} value={item.notes}/>
                    </ListGroup.Item>
                )
            })}
        </ListGroup> 
    )
    const mobileAccruedPtoRows = (
        <ListGroup>
            {transactions.filter(i => !i.scheduleItem && i.payrollPeriod).sort(sortAccruedTransactions).map((item) => {
                return (
                    <ListGroup.Item key={item.uid}>
                        <KeyValueRow title={'Payroll Period Earned'} value={`${moment(item.payrollPeriod.periodStart).format('MMM D, YYYY')} - ${moment(item.payrollPeriod.periodEnd).format('MMM D, YYYY')}`}/>
                        <KeyValueRow title={'Amount (Hours)'} value={item.amount}/>
                    </ListGroup.Item>
                )
            })}
        </ListGroup> 
    )

    const toolsDropdown = (
        <Dropdown>
            <Dropdown.Toggle variant='outline-primary'>
                <FontAwesomeIcon icon={faBars}/>
            </Dropdown.Toggle>
            <Dropdown.Menu>
                <Dropdown.Item onClick={() => {setSelectedTransaction(null); setModalSwitch('transactionEditor')}}>Add/Subtract PTO</Dropdown.Item>
                <Dropdown.Item onClick={() => setModalSwitch('showCreateTimeOff')}>Schedule Time Off</Dropdown.Item>
            </Dropdown.Menu>
        </Dropdown>
    )

    const content = (
        <div style={{height: '100%', display: 'flex', flexDirection: 'column', gap: 12, overflow: 'auto'}}>
                <div style={{display: 'flex', gap: 12, justifyContent: 'right'}}>
                    <Button variant='outline-primary' onClick={() => setShowDateRangeSelector(true)}>Viewing: <b>{dateRangeToString(startDate, endDate)}</b></Button>
                    <DateRangeSelector handleSetDateRange={handleSetSelectedDateRange} show={showDateRangeSelector} onHide={() => setShowDateRangeSelector(false)} presetOptions={['pastYear', 'pastMonth', 'pastWeek', 'nextWeek', 'nextMonth', 'nextYear', 'allTime']}/>
                    {toolsDropdown}
            </div>
            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'end'}}>
                <span>Current PTO Balance: <b>{`${toFixedMax(balance, 2)} Hours`}</b></span>
            </div>
            <h4 style={{textAlign: 'center'}}>PTO Transaction History</h4>
            <Accordion alwaysOpen defaultActiveKey={['0', '1', '2']}>
                <Accordion.Item eventKey='0'>
                    <Accordion.Header><b>Scheduled PTO</b></Accordion.Header>
                    <Accordion.Body>
                        <div className='desktop-block'>
                            <QuickTable 
                                headers={['Date of PTO', 'Date Approved', 'Amount (Hours)', 'Payroll Period Paid', 'Notes']}
                                rows={scheduledPtoRows}
                                hover
                                style={{flex: 0}}
                            />
                        </div>
                        <div className='mobile-block'>
                            {mobileScheduledPtoRows}
                        </div>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey='1'>
                    <Accordion.Header><b>Manually Entered Transactions</b></Accordion.Header>
                    <Accordion.Body>
                        <div className='desktop-block'>
                            <QuickTable 
                                headers={['Date Created', 'Amount (Hours)', 'Notes']}
                                rows={manualPtoRows}
                                hover
                                style={{flex: 0}}
                                />
                        </div>
                        <div className='mobile-block'>
                            {mobileManualPtoRows}
                        </div>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey='2'>
                    <Accordion.Header><b>Accrued PTO</b></Accordion.Header>
                    <Accordion.Body>
                        <div className='desktop-block'>
                            <QuickTable 
                                headers={['Payroll Period Earned', 'Amount (Hours)']}
                                rows={accruedPtoRows}
                                hover
                                style={{flex: 0}}
                            />
                        </div>
                        <div className='mobile-block'>
                            {mobileAccruedPtoRows}
                        </div>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
            <Modal show={modalSwitch === 'transactionEditor'} onHide={() => setModalSwitch('')} size='lg'>
                <HRUserPtoModal
                    handleTransactionCrud={handleTransactionCrud}
                    companyUserIdentifier={selectedEmployee.companyUserIdentifier}
                    selectedTransaction={selectedTransaction}
                />
            </Modal>
            <Modal show={modalSwitch === 'showCreateTimeOff'} onHide={() => setModalSwitch('')} size='lg'>
                <HRCreateTimeOffModal companyUserIdentifier={selectedEmployee.companyUserIdentifier} timeOff={timeOff} handleCrud={handleAddTimeOffPtoCrud}/>
            </Modal>
        </div>
    )

    return (
        <LoadingWrapper isLoading={isLoading}>
            {content}
        </LoadingWrapper>
    )

}