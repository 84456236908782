/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, { useMemo, useState } from "react";
import Modal from 'react-bootstrap/Modal';
import { ListGroup } from "react-bootstrap";
import PayrollEntry from "../Models/PayrollEntry";
import { useLoadData } from "../../../../hooks.tsx";
import { ApiRequest } from "../../../../ApiManager.tsx";
import LoadingWrapper from "../../../../components/LoadingWrapper";
import PayrollEntryPaySection from "./PayrollEntryEditor/Pay/PayrollEntryPaySection.js";
import { toast } from "react-toastify";
import SearchBar from "../../../../components/SearchBar.js";

export default function PayrollEmployeeSelector({entries, handleAddEntry, employeeFilter, setEmployeeFilter, hideModal, payrollPeriod}) {
    const [isLoading, setIsLoading] = useState(false);
    const [employees, setEmployees] = useState([]);
    const [search, setSearch] = useState('');

    const otherEmployees = useMemo(() => {
        return employees.filter(employee => !(entries.some(e => e.companyUserIdentifier == employee.companyUserIdentifier)));
    }, [entries, employees])

    const filteredEntries = useMemo(() => {
        return entries.filter(e => {
            return `${e.lastName}, ${e.firstName}${e.middleName ? ` ${e.middleName.substring(0, 1)}` : ''} (${e.title ?? 'Terminated'})`.toLowerCase().includes(search.toLowerCase());
        });
    }, [entries, search])

    const filteredOtherEmployees = useMemo(() => {
        return otherEmployees.filter(e => {
            return `${e.lastName}, ${e.firstName}${e.middleName ? ` ${e.middleName.substring(0, 1)}` : ''} (${e.title ?? 'Terminated'})`.toLowerCase().includes(search.toLowerCase());
        });
    })

    useLoadData(() => {
        new ApiRequest('payroll', 'getEmployees', setIsLoading, (response) => {
            setEmployees(response.employees);
        }).withData({companyIdentifier: payrollPeriod.companyIdentifier}).withNoAlertOnSuccess().send();
    })

    function handleSetEmployeeFilter(companyUserIdentifier) {
        if (employeeFilter.includes(companyUserIdentifier)) {
            setEmployeeFilter(employeeFilter.filter(c => c != companyUserIdentifier));
        } else {
            setEmployeeFilter([...employeeFilter, companyUserIdentifier]);
        }
    }

   
    const filterRows = filteredEntries.map((entry) => {
        const isSelected = employeeFilter.includes(entry.companyUserIdentifier);
        
        return (
            <ListGroup.Item key={entry.companyUserIdentifier} style={{display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap', gap: 6}} action active={isSelected} onClick={() => {handleSetEmployeeFilter(entry.companyUserIdentifier)}}>
                <span>{entry.name()}</span>
                <span>{entry.terminalName}</span>
            </ListGroup.Item>
        )
    });

    const usersNotInEntries = filteredOtherEmployees.map((employee) => {
        return (
            <OtherEmployeeListItem key={employee.companyUserIdentifier} payrollIdentifier={payrollPeriod.uid} employee={employee} handleAddEntry={handleAddEntry}/>
        )
    });

    return (
        <>
            <Modal.Header>
                <Modal.Title>Select Employees to Include in Payroll</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{display: 'flex', flexDirection: 'column', gap: 12}}>
                <SearchBar label='Filter by name...' value={search} setValue={setSearch}/>
                <PayrollEntryPaySection title='Employee Filter'>
                    <ListGroup>{filterRows}</ListGroup>
                </PayrollEntryPaySection>
                <PayrollEntryPaySection title='Add Other Employees'>
                    <LoadingWrapper height={200} isLoading={isLoading}>
                        { usersNotInEntries.length > 0 ?
                            <ListGroup>{usersNotInEntries}</ListGroup>
                        :
                            <span style={{opacity: 0.5, fontWeight: 'bold'}}>None</span>
                        }
                    </LoadingWrapper>
                </PayrollEntryPaySection>
            </Modal.Body>
        </>
    )
}

function OtherEmployeeListItem({employee, payrollIdentifier, handleAddEntry}) {
    const [isLoading, setIsLoading] = useState(false);

    function handleGenerateEntry() {
        new ApiRequest('payroll', 'recalculateEntry', setIsLoading, (response) => {
            handleAddEntry(new PayrollEntry(response.payrollEntry));
            toast.success(`Successfully generated payroll entry for ${employee.firstName} ${employee.lastName}`)
        }).withData({companyUserIdentifier: employee.companyUserIdentifier, payrollIdentifier: payrollIdentifier}).withNoAlertOnSuccess().send();
    }

    return (
        <ListGroup.Item style={{ display: 'flex', justifyContent: 'space-between', gap: 6, flexWrap: 'wrap', padding: isLoading ? 0 : undefined}} action disabled={isLoading} onClick={handleGenerateEntry}>
            <LoadingWrapper isLoading={isLoading} height={41}>
                <span>{`${employee.lastName}, ${employee.firstName}${employee.middleName ? ` ${employee.middleName.substring(0, 1)}` : ''} (${employee.title ?? 'Terminated'})`}</span>
                <span>{employee.terminalName}</span>
            </LoadingWrapper>
        </ListGroup.Item>
    )
}