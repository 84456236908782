/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, { useEffect, useState } from 'react';
import moment from 'moment';
import Modal from 'react-bootstrap/Modal';
import InputGroup from 'react-bootstrap/InputGroup';
import Button from 'react-bootstrap/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { Dropdown, ListGroup } from 'react-bootstrap';
import { titleDictionary, naturalSort, checkPermission, getNameMatches } from '../../../../tools';
import SearchBar from '../../../../components/SearchBar';
// import HRHireCSV from '../../HRHire/HRHireCSV/HRHireCSV';
// import HRFedexNameMatch from './HRFedexNameMatch.js';
import Cookies from 'universal-cookie';
import DropdownItemWithCheck from '../../../../components/DropdownItemWithCheck';
import HumanResourcesUserRow from './HumanResourcesUserRow.js';
import MobileHumanResourcesUserRow from './MobileHumanResourcesUserRow.js';
import HRHubDateSelectionModal from './HRHubDateSelectionModal.js';
import QuickTable from '../../../../components/QuickTable.js';
import { ApiRequest } from '../../../../ApiManager.tsx';
import HRHire from '../HRHire/HRHire.js';
import HRHireCSV from '../HRHire/HRHireCSV/HRHireCSV.js';

export default function HRHub({setSelectedEmployee, selectedCompany, selectedDate, setSelectedDate, users, terminals, timeData, setTimeData, totalWeeklyHoursWorked}) {
    const [search, setSearch] = useState('');
    const [showDisabledUsers, setShowDisabledUsers] = useState(false);
    const [selectedTerminals, setSelectedTerminals] = useState([]);
    const [selectedTitles, setSelectedTitles] = useState(['Driver', 'Admin', 'AO', 'BC']);
    const [filterByScheduled, setFilterByScheduled] = useState(false);
    const [filterByUnscheduled, setFilterByUnscheduled] = useState(false);
    const [sortByScheduled, setSortByScheduled] = useState(false);
    const [sortByUnscheduled, setSortByUnscheduled] = useState(false);
    const [sortByClockedIn, setSortByClockedIn] = useState(false);
    const [sortByClockedOut, setSortByClockedOut] = useState(false);
    const [modalSwitch, setModalSwitch] = useState('');
    const auth = new Cookies().get('auth');

    useEffect(() => {
        const intervalId = setInterval(handleRefreshTimeData, 20000);
        return () => {
            clearInterval(intervalId);
        }
    }, [])

    function handleRefreshTimeData() {
        new ApiRequest('hr', 'getTimeData', () => {}, (response) => {
            setTimeData(response.timesheetEntries);
        }).withData({date: selectedDate, companyIdentifier: selectedCompany}).sendInBackground();
    }

    useEffect(() => {
        setModalSwitch('');
    }, [selectedDate]);

    useEffect(() => {
        setSelectedTerminals(terminals.map(t => t.uid));
    }, [terminals])

    function handleSetUserTimeDataForSelectedDate(companyUserIdentifier, newTimeData) {
        const newArray = [
            ...timeData.filter((t) => {return !moment(t.inTime).isSame(moment(selectedDate), 'day') || t.companyUserIdentifier !== companyUserIdentifier}),
            ...newTimeData
        ];
        setTimeData(newArray);
    }

    function handleSelectTerminal(uid) {
        let newArray = Array.from(selectedTerminals);
        if (newArray.includes(uid)) {
            newArray = newArray.filter(e => e !== uid);
        } else {
            newArray.push(uid);
        }
        setSelectedTerminals(newArray);
    }

    function handleSelectTitle(value) {
        let newArray = Array.from(selectedTitles);
        if (newArray.includes(value)) {
            newArray = newArray.filter(e => e !== value);
        } else {
            newArray.push(value);
        }
        setSelectedTitles(newArray);
    }
    
    const timeDataOnSelectedDate = timeData.filter((t) => moment(t.inTime).isSame(moment(selectedDate), 'day'));

    const filteredUsers = users.filter((u) => {
        const matchesSearch = getNameMatches(u, search);
        const inTerminal = selectedTerminals.includes(u.terminalIdentifier);
        const titleIsIncluded = selectedTitles.includes(u.title) || !u.title;
        const isEnabledOrAllowDisabled = (u.isEnabled && (u.title || u.firstPayDateEffective)) || showDisabledUsers;
        const userIsScheduled = u.route;
        const passesScheduleFilter = (filterByScheduled && userIsScheduled) || (filterByUnscheduled && !userIsScheduled) || (!filterByScheduled && !filterByUnscheduled);
        return (isEnabledOrAllowDisabled && inTerminal && titleIsIncluded && matchesSearch && passesScheduleFilter);
    });

    
    const sortedUsers = filteredUsers.sort((a, b) => {
        const aIsScheduled = a.route;
        const bIsScheduled = b.route;
        if (aIsScheduled !== bIsScheduled) {
            if (sortByScheduled) {
                return aIsScheduled ? -1 : 1;
            } else if (sortByUnscheduled) {
                return aIsScheduled ? 1 : -1;
            }
        }

        const aClockedIn = timeDataOnSelectedDate.some(t => t.companyUserIdentifier === a.companyUserIdentifier && !t.outTime);
        const bClockedIn = timeDataOnSelectedDate.some(t => t.companyUserIdentifier === b.companyUserIdentifier && !t.outTime);
        if (aClockedIn !== bClockedIn) {
            if (sortByClockedIn) {
                return aClockedIn ? -1 : 1;
            } else if (sortByClockedOut) {
                return aClockedIn ? 1 : -1;
            }
        }
        return 0;
    })
    
    const userElements = sortedUsers.map((user) => {
        return (
            <HumanResourcesUserRow 
                key={user.uid} 
                user={user} 
                timeData={timeData} 
                selectedDate={selectedDate} 
                terminals={terminals} 
                setSelectedEmployee={setSelectedEmployee}
                handleSetUserTimeDataForSelectedDate={handleSetUserTimeDataForSelectedDate} 
                users={users}
            />
        )
    });

    const mobileUserElements = sortedUsers.map((user) => {
        return (
            <MobileHumanResourcesUserRow 
                key={user.uid} 
                user={user} 
                timeData={timeData} 
                selectedDate={selectedDate} 
                terminals={terminals} 
                setSelectedEmployee={setSelectedEmployee}
                handleSetUserTimeDataForSelectedDate={handleSetUserTimeDataForSelectedDate} 
                users={users}
            />
        )
    });

    const filterDropdownMenu = (
        <Dropdown autoClose={'outside'}>
            <Dropdown.Toggle variant="outline-primary">Filter By</Dropdown.Toggle>
            <Dropdown.Menu>
                <Dropdown.Header>Terminals</Dropdown.Header>
                {naturalSort(terminals, 'name').map((terminal) => {
                    return <DropdownItemWithCheck key={terminal.uid} label={terminal.name} active={selectedTerminals.includes(terminal.uid)} onClick={() => {handleSelectTerminal(terminal.uid)}}/>
                })}
                <Dropdown.Header>Titles</Dropdown.Header>
                {Object.keys(titleDictionary).map((key) => {
                    return <DropdownItemWithCheck label={key} key={key} active={selectedTitles.includes(key)} onClick={() => {handleSelectTitle(key)}}/>
                })}
                <Dropdown.Divider/>
                <Dropdown.Header>Other</Dropdown.Header>
                <DropdownItemWithCheck label='Only Show Scheduled Employees' active={filterByScheduled} onClick={() => {setFilterByScheduled(!filterByScheduled); setFilterByUnscheduled(false)}}/>
                <DropdownItemWithCheck label='Only Show Unscheduled Employees' active={filterByUnscheduled} onClick={() => {setFilterByUnscheduled(!filterByUnscheduled); setFilterByScheduled(false)}}/>
                <DropdownItemWithCheck label='Show Terminated Employees' active={showDisabledUsers} onClick={() => setShowDisabledUsers(!showDisabledUsers)}/>
            </Dropdown.Menu>
        </Dropdown>
    )

    const sortByDropDownMenu = (
        <Dropdown autoClose={'outside'}>
            <Dropdown.Toggle variant="outline-primary">Sort By</Dropdown.Toggle>
            <Dropdown.Menu>
                <Dropdown.Header>Scheduled Status</Dropdown.Header>
                <DropdownItemWithCheck label='Scheduled' active={sortByScheduled} onClick={() => {setSortByScheduled(!sortByScheduled); setSortByUnscheduled(false)}}/>
                <DropdownItemWithCheck label='Unscheduled' active={sortByUnscheduled} onClick={() => {setSortByUnscheduled(!sortByUnscheduled); setSortByScheduled(false)}}/>
                <Dropdown.Divider/>
                <Dropdown.Header>Timeclock Status</Dropdown.Header>
                <DropdownItemWithCheck label='Clocked-In' active={sortByClockedIn} onClick={() => {setSortByClockedIn(!sortByClockedIn); setSortByClockedOut(false)}}/>
                <DropdownItemWithCheck label='Clocked-Out' active={sortByClockedOut} onClick={() => {setSortByClockedOut(!sortByClockedOut); setSortByClockedIn(false)}} />
            </Dropdown.Menu>
        </Dropdown>
    )

    const toolsDropdownMenu = (
        <Dropdown>
            <Dropdown.Toggle variant="outline-primary">
                <FontAwesomeIcon icon={faBars}/>
            </Dropdown.Toggle>
            <Dropdown.Menu>
                <Dropdown.Header>Hire</Dropdown.Header>
                <Dropdown.Item onClick={() => setModalSwitch('hire')}>Hire Employee</Dropdown.Item>
                <Dropdown.Item onClick={() => setModalSwitch('csvHire')}>Upload Onboarding CSV</Dropdown.Item>
            </Dropdown.Menu>
        </Dropdown>
    );

    return (
        <div style={{height: '100%', width: '100%'}}>
            <div style={{display: 'flex', flexDirection: 'column', height: '100%', gap: 12}}>
                <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexWrap: 'wrap-reverse', gap: 6}}>
                    <Button variant={'outline-primary'}  onClick={() => setModalSwitch('selectDate')}>{`Viewing Schedules and Timesheets | ` + (moment(selectedDate).diff(moment(),'days') == 0 ? 'Today' : moment(selectedDate).format('MMM D, YYYY'))}</Button>  
                    <div style={{flex: 1}}></div>  
                    <div style={{display: 'flex', gap: 12, marginLeft: 'auto'}}>
                        {checkPermission('hrProfile') && auth.companyUserIdentifier && toolsDropdownMenu}
                    </div>
                </div>
                <p style={{margin: 0, textAlign: 'center'}}>Total Hours Worked This Week: <strong>{totalWeeklyHoursWorked.toFixed(2)}</strong></p>
                <div style={{display: 'flex', alignItems: 'center', gap: 12, flexWrap: 'wrap-reverse'}}>
                    <div style={{minWidth: 260, flex: 1}}>
                        <SearchBar label='Search' value={search} setValue={setSearch}/>
                    </div>
                    <InputGroup style={{width: 'fit-content', flexShrink: 0, marginLeft: 'auto'}}>
                        {filterDropdownMenu}
                        {sortByDropDownMenu}
                    </InputGroup>
                </div>
                <div className='desktop-block' style={{overflowY: 'auto', flex: 1}}>
                    <QuickTable headers={['Name', 'Terminal', 'Title', 'Hours Worked', 'Schedule', 'Punches']} widths={[null, 1, 1, 1, 1, 1]} rows={userElements} size='sm' noWrap/>
                </div>
                <div className='mobile-block' style={{overflowY: 'auto'}}>
                    <ListGroup>
                        {mobileUserElements}
                    </ListGroup>
                </div>
            </div>
            <Modal backdrop='static' show={modalSwitch === 'hire'} size='xl' fullscreen='xl-down'>
                <HRHire terminals={terminals} hideModal={() => setModalSwitch('')}/>
            </Modal>
            <Modal show={modalSwitch === 'csvHire'} onHide={() => setModalSwitch('')} fullscreen>
                <HRHireCSV terminals={terminals}/>
            </Modal>
            <Modal show={modalSwitch === 'selectDate'} onHide={() => setModalSwitch('')}>
                <HRHubDateSelectionModal selectedDate={selectedDate} setSelectedDate={setSelectedDate}/>
            </Modal>
        </div>
    )
}