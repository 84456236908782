/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, { useState } from 'react';
import Form from 'react-bootstrap/Form';
import OptionalCol from '../../../../components/OptionalCol';
import { encodeInteger } from '../../../../tools';
import { FloatingLabel, InputGroup } from 'react-bootstrap';

export default function PayrollIntegerControl({title, value, setValue, max = 0, breakpoints, validate, validationMessage, ...props}) {
    const [isFocused, setIsFocused] = useState(false);
    const [localValue, setLocalValue] = useState(value);

    const validateValue = () => {
        let newValue = encodeInteger(localValue, 0, max).toString();
        setValue(newValue);
        setLocalValue(newValue);
    }

    const isInvalid = validate ? validationMessage != undefined : undefined;
    const isValid = validate ? validationMessage == undefined : undefined;

    const control = (
        <Form.Control type={'number'} style={{lineHeight: 1}} onKeyDown={(e) => {if (e.key == 'Enter') {validateValue()}}} isInvalid={isInvalid} isValid={isValid} placeholder=' ' value={isFocused ? localValue : value} onFocus={() => {setIsFocused(true)}} onBlur={() => {setIsFocused(false); validateValue();}} onChange={(e) => {setLocalValue(e.target.value)}} {...props}/>
    )

    return (
        <OptionalCol breakpoints={breakpoints}>
            { title ? <FloatingLabel label={title}>{control}</FloatingLabel> : control}
        </OptionalCol>
    )
}