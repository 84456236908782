/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import { toFixedMax, usdFormatter, validateDecimal } from "../../../../tools";
import { bigToUsd, validateUsd } from "../payrollTools";

export default function PayrollPeriodEntryPayRow(pay, qualifiesForFLSA) {
    const row = {};

    if (pay.payType === 'py') {
        row['Salary'] = usdFormatter.format(validateUsd(pay.payRate));
        row['Regular Wages'] = bigToUsd(pay.getWages(qualifiesForFLSA));
    } else if (pay.payType === 'pd') {
        row['Daily Rate'] = usdFormatter.format(validateUsd(pay.payRate));
        row['Days Worked'] = validateDecimal(pay.unitsWorked);
        row['Regular Wages'] = bigToUsd(pay.getWages(qualifiesForFLSA));
    } else if (pay.payType === 'ph') {
        row['Hourly Rate'] = usdFormatter.format(validateUsd(pay.payRate));
        row['Hours Worked'] = toFixedMax(validateDecimal(pay.unitsWorked), 3);
        row['Hourly Wages'] = bigToUsd(pay.getWages(qualifiesForFLSA));
    } else if (pay.payType === 'pm') {
        row['Rate Per Mile'] = usdFormatter.format(validateUsd(pay.payRate));
        row['Miles'] = toFixedMax(validateDecimal(pay.unitsWorked), 3);
        row['Mile Wages'] = bigToUsd(pay.getWages(qualifiesForFLSA));
    } else if (pay.payType === 'xd') {
        row['Extra Day Rate'] = usdFormatter.format(validateUsd(pay.payRate));
        row['Extra Days'] = validateDecimal(pay.unitsWorked);
        row['Extra Day Wages'] = bigToUsd(pay.getWages(qualifiesForFLSA));
    }
    
    return row;
}