/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React from "react";
import InputGroup from 'react-bootstrap/InputGroup';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { usdFormatter } from "../../../../../../tools";
import PayrollMoneyControl from "../../PayrollMoneyControl";
import PayrollEntryPaySection from "../Pay/PayrollEntryPaySection";
import { Container } from "react-bootstrap";
import PayrollEmployeeLoansEditor from "./Loans/PayrollEmployeeLoansEditor";
import PayrollAdditionalDeductionsEditor from "./AdditionalDeductions/PayrollAdditionalDeductionsEditor";
import QuickTable from "../../../../../../components/QuickTable";

export default function PayrollEmployeeDeductionsEditor({entry, handleSet, payrollPeriod, handleAddItemToAllEntries}) {

    const addChildSupport = () => {
        const newArr = Array.from(entry.childSupport);
        newArr.push('0');
        handleSet(entry.companyUserIdentifier, 'childSupport', newArr);
    }
    const handleSetChildSupport = (value, index) => {
        const newArr = Array.from(entry.childSupport);
        newArr[index] = value;
        handleSet(entry.companyUserIdentifier, 'childSupport', newArr);
    }
    const removeChildSupport = (index) => {
        const newArr = [...entry.childSupport]; 
        newArr.splice(index, 1);
        handleSet(entry.companyUserIdentifier, 'childSupport', newArr);
    };

    const childSupportRows = entry.childSupport.map((value, index) => {
        return (
            <tr key={index}>
                <td>
                    <PayrollMoneyControl lhDecimalPlaces={4} value={value} setValue={(newValue) => {handleSetChildSupport(newValue, index)}}/>
                </td>
                <td>
                    <Button variant="outline-danger" style={{padding: '3px 8px'}} onClick={() => removeChildSupport(index)}>
                        <FontAwesomeIcon icon={faTrashCan}/>
                    </Button>
                </td>
            </tr>
        )
    });

    return (
        <div style={{padding: 6, backgroundColor: 'rgb(255, 250, 250)'}}>
            <span style={{fontSize: 25, fontWeight: 'bold'}}>Deductions</span>
            <Container fluid>
                <Row>
                    <PayrollMoneyControl lhDecimalPlaces={4} breakpoints={{md: 4}} title='Medical Insurance' value={entry.medical} setValue={(value) => {handleSet(entry.companyUserIdentifier, 'medical', value)}}/>
                    <PayrollMoneyControl lhDecimalPlaces={4} breakpoints={{md: 4}} title='Dental Insurance' value={entry.dental} setValue={(value) => {handleSet(entry.companyUserIdentifier, 'dental', value)}}/>
                    <PayrollMoneyControl lhDecimalPlaces={4} breakpoints={{md: 4}} title='Vision Insurance' value={entry.vision} setValue={(value) => {handleSet(entry.companyUserIdentifier, 'vision', value)}}/>
                </Row>
            </Container>
            <PayrollEntryPaySection title='CHILD SUPPORT' showModal={addChildSupport}>
                { entry.childSupport.length > 0 && 
                    <div style={{display: 'flex', flexDirection: 'column', gap: 6}}>
                        <div style={{overflowX: 'auto'}}>
                            <QuickTable noMargin noWrap size='sm' rows={childSupportRows} headers={['Amount', '']} widths={[null, 1]}/>
                        </div>
                        <div style={{display: 'flex', justifyContent: 'flex-end', fontWeight: 'bold', gap: 6}}>
                            <span>Total:</span>
                            <span style={{color: 'red'}}>{usdFormatter.format(entry.totalChildSupport())}</span>
                        </div>
                    </div>
                }
            </PayrollEntryPaySection>
            <PayrollAdditionalDeductionsEditor entry={entry} handleSet={handleSet} handleAddItemToAllEntries={handleAddItemToAllEntries}/>
            <PayrollEmployeeLoansEditor entry={entry} handleSet={handleSet} payrollPeriod={payrollPeriod}/>
        </div>
    )
}

