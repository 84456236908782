/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faChevronLeft} from '@fortawesome/free-solid-svg-icons';
import React, { useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';
import { Button, Modal } from 'react-bootstrap';
import LoadingWrapper from '../../../components/LoadingWrapper';
import { ApiRequest } from '../../../ApiManager.tsx';
import MyScheduleCalendar from './MyScheduleCalendar.js';
import RequestOffModal from './RequestOffModal.js';

export default function MySchedule({}) {
    const [isLoading, setIsLoading] = useState(false);
    const [routes, setRoutes] = useState([]);
    const [timeOff, setTimeOff] = useState([]);
    const [routeEndDate, setRouteEndDate] = useState(moment().format('YYYY-MM-DD'));

    const [startOfMonth, setStartOfMonth] = useState(moment().startOf('month').format('YYYY-MM-DD'));
    const calendarStartDate = moment(startOfMonth).subtract(moment(startOfMonth).day(), 'days').format('YYYY-MM-DD');

    const [showModal, setShowModal] = useState(false);
    
    useEffect(() => {
        loadData();
    }, [startOfMonth])

    function loadData() {
        new ApiRequest('mySchedule', 'get', setIsLoading, (response) => {
            setRoutes(response.routes);
            setTimeOff(response.timeOff);
            setRouteEndDate(response.routeEndDate);
        }).withNoAlertOnSuccess().withData({
            startDate: calendarStartDate,
            endDate: moment(calendarStartDate).add(5, 'weeks').add(6, 'days').format('YYYY-MM-DD')
        }).send();
    }

    function handleAddRequestOff(requestOff) {
        const newArray = Array.from(timeOff);
        newArray.push(requestOff);
        setTimeOff(newArray);
        setShowModal(false);
    }
    
    function handleRemoveRequestOff(uid){
        const newArray = Array.from(timeOff.filter(item => item.uid !== uid));
        setTimeOff(newArray);
    }
    
    return (
        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', height: '100%', width: '100%', padding: 12, gap: 12}}>
            <Button variant='outline-primary' style={{alignSelf: 'flex-end'}} onClick={() => {setShowModal(true)}}>Request Off</Button>
            <div style={{display: 'flex', flexDirection: 'row', gap: 12}}>
                <Button variant={'outline-primary'} style={{fontSize: '3vh', padding: '2px 8px 2px 8px', display: 'flex', justifyContent: 'center', alignItems: 'center'}} onClick={() => {setStartOfMonth(moment(startOfMonth).subtract(1, 'month'))}}>
                    <FontAwesomeIcon icon={faChevronLeft}/>
                </Button>
                <Form.Control type='month' value={moment(startOfMonth).format('YYYY-MM')} onChange={(event) => {setStartOfMonth(moment(event.target.value ? event.target.value : undefined).startOf('month').format('YYYY-MM-DD'))}}/>
                <Button variant={'outline-primary'} style={{fontSize: '3vh', padding: '2px 8px 2px 8px', display: 'flex', justifyContent: 'center', alignItems: 'center'}} onClick={() => {setStartOfMonth(moment(startOfMonth).add(1, 'month'))}}>
                    <FontAwesomeIcon icon={faChevronRight}/>
                </Button>
            </div>
            <LoadingWrapper isLoading={isLoading}>
                <div style={{width: '100%', flex: 1, display: 'flex', flexDirection: 'column'}}>
                    <MyScheduleCalendar routes={routes} timeOff={timeOff} calendarStartDate={calendarStartDate} startOfMonth={startOfMonth} handleRemoveRequestOff={handleRemoveRequestOff}/>
                </div>
                <span style={{textAlign: 'center', fontWeight: 'bold', opacity: 0.65, fontSize: 14}}>{`*Only displaying routes on or before ${moment(routeEndDate).format("MMM D, YYYY")}`}</span>
            </LoadingWrapper>
            <Modal show={showModal} onHide={() => {setShowModal(false)}}>
                <RequestOffModal handleAddRequestOff={handleAddRequestOff}/>
            </Modal>
        </div>
    );
    
}