/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import { useState } from "react";
import { ListGroup, Modal } from "react-bootstrap";
import { ApiRequest } from "../../../../ApiManager.tsx";
import AlertConfirmation from "../../../../components/AlertModals/AlertConfirmation";
import CustomButton from "../../../../components/CustomButton.js";
import KeyValueRow from "../../../../components/KeyValueRow.js";
import moment from "moment";
import CustomTextArea from "../../../../components/CustomStateControls/CustomTextArea.js";

export default function HRUserScheduleAbsenceModal({absence, handleScheduleItemsCrud}){
    const [isUpdating, setIsUpdating] = useState(false)
    const [isDeleting, setIsDeleting] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [notes, setNotes] = useState(absence.notes ?? '');

    function deleteAbsence(){
        new ApiRequest('hr', 'deleteAbsence', setIsDeleting, () => {
            handleScheduleItemsCrud('absence', 'delete', absence.uid)
        }).withUid(absence.uid).send()
    }

    function updateAbsence(){
        new ApiRequest('hr', 'updateAbsence', setIsUpdating, () => {
            const newAbsence = structuredClone(absence);
            newAbsence.notes = notes;
            handleScheduleItemsCrud('absence', 'update', newAbsence.uid, newAbsence)
        }).withData({uid: absence.uid, notes: notes}).send()
    }

    const type = absence?.notifiedManager ? 'Call Off' : 'No Show';
    return (
        <>
            <Modal.Header closeButton>
                <Modal.Title>Viewing {absence?.notifiedManager ? 'Call Off' : 'No Show'}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <ListGroup>
                        <KeyValueRow isListItem title={'Date'} value={moment(absence?.date).format('MMM D, YYYY')}/>
                </ListGroup>
                <div style={{padding: 12}}>
                    <CustomTextArea placeholder={'Notes'} value={notes} setValue={setNotes} rows={3} max={200}/>
                    <CustomButton isLoading={isUpdating} onClick={updateAbsence} style={{display: 'block', marginLeft: 'auto', marginTop: 12}}>Update Notes</CustomButton> 
                </div>
            </Modal.Body>
            <Modal.Footer>
                <CustomButton onClick={()=> setShowDeleteModal(true)} variant='outline-danger'>Delete {absence?.notifiedManager ? 'Call Off' : 'No Show'}</CustomButton>
            </Modal.Footer>
            <Modal centered show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
                <AlertConfirmation callBack={deleteAbsence} isLoading={isDeleting} variant='outline-danger' title={`Delete ${type}`} buttonLabel={'Delete'} message={`Are you sure you want to delete this ${type}?`}/>
            </Modal>
        </>
    )
}