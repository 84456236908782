/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React from 'react';
import { faUser } from '@fortawesome/free-regular-svg-icons';
import { faRightFromBracket } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import logo from '../../assets/TTA-Logo-150.png';
import CompanySelector from '../CompanySelector';
import { Dropdown } from 'react-bootstrap';
import NavigationBarDropdownItem from './NavigationBarDropdownItem';

export default function NavigationBarMedium({auth, userData, companies, setShowLogoutModal, handleStopShadowing, routes}) {


    const companySections = ['ISP Management', 'Operations', 'Finance'].filter(c => routes.some(r => r.category == c)).map((category) => {
        const buttons = routes.filter(r => r.category == category).map((route) => {
            
            return (
                <NavigationBarDropdownItem key={route.name} route={route}/>
            )
        })
        return (
            <React.Fragment key={category}>
                <Dropdown>
                    <Dropdown.Toggle variant='outline-primary' style={{border: 'none', boxShadow: 'none'}}>{category}</Dropdown.Toggle>
                    <Dropdown.Menu>
                        {buttons}
                    </Dropdown.Menu>
                </Dropdown>
            </React.Fragment>
        )
    })

    const accountButtons = routes.filter(r => r.category == 'Account').map((route) => {
        return (
            <NavigationBarDropdownItem key={route.name} route={route}/>
        )
    })

    return (
        <div className='navbar-top navbar-top-medium'>
            <img src={logo} alt={'TTA LOGO'} id='logo' style={{height: '100%', width: 'auto', margin: 0}}/>
            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', gap: 24}}>
                {companySections}
                <Dropdown>
                    <Dropdown.Toggle variant='outline-primary'>
                        <FontAwesomeIcon icon={faUser} style={{marginRight: 4}}/>
                        <span>{`${userData.firstName} ${userData.lastName}`}</span>
                    </Dropdown.Toggle>
                    <Dropdown.Menu style={{whiteSpace: 'nowrap'}}>
                        <Dropdown.ItemText style={{color: 'var(--bs-primary)', textAlign: 'center', fontSize: 15, fontWeight: 'bold'}}>{`${userData.firstName} ${userData.lastName}`}</Dropdown.ItemText>
                        { auth.companyUserIdentifier && 
                            <Dropdown.ItemText style={{fontSize: 12, display: 'flex', alignItems: 'center', gap: 4, color: 'var(--bs-primary)'}}>
                                <CompanySelector companies={companies}/>
                            </Dropdown.ItemText>
                        }
                        <Dropdown.Divider/>
                        { accountButtons.length > 0 && 
                            <>
                                {accountButtons}
                                <Dropdown.Divider/>
                            </>
                        }
                        <Dropdown.Item onClick={()=>{setShowLogoutModal(true)}} style={{display: 'flex', alignItems: 'center', gap: 6, color: 'red'}}>
                            <span>Logout</span>
                            <FontAwesomeIcon icon={faRightFromBracket}/>
                        </Dropdown.Item>
                        { auth.adminIdentifier && auth.companyUserIdentifier &&
                            <Dropdown.Item onClick={handleStopShadowing} style={{display: 'flex', alignItems: 'center', gap: 6, color: 'red'}}>
                                <span>Stop Shadowing</span>
                                <FontAwesomeIcon icon={faRightFromBracket}/>
                            </Dropdown.Item>
                        }
                    </Dropdown.Menu>
                </Dropdown>
            </div>
        </div>
    )
}