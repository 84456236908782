/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, { useEffect, useState } from "react";
import TimesheetCalendar from "./TimesheetCalendar";
import moment from "moment";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight, faPlay, faStop } from '@fortawesome/free-solid-svg-icons';
import Spinner from 'react-bootstrap/Spinner';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import TimeClockTicker from "./TimeClockTicker";
import TimeClockQuestionnaire from "./TimeClockQuestionnaire";
import { ApiRequest } from "../../../ApiManager.tsx";
import LoadingWrapper from "../../../components/LoadingWrapper";
import { Card, Col, Container, Row } from "react-bootstrap";
import StateObject from "../../../state/StateObject.tsx";
import Incident from "./Incident.js";

export default function TimeClock({}){
    const [lastTimesheet, setLastTimesheet] = useState(undefined);
    const [entries, setEntries] = useState([],);
    const [isLoading, setIsLoading] = useState(true);
    const [isPunchLoading, setIsPunchLoading] = useState(false);
    const [buttonIsDisabled, setButtonIsDisabled] = useState(false);
    const [modalSwitch, setModalSwitch] = useState('none');
    
    const [startOfMonth, setStartOfMonth] = useState(moment().startOf('month').format('YYYY-MM-DD'));

    const calendarStartDate = moment(startOfMonth).subtract(moment(startOfMonth).day(), 'days').format('YYYY-MM-DD');

    const [latitude, setLatitude] = useState(undefined);
    const [longitude, setLongitude] = useState(undefined);

    const [incidentObj, setIncidentObj] = useState(() => new StateObject({incidents: [new Incident("Incident"), new Incident("Accident"), new Incident("Injury")]}));

    const [showMinutes, setShowMinutes] = useState(false);


    useEffect(() => {
        navigator.geolocation.getCurrentPosition((position) => {
            setLatitude(position.coords.latitude);
            setLongitude(position.coords.longitude);
        }, (error) => {}, {timeout: 10000});
    }, [])

    useEffect(() => {
        loadData();
    }, [startOfMonth])

    function loadData() {
        new ApiRequest('timeclock', 'getTimesheets', setIsLoading, (response) => {
            setLastTimesheet(response.lastTimesheet);
            setEntries(response.timesheets);
        }).withData({
            startDate: calendarStartDate,
            endDate: moment(calendarStartDate).add(5, 'weeks').add(6, 'days').format('YYYY-MM-DD')
        }).withNoAlertOnSuccess().send();
    }

    function handlePunch() {
        setButtonIsDisabled(true);
        setTimeout(() => {
            setButtonIsDisabled(false);
        }, 2000)


        const punchToSend = {
            latitude: latitude,
            longitude: longitude,
            incidents: incidentObj.incidents.filter(i => i.didOccur).map(i => i.encode())
        }

        new ApiRequest('timeclock', 'punch', setIsPunchLoading, (response) => {
            let newEntries = Array.from(entries);
            if (response.uid) {
                const newPunch = {
                    uid: response.uid,
                    inTime: moment().format('YYYY-MM-DD HH:mm:ss'),
                    inLat: latitude,
                    inLong: longitude,
                }
                newEntries.unshift(newPunch);
                setLastTimesheet(newPunch);
            } else {
                newEntries = newEntries.filter(e => e.uid != lastTimesheet.uid);
                const newLastTimesheetEntry = structuredClone(lastTimesheet);
                newLastTimesheetEntry.outTime = moment().format('YYYY-MM-DD HH:mm:ss');
                newLastTimesheetEntry.outLat = latitude;
                newLastTimesheetEntry.outLong = longitude;
                newEntries.unshift(newLastTimesheetEntry);
                setLastTimesheet(newLastTimesheetEntry);
            }
            setEntries(newEntries);
        }).withTimezone().withData(punchToSend).send();
        
    }

    function handleButtonPress() {
        if (!isPunchLoading) {
            if (lastTimesheet && !lastTimesheet.outTime) {
                setModalSwitch('driverClockOut')
            } else {
                handlePunch();
            }
        }
    }

    function handleQuestionnaireSubmission(hadIncident, hadAccident, hadInjury, incidentStatement, accidentStatement, injuryStatement) {
        hideModal();
        handlePunch(hadIncident, hadAccident, hadInjury, incidentStatement, accidentStatement, injuryStatement);
    }

    function hideModal() {
        setModalSwitch('none')
    }

    const totalHoursForToday = entries.filter(e => moment(e.inTime).isSame(moment(), 'day')).reduce((prev, curr) => {
        return prev + (curr.outTime ? moment(curr.outTime).diff(moment(curr.inTime), 'hours', true) : moment().diff(curr.inTime, 'hours', true))
    }, 0)
    const totalHoursAndMinutesForToday = `${parseInt(totalHoursForToday)} hours, ${parseInt((totalHoursForToday - parseInt(totalHoursForToday)) * 60)} minutes`

    const totalHoursForTheWeek = entries.filter(e => moment(e.inTime).isSame(moment(), 'week')).reduce((prev, curr) => {
        return prev + (curr.outTime ? moment(curr.outTime).diff(moment(curr.inTime), 'hours', true) : moment().diff(curr.inTime, 'hours', true))
    }, 0)

    const totalHoursAndMinutesForTheWeek = `${parseInt(totalHoursForTheWeek)} hours, ${parseInt((totalHoursForTheWeek - parseInt(totalHoursForTheWeek)) * 60)} minutes`

    const isClockedIn = lastTimesheet && !lastTimesheet.outTime; 
    const PunchIOCard = (

        <Container fluid style={{flex: 1, width:'100%', padding: 0}}>
            <Row style={{height: '100%', }}>
                <Col lg={3} style={{display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: 4}}>
                    <Card style={{width: '100%', height: '100%'}}>
                        <Card.Body className="desktop-flex" style={{flexWrap: 'wrap', justifyContent: 'space-evenly', alignItems: 'center'}}>
                            <Button
                                disabled={isLoading || buttonIsDisabled}
                                onClick={handleButtonPress}
                                style={{
                                    height: 200,
                                    width: 200,
                                    borderRadius: '50%',
                                    backgroundColor: isClockedIn ? '#ff8a8a' : '#a7ff8a',
                                    border: 'none',
                                    color: isClockedIn ? 'white' : 'black',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    gap: 0,
                                    padding: '5%'
                            }}>
                                {isPunchLoading ? (
                                    <Spinner />
                                ) : (
                                    <>
                                        <span style={{ margin: 0, textAlign: 'center', fontSize: 25}}>{isClockedIn ? 'Clock Out' : 'Clock In'}</span>
                                        <FontAwesomeIcon
                                            icon={isClockedIn ? faStop : faPlay}
                                            style={{fontSize: 50}}
                                        />
                                        <TimeClockTicker fontSize={20}/>
                                    </>
                                )}
                            </Button>
                            
                        </Card.Body>
                        <Card.Body className="mobile-flex" style={{flexWrap: 'wrap', justifyContent: 'space-evenly', alignItems: 'center', padding: 6}}>
                            <Button
                                disabled={isLoading || buttonIsDisabled}
                                onClick={handleButtonPress}
                                style={{
                                    height: 160,
                                    width: 160,
                                    borderRadius: '50%',
                                    backgroundColor: isClockedIn ? '#ff8a8a' : '#a7ff8a',
                                    border: 'none',
                                    color: isClockedIn ? 'white' : 'black',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    gap: 0,
                                    padding: '5%'
                            }}>
                                {isPunchLoading ? (
                                    <Spinner />
                                ) : (
                                    <>
                                        <span style={{ margin: 0, textAlign: 'center', fontSize: 16, whiteSpace: 'nowrap'}}>{isClockedIn ? 'Clock Out' : 'Clock In'}</span>
                                        <FontAwesomeIcon
                                            icon={isClockedIn ? faStop : faPlay}
                                            style={{fontSize: 30}}
                                        />
                                        <TimeClockTicker fontSize={14}/>
                                    </>
                                )}
                            </Button>
                            
                        </Card.Body>
                    </Card>
                </Col>
                <Col lg={9} style={{display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: 4}}>
                    <Card style={{width: '100%', height: '100%'}}>
                        <Card.Body style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly', flexWrap: 'wrap', fontSize: '2.5vmin'}}>
                            <div style={{display: 'flex', justifyContent: 'flex-end', marginBottom: 4}}>
                                <Button variant="outline-primary" onClick={() => {setShowMinutes(!showMinutes)}} style={{padding: '0px 6px 0px 6px', fontSize: '2.5vmin'}}>
                                    {showMinutes ? 'Displaying Hours & Minutes' : 'Displaying Precise Hours'}
                                </Button>      
                            </div>
                            <div style={{textAlign:'center', display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                                <span>Last Punch</span>
                                { lastTimesheet &&                         
                                    <span>{(isClockedIn ? 'In on ' : 'Out on ') + moment(isClockedIn ? lastTimesheet.inTime : lastTimesheet.outTime).format('MMM D, YYYY [at] h:mm A')}</span>
                                }
                            </div>
                            <div style={{textAlign:'center', display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                                <span>Hours Worked Today</span>
                                <span>
                                    {showMinutes ? totalHoursAndMinutesForToday : `${totalHoursForToday.toFixed(2)} hours`}
                                </span>
                            </div>
                            <div style={{textAlign:'center', display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                                <span>Hours Worked this Week</span>
                                <span>
                                    {showMinutes ? totalHoursAndMinutesForTheWeek : `${totalHoursForTheWeek.toFixed(2)} hours`}
                                </span>
                            </div>
                            
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    );

    const content = (
        <div style={{width: '100%', height: '100%', display: 'flex', flexDirection: 'column', padding: 12, gap: 0}}>
            {PunchIOCard}
            <Card style={{flex: 3}}>
                <Card.Body style={{height: '100%', padding: 12, display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 6}}>
                    <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', fontSize: '3vh'}}>
                        <Button variant={'outline-primary'} style={{fontSize: '3vh', padding: '2px 8px 2px 8px', display: 'flex', justifyContent: 'center', alignItems: 'center'}} onClick={() => {setStartOfMonth(moment(startOfMonth).subtract(1, 'month'))}}>
                            <FontAwesomeIcon icon={faChevronLeft}/>
                        </Button>
                        <span style={{marginRight: 8, marginLeft: 8, opacity: 0.7}}>{moment(startOfMonth).format('MMM YYYY')}</span>
                        <Button variant={'outline-primary'} style={{fontSize: '3vh', padding: '2px 8px 2px 8px', display: 'flex', justifyContent: 'center', alignItems: 'center'}} onClick={() => {setStartOfMonth(moment(startOfMonth).add(1, 'month'))}}>
                            <FontAwesomeIcon icon={faChevronRight}/>
                        </Button>
                    </div>
                    <TimesheetCalendar timesheetData={entries} startOfMonth={startOfMonth} calendarStartDate={calendarStartDate} showMinutes={showMinutes}/>
                </Card.Body>
            </Card>
        </div>
    );

    return (
        <div className="page-wrapper">
            <LoadingWrapper isLoading={isLoading}>
                {content}
            </LoadingWrapper>
            <Modal show={modalSwitch === 'driverClockOut'} onHide={hideModal} centered>
                <TimeClockQuestionnaire incidentObj={incidentObj} handleQuestionnaireSubmission={handleQuestionnaireSubmission}/>
            </Modal>
        </div>
    )
}