/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import PayrollPeriodHeadersRow from "./PayrollPeriodHeadersRow";
import PayrollPeriodTotalsRow from "./PayrollPeriodTotalsRow";
import PayrollPeriodEntryRow from "./PayrollPeriodEntryRow";
import { formatDateRange } from "../../../../tools";

export default function PayrollPeriodCsv(entries, payrollPeriod) {

    const rows = [];
    rows.push(['', "\"" + payrollPeriod.companyName + "\"", "\"" + formatDateRange(payrollPeriod.periodStart, payrollPeriod.periodEnd) + "\""]);
    rows.push([]);

    const tableHeaders = PayrollPeriodHeadersRow(entries);

    tableHeaders.unshift('');
    tableHeaders.unshift('');
    rows.push(tableHeaders);

    const terminals = [];
    entries.forEach((e) => {
        if (!terminals.includes(e.terminalName)) {
            terminals.push(e.terminalName);
        }
    });

    terminals.forEach((terminal) => {
        rows.push(['', terminal]);
        const entriesInTerminal = entries.filter(e => e.terminalName === terminal);

        entriesInTerminal.forEach((entry) => {
            PayrollPeriodEntryRow(entry).forEach((entryPay, index) => {
                const entryRow = tableHeaders.map((key) => `\"${entryPay[key] ?? ''}\"`);
                if (index === 0 && entry.isNew) {
                    entryRow[1] = 'NEW';
                }
                rows.push(entryRow);
            })
        })

        const csaTotals = PayrollPeriodTotalsRow(entriesInTerminal, tableHeaders);

        const terminalTotalsRow = tableHeaders.map((key) => `\"${csaTotals[key] ?? ''}\"`);
        terminalTotalsRow[1] = 'Total for Terminal';
        rows.push(terminalTotalsRow);
    })

    rows.push([]);
    
    const totals = PayrollPeriodTotalsRow(entries, tableHeaders);
    const totalsRow = tableHeaders.map((key) => `\"${totals[key] ?? ''}\"`);
    totals[0] = 'Totals';
    rows.push(totalsRow);

    const stringRows = rows.map(r => r.join(','));
    const fullString = stringRows.join('\r');
  
    return fullString;
}