/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import './App.css';
import React, { useEffect, useRef, useState } from 'react';
import NavigationBar from './components/NavigationBar/NavigationBar';
import Button from 'react-bootstrap/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretLeft, faCaretRight } from '@fortawesome/free-solid-svg-icons';
import { CSSTransition } from 'react-transition-group';
import { Modal } from 'react-bootstrap';
import AcknowledgementModal from './views/AcknowledgementModal';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import NotificationModal from './views/NotificationModal';
import { ToastContainer } from 'react-toastify';
import ApiManager, { ApiRequest } from './ApiManager.tsx';
import Cookies from 'universal-cookie';
import LoadingWrapper from './components/LoadingWrapper.js';
import ApiTimeoutModal from './views/ApiTimeoutModal.js';
import 'react-toastify/dist/ReactToastify.css';
import getRoutes from './routes.js';

export default function App() {
    const contentRef = useRef();
    const [isLoading, setIsLoading] = useState(true);
    const [auth, setAuth] = useState(null);
    const [showTimeout, setShowTimeout] = useState(false);
    const [showOffCanvas, setShowOffCanvas] = useState(true);
    const [showDocumentModal, setShowDocumentModal] = useState(false);
    const [showNotification, setShowNotification] = useState(false);
    const [initializedHelloMedian, setInitializedHelloMedian] = useState(false);
    const [companies, setCompanies] = useState([]);
    const [documents, setDocuments] = useState([]);
    const [notifications, setNotifications] = useState([]);
    const [permissions, setPermissions] = useState([]);

    useEffect(() => {
        ApiManager.initGlobalInstance(setAuth, setShowTimeout, handleCheckAuthentication);
        handleCheckAuthentication();
    }, [])

    const handleCheckAuthentication = () => {
        new ApiRequest('authentication', 'checkAuthentication', setIsLoading, (response) => {
            const cookies = new Cookies();
            cookies.set('auth', response.auth);
            cookies.set('userData', response.userData);
            setPermissions(response.permissions);
            cookies.set('permissions', response.permissions);
            if (response.auth.companyUserIdentifier) {
                cookies.set('restrictWhoSeesPay', response.restrictWhoSeesPay);
                cookies.set('companyName', response.companies.find(c => c.uid == response.userData.companyIdentifier)?.name ?? '');
                setCompanies(response.companies);
                if (response.documents?.length > 0) {
                    setShowDocumentModal(true);
                    setDocuments(response.documents);
                }
            }
            if (!initializedHelloMedian) {
                initializeHelloMedian(response.userData?.firstName ?? 'UNKNOWN', response.userData?.lastName ?? '', !!response.auth.adminIdentifier);
                setInitializedHelloMedian(true);
            }
            setAuth(response.auth);
        }).withNoAlertOnSuccess().send();
    }


    function initializeHelloMedian(firstName, lastName, isAdmin) {
        const text = `Linehaul - ${firstName} ${lastName} ${isAdmin ? ' (ADMIN)' : ''}`;

        const s = document.createElement('script');
        s.type = 'text/javascript';
        s.async = true;
        s.innerHTML = `! function () {
            var e = window.Median = window.Median || [];
            if (!e._initialized)
              if (e._snippet_loaded) console.warn("Median Snippet loaded twice.");
              else {
                e._snippet_loaded = !0, e._snippet_version = 3, e.methods = ["init", "identify", "endSession",
                  "on"], e.factory = function (n) {
                    return function () {
                      var t = Array.prototype.slice.call(arguments);
                      e.push([n, t])
                    }
                  };
                for (var n = 0; n < e.methods.length; n++) {
                  var t = e.methods[n];
                  e[t] = e.factory(t)
                }
                var i = document.createElement("script");
                i.type = "text/javascript", i.async = !0, i.src =
                "https://js.hellomedian.com/v1/mdn-cobrowse-screenshare.js";
                var a = document.getElementsByTagName("script")[0];
                a.parentNode.insertBefore(i, a)
              }
          }();
          Median.init("f2a996e2-70ec-4c5c-90c0-944c7f5abb6b");
          Median.identify('${text}');`;
        document.body.appendChild(s);
    }

    const routes = getRoutes(auth, permissions, handleCheckAuthentication);

    const routeElements = routes.map((route) => {
        return (
            <Route key={route.name} path={route.path} component={route.component}/>
        )
    });

    return (
        <div className='App'>
            <LoadingWrapper isLoading={isLoading}>
                <BrowserRouter>
                    {(auth?.companyUserIdentifier || auth?.adminIdentifier) &&
                        <NavigationBar routes={routes} auth={auth} companies={companies} handleCheckAuthentication={handleCheckAuthentication}/>
                    }

                    <Switch>{routeElements}</Switch>
                    {/* <Router auth={auth} permissions={permissions} handleCheckAuthentication={handleCheckAuthentication}/> */}
                </BrowserRouter>
            </LoadingWrapper>
            <Modal show={showDocumentModal} onHide={() => {setShowDocumentModal(false)}} fullscreen backdrop='static'>
                <AcknowledgementModal documents={documents} hideModal={() => {setShowDocumentModal(false)}}/>
            </Modal>
            <ToastContainer toastStyle={{fontSize: 14}} autoClose={2500}/>
        </div>
    )
}