/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, { useState } from 'react';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Dropdown from 'react-bootstrap/Dropdown';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import OptionalCol from './OptionalCol';

export default function Picker({selection, setSelection, itemNames, itemValues, title, validate, validationMessage, breakpoints, style}) {
    const [fieldIsFocused, setFieldIsFocused] = useState(false);

    const getActiveName = () => {
        for (let i = 0; i < itemValues.length; i++) {
            if (itemValues[i] == selection) {
                return itemNames[i];
            }
        }
        return '';
    }
    
    const dropdownItems = itemValues.map((value, index) => {
        return (
            <Dropdown.Item key={value} active={selection == itemValues[index]} onClick={() => {setSelection(value)}}>{itemNames[index]}</Dropdown.Item>
        )
    });

    const isInvalid = validate ? validationMessage != undefined : undefined;
    const isValid = validate ? validationMessage == undefined : undefined;

    return (
        <OptionalCol breakpoints={breakpoints}>
            
            <Dropdown as={InputGroup} show={fieldIsFocused ? true : undefined} >
                <FloatingLabel label={title}>
                    <Form.Control 
                        readOnly 
                        value={getActiveName()} 
                        style={{backgroundColor: 'white'}} 
                        isInvalid={isInvalid} 
                        isValid={isValid} 
                        onFocus={() => {setFieldIsFocused(true)}}
                        onBlur={() => {setTimeout(() => {setFieldIsFocused(false)}, 100)}} 
                    />
                </FloatingLabel>
                <Dropdown.Toggle variant={'outline-primary'}/>
                <Dropdown.Menu>
                    {dropdownItems}
                </Dropdown.Menu>
            </Dropdown>
        </OptionalCol>
    )
}