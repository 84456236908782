/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faExclamation } from '@fortawesome/free-solid-svg-icons';
import React from 'react';
import Form from 'react-bootstrap/Form'
import OptionalCol from './OptionalCol';
import { InputGroup } from 'react-bootstrap';

export default function RadioControl({selection, setSelection, originalValue, optionNames, optionValues, inline, title, disabled, bordered = true, validate, validationMessage, breakpoints, useCustom, labelStyle}) {

    const radioButtons = optionValues.map((optionValue, index) => {
        return (
            <Form.Check disabled={disabled} inline={inline} key={optionValue} type='radio' label={optionNames[index]} checked={selection === optionValue} onChange={() => setSelection(optionValue)}/>
        )
    });

    const isInvalid = validate ? validationMessage != undefined : undefined;
    const isValid = validate ? validationMessage == undefined : undefined;

    const content = (
        <Form.Group>
            { title ? <Form.Label>{title}</Form.Label> : ''}
            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', border: bordered ? '1px solid ' + (isValid ? '#198754' : isInvalid ? '#dc3545' : 'lightgray') : '', borderRadius: 6, padding: bordered ? 12 : 0, backgroundColor: originalValue && originalValue !== selection ? '#faffe3' : ''}}>
                <div>
                    {radioButtons}
                    {useCustom &&  <Form.Check disabled={disabled} inline={inline} type='radio' label={'Custom'} checked={!optionValues.includes(selection)} onChange={() => setSelection('')}/>}
                </div>
                { isValid ?
                <FontAwesomeIcon icon={faCheck} style={{color: '#198754', height: 19}}/>
                : isInvalid ? <FontAwesomeIcon icon={faExclamation} style={{color: '#dc3545', width: 11, height: 11, padding: 1, border: '1px solid red', borderRadius: '50%'}}/>
                : ''
                }
            </div>
            
        </Form.Group>
    );

    const content2 = (
        <InputGroup style={{flexWrap: 'nowrap'}}>
            { title && 
                <div style={{backgroundColor: 'rgb(233, 236, 239', fontWeight: 500, display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 12, borderTopLeftRadius: 6, borderBottomLeftRadius: 6, ...labelStyle}}>
                {title}
                </div>
            }
            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', flex: 1, border: bordered ? '1px solid ' + (isValid ? '#198754' : isInvalid ? '#dc3545' : 'lightgray') : '', borderTopRightRadius: 6, borderBottomRightRadius: 6, padding: bordered ? 12 : 0, backgroundColor: originalValue && originalValue !== selection ? '#faffe3' : ''}}>
                <div>
                    {radioButtons}
                    {useCustom &&  <Form.Check disabled={disabled} inline={inline} type='radio' label={'Custom'} checked={!optionValues.includes(selection)} onChange={() => setSelection('')}/>}
                </div>
                { isValid ?
                    <FontAwesomeIcon icon={faCheck} style={{color: '#198754', height: 19}}/>
                : isInvalid ? <FontAwesomeIcon icon={faExclamation} style={{color: '#dc3545', width: 11, height: 11, flexShrink: 0, padding: 1, border: '1px solid red', borderRadius: '50%'}}/>
                : ''
                }
            </div>
            
        </InputGroup>
    );

    return (
        <OptionalCol breakpoints={breakpoints}>
            {content2}
        </OptionalCol>
    )
}