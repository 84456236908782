/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import Big from "big.js";
import { encodeDecimal, encodeNumber, getFakeUID } from "../../../../tools";

export default class Holiday {
    uid;
    payType;
    payRate;
    unitsWorked;

    constructor(uid, payType, payRate, unitsWorked) {
        this.uid = uid;
        this.payType = payType
        this.payRate = payRate;
        this.unitsWorked = unitsWorked;
    }

    static decode(obj) {
        return new Holiday(obj.uid ?? getFakeUID(), obj.payType, obj.payRate, obj.unitsWorked);
    }

    encode() {
        return {
            payType: this.payType,
            payRate: encodeDecimal(this.payRate, 4, 2, true),
            unitsWorked: this.payType == 'fl' ? null : encodeNumber(this.unitsWorked, 0, 255, true)
        }
    }

    duplicate() {
        return new Holiday(getFakeUID(), this.payType, this.payRate, this.unitsWorked);
    }

    holidayPay() {
        if(this.payType === 'fl'){
            return new Big(this.payRate);
        } else if(this.payRate !== '' && this.unitsWorked !== ''){
            return new Big(this.payRate).times(this.unitsWorked)
        } else {
            return new Big('0.00')
        }
    }

    static holidayPayTypes = {
        'ph': 'Per Hour',
        'pd': 'Per Day',
        'fl': 'Flat Amount'
    }

    static holidayUnitsWorkedLabel = {
        'ph': 'Hours',
        'pd': 'Days',
    }
}