/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import { useEffect } from "react";
import Cookies from "universal-cookie";
import ApiManager from "../ApiManager.tsx";
import LoadingWrapper from "../components/LoadingWrapper.js";
import { useQuery } from "../hooks.tsx";

export default function Authenticate({match}) {
    const query = useQuery();

    useEffect(() => {
        handle();
    }, [])

    function handle() {
        new Cookies().set('lhToken', query.get('authToken'), {path: '/'});
        const elem = window.document.createElement('a');
        elem.href = ApiManager.DEV_MODE ? `http://localhost:3000/${query.get('destination')}` : `https://mytacticaltrucking.com/${query.get('destination')}`;
        document.body.appendChild(elem);
        elem.click();
        document.body.removeChild(elem);
    }

    return (
        <LoadingWrapper isLoading={true}/>
    )
}