/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import { PDFViewer } from "@react-pdf/renderer";
import React, { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import Modal from 'react-bootstrap/Modal';
import DropdownItemWithCheck from "../../../../components/DropdownItemWithCheck";
import { ApiRequest } from '../../../../ApiManager.tsx';
import { naturalSort } from "../../../../tools.js";
import LoadingWrapper from "../../../../components/LoadingWrapper.js";
import PDFAcknowledgement from "./PDFAcknowledgement.js";

export default function AcknowledgementReport({}){
    const [isLoading, setIsLoading] = useState(false);
    const [documents, setDocuments] = useState([]);
    const [users, setUsers] = useState([]);
    const [terminals, setTerminals] = useState([]);
    const [selectedTerminals, setSelectedTerminals] = useState([]);

    useEffect(()=>{
        loadData();
    }, [])

    function loadData(){
        new ApiRequest('reports', 'getAcknowledgementReport', setIsLoading, (response) => {
            setUsers(response.users.sort((a, b) => `${a.lastName}${a.firstName}${a.middleName}`.localeCompare(`${b.lastName}${b.firstName}${b.middleName}`)));
            setDocuments(naturalSort(response.documents, 'name'));
            setTerminals(response.terminals)
            setSelectedTerminals(response.terminals)
        }).withNoAlertOnSuccess().send();
    }

    function handleSelectAllTerminals(){
        if(selectedTerminals.length !== terminals.length){
            setSelectedTerminals(terminals);
        }else{
            setSelectedTerminals([])
        }
    }

    function handleSelectTerminal(csa){
        if(selectedTerminals.find(c=> c.uid == csa.uid)){
            setSelectedTerminals(selectedTerminals.filter(c=>c.uid != csa.uid))
        }else{
            setSelectedTerminals([...selectedTerminals, csa])
        }
    }

    const terminalElements = naturalSort(terminals, 'name').map((terminal) => {
        return (
            <DropdownItemWithCheck key={terminal.uid} label={terminal.name} active={selectedTerminals.find(t => t.uid == terminal.uid)} onClick={() => {handleSelectTerminal(terminal)}}/>
        )
    })

    return (
        <>
            <Modal.Header closeButton>
                <Modal.Title>Comapny Acknowledgement Reports</Modal.Title>
            </Modal.Header>
            <Modal.Header>
                { !isLoading &&
                    <Dropdown autoClose='outside'>
                        <Dropdown.Toggle variant="outline-primary">
                            {selectedTerminals.length == 0 ? 'Filter Terminals(s)' : selectedTerminals.length > 1 ? `Filter Terminal(s): ${selectedTerminals[0].name} +${selectedTerminals.length - 1}` : `Filter Terminal(s): ${selectedTerminals[0].name}`}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Dropdown.Header>Terminals</Dropdown.Header>
                            {terminalElements}
                            <Dropdown.Divider/>
                            <DropdownItemWithCheck label='Select/Deselect All' active={selectedTerminals.length === terminals.length} onClick={() => handleSelectAllTerminals()}/>
                        </Dropdown.Menu>
                    </Dropdown>
                }               
            </Modal.Header>
            <Modal.Body>
                <LoadingWrapper isLoading={isLoading}>
                    <PDFViewer style={{width: '100%', height: '99%'}}>
                        <PDFAcknowledgement key={selectedTerminals.length} documents={documents} users={users.filter(u => selectedTerminals.map(t => t.uid).includes(u.terminalIdentifier))}/>
                    </PDFViewer>
                </LoadingWrapper>
            </Modal.Body>
        </>
    )
}