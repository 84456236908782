/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React from "react";
import { Modal } from "react-bootstrap";
import { bigToUsd } from "../../../../payrollTools";

export default function PayrollOvertimeRateModal({entry}) {

    const labels = (
        <div style={{display: 'flex', alignItems: 'center', gap: 12, fontSize: 12, lineHeight: 1, textAlign: 'center', alignSelf: 'center'}}>
            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', width: 'max-content', gap: 4}}>
                <div style={{display: 'flex', alignItems: 'center', gap: 12}}>
                    <span>hourly rate</span>
                    <span style={{fontWeight: 'bold'}}>+</span>
                    <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2}}>
                        <span>bonuses, etc.</span>
                        <div style={{backgroundColor: 'black', height: 2, width: '100%'}}/>
                        <span>hours worked</span>
                    </div>
                </div>
                <div style={{backgroundColor: 'black', height: 2, width: '100%'}}/>
                <span style={{fontSize: 14}}>2.0</span>
            </div>
            <span style={{fontWeight: 'bold'}}>+</span>
            <span>hourly rate</span>
        </div>
    )

    const numbers = (
        <div style={{display: 'flex', alignItems: 'center', gap: 12, fontSize: 12, lineHeight: 1, textAlign: 'center', alignSelf: 'center'}}>
            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', width: 'max-content', gap: 4}}>
                <div style={{display: 'flex', alignItems: 'center', gap: 12}}>
                    <span>{bigToUsd(entry.weeks[entry.weekIndex].hourlyRate())}</span>
                    <span style={{fontWeight: 'bold'}}>+</span>
                    <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2}}>
                        <span>{bigToUsd(entry.weeks[entry.weekIndex].ndBonusesIncorporatedIntoOvertimeRate())}</span>
                        <div style={{backgroundColor: 'black', height: 2, width: '100%'}}/>
                        <span>{entry.weeks[entry.weekIndex].getHoursWorked()}</span>
                    </div>
                </div>
                <div style={{backgroundColor: 'black', height: 2, width: '100%'}}/>
                <span style={{fontSize: 14}}>2.0</span>
            </div>
            <span style={{fontWeight: 'bold'}}>+</span>
            <span style={{fontWeight: 'bold'}}>{bigToUsd(entry.weeks[entry.weekIndex].hourlyRate())}</span>
            <span style={{fontWeight: 'bold'}}>=</span>
            <span style={{fontWeight: 'bold', fontSize: 15}}>{bigToUsd(entry.weeks[entry.weekIndex].overtimeRate())}</span>
        </div>
    )

    return (
        <>
            <Modal.Header closeButton>
                <Modal.Title>Overtime Rate Calculation</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>All nondiscretionary bonuses, incentive wages, and stand-by wages are incorporated into the overtime pay rate by dividing the bonus pay by the number of hours worked and adding it to the hourly wage</p>
                <div style={{display: 'flex', flexDirection: 'column', gap: 12}}>
                    {labels}
                    <hr style={{margin: 0}}/>
                    {numbers}
                </div>
            </Modal.Body>
        </>
    )
}