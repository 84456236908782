/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import { validateDecimal, usdFormatter } from "../../../../tools";
import PayrollPeriodEntryRow from "./PayrollPeriodEntryRow";

export default function PayrollPeriodTotalsRow(entries, tableHeaders) {
    const entryRows = entries.reduce((prev, curr) => {
        return [...prev, ...PayrollPeriodEntryRow(curr)];
    }, []);

    const index = tableHeaders.findIndex(th => th.includes('Wages'));

    const row = {};

    tableHeaders.slice(index, tableHeaders.length - 1).filter(th => !th.includes('%')).forEach((header) => {
        const total = entryRows.reduce((prev, curr) => {
            const amount = curr[header] ? validateDecimal(curr[header].substring(1)) : 0;
            return prev + amount;
        }, 0)
        row[header] = usdFormatter.format(total);
    })

    return row;
}