/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, { useState, useRef, useEffect } from 'react';
import { Button, FloatingLabel, Form, InputGroup, Spinner } from 'react-bootstrap';
import CustomButton from './CustomButton';
import { toast } from 'react-toastify';
import { faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getFileIcon } from '../tools.js';
import CustomControl from './CustomControl.js';

export default function FileUploadControl({value, setValue, disabled, maxTitleLength, maxFileSize = 10000000, accept, onRemove, showAcceptedTypes = true}) { 
    const ref = useRef();
    const [isLoading, setIsLoading] = useState(false);
    const [tooLarge, setTooLarge] = useState(false);
    const [localValue, setLocalValue] = useState('');


    function handleSetDocumentName(newValue) {
        setValue({...value, name: newValue});
    }
    
    async function handleOnChange() {
        const file = ref.current.files[0];
        if (file) {
            setIsLoading(true);
            setTooLarge(false);
            if (maxFileSize && file?.size > maxFileSize) {
                setTooLarge(true);
                setValue({...value, base64: null});
            } else {
                const fileObj = await new Promise((resolve, reject) => {
                    const reader = new FileReader();
                    reader.onerror = () => {
                        reject(null);
                    } 
                    reader.onload = () => {   
                        const indexOfDot = file.name.lastIndexOf('.');
                        const fileObj = {
                            base64: reader.result,
                            name: file.name.slice(0, indexOfDot).substring(0, maxTitleLength),
                            type: file.name.slice(indexOfDot + 1)
                        };
                        resolve(fileObj)  
                    }
                    reader.readAsDataURL(file);
                })
    
                if (fileObj) {
                    setValue({...value, ...fileObj});
                }else{
                    setValue({...value, base64: null});
                }
            }

            setIsLoading(false);
        }
    }

    const maxFileSizeToString = () => {
        if (maxFileSize >= 1000000000) {
            return `${maxFileSize / 1000000000} GB`;
        } else if (maxFileSize >= 1000000) {
            return `${maxFileSize / 1000000} MB`;
        } else if (maxFileSize >= 1000) {
            return `${maxFileSize / 1000} KB`;
        } else {
            return `${maxFileSize} Bytes`;
        }
    }

    return (
        <div style={{flex: 1, display: 'flex', flexDirection: 'column'}}>
            <div style={{display: 'flex', alignItems: 'center', gap: 12, flex: 1}}>
                <InputGroup style={{display: !value.base64 ? 'flex' : 'none'}}>
                    <Form.Control
                        ref={ref}
                        disabled={disabled || isLoading}
                        type='file'
                        accept={accept}
                        value={localValue} 
                        onChange={handleOnChange} 
                    />
                    { !!onRemove && 
                        <Button variant='outline-danger' onClick={onRemove}>
                            <FontAwesomeIcon icon={faTrashCan}/>
                        </Button>
                    }
                </InputGroup>
                <InputGroup style={{display: !!value.base64 ? 'flex' : 'none'}}>
                    <CustomButton onClick={() => {setValue({...value, base64: null}); setLocalValue('');}}>Change File</CustomButton>
                    <CustomControl validate validationMessage={!value.name ? 'Required' : undefined} title='File Name' disabled={disabled} setValue={handleSetDocumentName} max={maxTitleLength} value={value.name ?? ''}/>
                    <InputGroup.Text style={{backgroundColor: 'white', color: 'var(--bs-primary)', padding: '0px 6px'}}>
                        <FontAwesomeIcon icon={getFileIcon(value.type)}/>
                    </InputGroup.Text>

                    { !!onRemove && 
                        <Button variant='outline-danger' onClick={onRemove}>
                            <FontAwesomeIcon icon={faTrashCan}/>
                        </Button>
                    }
                </InputGroup>
                { isLoading && 
                    <Spinner size='sm' style={{color: 'var(--bs-primary)'}}/>
                }
            </div>
            { !!accept && showAcceptedTypes && 
                <span>{`Accepted File Types: ${accept}`}</span>
            }
            { tooLarge &&
                <span style={{color: 'red'}}>{`File size must not exceed ${maxFileSizeToString(maxFileSize)}`}</span>
            }
        </div>
    )

}