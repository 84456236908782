/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import Big from "big.js";
import { validateBig } from "../payrollTools";
import NDBonus from "./NDBonus";
import { encodeDecimal, encodeNumber, validateDecimal, validateInteger } from "../../../../tools";
import AdditionalPay from "./AdditionalPay";
import Holiday from "./Holiday";
import Pay from "./Pay";
import Pto from "./Pto";
import DBonus from "./DBonus";

export default class PayrollEntryWeek {

    daysWorked; //int
    hoursWorked; //decimal
    hourlyWage; //decimal
    nighttimeHoursWorked;
    weekendHoursWorked;
    overtimeEnabled;
    nighttimeBonusEnabled;
    weekendBonusEnabled;
    additionalPay;
    dBonuses; //NDBonus[]
    holidays; //[Holiday]
    ndBonuses; //NDBonus[]
    pay; //Pay[]
    pto; //[Pto]
    nighttimeBonus;
    weekendBonus;

    constructor(daysWorked, hoursWorked, hourlyWage, nighttimeHoursWorked, weekendHoursWorked, overtimeEnabled, nighttimeBonusEnabled, weekendBonusEnabled, additionalPay, dBonuses, holidays, ndBonuses, pay, pto, nighttimeBonus, weekendBonus) {
        this.daysWorked = daysWorked;
        this.hoursWorked = hoursWorked;
        this.hourlyWage = hourlyWage;
        this.nighttimeHoursWorked = nighttimeHoursWorked;
        this.weekendHoursWorked = weekendHoursWorked;
        this.overtimeEnabled = overtimeEnabled;
        this.nighttimeBonusEnabled = nighttimeBonusEnabled;
        this.weekendBonusEnabled = weekendBonusEnabled;
        this.additionalPay = additionalPay;
        this.dBonuses = dBonuses;
        this.holidays = holidays;
        this.ndBonuses = ndBonuses;
        this.pay = pay;
        this.pto = pto;
        this.nighttimeBonus = nighttimeBonus;
        this.weekendBonus = weekendBonus;
    }

    static decode(payrollEntryWeek) {
        return new PayrollEntryWeek(
            payrollEntryWeek.daysWorked,
            payrollEntryWeek.hoursWorked,
            payrollEntryWeek.hourlyWage,
            payrollEntryWeek.nighttimeHoursWorked,
            payrollEntryWeek.weekendHoursWorked,
            !!payrollEntryWeek.overtimeEnabled,
            !!payrollEntryWeek.nighttimeBonusEnabled,
            !!payrollEntryWeek.weekendBonusEnabled,
            payrollEntryWeek.additionalPay.map(el => AdditionalPay.decode(el)),
            payrollEntryWeek.dBonuses.map(el => DBonus.decode(el)),
            payrollEntryWeek.holidays.map(el => Holiday.decode(el)),
            payrollEntryWeek.ndBonuses.map(el => NDBonus.decode(el)),
            payrollEntryWeek.pay.map(el => Pay.decode(el)),
            payrollEntryWeek.pto.map(el => Pto.decode(el)),
            payrollEntryWeek.nighttimeBonus,
            payrollEntryWeek.weekendBonus
        )
    }

    encode() {
        return {
            daysWorked: encodeNumber(this.daysWorked, 0, 7),
            hoursWorked: encodeDecimal(this.hoursWorked, 2, 5),
            hourlyWage: encodeDecimal(this.hourlyWage, 3, 2), 
            nighttimeHoursWorked: encodeDecimal(this.nighttimeHoursWorked, 2, 5),
            weekendHoursWorked: encodeDecimal(this.weekendHoursWorked, 2, 5),
            overtimeEnabled: this.overtimeEnabled,
            nighttimeBonusEnabled: this.nighttimeBonusEnabled,
            weekendBonusEnabled: this.weekendBonusEnabled,
            additionalPay: this.additionalPay.map(ap => ap.encode()),
            dBonuses: this.dBonuses.map(ap => ap.encode()),
            holidays: this.holidays.map(holiday => holiday.encode()),
            ndBonuses: this.ndBonuses.map(b => b.encode()),
            pay: this.pay.map(p => p.encode()),
            pto: this.pto.map(pto => pto.encode()),
        }
    }

    //    ___   __     __  _____   ____    _____   ___   __  __   _____   //
    //   / _ \  \ \   / / | ____| |  _ \  |_   _| |_ _| |  \/  | | ____|  //
    //  | | | |  \ \ / /  |  _|   | |_) |   | |    | |  | |\/| | |  _|    //
    //  | |_| |   \ V /   | |___  |  _ <    | |    | |  | |  | | | |___   //
    //   \___/     \_/    |_____| |_| \_\   |_|   |___| |_|  |_| |_____|  //
    
    qualifiesForFLSA() {
        if (this.overtimeEnabled) {
            let hoursForCalc;
            if (this.pay.find(p => p.payType === 'ph')) {
                hoursForCalc = this.pay.filter((p) => p.payType === 'ph').reduce((prev, curr) => prev + validateDecimal(curr.unitsWorked), 0);
            } else {
                hoursForCalc = validateDecimal(this.hoursWorked);
            }
            let qualifies = validateDecimal(hoursForCalc) > 40;
            const yearlyPay = this.pay.find(p => p.payType === 'py');
            if (yearlyPay) {
                qualifies = qualifies && validateDecimal(yearlyPay.payRate) <= 35568;
            }
            return qualifies;
        } else {
            return false;
        }
    }
    
    hourlyRate() {
        if (this.qualifiesForFLSA()) {
            return validateBig(this.hourlyWage);
        } else {
            return this.pay[0]?.payType === 'ph' ? validateBig(this.pay[0].payRate) : new Big('0.0');
        }
    }

    getHoursWorked() {
        if (this.pay.some(p => p.payType === 'ph')) {
            return this.pay.filter((p) => p.payType === 'ph').reduce((prev, curr) => prev + validateDecimal(curr.unitsWorked), 0.0);
        } else {
            return validateDecimal(this.hoursWorked);
        }
    }

    hourlyWages() {
        return this.qualifiesForFLSA() ? new Big('40').times(this.hourlyRate()) : new Big('0.0');
    }
    
    overtimeRate() {
        if (this.qualifiesForFLSA()) {
            return (validateBig(this.hourlyRate()).plus(this.ndBonusesIncorporatedIntoOvertimeRate().div(validateBig(this.getHoursWorked())))).div(2.0).add(validateBig(this.hourlyRate()));
        } else {
            return new Big('0.0');
        }
    }

    ndBonusesIncorporatedIntoOvertimeRate() {
        return this.getNDBonuses().plus(this.totalIncentiveWages()).plus(this.totalStandByWages());
    }
    
    adjustedHourlyRate() {
        return this.qualifiesForFLSA() ? this.overtimeRate().div(1.5) : new Big('0.0');
    }
    
    overtimeHoursWorked() {
        return this.qualifiesForFLSA() ? validateBig(this.getHoursWorked()).minus('40.00') : new Big('0.0');
    }

    overtimeWages() {
        return this.qualifiesForFLSA() ? this.overtimeRate().times(this.overtimeHoursWorked()) : new Big('0.0');
    }

    //   _____    ___    _____      _      _       ____    //
    //  |_   _|  / _ \  |_   _|    / \    | |     / ___|   //
    //    | |   | | | |   | |     / _ \   | |     \___ \   //
    //    | |   | |_| |   | |    / ___ \  | |___   ___) |  //
    //    |_|    \___/    |_|   /_/   \_\ |_____| |____/   //

    getNDBonuses() {
        const totalNdBonuses =  this.ndBonuses.reduce((prev, curr) => {
            return prev.plus(validateBig(curr.getAmount()));
        }, new Big('0.0'));
        const totalDiffs = this.nighttimeDiffWages().plus(this.weekendDiffWages());
        return totalNdBonuses.plus(totalDiffs)
    }

    getAdditionalPay() {
        return this.additionalPay.reduce((prev, curr) => {
            return prev.plus(validateBig(curr.getAmount()));
        }, new Big('0.0'));
    }

    totalDBonuses() {
        return this.dBonuses.reduce((prev, curr) => {
            return prev.plus(validateBig(curr.getAmount()));
        }, new Big('0.0'));
    }

    totalPtoHours() {
        return this.pto.reduce((prev, curr) => { return prev + validateInteger(curr.hours) }, 0);
    }

    getTotalPayWages() {
        return this.pay.reduce((prev, curr) => {
            return prev.plus(curr.getWages(this.qualifiesForFLSA()));
        }, new Big('0.00'));
    }

    totalPtoWages() {
        return this.pto.reduce((prev, curr)=>{
            return prev.plus(curr.getPtoPay());
        }, new Big('0.00'));
    }
    
    totalHolidayWages() {
        return this.holidays.reduce((prev,curr)=>{
            return prev.plus(curr.holidayPay());
        }, new Big('0.00'));
    }

    totalIncentiveWages() {
        return this.pay.filter(p => p.payType === 'iw').reduce((prev, curr) => {
            return prev.plus(curr.getWages(this.qualifiesForFLSA()));
        }, new Big('0.00'));
    }

    totalStandByWages() {
        return this.pay.filter(p => p.payType === 'sw').reduce((prev, curr) => {
            return prev.plus(curr.getWages(this.qualifiesForFLSA()));
        }, new Big('0.00'));
    }

    totalAutoOvertimeWages() {
        return this.pay.filter(p => p.payType === 'ot').reduce((prev, curr) => {
            return prev.plus(curr.getWages(this.qualifiesForFLSA()));
        }, new Big('0.00'));
    }

    nighttimeDiffWages() {
        if (this.nighttimeBonusEnabled && !!this.nighttimeBonus) {
            return new Big(this.nighttimeBonus).times(new Big(this.nighttimeHoursWorked));
        } else {
            return new Big('0.0');
        }
    }

    weekendDiffWages() {
        if (this.weekendBonusEnabled && !!this.weekendBonus) {
            return new Big(this.weekendBonus).times(new Big(this.weekendHoursWorked));
        } else {
            return new Big('0.0');
        }
    }
    
    getSubGross() {
        return this.getTotalPayWages()
            .plus(this.totalDBonuses())
            .plus(this.getNDBonuses())
            .plus(this.getAdditionalPay())
            .plus(this.totalHolidayWages())
            .plus(this.totalPtoWages())
            .plus(this.hourlyWages())
            .plus(this.overtimeWages())
        ;
    }


    //   __  __   ___   ____     ____   //
    //  |  \/  | |_ _| / ___|   / ___|  //
    //  | |\/| |  | |  \___ \  | |      //
    //  | |  | |  | |   ___) | | |___   //
    //  |_|  |_| |___| |____/   \____|  //

    getColumnInclusion() {
        const includeOvertimeColumns = this.qualifiesForFLSA();
        const includeSalary = !includeOvertimeColumns && this.pay.some(pay => pay.payType === 'py');
        const includeDaily = !includeOvertimeColumns && this.pay.some(pay => pay.payType === 'pd');        

        return {
            includeSalary: includeSalary,
            includeDaily: includeDaily,
            includeOvertimeColumns: includeOvertimeColumns,
            includeHourly: includeOvertimeColumns || this.pay.some(pay => pay.payType === 'ph'),
            includeMile: !includeOvertimeColumns && this.pay.some(pay => pay.payType === 'pm'),
            includeExtraDay: !includeOvertimeColumns && this.pay.some(pay => pay.payType === 'xd'),
            includeIncentiveWages: this.pay.some(pay => pay.payType === 'iw'),
            includeStandByWages: this.pay.some(pay => pay.payType === 'sw'),
            includeAutoOvertime: this.pay.some(pay => pay.payType === 'ot'),
            includeRegularWages: includeSalary || includeDaily,
            includePto: this.pto.some(p => p.hours > 0),
            includeHolidayWages: this.holidays.length > 0,
            includeNighttimeDif: this.nighttimeBonusEnabled && !!this.nighttimeBonus && this.nighttimeHoursWorked > 0,
            includeWeekendDif: this.weekendBonusEnabled && !!this.weekendBonus && this.weekendHoursWorked > 0,
        }
    }
}