/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React from 'react';
import Form from 'react-bootstrap/Form';
import OptionalCol from './OptionalCol';

export default function SwitchControl({value, setValue, originalValue, title, disabled, breakpoints, style, noWrap = false, reverse}) {
    const handleSetValue = () => {
        if (value === '1') {
            setValue('0');
        } else if (value === '0') {
            setValue('1');
        } else if (value === 1) {
            setValue(0);
        } else if (value === 0) {
            setValue(1);
        } else {
            setValue(!value);
        }
    }

    return (
        <OptionalCol breakpoints={breakpoints}>
            <div style={{minHeight: 58, display: 'flex', alignItems: 'center', justifyContent: 'space-between', gap: 12, border: '1px solid lightgray', borderRadius: 6, padding: '12px 12px 12px 12px', backgroundColor: originalValue !== undefined && originalValue != value ? '#faffe3' : 'white', ...style}}>
                { title && <span style={{whiteSpace: !noWrap ? 'wrap' : 'nowrap'}}>{title}</span>}
                <Form.Check style={{marginRight: -6}} 
                    type='switch' 
                    checked={value == 0 ? false : !!value} 
                    onChange={handleSetValue} 
                    reverse={reverse} 
                    disabled={disabled} 
                />
            </div>
        </OptionalCol>
    )
}