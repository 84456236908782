/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, { useEffect, useState } from 'react';
import moment from 'moment';
import Card from 'react-bootstrap/Card';
import { Button, Form } from 'react-bootstrap';
import SearchBar from '../../../../components/SearchBar';
import { dateIsInRange, dateRangeToString } from '../../../../tools';
import DateRangeSelector from '../../../../components/DateRangeSelector';
import HRNotesEditor from './HRNotesEditor';
import Modal from 'react-bootstrap/Modal';
import Cookies from 'universal-cookie';
import { ApiRequest } from '../../../../ApiManager.tsx';
import LoadingWrapper from '../../../../components/LoadingWrapper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencil } from '@fortawesome/free-solid-svg-icons';
import SwitchControl from '../../../../components/SwitchControl.js';

export default function HRNotes({selectedEmployee}) {
    const [isLoading, setIsLoading] = useState(false);
    const [notes, setNotes] = useState([]);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [filter, setFilter] = useState('');
    const [filterType, setFilterType] = useState('title');
    const [showDateRangeFilter, setShowDateRangeFilter] = useState(false);
    const [onlyShowAuthoredNotes, setOnlyShowAuthoredNotes] = useState(false);
    const [showEditor, setShowEditor] = useState(false);
    const [selectedNote, setSelectedNote] = useState(undefined);
    const cookieMonster = new Cookies();

    useEffect(() => {
        loadData();
    }, [selectedEmployee])

    function loadData(){
        new ApiRequest('hr', 'getEmployeeNotes', setIsLoading, (response) => setNotes(response.notes.sort(sortNotes))).withData({companyUserIdentifier: selectedEmployee.companyUserIdentifier}).withNoAlertOnSuccess().send();
    }

    function handleSetDateRange(startDate, endDate) {
        setStartDate(startDate);
        setEndDate(endDate);
    }

    function sortNotes(a, b) {
        if (moment(a.submissionDate).isBefore(moment(b.submissionDate), 'days')) {
            return 1;
        } else if (moment(a.submissionDate).isAfter(moment(b.submissionDate), 'days')) {
            return -1;
        } else 
        return 0;
    }

    function handleCrud(type, obj) {
        let newArray = Array.from(notes);
        switch(type){
            case 'create':
                setNotes([...newArray, obj])
                break;
            case 'update':
                newArray[newArray.findIndex(n=> n.uid === obj.uid)] = obj;
                setNotes(newArray);
                break;
            case 'delete':
                setNotes(newArray.filter(r => r.uid != obj.uid));
                break;
        }
    }

    const filteredNotes = notes.filter((note) => {
        const isInRange = dateIsInRange(note.submissionDate, startDate, endDate);
        const isInSearch = !filter || note[filterType].toLocaleLowerCase().includes(filter.toLocaleLowerCase());
        const obeysOnlyShowAuthoredNotes = !onlyShowAuthoredNotes || cookieMonster.get('userData').companyUserIdentifier === note.author;
        return isInRange && isInSearch && obeysOnlyShowAuthoredNotes;
    })

    const noteElements = filteredNotes.map((note) => {
        return (
            <Card key={note.uid}>
                <Card.Body style={{display: 'flex', flexDirection: 'column', gap: 12}}>
                    <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: 6}}>
                        <Card.Title>{note.title}</Card.Title>
                        <Button onClick={() => {setShowEditor(true); setSelectedNote(note)}}><FontAwesomeIcon color='white' icon={faPencil}/></Button>
                    </div>
                    <div style={{backgroundColor: 'rgba(0, 0, 0, 0.05)', borderRadius: 6, padding: 6, overflow: 'auto', maxHeight: 300}}>
                        <span style={{whiteSpace: 'pre-line'}}>{note.body}</span>
                    </div>
                    <div style={{display: 'flex', justifyContent: 'space-between'}}>
                        <span style={{opacity: 0.5}}>{(note.lastUpdated ? 'Last edited on ' + moment(note.lastUpdated).format('MMM D, YYYY') : 'Created on ' + moment(note.submissionDate).format('MMM D, YYYY'))}</span>
                        <span style={{color: 'var(--bs-primary)'}}>{note.user ? `- ${note.user.firstName} ${note.user.lastName}` : '- TTA Admin'}</span>
                    </div>
                </Card.Body>
            </Card>
        )
    });

    const content = (
        <div style={{height: '100%', display: 'flex', flexDirection: 'column', gap: 12, overflowY: 'hidden'}}>
            <div style={{display: 'flex', gap: 12, alignItems: 'center', justifyContent: 'space-between', flexWrap: 'wrap'}}>
                <Button variant={'outline-primary'} style={{flexShrink: 0}} onClick={() => setShowDateRangeFilter(true)}>
                    {'Submission Date Filter: ' + dateRangeToString(startDate, endDate)}
                </Button>
                <div style={{display: 'flex', alignItems: 'center', gap: 12, flexWrap: 'wrap'}}>
                    <Button variant={'outline-primary'} style={{flexShrink: 0}} onClick={() => {setShowEditor(true); setSelectedNote(undefined)}}>Create Note</Button>
                    { cookieMonster.get('auth').companyUserIdentifier &&
                        <div style={{flexShrink: 0}}>
                            <Form.Check type='switch' label='Only Show My Notes' value={onlyShowAuthoredNotes} onChange={() => setOnlyShowAuthoredNotes(!onlyShowAuthoredNotes)}/>
                        </div>
                    }
                </div>
            </div>
            <SearchBar label='Filter by...' value={filter} setValue={setFilter} typeNames={['Title', 'Content']} typeValues={['title', 'body']} type={filterType} setType={setFilterType}/>
            <div style={{flex: 1, display: 'flex', flexDirection: 'column', gap: 12, overflowY: 'auto'}}>
                {noteElements}
            </div>
            <DateRangeSelector show={showDateRangeFilter} onHide={() => setShowDateRangeFilter(false)} centered handleSetDateRange={handleSetDateRange} presetOptions={['pastYear', 'pastMonth', 'pastWeek', 'allTime']}/>
        </div>
    );

    return (
        <>
            <LoadingWrapper isLoading={isLoading}>{content}</LoadingWrapper>
            <Modal show={showEditor} onHide={() => setShowEditor(false)} size='lg' fullscreen='md-down'>
                <HRNotesEditor onHide={() => setShowEditor(false)} note={selectedNote} handleCrud={handleCrud} selectedEmployee={selectedEmployee}/>
            </Modal>
        </>
    )

}