/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, { useMemo, useState } from "react";
import Modal from 'react-bootstrap/Modal';
import CustomButton from "../../../../components/CustomButton.js";
import { ApiRequest } from "../../../../ApiManager.tsx";
import { Form } from "react-bootstrap";
import { formatDateRange } from "../../../../tools.js";

export default function DeleteCustomPayrollModal({payrollPeriod, callback}) {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [deleteText, setDeleteText] = useState('');

    const deleteConfirmation = 'delete custom payroll';

    const isValid = useMemo(() => {
        return deleteText.toLowerCase() == deleteConfirmation.toLowerCase();
    }, [deleteConfirmation, deleteText])

    const isInvalid = useMemo(() => {
        return  !deleteConfirmation.toLowerCase().includes(deleteText.toLowerCase());
    }, [deleteConfirmation, deleteText])

    function handleSubmit() {
        new ApiRequest('payroll', 'deleteCustom', setIsSubmitting, (response) => {
            callback();
        }).withUid(payrollPeriod.uid).send()
    }

    return (
        <>
            <Modal.Header closeButton>
                <div>
                    <Modal.Title>{`Delete Custom Payroll?`}</Modal.Title>
                    <span style={{fontSize: 15}}>{`${payrollPeriod.companyName} | ${formatDateRange(payrollPeriod.periodStart, payrollPeriod.periodEnd)}`}</span>
                </div>
            </Modal.Header>
            <Modal.Body style={{display: 'flex', flexDirection: 'column', gap: 12, overflow: 'hidden'}}>
                    <p style={{textAlign: 'center', marginBottom: 0, fontWeight: 'bold'}}>Type "delete custom payroll" to delete this payroll period</p>
                    <Form.Control value={deleteText} isValid={isValid} isInvalid={isInvalid} onChange={(e) => {setDeleteText(e.target.value)}}/>
            </Modal.Body>
            <Modal.Footer>
                <CustomButton isLoading={isSubmitting} disabled={!isValid} onClick={handleSubmit}>Submit</CustomButton>
            </Modal.Footer>
        </>
    )
}