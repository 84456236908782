/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React from "react";
import { Form, InputGroup, Modal } from "react-bootstrap";
import { toFixedMax, usdFormatter } from "../../../../../../../tools.js";
import PayrollEntryPaySection from "../PayrollEntryPaySection.js";
import { useModalToggle } from "../../../../../../../hooks.tsx";
import AddNDBonusForm from "./AddNDBonusForm.js";
import PayrollNDBonusItem from "./PayrollNDBonusItem.js";
import QuickTable from "../../../../../../../components/QuickTable.js";
import CustomToolTip from "../../../../../../../components/CustomToolTip.js";
import { bigToUsd } from "../../../../payrollTools.js";

export default function PayrollEmployeeNondiscretionaryBonuses({entry, handleSetWeek, handleAddItemToAllEntries}) {
    const [modalIsVisible, showModal, hideModal] = useModalToggle();

    const handleAddNonDiscretionaryBonus = (ndBonus, applyToAll) => {
        if (applyToAll) {
            handleAddItemToAllEntries('ndBonuses', ndBonus, entry.weekIndex);
        } else {
            const newArr = Array.from(entry.weeks[entry.weekIndex].ndBonuses);
            newArr.push(ndBonus);
            handleSetWeek(entry.weekIndex, 'ndBonuses', newArr);
        }
        hideModal();
    }

    const removeNdBonus = (uid) => {
        const newArr = entry.weeks[entry.weekIndex].ndBonuses.filter((element) => {
            return element.uid !== uid;
        });
        handleSetWeek(entry.weekIndex, 'ndBonuses', newArr )
    }

    const handleSetNdBonus = (uid, key, value) => {
        const newArr = Array.from(entry.weeks[entry.weekIndex].ndBonuses);
        newArr.find(r => r.uid === uid)[key] = value;
        handleSetWeek(entry.weekIndex, 'ndBonuses', newArr )
    }
    
    const ndBonusRows = entry.weeks[entry.weekIndex].ndBonuses.map((nd) => {
        return (
            <PayrollNDBonusItem key={nd.uid} nd={nd} handleSetNdBonus={handleSetNdBonus} removeNdBonus={removeNdBonus}/>
        )
    });

    const reoccurringHeader = (
        <div style={{display: 'flex', gap: 6, alignItems: 'center'}}>
            <span>Reoccurring</span>
            <CustomToolTip text={'Next payroll period, this bonus will be automatically added to this employee'}/>
        </div>
    )

    const diffRows = [];

    if (!!entry.weeks[entry.weekIndex].nighttimeBonus && entry.weeks[entry.weekIndex].nighttimeHoursWorked > 0) {
        diffRows.push(
            <tr key={'nd'} style={{whiteSpace: 'nowrap'}}>
                <td style={{fontWeight: 'bold'}}>Night Differential</td>
                <td>{usdFormatter.format(entry.weeks[entry.weekIndex].nighttimeBonus ?? 0.0)}</td>
                <td>{toFixedMax(entry.weeks[entry.weekIndex].nighttimeHoursWorked, 3)}</td>
                <td style={{color: entry.weeks[entry.weekIndex].nighttimeBonusEnabled ? 'green' : 'gray'}}>{bigToUsd(entry.weeks[entry.weekIndex].nighttimeDiffWages())}</td>
                <td>
                    <Form.Check type='switch' checked={entry.weeks[entry.weekIndex].nighttimeBonusEnabled} onChange={() => {handleSetWeek(entry.weekIndex, 'nighttimeBonusEnabled', !entry.weeks[entry.weekIndex].nighttimeBonusEnabled)}}/>
                </td>
            </tr>
        )
    }

    if (!!entry.weeks[entry.weekIndex].weekendBonus && entry.weeks[entry.weekIndex].weekendHoursWorked > 0) {
        diffRows.push(
            <tr key={'wd'} style={{whiteSpace: 'nowrap'}}>
                <td style={{fontWeight: 'bold'}}>Weekend Differential</td>
                <td>{usdFormatter.format(entry.weeks[entry.weekIndex].weekendBonus ?? 0.0)}</td>
                <td>{toFixedMax(entry.weeks[entry.weekIndex].weekendHoursWorked, 3)}</td>
                <td style={{color: entry.weeks[entry.weekIndex].weekendBonusEnabled ? 'green' : 'gray'}}>{bigToUsd(entry.weeks[entry.weekIndex].weekendDiffWages())}</td>
                <td>
                    <Form.Check type='switch' checked={entry.weeks[entry.weekIndex].weekendBonusEnabled} onChange={() => {handleSetWeek(entry.weekIndex, 'weekendBonusEnabled', !entry.weeks[entry.weekIndex].weekendBonusEnabled)}}/>
                </td>
            </tr>
        )
    }


    return (
        <PayrollEntryPaySection title='NONDISCRETIONARY BONUSES' showModal={showModal}>
                <div style={{display: 'flex', flexDirection: 'column', gap: 6}}>
                    { entry.weeks[entry.weekIndex].ndBonuses.length > 0 && 
                        <div style={{overflowX: 'auto'}}>
                            <QuickTable noMargin noWrap size='sm' rows={ndBonusRows} headers={['Type', 'Amount', reoccurringHeader, '']} widths={[1, null, 1, 1]}/>
                        </div>
                    }
                    { diffRows.length > 0 && 
                        <div style={{overflowX: 'auto'}}>
                            <QuickTable noMargin noWrap size='sm' rows={diffRows} headers={['Type', 'Pay Rate', 'Hours Worked', 'Wages', 'Enabled']} widths={[1, null, null, 1, 1]}/>
                        </div>
                    }
                    { (entry.weeks[entry.weekIndex].ndBonuses.length > 0 || diffRows.length > 0) && 
                        <div style={{display: 'flex', justifyContent: 'flex-end', fontWeight: 'bold', gap: 6}}>
                            <span>Total:</span>
                            <span style={{color: 'green', }}>{usdFormatter.format(entry.weeks[entry.weekIndex].getNDBonuses().toNumber())}</span>
                        </div>
                    }
                </div>
            <Modal show={modalIsVisible} onHide={hideModal} fullscreen='lg-down'>
                <AddNDBonusForm handleAddNonDiscretionaryBonus={handleAddNonDiscretionaryBonus}/>
            </Modal>
        </PayrollEntryPaySection>
    )
}