/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import '../payroll.css';
import React, { useState, useEffect, useMemo } from "react";
import { ApiRequest } from "../../../../ApiManager.tsx";
import { Button, Card, Popover, ListGroup, OverlayTrigger, Dropdown, InputGroup, Container, Row, Col, Modal } from "react-bootstrap";
import CustomButton from "../../../../components/CustomButton.js";
import { faBars, faCheck, faEllipsis } from "@fortawesome/free-solid-svg-icons";
import { faQuestionCircle } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import LoadingWrapper from "../../../../components/LoadingWrapper.js";
import { useCloseWarning, useLoadData, useModalSwitch, useStateArray, useStateObjectArray } from "../../../../hooks.tsx";
import { formatDateRange } from "../../../../tools.js";
import PayrollEntry from "../Models/PayrollEntry.js";
import PayrollEntryEditor from "./PayrollEntryEditor/PayrollEntryEditor.js";
import { bigToUsd } from "../payrollTools.js";
import Big from "big.js";
import PayrollVersionList from "./PayrollVersionList.js";
import PayrollEmployeeSelector from "./PayrollEmployeeSelector.js";
import PayrollPeriodPreview from "./PayrollPeriodPreview.js";
import { toast } from "react-toastify";
import { Prompt } from 'react-router-dom';

export default function PayrollEditor({match, history}) {

    const [isLoading, setIsLoading] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [payrollPeriod, setPayrollPeriod] = useState(undefined);
    const [selectedVersions, setSelectedVersions] = useState(undefined);
    const [entries, setEntries, setEntryProp] = useStateObjectArray([], 'companyUserIdentifier');
    const [employeeFilter, setEmployeeFilter] = useState([]);
    const filteredEntries = useMemo(() => entries.filter(e => employeeFilter.includes(e.companyUserIdentifier)), [entries, employeeFilter]);
    const [selectedCompanyUserIdentifier, setSelectedCompanyUserIdentifier] = useState(undefined);
    const selectedEntry = useMemo(() => {
        return filteredEntries.find(e => e.companyUserIdentifier == selectedCompanyUserIdentifier);
    }, [filteredEntries, selectedCompanyUserIdentifier])
    const [modalSwitch, setModalSwitch, hideModal] = useModalSwitch();

    const [payrollVersions, setPayrollVersions, payrollVersionsMod] = useStateArray([]);

    useCloseWarning();

    useLoadData(() => {
        new ApiRequest('payroll', 'review', setIsLoading, (response) => {
            let entries = [];
            response.latestVersions.forEach((version) => {
                const versionEntries = version.entries.filter(entry => !entries.some(e => e.companyUserIdentifier == entry.companyUserIdentifier)).map(e => new PayrollEntry(e));
                entries = entries.concat(versionEntries);
            })
            entries.sort(PayrollEntry.sort);
            if (entries.some(e => !e.companyUserIdentifier)) {
                toast.error('Missing employee data detected. Please contact TTA Support');
            } else {
                setPayrollPeriod(response.payrollPeriod);
                setPayrollVersions(response.payrollPeriod.versions);
                setSelectedVersions(response.latestVersions);
                setEntries(entries);
                setEmployeeFilter(entries.map(e => e.companyUserIdentifier));
                setSelectedCompanyUserIdentifier(entries[0].companyUserIdentifier);
            }
        }).withUid(parseInt(match.params.payrollIdentifier)).withNoAlertOnSuccess().send();
    });

    function handleSaveVersion() {
        const gross = filteredEntries.reduce((prev, curr) => {
            return prev.plus(curr.gross());
        }, new Big('0')).toFixed(2);

        const payrollVersion = {
            payrollIdentifier: payrollPeriod.uid,
            entries: filteredEntries.map(e => e.encode()),
            gross: gross
        }

        new ApiRequest('payroll', 'saveVersion', setIsSaving, (response) => {
            setSelectedVersions([response.payrollVersion]);
            payrollVersionsMod.unshift(response.payrollVersion);
        }).withData({payrollVersion: payrollVersion}).send();
    }

    function handleSubmit() {
        const gross = filteredEntries.reduce((prev, curr) => {
            return prev.plus(curr.gross());
        }, new Big('0')).toFixed(2);

        const payrollVersion = {
            payrollIdentifier: payrollPeriod.uid,
            entries: filteredEntries.map(e => e.encode()),
            gross: gross
        }

        new ApiRequest('payroll', 'approve', setIsSubmitting, () => {
            history.push(`/payroll/${payrollPeriod.uid}`);
        }).withData({payrollVersion: payrollVersion}).send();
    }

    function handleSelectPayrollEntry(entry) {
        setSelectedCompanyUserIdentifier(entry.companyUserIdentifier);
        hideModal();
        setEntryProp(entry.companyUserIdentifier, 'touched', true);
    }

    function handleAddEntry(newEntry) {
        setEntries((prevState) => {
            const newArray = Array.from(prevState);
            newArray.push(newEntry);
            newArray.sort(PayrollEntry.sort);
            return newArray;
        })
    }

    function handleAddItemToAllEntries(type, item, weekIndex = null) {
        setEntries((prevState) => {
            const entries = Array.from(prevState);
            entries.forEach((entry) => {
                if (weekIndex != null) {
                    entry.weeks[weekIndex][type].push(item.duplicate());
                } else {
                    entry[type].push(item.duplicate());
                }
            });
            return entries;
        })
    }

    function handleReplacePayrollEntry(entry) {
        const newArray = entries.filter(e => e.companyUserIdentifier != entry.companyUserIdentifier);
        newArray.push(entry);
        newArray.sort(PayrollEntry.sort);
        setEntries(newArray);
    }
    
    function handleProceed() {
        const currentIndex = filteredEntries.findIndex(e => e.companyUserIdentifier == selectedEntry?.companyUserIdentifier);
        if (currentIndex >= 0 && currentIndex < filteredEntries.length - 1) {
            const entry = filteredEntries[currentIndex + 1];
            entry.touched = true;
            setSelectedCompanyUserIdentifier(entry.companyUserIdentifier);
        }
    }

    const terminals = filteredEntries.reduce((prev, curr) => {
        if (!prev.some(t => t.uid == curr.terminalIdentifier)) {
            return [...prev, {uid: curr.terminalIdentifier, name: curr.terminalName}];
        } else {
            return prev;
        }
    }, []);



    const terminalCategories = terminals.map((terminal) => {

        const entriesForTerminal = filteredEntries.filter(e => e.terminalIdentifier == terminal.uid);

        const oldUsersForTerminal = entriesForTerminal.filter(e => !e.isNew).map((entry) => {
            return (
                <PayrollEntryListItem key={entry.companyUserIdentifier} entry={entry} selectedEntry={selectedEntry} handleSelectPayrollEntry={handleSelectPayrollEntry}/>
            )
        })
        const newUsersForTerminal = entriesForTerminal.filter(e => e.isNew).map((entry) => {
            return (
                <PayrollEntryListItem key={entry.companyUserIdentifier} entry={entry} selectedEntry={selectedEntry} handleSelectPayrollEntry={handleSelectPayrollEntry}/>
            )
        })

        return (
            <div key={terminal.uid} style={{display: 'flex', flexDirection: 'column', gap: 8, marginBottom: 8,}}>
                <span style={{fontWeight: 'bold', textAlign: 'center'}}>{terminal.name}</span>
                <ListGroup>{oldUsersForTerminal}</ListGroup>
                <ListGroup>{newUsersForTerminal}</ListGroup>
            </div>
        )
    });
    

    const allApproved = filteredEntries.reduce((prev, curr) => {
        return prev && curr.approved;
    }, true);


 
    const submitDisabled = isLoading || !allApproved || employeeFilter.length == 0;


    const headerTitle = (
        <div style={{maxWidth: '100%', overflow: 'hidden'}}>
            <h5 style={{margin: 0, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>{`${formatDateRange(payrollPeriod?.periodStart, payrollPeriod?.periodEnd)} Payroll Period`}</h5>
            <p style={{margin: 0, opacity: 0.5, fontWeight: 'bold', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>{payrollPeriod?.companyName}</p>
        </div>
    )


    const topBarDesktop = (
        <div className="desktop-flex" style={{backgroundColor: 'white', padding: '6px 12px 6px 12px', alignItems: 'center', justifyContent: 'space-between', flexWrap: 'wrap', borderBottom: '1px solid lightgray', gap: 12, width: '100%'}}>
            {headerTitle}
            <div style={{display: 'flex', flexWrap: 'wrap', gap: 6, justifyContent: 'center'}}>
                <CustomButton isLoading={isSaving} onClick={handleSaveVersion}>Save Changes</CustomButton>
                <InputGroup style={{width: 'auto', flexWrap: 'nowrap', whiteSpace: 'nowrap'}}>
                    <Button variant={'outline-success'} style={{position: 'relative'}} disabled={submitDisabled} onClick={() => {setModalSwitch('approve')}}>Submit Payroll</Button>
                    { submitDisabled && 
                        <OverlayTrigger placement='auto-start' overlay={
                            <Popover style={{position: 'fixed'}}>
                                <Popover.Body>You must approve the payroll entries for all employees before submitting</Popover.Body>
                            </Popover>
                        }>
                            <Button variant="outline-primary">
                                <FontAwesomeIcon icon={faQuestionCircle}/>
                            </Button>
                        </OverlayTrigger>
                    }
                </InputGroup>
                <Dropdown>
                    <Dropdown.Toggle variant="outline-primary"><FontAwesomeIcon icon={faBars}/></Dropdown.Toggle>
                    <Dropdown.Menu>
                        <Dropdown.Item onClick={() => {setModalSwitch('selectVersion')}}>Payroll Versions</Dropdown.Item>
                        <Dropdown.Item onClick={() => {setModalSwitch('selectEmployees')}}>Change Selected Employees</Dropdown.Item>
                        <Dropdown.Item onClick={() => {setModalSwitch('previewSpreadsheet')}}>Preview Payroll Spreadsheet</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </div>
            <div className="mobile-flex" style={{flex: 1, justifyContent: 'center', whiteSpace: 'nowrap'}}> 
                <Button variant="outline-primary" onClick={() => {setModalSwitch('employeeList')}}>{selectedEntry ? `${selectedEntry.firstName} ${selectedEntry.lastName}` : 'Select Employee'}</Button>
            </div>
        </div>
    )

    const topBarMobile = (
        <div className="mobile-flex" style={{backgroundColor: 'white', padding: '6px 12px 6px 12px', flexDirection: 'column', borderBottom: '1px solid lightgray', gap: 6, textAlign: 'center'}}>
            <div style={{display: 'flex', gap: 6, justifyContent: 'space-between'}}>
                {headerTitle}
                <Dropdown>
                    <Dropdown.Toggle variant="outline-primary"><FontAwesomeIcon icon={faBars}/></Dropdown.Toggle>
                    <Dropdown.Menu>
                        <Dropdown.Item onClick={() => {setModalSwitch('selectVersion')}}>Payroll Versions</Dropdown.Item>
                        <Dropdown.Item onClick={() => {setModalSwitch('selectEmployees')}}>Change Selected Employees</Dropdown.Item>
                        <Dropdown.Item onClick={() => {setModalSwitch('previewSpreadsheet')}}>Preview Payroll Spreadsheet</Dropdown.Item>
                        <Dropdown.Divider/>
                        <Dropdown.Item style={{fontWeight: 'bold'}} onClick={handleSaveVersion}>Save Changes</Dropdown.Item>
                        <Dropdown.Divider/>
                        { submitDisabled ?
                            <OverlayTrigger placement='auto-start' overlay={
                                <Popover style={{position: 'fixed'}}>
                                    <Popover.Body>You must approve the payroll entries for all employees before submitting</Popover.Body>
                                </Popover>
                            }>
                                <Dropdown.ItemText style={{display: 'flex', alignItems: 'center', gap: 6, opacity: 0.5}}>
                                    <span>Submit Payroll</span>
                                    <FontAwesomeIcon icon={faQuestionCircle}/>
                                </Dropdown.ItemText>
                            </OverlayTrigger>
                        :
                            <Dropdown.Item disabled={submitDisabled} style={{color: 'green'}} onClick={() => {setModalSwitch('approve')}}>Submit Payroll</Dropdown.Item>
                        }

                    </Dropdown.Menu>
                </Dropdown>
            </div>
            <hr style={{margin: 0}}/>
            <div style={{display: 'flex', gap: 6}}>
                <Button style={{flex: 1}} variant="outline-primary" onClick={() => {setModalSwitch('employeeList')}}>{selectedEntry ? `${selectedEntry.firstName} ${selectedEntry.lastName}` : 'Select Employee'}</Button>
            </div>
        </div>
    )


    return (
        <LoadingWrapper isLoading={isLoading}>
            <Prompt message={'Warning - Any unsaved changes will be lost!'}/>
            { payrollPeriod && 
                <div style={{flex: 1, display: 'flex', flexDirection: 'column', overflowY: 'hidden'}}>
                    {topBarDesktop}
                    {topBarMobile}
                    
                    <div style={{padding: 6, display: 'flex', flex: 1, gap: 6, overflowY: 'hidden'}}>
                        <Card className="desktop-flex" style={{height: '100%'}}>
                            <Card.Header>
                                <Card.Title>Employees</Card.Title>
                            </Card.Header>
                            <Card.Body style={{overflowY: 'auto'}}>
                                {terminalCategories}
                            </Card.Body>
                        </Card>
                        { selectedEntry ? 
                            <PayrollEntryEditor 
                                key={selectedCompanyUserIdentifier} 
                                payrollPeriod={payrollPeriod} 
                                entry={selectedEntry} 
                                setEntryProp={setEntryProp} 
                                handleProceed={handleProceed} 
                                handleAddItemToAllEntries={handleAddItemToAllEntries} 
                                handleReplacePayrollEntry={handleReplacePayrollEntry}
                            />
                            :
                            <div style={{flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center', textAlign: 'center'}}>
                                <h2 style={{fontWeight: 'bold', opacity: 0.5}}>No Employee Selected</h2>
                            </div>
                        }
                    </div>
                    
                </div>
            }
            <Modal show={modalSwitch == 'employeeList'} onHide={hideModal} fullscreen>
                <Modal.Header closeButton>
                    <Modal.Title>Employees</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {terminalCategories}
                </Modal.Body>
            </Modal>
            <Modal show={modalSwitch == 'selectVersion'} onHide={hideModal} size='xl' fullscreen='xl-down'>
                <PayrollVersionList payrollPeriod={payrollPeriod} payrollVersions={payrollVersions} selectedVersions={selectedVersions} setSelectedVersions={setSelectedVersions} setEntries={setEntries} hideEditor={hideModal}/>
            </Modal>
            <Modal show={modalSwitch == 'selectEmployees'} onHide={hideModal} size='xl' fullscreen='xl-down'>
                <PayrollEmployeeSelector  entries={entries} handleAddEntry={handleAddEntry} employeeFilter={employeeFilter} setEmployeeFilter={setEmployeeFilter} hideModal={hideModal} payrollPeriod={payrollPeriod}/>
            </Modal>
            <Modal show={modalSwitch == 'previewSpreadsheet'} onHide={hideModal} size='xl' fullscreen='xl-down'>
                <PayrollPeriodPreview entries={filteredEntries} payrollPeriod={payrollPeriod}/>
            </Modal>
            <Modal show={modalSwitch == 'approve'} onHide={hideModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Approve Payroll on Behalf of your Company?</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    This cannot be undone. Submissions cannot be deleted.
                </Modal.Body>
                <Modal.Footer>
                    <CustomButton isLoading={isSubmitting} onClick={handleSubmit}>Submit</CustomButton>
                </Modal.Footer>
            </Modal>
        </LoadingWrapper>
    );
    
}


function PayrollEntryListItem({entry, selectedEntry, handleSelectPayrollEntry}) {

    const isSelected = entry.companyUserIdentifier == selectedEntry?.companyUserIdentifier;

    return (
        <ListGroup.Item action style={isSelected ? {backgroundColor: 'var(--bs-primary)', position: 'relative'} : {position: 'relative'}} onClick={() => {handleSelectPayrollEntry(entry);}}>
            <div key={entry.companyUserIdentifier} style={{display: 'flex', justifyContent: 'space-between', gap: 12, alignItems:'center'}}>
                <div style={{display:'flex', gap:10, alignItems:'center'}}>
                    <span style={isSelected ? {color: 'white'} : {}}>
                        {`${entry.lastName}, ${entry.firstName} ${entry.middleName}${false ? ' (L.o.A)' : ''}`}
                    </span>
                </div>
                <div style={{display: 'flex', alignItems: 'center', gap: 6}}>
                    <span style={{color: isSelected ? 'white' : 'black', opacity: 0.5}}>{bigToUsd(entry.gross())}</span>
                    { (entry.touched || entry.approved) &&
                        <FontAwesomeIcon icon={entry.approved ? faCheck : faEllipsis} style={{color: entry.approved ? 'green' : 'gold'}}/>
                    }
                </div>
            </div>
            { !entry.previousEntry && 
                <span style={{position: 'absolute', fontSize: 10, right: 6, bottom: 0, fontWeight: 'bold', color: isSelected ? 'white' : 'var(--bs-primary)'}}>NEW</span>
            }
        </ListGroup.Item>
    )
}