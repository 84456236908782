/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, { useEffect, useState } from 'react';
import { Accordion, ListGroup, Modal } from 'react-bootstrap';
import { usdFormatter, validateDecimal } from '../../../../tools';
import { ApiRequest } from '../../../../ApiManager.tsx';
import LoadingWrapper from '../../../../components/LoadingWrapper.js';
import QuickTable from '../../../../components/QuickTable.js';
import CustomButton from '../../../../components/CustomButton.js';
import HRUserLoanEditor from './HRUserLoanEditor.js';
import KeyValueRow from '../../../../components/KeyValueRow.js';
import './HRUserLoan.css'

export default function HRUserLoan({selectedEmployee, handleRecalculateEntry}) {
    const [isLoading, setIsLoading] = useState(false);
    const [loans, setLoans] = useState([]);
    const [tickets, setTickets] = useState([]);
    const [isTicket, setIsTicket] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [selectedLoan, setSelectedLoan] = useState(null);

    useEffect(() => {
        loadData();
    }, [selectedEmployee])

    function loadData(){
        new ApiRequest('hr', 'getAllLoans', setIsLoading, (response) => {
            setLoans(response.loans.filter(l => !l.isTicket));
            setTickets(response.loans.filter(l => l.isTicket));
        }).withData({companyUserIdentifier: selectedEmployee.companyUserIdentifier}).withNoAlertOnSuccess().send()
    }

    function handleLoanCrud(type, data, isTicket) {
        let newArr = isTicket ? Array.from(tickets) : Array.from(loans);
        if (type === 'create') {
            newArr.push(data);
        } else if (type === 'update') {
            newArr = newArr.filter(t => t.uid != data.uid);
            newArr.push(data);
        } else if (type === 'delete') {
            newArr = newArr.filter(t => t.uid != data);
        } else if (type === 'installment') {
            const loan = newArr.find(l => l.uid == data.loanIdentifier);
            loan.installmentSum = validateDecimal(loan.installmentSum) + validateDecimal(data.amount);
        } else if (type === 'installmentRemove'){
            const loan = newArr.find(l => l.uid == data.loanIdentifier);
            loan.installmentSum = validateDecimal(loan.installmentSum) - validateDecimal(data.amount);
        }

        isTicket ? setTickets(newArr) : setLoans(newArr);

        if (type !== 'installment' && type !== 'installmentRemove') {
            setShowModal(false);
        }
        if (handleRecalculateEntry) {
            handleRecalculateEntry();
        }
    }

    /////////////////
    /// DESKTOP
    /////////////////
    const loanRows = loans.filter(l => validateDecimal(l.installmentSum) < validateDecimal(l.amount)).map((l)=>{
        const amountLeft = validateDecimal(l.amount) - validateDecimal(l.installmentSum);
        return (
           <tr key={l.uid} className='cursor-pointer' onClick={() => {setSelectedLoan(l); setShowModal(true)}}>
                <td>{l.name}</td>
                <td>{usdFormatter.format(l.amount)}</td>
                <td>{usdFormatter.format(l.installment)}</td>
                <td>{usdFormatter.format(l.installmentSum)}</td>
                <td>{usdFormatter.format(amountLeft)}</td>
                <td>{l.isEnabled ? 'Yes' : 'No'}</td>
           </tr>
        )
    });

    const ticketRows = tickets.filter(t => validateDecimal(t.installmentSum) < validateDecimal(t.amount)).map((ticket) => {
        const amountLeft = ticket.amount - ticket.installmentSum;
        return (
           <tr key={ticket.uid} className='cursor-pointer' onClick={() => {setSelectedLoan(ticket); setShowModal(true)}}>
                <td>{ticket.name}</td>
                <td>{usdFormatter.format(ticket.amount)}</td>
                <td>{usdFormatter.format(ticket.installment)}</td>
                <td>{usdFormatter.format(ticket.installmentSum)}</td>
                <td>{usdFormatter.format(amountLeft)}</td>
                <td>{ticket.isEnabled ? 'Yes' : 'No'}</td>
           </tr>
        )
    });

    const loanHistoryRows = loans.filter(l => validateDecimal(l.installmentSum) >= validateDecimal(l.amount)).map((l) => {
        return (
            <tr key={'loan' + l.uid} className='cursor-pointer' onClick={() => {setSelectedLoan(l); setShowModal(true)}}>
                <td>Loan</td>
                <td>{l.name}</td>
                <td>{l.amount}</td>
            </tr>
        )
    })

    const ticketHistoryRows = tickets.filter(t => validateDecimal(t.installmentSum) >= validateDecimal(t.amount)).map((t) => {
        return (
            <tr key={'ticket' + t.uid} className='cursor-pointer' onClick={() => {setSelectedLoan(t); setShowModal(true)}}>
                <td>Fine Ticket Damage</td>
                <td>{t.name}</td>
                <td>{t.amount}</td>
            </tr>
        )
    })

    /////////////////
    /// MOBILE
    /////////////////

    const loanListItems = loans.filter(l => validateDecimal(l.installmentSum) < validateDecimal(l.amount)).map((l)=>{
        const amountLeft = validateDecimal(l.amount) - validateDecimal(l.installmentSum);
        return (
            <ListGroup.Item key={l.uid} action onClick={() => {setSelectedLoan(l); setShowModal(true)}}>
                <h5 style={{fontWeight: 'bold', textAlign: 'center'}}>{l.name}</h5>
                <hr/>
                <KeyValueRow title='Amount Borrowed' value={usdFormatter.format(l.amount)}/>
                <KeyValueRow title='Installment' value={usdFormatter.format(l.installment)}/>
                <KeyValueRow title='Amount Paid' value={usdFormatter.format(l.installmentSum)}/>
                <KeyValueRow title='Amount Remaining' value={usdFormatter.format(amountLeft)}/>
                <KeyValueRow title='Enabled' value={l.isEnabled ? 'Yes' : 'No'}/>
            </ListGroup.Item>
        )
    });

    const ticketListItems = tickets.filter(t => validateDecimal(t.installmentSum) < validateDecimal(t.amount)).map((t) => {
        const amountLeft = t.amount - t.installmentSum;
        return (
            <ListGroup.Item key={t.uid} action onClick={() => {setSelectedLoan(t); setShowModal(true)}}>
                <h5 style={{fontWeight: 'bold', textAlign: 'center'}}>{t.name}</h5>
                <hr/>
                <KeyValueRow title='Amount Borrowed' value={usdFormatter.format(t.amount)}/>
                <KeyValueRow title='Installment' value={usdFormatter.format(t.installment)}/>
                <KeyValueRow title='Amount Paid' value={usdFormatter.format(t.installmentSum)}/>
                <KeyValueRow title='Amount Remaining' value={usdFormatter.format(amountLeft)}/>
                <KeyValueRow title='Enabled' value={t.isEnabled ? 'Yes' : 'No'}/>
            </ListGroup.Item>
        )
    });

    const loanHistoryListItems = loans.filter(l => validateDecimal(l.installmentSum) >= validateDecimal(l.amount)).map((l) => {
        return (
            <ListGroup.Item key={l.uid} action onClick={() => {setSelectedLoan(l); setShowModal(true)}}>
                <h5 style={{fontWeight: 'bold', textAlign: 'center'}}>{l.name}</h5>
                <hr/>
                <KeyValueRow title='Type' value={'Loan'}/>
                <KeyValueRow title='Name' value={l.name}/>
                <KeyValueRow title='Amount' value={usdFormatter.format(l.amount)}/>
            </ListGroup.Item>
        )
    });

    const ticketHistoryListItems = tickets.filter(t => validateDecimal(t.installmentSum) >= validateDecimal(t.amount)).map((t) => {
        return (
            <ListGroup.Item key={t.uid} action onClick={() => {setSelectedLoan(t); setShowModal(true)}}>
                <h5 style={{fontWeight: 'bold', textAlign: 'center'}}>{t.name}</h5>
                <hr/>
                <KeyValueRow title='Type' value={'Loan'}/>
                <KeyValueRow title='Name' value={t.name}/>
                <KeyValueRow title='Amount' value={usdFormatter.format(t.amount)}/>
            </ListGroup.Item>
        )
    });

    return (
        <LoadingWrapper isLoading={isLoading} onlyRenderIfTruthy={selectedEmployee ?? false}>
            <div style={{display: 'flex', flexDirection: 'column', gap: 12, haight: '100%', overflow: 'auto'}}>
                <div style={{display:'flex', justifyContent:'flex-end', gap: 8, alignItems: 'center'}}>
                    <CustomButton label={'Create Loan'} onClick={() => {setSelectedLoan(null); setShowModal(true); setIsTicket(false)}}>Create Loan</CustomButton>
                    <CustomButton label={'Create Ticket'} onClick={() => {setSelectedLoan(null); setShowModal(true); setIsTicket(true)}}>Create Ticket</CustomButton>
                </div>
                <Accordion alwaysOpen defaultActiveKey={['0', '1', '2']}>
                    <Accordion.Item eventKey='0'>
                        <Accordion.Header><b>Current Loans</b></Accordion.Header>
                        <Accordion.Body>
                            <div className='hr-loan-desktop-block'>
                                <QuickTable headers={['Name','Amount Borrowed', 'Installment', 'Amount Paid', 'Amount Remaining', 'Enabled']} rows={loanRows}  size={'sm'}/>
                            </div>
                            <div className='hr-loan-mobile-block'>
                                <ListGroup>
                                    {loanListItems}
                                </ListGroup>
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey='1'>
                        <Accordion.Header><b>Current Fine Ticket Damage</b></Accordion.Header>
                        <Accordion.Body>
                            <div className='hr-loan-desktop-block'>
                                <QuickTable headers={['Name','Amount Borrowed', 'Installment', 'Amount Paid', 'Amount Remaining', 'Enabled']} rows={ticketRows} size={'sm'}/>
                            </div>
                            <div className='hr-loan-mobile-block'>
                                <ListGroup>
                                    {ticketListItems}
                                </ListGroup>
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey='2'>
                        <Accordion.Header><b>Paid Loans / Fine Ticket Damage</b></Accordion.Header>
                        <Accordion.Body>
                            <div className='hr-loan-desktop-block'>
                                <QuickTable headers={['Type', 'Name', 'Amount']} rows={loanHistoryRows.concat(ticketHistoryRows)} size='sm'/>
                            </div>
                            <div className='hr-loan-mobile-block'>
                                <ListGroup>
                                    {loanHistoryListItems}
                                    {ticketHistoryListItems}
                                </ListGroup>
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </div>
            <Modal centered size='lg' show={showModal} onHide={() => setShowModal(false)}>
                <HRUserLoanEditor companyUserIdentifier={selectedEmployee.companyUserIdentifier} isTicketProp={isTicket} selectedLoan={selectedLoan} handleLoanCrud={handleLoanCrud}/>
            </Modal>
        </LoadingWrapper>
    )
}