/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, { useEffect, useReducer, useState } from 'react';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Dropdown from 'react-bootstrap/Dropdown';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import OptionalCol from '../OptionalCol';
import ValidationFeedback from '../ValidationFeedback';

export default function CustomRadio({itemValues, itemNames, title, breakpoints, setSelection, selection, stateProps, validation, forceValidation = false, inline = true, innerRef, style}) {
    const [showValidation, setShowValidation] = useState(forceValidation);
    const [_, forceUpdate] = useReducer(x => x + 1, 0);

    useEffect(() => {
        stateProps?.subscribe((showValidation) => {
            if (showValidation) {
                setShowValidation(true);
            }
            forceUpdate();
        });
    }, [stateProps]);

    const handleSetSelection = (newValue) => {
        setShowValidation(true);
        if (stateProps) {
            stateProps.setValue(newValue);
        }
        if (setSelection) {
            setSelection(newValue);
        }
    }

    const radioButtons = itemValues.map((value, index) => {
        const label = itemNames[index]
        return (
            <Form.Check 
                onBlur={() => {setShowValidation(true)}}
                inline={inline} 
                label={label} 
                type='radio' 
                key={value} 
                onChange={() => handleSetSelection(value)} 
                checked={selection == value || (stateProps?.getValue() == value ?? false)}
            />
        )
    });

    const isInvalid = showValidation ? validation ? validation(selection) : stateProps?.getIsInvalid() : false;
    const isValid = showValidation ? validation ? !validation(selection) : stateProps?.getIsValid() : false;

    return (
        <OptionalCol breakpoints={breakpoints}>
            <div ref={innerRef} className={'form-control ' + (isInvalid ? 'is-invalid' : isValid ? 'is-valid' : '')} style={{position: 'relative', minHeight: 58, display: 'flex', flexDirection: 'column', alignItems: 'flex-start', padding: '6px 12px 3px 12px', ...style}}>
                <div style={{marginBottom: 0, opacity: 0.65, fontSize: 14}}>{title}<span style={{color: 'red'}}>{(validation || stateProps?.hasValidation()) ? '*' : ''}</span></div>
                <div>
                    {radioButtons}
                </div>
                <ValidationFeedback stateProps={stateProps} value={selection} validation={validation} showValidation={showValidation}/>
            </div>
        </OptionalCol>
    )
}