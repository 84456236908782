/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React from "react";
import InputGroup from 'react-bootstrap/InputGroup';
import Button from 'react-bootstrap/Button';
import Dropdown from 'react-bootstrap/Dropdown';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { bigToUsd } from "../../../../payrollTools.js";
import Holiday from "../../../../Models/Holiday.js";
import PayrollIntegerControl from "../../../PayrollIntegerControl.js";
import PayrollMoneyControl from "../../../PayrollMoneyControl.js";
import PayrollEntryPaySection from "../PayrollEntryPaySection.js";
import { useModalToggle } from "../../../../../../../hooks.tsx";
import { Modal } from "react-bootstrap";
import AddHolidayForm from "./AddHolidayForm.js";
import PayrollHolidayItem from "./PayrollHolidayItem.js";
import QuickTable from "../../../../../../../components/QuickTable.js";

export default function PayrollEmployeeHolidaysEditor({entry, handleSetWeek}) {
    const [modalIsVisible, showModal, hideModal] = useModalToggle();

    const handleAddHoliday = (holiday) => {
        const newArr = Array.from(entry.weeks[entry.weekIndex].holidays);
        newArr.push(holiday);
        handleSetWeek(entry.weekIndex, 'holidays', newArr);
        hideModal();
    }

    const removeHoliday = (uid) => {
        const newArr = entry.weeks[entry.weekIndex].holidays.filter((element) => {
            return element.uid !== uid;
        });
        handleSetWeek(entry.weekIndex, 'holidays', newArr);
    }

    const handleSetHoliday = (uid, key, value) => {
        const newArr = Array.from(entry.weeks[entry.weekIndex].holidays);
        newArr.find(r => r.uid === uid)[key] = value;
        handleSetWeek(entry.weekIndex, 'holidays', newArr);
    }

    const holidayElements = entry.weeks[entry.weekIndex].holidays.map((holiday) => {
        return (
            <PayrollHolidayItem key={holiday.uid} holiday={holiday} handleSetHoliday={handleSetHoliday} removeHoliday={removeHoliday}/>
        )
    });

    return (
        <PayrollEntryPaySection title='HOLIDAYS' showModal={showModal}>
            { entry.weeks[entry.weekIndex].holidays.length > 0 && 
                <div style={{display: 'flex', flexDirection: 'column', gap: 6}}>
                    <div style={{overflowX: 'auto'}}>
                        <QuickTable noMargin noWrap size='sm' rows={holidayElements} headers={['Pay Rate', 'Pay Type', 'Units Worked', 'Wages', '']} widths={[null, 1, null, 1, 1]}/>
                    </div>
                    <div style={{display: 'flex', justifyContent: 'flex-end', fontWeight: 'bold', gap: 6}}>
                        <span>Total:</span>
                        <span style={{color: 'green', }}>{bigToUsd(entry.weeks[entry.weekIndex].totalHolidayWages())}</span>
                    </div>
                </div>
            }
            <Modal show={modalIsVisible} onHide={hideModal} fullscreen='lg-down'>
                <AddHolidayForm handleAddHoliday={handleAddHoliday} qualifiesForPtoAndHolidays={entry.qualifiesForPtoAndHolidays()} pay={entry.weeks[entry.weekIndex].pay}/>
            </Modal>
        </PayrollEntryPaySection>
    )
}