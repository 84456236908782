/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__∂
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useIdentifiableStateArray } from '../../../../hooks.tsx';
import MultipleFileUploadControl from '../../../../components/MultipleFileUploadControl.js';
import CustomButton from '../../../../components/CustomButton.js';
import { ApiRequest } from '../../../../ApiManager.tsx';

export default function HRDocumentsUploadModal({companyUserIdentifier, handleUploadDocumentsCrud}) {
    const [documents, modifyArray] = useIdentifiableStateArray([], 'uid');
    const [isLoading, setIsLoading] = useState(false);

    function handleUploadDocuments() {
        const documentsToSend = documents.filter(d => !!d.base64).map((d) => {return {...d, companyUserIdentifier: companyUserIdentifier}});
        new ApiRequest('hr', 'createCustomDocument', setIsLoading, (response) => {
            documentsToSend.forEach((f, index) => f.fileIdentifier = response['uids'][index]);
            handleUploadDocumentsCrud(documentsToSend);
        }).withData({files: documentsToSend}).send()
    }

    return (
        <>
            <Modal.Header closeButton>
                <Modal.Title>Upload Documents</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <MultipleFileUploadControl value={documents} modifyArray={modifyArray} maxTitleLength={100}/>
            </Modal.Body>
            <Modal.Footer>
                <CustomButton isLoading={isLoading} onClick={handleUploadDocuments}>Submit</CustomButton>
            </Modal.Footer>
        </>
    )
}