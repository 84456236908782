/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import DBonus from "../Models/DBonus";
import NDBonus from "../Models/NDBonus";
import AdditionalPay from "../Models/AdditionalPay";
import Deduction from "../Models/Deduction";
import PayrollPeriodEntryRow from "./PayrollPeriodEntryRow";

export default function PayrollPeriodHeadersRow(entries) {

    const rows = entries.reduce((prev, curr) => {
        return [...prev, ...PayrollPeriodEntryRow(curr)];
    }, []);

    let tableHeaders = [
        'Name', 'Terminal', 'BWC Code', 'Salary', 'Daily Rate', 'Hourly Rate', 'Adjusted Hourly Rate', 
        'Overtime Rate', 'Rate Per Mile', 'Extra Day Rate', 'Days Worked', 'Hours Worked', 'Straight Time Hours', 'Overtime Hours', 
        'Miles', 'Extra Days', 'PTO Hours', 'Regular Wages', 'Hourly Wages', 'Overtime Wages', 'Mile Wages', 
        'Extra Day Wages', 'Incentive Wages', 'Stand-By Wages', 'Extra Day Pay', 'PTO Wages', 'Holiday Wages', 'Night Differential', 'Weekend Differential'
    ].filter(header => rows.some(r => r[header] != undefined));

    tableHeaders = [...tableHeaders, ...NDBonus.ndBonusTypes.filter((type) => {
        return rows.some(row => row[type]);
    })];

    AdditionalPay.additionalPayTypes.forEach((type) => {
        let apPointer = 0;
        while (rows.some(row => row[`${type} #${apPointer + 1}`])) {
            tableHeaders.push(`${type} #${apPointer + 1}`);
            apPointer++;
        }
    })

    tableHeaders = [...tableHeaders, ...DBonus.dBonusTypes.filter((type) => {
        return rows.some(row => row[type]);
    })];

    tableHeaders.push('Gross');

    let loanPointer = 0;
    while (rows.some(row => row[`Loan #${loanPointer + 1}`])) {
        tableHeaders.push(`Loan #${loanPointer + 1}`);
        loanPointer++;
    }

    let ticketPointer = 0;
    while (rows.some(row => row[`Fine Ticket Damage #${ticketPointer + 1}`])) {
        tableHeaders.push(`Fine Ticket Damage #${ticketPointer + 1}`);
        ticketPointer++;
    }

    tableHeaders = [...tableHeaders, ...['Child Support', 'Medical Insurance', 'Dental Insurance', 'Vision Insurance'].filter(header => rows.some(r => r[header]))];
    
    tableHeaders = [...tableHeaders, ...Deduction.deductionTypes.filter((type) => {
        return rows.some(row => row[type]);
    })];

    tableHeaders.push('Notes');

    return tableHeaders;
}