/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React from "react";
import { bigToUsd } from "../../../../payrollTools.js";
import moment from "moment";
import { usdFormatter } from "../../../../../../../tools.js";

export default function PayrollPtoItem({pto}) {

    return (
        <tr style={{verticalAlign: 'middle', textAlign: 'center', whiteSpace: 'nowrap'}}>
            <td>{moment(pto.date).format('MMM D')}</td>
            <td>{`${usdFormatter.format(pto.payRate)} Per Hour`}</td>
            <td>{pto.hours}</td>
            <td style={{color: 'green', fontWeight: 'bold'}}>{bigToUsd(pto.getPtoPay())}</td>
        </tr>
    )
}