/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, { useState } from "react";
import { usdFormatter } from "../../../../../../../tools.js";
import PayrollEntryPaySection from "../PayrollEntryPaySection.js";
import { useModalToggle } from "../../../../../../../hooks.tsx";
import { InputGroup, Modal } from "react-bootstrap";
import AddWageForm from "./AddWageForm.js";
import PayrollEmployeeWageItem from "./PayrollEmployeeWageItem.js";
import QuickTable from "../../../../../../../components/QuickTable.js";
import CustomButton from "../../../../../../../components/CustomButton.js";
import CustomToolTip from "../../../../../../../components/CustomToolTip.js";
import { ApiRequest } from "../../../../../../../ApiManager.tsx";
import PayrollEntry from "../../../../Models/PayrollEntry.js";
import { toast } from "react-toastify";

export default function PayrollEmployeeWagesEditor({entry, handleSetWeek, payrollPeriod}) {
    const [modalIsVisible, showModal, hideModal] = useModalToggle();
    const [isLoading, setIsLoading] = useState(false);

    function handleRecalculateWages() {
        new ApiRequest('payroll', 'recalculateEntry', setIsLoading, (response) => {
            const payrollEntry = new PayrollEntry(response.payrollEntry);
            for (let i = 0; i < entry.weeks.length; i++) {
                handleSetWeek(i, 'pay', payrollEntry.weeks[i].pay);
            }
            toast.success(`Successfully recalculated Wages for ${entry.firstName} ${entry.lastName}`);
        }).withNoAlertOnSuccess().withData({companyUserIdentifier: entry.companyUserIdentifier, payrollIdentifier: payrollPeriod.uid}).send()
    }

    const handleAddWages = (pay, applyToAll) => {
        if(applyToAll){
            entry.weeks.forEach((week, i)=>{
                const newArr = Array.from(week.pay);
                newArr.push(pay);
                handleSetWeek(i, 'pay', newArr);
            });
        } else {
            const newArr = Array.from(entry.weeks[entry.weekIndex].pay);
            newArr.push(pay);
            handleSetWeek(entry.weekIndex, 'pay', newArr);
        }
        hideModal();
    }
    
    const removePay = (uid) => {
        const newArr = entry.weeks[entry.weekIndex].pay.filter((element) => {
            return element.uid !== uid;
        });
        handleSetWeek(entry.weekIndex, 'pay', newArr);
    }

    const handleSetPay = (uid, key, value) => {
        const newArr = Array.from(entry.weeks[entry.weekIndex].pay);
        newArr.find(r => r.uid === uid)[key] = value
        handleSetWeek(entry.weekIndex, 'pay', newArr);
    }

    const payElements = entry.weeks[entry.weekIndex].pay.map((pay) => {
        return (
            <PayrollEmployeeWageItem key={pay.uid} entry={entry} pay={pay} handleSetPay={handleSetPay} removePay={removePay}/>
        );
    });

    const paySubGross = entry.weeks[entry.weekIndex].getTotalPayWages();
  
    return (
        <PayrollEntryPaySection title='WAGES' showModal={showModal}>
            <div style={{display: 'flex', flexDirection: 'column', gap: 6}}>
                { entry.weeks[entry.weekIndex].pay.length > 0 &&
                    <>
                        <div style={{overflowX: 'auto'}}>
                            <QuickTable size='sm' responsive={false} headers={['Pay Rate', 'Pay Type', 'Units Worked', 'Subgross', '']} noWrap noMargin widths={[null, 1, null, 1, 1]} rows={payElements}/>
                        </div>
                        <div style={{display: 'flex', justifyContent: 'flex-end', fontWeight: 'bold', gap: 6}}>
                            <span>Total:</span>
                            <span style={{color: 'green', }}>{usdFormatter.format(paySubGross)}</span>
                        </div>
                    </>
                }
                <InputGroup style={{width: 'auto', marginLeft: 'auto'}}>
                    <CustomButton isLoading={isLoading} onClick={handleRecalculateWages}>Recalculate Wages</CustomButton>
                    <InputGroup.Text>
                        <CustomToolTip text="Recalculate Wages to import the pay data & timesheet data from our system into this employee's payroll entry. This applies to all weeks and will overwrite any existing wages."/>
                    </InputGroup.Text>
                </InputGroup>
            </div>
            <Modal show={modalIsVisible} onHide={hideModal} fullscreen='lg-down'>
                <AddWageForm handleAddWages={handleAddWages} entry={entry} payrollPeriod={payrollPeriod}/>
            </Modal>
        </PayrollEntryPaySection>
    )
}

