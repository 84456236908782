/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React from 'react';
import Form from 'react-bootstrap/Form';
import { faPlus,faTrashCan} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, InputGroup } from 'react-bootstrap';
import OptionalCol from './OptionalCol';
import CustomControl from './CustomControl';

export default function ChildSupportControl({value, setValue, breakpoints, validationMessages}) {

    const handleSetChildSupport = (index, newValue) => {
        const newChildSupport = Array.from(value);
        newChildSupport[index] = newValue;
        setValue(newChildSupport);
    }

    const handleAddChildSupport = () => {
        const newChildSupport = Array.from(value);
        newChildSupport.push('');
        setValue(newChildSupport);
    }

    const handleRemoveChildSupport = (index) => {
        setValue(value.toSpliced(index, 1));
    }

    const csElements = value.map((cs, index) => {
        return (
            <InputGroup key={index} style={{flexWrap: 'nowrap'}}>
                <InputGroup.Text>{index + 1}</InputGroup.Text>
                <CustomControl validate type='number' title={`Amount`} max={9999.99} validationMessage={validationMessages[index]} value={cs} setValue={(value) => {handleSetChildSupport(index, value)}} style={{borderRadius: 0}}/>
                <Button variant='outline-danger'  onClick={() => {handleRemoveChildSupport(index)}}>
                    <FontAwesomeIcon icon={faTrashCan}/>
                </Button>
            </InputGroup>

        )
    })

    return (
        <OptionalCol breakpoints={breakpoints}>
            <div style={{border: '1px solid lightgray', borderRadius: 6, padding: 12}}>
                <p style={{marginBottom: 4, fontSize: 14, opacity: 0.65}}>Child Support</p>
                <div style={{display: 'flex', flexWrap: 'wrap', gap: 12}}>
                    {csElements}
                    <Button variant='outline-primary' onClick={handleAddChildSupport}><FontAwesomeIcon icon={faPlus}/></Button>
                </div>
            </div>
        </OptionalCol>
    )
}