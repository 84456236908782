/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, { useEffect, useMemo, useState } from "react";
import { ApiRequest } from "../../../../ApiManager.tsx";
import LoadingWrapper from "../../../../components/LoadingWrapper.js";
import { formatDateRange } from "../../../../tools.js";
import { Button, Card, Dropdown, Modal } from "react-bootstrap";
import moment from "moment";
import PayrollPeriodTable from "./PayrollPeriodTable.js";
import PayrollEntry from "../Models/PayrollEntry.js";
import { useLoadData, useModalSwitch, useModalToggle } from "../../../../hooks.tsx";
import PayrollPeriodCsv from "./PayrollPeriodCsv.js";
import PayrollPeriodPdf from "./PayrollPeriodPdf.js";
import Cookies from "universal-cookie";
import CreateCustomPayrollModal from "./CreateCustomPayrollModal.js";
import DeleteCustomPayrollModal from "./DeleteCustomPayrollModal.js";
import { getPayrollVersionType } from "../payrollTools.js";
import SendBackToBcModal from "./SendBackToBcModal.js";
import SendBackToCompanyModal from "./SendBackToCompanyModal.js";
import DeletePayrollVersionModal from "./DeletePayrollVersionModal.js";

export default function PayrollPeriod({match, history}) {

    const [isLoading, setIsLoading] = useState(false);
    const [payrollPeriod, setPayrollPeriod] = useState(undefined);
    const [payrollVersions, setPayrollVersions] = useState([]);
    const [selectedVersionIdentifier, setSelectedVersionIdentifier] = useState(undefined);
    const [selectedComparedVersionIdentifier, setSelectedComparedVersionIdentifier] = useState(undefined);
    const [modalSwitch, setModalSwitch, hideModal] = useModalSwitch();
    const [isAdmin, _] = useState(!!(new Cookies().get('auth').adminIdentifier));

    const selectedVersion = useMemo(() => {
        return payrollVersions.find(v => v.uid == selectedVersionIdentifier);
    }, [payrollVersions, selectedVersionIdentifier])

    const selectedComparedVersion = useMemo(() => {
        return payrollVersions.find(v => v.uid == selectedComparedVersionIdentifier);
    }, [payrollVersions, selectedComparedVersionIdentifier])

    function loadData(payrollIdentifier = parseInt(match.params.payrollIdentifier)) {
        new ApiRequest('payroll', 'get', setIsLoading, (response) => {
            setPayrollPeriod(response.payrollPeriod);
            setPayrollVersions(response.payrollPeriod.versions);
            setSelectedVersionIdentifier(response.payrollPeriod.versions[0].uid);
        }).withUid(payrollIdentifier).withNoAlertOnSuccess().send();
    }
    
    useLoadData(loadData);

    


    function handleSetVersion(version) {
        version.entries = version.entries.map(e => new PayrollEntry(e)).sort(PayrollEntry.sort);
        setPayrollVersions((prevState) => {
            return prevState.filter(v => v.uid != version.uid).concat(version).sort((a, b) => {return a.dateTime < b.dateTime ? 1 : a.dateTime > b.dateTime ? -1 : 0});
        })
    }


    const versionElements = payrollPeriod?.versions.map((v) => {
        return (
            <PayrollVersionDropdownItem key={v.uid} version={v} selectedVersion={selectedVersionIdentifier} setSelectedVersionIdentifier={setSelectedVersionIdentifier} payrollPeriod={payrollPeriod}/>
        )
    })

    const comparedVersionElements = payrollPeriod?.versions.filter(v => v.uid != selectedVersionIdentifier).map((v) => {
        return (
            <PayrollVersionDropdownItem key={v.uid} version={v} selectedVersion={selectedComparedVersionIdentifier} setSelectedVersionIdentifier={setSelectedComparedVersionIdentifier} payrollPeriod={payrollPeriod}/>
        )
    })
    
    return (
        <LoadingWrapper isLoading={isLoading}>
            { payrollPeriod && 
                <div style={{flex: 1, padding: 12, display: 'flex', flexDirection: 'column', gap: 6, overflowY: 'auto'}}>
                    <h3 style={{margin: 0, textAlign: 'center'}}>{`${formatDateRange(payrollPeriod.periodStart, payrollPeriod.periodEnd)} Payroll Period`}</h3>
                    <h6 style={{margin: 0, textAlign: 'center', opacity: 0.5, fontWeight: 'bold'}}>{payrollPeriod.companyName}</h6>
                    <div style={{display: 'flex', gap: 6, flexWrap: 'wrap', justifyContent: 'center'}}>
                        { !!payrollPeriod.isEditable && 
                            <Button variant="outline-primary" style={{fontWeight: 'bold'}} onClick={() => {history.push(`edit/${payrollPeriod.uid}`)}}>Review Payroll Period</Button>
                        }
                        { isAdmin && !payrollPeriod.isCustom && 
                            <Button variant="outline-warning" onClick={() => {setModalSwitch('createCustom')}}>Create Custom Payroll</Button>
                        }
                        { isAdmin && !!payrollPeriod.isCustom && 
                            <Button variant="outline-danger" onClick={() => {setModalSwitch('deleteCustom')}}>Delete Custom Payroll</Button>
                        }
                    </div>
                    <hr style={{marginBottom: 6, marginTop: 6}}/>
                    { payrollVersions.length > 1 && 
                        <Dropdown>
                            <Dropdown.Toggle variant='outline-primary'>Selected Payroll Version</Dropdown.Toggle>
                            <Dropdown.Menu style={{maxWidth: 300, maxHeight: 300, overflowY: 'auto', overflowX: 'hidden'}}>
                                {versionElements}
                            </Dropdown.Menu>
                        </Dropdown>
                    }
                    { selectedVersion && 
                        <PayrollPeriodVersion version={selectedVersion} comparedVersion={selectedComparedVersion} handleSetVersion={handleSetVersion} payrollPeriod={payrollPeriod} isAdmin={isAdmin} loadData={loadData}/>
                    }
                    { payrollVersions.length > 1 && 
                        <>
                            <hr style={{marginBottom: 6, marginTop: 6}}/>
                            <Dropdown>
                                <Dropdown.Toggle variant='outline-primary'>Payroll Version To Compare</Dropdown.Toggle>
                                <Dropdown.Menu style={{maxWidth: 300, maxHeight: 300, overflowY: 'auto'}}>
                                    {comparedVersionElements}
                                </Dropdown.Menu>
                            </Dropdown>
                            { selectedComparedVersion && 
                                <PayrollPeriodVersion version={selectedComparedVersion} comparedVersion={selectedVersion} handleSetVersion={handleSetVersion} payrollPeriod={payrollPeriod} isAdmin={isAdmin} loadData={loadData}/>
                            }
                        </>
                    }

                    
                    <Modal show={modalSwitch == 'createCustom'} onHide={hideModal} fullscreen='lg-down' size='lg'>
                        <CreateCustomPayrollModal payrollPeriod={payrollPeriod} callback={(uid) => {
                            hideModal();
                            history.push(`/payroll/${uid}`);
                            loadData(uid);
                        }}/>
                    </Modal>
                    <Modal show={modalSwitch == 'deleteCustom'} onHide={hideModal} fullscreen='lg-down' size='lg'>
                        <DeleteCustomPayrollModal payrollPeriod={payrollPeriod} callback={() => {
                            hideModal();
                            history.push(`/`);
                        }}/>
                    </Modal>
                </div>
            }
        </LoadingWrapper>
    );
    
}


function PayrollPeriodVersion({version, comparedVersion, handleSetVersion, payrollPeriod, isAdmin, loadData}) {
    const [isLoading, setIsLoading] = useState(false);
    const [modalSwitch, setModalSwitch, hideModal] = useModalSwitch();

    function downloadCsv() {
        const str = PayrollPeriodCsv(version.entries, payrollPeriod);
        const blob = new Blob([str], { type: 'text/csv;charset=utf-8,' });
        const elem = window.document.createElement('a');
        elem.href = window.URL.createObjectURL(blob);
        elem.download = payrollPeriod.companyName + ' ' + formatDateRange(payrollPeriod.periodStart, payrollPeriod.periodEnd) + ' Payroll.csv';
        document.body.appendChild(elem);
        elem.click();
        document.body.removeChild(elem);
    }

    useEffect(() => {
        if (!version.entries) {
            new ApiRequest('payroll', 'getVersion', setIsLoading, (response) => {
                handleSetVersion(response.payrollVersion);
            }).withData({payrollIdentifier: version.payrollIdentifier, payrollVersionIdentifier: version.uid}).withNoAlertOnSuccess().send()
        }
    }, [version]);

    const canSendBackToCompany = isAdmin && payrollPeriod.versions.some(v => v.adminIdentifier);

    const canSendBackToBc = 
        !!payrollPeriod.isEditable 
        && !!payrollPeriod.bcPayrollEnabled 
        && (!payrollPeriod['terminalIdentifier'] || !payrollPeriod['restrictBcPayrollToTerminals']) 
        && payrollPeriod.versions.some(v => !v.isBc)
    ;

    const canDelete = isAdmin && new Cookies().get('permissions').includes('superDuperAdministrator');

    return (
        <Card>
            <PayrollVersionHeader version={version} payrollPeriod={payrollPeriod}/>
            <Card.Body style={{padding: 6}}>
                <LoadingWrapper isLoading={isLoading} height={200}>
                    { version.entries && 
                        <PayrollPeriodTable entries={version.entries} originalEntries={comparedVersion?.entries}/>
                    }
                </LoadingWrapper>
            </Card.Body>
            { version.entries && 
                <Card.Footer style={{display: 'flex', gap: 6, flexWrap: 'wrap', justifyContent: 'flex-end', padding: 6}}>
                    { canSendBackToCompany && 
                        <Button variant="outline-primary" style={{padding: '0px 6px', fontSize: 14}} onClick={() => {setModalSwitch('sendBackToCompany')}}>Send Back to Company</Button>
                    }
                    { canSendBackToBc && 
                        <Button variant="outline-primary" style={{padding: '0px 6px', fontSize: 14}} onClick={() => {setModalSwitch('sendBackToBc')}}>Send Back to BC</Button>
                    }
                    <Button variant="outline-primary" style={{padding: '0px 6px', fontSize: 14}} onClick={downloadCsv}>Download Spreadsheet</Button>
                    <Button variant="outline-primary" style={{padding: '0px 6px', fontSize: 14}} onClick={() => {setModalSwitch('pdf')}}>Download PDF</Button>
                    { canDelete &&
                        <Button variant="outline-danger" style={{padding: '0px 6px', fontSize: 14}} onClick={() => {setModalSwitch('delete')}}>Delete Version</Button>
                    }
                </Card.Footer>
            }
            <Modal show={modalSwitch == 'pdf'} onHide={hideModal} fullscreen>
                <PayrollPeriodPdf 
                    entries={version?.entries}
                    originalEntries={comparedVersion?.entries}
                    payrollPeriod={payrollPeriod}
                />
            </Modal>
            <Modal show={modalSwitch == 'sendBackToBc'} onHide={hideModal}>
                <SendBackToBcModal payrollVersion={version} payrollPeriod={payrollPeriod} callback={() => {hideModal(); loadData();}}/>
            </Modal>
            <Modal show={modalSwitch == 'sendBackToCompany'} onHide={hideModal}>
                <SendBackToCompanyModal payrollVersion={version} payrollPeriod={payrollPeriod} callback={() => {hideModal(); loadData();}}/>
            </Modal>
            <Modal show={modalSwitch == 'delete'} onHide={hideModal}>
                <DeletePayrollVersionModal payrollVersion={version} payrollPeriod={payrollPeriod} callback={() => {hideModal(); loadData();}}/>
            </Modal>
        </Card>
    )
}

function PayrollVersionDropdownItem({version, selectedVersion, setSelectedVersionIdentifier, payrollPeriod}) {
    const type = getPayrollVersionType(version, payrollPeriod);

    return (
        <Dropdown.Item active={selectedVersion == version.uid} onClick={() => {setSelectedVersionIdentifier(version.uid)}} style={{padding: '4px 8px', display: 'flex', justifyContent: 'space-between', gap: 6, overflowX: 'auto'}}>
            <div style={{display:'flex', flexDirection: 'column'}}>
                <span>
                    {
                        version.companyUserIdentifier ? `${version.user.firstName} ${version.user.middleName ? `${version.user.middleName.substring(0, 1)}. ` : ''}${version.user.lastName}` 
                        : version.adminIdentifier ? 'TTA Admin'
                        : 'TTA Linehaul'
                    }
                </span>
                <span style={{fontSize: 12}}>{payrollPeriod.terminalName ?? version.terminalName ?? 'All Terminals'}</span>
            </div>
            <div style={{display:'flex', flexDirection: 'column', textAlign: 'end'}}>
                <span style={{whiteSpace: 'nowrap'}}>{type}</span>
                <span style={{fontSize: 12}}>{moment(version.dateTime).format('MMM D [at] h:mm:ss A')}</span>
            </div>
        </Dropdown.Item>
    )
}

function PayrollVersionHeader({version, payrollPeriod}) {
    const type = getPayrollVersionType(version, payrollPeriod);

    return (
        <Card.Header>
            <div style={{display:'flex', justifyContent:'space-between', gap: 12}}>
                <span>
                    {
                        version.companyUserIdentifier ? `${version.user.firstName} ${version.user.middleName ? `${version.user.middleName.substring(0, 1)}. ` : ''}${version.user.lastName}` 
                        : version.adminIdentifier ? 'TTA Admin'
                        : 'TTA Linehaul'
                    }
                </span>
                <span style={{whiteSpace: 'nowrap'}}>{type}</span>
            </div>
            <div style={{display:'flex', justifyContent:'space-between', gap: 12, fontSize: 12}}>
                <span>{payrollPeriod.terminalName ?? version.terminalName ?? 'All Terminals'}</span>
                <span>{moment(version.dateTime).format('MMM D [at] h:mm:ss A')}</span>
            </div>
        </Card.Header>
    )
}