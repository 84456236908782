

/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import Decoder from "../../../../decoding";
import Address from "../../../../models/Address";
import StateObject from "../../../../state/StateObject.tsx";
import { validateDecimal, validateDecimalFixed, validateInteger } from "../../../../tools.js";

export default class Terminal extends StateObject{
    uid;
    companyIdentifier;
    name;
    address;
    defaultLoadTime; // In Minutes
    clockInRadius;

    constructor(
        uid,
        companyIdentifier,
        name,
        address,
        defaultLoadTime,
        clockInRadius,
    ){
        super();
        this.uid = uid;
        this.companyIdentifier = companyIdentifier;
        this.name = name;
        this.address = address;
        this.defaultLoadTime = defaultLoadTime;
        this.clockInRadius = clockInRadius;
        this.initChildren();
    }

    static initDefault(){
        return new Terminal(
            -1,
            -1,
            '',
            undefined,
            '',
            ''
        )
    }

    static decode(json){
        const decoder = new Decoder(json);
        const uid = decoder.decode('uid', Decoder.Uid);
        const companyIdentifier = decoder.decode('companyIdentifier', Decoder.Uid);
        const name = decoder.decode('name', Decoder.String);
        const address = Address.decode(json.address);
        const defaultLoadTime = decoder.decode('defaultLoadTime', Decoder.Integer);
        const clockInRadius = decoder.decode('clockInRadius', Decoder.Decimal)

        if(decoder.checkForErrors()){
            return new Terminal(uid, companyIdentifier, name, address, defaultLoadTime, clockInRadius)
        } else {
            return Terminal.initDefault()
        }
    }

    encode(){
        return {
            uid: this.uid,
            companyIdentifier: this.companyIdentifier,
            name: this.name,
            address: this.address?.encode(),
            defaultLoadTime: validateInteger(this.defaultLoadTime),
            clockInRadius: validateDecimalFixed(this.clockInRadius, 4),
        }
    }
}