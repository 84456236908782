/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, { useEffect, useState } from 'react';
import moment from 'moment';
import ListGroup from 'react-bootstrap/ListGroup';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import FileDownloadButton from '../../../../components/FileDownloadButton';
import HRUserBWCVersionHeader from './HRUserBWCVersionHeader';
import { ApiRequest } from '../../../../ApiManager.tsx';
import LoadingWrapper from '../../../../components/LoadingWrapper.js';
import KeyValueRow from '../../../../components/KeyValueRow.js';
import { addressToString } from '../../../../tools.js';
import FileUploadControl from '../../../../components/FileUploadControl.js';
import CustomButton from '../../../../components/CustomButton.js';
import QuickTable from '../../../../components/QuickTable.js';
import Cookies from 'universal-cookie';

export default function HRUserBWCDetails({users, bwcRecord, reviseRecord}){
    const [isLoading, setIsLoading] = useState(true);
    const [isUploading, setIsUploading] = useState(false);
    const [versions, setVersions] = useState([bwcRecord.latestVersion]);
    const [selectedVersion, setSelectedVersion] = useState(bwcRecord.latestVersion);
    const [attachments, setAttachments] = useState([]);
    const [newAttachment, setNewAttachment] = useState({base64: null});

    useEffect(()=>{
        loadData();
    }, [])

    function loadData(){
        new ApiRequest('hr', 'getBwcRecord', setIsLoading, (response) => {
            setVersions(response.bwc.versions); 
            setSelectedVersion(response.bwc.versions[0]); 
            setAttachments(response.bwc.attachments);
        }).withUid(bwcRecord.uid).withNoAlertOnSuccess().send();
    }

    function handleUploadBwcRecordAttachment() {
    
        new ApiRequest('hr', 'uploadBwcRecordAttachment', setIsUploading, (response) => {
            const userData = new Cookies().get('userData');
            const auth = new Cookies().get('auth');
            const attachmentToSave = {
                fileIdentifier: response.fileIdentifier,
                bwcRecordIdentifier: bwcRecord.uid,
                companyUserIdentifier: bwcRecord.companyUserIdentifier,
                submissionDateTime: moment().format('YYYY-MM-DD HH:mm:ss'),
                name: newAttachment.name,
                type: newAttachment.type,
                author: userData.companyUserIdentifier ? userData : null,
                companyUserIdentifier: userData.companyUserIdentifier,
                adminIdentifier: userData.adminIdentifier,
            }
            setAttachments((prevState) => [...prevState, attachmentToSave]);
            setNewAttachment({base64: null});
        }).withData({attachment: newAttachment, bwcRecordIdentifier: bwcRecord.uid}).send()
    }
    
    function getNumberOfDaysMissed(bwc){
        let daysOffWork = 0;
        if(!bwc.rtwLightDutyDate && !bwc.rtwFullDutyDate){
                daysOffWork = moment().diff(moment(bwc.injuryReportDate),'days');
        } else if (bwc.rtwLightDutyDate){
                daysOffWork = moment(bwc.rtwLightDutyDate).diff(moment(bwc.injuryReportDate),'days');
        } else if(!bwc.rtwLightDutyDate && bwc.rtwFullDutyDate){
                daysOffWork = moment(bwc.rtwFullDutyDate).diff(moment(bwc.injuryReportDate),'days');
        }
        return daysOffWork > 0 ? daysOffWork : 0;
    }

    const reportedTo = selectedVersion ? users.find(u => u.companyUserIdentifier == selectedVersion.injuryReportedTo): undefined;
    const attachmentButtons = !selectedVersion || !selectedVersion?.attachments ? '' : JSON.parse(selectedVersion.attachments).map((attachment, index) => {
        return (
            <ListGroup.Item key={attachment}>
                <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                    <span>{'Custom Attachment #' + (index + 1)}</span>
                    <FileDownloadButton value={attachment} type='hr' target='downloadCustomDocument'/>
                </div>
            </ListGroup.Item>
        )
    });

    ///////////////
    /// DESKTOP
    ///////////////
    const attachmentRows = attachments.map(a => {
        return (
            <tr key={a.fileIdentifier}>
                <td style={{fontSize: 16}}>{a.name}</td>
                <td style={{fontSize: 16}}>{a.author ? `${a.author.firstName} ${a.author.lastName}` : a.adminIdentifier ? 'TTA Admin' : ''}</td>
                <td style={{fontSize: 16}}>{moment(a.submissionDateTime).format('MMM D, YYYY [at] HH:mm LT')}</td>
                <td style={{textAlign: 'center'}}>
                    <FileDownloadButton doc={a} type='hr' target='downloadBwcAttachment' data={{fileIdentifier: a.fileIdentifier}}/>
                </td>
            </tr>
        )
    })

    ///////////////
    /// MOBILE
    ///////////////
    const mobileAttachmentRows = attachments.map(a => {
        return (
            <ListGroup.Item key={a.fileIdentifier} style={{display: 'flex', justifyContent: 'space-between', gap: 8, flexWrap: 'wrap'}}>
                <div>
                    <h6>{a.name}</h6>
                    <h6 style={{opacity: 0.5}}>Uploaded by {a.author ? `${a.author.firstName} ${a.author.lastName}` : a.adminIdentifier ? 'TTA Admin' : ''}</h6>
                    <h6>{moment(a.submissionDateTime).format('MMM D, YYYY [at] LT')}</h6>
                </div>
                <FileDownloadButton doc={a} type='hr' target='downloadBwcAttachment' data={{fileIdentifier: a.fileIdentifier}} style={{display: 'block', marginLeft: 'auto'}}/>
            </ListGroup.Item>
        )
    })

    const content = !selectedVersion ? '' : (
        <>
            <Modal.Header closeButton>
                <Modal.Title>BWC Record</Modal.Title>
            </Modal.Header>
            <HRUserBWCVersionHeader 
                versions={versions} 
                selectedVersion={selectedVersion} 
                setSelectedVersion={(value) => setSelectedVersion(value)} 
                isLoading={isLoading}
            />
            <Modal.Body>
                <ListGroup>
                    <KeyValueRow isListItem title={'Date of Injury'} value={selectedVersion.dateOfInjury ? moment(selectedVersion.dateOfInjury).format('MMM D, YYYY') : ''}/>
                    <KeyValueRow isListItem title={'Injury Reported to'} value={reportedTo ? reportedTo.firstName + ' ' + reportedTo.lastName : ''}/>
                    <KeyValueRow isListItem title={'Date Reported'} value={selectedVersion.injuryReportDate ? moment(selectedVersion.injuryReportDate).format('MMM D, YYYY') : ''}/>
                    { bwcRecord.driverStatement && 
                        <KeyValueRow isListItem title={'Driver Statement'} value={bwcRecord.driverStatement}/>
                    }
                    <KeyValueRow isListItem title={'Treatment Facility Name'} value={selectedVersion.treatmentFacilityName}/>
                    <KeyValueRow isListItem title={'Treatment Facility Address'} value={addressToString(selectedVersion.treatmentFacilityAddress)}/>
                    <KeyValueRow isListItem title={'Date & Time Admitted to Facility'} value={selectedVersion.treatmentFacilityAdmittedDateTime ? moment(selectedVersion.treatmentFacilityAdmittedDateTime).format('MMM D, YYYY [at] h:mm A') : ''}/>
                    <KeyValueRow isListItem title={'Salary Continuation?'} value={selectedVersion.salaryContinuation ? 'Yes' : 'No'}/>
                    <KeyValueRow isListItem title={'Date Returned to Work for Light Duty'} value={selectedVersion.rtwLightDutyDate ? moment(selectedVersion.rtwLightDutyDate).format('MMM D, YYYY') : ''}/>
                    <ListGroup.Item>
                        <p>Description of Light Duty</p>
                        <p style={{marginBottom: 0, opacity: 0.5}}>{selectedVersion.lightDutyDescription}</p>
                    </ListGroup.Item>
                    <KeyValueRow isListItem title={'Date Returned to Work for Full Duty'} value={selectedVersion.rtwFullDutyDate ? moment(selectedVersion.rtwFullDutyDate).format('MMM D, YYYY') : ''}/>
                    <KeyValueRow isListItem title={'Number of Days Missed'} value={getNumberOfDaysMissed(selectedVersion)}/>
                    <KeyValueRow isListItem title={'BWC Injury Claim #'} value={selectedVersion.bwcInjuryClaimNumber}/>
                    <KeyValueRow isListItem title={'Event Location'} value={selectedVersion.eventLocation}/>
                    <KeyValueRow isListItem title={'Injury or Illness'} value={selectedVersion.injuryOrIllness}/>
                    <KeyValueRow isListItem title={'Classify Case'} value={selectedVersion.classifyCase}/>
                    <ListGroup.Item>
                        <p>Injury / Illness Description</p>
                        <p style={{marginBottom: 0, opacity: 0.5}}>{selectedVersion.notes}</p>
                    </ListGroup.Item>
                    {attachmentButtons}
                </ListGroup>
                <Button variant={'outline-primary'} style={{width: '100%', marginTop: 12}} onClick={reviseRecord}>Revise Record</Button>
                <hr/>
                <h4 style={{textAlign: 'center'}}>Attachments</h4>
                <div className='desktop-block'>
                    <QuickTable noMargin size='sm' headers={['Name', 'Uploaded By', 'Submission Date', '']} rows={attachmentRows}/>
                </div>
                <div className='mobile-block'>
                    <ListGroup>
                        {mobileAttachmentRows}
                    </ListGroup>
                </div>
                <div style={{display: 'flex', gap: 12, marginTop: 12}}>
                    <FileUploadControl value={newAttachment} setValue={setNewAttachment} maxTitleLength={100}/>
                    <CustomButton isLoading={isUploading} disabled={!newAttachment.base64 || !newAttachment.name} onClick={handleUploadBwcRecordAttachment}>Upload</CustomButton>
                </div>
            </Modal.Body>
        </>
    )

    return (
       <LoadingWrapper isLoading={isLoading}>
            {content}
       </LoadingWrapper>
    )
}