/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, { useState } from "react";
import Modal from 'react-bootstrap/Modal';
import PayrollEmployeeWageItem from "../Pay/Wages/PayrollEmployeeWageItem";
import PayrollEntryPaySection from "../Pay/PayrollEntryPaySection";
import PayrollDBonusItem from "../Pay/DBonuses/PayrollDBonusItem";
import PayrollNDBonusItem from "../Pay/NDBonuses/PayrollNDBonusItem";
import PayrollAdditionalPayItem from "../Pay/AdditionalPay/PayrollAdditionalPayItem";
import PayrollHolidayItem from "../Pay/Holidays/PayrollHolidayItem";
import PayrollPtoItem from "../Pay/Pto/PayrollPtoItem";
import { Button, ButtonGroup, Container, Row } from "react-bootstrap";
import { toFixedMax, usdFormatter } from "../../../../../../tools";
import PayrollMoneyControl from "../../PayrollMoneyControl";
import { bigToUsd } from "../../../payrollTools";
import PayrollAdditionalDeductionItem from "../Deductions/AdditionalDeductions/PayrollAdditionalDeductionItem";
import PayrollLoanItem from "../Deductions/Loans/PayrollLoanItem";
import QuickTable from "../../../../../../components/QuickTable";
import PayrollOvertimeEditor from "../Pay/Overtime/PayrollOvertimeEditor";

export default function PreviousEntryModal({entry}) {
    const [weekIndex, setWeekIndex] = useState(0);

    const weekTabButtons = entry.weeks.map((_, i) => {
        const buttonStyle = weekIndex == i ? {paddingLeft: 0, paddingRight: 0, backgroundColor: 'var(--bs-primary)', color: 'white'} : {paddingLeft: 0, paddingRight: 0};
        return (
            <Button variant="outline-primary" key={i} onClick={() => {setWeekIndex(i)}} style={buttonStyle}>
                {`Week ${i + 1}`}
            </Button>
        )
    })

    const wageElements = entry.weeks[weekIndex].pay.map((pay) => {
        return (<PayrollEmployeeWageItem key={pay.uid} entry={entry} pay={pay}/>);
    });

    

    const ndBonusElements = entry.weeks[weekIndex].ndBonuses.map((nd) => {
        return (<PayrollNDBonusItem key={nd.uid} nd={nd}/>);
    });

    const diffRows = [];

    if (!!entry.weeks[entry.weekIndex].nighttimeBonus && entry.weeks[entry.weekIndex].nighttimeHoursWorked > 0) {
        diffRows.push(
            <tr key={'nd'} style={{whiteSpace: 'nowrap'}}>
                <td style={{fontWeight: 'bold'}}>Night Differential</td>
                <td>{usdFormatter.format(entry.weeks[entry.weekIndex].nighttimeBonus ?? 0.0)}</td>
                <td>{toFixedMax(entry.weeks[entry.weekIndex].nighttimeHoursWorked, 3)}</td>
                <td style={{color: entry.weeks[entry.weekIndex].nighttimeBonusEnabled ? 'green' : 'gray'}}>{bigToUsd(entry.weeks[entry.weekIndex].nighttimeDiffWages())}</td>
                <td>
                    {entry.weeks[entry.weekIndex].nighttimeBonusEnabled ? 'Yes' : 'No'}
                </td>
            </tr>
        )
    }

    if (!!entry.weeks[entry.weekIndex].weekendBonus && entry.weeks[entry.weekIndex].weekendHoursWorked > 0) {
        diffRows.push(
            <tr key={'wd'} style={{whiteSpace: 'nowrap'}}>
                <td style={{fontWeight: 'bold'}}>Weekend Differential</td>
                <td>{usdFormatter.format(entry.weeks[entry.weekIndex].weekendBonus ?? 0.0)}</td>
                <td>{toFixedMax(entry.weeks[entry.weekIndex].weekendHoursWorked, 3)}</td>
                <td style={{color: entry.weeks[entry.weekIndex].weekendBonusEnabled ? 'green' : 'gray'}}>{bigToUsd(entry.weeks[entry.weekIndex].weekendDiffWages())}</td>
                <td>
                    {entry.weeks[entry.weekIndex].weekendBonusEnabled ? 'Yes' : 'No'}
                </td>
            </tr>
        )
    }

    const additionalPayElements = entry.weeks[weekIndex].additionalPay.map((ap) => {
        return (<PayrollAdditionalPayItem key={ap.uid} ap={ap}/>);
    });

    const holidayElements = entry.weeks[weekIndex].holidays.map((holiday) => {
        return (<PayrollHolidayItem key={holiday.uid} holiday={holiday}/>);
    });

    const dBonusElements = entry.weeks[weekIndex].dBonuses.map((dBonus) => {
        return (<PayrollDBonusItem key={dBonus.uid} dBonus={dBonus}/>);
    });

    const ptoElements = entry.weeks[weekIndex].pto.map((pto) => {
        return (<PayrollPtoItem key={pto.ptoIdentifier} pto={pto}/>);
    });


    const paySection = (
        <div style={{padding: 6, display: 'flex', flexDirection: 'column', gap: 6, background: 'repeating-linear-gradient( -45deg, white 0px, white 5px, rgba(0, 150, 0, 0.05) 6px, rgba(0, 150, 0, 0.05) 11px, white 12px)'}}>
            <div style={{display: 'flex', gap: 12, alignItems: 'center'}}>
                <span style={{fontSize: 25, fontWeight: 'bold'}}>Pay</span>
                <ButtonGroup style={{flex: 1}}>{weekTabButtons}</ButtonGroup>
            </div>
            <div style={{display: 'flex', justifyContent: 'space-evenly', fontWeight: 'bold', flexWrap: 'wrap', backgroundColor: 'lightgray', borderRadius: 6}}>
                <span>{`Days Worked: ${entry.weeks[weekIndex].daysWorked}`}</span>
                <span>{`Hours Worked: ${toFixedMax(entry.weeks[weekIndex].hoursWorked, 3)}`}</span>
            </div>
            { wageElements.length > 0 && 
                <PayrollEntryPaySection title='WAGES'>
                    <div style={{display: 'flex', flexDirection: 'column', gap: 6}}>
                        <div style={{overflowX: 'auto'}}>
                            <QuickTable size='sm' responsive={false} headers={['Pay Rate', 'Pay Type', 'Units Worked', 'Subgross', '']} noWrap noMargin widths={[null, 1, null, 1, 1]} rows={wageElements}/>
                        </div>
                        <div style={{display: 'flex', justifyContent: 'flex-end', fontWeight: 'bold', gap: 6}}>
                            <span>Total:</span>
                            <span style={{color: 'green'}}>{usdFormatter.format(entry.weeks[weekIndex].getTotalPayWages())}</span>
                        </div>
                    </div>
                </PayrollEntryPaySection>
            }
            { ndBonusElements.length > 0 && 
                <PayrollEntryPaySection title='NONDISCRETIONARY BONUSES'>
                    <div style={{display: 'flex', flexDirection: 'column', gap: 6}}>
                        <div style={{overflowX: 'auto'}}>
                            <QuickTable noMargin noWrap size='sm' rows={ndBonusElements} headers={['Type', 'Amount', 'Reoccurring', '']} widths={[1, null, 1, 1]}/>
                        </div>
                        <div style={{overflowX: 'auto'}}>
                            <QuickTable noMargin noWrap size='sm' rows={diffRows} headers={['Type', 'Pay Rate', 'Hours Worked', 'Wages', 'Enabled']} widths={[1, null, null, 1, 1]}/>
                        </div>
                        <div style={{display: 'flex', justifyContent: 'flex-end', fontWeight: 'bold', gap: 6}}>
                            <span>Total:</span>
                            <span style={{color: 'green'}}>{usdFormatter.format(entry.weeks[weekIndex].getNDBonuses().toNumber())}</span>
                        </div>
                    </div>
                </PayrollEntryPaySection>
            }
            { additionalPayElements.length > 0 && 
                <PayrollEntryPaySection title='ADDITIONAL PAY'>
                    <div style={{display: 'flex', flexDirection: 'column', gap: 6}}>
                        <div style={{overflowX: 'auto'}}>
                            <QuickTable noMargin noWrap size='sm' rows={additionalPayElements} headers={['Type', 'Amount', 'Reoccurring', '']} widths={[1, null, 1, 1]}/>
                        </div>
                        <div style={{display: 'flex', justifyContent: 'flex-end', fontWeight: 'bold', gap: 6}}>
                            <span>Total:</span>
                            <span style={{color: 'green'}}>{usdFormatter.format(entry.weeks[weekIndex].getAdditionalPay().toNumber())}</span>
                        </div>
                    </div>
                </PayrollEntryPaySection>
            }
            { holidayElements.length > 0 && 
                <PayrollEntryPaySection title='HOLIDAYS'>
                    <div style={{display: 'flex', flexDirection: 'column', gap: 6}}>
                        <div style={{overflowX: 'auto'}}>
                            <QuickTable noMargin noWrap size='sm' rows={holidayElements} headers={['Pay Rate', 'Pay Type', 'Units Worked', 'Wages', '']} widths={[null, 1, null, 1, 1]}/>
                        </div>
                        <div style={{display: 'flex', justifyContent: 'flex-end', fontWeight: 'bold', gap: 6}}>
                            <span>Total:</span>
                            <span style={{color: 'green'}}>{bigToUsd(entry.weeks[weekIndex].totalHolidayWages())}</span>
                        </div>
                    </div>
                </PayrollEntryPaySection>
            }
            { dBonusElements.length > 0 && 
                <PayrollEntryPaySection title='DISCRETIONARY BONUSES'>
                    <div style={{display: 'flex', flexDirection: 'column', gap: 6}}>
                        <div style={{overflowX: 'auto'}}>
                            <QuickTable noMargin noWrap size='sm' rows={dBonusElements} headers={['Type', 'Amount', '']} widths={[1, null, 1]}/>
                        </div>
                        <div style={{display: 'flex', justifyContent: 'flex-end', fontWeight: 'bold', gap: 6}}>
                            <span>Total:</span>
                            <span style={{color: 'green'}}>{bigToUsd(entry.weeks[weekIndex].totalDBonuses())}</span>
                        </div>
                    </div>
                </PayrollEntryPaySection>
            }
            { ptoElements.length > 0 && 
                <PayrollEntryPaySection title='PTO'>
                    <div style={{overflowX: 'auto'}}>
                        <QuickTable noMargin noWrap size='sm' rows={ptoElements} headers={['Date', 'Pay Rate', 'Hours', 'Wages']} widths={[1, null, null, 1]}/>
                    </div>
                </PayrollEntryPaySection>
            }
            { entry.weeks[weekIndex].qualifiesForFLSA() && 
                <PayrollOvertimeEditor entry={entry} disabled/>
            }
            <div style={{display: 'flex', justifyContent: 'center', gap: 6, fontWeight: 'bold', fontSize: 18}}>
                <span>{`Sub Gross for Week ${weekIndex + 1}:`}</span>
                <span style={{color: 'green', }}>{bigToUsd(entry.weeks[weekIndex].getSubGross())}</span>
            </div>
        </div>
    )

    const childSupportRows = entry.childSupport.map((value, index) => {
        return (
            <tr key={index}>
                <td>
                    <PayrollMoneyControl lhDecimalPlaces={4} value={value} disabled/>
                </td>
            </tr>
        )
    });

    const additionalDeductionElements = entry.deductions.map((deduction) => {
        return (<PayrollAdditionalDeductionItem key={deduction.uid} deduction={deduction}/>);
    });

    const loanElements = entry.loans.map((loan) => {
        return (<PayrollLoanItem key={loan.loanIdentifier} loan={loan}/>);
    });

    const deductionsSection = (
        <div style={{padding: 6, background: 'repeating-linear-gradient( -45deg, white 0px, white 5px, rgba(150, 0, 0, 0.05) 6px, rgba(150, 0, 0, 0.05) 11px, white 12px)'}}>
            <span style={{fontSize: 25, fontWeight: 'bold'}}>Deductions</span>
            <Container fluid>
                <Row>
                    <PayrollMoneyControl breakpoints={{md: 4}} title='Medical Insurance' value={entry.medical} disabled/>
                    <PayrollMoneyControl breakpoints={{md: 4}} title='Dental Insurance' value={entry.dental} disabled/>
                    <PayrollMoneyControl breakpoints={{md: 4}} title='Vision Insurance' value={entry.vision} disabled/>
                </Row>
            </Container>
            { entry.childSupport.length > 0 && 
                <PayrollEntryPaySection title='CHILD SUPPORT'>
                    <div style={{display: 'flex', flexDirection: 'column', gap: 6}}>
                        <div style={{overflowX: 'auto'}}>
                            <QuickTable noMargin noWrap size='sm' rows={childSupportRows} headers={['Amount', '']} widths={[null, 1]}/>
                        </div>
                        <div style={{display: 'flex', justifyContent: 'flex-end', fontWeight: 'bold', gap: 6}}>
                            <span>Total:</span>
                            <span style={{color: 'red'}}>{usdFormatter.format(entry.totalChildSupport())}</span>
                        </div>
                    </div>
                </PayrollEntryPaySection>
            }
            { additionalDeductionElements.length > 0 && 
                <PayrollEntryPaySection title='ADDITIONAL DEDUCTIONS'>
                    <div style={{overflowX: 'auto'}}>
                        <QuickTable noMargin noWrap size='sm' rows={additionalDeductionElements} headers={['Type', 'Amount', 'Reoccurring', '']} widths={[1, null, 1, 1]}/>
                    </div>
                </PayrollEntryPaySection>
            }
            { loanElements.length > 0 && 
                <PayrollEntryPaySection title='LOANS & FINE TICKET DAMAGE'>
                    <div style={{overflowX: 'auto'}}>
                        <QuickTable noMargin noWrap size='sm' rows={loanElements} headers={['Type', 'Name', 'Amount', 'Enabled']} widths={[1, null, 1, 1]}/>
                    </div>
                </PayrollEntryPaySection>
            }
        </div>
    )
       

    const notesSection = (
        <div style={{padding: 6, display: 'flex', flexDirection: 'column', gap: 6, background: 'repeating-linear-gradient( -45deg, white 0px, white 5px, rgba(0, 0, 0, 0.05) 6px, rgba(0, 0, 0, 0.05) 11px, white 12px)'}}>
            <span style={{fontSize: 25, fontWeight: 'bold'}}>Notes</span>
            <div style={{backgroundColor: 'white', borderRadius: 6, padding: 6}}>
                <span style={{whiteSpace: 'pre-line'}}>{entry.notes}</span>
            </div>
        </div>
    )
    

    return (
        <>
            <Modal.Header closeButton>
                <Modal.Title>{`Previous Payroll Entry for ${entry.firstName} ${entry.lastName}`}</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{display: 'flex', flexDirection: 'column', padding: 0}}>
                {paySection}
                <hr style={{margin: 0}}/>
                {deductionsSection}
                <hr style={{margin: 0}}/>
                {!!entry.notes && notesSection}
            </Modal.Body>
            <Modal.Footer style={{display: 'flex', justifyContent: 'space-between', fontSize: 20, fontWeight: 'bold'}}>
                <span>Gross Pay</span>
                <span style={{color: 'green', }}>{bigToUsd(entry.gross())}</span>
            </Modal.Footer>
        </>
    )
}