/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, { useEffect, useState } from "react";
import QuickTable from "../../../../components/QuickTable";
import CustomButton from "../../../../components/CustomButton.js";
import { ApiRequest } from "../../../../ApiManager.tsx";
import { Button, Dropdown, Form, InputGroup, Modal } from "react-bootstrap";
import { faX } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import LoadingWrapper from "../../../../components/LoadingWrapper.js";

export default function MyCompanyIncentiveWages({}) {
    const [isLoading, setIsLoading] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [incentiveWages, setIncentiveWages] = useState([]);

    const days = ['Saturday', 'Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'];

    useEffect(() => {
        loadData();
    }, []);

    function loadData(){
        new ApiRequest('myCompany', 'getIncentiveWages', setIsLoading, (response) => setIncentiveWages(response.incentiveWages)).withNoAlertOnSuccess().send()
    }

    function handleSubmit(){
        new ApiRequest('myCompany', 'updateIncentiveWages', setIsSubmitting).withData({incentiveWages: incentiveWages}).send()
    }

    function handleSetIncentiveWage(index, amount){
        const newArray = Array.from(incentiveWages);
        if (amount < 0) {
            amount = 0;
        } else if (amount > 999.99) {
            amount = 999.99;
        }
        newArray[index].amount = amount;
        setIncentiveWages(newArray);
    }

    function handleDeleteIncentiveWage(index){
        setIncentiveWages(incentiveWages.toSpliced(index, 1));
    }

    function handleAddIncentiveWage(dayIndex){
        const newArray = Array.from(incentiveWages);
        newArray.push({dayIndex: dayIndex, amount: ''});
        newArray.sort((a, b) => {return a.dayIndex < b.dayIndex ? -1 : a.dayIndex > b.dayIndex ? 1 : 0});
        setIncentiveWages(newArray);
    }

    const iwElements = incentiveWages.map((iw, index) => {
        const day = days[iw.dayIndex];

        return (
            <tr key={index}>
                <td style={{verticalAlign: 'middle'}}>{day}</td>
                <td>
                    <InputGroup>
                        <InputGroup.Text>$</InputGroup.Text>
                        <Form.Control type='number' placeholder="Enter Amount" value={iw.amount} onChange={(event) => {handleSetIncentiveWage(index, event.target.value)}}/>
                    </InputGroup>
                </td>
                <td>
                    <Button variant="outline-danger" onClick={() => {handleDeleteIncentiveWage(index)}}>
                        <FontAwesomeIcon icon={faX}/>
                    </Button>
                </td>
            </tr>
        )
    })


    const dayElements = days.map((day, index) => {
        const disabled = incentiveWages.map(iw => iw.dayIndex).includes(index);
        return (
            <Dropdown.Item key={day} disabled={disabled} onClick={() => {handleAddIncentiveWage(index)}}>{day}</Dropdown.Item>
        )
    })

    return (
        <>
            <Modal.Header closeButton>
                <Modal.Title>Custom Incentive Wages</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{display: 'flex', flexDirection: 'column', gap: 12}}>
                <LoadingWrapper isLoading={isLoading}>
                    <span style={{opacity: 0.66}}>Employees will automatically be rewarded for working on each of the specified days. </span>
                    <Dropdown style={{display: 'flex', justifyContent: 'flex-end'}}>
                        <Dropdown.Toggle variant="outline-primary">{'Add Incentive Wage'}</Dropdown.Toggle>
                        <Dropdown.Menu>{dayElements}</Dropdown.Menu>
                    </Dropdown>
                    <QuickTable headers={['Day', 'Amount', '']} rows={iwElements}/>
                </LoadingWrapper>
            </Modal.Body>
            <Modal.Footer>
                <CustomButton variant={'outline-primary'} disabled={isLoading} isLoading={isSubmitting} onClick={handleSubmit}>Save Changes</CustomButton>
            </Modal.Footer>
        </>
    )
}
