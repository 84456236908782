/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, { useState } from 'react';
import moment from 'moment';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import CustomControl from '../../../../components/CustomControl';

export default function HRHubDateSelectionModal({selectedDate, setSelectedDate}) {
    const [date, setDate] = useState(selectedDate);

    return (
        <>
            <Modal.Header closeButton>
                <Modal.Title>Change Date</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <CustomControl max={moment().format('YYYY-MM-DD')} title='Select A Date' type='date' value={date} setValue={setDate}/>
            </Modal.Body>
            <Modal.Footer>
                <Button variant={'outline-primary'} style={{marginBottom:10}} onClick={() => setSelectedDate(date)}>Submit</Button>
            </Modal.Footer>
        </>
    )
}