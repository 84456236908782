/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, {useState} from 'react';
import Modal from 'react-bootstrap/Modal';
import CustomButton from '../../../components/CustomButton.js';
import RadioControl from '../../../components/RadioControl.js';
import CustomControl from '../../../components/CustomControl.js';
import CustomTextArea from '../../../components/CustomStateControls/CustomTextArea.js';
import MultipleFileUploadControl from '../../../components/MultipleFileUploadControl.js';
import { Validation, useStateWithValidation, useValidationReducer } from '../../../validation.tsx';
import SwitchControl from '../../../components/SwitchControl.js';
import { useIdentifiableStateArray } from '../../../hooks.tsx';
import { ApiRequest } from '../../../ApiManager.tsx';

export default function HRTerminate({selectedEmployee}) {
    const [isLoading, setIsLoading] = useState(false);
    const [terminationType, setTerminationType, terminationTypeVM] = useStateWithValidation('', Validation.nonEmptyString);
    const [date, setDate, dateVM] = useStateWithValidation('', Validation.date);
    const [synopsis, setSynopsis, synopsisVM] = useStateWithValidation('', Validation.none);
    const [complaints, setComplaints, complaintsVM] = useStateWithValidation('', Validation.none);
    const [attachments, modifyAttachments] = useIdentifiableStateArray([], 'uid');
    const [deleteScheduleItems, setDeleteScheduleItems] = useState(true);

    const isValid = useValidationReducer([terminationTypeVM, dateVM, synopsisVM, complaintsVM]) && !attachments.find(a => !!a.base64 && !a.name);

    function handleTerminate(){
        const terminationToSend = {
            companyUserIdentifier: selectedEmployee.companyUserIdentifier,
            title: terminationType,
            date: date,
            terminationType: terminationType,
            synopsis: synopsis,
            complaints: complaints,
            attachments: attachments.filter((a) => !!a.base64).map((d) => {return {...d, companyUserIdentifier: selectedEmployee.companyUserIdentifier}}),
        }
        new ApiRequest('hr', 'terminateEmployee', setIsLoading, () => {
            window.location.reload(false)
        }).withData({termination: terminationToSend, deleteScheduleItems: deleteScheduleItems, userIdentifier: selectedEmployee.userIdentifier}).send()
    }

    return (
        <>
            <Modal.Header closeButton>
                <Modal.Title>{`Terminating ${selectedEmployee.firstName} ${selectedEmployee.lastName}`}</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{display: 'flex', flexDirection: 'column', gap: 6}}>
                <span>The employee will no longer show up in Scheduling or future payroll periods. The employee and all his or her data will not be deleted and will still be viewable in Human Resources.</span>
                <div style={{display: 'flex', flexWrap: 'wrap', margin: '16px 0', gap: 4}}>
                    <SwitchControl style={{marginRight: 24}} title='Delete Future Routes And Schedule Items' value={deleteScheduleItems} setValue={setDeleteScheduleItems} />
                </div>
                <CustomControl validate validationMessage={dateVM} title='Date Effective' type='date' disabled={isLoading} value={date} setValue={setDate}/>
                <RadioControl 
                    inline
                    title='Termination Type' 
                    validate
                    validationMessage={terminationTypeVM}
                    optionNames={['Resignation', 'Abandonment', 'Retirement', 'Terminate for Cause', 'Layoff', 'End of Contract']} 
                    optionValues={['resignation', 'abandonment', 'retirement', 'terminateForCause', 'layoff', 'endOfContract']} 
                    selection={terminationType} 
                    setSelection={setTerminationType} 
                    disabled={isLoading} 
                />  
                <CustomTextArea placeholder='Customer Complaints' disabled={isLoading} value={complaints} setValue={setComplaints} rows={5} max={300}/>
                <CustomTextArea placeholder='Termination Synopsis' disabled={isLoading} value={synopsis} setValue={setSynopsis} rows={5} max={300}/>
                <MultipleFileUploadControl value={attachments} modifyArray={modifyAttachments} maxTitleLength={100}/>     
            </Modal.Body>
            <Modal.Footer>
                <CustomButton isLoading={isLoading} disabled={!isValid} onClick={handleTerminate}>Terminate Employee</CustomButton>
            </Modal.Footer>
        </>
    )
}