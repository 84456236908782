/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import { validateDecimal, validateInteger } from '../tools';

export default function ValidationFeedback({stateProps, validation, value, maxLength, maxInt, maxDecimal, showValidation}) {

    const validationMessage = validation ? validation(value) : stateProps?.getValidationMessage();

    return ( 
        <div style={{position: 'absolute', fontSize: 11, bottom: 2, right: 0, display: 'flex', alignItems: 'center', justifyContent: 'flex-end', gap: 6, paddingRight: 10}}>
            { validationMessage && showValidation ? 
                    <span style={{color: 'var(--bs-form-invalid-color)'}}>{validationMessage}</span>
                : maxLength ?
                    <div>
                        <span>{stateProps?.getValue()?.length ?? value?.length ?? 0}</span>
                        <span>/</span>
                        <span>{maxLength}</span>
                    </div>
                : maxInt ?
                    <span style={{color: validateInteger(stateProps?.getValue() ?? value) == maxInt ? 'red' : 'black'}}>{`max: ${maxInt}`}</span>
                : maxDecimal ?
                    <span style={{color: validateDecimal(stateProps?.getValue() ?? value) == maxDecimal ? 'red' : 'black'}}>{`max: ${maxDecimal}`}</span>
                :
                    ''
            }
        </div>
        
    )
    
}