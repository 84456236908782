/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, { useEffect, useReducer, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import AvailabilityControl from '../../../components/AvailabilityControl';
import moment from 'moment';
import CustomButton from '../../../components/CustomButton';
import PasswordProtectedControl from '../../../components/PasswordProtectedControl';
import Cookies from 'universal-cookie';
import { ApiRequest } from '../../../ApiManager.tsx';
import LoadingWrapper from '../../../components/LoadingWrapper';
import { Modal, Button, Form } from 'react-bootstrap';
import User from '../../../models/User.js';
import CustomDropDown from '../../../components/CustomStateControls/CustomDropDown.js';
import ControlStateProps from '../../../state/ControlStateProps.tsx';
import { Validation } from '../../../validation.tsx';
import AddressAutoFill from '../../../components/CustomStateControls/AddressAutoFill.js';
import EmergencyContact from '../../../models/EmergencyContact.js';
import HRTerminate from './HRTerminate.js';
import HRHire from './HRHire/HRHire.js';
import CustomControl from '../../../components/CustomControl.js';
import StateObject from '../../../state/StateObject.tsx';
import { useStateObjectWithValidation } from '../../../hooks.tsx';
import { default as StatePropsCustomControl} from '../../../components/CustomStateControls/CustomControl.js';
import Address from '../../../models/Address.js';
import { encodeAvailability, parseAvailability } from '../../../tools.js';

export default function HRUserProfile({selectedEmployee, selectedCompany}) {
    const [isLoading, setIsLoading] = useState(false);
    const [isUpdating, setIsUpdating] = useState(false);
    const [terminals, setTerminals] = useState([]);
    const [userIsEditable, setUserIsEditable] = useState(false);
    const [effectiveTermination, setEffectiveTermination] = useState(undefined);
    const [modalSwitch, setModalSwitch] = useState('');
    const [formObject, setFormObject] = useState(new StateObject({address: undefined}))
    const [emergencyContacts, setEmergencyContacts] = useState([]);
    const cookieMonster = new Cookies();

    const [user, setUser, setUserKey, userVMs, userIsValid] = useStateObjectWithValidation({
        firstName: selectedEmployee?.firstName ?? '',
        middleName: selectedEmployee?.middleName ?? '',
        lastName: selectedEmployee?.lastName ?? '',
        email: selectedEmployee?.email ?? '',
        phoneNumber: selectedEmployee?.phoneNumber ?? '',
        dateOfBirth: '',
        ssn: '',
        availability: [false, false, false, false, false, false, false],
        terminalIdentifier: null,
        employeeId: '',
        dlExpiration: selectedEmployee?.dlExpiration ?? '',
        medCardExpiration: selectedEmployee?.medCardExpiration ?? '',
    },
    {
        firstName: Validation.nonEmptyString,
        lastName: Validation.nonEmptyString,
        email: Validation.email,
        phoneNumber: Validation.phoneNumber,
        dateOfBirth: Validation.date,
        ssn: Validation.ssn,
        availability: () => [],
        terminalIdentifier: Validation.notUndefined,
        employeeId: Validation.nonEmptyString,
        dlExpiration: Validation.none,
        medCardExpiration: Validation.none,
    });

    const [originalUser, setOriginalUser] = useState(user);

    useEffect(() => {
        loadData();
    }, [selectedEmployee])

    function loadData(){
        new ApiRequest('hr', 'getProfile', setIsLoading, (response)=>{
            response.user.ssn = '000000000';
            response.user.availability = parseAvailability(response.user.availability);
            setTerminals(response.terminals);
            setUser(response.user);
            setEmergencyContacts(response.user.emergencyContacts.map(contact => EmergencyContact.decode(contact)))
            setOriginalUser(response.user)
            setUserIsEditable(response.userIsEditable);
            setEffectiveTermination(response.effectiveTermination);
            formObject.setState('address', Address.decode(response.user.address))
        }).withData({companyUserIdentifier: selectedEmployee.companyUserIdentifier, companyIdentifier: selectedCompany}).withNoAlertOnSuccess().send()
    }

    function handleSaveChanges() {
        const userToSend ={
            ...user,
            dlExpiration: user.dlExpiration ?? null,
            medCardExpiration: user.medCardExpiration ?? null,
            ssn: user.ssn == '000000000' ? null : user.ssn,
            availability: encodeAvailability(user.availability),
            emergencyContacts: emergencyContacts.map(contact => contact.encode()),
        }
        const userObject = cookieMonster.get('userData');
        new ApiRequest('hr', 'updateProfile', setIsUpdating, ()=>{
            if(userObject.userIdentifier === user.userIdentifier){
                userObject.terminalIdentifier = user.terminalIdentifier;
                cookieMonster.set('userData', userObject);               
            }
            setOriginalUser(user);
        }).withData({user: userToSend}).send()
    }

    function handleAddEmergencyContact(){
        const newEmergencyContact = EmergencyContact.initDefault();
        newEmergencyContact.setState('userIdentifier', user.uid)
        setEmergencyContacts([...emergencyContacts, newEmergencyContact])
    }

    function handleSetEmergencyContactProp(uid, key, value) {
        const newArray = Array.from(emergencyContacts);
        const ec = newArray.find(e => e.uid == uid);
        if (!!ec) {
            ec[key] = value;
            setEmergencyContacts(newArray);
        }
    }

    function handleDeleteEmergencyContact(index){
        const newContacts = emergencyContacts.toSpliced(index, 1);
        setEmergencyContacts(newContacts)
    }

    const terminalItems = {};
    terminals.forEach(t=>{
        terminalItems[t.uid] = t.name;
    })

    const content = (
        <>
            <Container fluid style={{overflowY: 'auto'}}>
                <Row>
                    { cookieMonster.get('auth').companyUserIdentifier &&
                        <Col style={{marginBottom: 12}}>
                        { effectiveTermination ?
                            <div style={{display:'flex', gap:8, justifyContent: 'space-between', alignItems:'center'}}>
                                <h5 style={{fontWeight:'bold', margin: 0}}>{`Terminated ${moment(effectiveTermination.date).format('MMM D, YYYY')}`}</h5> 
                                <Button variant='outline-primary' onClick={() => setModalSwitch('rehire')}>Rehire Employee</Button>
                            </div>  
                        :
                            <div style={{display:'flex', justifyContent: 'flex-end'}}>
                                <Button variant='outline-primary' onClick={() => setModalSwitch('terminate')}>Terminate Employee</Button>
                            </div>  
                        }
                    </Col>
                    }
                </Row>
                <Row style={{padding: 8, border: '1px solid lightgray', borderRadius: 6, marginBottom: 8}}>
                    <Col lg={12}>
                        <p style={{fontWeight: 'bold', width: '100%', textAlign: 'center'}}>Employment Information</p>
                    </Col>
                    <AvailabilityControl 
                        lg={12}
                        disabled={effectiveTermination}
                        title='Availability' 
                        originalValue={originalUser.availability} 
                        inline 
                        value={user.availability} 
                        setValue={(value) => setUserKey('availability', value)}
                    />
                    <CustomDropDown breakpoints={{lg: 6, xl: 4}} originalValue={user.terminalIdentifier} selection={user.terminalIdentifier} setSelection={(value) => setUserKey('terminalIdentifier', value)} title='Assigned Terminal' items={terminalItems}/>
                    <CustomControl title='Employee Id' validate validationMessage={userVMs['employeeId']} breakpoints={{lg: 6, xl: 4}} max={30} disabled={effectiveTermination || !userIsEditable} value={user.employeeId} setValue={(value) => setUserKey('employeeId', value)} originalValue={originalUser.employeeId}/>
                    <CustomControl title='Start Date' breakpoints={{lg: 6, xl: 4}} type='date' max={30} disabled={effectiveTermination || !userIsEditable} value={user.startDate} setValue={(value) => setUserKey('startDate', value)} originalValue={originalUser.startDate}/>
                    <CustomControl title='Daily Start Time' breakpoints={{lg: 6, xl: 4}} type='time' max={30} disabled={effectiveTermination || !userIsEditable} value={user.dailyStartTime} setValue={(value) => setUserKey('dailyStartTime', value)} originalValue={originalUser.dailyStartTime}/>
                </Row>
                { !userIsEditable &&
                    <Row>
                        <Col lg={12}>
                            <p style={{width: '100%', textAlign: 'center'}}>Employee information below is not editable because the employee belongs to multiple companies</p>
                        </Col>
                    </Row>
                }
                <Row style={{padding: 8, border: '1px solid lightgray', borderRadius: 6, marginBottom: 8}} >
                    <Col lg={12}>
                        <p style={{fontWeight: 'bold', width: '100%', textAlign: 'center'}}>Personal Information</p>
                    </Col>
                    <CustomControl title='First Name' validate validationMessage={userVMs['firstName']} breakpoints={{lg: 4}} max={30} disabled={effectiveTermination || !userIsEditable} value={user.firstName} setValue={(value) => setUserKey('firstName', value)} originalValue={originalUser.firstName}/>
                    <CustomControl title='Middle Name' breakpoints={{lg: 4}} max={30} disabled={effectiveTermination || !userIsEditable} value={user.middleName} setValue={(value) => setUserKey('middleName', value)} originalValue={originalUser.middleName}/>
                    <CustomControl title='Last Name' validate validationMessage={userVMs['lastName']} breakpoints={{lg: 4}} max={30} disabled={effectiveTermination || !userIsEditable} value={user.lastName} setValue={(value) => setUserKey('lastName', value)} originalValue={originalUser.lastName}/>
                    <CustomControl title='Email Address' validate validationMessage={userVMs['email']} breakpoints={{lg: 4}} max={50} disabled={effectiveTermination || !userIsEditable} value={user.email} setValue={(value) => setUserKey('email', value)} originalValue={originalUser.email}/>
                    <CustomControl title='Phone Number' validate validationMessage={userVMs['phoneNumber']} breakpoints={{lg: 4}} max={10} clampAsText disabled={effectiveTermination || !userIsEditable} type='number' value={user.phoneNumber} setValue={(value) => setUserKey('phoneNumber', value)} originalValue={originalUser.phoneNumber}/>
                    <CustomControl title='Date of Birth' validate validationMessage={userVMs['dateOfBirth']} breakpoints={{lg: 4}} disabled={effectiveTermination || !userIsEditable} type='date' value={user.dateOfBirth} setValue={(value) => setUserKey('dateOfBirth', value)} originalValue={originalUser.dateOfBirth}/>
                    <CustomControl title={`Driver\'s License Expiration`} breakpoints={{lg: 4}} disabled={effectiveTermination || !userIsEditable} type='date' value={user.dlExpiration} setValue={(value) => setUserKey('dlExpiration', value)} originalValue={originalUser.dlExpiration}/>
                    <CustomControl title={`Medical Card Expiration`} breakpoints={{lg: 4}} disabled={effectiveTermination || !userIsEditable} type='date' value={user.medCardExpiration} setValue={(value) => setUserKey('medCardExpiration', value)} originalValue={originalUser.medCardExpiration}/>
                    <PasswordProtectedControl max={9} clampAsText breakpoints={{lg: 4}} companyUserIdentifier={selectedEmployee.companyUserIdentifier} user={user} originalUser={originalUser} setValue={(value) => setUserKey('ssn', value)} setOriginalValue={(value) => setOriginalUser((prevState) => {return{...prevState, ssn: value}})} disabled={effectiveTermination || !userIsEditable} title='Social Security Number' value={user.ssn} originalValue={originalUser.ssn}/>
                    <AddressAutoFill label='Address' stateProps={new ControlStateProps('address', formObject, Validation.notUndefined)}/>
                </Row>
                <Row style={{padding: 8, border: '1px solid lightgray', borderRadius: 6, marginBottom: 8}}>
                    <Col lg={12}>
                        <p style={{fontWeight: 'bold', width: '100%', textAlign: 'center'}}>Emergency Contacts</p>
                        {emergencyContacts.length > 0 && 
                        emergencyContacts.map((contact, i)=>{
                            return <EmergencyContactForm key={i} contact={contact} handleDeleteEmergencyContact={handleDeleteEmergencyContact} handleSetEmergencyContactProp={handleSetEmergencyContactProp} index={i}/>
                        })
                        }
                        <Button onClick={handleAddEmergencyContact} style={{marginTop: 10, marginLeft: 'auto', display: 'block'}} variant={'outline-primary'}>Add Emergency Contact</Button>
                    </Col>
                </Row>
                { (!effectiveTermination && userIsEditable) &&
                    <Row>
                        <CustomButton label='Save Changes' onClick={handleSaveChanges} disabled={!userIsValid} stateObject={formObject} isLoading={isUpdating}>Save Changes</CustomButton>
                    </Row>
                }
            </Container>
            <Modal show={modalSwitch === 'terminate'} onHide={() => setModalSwitch('')} centered size='lg'>
                <HRTerminate selectedEmployee={selectedEmployee}/>
            </Modal>
            <Modal backdrop='static' show={modalSwitch === 'rehire'} size='xl' fullscreen='lg-down'>
                <HRHire terminals={terminals} hideModal={() => setModalSwitch('')} selectedUser={selectedEmployee}/>
            </Modal>
        </>
    );

    return (
        <LoadingWrapper isLoading={isLoading} onlyRenderIfTruthy={selectedEmployee ?? false}>
            {content}
        </LoadingWrapper>
    )
}

export function EmergencyContactForm({contact, index, handleDeleteEmergencyContact, handleSetEmergencyContactProp}) {
    return (
        <Form.Group>
            <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', fontSize: 18, marginTop: 8}}>
                    <h6>Emergency Contact #{index+1}</h6>
                    <Button variant='outline-danger' onClick={() => handleDeleteEmergencyContact(index)}>Delete Contact</Button>
            </div>
            <Container fluid style={{borderRadius: 6, padding: 12, marginBottom: 8}}>
                <Row>
                    <CustomControl
                        max={30} 
                        type='text' 
                        title='First Name'
                        breakpoints={{lg: 6}}
                        value={contact.firstName}
                        setValue={(newValue) => {handleSetEmergencyContactProp(contact.uid, 'firstName', newValue)}}
                    />
                    <CustomControl
                        max={30} 
                        type='text' 
                        title='Last Name'
                        breakpoints={{lg: 6}}
                        value={contact.lastName}
                        setValue={(newValue) => {handleSetEmergencyContactProp(contact.uid, 'lastName', newValue)}}
                    />
                    <CustomControl
                        max={20}
                        type='text' 
                        title='Relationship'
                        breakpoints={{lg: 6}}
                        value={contact.relationship}
                        setValue={(newValue) => {handleSetEmergencyContactProp(contact.uid, 'relationship', newValue)}}
                    />
                    <CustomControl
                        max={10} 
                        clampAsText
                        type='number' 
                        title='Phone Number'
                        breakpoints={{lg: 6}}
                        value={contact.phoneNumber}
                        setValue={(newValue) => {handleSetEmergencyContactProp(contact.uid, 'phoneNumber', newValue)}}
                    />
                    <CustomControl
                        max={50} 
                        type='text' 
                        title='Email Address'
                        breakpoints={{lg: 12}}
                        value={contact.email}
                        setValue={(newValue) => {handleSetEmergencyContactProp(contact.uid, 'email', newValue)}}
                    />
                    <div style={{marginBottom: 10}}></div>
                    <AddressAutoFill stateProps={new ControlStateProps('address', contact, Validation.none)} label='Address'/>
                </Row>
            </Container>
            <hr/>
        </Form.Group>
    )
}

export function validationForArrays(array, isChildSupport){
    if(!array){
        return;
    }
    if(isChildSupport){
        return array.map(cs => {if(!cs){return 'Amount is required'}});
    } else{
        return array.map(arr => {
            if(!arr.type && !arr.amount){
                return 'Both an amount and type is required';
            } else if (!arr.type){
                return 'Type is required'
            } else if (!arr.amount){
                return 'Amount is required';
            }
        })
    }
}