/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import { Button } from "react-bootstrap";
import FileUploadControl from "./FileUploadControl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { getFakeUID } from "../tools";

export default function MultipleFileUploadControl({value, modifyArray, maxFiles = 5, maxTitleLength, maxFileSize = 10000000, accept}) {

    const uploadControls = value.map((file) => {
        return (
            <div key={file.uid} style={{display: 'flex', gap: 6, width: '100%'}}>
                <FileUploadControl 
                    maxTitleLength={maxTitleLength} 
                    value={file} 
                    setValue={(value) => modifyArray({type: 'replace', uid: file.uid, value: value})} 
                    maxFileSize={maxFileSize} 
                    accept={accept} 
                    onRemove={() => {modifyArray({type: 'remove', uid: file.uid})}} 
                    showAcceptedTypes={false}
                />
            </div>
        )
    })

    return (
        <div style={{display: 'flex', flexDirection: 'column', gap: 6, alignItems: 'flex-start'}}>
            {uploadControls}
            {value.length < maxFiles && <Button variant="outline-primary" onClick={() => {modifyArray({type: 'push', value: {uid: getFakeUID(), base64: null}})}}>Add File</Button>}
            { !!accept && 
                <span>{`Accepted File Types: ${accept}`}</span>
            }
        </div>
    )

}