/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, { useEffect, useState } from 'react';
import moment from 'moment';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import QuickTable from '../../../../components/QuickTable';
import { durationToString, formatDateRange, toFixedMax } from '../../../../tools.js';
import HRUserTimesheetDateSelector from './HRUserTimesheetDateSelector';
import HRUserTimesheetEditor from './HRUserTimesheetEditor';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faWarning } from '@fortawesome/free-solid-svg-icons';
import { ListGroup, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { ApiRequest } from '../../../../ApiManager.tsx';
import LoadingWrapper from '../../../../components/LoadingWrapper.js';
import './HRUserTimesheet.css'
import KeyValueRow from '../../../../components/KeyValueRow.js';

export default function HRUserTimesheet({selectedEmployee, handleJumpToBwcRecord, defaultStartDate, defaultEndDate}){
    const [isLoading, setIsLoading] = useState(false);
    const [entries, setEntries] = useState([]);
    const [selectedStartDate, setSelectedStartDate] = useState(defaultStartDate ?? moment().subtract(6, 'days').format('YYYY-MM-DD'));
    const [selectedEndDate, setSelectedEndDate] = useState(defaultEndDate ?? moment().format('YYYY-MM-DD'));
    const [showDatePicker, setShowDatePicker] = useState(false);
    const [selectedEntry, setSelectedEntry] = useState(undefined);
    const [showEditor, setShowEditor] = useState(false);

    useEffect(() => {
        loadData();
    }, [selectedEmployee, selectedStartDate, selectedEndDate])

    function loadData() {
        new ApiRequest('hr', 'getTimesheet', setIsLoading, (response) => {
            setEntries(response.timesheets.sort(sortEntries));
        }).withData({
            companyUserIdentifier: selectedEmployee.companyUserIdentifier,
            startDate: selectedStartDate,
            endDate: selectedEndDate
        }).withNoAlertOnSuccess().send();
    }

    function handleAddEntry(entry) {
        const newArray = Array.from(entries);
        newArray.push(entry);
        newArray.sort(sortEntries);
        setEntries(newArray);
        setEntries(newArray);
    }

    function handleUpdateEntry(entry) {
        const newArray = Array.from(entries.filter(e => e.uid !== entry.uid));
        newArray.push(entry);
        newArray.sort(sortEntries);
        setEntries(newArray);
    }

    function handleDeleteEntry(uid) {
        const newArray = Array.from(entries.filter(e => e.uid !== uid));
        setEntries(newArray);
    }


    function sortEntries(a, b) {

        if (a.inTime < b.inTime) {
            return 1;
        } else if (a.inTime > b.inTime) {
            return -1;
        }
        return 0;
    }

    const totalDays = entries.reduce((prev, curr) => {
        if (prev.includes(curr.inTime.substring(0, 10))) {
            return prev;
        } else {
            return [...prev, curr.inTime.substring(0, 10)];
        }
    }, []).length;

    const totalHours = entries.reduce((prev, curr) => {
        if (curr.outTime) {
            return prev + moment(curr.outTime).diff(moment(curr.inTime), 'hours', true);
        } else {
            return prev;
        }
    }, 0);

    //////////////////
    /// DESKTOP
    //////////////////

    const historyRows = entries.map((item) => {
        return (
            <tr key={item.uid} className='cursor-pointer' onClick={() => {setSelectedEntry(item); setShowEditor(true)}}>
                <td>{moment(item.inTime).format('MMM D, YYYY')}</td>
                <td>{moment(item.inTime).format('hh:mm A')}</td>
                <td>{item.outTime ? !moment(item.outTime).isSame(moment(item.inTime),'day') ? moment(item.outTime).format('MMMM D, YYYY hh:mm A') : moment(item.outTime).format('hh:mm A') : ''}</td>
                <td>{item.outTime && item.outTime !== 'ACO' ? durationToString(moment(item.outTime).diff(moment(item.inTime), 'minutes')) : 'N/A'}</td>
                <td>
                    {item.events?.length > 0 ? 
                        <OverlayTrigger overlay={<Tooltip>An incident, accident, or injury was reported on this day</Tooltip>}>
                            <FontAwesomeIcon style={{color: 'gold'}} icon={faWarning}/>
                        </OverlayTrigger>
                    : 
                        <OverlayTrigger overlay={<Tooltip>No incidents, accidents, or injuries were reported on this day</Tooltip>}>
                            <FontAwesomeIcon style={{color: 'green'}} icon={faCheck}/>
                        </OverlayTrigger>
                    }
                </td>
            </tr>
        )
    });

    //////////////////
    /// MOBILE
    //////////////////

    const mobileHistoryItems = entries.map((item) => {
        return (
            <ListGroup.Item key={item.uid} action onClick={() => {setSelectedEntry(item); setShowEditor(true)}}>
                <h6 style={{fontWeight: 'bold'}}>{moment(item.inTime).format('MMM D, YYYY')}</h6>
                <KeyValueRow title={'Time In'} value={moment(item.inTime).format('hh:mm A')}/>
                <KeyValueRow title={'Time Out'} value={item.outTime ? !moment(item.outTime).isSame(moment(item.inTime),'day') ? moment(item.outTime).format('MMMM D, YYYY hh:mm A') : moment(item.outTime).format('hh:mm A') : ''}/>
                <KeyValueRow title={'Total Time'} value={item.outTime && item.outTime !== 'ACO' ? durationToString(moment(item.outTime).diff(moment(item.inTime), 'minutes')) : 'N/A'}/>
                <KeyValueRow title={'Safety'} value={
                    item.events?.length > 0 ? 
                        <OverlayTrigger overlay={<Tooltip>An incident, accident, or injury was reported on this day</Tooltip>}>
                            <FontAwesomeIcon style={{color: 'gold'}} icon={faWarning}/>
                        </OverlayTrigger>
                    : 
                        <OverlayTrigger overlay={<Tooltip>No incidents, accidents, or injuries were reported on this day</Tooltip>}>
                            <FontAwesomeIcon style={{color: 'green'}} icon={faCheck}/>
                        </OverlayTrigger>
                    
                }/>
            </ListGroup.Item>
        )
    });

    const content = (
        <div style={{display: 'flex', flexDirection: 'column', gap: 12, height: '100%', overflowY: 'hidden'}}>
            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', gap: 12, flexWrap: 'wrap'}}>                
                <Button variant={'outline-primary'} style={{flexShrink: 0}} onClick={() => setShowDatePicker(true)}>{formatDateRange(selectedStartDate, selectedEndDate)}</Button>
                <Button variant={'outline-primary'} style={{flexShrink: 0}} onClick={() => {setShowEditor(true); setSelectedEntry(undefined)}}>Create Timesheet Entry</Button>
            </div>
            <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between', flex: 1}}>
                <div className='hr-timesheet-desktop-block '>
                    <QuickTable style={{overflowY: 'auto'}} headers={['Date', 'In Time', 'Out Time', 'Total Time', 'Safety']} rows={historyRows} hover size='sm'/>
                </div>
                <div className='hr-timesheet-mobile-block '>
                    <ListGroup>
                        {mobileHistoryItems}
                    </ListGroup>
                </div>
                <div style={{display: 'flex', justifyContent: 'space-evenly'}}>
                    <Card.Title style={{fontWeight: 'bold'}}>{`Total Days: ${totalDays}`}</Card.Title>
                    <Card.Title style={{fontWeight: 'bold'}}>{`Total Hours: ${toFixedMax(totalHours, 3)}`}</Card.Title>
                </div>
            </div>
        </div>
    )

    return (
        <>
            <LoadingWrapper isLoading={isLoading} onlyRenderIfTruthy={selectedEmployee ?? false}>
                {content}
            </LoadingWrapper>
            <Modal show={showDatePicker} onHide={() => setShowDatePicker(false)} centered>
                <HRUserTimesheetDateSelector 
                    hideModal={() => setShowDatePicker(false)} 
                    loadData={(startDate, endDate) => {
                        setSelectedStartDate(startDate);
                        setSelectedEndDate(endDate);
                    }}
                />
            </Modal>
            <Modal show={showEditor} onHide={() => setShowEditor(false)} centered size='lg'>
                <HRUserTimesheetEditor 
                    companyUserIdentifier={selectedEmployee.companyUserIdentifier}
                    selectedEntry={selectedEntry} 
                    hideModal={() => setShowEditor(false)} 
                    handleAddEntry={handleAddEntry} 
                    handleUpdateEntry={handleUpdateEntry} 
                    handleDeleteEntry={handleDeleteEntry} 
                    entries={entries}
                    handleJumpToBwcRecord={handleJumpToBwcRecord}
                />
            </Modal>
        </>
    )
}