/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, {useEffect, useState} from 'react';
import moment from 'moment';
import Modal from 'react-bootstrap/Modal';
import QuickTable from '../../../../components/QuickTable';
import PageSpinner from '../../../../components/PageSpinner';
import { ApiRequest } from '../../../../ApiManager.tsx';

export default function HRTimeOffModal({startDate, endDate, selectedCompany}) {
    const [isLoading, setIsLoading] = useState(false);
    const [timeOff, setTimeOff] = useState([]);

    useEffect(() => {
        loadData();
    }, [])

    function loadData(){
        new ApiRequest('hr', 'getAllUpcomingTimeOff', setIsLoading, (response) => setTimeOff(response.timeOff)).withData({startDate, endDate, companyIdentifier: selectedCompany}).withNoAlertOnSuccess().send()
    }

    const timeOffElements = timeOff.map((item) => {
        return (
            <tr key={item.uid}>
                <td>{`${item.user?.lastName}, ${item.user?.firstName} ${(item.user?.middleName ?? '').substring(0, 1)}`}</td>
                <td>{Math.abs(item.ptoHours)}</td>
                <td>{`${moment(item.startDate).format('MMM D')} - ${moment(item.endDate).format('MMM D')}`}</td>
            </tr>
        )
    })

    return (
        <>
            <Modal.Header closeButton>
                <Modal.Title>{`All Time-Off Between ${moment(startDate).format('MMM D, YYYY')} - ${moment(endDate).format('MMM D, YYYY')}`}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                { isLoading ? <PageSpinner/> :
                    <QuickTable headers={['Name', 'PTO Hours Used', 'Date(s)']} rows={timeOffElements}/>
                }
            </Modal.Body>
        </>
    )
}