/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React from "react";
import InputGroup from 'react-bootstrap/InputGroup';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashCan } from "@fortawesome/free-solid-svg-icons";
import CustomToolTip from "../../../../../../../components/CustomToolTip";
import PayrollMoneyControl from "../../../PayrollMoneyControl";
import Deduction from "../../../../Models/Deduction";
import { Dropdown } from "react-bootstrap";
import PayrollPercentageControl from "../../../PayrollPercentageControl";

export default function PayrollAdditionalDeductionItem({deduction, handleSetDeduction, removeDeduction}) {
    const deductionTypeDropDownItems = Deduction.deductionTypes.map((type) =>{
        return (
            <Dropdown.Item key={type} active={deduction.type === type} onClick={() => {handleSetDeduction(deduction.uid, 'type', type)}}>
                <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                    <p style={{marginBottom: 0, marginRight: 8}}>{type}</p> 
                </div>
            </Dropdown.Item>
        )
    });

    return (
        <tr style={{verticalAlign: 'middle', textAlign: 'center'}}>
            <td>
                <Dropdown>
                    <Dropdown.Toggle variant="outline-primary" style={{padding: '3px 8px'}} disabled={!handleSetDeduction}>{deduction.type}</Dropdown.Toggle>
                    <Dropdown.Menu className="position-fixed">{deductionTypeDropDownItems}</Dropdown.Menu>
                </Dropdown>
            </td>
            <td>
                { deduction.isPercentage() ? 
                    <PayrollPercentageControl style={{minWidth: 100, height: 32}} value={deduction.amount} setValue={(newValue) => {handleSetDeduction(deduction.uid, 'amount', newValue)}} disabled={!handleSetDeduction}/>
                    :
                    <PayrollMoneyControl style={{minWidth: 100, height: 32}} lhDecimalPlaces={3} value={deduction.amount} setValue={(newValue) => {handleSetDeduction(deduction.uid, 'amount', newValue)}} disabled={!handleSetDeduction}/>
                }
            </td>
            <td>
                { deduction.isFromPay ?
                    <span style={{opacity: 0.5, fontWeight: 'bold'}}>From Pay</span>
                :
                    <Form.Check type='switch' checked={deduction.isReoccurring} onChange={() => {handleSetDeduction(deduction.uid, 'isReoccurring', !deduction.isReoccurring)}} disabled={!handleSetDeduction}/>
                }
            </td>
            <td>
                { removeDeduction && 
                    <Button variant="outline-danger" style={{padding: '3px 8px'}} onClick={() => removeDeduction(deduction.uid)}>
                        <FontAwesomeIcon icon={faTrashCan}/>
                    </Button>
                }
            </td>
        </tr>
    )
}