/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import { Button, ListGroup, Modal, Row } from "react-bootstrap"
import SwitchControl from "../../../../components/SwitchControl"
import CustomControl from "../../../../components/CustomStateControls/CustomControl"
import { useReducer, useState } from "react"
import KeyValueRow from "../../../../components/KeyValueRow";
import MyCompanyIncentiveWages from "./MyCompanyIncentiveWages";

export default function PayrollSettings({settings, setSettingsKey, settingsVMs, originalSettings}){
    const [showModal, setShowModal] = useState(false);
    
    return (
        <div>
            <Button style={{display: 'block', marginLeft: 'auto', marginBottom: 8}} variant={'outline-primary'} onClick={() => setShowModal(true)}>View/Edit Custom Incentive Wages</Button>
            <Row style={{padding: 0, marginBottom: 8}}>
                { settings.payrollEnabled == 1 &&
                    <SwitchControl
                        breakpoints={{lg: 12}}
                        title={'Include AOs in payroll by default'}
                        originalValue={originalSettings.includeAOsInPayroll}
                        value={settings.includeAOsInPayroll}
                        setValue={(value) => setSettingsKey('includeAOsInPayroll', value)}        
                    />
                }
                { settings.payrollEnabled == 1 &&
                    <SwitchControl
                        breakpoints={{lg: 12}}
                        title={'Show BCs Payroll History'}
                        originalValue={originalSettings.showBcsPayrollHistory}
                        value={settings.showBcsPayrollHistory}
                        setValue={(value) => setSettingsKey('showBcsPayrollHistory', value)}   
                    />
                }
                { settings.payrollEnabled == 1 &&
                    <SwitchControl 
                        breakpoints={{lg: 12}}
                        title={'Only allow AOs to view the pay of AOs, and BCs on the Human Resources page'}
                        originalValue={originalSettings.restrictWhoSeesPay}
                        value={settings.restrictWhoSeesPay}
                        setValue={(value) => setSettingsKey('restrictWhoSeesPay', value)}   
                    />
                }
                <SwitchControl
                    breakpoints={{lg: 12}}
                    title='Automatically reward employees with an incentive wage if they work extra days'
                    originalValue={originalSettings.autoIncentiveWageAmount != null}
                    value={settings.autoIncentiveWageAmount != null}
                    setValue={()=> setSettingsKey('autoIncentiveWageAmount', settings.autoIncentiveWageAmount == null ? 0 : null)}
                />
                { settings.autoIncentiveWageAmount != null &&
                    <>
                        <CustomControl breakpoints={{sm: 6}} type='number' min={0} maxInt={6} originalValue={originalSettings.autoIncentiveWageThreshold} value={settings.autoIncentiveWageThreshold} setValue={(value) => setSettingsKey('autoIncentiveWageThreshold', value)} floatingLabel label='Automatic Incentive Wage Threshold'/>
                        <CustomControl breakpoints={{sm: 6}} type='number' min={0} maxDecimal={999.99} originalValue={originalSettings.autoIncentiveWageAmount} value={settings.autoIncentiveWageAmount} setValue={(value) => setSettingsKey('autoIncentiveWageAmount', value)} floatingLabel label='Automatic Incentive Wage Amount'/>
                        <p style={{fontSize: 14, opacity: 0.66, textAlign: 'center', margin: '4px 0 10px 0'}}>The threshold defines the number of days in a week which an employee must <b>exceed</b> in order to recieve an incentive wage. The employee will recieve the incentive wage amount for <b>each day</b> which they work beyond this threshold.</p>
                    </>
                }
                <SwitchControl 
                    breakpoints={{lg: 12}}
                    title='Automatically reward employees with overtime if they work extra days'
                    originalValue={originalSettings.autoOvertimeAmount != null}
                    value={settings.autoOvertimeAmount != null}
                    setValue={() => {setSettingsKey('autoOvertimeAmount', settings.autoOvertimeAmount == null ? 0 : null)}} 
                />
                { settings.autoOvertimeAmount != null &&
                    <>
                        <CustomControl breakpoints={{sm: 6}} type='number' min={0} maxInt={6} originalValue={originalSettings.autoOvertimeThreshold} value={settings.autoOvertimeThreshold} setValue={(value) => setSettingsKey('autoOvertimeThreshold', value)} floatingLabel label='Automatic Overtime Threshold'/>
                        <CustomControl breakpoints={{sm: 6}} type='number' min={0} maxDecimal={999.99} originalValue={originalSettings.autoOvertimeAmount} value={settings.autoOvertimeAmount} setValue={(value) => setSettingsKey('autoOvertimeAmount', value)} floatingLabel label='Automatic Overtime % (Out of 100)'/>
                        <p style={{fontSize: 14, opacity: 0.66}}>The threshold defines the number of days in a week which an employee must <b>exceed</b> in order to recieve overtime. The employee will recieve overtime wages equal to their hourly and/or daily wages multipled by the percentage specified.</p>
                    </>
                }
                <SwitchControl 
                    breakpoints={{lg: 12}}
                    title='Automatically reward employees with night time bonus if they work beyond the threshold'
                    originalValue={originalSettings.nighttimeBonus != null}
                    value={settings.nighttimeBonus != null}
                    setValue={() => {setSettingsKey('nighttimeBonus', settings.nighttimeBonus == null ? 0 : null)}} 
                />
                { settings.nighttimeBonus != null &&
                    <>
                        <CustomControl breakpoints={{sm: 6}} type='time' originalValue={originalSettings.nighttimeThreshold} value={settings.nighttimeThreshold} setValue={(value) => setSettingsKey('nighttimeThreshold', value)} floatingLabel label='Night Time Threshold'/>
                        <CustomControl breakpoints={{sm: 6}} type='number' min={0} maxDecimal={99.99} originalValue={originalSettings.nighttimeBonus} value={settings.nighttimeBonus} setValue={(value) => setSettingsKey('nighttimeBonus', value)} floatingLabel label='Night Time Bonus'/>
                        <p style={{fontSize: 14, opacity: 0.66}}>Only relevant for per hour employees. The threshold defines the time in which an employee must <b>exceed</b> in order to recieve a night time bonus. The employee will recieve the their standard pay in addtion to the night time bonus as there new hourly rate during the time after the threshold.</p>
                    </>
                }
                <SwitchControl 
                    breakpoints={{lg: 12}}
                    title='Automatically reward employees with weekend bonus'
                    originalValue={originalSettings.weekendBonus != null}
                    value={settings.weekendBonus != null}
                    setValue={() => {setSettingsKey('weekendBonus', settings.weekendBonus == null ? 0 : null)}} 
                />
                { settings.weekendBonus != null &&
                    <>
                        <CustomControl breakpoints={{sm: 12}} type='number' min={0} maxDecimal={99.99} originalValue={originalSettings.weekendBonus} value={settings.weekendBonus} setValue={(value) => setSettingsKey('weekendBonus', value)} floatingLabel label='Weekend Bonus'/>
                        <p style={{fontSize: 14, opacity: 0.66}}>Only relevant for per hour employees. The employee will recieve the their standard pay in addtion to the weekend bonus as there new hourly rate during the weekend.</p>
                    </>
                }
            </Row>
            <h5 style={{textAlign: 'center', marginTop: 24}}>Contact TTA to alter any of the following settings</h5>
            <ListGroup>
                <KeyValueRow isListItem title='Payroll Enabled' value={settings.payrollEnabled ? 'Yes' : 'No'}/>
                <KeyValueRow isListItem title='Payroll Period Length' value={`${settings.payrollPeriodLength} weeks`}/>
                <KeyValueRow isListItem title='Restrict BC Payroll to terminals' value={settings.restrictBcPayrollToTerminals ? 'Yes' : 'No'}/>
                <KeyValueRow isListItem title='Run payroll separately for each terminal' value={settings.generatePayrollForEachTerminal ? 'Yes' : 'No'}/>
            </ListGroup>
            <Modal centered size='lg' show={showModal} onHide={() => setShowModal(false)}>
                <MyCompanyIncentiveWages/>
            </Modal>
        </div>
    )
}