/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, { useState } from "react";
import Button from 'react-bootstrap/Button';
import CustomControl from "../../../../../../../components/CustomControl.js";
import Modal from 'react-bootstrap/Modal';
import { payTypeDictionary, unitsWorkedDictionary} from "../../../../payrollTools.js";
import Pay from "../../../../Models/Pay.js";
import { Dropdown, Form, InputGroup } from "react-bootstrap";
import Picker from "../../../../../../../components/Picker.js";
import { useStateWithValidation, useValidationReducer, Validation } from "../../../../../../../validation.tsx";
import { getFakeUID } from "../../../../../../../tools.js";
import PayrollMoneyControl from "../../../PayrollMoneyControl.js";
import moment from "moment";

export default function AddWageForm({handleAddWages, entry}) {
    const [payType, setPayType, payTypeValidationMessage] = useStateWithValidation('', Validation.nonEmptyString);
    const [payRate, setPayRate, payRateValidationMessage] = useStateWithValidation('', Validation.greaterThanZero);
    const [unitsWorked, setUnitsWorked, unitsWorkedValidationMessage] = useStateWithValidation('', ['py', 'iw', 'sw'].includes(payType) ? Validation.none : Validation.greaterThanZero, true);
    const [payStartIndex, setPayStartIndex] = useState(0);
    const [payEndIndex, setPayEndIndex] = useState(6);
    const [applyToAll, setApplyToAll] = useState(false);

    const selectedPayStartDate = moment(entry.periodStart).add(entry.weekIndex, 'weeks').add(payStartIndex, 'days').format('ddd D')
    const selectedPayEndDate = moment(entry.periodStart).add(entry.weekIndex, 'weeks').add(payEndIndex, 'days').format('ddd D')


    const isValid = useValidationReducer([payTypeValidationMessage, payRateValidationMessage, unitsWorkedValidationMessage]);

    const handleSubmit = () => {
        handleAddWages(new Pay(getFakeUID(), payStartIndex, payEndIndex, payType, payRate, unitsWorked), applyToAll);
        
    }

    const payStartDropdownItems = [0, 1, 2, 3, 4, 5, 6].filter(i => i <= payEndIndex).map((index) => {
        const date = moment(entry.periodStart).add(entry.weekIndex, 'weeks').add(index, 'days').format('ddd D')
        return (
            <Dropdown.Item key={index} onClick={() => {setPayStartIndex(index)}}>{date}</Dropdown.Item>
        )
    })
    const payEndDropdownItems = [0, 1, 2, 3, 4, 5, 6].filter(i => i >= payStartIndex).map((index) => {
        const date = moment(entry.periodStart).add(entry.weekIndex, 'weeks').add(index, 'days').format('ddd D')
        return (
            <Dropdown.Item key={index} onClick={() => {setPayEndIndex(index)}}>{date}</Dropdown.Item>
        )
    })
    
    return (
        <>
            <Modal.Header closeButton>
                <Modal.Title>Adding Wage</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{display: 'flex', flexDirection: 'column', gap: 6}}>
                <InputGroup style={{flexWrap: 'nowrap'}}>
                    <PayrollMoneyControl lhDecimalPlaces={6} validate validationMessage={payRateValidationMessage} value={payRate} setValue={setPayRate} title='Pay Rate'/>
                    <Picker title='Pay Type' lg={4} validate validationMessage={payTypeValidationMessage} selection={payType} setSelection={setPayType} itemNames={Object.values(payTypeDictionary)} itemValues={Object.keys(payTypeDictionary)}/>
                </InputGroup>
                {!['py', 'sw'].includes(payType) && 
                    <CustomControl type='number' lg={4} validate validationMessage={unitsWorkedValidationMessage} value={unitsWorked} setValue={setUnitsWorked} title={unitsWorkedDictionary[payType] ?? 'Units Worked'}/>
                }
                { payType == 'py' && 
                    <InputGroup style={{flexWrap: 'nowrap'}}>
                        <InputGroup.Text>Active Period</InputGroup.Text>
                        <Dropdown>
                            <Dropdown.Toggle variant="outline-primary">{selectedPayStartDate}</Dropdown.Toggle>
                            <Dropdown.Menu>
                                {payStartDropdownItems}
                            </Dropdown.Menu>
                        </Dropdown>
                        <InputGroup.Text style={{backgroundColor: 'white'}}>-</InputGroup.Text>
                        <Dropdown>
                            <Dropdown.Toggle variant="outline-primary">{selectedPayEndDate}</Dropdown.Toggle>
                            <Dropdown.Menu>
                                {payEndDropdownItems}
                            </Dropdown.Menu>
                        </Dropdown>
                    </InputGroup>

                }
            </Modal.Body>
            <Modal.Footer style={{gap: 12}}>
                <Form.Check type='switch' checked={applyToAll} label='Add Pay To All Weeks' onChange={()=>{setApplyToAll(!applyToAll)}}/>
                <Button style={{color: 'white'}} disabled={!isValid} onClick={handleSubmit}>Submit</Button>
            </Modal.Footer>
        </>
    )
}