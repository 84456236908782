/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import moment from "moment";
import React, { useEffect, useMemo, useReducer, useState } from "react"
import { ApiRequest } from "../../../../ApiManager.tsx";
import { Accordion, Button, ButtonGroup, Dropdown, InputGroup } from "react-bootstrap";
import { DndProvider} from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { TouchBackend } from 'react-dnd-touch-backend';
import { getNameMatches, stringTimeToInt, intToTime} from "../../../../tools.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faLocationDot, faMinusCircle, faTruck, faUsers, faWarehouse } from "@fortawesome/free-solid-svg-icons";
import SearchBar from "../../../../components/SearchBar.js";
import DropdownItemWithCheck from "../../../../components/DropdownItemWithCheck.js";
import Route from '../Models/Route.js';
import CustomButton from "../../../../components/CustomButton.js";
import { DraggableClient, DraggableUser, DraggableTerminal, DraggableVehicle } from "./DraggableComponents.js";
import CustomToolTip from "../../../../components/CustomToolTip.js";
import RouteCard from "./RouteCard.js";
import  "./RouteEditor.css";
import { getConflicts } from "../Scheduling.js";
import CustomPreviews from "./CustomPreviews.js";
// import { isMobile } from 'react-device-detect';

export default function RouteEditor({selectedRoute, setSelectedRoute, setShowRouteEditor, clients, terminals, vehicles, users, routesForDay, selectedDate, templateMode, selectedTemplate, handleRoutesCrud}) {
    const [route, setRoute] = useState(() => selectedRoute ? new Route(selectedRoute.uid, selectedRoute.user, selectedRoute.vehicle, selectedDate, selectedRoute.notes, selectedRoute.stops) : Route.initDefault(selectedDate));
    const [stemTimes, setStemTimes] = useState([]);
    const [isDraggingClientOrTerminal, setIsDraggingClientOrTerminal] = useState(false);
    const [expandSection, setExpandSection] = useState('');
    const [selectedTerminals, setSelectedTerminals] = useState(terminals.map(t=> t.uid));
    const [includeNonDrivers, setIncludeNonDrivers] = useState(false);
    const [userSearch, setUserSearch] = useState('');
    const [clientSearch, setClientSearch] = useState('');
    const [terminalSearch, setTerminalSearch] = useState('');
    const [vehicleSearch, setVehicleSearch] = useState('');
    const [isValidating, setIsValidating] = useState(false);
    const [isAutofilling, setIsAutofilling] = useState(false);
    const isMobile = navigator.maxTouchPoints > 0;

    const conflicts = useMemo(() => {
        return getConflicts(route, routesForDay, stemTimes, selectedDate, templateMode);
    }, [route, stemTimes, routesForDay, selectedDate, templateMode])

    function handleCalculateStemForRoute(willAutoFill = false){
        // Detemine if any stem times are needed from api call
        const stopsToSend = [];
        for(let i = 1; i < route.stops.length; i++){
            if(!stemTimes.find(s => (s.startAddressIdentifier === route.stops[i-1].address.uid && s.endAddressIdentifier === route.stops[i].address.uid))){
                if(!stopsToSend.length || route.stops[i-1].uid !== stopsToSend[stopsToSend.length-1].uid){
                    stopsToSend.push(route.stops[i-1]);
                }
                stopsToSend.push(route.stops[i]);
            }
        }

        if(stopsToSend.length){
            new ApiRequest('scheduling', 'calculateStem', willAutoFill ? setIsAutofilling : setIsValidating, (response) => {
                const newStemTimes = [...stemTimes, ...response.stemTimes];
                setStemTimes(newStemTimes);
                if(willAutoFill){
                    autoFillRoute(newStemTimes);
                }
            }).withData({stops: stopsToSend.map(s => s.encode())}).send();
        }else{
            if(willAutoFill){
                autoFillRoute(stemTimes);
            }
        }
    }

    function autoFillRoute(stemTimes){
        setRoute((prevState) => {
            const newRoute = prevState.duplicate();
            for(let i = 0; i < newRoute.stops.length; i++){
                if(i === 0){
                    // Set endTime for first stop is not locked or no end time exists
                    if(!newRoute.stops[i].endTimeIsLocked || !newRoute.stops[i].endTime){
                        newRoute.stops[i].endTime = intToTime(Math.ceil((stringTimeToInt(newRoute.stops[i].startTime) + newRoute.stops[i].defaultLoadTime)/15)*15);
                    }
                }else{
                    //set startTime and endTime for rest of stops based on stem and default load times or existing values if locked
                    const stemTime = stemTimes.find(s => (s.startAddressIdentifier === newRoute.stops[i-1].address.uid && s.endAddressIdentifier === newRoute.stops[i].address.uid))?.stemTime ?? 0;
                    if(!newRoute.stops[i].startTimeIsLocked || !newRoute.stops[i].startTime){
                        newRoute.stops[i].startTime = intToTime(Math.ceil((stringTimeToInt(newRoute.stops[i-1].endTime) + stemTime) / 15) * 15);
                    }
                    if(!newRoute.stops[i].endTimeIsLocked || !newRoute.stops[i].endTime){
                        newRoute.stops[i].endTime = intToTime(stringTimeToInt(newRoute.stops[i].startTime) + Math.ceil(newRoute.stops[i].defaultLoadTime/15)*15);
                    }
                }
            }
            return newRoute;
        });
    }

    function handleSetRoute(key, value){
        setRoute((prevState) => {
            const newRoute = new Route(prevState.uid, prevState.user, prevState.vehicle, selectedDate, prevState.notes, prevState.stops);
            newRoute[key] = value;
            return newRoute;
        });
    }

    function handleSetRouteStop(type, key, value, stopIndex, deleteIndex){
        setRoute((prevState) => {
            const newRoute = new Route(prevState.uid, prevState.user, prevState.vehicle, selectedDate, prevState.notes, prevState.stops);
            if(key){
                newRoute.stops[stopIndex][key] = value;
            } else if(type){
                switch(type){
                    case 'stop':
                        if(stopIndex === -1){
                            newRoute.stops.push(value);
                        }else{
                            if(typeof deleteIndex === 'number'){
                                newRoute.stops =  newRoute.stops.toSpliced(deleteIndex, 1);
                            }

                            const insertIndex = stopIndex === 0 ? 0 : deleteIndex >= stopIndex ? stopIndex : stopIndex-1;
                        
                            newRoute.stops.splice(insertIndex, 0, value?.stop ? value.stop : value)
                        }
                        break;
                    case 'stopRemove':
                        newRoute.stops =  newRoute.stops.toSpliced(stopIndex, 1);
                        break;
                }
            }
            return newRoute;
        });
    }
    
    function handleSetSelectedTerminals(uid, isSelected){
        const newSelectedTerminals = isSelected ? selectedTerminals.filter(terminalIdentifier => terminalIdentifier != uid) : [...selectedTerminals, uid];
        setSelectedTerminals(newSelectedTerminals);
    }

    function sortByConflictSeverity(a, b){
        const aSeverity = a?.conflicts?.reduce((accumulator, currentValue) => Math.max(accumulator, currentValue.severity), 0)
        const bSeverity = b?.conflicts?.reduce((accumulator, currentValue) => Math.max(accumulator, currentValue.severity), 0)

        if(aSeverity < bSeverity){
            return -1
        } else if(aSeverity > bSeverity){
            return 1
        }

        return 0;
    }

    const filteredTerminals = terminals.filter(t => {
        return t.name.toLocaleLowerCase().includes(terminalSearch.toLocaleLowerCase());
    })

    const filteredClients = clients.filter(c => {
        return  c.name.toLocaleLowerCase().includes(clientSearch.toLocaleLowerCase());
    })

    const filteredUsers = users.filter(user => {
        user.conflicts = [];

        // already scheduled conflict
        if(routesForDay.find(r => r?.user?.companyUserIdentifier === user.companyUserIdentifier && route?.uid !== r.uid)){
            user.conflicts.push({
                severity: 2,
                message: 'This user is already scheduled for this date on another route.'
            })
        }
    
        // availability conflict
        if(!parseInt(user.availability[(moment(route.date).day() + 1)%7])){
            user.conflicts.push({
                severity: 1,
                message: 'Current day falls ouside of user\'s availability.',
            })
        }
    
        // timeOff conflict
        if(!templateMode){
            let selectedTimeOff = route?.user?.timeOff?.find(to => moment(route.date).isBetween(moment(to.startDate), moment(to.endDate), undefined, '[]') && to.status !== -1)
            if(selectedTimeOff){
                const timeOffString = selectedTimeOff.isPto ? 'paid time-off' : selectedTimeOff.status === 1 ? 'unpaid time-off' : 'a pending time-off request';
                conflicts.user.push({
                    severity: 2,
                    message: `This user has ${timeOffString} on this date.`
                })
            }
        }

        return getNameMatches(user, userSearch) && (includeNonDrivers || user.title === 'Driver') && route?.user?.companyUserIdentifier !== user.companyUserIdentifier && selectedTerminals.includes(user.terminalIdentifier);
    }).sort(sortByConflictSeverity)

    const filteredVehicles = vehicles.filter(vehicle => {
        // already scheduled conflict
        vehicle.conflicts = [];
        if(routesForDay.find(r => r?.vehicle?.uid === vehicle.uid && route?.uid !== r.uid)){
            vehicle.conflicts.push({
                severity: 2,
                message: 'This vehicle is already scheduled for this date on another route.'
            })
        }
        return vehicle.name.toLocaleLowerCase().includes(vehicleSearch.toLocaleLowerCase()) && route?.vehicle?.uid !== vehicle.uid;
    }).sort(sortByConflictSeverity) 

    const terminalsFilter = (
        <Dropdown autoClose='outside'>
            <Dropdown.Toggle variant="outline-primary">Filters</Dropdown.Toggle>
            <Dropdown.Menu>
                <Dropdown.Header>Assigned Terminals</Dropdown.Header>
                {terminals.map(t => {
                    const isSelected = selectedTerminals.includes(t.uid);
                    return <DropdownItemWithCheck key={t.uid} label={t.name} active={isSelected} onClick={() => handleSetSelectedTerminals(t.uid, isSelected)}/>
                })}
                <Dropdown.Divider/>
                <Dropdown.Header>Other</Dropdown.Header>
                <DropdownItemWithCheck label='Include Non-Drivers' active={includeNonDrivers} onClick={() => setIncludeNonDrivers(!includeNonDrivers)}/>
            </Dropdown.Menu>
        </Dropdown>
    )

    return (
        <DndProvider backend={isMobile ? TouchBackend : HTML5Backend} options={{enableMouseEvents: true, enableKeyboardEvents: true}}>
            {isMobile && <CustomPreviews/>}
            <div style={{ display: 'flex', overflowY: 'hidden', flex: 1}} >
                {/* ROUTE CARDS */}
                <div style={{display: "flex", flexDirection: 'column', flex: 10, padding: '0 18px', overflow: 'auto'}}>
                    <div style={{display: 'flex', gap: 8, justifyContent: 'space-between', flexWrap: 'wrap', paddingBottom: 8, paddingTop: 8}}>
                        <Button style={{minWidth: 170, flex: 1}} variant='outline-primary' onClick={() => {setSelectedRoute(null); setShowRouteEditor(false)}}>
                            <FontAwesomeIcon icon={faChevronLeft}/>
                            <span>Back to Routes</span>
                        </Button>
                        <div style={{display: 'flex', marginLeft: 'auto', gap: 6, flexWrap: "wrap", justifyContent: 'end', width: '100%'}}>
                            {route.stops.length > 1 && 
                                <div style={{display: 'flex'}}>
                                    <CustomButton isLoading={isValidating} style={{borderRight: 'none', borderTopRightRadius: 0, borderBottomRightRadius: 0}} onClick={() => handleCalculateStemForRoute()} variant="outline-primary">
                                        Validate Route
                                    </CustomButton >
                                    <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', width: 25, border: '1px solid var(--bs-primary)', borderTopRightRadius: 6, borderBottomRightRadius: 6}}>
                                        <CustomToolTip placement='bottom' text={'Calculate the travel time between current stops and validate current route.'}/>
                                    </div>
                                </div>
                            }
                            {route.stops.length > 1 && 
                                <div style={{display: 'flex'}}>
                                    <CustomButton isLoading={isAutofilling} style={{borderRight: 'none', borderTopRightRadius: 0, borderBottomRightRadius: 0}} disabled={!route.stops?.[0].startTime} onClick={() => {handleCalculateStemForRoute(true)}}  variant="outline-primary">
                                        Autofill Route
                                    </CustomButton >
                                    <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', width: 25, border: '1px solid var(--bs-primary)', borderTopRightRadius: 6, borderBottomRightRadius: 6}}>
                                        <CustomToolTip placement='bottom' text={'(Requires first stop to have a Time-In) Autofill the entire route card using calculated stem times and default load times. Use the lock checkbox columns to prevent certain times from being overwritten.'}/>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                    {/*//////////////////
                        MOBILE
                    ////////////////////*/}
                    <div className="mobile-route-editor-draggable-container" style={{paddingBottom: 8}}>
                        <ButtonGroup style={{flex: 1}}>
                            <Button onClick={()=> {setExpandSection('users')}} style={{borderBottomLeftRadius: expandSection ? 0 : 6}} variant="outline-primary">
                                <FontAwesomeIcon icon={faUsers}/>
                            </Button>
                            <Button onClick={()=> {setExpandSection('vehicles')}} variant="outline-primary">
                                <FontAwesomeIcon icon={faTruck}/>
                            </Button>
                            <Button onClick={()=> {setExpandSection('terminals')}} variant="outline-primary">
                                <FontAwesomeIcon icon={faWarehouse}/>
                            </Button>
                            <Button onClick={()=> {setExpandSection('clients')}} style={{borderBottomRightRadius: expandSection ? 0 : 6}} variant="outline-primary">
                                <FontAwesomeIcon icon={faLocationDot}/>
                            </Button>
                        </ButtonGroup>
                        {expandSection === 'users' &&
                            <div style={{border: '1px solid var(--bs-primary)', borderTop: 'none', borderBottomLeftRadius: 6, borderBottomRightRadius: 6, position: 'relative'}}>
                                <h6 style={{textAlign: 'center', margin: 0, margin: 6, fontWeight: 'bold'}}>Users <FontAwesomeIcon style={{height: 14, width: 14}} color='var(--bs-primary)' icon={faUsers}/></h6>
                                <div style={{width: '100%', padding: '0 8px 8px 8px', display: 'flex', gap: 4}}>
                                    <SearchBar label='Search' value={userSearch} setValue={setUserSearch}/>
                                    {terminalsFilter}
                                </div>
                                <div style={{display: 'flex', flexDirection: 'column', gap: 8, overflowY: 'auto', padding: '0 6px 6px 6px', maxHeight: 210}}>
                                    <div style={{display: 'flex', flexDirection: 'column', gap: 8, overflowY: 'auto'}}> 
                                        <Accordion alwaysOpen defaultActiveKey={['0', '1']}>
                                            <Accordion.Item eventKey="0">
                                                <Accordion.Header>
                                                    <p style={{fontWeight: 'bold', textAlign: 'center', margin: 0, width: 240}}>Full-Time Users: {filteredUsers.filter((u) => u.employeeType === 0).length}</p>
                                                </Accordion.Header>
                                                <Accordion.Body style={{display: 'flex', flexDirection: 'column', gap: 6, maxHeight: 400, overflowY: 'auto', overflowX: 'hidden', padding: 8}}>
                                                    {filteredUsers.filter((u) => u.employeeType === 0).map(e=> {
                                                        return <DraggableUser routesForDay={routesForDay} route={route} isMobile handleSetRoute={handleSetRoute} key={e.uid} user={e}/>
                                                    })}
                                                </Accordion.Body>
                                            </Accordion.Item>
                                            <Accordion.Item eventKey="1">
                                                <Accordion.Header>
                                                    <p style={{fontWeight: 'bold', textAlign: 'center', margin: 0, width: 240}}>Part-Time Users: {filteredUsers.filter((u) => u.employeeType === 1).length}</p>
                                                </Accordion.Header>
                                                <Accordion.Body style={{display: 'flex', flexDirection: 'column', gap: 6, maxHeight: 400, overflowY: 'auto', padding: 8}}>
                                                    {filteredUsers.filter((u) => u.employeeType === 1).map(e=> {
                                                        return <DraggableUser routesForDay={routesForDay} route={route} isMobile handleSetRoute={handleSetRoute} key={e.uid} user={e}/>
                                                    })}
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        </Accordion>
                                    </div>
                                </div>
                                <div style={{top: 4, left: 6, position:'absolute'}}>
                                    <FontAwesomeIcon onClick={() => {setExpandSection(''); setUserSearch('')}} style={{cursor: 'pointer', color: 'var(--bs-primary)', width: 18, height: 18}} icon={faMinusCircle}/>
                                </div>
                            </div>    
                        }
                        {expandSection === 'vehicles' &&
                            <div style={{border: '1px solid var(--bs-primary)', borderTop: 'none', borderBottomLeftRadius: 6, borderBottomRightRadius: 6, position: 'relative'}}>
                                <h6 style={{textAlign: 'center', margin: 0, margin: 6, fontWeight: 'bold'}}>Vehicles <FontAwesomeIcon style={{height: 14, width: 14}} color='var(--bs-primary)' icon={faTruck}/></h6>
                                <div style={{width: '100%', padding: '0 8px 8px 8px'}}>
                                    <SearchBar label='Search' value={vehicleSearch} setValue={setVehicleSearch}/>
                                </div>
                                <div style={{display: 'flex', flexDirection: 'column', gap: 8, overflowY: 'auto', padding: '0 6px 6px 6px', maxHeight: 210}}>
                                    {filteredVehicles.map(v=> {
                                        return <DraggableVehicle routesForDay={routesForDay} route={route} isMobile handleSetRoute={handleSetRoute} key={v.uid} vehicle={v}/>
                                    })}
                                </div>
                                <div style={{top: 4, left: 6, position:'absolute'}}>
                                    <FontAwesomeIcon onClick={() => {setExpandSection(''); setVehicleSearch('')}} style={{cursor: 'pointer', color: 'var(--bs-primary)', width: 18, height: 18}} icon={faMinusCircle}/>
                                </div>
                            </div>    
                        }
                        {expandSection === 'terminals' &&
                            <div style={{border: '1px solid var(--bs-primary)', borderTop: 'none', borderBottomLeftRadius: 6, borderBottomRightRadius: 6, position: 'relative'}}>
                                <h6 style={{textAlign: 'center', margin: 0, margin: 6, fontWeight: 'bold'}}>Terminals <FontAwesomeIcon style={{height: 14, width: 14}} color='var(--bs-primary)' icon={faWarehouse}/></h6>
                                <div style={{width: '100%', padding: '0 8px 8px 8px'}}>
                                    <SearchBar label='Search' value={terminalSearch} setValue={setTerminalSearch}/>
                                </div>
                                <div style={{display: 'flex', flexDirection: 'column', gap: 8, overflowY: 'auto', padding: '0 6px 6px 6px', maxHeight: 210}}>
                                    {filteredTerminals.map(t=> {
                                        return <DraggableTerminal isMobile handleSetRouteStop={handleSetRouteStop} setIsDraggingClientOrTerminal={setIsDraggingClientOrTerminal} key={t.uid} terminal={t} stops={route.stops}/>
                                    })}
                                </div>
                                <div style={{top: 4, left: 6, position:'absolute'}}>
                                    <FontAwesomeIcon onClick={() => {setExpandSection(''); setTerminalSearch('')}} style={{cursor: 'pointer', color: 'var(--bs-primary)', width: 18, height: 18}} icon={faMinusCircle}/>
                                </div>
                            </div>    
                        }
                        {expandSection === 'clients' &&
                            <div style={{border: '1px solid var(--bs-primary)', borderTop: 'none', borderBottomLeftRadius: 6, borderBottomRightRadius: 6, position: 'relative'}}>
                                <h6 style={{textAlign: 'center', margin: 0, margin: 6, fontWeight: 'bold'}}>Clients <FontAwesomeIcon style={{height: 14, width: 14}} color='var(--bs-primary)' icon={faLocationDot}/></h6>
                                <div style={{width: '100%', padding: '0 8px 8px 8px'}}>
                                    <SearchBar label='Search' value={clientSearch} setValue={setTerminalSearch}/>
                                </div>
                                <div style={{display: 'flex', flexDirection: 'column', gap: 8, overflowY: 'auto', padding: '0 6px 6px 6px', maxHeight: 210}}>
                                    {filteredClients.map(c=> {
                                        return <DraggableClient isMobile handleSetRouteStop={handleSetRouteStop} setIsDraggingClientOrTerminal={setIsDraggingClientOrTerminal} key={c.uid} client={c} stops={route.stops}/>
                                    })}
                                </div>
                                <div style={{top: 4, left: 6, position:'absolute'}}>
                                    <FontAwesomeIcon onClick={() => {setExpandSection(''); setClientSearch('')}} style={{cursor: 'pointer', color: 'var(--bs-primary)', width: 18, height: 18}} icon={faMinusCircle}/>
                                </div>
                            </div>    
                        }
                    </div>
                    <div style={{display: 'flex', flexDirection: 'column', gap: 48, marginBottom: 24, width: '100%'}}>
                        <RouteCard isDraggingClientOrTerminal={isDraggingClientOrTerminal} setIsDraggingClientOrTerminal={setIsDraggingClientOrTerminal} handleSetRoute={handleSetRoute} handleSetRouteStop={handleSetRouteStop} conflicts={conflicts} selectedTemplate={selectedTemplate} templateMode={templateMode} selectedRoute={selectedRoute} handleRoutesCrud={handleRoutesCrud} route={route}/>
                    </div>
                </div>
                  {/*//////////////////
                        DESKTOP
                    ////////////////////*/}
                {/* EXPAND SECTION */}
                <div className="desktop-route-editor-draggable-container">
                    { !expandSection &&
                        <div style={{display: 'flex', flexDirection: 'column'}}>
                            <div className="expand-box" onClick={()=> {setExpandSection('users')}}>
                                <h5 className='expand-text' style={{fontWeight: 'bold'}}>Users</h5>
                                <FontAwesomeIcon className="expand-icon" color='var(--bs-primary)' icon={faUsers}/>
                            </div>
                            <div className="expand-box" onClick={()=> {setExpandSection('vehicles')}}>
                                <h5 className='expand-text' style={{fontWeight: 'bold'}}>Vehicles</h5>
                                <FontAwesomeIcon className="expand-icon" color='var(--bs-primary)' icon={faTruck}/>
                            </div>
                            <div className="expand-box" onClick={()=> {setExpandSection('terminals')}}>
                                <h5 className='expand-text' style={{fontWeight: 'bold'}}>Terminals</h5>
                                <FontAwesomeIcon className="expand-icon" icon={faWarehouse}/>
                            </div>
                            <div className="expand-box" onClick={()=> {setExpandSection('clients')}}>
                                <h5 className='expand-text' style={{fontWeight: 'bold'}}>Clients</h5>
                                <FontAwesomeIcon className="expand-icon" color='var(--bs-primary)' icon={faLocationDot}/>
                            </div>
                        </div>
                    }    
                    {/* USERS  */}
                    { expandSection === 'users' &&
                        <div className="expand-section">
                            <h5 style={{fontWeight: 'bold', margin: 0}}>Users &nbsp;<FontAwesomeIcon color='var(--bs-primary)' icon={faUsers}/></h5>
                            <hr style={{width: '90%', margin: 10, marginTop: 2}}/>
                            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-end', width: '90%'}}>
                                {terminalsFilter}
                            </div>   
                            <div style={{width: '90%'}}>
                                <SearchBar label='Search by name' value={userSearch} setValue={setUserSearch}/>
                            </div>
                            <hr style={{width: '90%', margin: 10, marginTop: 2}}/>
                            <div style={{display: 'flex', flexDirection: 'column', gap: 8, overflowY: 'auto', width: '100%', padding: '0 8px'}}> 
                                <Accordion alwaysOpen defaultActiveKey={['0', '1']}>
                                    <Accordion.Item eventKey='0'>
                                        <Accordion.Header>
                                            <p style={{fontWeight: 'bold', textAlign: 'center', margin: 0}}>Full-Time Users: {filteredUsers.filter((u) => u.employeeType === 0).length}</p>
                                        </Accordion.Header>
                                        <Accordion.Body style={{display: 'flex', flexDirection: 'column', gap: 6, maxHeight: 400, overflowY: 'auto'}}>
                                            {filteredUsers.filter((u) => u.employeeType === 0).map(e=> {
                                                return <DraggableUser routesForDay={routesForDay} route={route} key={e.uid} user={e}/>
                                            })}
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey='1'>
                                        <Accordion.Header>
                                            <p style={{fontWeight: 'bold', textAlign: 'center', margin: 0}}>Part-Time Users: {filteredUsers.filter((u) => u.employeeType === 1).length}</p>
                                        </Accordion.Header>
                                        <Accordion.Body style={{display: 'flex', flexDirection: 'column', gap: 6, maxHeight: 400, overflowY: 'auto'}}>
                                            {filteredUsers.filter((u) => u.employeeType === 1).map(e=> {
                                                return <DraggableUser routesForDay={routesForDay} route={route} key={e.uid} user={e}/>
                                            })}
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </div>
                            <div style={{top: 14, left: 14, position:'absolute'}}>
                                <FontAwesomeIcon onClick={() => {setExpandSection(''); setUserSearch('')}} style={{cursor: 'pointer', color: 'var(--bs-primary)', width: 24, height: 24}} icon={faMinusCircle}/>
                            </div>
                        </div>
                    }
                        {/* VEHICLES */}
                    { expandSection === 'vehicles' &&
                        <div className="expand-section">
                            <h5 style={{fontWeight: 'bold'}}>Vehicles &nbsp;<FontAwesomeIcon color='var(--bs-primary)' icon={faTruck}/></h5>
                            <div style={{width: '90%'}}>
                                <SearchBar label='Search by name' value={vehicleSearch} setValue={setVehicleSearch}/>
                            </div>
                            <hr style={{width: '90%', margin: 10, marginTop: 2}}/>
                            <div style={{display: 'flex', flexDirection: 'column', gap: 8, overflowY: 'auto', padding: 8}}>
                                {filteredVehicles.map(v=> {
                                    return <DraggableVehicle route={route} routesForDay={routesForDay} key={v.uid} vehicle={v}/>
                                })}
                            </div>
                            <div style={{top: 14, left: 14, position:'absolute'}}>
                                <FontAwesomeIcon onClick={() => {setExpandSection(''); setVehicleSearch('')}} style={{cursor: 'pointer', color: 'var(--bs-primary)', width: 24, height: 24}} icon={faMinusCircle}/>
                            </div>
                        </div>
                    }
                    {/* TERMINALS */}
                    { expandSection === 'terminals' &&
                        <div className="expand-section">
                            <h5 style={{fontWeight: 'bold'}}>Terminals &nbsp;<FontAwesomeIcon color='var(--bs-primary)' icon={faWarehouse}/></h5>
                            <div style={{width: '90%'}}>
                                <SearchBar label='Search by name' value={terminalSearch} setValue={setTerminalSearch}/>
                            </div>
                            <hr style={{width: '90%', margin: 10, marginTop: 2}}/>
                            <div style={{display: 'flex', flexDirection: 'column', gap: 8, overflowY: 'auto', padding: 8}}>
                                {filteredTerminals.map(t=> {
                                    return <DraggableTerminal setIsDraggingClientOrTerminal={setIsDraggingClientOrTerminal} key={t.uid} terminal={t} stops={route.stops}/>
                                })}
                            </div>
                            <div style={{top: 14, left: 14, position:'absolute'}}>
                                <FontAwesomeIcon onClick={() => {setExpandSection(''); setTerminalSearch('')}} style={{cursor: 'pointer', color: 'var(--bs-primary)', width: 24, height: 24}} icon={faMinusCircle}/>
                            </div>
                        </div>
                    }
                        {/* CLIENTS  */}
                    { expandSection === 'clients' &&
                        <div className="expand-section">
                            <h5 style={{fontWeight: 'bold'}}>Clients &nbsp;<FontAwesomeIcon color='var(--bs-primary)' icon={faLocationDot}/></h5>
                            <div style={{width: '90%'}}>
                                <SearchBar label='Search by name' value={clientSearch} setValue={setClientSearch}/>
                            </div>
                            <hr style={{width: '90%', margin: 10, marginTop: 2}}/>
                            <div style={{display: 'flex', flexDirection: 'column', gap: 8, overflowY: 'auto', padding: 8}}>
                                {filteredClients.map(c=> {
                                    return <DraggableClient setIsDraggingClientOrTerminal={setIsDraggingClientOrTerminal} key={c.uid} client={c} stops={route.stops}/>
                                })}
                            </div>
                            <div style={{top: 14, left: 14, position:'absolute'}}>
                                <FontAwesomeIcon onClick={() => {setExpandSection(''); setClientSearch('')}} style={{cursor: 'pointer', color: 'var(--bs-primary)', width: 24, height: 24}} icon={faMinusCircle}/>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </DndProvider>
    )
}