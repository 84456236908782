/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, { useEffect, useReducer, useState } from 'react';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Dropdown from 'react-bootstrap/Dropdown';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import OptionalCol from '../OptionalCol';
import ValidationFeedback from '../ValidationFeedback';

export default function CustomDropDown({selection, setSelection, originalValue, items, title, breakpoints, stateProps, clearSearch, setClearSearch, style}) {
    const [showDropdown, setShowDropdown] = useState(false);
    const [search, setSearch] = useState(items[selection ?? stateProps?.getValue()] ?? '');

    const [showValidation, setShowValidation] = useState(false);
    const [_, forceUpdate] = useReducer(x => x + 1, 0);

    useEffect(() => {
        stateProps?.subscribe((showValidation) => {
            if (showValidation) {
                setShowValidation(true);
            }
            forceUpdate();
        });

        if(clearSearch){
            setSearch('');
            setClearSearch();
        }

    }, [stateProps, clearSearch]);


    const handleSetSelection = (newValue) => {
        handleOnBlur();
        if (stateProps) {
            stateProps.stateObject.setState(stateProps.stateKey, newValue);
        } else if (setSelection) {
            setSelection(newValue);
        }
        setSearch(items[newValue] ?? '');
        setShowDropdown(false);
    }
    
    const handleSearchOnChange = (event) => {
        let newSearch = event.target.value;
        const newValue = Object.entries(items).find(([value, label]) => {
            return newSearch.toLowerCase() === label.toLowerCase();
        })?.[0];
        if (newValue) {
            newSearch = items[newValue];
            setShowDropdown(false);
        } else if (!showDropdown) {
            setShowDropdown(true);
        }
        if (stateProps) {
            stateProps.stateObject.setState(stateProps.stateKey, newValue);
        } else if (setSelection) {
            setSelection(newValue);
        }

        setSearch(newSearch);
    }
    
    const handleOnBlur = () => {
        setShowValidation(true);
    }
    const getHighlightedItem = () => {
        return search === '' ? undefined : Object.entries(items).find(([value, label]) => {
            return search.toLowerCase() === label.toLowerCase().substring(0, search.length);
        })?.[0];
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            handleSetSelection(getHighlightedItem());
        }
    }

    const changedFromOriginal = () => {
        return originalValue !== undefined && originalValue !== null && (selection != originalValue);
    }

    const filteredDropdownItems = Object.entries(items).filter(([value, label]) => {
        return search.toLowerCase() === label.toLowerCase().substring(0, search.length);
    });

    const dropdownItems = filteredDropdownItems.map(([value, label]) => {
        return (
            <Dropdown.Item active={value === getHighlightedItem()} key={value} onClick={() => {handleSetSelection(value)}}>{label}</Dropdown.Item>
        )
    });

    return (
        <OptionalCol breakpoints={breakpoints}>
            <div style={{position: 'relative'}} onKeyDown={handleKeyDown} onFocus={() => {setShowDropdown(true)}} onBlur={() => {handleOnBlur(); setTimeout(() => {setShowDropdown(false)}, 200)}}>
                <FloatingLabel label={<div>{title}<span style={{color: 'red'}}>{(stateProps?.hasValidation()) ? '*' : ''}</span></div>}>
                    <Form.Control 
                        placeholder=' '
                        onChange={handleSearchOnChange}
                        value={search}
                        isInvalid={showValidation ? stateProps?.getIsInvalid() : undefined} 
                        isValid={showValidation ? stateProps?.getIsValid() : undefined} 
                        style={changedFromOriginal() ? {backgroundColor: '#faffe3', ...style} : style}
                    />
                </FloatingLabel>
                <Dropdown show={showDropdown && filteredDropdownItems.length > 0} as={InputGroup}>  
                    <Dropdown.Menu style={{maxHeight: 300, overflowY: 'auto'}}>
                        {dropdownItems}
                    </Dropdown.Menu>
                </Dropdown>
                <ValidationFeedback stateProps={stateProps} showValidation={showValidation}/>
            </div>
        </OptionalCol>
    )   
}

