/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import { validateUsd } from "../payrollTools";
import { encodeDecimal, getFakeUID } from "../../../../tools";
import NDBonus from "./NDBonus";

export default class DBonus {
    uid;
    type;
    amount;

    constructor(uid, type, amount) {
        this.uid = uid;
        this.type = type;
        this.amount = amount;
    }

    static decode(obj) {
        return new DBonus(obj.uid ?? getFakeUID(), obj.type, obj.amount);
    }

    encode() {
        return {
            type: this.type,
            amount: encodeDecimal(this.amount, 3, 2, true)
        }
    }
    
    duplicate() {
        return new NDBonus(getFakeUID(), this.type, this.amount);
    }

    getAmount() {
        return validateUsd(this.amount);
    }
    
    static dBonusTypes = [
        'Holiday Bonus',
        'Other Discretionary Bonus'
    ];
}