/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/
import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "react-bootstrap/Button";
import Spinner from 'react-bootstrap/Spinner';

export default function CustomButton({style, isLoading, onClick, disabled, icon, stateObject, uniqueKey, narrow, variant, children}) {

    const [isValid, setIsValid] = useState(true);

    useEffect(() => {
        stateObject?.subscribeToValidation(uniqueKey, (isValid) => setTimeout(() => {
            setIsValid(isValid);
        }));
    }, [stateObject, uniqueKey]);

    const handleOnClick = () => {
        if (isValid) {
            if (onClick) {
                onClick();
            }
        } else {
            stateObject?.showValidationMessages();
        }
    }

    return (
        <Button variant={variant ?? 'outline-primary'} disabled={disabled || isLoading} onClick={handleOnClick} style={{padding: narrow ? '0px 6px 0px 6px' : undefined, opacity: !isValid ? 0.65 : undefined, ...style}}>
            { isLoading ? <Spinner size='sm'/> : 
                <div style={{display: 'flex', gap: 8, alignItems: 'center', justifyContent: 'center'}}>
                    {children}
                    { icon &&
                        <FontAwesomeIcon icon={icon}/>
                    }
                </div>
            }
        </Button>
    );
    
}