/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/


import React from "react";
import { Page, Document, View, Text, Image } from "@react-pdf/renderer";
import moment from "moment";
import logo from '../../../../assets/TTA-Logo-150.png';
import Cookies from "universal-cookie";

export default function PDFTimesheet({data, selectedTerminals}) {

    const tables = [];
    
    selectedTerminals.forEach((terminal)=>{
        const usersWithRows = [];
        const usersWithoutRows = [];
        const usersInTerminal = data.users.filter((user)=>{
            return user.terminalIdentifier == terminal.uid
        });

        const sortedUsers = usersInTerminal.sort((a, b) => a.lastName.localeCompare(b.lastName));
        sortedUsers.forEach((user) => {
            
            const timeSheetEntriesForUser = data.timesheets.filter((timesheet)=>{
                return timesheet.companyUserIdentifier == user.companyUserIdentifier;
            });
          
            const rows = timeSheetEntriesForUser.map((entry)=>{
                const timeWorked = moment.duration(moment(entry.outTime).diff(moment(entry.inTime)));
                return(
                    <View key={entry.uid} style={{display:'flex', flexDirection:'row', alignContent:'center', borderTop:'1px gray solid', padding: 4}}>
                        <Text style={{flex: 1}}>{moment(entry.inTime).format('MMM D, YYYY')}</Text>
                        <Text style={{flex: 1}}>{moment(entry.inTime).format('h:mm A')}</Text>
                        {entry.outTime ? <Text style={{flex: 1}}>{moment(entry.outTime).isSame(moment(entry.inTime),'day') ? (moment(entry.outTime).format('h:mm A')) : moment(entry.outTime).format('MMM D, YYYY hh:mm A')}</Text> : <View style={{flex: 1}}/>}
                        {entry.outTime ? <Text style={{flex: 1}}>{fractionalHoursConvertor(timeWorked.asHours())}</Text>: <View style={{flex: 1}}/>}
                    </View>
                ) 
            }); 

            const sum = timeSheetEntriesForUser.reduce((acc, el)=>{   
                return acc + moment.duration(moment(el.outTime).diff(moment(el.inTime))).asHours()   
            }, 0);

            if(rows.length > 0){
                usersWithRows.push(
                    <View key={user.uid} style={{display:'flex', flexDirection:'column', gap: 8, marginBottom: 24}} wrap={true}>
                        <View style={{display: 'flex', flexDirection:'row',justifyContent:'space-between', alignContent:'center'}}>
                            <Text style={{fontSize: 13}}>{user.lastName + ', ' + user.firstName + ' ' + (user.middleName ? user.middleName : '')}</Text>
                            <Text style={{fontSize: 11}}>{terminal.name}</Text>  
                        </View>
                        <View style={{border: '1px gray solid', width: '100%', fontSize: 9}}>
                            <View style={{flexDirection:'row', padding: 4}}>
                                <View style={{flex: 1}}>
                                    <Text style={{fontWeight: 'bold', fontSize: '11'}}>Date</Text>
                                </View>
                                <View style={{flex: 1}}>
                                    <Text style={{fontWeight: 'bold', fontSize: '11'}}>In Punch</Text>
                                </View>
                                <View style={{flex: 1}}>
                                    <Text style={{fontWeight: 'bold', fontSize: '11'}}>Out Punch</Text>
                                </View>
                                <View style={{flex: 1}}>
                                    <Text style={{fontWeight: 'bold', fontSize: '11', marginLeft:-5}}>Shift Time</Text>
                                </View>
                            </View>
                            {rows}
                        </View>
                        <View>
                            <Text style={{fontSize:11}}>Total Time: {sum ? fractionalHoursConvertor(sum) : 0}</Text>
                        </View>
                    </View>
                )
            }else if(user.title){
                usersWithoutRows.push(
                    <View key={user.uid} style={{display:'flex', flexDirection:'column', gap: 8, marginBottom: 24}} wrap={false}>
                        <View style={{display: 'flex', flexDirection:'row',justifyContent:'space-between', alignContent:'center'}}>
                            <Text style={{fontSize: 13}}>{user.lastName + ', ' + user.firstName + ' ' + (user.middleName ? user.middleName : '')}</Text>
                            <Text style={{fontSize: 11}}>{terminal.name}</Text>  
                        </View>
                        <View style={{border: '1px gray solid', width: '100%', fontSize: 9}}>
                            <View style={{flexDirection:'row', padding: 4}}>
                                <View style={{flex: 1}}>
                                    <Text style={{fontWeight: 'bold', fontSize:'11'}}>Date</Text>
                                </View>
                                <View style={{flex: 1}}>
                                    <Text style={{fontWeight: 'bold', fontSize:'11'}}>In Punch</Text>
                                </View>
                                <View style={{flex: 1}}>
                                    <Text style={{fontWeight: 'bold', fontSize: '11'}}>Out Punch</Text>
                                </View>
                                <View style={{flex: 1}}>
                                    <Text style={{fontWeight: 'bold', fontSize: '11', marginLeft:-5}}>Shift Time</Text>
                                </View>
                            </View>
                            {rows}
                        </View>
                        <View>
                            <Text style={{fontSize:11}}>Total Time: {sum ? fractionalHoursConvertor(sum) : 0}</Text>
                        </View>
                    </View>
                )
            }
            
        });
        tables.push(...usersWithRows, ...usersWithoutRows);
    });
    
    return (
        <Document> 
            <Page size='LETTER' style={{padding: '0.5in', flexDirection: 'column'}}>
            <View fixed style={{flexDirection: 'row', width: '100%', justifyContent: 'space-between', marginBottom:10}}>
                    <Image src={logo} style={{width: 100}}/>
                    <Text style={{fontSize: 12, color: 'gray'}}>{new Cookies().get('companyName')}</Text>
                </View>
                <Text style={{textAlign: 'center', paddingBottom: 4, textDecoration: 'underline', fontSize: 16}}>Timesheet Report</Text>
                <Text style={{opacity: 0.5, textAlign: 'center', fontSize: 14}}>{moment(data.startDate).format('MMM D, YYYY')+' - '+moment(data.endDate).format('MMM D, YYYY')}</Text>
                {tables}
            </Page>
        </Document>
    )
}

function fractionalHoursConvertor(workTime){
   const hours = Math.floor(workTime);
   const fractional = workTime - hours;
   const minutes = Math.ceil(fractional * 60)

   let time;
   if(minutes < 10){
        time=hours+':0'+minutes
   } else { 
        time=hours+':'+minutes;
   }
   return time;
}
  

