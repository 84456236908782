/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, { useState } from "react";
import Modal from 'react-bootstrap/Modal';
import { usdFormatter } from "../../../../../../../tools.js";
import HRUserLoan from "../../../../../HumanResources/HRUserLoan/HRUserLoan.js";
import { ApiRequest } from "../../../../../../../ApiManager.tsx";
import PayrollEntry from "../../../../Models/PayrollEntry.js";
import { toast } from "react-toastify";
import { Button, Form, InputGroup } from "react-bootstrap";
import CustomButton from "../../../../../../../components/CustomButton.js";
import CustomToolTip from "../../../../../../../components/CustomToolTip.js";
import PayrollEntryPaySection from "../../Pay/PayrollEntryPaySection.js";
import PayrollLoanItem from "./PayrollLoanItem.js";
import QuickTable from "../../../../../../../components/QuickTable.js";

export default function PayrollEmployeeLoansEditor({entry, handleSet, payrollPeriod}) {
    const [showModal, setShowModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedEmployee, _] = useState({companyUserIdentifier: entry.companyUserIdentifier});

    function handleRecalculateLoans() {
        new ApiRequest('payroll', 'recalculateEntry', setIsLoading, (response) => {
            const payrollEntry = new PayrollEntry(response.payrollEntry);
            payrollEntry.loans.forEach((loan) => {
                const preexistingLoan = entry.loans.find(l => l.loanIdentifier == loan.loanIdentifier);
                if (preexistingLoan && !preexistingLoan.isEnabled) {
                    loan.isEnabled = false;
                }
            })

            handleSet(entry.companyUserIdentifier, 'loans', payrollEntry.loans);
            toast.success(`Successfully recalculated PTO for ${entry.firstName} ${entry.lastName}`);
        }).withNoAlertOnSuccess().withData({companyUserIdentifier: entry.companyUserIdentifier, payrollIdentifier: payrollPeriod.uid}).send()
    }

    function handleSetLoan(loanIdentifier, key, value) {
        const newArray = Array.from(entry.loans);
        const loan = entry.loans.find(l => l.loanIdentifier == loanIdentifier);
        if (loan) {
            loan[key] = value;
            handleSet(entry.companyUserIdentifier, 'loans', newArray);
        }
    }

    const loanRows = entry.loans.map((loan) => {
        return (
            <PayrollLoanItem key={loan.loanIdentifier} loan={loan} handleSetLoan={handleSetLoan}/>
        )
    });


    return (
        <>
            <PayrollEntryPaySection title={'LOANS & FINE TICKET DAMAGE'}>
                <div style={{display: 'flex', flexDirection: 'column', gap: 6}}>
                    { loanRows.length > 0 && 
                        <div style={{overflowX: 'auto'}}>
                            <QuickTable noMargin noWrap size='sm' rows={loanRows} headers={['Type', 'Name', 'Amount', 'Enabled']} widths={[1, null, 1, 1]}/>
                        </div>
                    }
                    <div style={{display: 'flex', gap: 6, flexWrap: 'wrap'}}>
                        <div style={{flex: 1, display: 'flex', alignItems: 'center'}}>
                            { loanRows.length == 0 && 
                                <span style={{opacity: 0.5, fontWeight: 'bold', marginLeft: 6}}>None</span>
                            }
                        </div>
                        <Button variant="outline-primary" onClick={() => {setShowModal(true)}}>View/Edit Loans & FTD</Button>
                        <InputGroup style={{width: 'auto', flexWrap: 'nowrap'}}>
                            <CustomButton isLoading={isLoading} onClick={handleRecalculateLoans}>Recalculate Loans & FTD</CustomButton>
                            <InputGroup.Text>
                                <CustomToolTip text="Recalculate Loans & FTD to import the current Loan & FTD data from our system into this employee's payroll entry."/>
                            </InputGroup.Text>
                        </InputGroup>
                    </div>
                </div>
            </PayrollEntryPaySection>
            <Modal show={showModal} onHide={() => {setShowModal(false)}} size='xl'>
                <Modal.Header closeButton>
                    <Modal.Title>{`Loans & Fine Ticket Damage for ${entry.name()}`}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <HRUserLoan selectedEmployee={selectedEmployee} startDateProp={payrollPeriod.periodStart} endDateProp={payrollPeriod.periodEnd}/>
                </Modal.Body>
            </Modal>
        </>
    )
}
