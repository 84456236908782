/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, { useState } from 'react';
import CustomButton from '../../../../components/CustomButton'
import Modal from 'react-bootstrap/Modal';
import Cookies from 'universal-cookie';
import CustomControl from '../../../../components/CustomControl';
import { ApiRequest } from '../../../../ApiManager.tsx';
import AlertConfirmation from '../../../../components/AlertModals/AlertConfirmation.js';
import moment from 'moment';
import CustomTextArea from '../../../../components/CustomStateControls/CustomTextArea.js';

export default function HRNotesEditor({onHide, note, handleCrud, selectedEmployee}) {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const [title, setTitle] = useState(note ? note.title : '');
    const [body, setBody] = useState(note ? note.body : '');
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const userData = new Cookies().get('userData');
    const auth = new Cookies().get('auth');
    const isDisabled = note?.author !== userData.companyUserIdentifier && (userData.title !== 'AO' && !auth.adminIdentifier);

    function handleCreateNote() {
        const userData = new Cookies().get('userData');

        const newNote = {
            uid: note ? note.uid : undefined,
            submissionDate: note ? note.submissionDate : undefined,
            author: userData.companyUserIdentifier,
            adminAuthor: userData.adminIdentifier,
            user: userData.companyUserIdentifier ? userData : undefined,
            companyUserIdentifier: selectedEmployee.companyUserIdentifier,
            title: title,
            body: body,
        }

        new ApiRequest('hr', 'createEmployeeNote', setIsSubmitting, (response) => {
            newNote.uid = response.uid;
            handleCrud('create', newNote);
            onHide();
        }).withData({note: newNote}).send();
    }

    function handleUpdateNote(){
        const newNote = {
            ...note,
            title: title,
            body: body,
        }
        new ApiRequest('hr', 'updateEmployeeNote', setIsSubmitting, () => {
            newNote.lastUpdated = moment();
            handleCrud('update', newNote);
            onHide();
        }).withData({note: newNote}).send()
    }

    function handleDeleteNote(){
        new ApiRequest('hr', 'deleteEmployeeNote', setIsDeleting, () => {
            handleCrud('delete', note);
            onHide();
        }).withUid(note.uid).send();
    }

    return (
        <>
            <Modal.Header closeButton>
                <Modal.Title>{note ? 'Employee Note' : 'Creating Employee Note'}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <CustomControl breakpoints={{xl: 12}} disabled={isSubmitting || isDisabled} title='Title' value={title} setValue={setTitle} maxLength={30}/>
                <CustomTextArea breakpoints={{xl: 12}} placeholder='Notes' rows={12} max={2000} value={body} setValue={setBody}/>
            </Modal.Body>
            <Modal.Footer>
            { !note ?
                <CustomButton isLoading={isSubmitting} onClick={handleCreateNote}>Submit</CustomButton>
            : 
                <>
                    {isDisabled ?
                        <h6 style={{color: 'gray'}}>Not Editable</h6>
                    :
                        <>
                        <button onClick={() => setShowDeleteModal(true)} style={{marginRight: 'auto',border: 'none', marginRight: 2, boxShadow: 'none', color: 'red', backgroundColor: 'transparent'}}>Delete Note</button>
                        <CustomButton isLoading={isSubmitting} variant='outline-primary' onClick={handleUpdateNote}>Save Changes</CustomButton>
                        </>
                    }
                </>
            }
            </Modal.Footer>
            <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
                <AlertConfirmation isLoading={isDeleting} variant='outline-danger' title='Delete Employee Note' buttonLabel='Delete' message='Are you sure you want to delete this employee note?' callBack={handleDeleteNote}/>
            </Modal>
        </>
    )
}