/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, { forwardRef, useEffect, useState } from 'react';
import moment from 'moment';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { Accordion, Form, ListGroup, OverlayTrigger, Popover, Table } from 'react-bootstrap';
import CustomButton from '../../../../components/CustomButton';
import Cookies from 'universal-cookie';
import { ApiRequest } from '../../../../ApiManager.tsx';
import LoadingWrapper from '../../../../components/LoadingWrapper.js';
import QuickTable from '../../../../components/QuickTable.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import KeyValueRow from '../../../../components/KeyValueRow.js';
import { faBuilding, faCheck, faChevronLeft, faChevronRight, faEllipsis, faLocationDot, faPersonCircleQuestion, faPhone, faTruck, faX } from '@fortawesome/free-solid-svg-icons';
import HRUserScheduleTimeOffModal from './HRUserScheduleTimeOffModal.js';
import HRUserScheduleAbsenceModal from './HRUserScheduleAbsenceModal.js';
import HRUserScheduleReportAbsenceModal from './HRUserScheduleReportAbsenceModal.js';
import HRCreateTimeOffModal from './HRCreateTimeOffModal.js';

export default function HRUserSchedule({selectedEmployee, selectedCompany}){
    const [isLoading, setIsLoading] = useState(false);
    const [scheduleItems, setScheduleItems] = useState([]);
    const [routes, setRoutes] = useState([]);
    const [absences, setAbsences] = useState([]);
    const [timeOff, setTimeOff] = useState([]);
    const [selectedAbsence, setSelectedAbsence] = useState(null);
    const [selectedTimeOff, setSelectedTimeOff] = useState(null);
    const [modalSwitch, setModalSwitch] = useState('none');
    const [startOfMonth, setStartOfMonth] = useState(moment().startOf('month').format('YYYY-MM-DD'));
    const calendarStartDate = moment(startOfMonth).subtract(moment(startOfMonth).day(), 'days').format('YYYY-MM-DD');

    useEffect(() => {
        loadData();
    }, [selectedEmployee])

    function loadData(){
        new ApiRequest('hr', 'getUserSchedule', setIsLoading, (response) => {
            setAbsences(response.absences)
            setTimeOff(response.timeOff)
            setRoutes(response.routes);
        }).withData({companyUserIdentifier: selectedEmployee.companyUserIdentifier}).withNoAlertOnSuccess().send();
    }

    function handleScheduleItemsCrud(type, target, uid, value){
        if(type === 'timeOff'){
            const newTimeOff = Array.from(timeOff)
            switch(target){
                case 'create':
                    newTimeOff.push(value)
                    setTimeOff(newTimeOff);
                case 'update':
                    const index = newTimeOff.findIndex(t => t.uid === uid);
                    newTimeOff[index] = value;
                    setTimeOff(newTimeOff);
                    break;
                case 'delete':
                    setTimeOff(newTimeOff.filter(t => t.uid !== uid));
                    break;
            }
        }

        if(type === 'absence'){
            const newAbsences = Array.from(absences);
            switch(target){
                case 'create':
                    newAbsences.push(value)
                    setAbsences(newAbsences);
                case 'update':
                    const index = newAbsences.findIndex(a => a.uid === uid);
                    newAbsences[index] = value;
                    setAbsences(newAbsences);
                    break;
                case 'delete':
                    setAbsences(newAbsences.filter(a => a.uid !== uid));
                    break;
            }
        }

        hideModal();
    }


    function handleTimeOffCreationCrud(response, timeOff){
        const newTimeOff = structuredClone(timeOff);
        newTimeOff.uid = response.uid;
        newTimeOff.status = 1;
        handleScheduleItemsCrud('timeOff', 'create', null, newTimeOff)
    }

    function hideModal(){
        setModalSwitch('');
        setSelectedTimeOff(null);
        setSelectedAbsence(null);
    }

    const pendingTimeOff = timeOff.filter((timeOff) => {
        return timeOff.status == 0;
    });       

    const pendingTimeOffElements = pendingTimeOff.map((timeOff) => {
        return (
            <tr key={timeOff.uid} style={{cursor: 'pointer'}} onClick={() => {setSelectedTimeOff(timeOff); setModalSwitch('viewTimeOff')}}>
                <td>{moment(timeOff.startDate).format('MMM D, YYYY')}</td>
                <td>{moment(timeOff.endDate).format('MMM D, YYYY')}</td>
                <td>{moment(timeOff.submissionDate).format('MMM D, YYYY')}</td>
                <td className='td-ellipsis'><span>{timeOff.reason}</span></td>
            </tr>
        )
    });

    const mobilePendingTimeOffElements = (
        <ListGroup>
            {pendingTimeOff.map((timeOff) => {
                return (
                    <ListGroup.Item key={timeOff.uid} action onClick={() => {setSelectedTimeOff(timeOff); setModalSwitch('viewTimeOff')}}>
                        <KeyValueRow title={'Start Date'} value={moment(timeOff.startDate).format('MMM D, YYYY')}/>
                        <KeyValueRow title={'End Date'} value={moment(timeOff.endDate).format('MMM D, YYYY')}/>
                        <KeyValueRow title={'Submission Date'} value={moment(timeOff.submissionDate).format('MMM D, YYYY')}/>
                        <div className='td-ellipsis'>
                            <span style={{margin: 0}}><b>Reason: &nbsp;</b>{timeOff.reason}</span>
                        </div>
                    </ListGroup.Item>
                )
            })}
        </ListGroup>
    )

    const content = (
        <div style={{height: '100%', display: 'flex', flexDirection: 'column', overflowY: 'auto'}}>
            <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 12, marginBottom: 12, justifyContent:'space-between'}}>
                <div style={{display: 'flex', alignItems: 'center', gap: 4, flexWrap: 'wrap'}}>
                    {<CustomButton onClick={() => setModalSwitch('showCreateTimeOff')}>Schedule Time Off</CustomButton>}
                    <div style={{display:'flex', justifyContent:'flex-end'}}>
                        <Button variant={'outline-primary'} onClick={() => setModalSwitch('reportAbsence')}>Report Call Off / No-Show</Button>
                    </div>
                </div>
            </div>
            <div style={{flex: 1}}>
                { pendingTimeOffElements.length > 0 &&
                    <div style={{marginBottom: 30}}>
                        <Accordion alwaysOpen defaultActiveKey='0'>
                            <Accordion.Item eventKey='0'>
                                <Accordion.Header><b>Pending Requests</b></Accordion.Header>
                                <Accordion.Body>
                                    <div className='desktop-block'>
                                        <QuickTable headers={['Start Date', 'End Date', 'Submission Date', 'Reason']} widths={[140, 140, 170, null]} rows={pendingTimeOffElements} hover size='sm'/>
                                    </div>
                                    <div className='mobile-block'>
                                        {mobilePendingTimeOffElements}
                                    </div>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </div>
                }
                <div style={{width: '100%', flex: 1, height: '100%', display: 'flex', flexDirection: 'column'}}>
                    <h3 style={{textAlign: 'center', marginBottom: 12}}>Schedule History</h3>
                    <div style={{display: 'flex', flexDirection: 'row', gap: 12, marginBottom: 12}}>
                        <Button variant={'outline-primary'} style={{fontSize: '3vh', padding: '2px 8px 2px 8px', display: 'flex', justifyContent: 'center', alignItems: 'center'}} onClick={() => {setStartOfMonth(moment(startOfMonth).subtract(1, 'month'))}}>
                            <FontAwesomeIcon icon={faChevronLeft}/>
                        </Button>
                        <Form.Control type='month' value={moment(startOfMonth).format('YYYY-MM')} onChange={(event) => {setStartOfMonth(moment(event.target.value ? event.target.value : undefined).startOf('month').format('YYYY-MM-DD'))}}/>
                        <Button variant={'outline-primary'} style={{fontSize: '3vh', padding: '2px 8px 2px 8px', display: 'flex', justifyContent: 'center', alignItems: 'center'}} onClick={() => {setStartOfMonth(moment(startOfMonth).add(1, 'month'))}}>
                            <FontAwesomeIcon icon={faChevronRight}/>
                        </Button>
                    </div>
                    <HRUserScheduleCalendar routes={routes} absences={absences} timeOff={timeOff} startOfMonth={startOfMonth} calendarStartDate={calendarStartDate} setSelectedAbsence={setSelectedAbsence} setSelectedTimeOff={setSelectedTimeOff} setModalSwitch={setModalSwitch}/>
                </div>
            </div>
        </div>
    )
    

    return (
        <>
            <LoadingWrapper isLoading={isLoading}>{content}</LoadingWrapper>
            <Modal centered show={modalSwitch === 'viewAbsence'} onHide={hideModal}>
                {selectedAbsence && <HRUserScheduleAbsenceModal absence={selectedAbsence} handleScheduleItemsCrud={handleScheduleItemsCrud}/>}
            </Modal>
            <Modal centered size='lg' show={modalSwitch === 'viewTimeOff'} onHide={hideModal}>
                {selectedTimeOff && <HRUserScheduleTimeOffModal selectedCompany={selectedCompany} timeOff={selectedTimeOff} handleScheduleItemsCrud={handleScheduleItemsCrud}/>}
            </Modal>
            <Modal centered show={modalSwitch === 'reportAbsence'} onHide={hideModal}>
                <HRUserScheduleReportAbsenceModal companyUserIdentifier={selectedEmployee.companyUserIdentifier} handleScheduleItemsCrud={handleScheduleItemsCrud}/>
            </Modal>
            <Modal centered show={modalSwitch === 'showCreateTimeOff'} onHide={hideModal} size='lg'>
                <HRCreateTimeOffModal selectedCompany={selectedCompany} companyUserIdentifier={selectedEmployee.companyUserIdentifier} timeOff={timeOff} handleCrud={handleTimeOffCreationCrud}/>
            </Modal> 
        </>
    )
}

function HRUserScheduleCalendar({routes, timeOff, absences, calendarStartDate, startOfMonth, setSelectedAbsence, setSelectedTimeOff, setModalSwitch}) {
    const weekRows = [0, 1, 2, 3, 4, 5].map((weekIndex) => {
        const dayElements = [0, 1, 2, 3, 4, 5, 6].map((dayIndex) => {
            const date = moment(calendarStartDate).add(weekIndex, 'weeks').add(dayIndex, 'days');
            const routeOnDay = routes.find(r => r.date == date.format('YYYY-MM-DD'));
            const timeOffOnDay = timeOff.find(t => date.format('YYYY-MM-DD') >= t.startDate && date.format('YYYY-MM-DD') <= t.endDate);
            const absenceOnDay = absences.find(a => a.date ==  date.format('YYYY-MM-DD'));

            return (
                <td key={dayIndex} style={{position: 'relative'}}>
                    <div className='desktop-flex' style={{position: 'absolute', top: 0, bottom: 0, left: 0, right: 0, height: '100%', flexDirection: 'column', justifyContent: 'flex-end', padding: 3, gap: 4}}>
                        { routeOnDay && <RouteItem route={routeOnDay}/>}
                        { timeOffOnDay && <TimeOffItem timeOff={timeOffOnDay} setSelectedTimeOff={setSelectedTimeOff} setModalSwitch={setModalSwitch}/>}
                        { absenceOnDay && <AbsenceItem absence={absenceOnDay} setSelectedAbsence={setSelectedAbsence} setModalSwitch={setModalSwitch}/>}
                    </div>
                    <div className='mobile-flex' style={{position: 'absolute', top: 0, bottom: 0, left: 0, right: 0, height: '100%', flexDirection: 'column', justifyContent: 'flex-end', alignItems: 'center', padding: 3, gap: 4}}>
                        { routeOnDay && <div style={{cursor: 'pointer', width: 18, height: 18, backgroundColor: 'var(--bs-primary)', borderRadius: '50%'}}/>}
                        { timeOffOnDay && <div onClick={() => {setSelectedTimeOff(timeOffOnDay); setModalSwitch('viewTimeOff')}} style={{cursor: 'pointer', width: 18, height: 18, backgroundColor: timeOffOnDay.status == -1 ? 'red' : timeOffOnDay.status == 0 ? 'gold' : 'green', borderRadius: '50%'}}/>}
                        { absenceOnDay && <div onClick={() => {setSelectedAbsence(absenceOnDay); setModalSwitch('viewAbsence')}} style={{cursor: 'pointer', width: 18, height: 18, backgroundColor: absenceOnDay.notifiedManager == 1 ? 'purple' : 'lightgray', borderRadius: '50%'}}/>}
                    </div>
                    <p style={{position: 'absolute', right: 4, top: 4, fontSize: 14, lineHeight: 1}}>{date.format('D')}</p>
                    { date.format('MMM') !== moment(startOfMonth).format('MMM') && 
                        <div style={{position: 'absolute', top: 0, bottom: 0, left: 0, right: 0, backgroundColor: 'black', opacity: 0.075, pointerEvents: 'none'}}/>
                    }
                    { date.isSame(moment(), 'day') && 
                        <div style={{position: 'absolute', top: 0, bottom: 0, left: 0, right: 0, backgroundColor: 'var(--bs-primary)', opacity: 0.075, pointerEvents: 'none'}}/>
                    }
                </td>
            );
        })

        return (
            <tr key={weekIndex} style={{height: '16.66%'}}>
                {dayElements}
            </tr>
        );
    });

    const columnHeaders = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'].map((str) => {
        return (<th key={str} style={{width: '14.286%', opacity: 0.66, fontSize: 14}}>{str}</th>);
    });

    const columnHeadersMobile = ['S', 'M', 'T', 'W', 'T', 'F', 'S'].map((str, index) => {
        return (<th key={index} style={{width: '14.286%', opacity: 0.66, fontSize: 14}}>{str}</th>);
    });

    return (
        <Table bordered size='sm' style={{width: '100%', flex: 1, margin: 0}}>
            <thead>
                <tr className='desktop-table-row' style={{textAlign: 'center'}}>
                    {columnHeaders}
                </tr>
                <tr className='mobile-table-row' style={{textAlign: 'center'}}>
                    {columnHeadersMobile}
                </tr>
            </thead>
            <tbody>
                {weekRows}
            </tbody>
        </Table>
    );
}

function RouteItem({route}) {
    return (
        <div style={{backgroundColor: 'white', width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center', fontSize: 12, whiteSpace: 'nowrap', padding: 6, border: '1px solid var(--bs-primary)', borderLeft: '6px solid var(--bs-primary)', borderTopRightRadius: 6, borderBottomRightRadius: 6}}>
            <span style={{overflow: 'hidden', textOverflow: 'ellipsis', fontWeight: 'bold'}}>Route</span>
            <FontAwesomeIcon icon={faTruck} style={{color: 'var(--bs-primary)'}}/>
        </div>
    )
}

function TimeOffItem({timeOff, setSelectedTimeOff, setModalSwitch}) {
    const icon = timeOff.status == -1 ? faX : timeOff.status == 0 ? faEllipsis : faCheck;
    const color = timeOff.status == -1 ? 'red' : timeOff.status == 0 ? 'gold' : 'green';

    return (
        <div onClick={() => {setSelectedTimeOff(timeOff); setModalSwitch('viewTimeOff')}} style={{cursor: 'pointer', backgroundColor: 'white', width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center', fontSize: 12, whiteSpace: 'nowrap', padding: 6, border: `1px solid ${color}`, borderLeft: `6px solid ${color}`, borderTopRightRadius: 6, borderBottomRightRadius: 6}}>
            <span style={{overflow: 'hidden', textOverflow: 'ellipsis', fontWeight: 'bold'}}>Time-Off Request</span>
            <FontAwesomeIcon icon={icon} style={{color: color, border: `1px solid ${color}`, width: 10, height: 10, borderRadius: '50%', padding: 2}}/>
        </div>
    )
}

function AbsenceItem({absence, setSelectedAbsence, setModalSwitch}){
    const icon = absence.notifiedManager == 1 ? faPhone : faPersonCircleQuestion;
    const color = absence.notifiedManager == 1 ? 'purple' : 'lightgray';

    return (
        <div onClick={() => {setSelectedAbsence(absence); setModalSwitch('viewAbsence')}} style={{cursor: 'pointer', backgroundColor: 'white', width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center', fontSize: 12, whiteSpace: 'nowrap', padding: 6, border: `1px solid ${color}`, borderLeft: `6px solid ${color}`, borderTopRightRadius: 6, borderBottomRightRadius: 6}}>
            <span style={{overflow: 'hidden', textOverflow: 'ellipsis', fontWeight: 'bold'}}>{absence.notifiedManager == 1 ? 'Call Off' : 'No Show'}</span>
            <FontAwesomeIcon icon={icon} style={{color: color, border: `1px solid ${color}`, width: 10, height: 10, borderRadius: '50%', padding: 2}}/>
        </div>
    )
}