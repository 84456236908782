

/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import Decoder from "../../../../decoding";
import Address from "../../../../models/Address";
import StateObject from "../../../../state/StateObject.tsx";

export default class Client extends StateObject{
    uid;
    companyIdentifier;
    name;
    address;
    defaultLoadTime; // In Minutes

    constructor(
        uid,
        companyIdentifier,
        name,
        address,
        defaultLoadTime,
    ){
        super();
        this.uid = uid;
        this.companyIdentifier = companyIdentifier;
        this.name = name;
        this.address = address;
        this.defaultLoadTime = defaultLoadTime;
        this.initChildren();
    }

    static initDefault(){
        return new Client(
            -1,
            -1,
            '',
            undefined,
            true
        )
    }

    static decode(json){
        const decoder = new Decoder(json);
        const uid = decoder.decode('uid', Decoder.Uid);
        const companyIdentifier = decoder.decode('companyIdentifier', Decoder.Uid);
        const name = decoder.decode('name', Decoder.String);
        const address = Address.decode(json.address);
        const defaultLoadTime = decoder.decode('defaultLoadTime', Decoder.Integer);

        if(decoder.checkForErrors()){
            return new Client(uid, companyIdentifier, name, address, defaultLoadTime)
        } else {
            return Client.initDefault()
        }
    }

    encode(){
        return {
            uid: this.uid,
            companyIdentifier: this.companyIdentifier,
            name: this.name,
            address: this.address?.encode(),
            defaultLoadTime: this.defaultLoadTime,
        }
    }
}