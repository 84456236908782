/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import { toast } from "react-toastify";
import Cookies from "universal-cookie";

export default class ApiManager {
    static DEV_MODE = false;
    static API_URL = ApiManager.DEV_MODE ? "http://192.168.1.52/lh/public/" : "https://api.mytacticaltrucking.com";
    static API_VERSION_CODE = "1.0.0";
    static instance: ApiManager | undefined;
    
    setAuth: (value: any) => void;
    setShowTimeout: (value: boolean) => void;
    handleCheckAuthentication: () => void

    private currentRequest: ApiRequest | null;

    private constructor(setAuth: (value: any) => void, setShowTimeout: (value: boolean) => void, handleCheckAuthentication: () => void) {
        this.setAuth = setAuth;
        this.setShowTimeout = setShowTimeout;
        this.handleCheckAuthentication = handleCheckAuthentication;
    }

    static initGlobalInstance(setAuth: (value: any) => void, setShowTimeout: (value: boolean) => void, handleCheckAuthentication: () => void) {
        ApiManager.instance = new ApiManager(setAuth, setShowTimeout, handleCheckAuthentication);
    }

    setCurrentRequest(request: ApiRequest) {
        if (this.currentRequest) {
            this.currentRequest.cancel();
        }
        this.currentRequest = request;
    }

    resendRequest() {
        this.currentRequest?.resend();
    }

}

export class ApiRequest {
    data: any;
    setIsLoading: (value: boolean) => void;
    onSuccess: (response: any, status: number) => void;
    onFail: ((response: any, status: number) => void) | undefined;

    returnStatuses: number[] = [200];
    noAlertOnStatuses: number[] = [];
    alertOnSuccess: boolean = true;

    abortController: AbortController | null = null;
    timeout: NodeJS.Timeout | null = null;
    cancelled: boolean = false;

    constructor(type: string, target: string, setIsLoading: (value: boolean) => void, onSuccess: (response: any, status: number) => void = () => {}, onFail: ((response: any, status: number) => void) | undefined) {
        const packagedData: object = {type: type, target: target, authToken: new Cookies().get('lhToken') ?? '', version: ApiManager.API_VERSION_CODE};
        this.data = packagedData;
        this.setIsLoading = setIsLoading;
        this.onSuccess = onSuccess;
        this.onFail = onFail;
    }

    withData(data: any): ApiRequest {
        this.data = {...this.data, ...data};
        return this;
    }

    withUid(uid: number): ApiRequest {
        this.data.uid = uid;
        return this;
    }

    withTimezone() {
        this.data.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        return this;
    }

    withReturnStatuses(returnStatuses: [number]): ApiRequest {
        this.returnStatuses = returnStatuses;
        return this;
    }

    withNoAlertOnStatuses(statuses: [number]): ApiRequest{
        this.noAlertOnStatuses = statuses;
        return this;
    }

    withNoAlertOnSuccess(): ApiRequest {
        this.alertOnSuccess = false;
        return this;
    }

    send() {
        if (ApiManager.instance) {
            ApiManager.instance.setCurrentRequest(this);
            this.execute();
        }
    }

    async sendInBackground() {
        if (ApiManager.DEV_MODE) {
            console.log("->", this.data);
        }
        const payload = {method: 'POST', headers: {'Accept': 'application/json', 'Content-Type': 'application/json'}, body: JSON.stringify(this.data)};
        
        try {
            const fetchResponse = await fetch(ApiManager.API_URL, {...payload});
            const responseObject = await fetchResponse.json();

            if (ApiManager.DEV_MODE) {
                console.log("<-", responseObject);
            }

            if (this.returnStatuses.includes(fetchResponse.status)) {
                this.onSuccess(responseObject, fetchResponse.status);
            } else if (fetchResponse.status == 401) {
                ApiManager.instance?.setAuth(null);
            } else {
                console.log(responseObject.message ?? 'Something went wrong');
            }
        } catch (error) {
            console.log(error.message);
        }
    }

    resend() {
        this.execute();
    }

    cancel() {
        this.cancelled = true;
        this.abortController?.abort();
        if (this.timeout) {
            clearTimeout(this.timeout);
        }
    }

    private async execute() {
        this.setIsLoading(true);
        this.abortController = new AbortController();
        this.timeout = setTimeout(() => this.abortController!.abort(), 8000);

        if (ApiManager.DEV_MODE) {
            console.log("->", this.data);
        }

        const payload = {method: 'POST', headers: {'Accept': 'application/json', 'Content-Type': 'application/json'}, body: JSON.stringify(this.data)};
        
        try {
            const fetchResponse = await fetch(ApiManager.API_URL, {...payload, signal: this.abortController.signal});
            if (this.timeout) {
                clearTimeout(this.timeout);
            }
            const responseObject = await fetchResponse.json();

            if (ApiManager.DEV_MODE) {
                console.log("<-", responseObject);
            }

            if (this.returnStatuses.includes(fetchResponse.status)) {
                if (this.alertOnSuccess) {
                    if(!this.noAlertOnStatuses.includes(fetchResponse.status)){
                        toast.success(responseObject.message ?? 'Success');
                    }
                }
                this.onSuccess(responseObject, fetchResponse.status);
            } else if (fetchResponse.status == 401) {
                ApiManager.instance?.setAuth(null);
            } else {
                if(this.onFail){
                    this.onFail(responseObject, fetchResponse.status)
                }
                if(!this.noAlertOnStatuses.includes(fetchResponse.status)){
                    toast.error(responseObject.message ?? 'Something went wrong');
                }
            }
        } catch (error) {
            if (this.abortController.signal.aborted) {
                if (this.cancelled) {
                    console.log("Request cancelled");
                } else {
                    console.log("Request timed out");
                    ApiManager.instance?.setShowTimeout(true);
                }
            } else {
                console.log(error);
                toast.error('Something went wrong');
            }
        }
        this.setIsLoading(false);
    }
}

