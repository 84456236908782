/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import { useState } from "react"
import { Button, Col, Dropdown, Modal, Row } from "react-bootstrap";
import DropdownItemWithCheck from "../../../../components/DropdownItemWithCheck.js";
import CustomControl from "../../../../components/CustomStateControls/CustomControl.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import CustomButton from "../../../../components/CustomButton.js";
import { ApiRequest } from "../../../../ApiManager.tsx";
import AlertConfirmation from "../../../../components/AlertModals/AlertConfirmation.js";

export default function VehicleEditorModal({selectedVehicle, vehicles, setVehicles, hideModal, terminals}){
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const [vehiclesToCreate, setVehiclesToCreate] = useState([{name: '', terminalIdentifier: null}])
    const [vehicleToEdit, setVehicleToEdit] = useState(selectedVehicle);
    const [showDeleteModal, setShowDeleteModal] = useState(false);

    function handleCreateVehicles(){
        new ApiRequest('myCompany', 'createVehicles', setIsSubmitting, (response) => {
            let count = 0;
            vehiclesToCreate.forEach(v => v.uid = response.uids[count++]);
            setVehicles([...vehicles, ...vehiclesToCreate]);
            hideModal();
        }).withData({vehicles: vehiclesToCreate}).send()
    }

    function handleUpdateVehicle(){
        new ApiRequest('myCompany', 'updateVehicle', setIsSubmitting, () => {
            const newArray = Array.from(vehicles);
            newArray[newArray.findIndex(v => v.uid === selectedVehicle.uid)] = vehicleToEdit;
            setVehicles(newArray);
            hideModal();
        }).withData({vehicle: vehicleToEdit}).send();
    }

    function handleDeleteVehicle(){
        new ApiRequest('myCompany', 'deleteVehicle', setIsDeleting, () => {
            setVehicles(vehicles.filter(v => v.uid !== vehicleToEdit.uid));
            setShowDeleteModal(false);
            hideModal();
        }).withUid(vehicleToEdit.uid).send();
    }

    function handleAddVehicle(){
        setVehiclesToCreate([...vehiclesToCreate, {name: '', terminalIdentifier: null}])
    }

    function handleRemoveVehicle(index){
        const newArray = Array.from(vehiclesToCreate);
        const newVehicles = newArray.toSpliced(index, 1);
        setVehiclesToCreate(newVehicles);
    }

    function handleSetVehicles(key, index, value){
        const newArray = Array.from(vehiclesToCreate);
        newArray[index][key] = value;
        setVehiclesToCreate(newArray);
    }

    function handleSetVehicle(key, value){
        const newObject = structuredClone(vehicleToEdit);
        newObject[key] = value;
        setVehicleToEdit(newObject);
    }

    function checkIfVehiclesToCreateIsValid(){
       return vehiclesToCreate.reduce((acc, el) => {
            if(!acc)
                return false;

            if(!el.terminalIdentifier || !el.name){
                return false;
            }

            return acc;
       }, true)
    }
    
    const vehicleList = vehiclesToCreate.map((vehicle, index) => {
        return (
            <div key={index} style={{marginBottom: 32, padding: 10, border: '1px solid lightgray', borderRadius: 6}}>
                <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                    <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', fontWeight: 'bolder', margin: 0, height: 30, width: 30, marginBottom: 8, color: 'white', borderRadius: '100%', padding: 12, backgroundColor: 'var(--bs-primary)'}}>{index+1}</div>
                    <Button variant="outline-danger" style={{borderRadius: '100%', height: 30, width: 30, display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: 8}} onClick={() => handleRemoveVehicle(index)}><FontAwesomeIcon icon={faTrash}/></Button>
                </div>
                <Row key={index}>
                    <CustomControl floatingLabel breakpoints={{lg: 6}} maxLength={30} label={'Name'} value={vehicle.name} setValue={(value) => handleSetVehicles('name', index, value)}/>
                    <Col lg={6}>
                        <Dropdown>
                            <Dropdown.Toggle style={{width: '100%', height: 58}} variant="outline-primary">{vehicle.terminalIdentifier ? terminals.find(t => t.uid == vehicle.terminalIdentifier)?.name : 'Select a terminal'}</Dropdown.Toggle>
                            <Dropdown.Menu>
                                {terminals.map(t => {
                                    return <DropdownItemWithCheck key={t.uid} label={t.name} active={t.uid == vehicle.terminalIdentifier} onClick={() => handleSetVehicles('terminalIdentifier', index, t.uid)}/>
                                })}
                            </Dropdown.Menu>
                        </Dropdown>
                    </Col>
                </Row>
            </div>
        )
    })

    return ( 
        <>
            {selectedVehicle ?
                <>
                    <Modal.Header closeButton>
                        <Modal.Title>Edit Vehicle</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            <CustomControl breakpoints={{lg: 6}} floatingLabel label='Name' maxLength={30} value={vehicleToEdit.name} setValue={(value) => handleSetVehicle('name', value)}/>
                            <Col lg={6}>
                                <Dropdown>
                                    <Dropdown.Toggle style={{width: '100%', height: 58}} variant="outline-primary">{vehicleToEdit.terminalIdentifier ? terminals.find(t => t.uid == vehicleToEdit.terminalIdentifier)?.name : 'Select a terminal'}</Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        {terminals.map(t => {
                                            return <DropdownItemWithCheck key={t.uid} label={t.name} active={t.uid == vehicleToEdit.terminalIdentifier} onClick={() => handleSetVehicle('terminalIdentifier', t.uid)}/>
                                        })}
                                    </Dropdown.Menu>
                                </Dropdown>
                            </Col>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer >
                        <button onClick={() => setShowDeleteModal(true)} style={{marginRight: 'auto',border: 'none', marginRight: 2, boxShadow: 'none', color: 'red', backgroundColor: 'transparent'}}>Delete Vehicle</button>
                        <CustomButton isLoading={isSubmitting} variant='outline-primary' onClick={handleUpdateVehicle}>Save Changes</CustomButton>
                    </Modal.Footer>
                    <Modal centered show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
                        <AlertConfirmation variant='outline-danger' isLoading={isDeleting} callBack={handleDeleteVehicle} title='Delete Vehicle' message='Deleting this vehicle will also delete any routes that are associated with this vehicle (including routes within templates). Are you sure you want to delete this vehicle?' buttonLabel='Delete'/>
                    </Modal>
                </>
            :
                <>
                    <Modal.Header closeButton>
                        <Modal.Title>Add Vehicle(s)</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {vehicleList}
                        <Button variant="outline-primary" style={{marginTop: 12, marginLeft: 'auto', display: 'block'}} onClick={handleAddVehicle}><FontAwesomeIcon icon={faPlus}/> Add Vehicle</Button>
                    </Modal.Body>
                    <Modal.Footer>
                        <CustomButton isLoading={isSubmitting} variant='outline-primary' disabled={vehiclesToCreate.length < 1 || !checkIfVehiclesToCreateIsValid()} onClick={handleCreateVehicles}>Submit</CustomButton>
                    </Modal.Footer>
                </>
            }
        </>
    )
}