/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import { useState } from "react";
import { ApiRequest } from "../../../ApiManager.tsx";
import { Modal, Row } from "react-bootstrap";
import CustomDropDown from "../../../components/CustomStateControls/CustomDropDown.js";
import CustomButton from "../../../components/CustomButton.js";
import AlertConfirmation from "../../../components/AlertModals/AlertConfirmation.js";

export default function ManagerOnDutyEditor({selectedManagerOnDuty, selectedDate, users, terminals, handleManagerOnDutyCrud}){
    const [terminal, setTerminal] = useState(selectedManagerOnDuty?.terminalIdentifier ?? null);
    const [user, setUser] = useState(selectedManagerOnDuty?.companyUserIdentifier ?? null);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);

    function handleCreateManagerOnDuty(){
        const managerOnDuty = {
            terminalIdentifier: parseInt(terminal),
            companyUserIdentifier: parseInt(user),
            date: selectedDate,
        }

        new ApiRequest('scheduling', 'createManagerOnDuty', setIsSubmitting, (response) => {
            managerOnDuty.uid = response.uid;
            handleManagerOnDutyCrud('create', managerOnDuty);
        }).withData({managerOnDuty: managerOnDuty}).send()
    }

    function handleUpdateManagerOnDuty(){
        const managerOnDuty = {
            uid: selectedManagerOnDuty.uid,
            terminalIdentifier: parseInt(terminal),
            companyUserIdentifier: parseInt(user),
            date: selectedDate
        }
        new ApiRequest('scheduling', 'updateManagerOnDuty', setIsSubmitting, (response) => {
            handleManagerOnDutyCrud('update', managerOnDuty)
        }).withData({managerOnDuty: managerOnDuty}).send()
    }

    function handleDeleteManagerOnDuty(){
        new ApiRequest('scheduling', 'deleteManagerOnDuty', setIsDeleting, () => {
            handleManagerOnDutyCrud('delete', selectedManagerOnDuty.uid)
        }).withUid(selectedManagerOnDuty.uid).send();
    }

    const userItems = {};
    users.forEach(user => {
        const middleName = user?.middleName ? `${user?.middleName} ` : ''
        const name = `${user.firstName} ${middleName}${user.lastName}`
        userItems[user.companyUserIdentifier] = name;
    });

    const terminalItems = {};
    terminals.forEach(t=>{
        terminalItems[t.uid] = t.name;
    })

    return (
        <>
            <Modal.Header closeButton>
                <Modal.Title>{selectedManagerOnDuty ? 'Edit' : 'Create'} Manager On Duty</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <CustomDropDown breakpoints={{lg: 6}} title={'Select Manager'} selection={user} setSelection={setUser} items={userItems}/>
                    <CustomDropDown breakpoints={{lg: 6}} title={'Select Terminal'} selection={terminal} setSelection={setTerminal} items={terminalItems}/>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                    {selectedManagerOnDuty && <button onClick={() => setShowDeleteModal(true)} style={{marginRight: 'auto',border: 'none', marginRight: 2, boxShadow: 'none', color: 'red', backgroundColor: 'transparent'}}>Delete</button>}
                    <CustomButton isLoading={isSubmitting} disabled={!terminal || !user} variant='outline-primary' onClick={selectedManagerOnDuty ? handleUpdateManagerOnDuty : handleCreateManagerOnDuty}>{selectedManagerOnDuty ? 'Save Changes' : 'Submit'}</CustomButton>
            </Modal.Footer>
            <Modal centered show={showDeleteModal}>
                <AlertConfirmation isLoading={isDeleting} title='Delete Manger On Duty' message='Are you sure you want to delete this manager on duty?' buttonLabel='Delete' variant={'outline-danger'} callBack={handleDeleteManagerOnDuty}/>
            </Modal>
        </>
    )
}