/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React from 'react';
import ListGroup from 'react-bootstrap/ListGroup';


export default function KeyValueRow({title, value, isListItem, titleStyle = {}, valueStyle = {}, ...props}) {


    const content = (
        <>
            <span style={{overflow: 'hidden', textOverflow: 'ellipsis', ...titleStyle}}>{title}</span>
            <span style={{overflow: 'hidden', textOverflow: 'ellipsis', opacity: 0.5, ...valueStyle}}>{value}</span>
        </>
    )

    if (isListItem) {
        return (
            <ListGroup.Item style={{display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap', gap: 8}} {...props}>
                {content}
            </ListGroup.Item>
        )
    } else {
        return (
            <div style={{display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap', gap: 8}} {...props}>
                {content}
            </div>
        )
    }

}