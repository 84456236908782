/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import Table from 'react-bootstrap/Table';
import moment from 'moment';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import React from 'react';

export default function TimesheetCalendar({timesheetData, calendarStartDate, startOfMonth, showMinutes}) {
    const weekRows = [0, 1, 2, 3, 4, 5].map((weekIndex) => {
        const dayElements = [0, 1, 2, 3, 4, 5, 6].map((dayIndex) => {
            const date = moment(calendarStartDate).add(weekIndex, 'weeks').add(dayIndex, 'days');

            const timesheetsOnDay = timesheetData.filter((t =>moment(t.inTime).isSame(moment(date), 'day')));

            return (
                <td key={dayIndex} style={{position: 'relative'}}>
                    <div style={{position: 'absolute', top: 0, bottom: 0, left: 0, right: 0, display: 'flex', height: '100%', flexDirection: 'column', padding: 2}}>
                        
                        { timesheetsOnDay.length > 0 && 
                            <CalendarItem date={date} timesheetsOnDay={timesheetsOnDay} showMinutes={showMinutes}/>
                        }
                    </div>
                    <p style={{position: 'absolute', right: 4, top: 4, fontSize: 14, backgroundColor: 'white', borderRadius: 20, lineHeight: 1}}>{date.format('D')}</p>
                    { date.format('MMM') !== moment(startOfMonth).format('MMM') && 
                        <div style={{position: 'absolute', top: 0, bottom: 0, left: 0, right: 0, backgroundColor: 'black', opacity: 0.075, pointerEvents: 'none'}}/>
                    }
                    { date.isSame(moment(), 'day') && 
                        <div style={{position: 'absolute', top: 0, bottom: 0, left: 0, right: 0, backgroundColor: 'var(--bs-primary)', opacity: 0.075, pointerEvents: 'none'}}/>
                    }
                </td>
            );
        })

        return (
            <tr key={weekIndex} style={{height: '16.66%'}}>
                {dayElements}
            </tr>
        );
    });

    const columnHeaders = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'].map((str) => {
        return (<th key={str} style={{width: '14.286%', opacity: 0.66, fontSize: 14}}>{str}</th>);
    });

    const columnHeadersMobile = ['S', 'M', 'T', 'W', 'T', 'F', 'S'].map((str, index) => {
        return (<th key={index} style={{width: '14.286%', opacity: 0.66, fontSize: 14}}>{str}</th>);
    });

    return (
        <Table bordered size='sm' style={{width: '100%', flex: 1, margin: 0}}>
            <thead>
                <tr className='desktop-table-row' style={{textAlign: 'center'}}>
                    {columnHeaders}
                </tr>
                <tr className='mobile-table-row' style={{textAlign: 'center'}}>
                    {columnHeadersMobile}
                </tr>
            </thead>
            <tbody>
                {weekRows}
            </tbody>
        </Table>
    );
}


function CalendarItem({date, timesheetsOnDay, showMinutes}) {
    const inTime = timesheetsOnDay[0]?.inTime
    const outTime = timesheetsOnDay[timesheetsOnDay.length - 1]?.outTime

    const timesheetElements = timesheetsOnDay.map((t) => {
        if (t.outTime) {
            return (
                <li key={t.uid} style={{textOverflow: 'ellipsis', overflow: 'hidden'}}>{`${moment(t.inTime).format('h:mm A')} - ${moment(t.outTime).format('h:mm A')}`}</li>
            )
        } else {
            return (
                <li key={t.uid} style={{textOverflow: 'ellipsis', overflow: 'hidden', color: 'green'}}>{'IN: ' + moment(t.inTime).format('h:mm A')}</li>
            )
        }
    })

    const totalHours = timesheetsOnDay.reduce((prev, curr) => {
        return prev + (curr.outTime ? moment(curr.outTime).diff(moment(curr.inTime), 'hours', true) : moment().diff(moment(curr.inTime), 'hours', true))
    }, 0)

    const totalHoursAndMinutes = `${parseInt(totalHours)} hr(s), ${parseInt((totalHours - parseInt(totalHours)) * 60)} min`

    return (
        <>
            <div className='desktop-flex' style={{width: '100%', height: '100%', alignItems: 'flex-end'}}>
                <OverlayTrigger overlay={
                    <Popover style={{position: 'fixed'}}>
                        <Popover.Header>{`Timesheets on ${moment(date).format('MMM D')}`}</Popover.Header>
                        <Popover.Body style={{display: 'flex', flexDirection: 'column', gap: 4}}>
                            {timesheetElements}
                            <span style={{textAlign: 'center', fontWeight: 'bold'}}>{showMinutes ? totalHoursAndMinutes : `${totalHours.toFixed(2)} Hour(s)`}</span>
                        </Popover.Body>
                    </Popover>
                }>
                    <div style={{backgroundColor: 'white', width: '100%', flexDirection: 'column', fontSize: 12, lineHeight: 1.2, whiteSpace: 'nowrap', paddingLeft: 2, paddingRight: 4, border: '1px solid var(--bs-primary)', borderLeft: '6px solid var(--bs-primary)', borderTopRightRadius: 6, borderBottomRightRadius: 6}}>
                        <div style={{display: 'flex', justifyContent: 'space-between'}}>
                            <span style={{textOverflow: 'ellipsis', overflow: 'hidden'}}>{'In Time'}</span>
                            <span>{moment(inTime).format('h:mm A')}</span>
                        </div>
                        { outTime &&
                            <div style={{display: 'flex', justifyContent: 'space-between'}}>
                                <span style={{textOverflow: 'ellipsis', overflow: 'hidden'}}>{'Out Time'}</span>
                                <span>{moment(outTime).format('h:mm A')}</span>
                            </div>
                        }
                        <div style={{display: 'flex', justifyContent: 'space-between'}}>
                            <span style={{textOverflow: 'ellipsis', overflow: 'hidden'}}>{'Total'}</span>
                            <span>{showMinutes ? totalHoursAndMinutes : `${totalHours.toFixed(2)} Hour(s)`}</span>
                        </div>
                    </div>
                </OverlayTrigger>
            </div>
            <OverlayTrigger overlay={
                <Popover style={{position: 'fixed'}}>
                    <Popover.Header>{`Timesheets on ${moment(date).format('MMM D')}`}</Popover.Header>
                    <Popover.Body style={{display: 'flex', flexDirection: 'column', gap: 4}}>
                        {timesheetElements}
                        <span style={{textAlign: 'center', fontWeight: 'bold'}}>{showMinutes ? totalHoursAndMinutes : `${totalHours.toFixed(2)} Hour(s)`}</span>
                    </Popover.Body>
                </Popover>
            }>
                <div className='mobile-flex' style={{height: '100%', width: '100%', justifyContent: 'center', alignItems: 'center'}}>
                    <div style={{height: '50%', width: '50%', display: 'flex', justifyContent: 'center'}}>
                        <div style={{aspectRatio: 1, maxHeight: '100%', maxWidth: '100%', backgroundColor: 'var(--bs-primary)', borderRadius: '50%'}}/>
                    </div>
                </div>
            </OverlayTrigger>
        </>
    )
}

