/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, {useState} from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Cookies from 'universal-cookie';
import CustomToolTip from '../../../../components/CustomToolTip';
import QuickTable from '../../../../components/QuickTable';

export default function HRHirePermissions({handlePermissionChange, handleUseTemplate, permissions, permissionsTemplate}) {
    const userPermissions = new Cookies().get('permissions');
    const title = new Cookies().get('userData')['title'];
    const auth = new Cookies().get('auth');

    const permissionRows = permissionsTemplate.map((p) => {
        return (
            <tr key={p.permissionName}>
                <td>
                    <span style={{marginRight: 8}}>{p.permissionTitle}</span>
                    <CustomToolTip text={p.description}/>
                </td>
                <td>
                    <Form.Check type='switch' checked={permissions.includes(p.permissionName)} onChange={() => handlePermissionChange(p.permissionName)}/>
                </td>
            </tr>
        )
    });

    const showBcTemplate = !permissionsTemplate.some(p =>{return p.bc && userPermissions[p.permissionName]});

    return (
        <>
             { (title === 'AO' || showBcTemplate) && 
                <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 12}}>
                    <div style={{display: 'flex', gap: 12, alignItems: 'center', border: '1px solid lightgray', borderRadius: 6, padding: 8, width: 'fit-content'}}>
                        <span>Default Permission Templates</span>
                        { (title === 'AO' || !!auth.adminIdentifier) && 
                            <Button variant={'outline-primary'} onClick={() => handleUseTemplate('ao')}>Authorized Officer</Button>
                        }
                        <Button variant={'outline-primary'} onClick={() => handleUseTemplate('bc')}>Business Contact</Button>
                    </div>
                </div>
            }
            <QuickTable responsive bordered striped rows={[permissionRows]} headers={['Permission', '']}/>
        </>
    )
}

