/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import { useState } from "react";
import CustomControl from "../../../../components/CustomStateControls/CustomControl";
import { Button, Col, Container, Modal, Row } from "react-bootstrap";
import SwitchControl from "../../../../components/SwitchControl";
import { ApiRequest } from "../../../../ApiManager.tsx";
import moment from "moment";
import DropdownControl from "../../../../components/DropdownControl.js";
import RadioControl from "../../../../components/RadioControl";
import CustomButton from "../../../../components/CustomButton.js";
import AddressAutoFill from "../../../../components/CustomStateControls/AddressAutoFill.js";
import ControlStateProps from "../../../../state/ControlStateProps.tsx";
import Address from "../../../../models/Address.js";
import StateObject from "../../../../state/StateObject.tsx";
import { Validation, useStateWithValidation, useValidationReducer } from "../../../../validation.tsx";
import CustomTextArea from "../../../../components/CustomStateControls/CustomTextArea.js";
import { validateInteger } from "../../../../tools.js";
import MultipleFileUploadControl from "../../../../components/MultipleFileUploadControl.js";
import { useIdentifiableStateArray } from "../../../../hooks.tsx";
import Cookies from "universal-cookie";

export default function HRUserBWCForm({user, users, selectedBWC, handleAddRecord, handleReviseRecord}) {
    const [dateOfInjury, setDateOfInjury, dateOfInjuryVM] = useStateWithValidation(selectedBWC ? selectedBWC.dateOfInjury : moment().format('YYYY-MM-DD'), Validation.date);
    const [injuryReportDate, setInjuryReportDate] = useState(selectedBWC ? selectedBWC.injuryReportDate : moment().format('YYYY-MM-DD'));
    const [injuryReportedTo, setInjuryReportedTo] = useState(selectedBWC ? selectedBWC.injuryReportedTo : undefined);
    const [treatmentFacilityName, setTreatmentFacilityName] = useState(selectedBWC ? selectedBWC.treatmentFacilityName : '');
    const [treatmentFacilityAdmittedDateTime, setTreatmentFacilityAdmittedDateTime] = useState(selectedBWC ? selectedBWC.treatmentFacilityAdmittedDateTime : '');
    const [salaryContinuation, setSalaryContinuation] = useState(selectedBWC ? selectedBWC.salaryContinuation : '0');
    const [rtwLightDutyDate, setRtwLightDutyDate] = useState(selectedBWC ? selectedBWC.rtwLightDutyDate : '');
    const [lightDutyDescription, setLightDutyDescription] = useState(selectedBWC ? selectedBWC.lightDutyDescription : '');
    const [rtwFullDutyDate, setRtwFullDutyDate] = useState(selectedBWC ? selectedBWC.rtwFullDutyDate : '');
    const [bwcInjuryClaimNumber, setBwcInjuryClaimNumber] = useState(selectedBWC ? selectedBWC.bwcInjuryClaimNumber : '');
    const [notes, setNotes] = useState(selectedBWC ? selectedBWC.notes : '');
    const [injuryOrIllness, setInjuryOrIllness] = useState(selectedBWC ? selectedBWC.injuryOrIllness : '');
    const [eventLocation, setEventLocation] = useState(selectedBWC ? selectedBWC.eventLocation : '');
    const [classifyCase, setClassifyCase] = useState(selectedBWC ? selectedBWC.classifyCase : '');
    const [confirmSubmission, setConfirmSubmission] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [formObject, _setformObject] = useState(new StateObject({address: (selectedBWC && selectedBWC.treatmentFacilityAddress) ?  Address.decodeNonStrict(selectedBWC.treatmentFacilityAddress) : undefined}))
    const [attachments, modifyAttachments] = useIdentifiableStateArray([], 'uid');

    const isValid = useValidationReducer([dateOfInjuryVM]);
    
    function handleSubmit(){
        setConfirmSubmission(false);
        const userData = new Cookies().get('userData');

        const newBWC = {
            bwcRecordIdentifier: selectedBWC ? selectedBWC.bwcRecordIdentifier : undefined,
            dateOfInjury: dateOfInjury ? dateOfInjury : null,
            injuryReportDate: injuryReportDate,
            injuryReportedTo: injuryReportedTo ? injuryReportedTo : null,
            treatmentFacilityName: treatmentFacilityName ? treatmentFacilityName : null,
            treatmentFacilityAddress: formObject.address ? formObject.address.encode() : null,
            treatmentFacilityAdmittedDateTime: treatmentFacilityAdmittedDateTime ? treatmentFacilityAdmittedDateTime : null,
            salaryContinuation: salaryContinuation ? salaryContinuation : null,
            rtwLightDutyDate: rtwLightDutyDate ? rtwLightDutyDate : null,
            lightDutyDescription: lightDutyDescription ? lightDutyDescription : null,
            rtwFullDutyDate: rtwFullDutyDate ? rtwFullDutyDate : null,
            bwcInjuryClaimNumber: validateInteger(bwcInjuryClaimNumber) ? validateInteger(bwcInjuryClaimNumber) : null,
            notes: notes ? notes : null,
            eventLocation: eventLocation ? eventLocation : null,
            injuryOrIllness: injuryOrIllness ? injuryOrIllness : null,
            classifyCase: classifyCase ? classifyCase : null,
            firstAndLastName: user.firstName + ' ' + user.lastName
        }

        if(selectedBWC){
            new ApiRequest('hr', 'reviseBwcRecord', setIsLoading, (response) => {
                newBWC.uid = response.uid;
                newBWC.author = userData.companyUserIdentifier ? userData : null;
                newBWC.companyUserIdentifier = userData.companyUserIdentifier;
                newBWC.adminIdentifier = userData.adminIdentifier;
                newBWC.submissionDateTime = moment().format('YYYY-MM-DD HH:mm:ss');
                handleReviseRecord(newBWC);
            }).withData({bwc: newBWC}).send();
        } else {
            new ApiRequest('hr', 'createBwcRecord', setIsLoading, (response) => {
                newBWC.bwcRecordIdentifier = response.bwcRecordIdentifier;
                newBWC.author = userData.companyUserIdentifier ? userData : null;
                newBWC.companyUserIdentifier = userData.companyUserIdentifier;
                newBWC.adminIdentifier = userData.adminIdentifier;
                newBWC.uid = response.uid;
                newBWC.submissionDateTime = moment().format('YYYY-MM-DD HH:mm:ss');
                handleAddRecord({uid: response.bwcRecordIdentifier, latestVersion: newBWC});
            }).withData({bwc: newBWC, companyUserIdentifier: user.companyUserIdentifier, files: attachments.filter(a => !!a.base64)}).send()
        }
    }

    return (
        <>
            <Modal.Header closeButton>
                <Modal.Title>{selectedBWC ? 'Revising BWC Report' : 'Creating New BWC Report'}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container fluid>
                    <Row>
                        <RadioControl labelStyle={{width: 160}} title='Injury or Illness' breakpoints={{lg: 12}} inline optionNames={['Injury','Illness']} optionValues={['Injury','Illness']} selection={injuryOrIllness} setSelection={setInjuryOrIllness}/>
                        <RadioControl labelStyle={{width: 160}} title='Classify Case' breakpoints={{lg: 12}} inline optionNames={['Off Work', 'Other', 'Death']} optionValues={['Off Work', 'Other', 'Death']} selection={classifyCase} setSelection={setClassifyCase}/>
                        <Col lg={12} style={{marginBottom: 8}}>
                            <DropdownControl title={'Injury Reported To'} selection={injuryReportedTo} setSelection={setInjuryReportedTo} itemNames={users.map(u => u.firstName + ' ' + u.lastName)} itemValues={users.map(u => u.companyUserIdentifier)}/>
                        </Col>
                        <CustomControl floatingLabel label='Date of Injury' type='date' breakpoints={{lg: 6}} disabled={isLoading} originalValue={selectedBWC ? selectedBWC.dateOfInjury : undefined} value={dateOfInjury} setValue={setDateOfInjury}/>
                        <CustomControl validationStateMessage={dateOfInjuryVM} floatingLabel label='Date Reported' type='date' breakpoints={{lg: 6}} disabled={isLoading} originalValue={selectedBWC ? selectedBWC.injuryReportDate : undefined} value={injuryReportDate} setValue={setInjuryReportDate}/>
                        <CustomControl breakpoints={{lg: 6}} floatingLabel label='Date Returned to Work for Light Duty' type='date' disabled={isLoading} originalValue={selectedBWC ? selectedBWC.rtwLightDutyDate : undefined} value={rtwLightDutyDate} setValue={setRtwLightDutyDate}/>
                        { !rtwLightDutyDate ? '' :
                            <Col xxl={12} style={{marginBottom: 8}}>
                                <CustomTextArea disabled={isLoading} max={200} originalValue={selectedBWC ? selectedBWC.lightDutyDescription : undefined} placeholder='Description of Light Duty' rows={4} value={lightDutyDescription} setValue={setLightDutyDescription}/>
                            </Col>
                        }
                        <CustomControl breakpoints={{lg: 6}} floatingLabel label='Date Returned to Work for Full Duty' type='date' disabled={isLoading} originalValue={selectedBWC ? selectedBWC.rtwFullDutyDate : undefined} value={rtwFullDutyDate} setValue={setRtwFullDutyDate}/>
                        <Col lg={6} style={{marginBottom: 8}}>
                            <SwitchControl style={{padding: 16}} disabled={isLoading} originalValue={selectedBWC ? selectedBWC.salaryContinuation : undefined} title='Salary Continuation?' value={salaryContinuation} setValue={(value) => {setSalaryContinuation(value)}}/>
                        </Col>
                        <CustomControl breakpoints={{lg: 6}} floatingLabel label='BWC Injury Claim #' type='number'  disabled={isLoading} maxLength={8} originalValue={selectedBWC ? selectedBWC.bwcInjuryClaimNumber : undefined} value={bwcInjuryClaimNumber} setValue={setBwcInjuryClaimNumber}/>
                        <CustomControl breakpoints={{lg: 12}} floatingLabel label='Where did the event occur?' maxLength={30} value={eventLocation} setValue={setEventLocation}/>
                        <CustomControl breakpoints={{lg: 6}} disabled={isLoading} maxLength={50} originalValue={selectedBWC ? selectedBWC.treatmentFacilityName : undefined} floatingLabel type='text' label='Treatment Facility Name' value={treatmentFacilityName} setValue={setTreatmentFacilityName}/>
                        <CustomControl breakpoints={{lg: 6}} disabled={isLoading} originalValue={selectedBWC ? selectedBWC.treatmentFacilityAdmittedDateTime : undefined} floatingLabel type='datetime-local' label='Date & Time Admitted to Facility' value={treatmentFacilityAdmittedDateTime} setValue={setTreatmentFacilityAdmittedDateTime}/>
                        <Col lg={12} style={{marginBottom: 8}}>
                            <AddressAutoFill label="Treatment Facility Address" stateProps={new ControlStateProps('address', formObject, Validation.notUndefined)} noRedAsterisk/>
                        </Col>
                        <Col lg={12} style={{marginBottom: 12}}>
                            <CustomTextArea disabled={isLoading} max={500} originalValue={selectedBWC ? selectedBWC.notes : undefined} placeholder='Injury / Illness Description' rows={4} value={notes} setValue={setNotes}/>
                        </Col>
                        { !selectedBWC && 
                            <Col lg={12}>
                                <MultipleFileUploadControl value={attachments} modifyArray={modifyAttachments} maxTitleLength={100}/>
                            </Col>
                        }
                    </Row>
                </Container>
            </Modal.Body>
            <Modal.Footer>
                <CustomButton label='Submit' isLoading={isLoading}  disabled={!injuryReportDate} onClick={() => {setConfirmSubmission(true)}}>Submit</CustomButton>
            </Modal.Footer>
            <Modal show={confirmSubmission} centered onHide={() => {setConfirmSubmission(false)}}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirm Submission</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Once submitted, the entered information will become a permanent record. Proceed?
                </Modal.Body>
                <Modal.Footer>
                    <Button variant={'outline-primary'} onClick={() => {setConfirmSubmission(false)}}>Cancel</Button>
                    <Button variant={'outline-primary'} disabled={!isValid} onClick={handleSubmit}>Confirm Submission</Button>
                </Modal.Footer>
            </Modal>

        </>
    )
    
}

