/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, {useState} from 'react';
import moment from 'moment';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import CustomButton from '../../../../components/CustomButton.js';
import { ApiRequest } from '../../../../ApiManager.tsx';
import CustomTextArea from '../../../../components/CustomStateControls/CustomTextArea.js';
import SwitchControl from '../../../../components/SwitchControl.js';
import CustomControl from '../../../../components/CustomStateControls/CustomControl.js';

export default function HRUserScheduleReportAbsenceModal({companyUserIdentifier, handleScheduleItemsCrud}) {
    const [notifiedManager, setNotifiedManger] = useState(false);
    const [date, setDate] = useState(moment().format('YYYY-MM-DD'));
    const [notes, setNotes] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);

    function handleCreateNoShow(){
        const newAbsence = {
            companyUserIdentifier: companyUserIdentifier,
            notifiedManager: notifiedManager,
            date: date, 
            notes: notes, 
        }

        new ApiRequest('hr', 'createAbsence', setIsSubmitting, (response) => {
            newAbsence.uid = response.uid;
            handleScheduleItemsCrud('absence', 'create', null, newAbsence)
        }).withData({absence: newAbsence}).send()
    }

    return (
        <>
            <Modal.Header closeButton>
                <Modal.Title>Reporting Call Off / No-Show</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div style={{display: 'flex', flexDirection: 'column', gap: 12}}>
                    <CustomControl floatingLabel label='Date' type='date' value={date} setValue={setDate}/>
                    <SwitchControl title={'Employee notified manager?'} value={notifiedManager} setValue={setNotifiedManger}/>
                    <CustomTextArea placeholder={'Notes'} max={200} value={notes} setValue={setNotes} rows={4}/>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <CustomButton isSubmitting={isSubmitting} onClick={handleCreateNoShow}>Submit</CustomButton>
            </Modal.Footer>    
        </>
    )
}