/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, { useState } from "react";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Form } from "react-bootstrap";
import SwitchControl from "../../../../../../../components/SwitchControl.js";
import RadioControl from "../../../../../../../components/RadioControl.js";
import Deduction from "../../../../Models/Deduction.js";
import PayrollMoneyControl from "../../../PayrollMoneyControl.js";
import { Validation, useStateWithValidation, useValidationReducer } from "../../../../../../../validation.tsx";
import { useToggle } from "../../../../../../../hooks.tsx";
import { getFakeUID } from "../../../../../../../tools.js";
import PayrollTooltip from "../../PayrollTooltip.js";

export default function PayrollAdditionalDeductionsModal({handleAddDeduction}) {
    const [amount, setAmount, amountValidationMessage] = useStateWithValidation('', Validation.greaterThanZero);
    const [type, setType, typeValidationMessage] = useStateWithValidation('', Validation.nonEmptyString);
    const [isReoccurring, toggleIsReoccurring] = useToggle(false);

    const [applyToAll, setApplyToAll] = useState(false);

    const isValid = useValidationReducer([amountValidationMessage, typeValidationMessage]);

    const handleSubmit = () => {
        handleAddDeduction(new Deduction(getFakeUID(), type, amount, isReoccurring), applyToAll);
    }
    
    return (
        <>
            <Modal.Header closeButton>
                <Modal.Title>Adding Deduction</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{display: 'flex', flexDirection: 'column', gap: 6}}>
                <RadioControl validate validationMessage={typeValidationMessage} title='Deductions' inline selection={type} setSelection={setType} optionNames={Deduction.deductionTypes} optionValues={Deduction.deductionTypes}/>             
                <div style={{display: 'flex', gap: 6}}>
                    <PayrollMoneyControl validate validationMessage={amountValidationMessage} lhDecimalPlaces={3} value={amount} setValue={setAmount} title='Amount'/>
                    <SwitchControl value={isReoccurring} setValue={toggleIsReoccurring} title={'Reoccurring'}/>
                </div>
                <PayrollTooltip boldText='Reoccuring' text={' - Next payroll period, this Deduction will be automatically added to the employee(s)'}/>                
            </Modal.Body>
            <Modal.Footer style={{display: 'flex', justifyContent: 'flex-end'}}>
                <Form.Check type='switch' checked={applyToAll} onChange={() => {setApplyToAll(!applyToAll)}} label='Add deduction to all employees'/>
                <Button style={{color: 'white'}} disabled={!isValid} onClick={handleSubmit}>Submit</Button>
            </Modal.Footer>
        </>
    )
}