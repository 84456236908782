/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React from "react";
import Button from 'react-bootstrap/Button';
import Dropdown from 'react-bootstrap/Dropdown';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { payTypeDictionary, bigToUsd, payTypeDictionaryWithOt } from "../../../../payrollTools.js";
import PayrollMoneyControl from "../../../PayrollMoneyControl.js";
import PayrollIntegerControl from "../../../PayrollIntegerControl.js";
import PayrollDecimalControl from "../../../PayrollDecimalControl.js";
import moment from "moment";
import { InputGroup } from "react-bootstrap";

export default function PayrollEmployeeWageItem({entry, pay, handleSetPay, removePay}) {
    const payTypeDropDownItems = Object.entries(payTypeDictionary).map(([key, value]) =>{
        return (
            
            <Dropdown.Item key={key} eventKey={key} active={pay.payType === key} onClick={() => {handleSetPay(pay.uid, 'payType', key)}}>
                <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                    <p style={{marginBottom: 0, marginRight: 8}}>{value}</p> 
                </div>
            </Dropdown.Item>
        )
    });

    const payStartDropdownItems = [0, 1, 2, 3, 4, 5, 6].filter(i => i <= pay.payEndIndex).map((index) => {
        const date = moment(entry.periodStart).add(entry.weekIndex, 'weeks').add(index, 'days').format('ddd D')
        return (
            <Dropdown.Item key={index} active={pay.payStartIndex == index} onClick={() => {handleSetPay(pay.uid, 'payStartIndex', index)}}>{date}</Dropdown.Item>
        )
    })
    const payEndDropdownItems = [0, 1, 2, 3, 4, 5, 6].filter(i => i >= pay.payStartIndex).map((index) => {
        const date = moment(entry.periodStart).add(entry.weekIndex, 'weeks').add(index, 'days').format('ddd D')
        return (
            <Dropdown.Item key={index} active={pay.payEndIndex == index} onClick={() => {handleSetPay(pay.uid, 'payEndIndex', index)}}>{date}</Dropdown.Item>
        )
    })
    
    return (
        <tr style={{verticalAlign: 'middle', textAlign: 'center'}}>
            <td>
                <PayrollMoneyControl style={{minWidth: 100, height: 32}} lhDecimalPlaces={6} value={pay.payRate} setValue={(newValue) => {handleSetPay(pay.uid, 'payRate', newValue)}} disabled={!handleSetPay}/>
            </td>
            <td>
                <Dropdown>
                    <Dropdown.Toggle variant="outline-primary" style={{padding: '3px 6px'}} disabled={!handleSetPay}>{payTypeDictionaryWithOt[pay.payType]}</Dropdown.Toggle>
                    <Dropdown.Menu className="fixed-important">
                        {payTypeDropDownItems}
                    </Dropdown.Menu>
                </Dropdown>
            </td>
            <td>
                { !['sw', 'ot'].includes(pay.payType) && 
                    <>
                        { pay.payType == 'py' ?
                            <InputGroup style={{flexWrap: 'nowrap'}}>
                                <Dropdown>
                                    <Dropdown.Toggle style={{padding: '3px 8px'}} variant="outline-primary">{moment(pay.getStartDate(entry.periodStart, entry.weekIndex)).format('ddd D')}</Dropdown.Toggle>
                                    <Dropdown.Menu className="fixed-important">
                                        {payStartDropdownItems}
                                    </Dropdown.Menu>
                                </Dropdown>
                                <InputGroup.Text style={{backgroundColor: 'white', flex: 1, display: 'flex', justifyContent: 'center', color: 'var(--bs-primary)'}}>
                                    <FontAwesomeIcon icon={faArrowRight}/>
                                </InputGroup.Text>
                                <Dropdown>
                                    <Dropdown.Toggle style={{padding: '3px 8px'}} variant="outline-primary">{moment(pay.getEndDate(entry.periodStart, entry.weekIndex)).format('ddd D')}</Dropdown.Toggle>
                                    <Dropdown.Menu className="fixed-important">
                                        {payEndDropdownItems}
                                    </Dropdown.Menu>
                                </Dropdown>
                            </InputGroup>
                        : ['pd', 'xd'].includes(pay.payType) ?
                            <PayrollIntegerControl style={{minWidth: 100, height: 32}} max={999} value={pay.unitsWorked} setValue={(newValue) => {handleSetPay(pay.uid, 'unitsWorked', newValue)}} disabled={!handleSetPay}/>
                        :
                            <PayrollDecimalControl style={{minWidth: 100, height: 32}} lhDecimalPlaces={3} rhDecimalPlaces={5} value={pay.unitsWorked} setValue={(newValue) => {handleSetPay(pay.uid, 'unitsWorked', newValue)}} disabled={!handleSetPay}/>
                        }
                    </>
                }       
            </td>
            <td style={{color: 'green', fontWeight: 'bold'}}>
                {bigToUsd(pay.getWages(entry.weeks[entry.weekIndex].qualifiesForFLSA()))}
            </td>
            <td>
                { removePay && 
                    <Button variant="outline-danger" style={{padding: '3px 8px'}} onClick={() => removePay(pay.uid)}>
                        <FontAwesomeIcon icon={faTrashCan}/>
                    </Button>
                }
            </td>

            
        </tr>  
    );
}