/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import moment from "moment";
import React, {useEffect, useState } from "react"
import { ApiRequest } from "../../../../ApiManager.tsx";
import { Button, Modal, OverlayTrigger, Popover, } from "react-bootstrap";
import { useDrop } from "react-dnd";
import { getFakeUID } from "../../../../tools.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLock, faTrash, faTrashCan, faTriangleExclamation} from "@fortawesome/free-solid-svg-icons";
import RouteStop from "../Models/RouteStop.js";
import CustomButton from "../../../../components/CustomButton.js";
import { DraggableStopRow} from "./DraggableComponents.js";
import AlertConfirmation from "../../../../components/AlertModals/AlertConfirmation.js";
import CustomTextArea from "../../../../components/CustomStateControls/CustomTextArea.js";
import CustomToolTip from "../../../../components/CustomToolTip.js";
import './RouteCard.scss'

export default function RouteCard({route, handleSetRoute, handleSetRouteStop, isDraggingClientOrTerminal, setIsDraggingClientOrTerminal, selectedRoute, selectedTemplate, templateMode, handleRoutesCrud, conflicts}){
    const [isDeleting, setIsDeleting] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [modalSwitch, setModalSwitch] = useState('');

    const [{isOverVehicle}, vehicleDrop] = useDrop(() => ({
        accept: 'vehicle',
        drop: (item) => handleSetRoute('vehicle', item),
        collect: (monitor) => ({
            isOverVehicle: monitor.isOver()
        })
    }))

    const [{isOverUser}, userDrop] = useDrop(() => ({
        accept: 'user',
        drop: (item) => handleSetRoute('user', item),
        collect: (monitor) => ({
            isOverUser: monitor.isOver()
        })
    }))

    const [{isOverStop}, stopDrop] = useDrop(() => ({
        accept: 'stop',
        drop: (item) => handleSetRouteStop('stop', null, RouteStop.initFromRouteOrTerminal(item.stop), 0),
        collect: (monitor) => ({
            isOverStop: monitor.isOver()
        }) 
    }))

    const [{isOverBody}, bodyDrop] = useDrop(() => ({
        accept: 'stop',
        collect: (monitor) => ({
            isOverBody: monitor.isOver(),
        })
    }))

    function handleCreateRoute(){
        new ApiRequest('scheduling', 'createRoute', setIsSubmitting, (response) => {
            route.uid = response.uid;
            handleRoutesCrud('create', route);
            hideModal();
        }).withData({route: route.encode()}).send()
    }
    
    function handleUpdateRoute(){
        new ApiRequest('scheduling', 'updateRoute', setIsSubmitting, () => {
            handleRoutesCrud('update', route);
            hideModal();
        }).withData({route: route.encode()}).send()
    }

    function handleDeleteRoute(){
        new ApiRequest('scheduling', 'deleteRoute', setIsDeleting, () => {
            handleRoutesCrud('delete', route.uid);
        }).withUid(route.uid).send();
    }

    function handleCreateTemplateRoute(){
        new ApiRequest('scheduling', 'createTemplateRoute', setIsSubmitting, (response) => {
            route.uid = response.uid;
            handleRoutesCrud('create', route, true);
            hideModal();
        }).withData({uid: selectedTemplate.uid, route: route.encode()}).send()
    }
    
    function handleUpdateTemplateRoute(){
        new ApiRequest('scheduling', 'updateTemplateRoute', setIsSubmitting, () => {
            handleRoutesCrud('update', route, true);
            hideModal();
        }).withData({route: route.encode()}).send()
    }

    function handleDeleteTemplateRoute(){
        new ApiRequest('scheduling', 'deleteTemplateRoute', setIsDeleting, () => {
            handleRoutesCrud('delete', route.uid, true);
        }).withUid(route.uid).send();
    }

    function hideModal(){
        setModalSwitch('');
    }

    const userConflictSeverity = conflicts.user.reduce((accumulator, currentValue) => Math.max(accumulator, currentValue.severity), 0);
    const userConflictListItems = conflicts.user.map((element, index) => {
        return (
            <div key={index} style={{display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 8}}>
                <FontAwesomeIcon icon={faTriangleExclamation} style={{color: element.severity === 0 ? 'lightgreen' : element.severity === 1 ? 'yellow' : element.severity === 2 ? 'orange' : 'red'}}/>
                <p style={{margin: 0,}}>{element.message}</p>
            </div>
        )
    });

    const vehicleConflictSeverity = conflicts.vehicle.reduce((accumulator, currentValue) => Math.max(accumulator, currentValue.severity), 0);
    const vehicleConflictListItems = conflicts.vehicle.map((element, index) => {
        return (
            <div key={index} style={{display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 8}}>
                <FontAwesomeIcon icon={faTriangleExclamation} style={{color: element.severity === 0 ? 'lightgreen' : element.severity === 1 ? 'yellow' : element.severity === 2 ? 'orange' : 'red'}}/>
                <p style={{margin: 0,}}>{element.message}</p>
            </div>
        )
    });

    const userPopover = (
        <Popover style={{position:'fixed'}}>
            <Popover.Header></Popover.Header>
            <Popover.Body>
                {userConflictListItems?.length > 0 ? userConflictListItems : <></>}
            </Popover.Body>
        </Popover>
    )

    const vehiclePopover = (
        <Popover style={{position:'fixed'}}>
            <Popover.Header></Popover.Header>
            <Popover.Body>
                {vehicleConflictListItems?.length > 0 ? vehicleConflictListItems : <></>}
            </Popover.Body>
        </Popover>
    )

    const canBeSaved = conflicts.stops.reduce((acc, el) => {
        if(!acc){
            return false;
        }
        if(el.length && el.find(c => c.severity > 2)){
            return false
        }
        return acc;   
    }, true) && route.vehicle && route.user;

    const canBeSavedString = canBeSaved ? '': ' (Resolve Red Conflicts)';
    const middleName = route?.user?.middleName ? `${route.user?.middleName} ` : '';
    const name = `${route?.user?.firstName} ${middleName}${route?.user?.lastName}`;
    const firstStopStartTime = `${route.date} ${route.stops?.[0]?.startTime}`;
    const lastStopEndTime = `${route.date} ${route.stops?.[route.stops.length-1]?.endTime}`;

    return ( 
        <div style={{backgroundColor: 'white'}}>
            <div className="route-card-header">
                <b>Route</b>
                {selectedRoute && <Button variant="outline-danger" onClick={() => setModalSwitch('delete')} style={{width: 24, height: 24, display: "flex", alignItems: 'center', justifyContent: 'center'}}><FontAwesomeIcon icon={faTrashCan}/></Button>}
            </div>
            <div ref={userDrop} className="route-card-header" style={{backgroundColor: isOverUser ? '#cfe7ff': undefined}}>
                <b>Driver</b> 
                <div>
                    {!conflicts.user.length ? <></> :
                        <OverlayTrigger placement='top' overlay={userPopover}>
                            <FontAwesomeIcon icon={faTriangleExclamation} style={{color: userConflictSeverity === 1 ? 'gold' : userConflictSeverity === 2 ? 'orange' : 'red', marginRight: 0}}/>
                        </OverlayTrigger>
                    }
                    <span style={{marginLeft: 6, fontSize: 14}}>{route?.user ? name : 'None'}</span>
                </div>
            </div>
            <div ref={vehicleDrop} className="route-card-header" style={{backgroundColor: isOverVehicle ? '#cfe7ff': undefined}}>
                <b>Truck</b>
                <div>
                    {!conflicts.vehicle.length ? <></> :
                        <OverlayTrigger placement='top' overlay={vehiclePopover}>
                            <FontAwesomeIcon icon={faTriangleExclamation} style={{color: vehicleConflictSeverity === 1 ? 'gold' : vehicleConflictSeverity === 2 ? 'orange'  : 'red', marginRight: 0}}/>
                        </OverlayTrigger>
                    }
                    <span style={{marginLeft: 6, fontSize: 14}}>{route?.vehicle ? route?.vehicle?.name : 'None'}</span>
                </div>
            </div>
            {/* ROUTE CARD */}
            <div> 
                {/* ROUTE CARD HEADERS */}
                <div className="desktop-route-card-header" style={{alignItems: 'center', backgroundColor: 'gold'}}>
                    <div className="route-stop-header" style={{justifyContent: 'left', paddingLeft: 12, flex: 14}}>Run</div>
                    <div className="route-stop-header route-card-time-column">Time In</div>
                    <div className="route-stop-header route-card-icon-column"><CustomToolTip icon={faLock} text='Checking this box will prevent the autofill feature from overwriting this time in, if there is one.'/></div>
                    <div className="route-stop-header route-card-time-column">Time Out</div>
                    <div className="route-stop-header route-card-icon-column"><CustomToolTip icon={faLock} text='Checking this box will prevent the autofill feature from overwriting this time out, if there is one.'/></div>
                    <div className="route-stop-header route-card-icon-column">&nbsp;</div>
                </div>
                <div className="mobile-route-card-header" style={{backgroundColor: 'gold', height: 40, border: '1px solid gray', justifyContent: 'center', alignItems: 'center', fontWeight: "bold"}}>
                    Run
                </div>
                {/* ROUTE CARD BODY */}
                <div ref={bodyDrop}>
                    {route.stops.map((stop, i) => {
                       return <StopRow key={getFakeUID()} index={i} route={route} conflicts={conflicts} stop={stop} allStops={route.stops} handleSetRouteStop={handleSetRouteStop} isDraggingClientOrTerminal={isDraggingClientOrTerminal} setIsDraggingClientOrTerminal={setIsDraggingClientOrTerminal}/>
                    })}
                    { route.stops.length === 0 &&
                        <div ref={stopDrop} style={{display: 'flex', alignItems: 'center', justifyContent: 'center', fontWeight: 'bold', backgroundColor: isOverStop ? '#cfe7ff' : undefined, border: '1px solid gray', height: 50}}>
                            Drop Stops Here
                        </div>
                    }
                </div>
                {route.stops.length > 7 &&
                    <div style={{height: 50, display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '0 12px', border: '1px solid gray', gap: 8, fontSize: 16}}>
                        <b>Maximum Stops Reached</b> 
                    </div>
                }
                <div style={{height: 50, display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '0 12px', border: '1px solid gray', gap: 8, fontSize: 16}}>
                    <b>Total Hours:</b> 
                    {conflicts.dateOverflow.length === 0 ? <span>{(route.stops?.[0]?.startTime && route.stops?.[route.stops.length-1]?.endTime) ? moment(lastStopEndTime).diff(moment(firstStopStartTime), 'minutes')/60 : 0}</span>
                    :  <OverlayTrigger placement='top' overlay={
                        <Popover style={{position:'fixed'}}>
                            <Popover.Header></Popover.Header>
                            <Popover.Body>
                                <p style={{margin: 0}}>{conflicts.dateOverflow[0].message}</p>
                            </Popover.Body>
                        </Popover>
                    }>
                            <FontAwesomeIcon icon={faTriangleExclamation} style={{color: 'red', marginRight: 0}}/>
                        </OverlayTrigger>
                    }
                </div>
                {/* ROUTE CARD FOOTER */}
                {!templateMode && <div style={{padding: '28px 0 28px 0', backgroundColor: 'rgb(240, 240, 240)'}}>
                    <CustomTextArea placeholder={'Notes'} value={route.notes} setValue={(value)=> handleSetRoute('notes', value)} rows={3} max={200}/>
                </div>}
                <CustomButton isLoading={isSubmitting} style={{ width: '100%'}} disabled={!canBeSaved || !route.stops.length} onClick={() => {
                    if(templateMode){
                        selectedRoute ? handleUpdateTemplateRoute() : handleCreateTemplateRoute()
                    }else{
                        selectedRoute ? handleUpdateRoute() : handleCreateRoute()
                    }
                }}>Save{route.stops.length < 2 ? ' (Minimum of 2 Stops)' : canBeSavedString}</CustomButton>
            </div>
            <Modal centered show={modalSwitch === 'delete'} onHide={hideModal}>
                <AlertConfirmation isLoading={isDeleting} variant='outline-danger' title='Delete Route' message='Are you sure you want to delete this route?' buttonLabel='Delete' callBack={templateMode ? handleDeleteTemplateRoute : handleDeleteRoute}/>
            </Modal>
        </div>
    )
}

function StopRow({stop, handleSetRouteStop, index, isDraggingClientOrTerminal, setIsDraggingClientOrTerminal, allStops, setRoute, conflicts}){

    const [{isOverUpperStopEmpty}, upperEmptyStopDrop] = useDrop(() => ({
        accept: 'stop',
        drop: (item) =>{
            if(allStops.length > 7){
                return;
            }
            handleSetRouteStop('stop', null, RouteStop.initFromRouteOrTerminal(item.stop), index, item.index)
        },
        collect: (monitor) => ({
            isOverUpperStopEmpty: monitor.isOver()
        })
    }))

    const [{isOverLowerStopEmpty}, lowerEmptyStopDrop] = useDrop(() => ({
        accept: 'stop',
        drop: (item) =>{
            if(allStops.length > 7){
                return;
            }
            handleSetRouteStop('stop', null, RouteStop.initFromRouteOrTerminal(item.stop), index + 1, item.index)
        },
        collect: (monitor) => ({
            isOverLowerStopEmpty: monitor.isOver()
        })
    }))

    const [{isOverUpperHalf}, upperHalfDrop] = useDrop(() => ({
        accept: 'stop',
        drop: (item) =>{
            if(allStops.length > 7){
                return;
            }
            handleSetRouteStop('stop', null, RouteStop.initFromRouteOrTerminal(item.stop), index, item.index)
        },
        collect: (monitor) => ({
            isOverUpperHalf: monitor.isOver()
        })
    }))

    const [{isOverLowerHalf}, lowerHalfDrop] = useDrop(() => ({
        accept: 'stop',
        drop: (item) =>{
            if(allStops.length > 7){
                return;
            }
            handleSetRouteStop('stop', null, RouteStop.initFromRouteOrTerminal(item.stop), index + 1, item.index)
        },
        collect: (monitor) => ({
            isOverLowerHalf: monitor.isOver()
        })
    }))

    useEffect(() => {
        document.body.style.userSelect = 'none';
        return () => {
            document.body.style.userSelect = '';
        };
    }, []);

    return (
        <div style={{position: 'relative'}}>
            {/* Upper empty row */}
            {(isOverUpperHalf && allStops.length < 8)&&
                <div ref={upperEmptyStopDrop} style={{display: 'flex', alignItems: 'center', backgroundColor: '#cfe7ff'}}>
                     <div className="desktop-empty-stop-row"></div>
                </div>
            }
            <DraggableStopRow stop={stop} index={index} allStops={allStops} conflicts={conflicts} setRoute={setRoute} handleSetRouteStop={handleSetRouteStop} setIsDraggingClientOrTerminal={setIsDraggingClientOrTerminal}/>
            {/* Lower empty row */}
            {(isOverLowerHalf && allStops.length < 8) &&
                <div ref={lowerEmptyStopDrop} style={{display: 'flex', alignItems: 'center', backgroundColor: '#cfe7ff'}}>
                    <div className="desktop-empty-stop-row"></div>
                </div>
            }
            {/* Absolutely positioned divs for determining if hovering over upper or lower half*/}
            {isDraggingClientOrTerminal &&
            <>
                <div ref={upperHalfDrop} style={{position: 'absolute', top: 0, left: 0, right: 0, bottom: '50%'}}></div>
                <div ref={lowerHalfDrop} style={{position: 'absolute', bottom: 0, left: 0, right: 0, top: '50%'}}></div>
            </>
            }
        </div>
    )
}