/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import { Card, Col, Container, Form, Row } from "react-bootstrap"
import CustomControl from "../../components/CustomControl"
import { useLoadData, useStateObjectWithValidation } from "../../hooks.tsx"
import { Validation } from "../../validation.tsx"
import RadioControl from "../../components/RadioControl"
import SwitchControl from "../../components/SwitchControl"
import AddressAutoFill from "../../components/CustomStateControls/AddressAutoFill"
import BwcCodeControl from "../../components/BwcCodeControl"
import moment from "moment"
import ControlStateProps from "../../state/ControlStateProps.tsx"
import Address from "../../models/Address"
import StateObject from "../../state/StateObject.tsx"
import { useMemo, useState } from "react"
import HRHirePermissions from "../Operations/HumanResources/HRHire/HRHirePermissions.js"
import CustomButton from "../../components/CustomButton"
import Terminal from "../ISPManagement/MyCompany/Models/Terminal.js"
import { ApiRequest } from "../../ApiManager.tsx"
import { toast } from "react-toastify"



export default function CreateCompany({}) {
    const [isLoading, setIsLoading] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const [company, setCompany, setCompanyProp, companyValidationMessages, companyIsValid] = useStateObjectWithValidation({
        name: '',
        ein: '',
        restrictWhoSeesPay: false,
        showBcsPayrollHistory: false,
        payrollEnabled: false,
        bcPayrollEnabled: false,
        restrictBcPayrollToTerminals: false,
        payrollPeriodLength: 1,
        payrollPeriodStartDayIndex: 0,
        payrollStartDate: '',
        includeAOsInPayroll: false,
        generatePayrollForEachTerminal: false
    }, {
        name: Validation.nonEmptyString,
        ein: Validation.nonEmptyString
    })

    const [terminal, setTerminal, setTerminalProp, terminalValidationMessages, terminalIsValid] = useStateObjectWithValidation(Terminal.initDefault(), {
        name: Validation.nonEmptyString,
        defaultLoadTime: Validation.nonEmptyString,
        clockInRadius: Validation.greaterThanZero,
    });

    const [ao, setAo, setAoProp, aoValidationMessages, aoIsValid] = useStateObjectWithValidation({
        firstName: '',
        middleName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
    }, {
        firstName: Validation.nonEmptyString,
        middleName: Validation.none,
        lastName: Validation.nonEmptyString,
        email: Validation.email,
        phoneNumber: Validation.phoneNumber,
    });

    const [companyAddress, setCompanyAddress] = useState(new StateObject({address: undefined}));
    const [terminalAddress, setTerminalAddress] = useState(new StateObject({address: undefined}));
    const [aoAddress, setAoAddress] = useState(new StateObject({address: undefined}));
    const [permissions, setPermissions] = useState([]);
    const [permissionsTemplate, setPermissionsTemplate] = useState([]);

    useLoadData(() => {
        new ApiRequest('admin', 'getPermissionsTemplate', () => {}, (response) => {
            setPermissionsTemplate(response.permissionsTemplate);
        }).withNoAlertOnSuccess().send()
    })

    function handlePermissionChange(permissionName) {
        if(permissions.includes(permissionName)){
            setPermissions(permissions.filter(p=> p !== permissionName));
        }else{
            setPermissions([...permissions, permissionName])
        }
    }

    function handleUseTemplate(template) {
        const newPermissions = [];
        permissionsTemplate.forEach(permission => {if(permission[template]){newPermissions.push(permission.permissionName)}});
        setPermissions(newPermissions);
    }

    function handleClearForm() {
        setCompany({name: '', ein: '', restrictWhoSeesPay: false, showBcsPayrollHistory: false, payrollEnabled: false, bcPayrollEnabled: false, restrictBcPayrollToTerminals: false, payrollPeriodLength: 1, payrollPeriodStartDayIndex: 0, payrollStartDate: '', includeAOsInPayroll: false, generatePayrollForEachTerminal: false});
        setTerminal(Terminal.initDefault());
        setAo({firstName: '', middleName: '', lastName: '', email: '', phoneNumber: ''});
        setCompanyAddress(new StateObject({address: undefined}));
        setTerminalAddress(new StateObject({address: undefined}));
        setAoAddress(new StateObject({address: undefined}));
        setPermissions([]);
    }

    function handleSubmit() {

        if (!companyAddress.address || !terminalAddress.address || !aoAddress.address) {
            toast.error('The Company, Terminal, or AO address is missing!');
            return;
        }

        const companyToSend = {...company, address: companyAddress.address.encode(), payrollStartDate: company.payrollStartDate ? company.payrollStartDate : null};
        const terminalToSend = {...terminal, address: terminalAddress.address.encode()};
        const aoToSend = {
            ...ao,
            employeeType: 0,
            title: 'AO',
            availability: '1111111',
            payType: 'py',
            payRate: 0,
            hourlyWage: 0,
            dateEffective: moment().format('YYYY-MM-DD'),
            ptoAccrual: 0,
            ptoAccrualType: 0,
            bwcCode: '0000',
            employeeId: '',
            medical: 0,
            dental: 0,
            vision: 0,
            childSupport: [],
            automaticDeductions: [],
            automaticReimbursements: [],
            permissions: permissions,
            address: aoAddress.address.encode(),
            notes: ''
        };

        new ApiRequest('admin', 'createCompany', setIsSubmitting, () => {
            handleClearForm();
        }).withData({company: companyToSend, terminal: terminalToSend, ao: aoToSend}).send();

    }

    return (
        <div style={{padding: 20, display: 'flex', flexDirection: 'column', gap: 12, flex: 1, overflowY: 'auto'}}>
            <Card>
                <Card.Header>
                    <Card.Title>Company</Card.Title>
                </Card.Header>
                <Card.Body style={{display: 'flex', flexDirection: 'column', gap: 6}}>
                    <Container fluid style={{padding: 0}}>
                        <Row>
                            <CustomControl breakpoints={{lg: 4, md: 6}} value={company.name} setValue={(value) => {setCompanyProp('name', value)}} title='Company Name' validate validationMessage={companyValidationMessages.name} max={50}/>
                            <CustomControl breakpoints={{lg: 4, md: 6}} type='number' value={company.ein} setValue={(value) => {setCompanyProp('ein', value)}} title='Company EIN' validate validationMessage={companyValidationMessages.ein} max={9} clampAsText/>
                            <Col lg={4}>
                                <AddressAutoFill label='Address' stateProps={new ControlStateProps('address', companyAddress, Validation.notUndefined)}/>
                            </Col>
                        </Row>
                    </Container>
                    <SwitchControl
                        title={'Only allow AOs to view the pay of AOs, and BCs on the Human Resources page'}
                        value={company.restrictWhoSeesPay}
                        setValue={(value) => setCompanyProp('restrictWhoSeesPay', value)}        
                        />
                    <SwitchControl
                        title={'Payroll Enabled'}
                        value={company.payrollEnabled}
                        setValue={(value) => setCompanyProp('payrollEnabled', value)}        
                        />
                    { company.payrollEnabled && 
                        <div style={{display: 'flex', flexDirection: 'column', gap: 6, borderLeft: '1px solid lightgray', paddingLeft: 12}}>
                            <ul style={{margin: 0}}>
                                <li>The <b>Payroll Start Date</b> represents the <b>Period Start</b> for the first payroll period the system will generate.</li>
                                <li>The <b>Payroll Start Date</b> must be on the same day of the week as the specified <b>Payroll Period Start Day</b>.</li>
                                <li>If the <b>Payroll Start Date</b> is set in the past, the system may generate multiple payroll periods as appropriate.</li>
                                <li>The <b>Payroll Start Date</b> can be omitted and set later on, in which case, payroll will not start generating for this company until the start date it set.</li>
                            </ul>
                            <CustomControl type="date" value={company.payrollStartDate} setValue={(value) => {setCompanyProp('payrollStartDate', value)}} title='Payroll Start Date'/>
                            <RadioControl title='Payroll Period Start Day' inline selection={company.payrollPeriodStartDayIndex} setSelection={(value) => {setCompanyProp('payrollPeriodStartDayIndex', value)}} optionValues={[0, 1, 2, 3, 4, 5, 6]} optionNames={['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']}/>
                            <div style={{backgroundColor: 'white', borderRadius: 6, border: '1px solid lightgray', padding: '6px 12px'}}>
                                <span style={{fontSize: 14, opacity: 0.65}}>Payroll Period Length (In Weeks)</span>
                                <div style={{display: 'flex', alignItems: 'center', gap: 12}}>
                                    <span style={{fontWeight: 'bold', whiteSpace: 'nowrap'}}>{`${company.payrollPeriodLength} Week(s)`}</span>
                                    <Form.Range style={{maxWidth: 400}} min={1} max={4} step={1} value={company.payrollPeriodLength} onChange={(e) => {setCompanyProp('payrollPeriodLength', e.target.value)}}/>
                                </div>
                            </div>

                            <SwitchControl
                                title={'Generate payroll separately for each terminal'}
                                value={company.generatePayrollForEachTerminal}
                                setValue={(value) => setCompanyProp('generatePayrollForEachTerminal', value)}        
                            />
                            <SwitchControl
                                title={'BC Payroll Enabled (Have BCs run payroll separately before the AO)'}
                                value={company.bcPayrollEnabled}
                                setValue={(value) => setCompanyProp('bcPayrollEnabled', value)}        
                            />
                            { company.bcPayrollEnabled && 
                                <div style={{display: 'flex', flexDirection: 'column', gap: 6, borderLeft: '1px solid lightgray', paddingLeft: 12}}>
                                    <SwitchControl
                                        title={'Restrict BC Payroll To Terminals (Have BCs run payroll for their own terminals before the AO runs payroll for the whole company)'}
                                        value={company.restrictBcPayrollToTerminals}
                                        setValue={(value) => setCompanyProp('restrictBcPayrollToTerminals', value)}        
                                    />
                                    <SwitchControl
                                        title={'Show BCs Payroll History'}
                                        value={company.showBcsPayrollHistory}
                                        setValue={(value) => setCompanyProp('showBcsPayrollHistory', value)}        
                                    />
                                </div>
                            }
                            <SwitchControl
                                title={'Include AOs in Payroll'}
                                value={company.includeAOsInPayroll}
                                setValue={(value) => setCompanyProp('includeAOsInPayroll', value)}        
                            />
                        </div>
                    }
                </Card.Body>
            </Card>
            <Card>
                <Card.Header>
                    <Card.Title>Terminal</Card.Title>
                </Card.Header>
                <Card.Body>
                    <Container fluid style={{padding: 0}}>
                        <Row>
                            <CustomControl breakpoints={{md: 4}} max={30} title={'Name'} value={terminal.name} setValue={(value) => {setTerminalProp('name', value)}} validate validationMessage={terminalValidationMessages.name}/>
                            <CustomControl breakpoints={{md: 4}} type='number' max={300} title={'Default Load Time (minutes)'} value={terminal.defaultLoadTime} setValue={(value) => {setTerminalProp('defaultLoadTime', value)}} validate validationMessage={terminalValidationMessages.defaultLoadTime}/>
                            <CustomControl breakpoints={{md: 4}} type='number' max={9.9999} title={'Clock-In Radius (miles)'} value={terminal.clockInRadius} setValue={(value) => {setTerminalProp('clockInRadius', value)}} validate validationMessage={terminalValidationMessages.clockInRadius}/>
                            <AddressAutoFill label='Address' stateProps={new ControlStateProps('address', terminalAddress, Validation.notUndefined)}/>
                        </Row>
                    </Container>
                </Card.Body>
            </Card>
            <Card>
                <Card.Header>
                    <Card.Title>AO</Card.Title>
                </Card.Header>
                <Card.Body>
                    <Container fluid style={{padding: 0}}>

                        <Row>
                            <CustomControl breakpoints={{md: 4, lg: 3}} title='First Name' maxLength={30} type='text' max={30} validate validationMessage={aoValidationMessages.firstName} value={ao.firstName} setValue={(value) => setAoProp('firstName', value)}/>
                            <CustomControl breakpoints={{md: 4, lg: 3}} title='Middle Name' maxLength={30} type='text' max={30} validate validationMessage={aoValidationMessages.middleName} value={ao.middleName} setValue={(value) => setAoProp('middleName', value)}/>
                            <CustomControl breakpoints={{md: 4, lg: 3}} title='Last Name' maxLength={30} type='text' max={30} validate validationMessage={aoValidationMessages.lastName} value={ao.lastName} setValue={(value) => setAoProp('lastName', value)}/>
                            <CustomControl breakpoints={{md: 4, lg: 3}} title='Email Address' maxLength={50} max={50} type='text' validate validationMessage={aoValidationMessages.email} value={ao.email} setValue={(value) => setAoProp('email', value)}/>
                            <CustomControl breakpoints={{md: 4, lg: 3}} title='Phone Number' maxLength={10} max={10} type='text' validate validationMessage={aoValidationMessages.phoneNumber} value={ao.phoneNumber} setValue={(value) => setAoProp('phoneNumber', value)}/>
                            <Col md={12} lg={9} style={{marginBottom: 8}}>
                                <AddressAutoFill label='Address' stateProps={new ControlStateProps('address', aoAddress, Validation.notUndefined)}/>
                            </Col>
                            <Col xl={12}>
                                <HRHirePermissions 
                                    handlePermissionChange={handlePermissionChange} 
                                    handleUseTemplate={handleUseTemplate} 
                                    permissions={permissions} 
                                    permissionsTemplate={permissionsTemplate}
                                />
                            </Col>
                        </Row>
                    </Container>
                </Card.Body>
            </Card>
            <Card>
                <Card.Body style={{display: 'flex', flexDirection: 'column'}}>
                    <CustomButton onClick={handleSubmit} isLoading={isSubmitting} disabled={!companyIsValid || !terminalIsValid || !aoIsValid}>
                        Submit
                    </CustomButton>
                </Card.Body>
            </Card>
        </div>
    )
}