/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, { useEffect, useState } from "react";
import { Button, Col, InputGroup, ListGroup, Modal, Row, Spinner } from "react-bootstrap";
import CustomButton from "../../../../components/CustomButton.js";
import PageSpinner from "../../../../components/PageSpinner.js";
import CustomControl from "../../../../components/CustomControl.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleXmark } from "@fortawesome/free-regular-svg-icons";
import moment from "moment";
import { ApiRequest } from "../../../../ApiManager.tsx";
import { width } from "@fortawesome/free-regular-svg-icons/faAddressBook";

export default function MyCompanyRequestOffBlackoutDates({}){
    const [isLoading, setIsLoading] = useState(true);
    const [isUpdating, setIsUpdating] = useState(false);
    const [isCreating, setIsCreating] = useState(false);
    const [blackoutRanges, setBlackoutRanges] = useState([]);
    const [newRangeStartDate, setNewRangeStartDate] = useState('');
    const [newRangeEndDate, setNewRangeEndDate] = useState('');
    const [savingRow, setSavingRow] = useState(undefined);
    const [deletingRow, setDeletingRow] = useState(undefined);

    useEffect(() => {
        loadData();
    }, [])

    function loadData(){
        new ApiRequest('myCompany', 'getCompanyRequestOffBlackoutDates', setIsLoading, (response) => setBlackoutRanges(response.blackoutDates)).withNoAlertOnSuccess().send();
    }

    function handleCreateRange(){
        const blackoutDates = {
            startDate: newRangeStartDate,
            endDate: newRangeEndDate
        }
        new ApiRequest('myCompany', 'createRequestOffBlackoutDate', setIsCreating, (response) => {
            const newArray = Array.from(blackoutRanges);
            blackoutDates.uid = response.uid;
            newArray.push(blackoutDates);
            newArray.sort(sortRanges);
            setBlackoutRanges(newArray);
            setNewRangeStartDate('');
            setNewRangeEndDate('')
        }).withData({blackoutDates: blackoutDates}).send();
    }

    function handleDeleteRange(uid){
        setDeletingRow(uid);
        new ApiRequest('myCompany', 'deleteRequestOffBlackoutDate', setIsLoading, () => setBlackoutRanges(blackoutRanges.filter(r => r.uid != uid))).withUid(uid).send();
        setDeletingRow(undefined);
    }

    function handleUpdateRange(range){
        setSavingRow(range.uid);
        new ApiRequest('myCompany', 'updateRequestOffBlackoutDate', setIsUpdating, () => {
            const newArray = Array.from(blackoutRanges);
            newArray.find(r => r.uid == range.uid).edited = false;
        }).withData({blackoutDates: range}).send();
        setSavingRow(undefined);
    }

    function handleSetRange(uid, key, value) {
        const newArray = Array.from(blackoutRanges);
        newArray.find(r => r.uid === uid)[key] = value;
        newArray.find(r => r.uid === uid).edited = true;
        setBlackoutRanges(newArray);
    }

    function sortRanges(a, b) {
        if (a.startDate < b.startDate) {
            return 1;
        } else if (a.startDate > b.startDate) {
            return -1;
        }
        return 0;   
    }

    const blackoutRangeRows = blackoutRanges.map((range) => {
        return (
            <ListGroup.Item key={range.uid} style={{display: 'flex', alignItems: 'center'}}>
                <InputGroup>
                    { moment(range.endDate).isBefore(moment(), 'days') &&
                        <InputGroup.Text style={{color: 'red'}}>Expired</InputGroup.Text>
                    }
                    { range.endDate < range.startDate &&
                        <InputGroup.Text style={{color: 'red'}}>Invalid Range</InputGroup.Text>
                    }
                    <CustomControl title='Start Date' type='date' min={moment().format('YYYY-MM-DD')} disabled={deletingRow == range.uid || savingRow == range.uid} value={range.startDate} setValue={(value) => handleSetRange(range.uid, 'startDate', value)}/>
                    <CustomControl title='End Date' type='date' min={moment().format('YYYY-MM-DD')} disabled={deletingRow == range.uid || savingRow == range.uid} value={range.endDate} setValue={(value) => handleSetRange(range.uid, 'endDate', value)}/>
                    { range.edited && range.endDate >= range.startDate &&
                        <CustomButton variant={'outline-primary'} isLoading={savingRow == range.uid} onClick={() => handleUpdateRange(range)}>Save Changes</CustomButton>
                    }
                </InputGroup>
                { deletingRow == range.uid ?
                    <Spinner size='sm' style={{marginLeft: 8}}/>
                    :
                    <Button style={{color: 'red', backgroundColor: 'transparent', border: 'none', boxShadow: 'none', padding: 0, marginLeft: 8}} onClick={() => handleDeleteRange(range.uid)}>
                        <FontAwesomeIcon icon={faCircleXmark}/>
                    </Button>
                }
            </ListGroup.Item>
        )
    })

    const creationRow = (
            <Row>
                <CustomControl breakpoints={{lg: 6}} title='Start Date' type='date' min={moment().format('YYYY-MM-DD')} value={newRangeStartDate} setValue={setNewRangeStartDate}/>
                <CustomControl breakpoints={{lg: 6}} title='End Date' type='date' min={moment().format('YYYY-MM-DD')} value={newRangeEndDate} setValue={setNewRangeEndDate}/>
                { newRangeEndDate && newRangeEndDate < newRangeStartDate &&
                    <span style={{color: 'red', textAlign: 'center', width: '100%'}}>Invalid Range</span>
                }
                <Col lg={12}>
                    <CustomButton style={{width: '100%'}} variant={'outline-primary'} onClick={handleCreateRange} isLoading={isCreating} disabled={!newRangeStartDate || !newRangeEndDate || newRangeStartDate > newRangeEndDate}>Add New Date Range</CustomButton>
                </Col>
            </Row>
    )

    return (
        <>
            <Modal.Header closeButton>
                <Modal.Title>Request-Off Blackout Date Ranges</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                { isLoading ? <PageSpinner/> :
                    <div style={{display: 'flex', flexDirection: 'column', gap: 12}}>
                        {creationRow}
                        <p style={{textAlign: 'center'}}>Employees are prevented from requesting off the following dates. AOs and BCs are still be able to manually override this feature by creating time off in <b>Human Resources - Schedule</b></p>
                        { blackoutRangeRows.length > 0 ?
                            <ListGroup>
                                {blackoutRangeRows}
                            </ListGroup>
                            :
                            <span style={{fontStyle: 'italic', opacity: 0.5, textAlign: 'center'}}>Request-Off blackout date ranges will appear here</span>
                        }
                    </div>
                }
            </Modal.Body>
        </>
    ) 
}