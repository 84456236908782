/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, { useState } from "react";
import Button from 'react-bootstrap/Button';
import ButtonGroup from "react-bootstrap/ButtonGroup";
import MyCompanyVehicles from "./MyCompanyVehicles/MyCompanyVehicles.js";
import MyCompanySettings from "./MyCompanySettings/MyCompanySettings.js";
import MyCompanyTerminals from "./MyCompanyTerminals/MyCompanyTerminals.js";
import MyCompanyDevices from "./MyCompanyDevices/MyCompanyDevices.js";
import MyCompanyClients from "./MyCompanyClients/MyCompanyClients.js";
import MyCompanyDocuments from "./MyCompanyDocuments/MyCompanyDocuments.js";
import { faBuilding, faFile, faGear, faMobile, faTruck, faUsers } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import './MyCompany.css'
import { Dropdown } from "react-bootstrap";
import DropdownItemWithCheck from "../../../components/DropdownItemWithCheck.js";

export default function MyCompany({}){
    const [activeTab, setActiveTab] = useState('Settings');

    //////////////////
    /// Desktop 
    //////////////////
 
    const tabs = [
        {key: 'Settings', component: <MyCompanySettings />, icon: faGear},
        {key: 'Documents', component: <MyCompanyDocuments />, icon: faFile},
        {key: 'Terminals', component: <MyCompanyTerminals />, icon: faBuilding},
        {key: 'Clients', component: <MyCompanyClients />, icon: faUsers},
        {key: 'Vehicles', component: <MyCompanyVehicles />, icon: faTruck},
        {key: 'Devices', component: <MyCompanyDevices />, icon: faMobile},
    ];

    const desktopButtons = tabs.map((item) =>  {
        return (
            <Button 
                key={item.key}
                style={activeTab === item.key ? {color: 'white'} : {color: 'var(--bs-primary)', backgroundColor: 'white'}} 
                onClick={() => setActiveTab(item.key)}
            >
                {item.key}
            </Button>
        )
    });

    //////////////////
    /// Tablet 
    //////////////////

    const tabletTabButtons = (
        <div>
            <div style={{display: 'flex'}}>
                <ButtonGroup style={{flex: 1}}>
                    {tabs.map((item) => {
                        return (
                            <Button 
                                key={item.key}
                                style={activeTab === item.key ? {color: 'white'} : {color: 'var(--bs-primary)', backgroundColor: 'white'}} 
                                onClick={() => setActiveTab(item.key)}
                            >
                                <FontAwesomeIcon icon={item.icon}/>
                            </Button>
                        )
                    })}
                </ButtonGroup>
            </div>
            {/* <h3 style={{textAlign: 'center', margin: 10}}>{activeTab}</h3> */}
            <hr style={{margin: 8}}/>
        </div>
    )
    
    //////////////////
    /// Mobile 
    //////////////////

    const mobileDropdown = (
        <Dropdown>
            <Dropdown.Toggle variant="outline-primary" style={{display: 'flex', alignItems: 'center', justifyContent: 'center', flex: 1, width: '100%'}}>My Company - {activeTab}</Dropdown.Toggle>
            <Dropdown.Menu>
                {tabs.map(item => {
                    return <DropdownItemWithCheck key={item.key} active={item.key === activeTab} label={item.key} onClick={() => setActiveTab(item.key)}/>
                })}
            </Dropdown.Menu>
        </Dropdown>
    )
   

    return (
        <div style={{flex: 1, padding: 12, display: 'flex', flexDirection: 'column', overflowY: 'hidden'}}>
            <div className="my-company-tabs-desktop" >
                <ButtonGroup style={{flex: 1}}>
                    {desktopButtons}
                </ButtonGroup>
            </div>
            <div className="my-company-tabs-tablet">
                {tabletTabButtons}
            </div>
            <div className="my-company-tabs-mobile">
                {mobileDropdown}
                <hr style={{margin: 6}}/>
            </div>
            <div style={{flex: 1, overflowY: 'auto'}}>
                {tabs.find(t => t.key === activeTab).component}
            </div>
        </div>
    )
}