/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, { useState } from 'react';
import Form from 'react-bootstrap/Form';
import OptionalCol from '../../../../components/OptionalCol';
import { encodeDecimal, toFixedMax } from '../../../../tools';

export default function PayrollPercentageControl({title, value, setValue, breakpoints, validate, validationMessage, ...props}) {
    const [isFocused, setIsFocused] = useState(false);
    const [localValue, setLocalValue] = useState(value);

    const validateValue = () => {
        let newValue = toFixedMax(encodeDecimal(localValue, 3, 2, true), 2);
        setValue(newValue);
        setLocalValue(newValue);
    }

    const isInvalid = validate ? validationMessage != undefined : undefined;
    const isValid = validate ? validationMessage == undefined : undefined;

    const displayValue = `${value}%`;

    return (
        <OptionalCol breakpoints={breakpoints}>
            <Form.Control type={isFocused ? 'number' : 'text'} style={{lineHeight: 1}} onKeyDown={(e) => {if (e.key == 'Enter') {validateValue()}}} isInvalid={isInvalid} isValid={isValid} placeholder=' ' value={isFocused ? localValue : displayValue} onFocus={() => {setIsFocused(true)}} onBlur={() => {setIsFocused(false); validateValue();}} onChange={(e) => {setLocalValue(e.target.value)}} {...props}/>
        </OptionalCol>
    )
}