/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import { useState } from "react";
import { Col, Dropdown, Modal, Row } from "react-bootstrap";
import { Validation } from "../../../../validation.tsx";
import CustomControl from "../../../../components/CustomStateControls/CustomControl.js";
import DropdownItemWithCheck from "../../../../components/DropdownItemWithCheck.js";
import CustomButton from "../../../../components/CustomButton.js";
import { ApiRequest } from "../../../../ApiManager.tsx";
import AlertConfirmation from "../../../../components/AlertModals/AlertConfirmation.js";

export default function DeviceEditorModal({selectedDevice, devices, setDevices, terminals, setShowQRModal, setDeviceToken, hideModal}){
    const [device, setDevice] = useState(selectedDevice ?? {name: '', terminalIdentifier: null});
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);

    function handleCreateDevice(){
        new ApiRequest('myCompany', 'createDevice', setIsSubmitting, (response) => {
            device['uid'] = response.device.uid;
            device['deviceToken'] = response.device.deviceToken;
            setDeviceToken(response.device.deviceToken);
            setDevices([...devices, device])
            hideModal();
            setShowQRModal(true)
        }).withData({device: device}).withNoAlertOnSuccess().send();
    }

    function handleUpdateDevice(){
        new ApiRequest('myCompany', 'updateDevice', setIsSubmitting, () => {
            const newArray = Array.from(devices);
            newArray[newArray.findIndex(d => d.uid === selectedDevice.uid)] = device;
            setDevices(newArray);
            hideModal();
        }).withData({device: device}).send()
    }

    function handleDeleteDevice(){
        new ApiRequest('myCompany', 'deleteDevice', setIsDeleting, () => {
            setDevices(devices.filter(d => d.uid !== device.uid));
            setShowDeleteModal(false);
            hideModal();
        }).withUid(device.uid).send();
    }

    function handleSetDevice(key, value){
        const newObject = structuredClone(device);
        newObject[key] = value;
        setDevice(newObject);
    }

    return (
        <>
            <Modal.Header closeButton>
                <Modal.Title>{selectedDevice ? 'Edit Device' : 'Add Device'}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <CustomControl floatingLabel breakpoints={{lg: 6}} maxLength={30} label={'Name'} value={device.name} setValue={(value) => handleSetDevice('name', value)}/>
                    <Col lg={6}>
                        <Dropdown>
                            <Dropdown.Toggle style={{width: '100%', height: 58}} variant="outline-primary">{device.terminalIdentifier ? terminals.find(t => t.uid == device.terminalIdentifier)?.name : 'Select a terminal'}</Dropdown.Toggle>
                            <Dropdown.Menu>
                                {terminals.map(t => {
                                    return <DropdownItemWithCheck key={t.uid} label={t.name} active={t.uid == device.terminalIdentifier} onClick={() => handleSetDevice('terminalIdentifier', t.uid)}/>
                                })}
                            </Dropdown.Menu>
                        </Dropdown>
                    </Col>
                </Row>
                </Modal.Body>
            <Modal.Footer>
                {selectedDevice && <button onClick={() => setShowDeleteModal(true)} style={{marginRight: 'auto',border: 'none', marginRight: 2, boxShadow: 'none', color: 'red', backgroundColor: 'transparent'}}>Delete Device</button>}
                <CustomButton isLoading={isSubmitting} variant='outline-primary' disabled={Validation.nonEmptyString(device.name) || Validation.notUndefined(device.terminalIdentifier)} onClick={selectedDevice ? handleUpdateDevice : handleCreateDevice}>{selectedDevice ? 'Save Changes' : 'Submit'}</CustomButton>
            </Modal.Footer>
            <Modal centered show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
                <AlertConfirmation variant='outline-danger' isLoading={isDeleting} callBack={handleDeleteDevice} title='Delete Device' message='Are you sure you want to delete this device?' buttonLabel='Delete'/>
            </Modal>
        </>
    )
}