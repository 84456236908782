/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, { useEffect, useState } from 'react';
import moment from 'moment';
import ListGroup from 'react-bootstrap/ListGroup';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import { ApiRequest } from '../../../../ApiManager.tsx';
import { employeeTypeDictionary, getFileIcon, ptoAccrualTypeDictionary, usdFormatter } from '../../../../tools.js';
import { payTypeDictionary } from '../../Payroll/payrollTools.js';
import KeyValueRow from '../../../../components/KeyValueRow.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FileDownloadButton from '../../../../components/FileDownloadButton.js';
import LoadingWrapper from '../../../../components/LoadingWrapper.js';
import HRUserPayEditor from './HRUserPayEditor.js';
import { Accordion } from 'react-bootstrap';
import QuickTable from '../../../../components/QuickTable.js';
import Cookies from 'universal-cookie';

export default function HRUserPay({selectedEmployee}) {
    const [isLoading, setIsLoading] = useState(false);
    const [selectedPay, setSelectedPay] = useState(null);
    const [effectivePay, setEffectivePay] = useState(null)
    const [effectiveTermination, setEffectiveTermination] = useState(null);
    const [payHistory, setPayHistory] = useState([]);
    const [futurePay, setFuturePay] = useState([]);
    const [modalSwitch, setModalSwitch] = useState('');
    const auth = new Cookies().get('auth');

    useEffect(() => {
        loadData();
    }, [selectedEmployee])

    function loadData(){
        new ApiRequest('hr', 'getAllUserPay', setIsLoading, (response) => {
            const nonEffectiveItems = response.nonEffectivePay.concat(response.nonEffectiveTermination);
            let payHistory = nonEffectiveItems.filter((pay)=>moment(pay.dateEffective).isSameOrBefore(moment(),'day')).sort((a, b) => {
                if (moment(a.dateEffective).isBefore(moment(b.dateEffective), 'days')) {
                    return -1;
                } else if (moment(a.dateEffective).isAfter(moment(b.dateEffective), 'days')) {
                    return 1;
                }else if(moment(a.dateEffective).isSame(moment(b.dateEffective),'day')){
                    if(a.uid < b.uid){
                        return -1;
                    } else {
                        return 1;
                    }
                } else {
                    return 0;
                }
            });

            let futurePay = nonEffectiveItems.filter((pay)=>moment(pay.dateEffective).isAfter(moment(),'day')).sort((a, b) => {
                if (moment(a.dateEffective).isBefore(moment(b.dateEffective), 'days')) {
                    return -1;
                } else if (moment(a.dateEffective).isAfter(moment(b.dateEffective), 'days')) {
                    return 1;
                }else if(moment(a.dateEffective).isSame(moment(b.dateEffective),'day')) {
                    if(a.uid < b.uid){
                        return -1;
                    } else {
                        return 1;
                    }
                } else {
                    return 0;
                }
            });

            setPayHistory(payHistory);
            setFuturePay(futurePay);
            setEffectivePay(response.effectivePay)
            setEffectiveTermination(response.effectiveTermination);
        }).withData({companyUserIdentifier: selectedEmployee.companyUserIdentifier}).withNoAlertOnSuccess().send();
    }

    function handleAddPay(newPayInfo) {
        let payToAdjustInState;
        if(moment(newPayInfo.dateEffective).isSameOrBefore(moment()) && (effectivePay && moment(newPayInfo.dateEffective).isAfter(moment(effectivePay?.dateEffective)))){
            payToAdjustInState = effectivePay;
            setEffectivePay(newPayInfo);
            setEffectiveTermination(null);
        } else{
            payToAdjustInState = newPayInfo;
        }

        if(moment(payToAdjustInState.dateEffective).isAfter(moment())){
            const newArray = Array.from(futurePay);
            newArray.push(payToAdjustInState);
            setFuturePay(newArray.sort(sortPay))
        }else{
            const newArray = Array.from(payHistory);
            newArray.push(payToAdjustInState);
            setPayHistory(newArray.sort(sortPay));
        }
        hideModal();
    }


    function sortPay(a, b) {
        if (a.dateEffective < b.dateEffective) {
            return -1;
        } else if (a.dateEffective > b.dateEffective) {
            return 1;
        }
        return 0;   
    }

    function hideModal() {
        setModalSwitch('');
    }

    const allPay = (effectivePay ? [effectivePay, ...payHistory, ...futurePay] : [...payHistory, ...futurePay]);

    const historyElements = payHistory.map((item) => {
        return (
            <tr key={item.uid} className='cursor-pointer' onClick={() => {setModalSwitch('preview'); setSelectedPay(item)}}>
                <td>{moment(item.dateEffective).format('MMM D, YYYY')}</td>
                <td>{item.title ? `${employeeTypeDictionary[item.employeeType]} ${item.title}` : `Termination (${item.terminationType})`}</td>
                <td>{item.title ? `${usdFormatter.format(item.payRate)} ${payTypeDictionary[item.payType]}` : ''}</td>
            </tr>
        )
    });

    const futureElements = futurePay.map((item) => {
        return (
            <tr key={item.uid} className='cursor-pointer' onClick={() =>  {setModalSwitch('preview'); setSelectedPay(item)}}>
                <td>{moment(item.dateEffective).format('MMM D, YYYY')}</td>
                <td>{item.title ? `${employeeTypeDictionary[item.employeeType]} ${item.title}` : `Termination (${item.terminationType})`}</td>
                <td>{item.title ? `${usdFormatter.format(item.payRate)} ${payTypeDictionary[item.payType]}` : ''}</td>
            </tr>
        )
    });

    const terminationCard = !effectiveTermination ? (<h4 style={{textAlign: 'center'}}>No Effective Pay Or Termination</h4>) : (
        <>
            <Card style={{marginBottom: 16}}>
                <Card.Header>
                    <Card.Title>Effective Termination</Card.Title>
                </Card.Header>
                <Card.Body>
                   <HRUserTerminationPreview termination={effectiveTermination}/>
                </Card.Body>
            </Card>
        </>
    )
    
    const content = (
        <div style={{display: 'flex', flexDirection: 'column', height: '100%', overflowY: 'auto', gap: 12}}>
            { auth.companyUserIdentifier &&
                <div style={{display: 'flex', justifyContent: 'end'}}>
                    <Button variant={'outline-primary'} onClick={() => {setModalSwitch('editor')}}>Create New Pay</Button>
                </div>
            }
            { futureElements.length > 0 && 
                <Card>
                    <Card.Header>
                        <Card.Title>Upcoming</Card.Title>
                    </Card.Header>
                    <Card.Body>
                        <QuickTable hover headers={['Effective Date', 'Position', 'Pay']} rows={futureElements}/>
                    </Card.Body>
                </Card>
            }
            { effectivePay ?
                <Card>
                    <Card.Header>
                        <Card.Title>Effective Pay</Card.Title>
                    </Card.Header>
                    <Card.Body>
                        <div style={{display: 'flex', flexDirection: 'row', gap: 12}}>
                            <HRUserPayPreview pay={effectivePay}/>
                        </div>
                    </Card.Body>
                </Card>
            : 
                terminationCard
            }
            { historyElements.length > 0 && 
                <Card>
                    <Card.Header>
                        <Card.Title>Pay History</Card.Title>
                    </Card.Header>
                    <Card.Body>
                        <QuickTable hover headers={['Effective Date', 'Position', 'Pay']} rows={historyElements}/>
                    </Card.Body>
                </Card>
            }
        </div>
    )
    
    return (
        <>
            <LoadingWrapper isLoading={isLoading} onlyRenderIfTruthy={selectedEmployee ?? false}>
                {content}
            </LoadingWrapper>
            <Modal show={modalSwitch === 'editor'} onHide={hideModal} centered size='xl' fullscreen='xl-down' backdrop='static'>
                <HRUserPayEditor companyUserIdentifier={selectedEmployee.companyUserIdentifier} allPay={allPay.filter(p => !!p.title)} handleAddPay={handleAddPay}/>
            </Modal>
            <Modal show={modalSwitch === 'preview'} onHide={hideModal} centered size='lg' fullscreen='md-down'>
                <Modal.Header closeButton>
                    <Modal.Title>{`Viewing ${selectedPay?.title ? 'Pay' : 'Termination'}`}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    { selectedPay?.title ? 
                        <HRUserPayPreview pay={selectedPay}/>
                    :
                        <HRUserTerminationPreview termination={selectedPay}/>
                    }
                </Modal.Body>
            </Modal>
        </>
    )

}

export function HRUserPayPreview({pay}){
    const payData = [
        {title: 'Effective Date', value: moment(pay.dateEffective).format('MMM D, YYYY')},
        {title: 'Title', value: pay.title},
        {title: 'BWC Code', value: pay.bwcCode},
        {title: 'Pay Rate', value: usdFormatter.format(pay.payRate)},
        {title: 'Pay Type', value: payTypeDictionary[pay.payType]},
        {title: 'Hourly Wage', value: usdFormatter.format(pay.hourlyWage)},
        {title: 'PTO Accrual ' + ptoAccrualTypeDictionary[pay.ptoAccrualType], value: (pay.ptoAccrual? pay.ptoAccrual : 0)},
        {title: 'Employee Type', value: pay.employeeType == 0 ? 'Full-Time' : 'Part-Time'},
        {title: 'Medical Insurance', value: usdFormatter.format(pay.medical)},
        {title: 'Dental Insurance', value: usdFormatter.format(pay.dental)},
        {title: 'Vision Insurance', value: usdFormatter.format(pay.vision)},
        {title: 'Child Support', value: usdFormatter.format(pay.childSupport.reduce((prev, curr) => {return prev + parseFloat(curr)}, 0))},
        {title: 'Automatic Reimbursements', value: usdFormatter.format(pay.automaticReimbursements.reduce((prev, curr) => {return prev + parseFloat(curr.amount)}, 0))},
        {title: 'Automatic Deductions (Flat Rate)', value: usdFormatter.format(pay.automaticDeductions.filter((d)=>d.type === '401k (Flat Rate)' || d.type === 'Other (Flat Rate)').reduce((prev, curr) => {return prev + parseFloat(curr.amount)}, 0))},
        {title: 'Automatic Deductions (% of Gross)', value: pay.automaticDeductions.filter((d)=>d.type === '401K (% of Gross)' || d.type === 'Other (% of Gross)').reduce((prev, curr) => {return prev + parseFloat(curr.amount)}, 0) + '%'},
    ];

    const payElements = payData.map((keyValuePair) => {
        return (
            <KeyValueRow isListItem key={keyValuePair.title} title={keyValuePair.title} value={keyValuePair.value}/>
        )
    });

    return (
        <ListGroup style={{flex: 1}}>
            {payElements}
        </ListGroup>
    )
}

export function HRUserTerminationPreview({termination}){
    return (
        <>
            <ListGroup style={{marginBottom: 16}}>
                <KeyValueRow isListItem title='Effective Date' value={termination.dateEffective} />
                <KeyValueRow isListItem title='Termination Type' value={termination.terminationType} />
                <KeyValueRow isListItem title='Termination Summary' value={termination.terminationSynopsis} />
                <KeyValueRow isListItem title='Complaints' value={termination.customerComplaints}/>
            </ListGroup>
            <div style={{display: 'flex', flexDirection: 'column', gap: 8}}>
                <h5 style={{textAlign: 'center'}}>Attachments</h5>
                {termination.attachments.map((a) => {
                    return (
                        <Card key={a.fileIdentifier} style={{display: 'flex', flexDirection: 'row'}}>
                            <div style={{display: 'flex', padding: 18, gap: 12, borderTopLeftRadius: 6, borderBottomLeftRadius: 6, backgroundColor: 'var(--bs-primary)', alignItems: 'center', flexShrink: 0}}>
                                <FontAwesomeIcon icon={getFileIcon(a.type)} style={{color: 'white'}}/>
                            </div>
                            <div style={{borderTopRightRadius: 6, borderBottomRightRadius: 6, border: '2px solid var(--bs-primary)', padding: '0px 8px 0px 8px', borderLeft: 'none', flex: 1, alignItems: 'center', justifyContent: 'space-between', display: 'flex', padding: 8}}>
                                <span style={{whiteSpace: 'nowrap', textOverflow: 'ellipsis', width: '20%'}}>{a.name}</span>
                                <FileDownloadButton type='hr' target='downloadTerminationAttachment' data={{fileIdentifier: a.fileIdentifier}} doc={a}/>
                            </div>
                        </Card>
                    )
                })}
            </div>
        </>
    )
}