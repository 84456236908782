/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import ListGroup from 'react-bootstrap/ListGroup';
import Button from 'react-bootstrap/Button';
import CustomSpinner from '../../../components/CustomSpinner.js';
import { ApiRequest } from '../../../ApiManager.tsx';
import AlertConfirmation from '../../../components/AlertModals/AlertConfirmation.js';
import moment from 'moment';
import Route from './Models/Route.js';

export default function SchedulingTemplateList({selectedTemplate, setSelectedTemplate, setTemplateMode, hideModal, setTemplateRoutes, startDate, setRoutes, setShowRouteEditor}){
    const [isLoading, setIsLoading] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const [isImporting, setIsImporting] = useState(false);
    const [templates, setTemplates] = useState([]);
    const [showImportAlert, setShowImportAlert] = useState(false);
    const [showDeleteAlert, setShowDeleteAlert] = useState(false);

    useEffect(() => {
        loadData();
    }, [])

    function loadData(){
        new ApiRequest('scheduling', 'getTemplates', setIsLoading, (response) =>  setTemplates(response.templates)).withNoAlertOnSuccess().send();
    }

    function handleGetTemplate(uid){
        new ApiRequest('scheduling', 'getTemplate', setIsDeleting, (response) => {
            hideModal();
            setTemplateMode(true);
            setTemplateRoutes(response.template.routes.map(r => Route.decodeFromTemplateRoute(r, startDate)));
            setShowRouteEditor(false)
        }).withUid(uid).withNoAlertOnSuccess().send();
    }

    function handleImportTemplate(){
        new ApiRequest('scheduling', 'importTemplate', setIsImporting, (response) => {
            setRoutes(response.routes.map(r => Route.decode(r)));
            setShowRouteEditor(false)
            hideModal();
        }).withData({uid: selectedTemplate.uid, startDate: moment(startDate).format('YYYY-MM-DD'), endDate: moment(startDate).add(6, 'days').format('YYYY-MM-DD')}).send();
    }

    function handleDeleteTemplate(){
        new ApiRequest('scheduling', 'deleteTemplate', setIsDeleting, () => {setShowDeleteAlert(false); setShowRouteEditor(false); loadData()}).withUid(selectedTemplate.uid).send();
    }

    const templateItems = templates.map((t) => {
        return (
            <ListGroup.Item key={t.uid}>
                <div style={{display: 'flex', alignItems: 'center', gap: 12, justifyContent: 'space-between'}}>
                    <p style={{margin: 0}}>{t.name}</p>
                    <p style={{fontSize: 12, flex: 1, opacity: 0.5, margin: 0, whiteSpace: 'nowrap', overflowX: 'hidden', textOverflow: 'ellipsis'}}>Last Modified: {moment(t.lastModified).format('LL')}</p>
                    <div style={{display: 'flex', gap: 8}}>
                        <Button variant={'outline-primary'} onClick={()=> {setSelectedTemplate(t); handleGetTemplate(t.uid)}}>View/Edit</Button>
                        <Button variant={'outline-primary'} onClick={() => {setSelectedTemplate(t); setShowImportAlert(true)}}>Import</Button>
                        <Button variant={'outline-danger'} onClick={() => {setSelectedTemplate(t); setShowDeleteAlert(true)}}>Delete</Button>
                    </div>
                </div>
            </ListGroup.Item>
        )
    })

    const content = (
        <ListGroup>
            {templateItems.length ? templateItems : <h5 style={{textAlign: 'center'}}>There are no templates.</h5>}
        </ListGroup>
    );

    return (
        <>
            <Modal.Header closeButton>
                <Modal.Title>Templates</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {isLoading ? <CustomSpinner/> : content}
            </Modal.Body>
            <Modal centered show={showDeleteAlert} onHide={() => setShowDeleteAlert(false)} >
                <AlertConfirmation
                    isLoading={isDeleting}
                    variant='outline-danger'
                    title={'Confirm Deletion'} 
                    message={'Permanently delete template? This cannot be undone.'} 
                    callBack={handleDeleteTemplate} 
                    buttonLabel={'Delete Template'}
                />
            </Modal>
            <Modal centered show={showImportAlert} onHide={() => setShowImportAlert(false)}>
            <AlertConfirmation
                    isLoading={isImporting}
                    variant='outline-danger'
                    title={'Confirm Import'} 
                    message={'Importing this template will overwrite all routes for this week. Are you sure you want to import this template?'} 
                    callBack={handleImportTemplate} 
                    buttonLabel={'Import Template'}
                />
            </Modal>
        </>   
    )
}