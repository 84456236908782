/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, { useState } from 'react';
import Cookies from 'universal-cookie';
import Dropdown from 'react-bootstrap/Dropdown';
import { Form, InputGroup, Spinner } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import { ApiRequest } from '../ApiManager.tsx';

export default function CompanySelector({companies}) {
    const [search, setSearch] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const selectedCompany = new Cookies().get('userData').companyIdentifier;

    const handleSwitchCompany = (company) => {
        new ApiRequest('authentication', 'switchCompany', setIsLoading, () => {
            window.location.reload();
        }).withData({companyIdentifier: company.uid}).withNoAlertOnSuccess().send();
    }

    const companyDropDownOptions = companies.filter(c => c.uid != selectedCompany && c.name.toLowerCase().includes(search.toLowerCase())).map((company) => {
        return (
            <Dropdown.Item style={{fontSize: 12}} key={company.name} onClick={() => {handleSwitchCompany(company)}}>
                {company.name}
            </Dropdown.Item>
        )
    });

    return (companies.length > 1 ? 
        <Dropdown>
            <Dropdown.Toggle variant='outline-primary' style={{fontSize: 12, width: '100%', maxWidth: 234, display: 'flex', alignItems: 'center', marginLeft: 'auto', marginRight: 'auto'}}>
                <span style={{whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', textAlign: 'center', marginLeft: 'auto', marginRight: 'auto'}}>{isLoading ? <Spinner size='sm'/> : selectedCompany ? companies.find(c => c.uid == selectedCompany).name : 'Select Company'}</span>
            </Dropdown.Toggle>
            <Dropdown.Menu>
                { companies.length > 3 && 
                    <>
                    
                        <Dropdown.ItemText style={{padding: '0px 6px 0px 6px'}}>
                            <InputGroup>
                                <InputGroup.Text>
                                <FontAwesomeIcon icon={faMagnifyingGlass}/>
                                </InputGroup.Text>
                                <Form.Control value={search} onChange={(event) => {setSearch(event.target.value)}}/>
                            </InputGroup>
                        </Dropdown.ItemText>
                        <Dropdown.Divider/>
                    </>
                }
                {companyDropDownOptions}
            </Dropdown.Menu>
        </Dropdown>
        : 
        <span style={{fontWeight: 'bold', fontSize: 13, textAlign: 'center', color: 'var(--bs-primary)', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>{companies.find(c => c.uid === selectedCompany)?.name}</span>
            
    )
}
