/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dropdown } from 'react-bootstrap';
import {  useHistory, useLocation } from 'react-router-dom';

export default function NavigationBarDropdownItem({route}){
    const history = useHistory();
    const location = useLocation();

    const isActive = route.path == location.pathname;

    return (
        <Dropdown.Item style={{backgroundColor: isActive ? 'var(--bs-primary)' : undefined, color: isActive ? 'white' : 'var(--bs-primary)', display: 'flex', alignItems: 'center', gap: 4}} onClick={() => {history.push(route.path)}}>
            <FontAwesomeIcon style={{width: 24}} icon={route.icon}/>
            <span>{route.name}</span>
        </Dropdown.Item>
    )
}