/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React from "react";
import InputGroup from 'react-bootstrap/InputGroup';
import Button from 'react-bootstrap/Button';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashCan } from "@fortawesome/free-solid-svg-icons";
import DBonus from "../../../../Models/DBonus.js";
import { Dropdown, Form } from "react-bootstrap";
import PayrollMoneyControl from "../../../PayrollMoneyControl.js";


export default function PayrollDBonusItem({dBonus, handleSetDBonus, handleRemoveDBonus}) {
    const dTypeDropDownItems = DBonus.dBonusTypes.map((type) =>{
        return (
            <Dropdown.Item key={type} active={dBonus.type === type} onClick={() => {handleSetDBonus(dBonus.uid, 'type', type)}}>
                <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                    <p style={{marginBottom: 0, marginRight: 8}}>{type}</p> 
                </div>
            </Dropdown.Item>
        )
    });

    return (
        <tr style={{verticalAlign: 'middle', textAlign: 'center'}}>
            <td>
                <Dropdown>
                    <Dropdown.Toggle style={{padding: '3px 8px'}} variant="outline-primary" disabled={!handleSetDBonus}>{dBonus.type}</Dropdown.Toggle>
                    <Dropdown.Menu className="position-fixed">
                        {dTypeDropDownItems}
                    </Dropdown.Menu>
                </Dropdown>
            </td>
            <td>
                <PayrollMoneyControl style={{minWidth: 100, height: 32}} lhDecimalPlaces={3} value={dBonus.amount} setValue={(newValue) => {handleSetDBonus(dBonus.uid, 'amount', newValue)}} disabled={!handleSetDBonus}/>
            </td>
            <td>
                { handleRemoveDBonus && 
                    <Button variant="outline-danger" style={{padding: '3px 8px'}} onClick={() => handleRemoveDBonus(dBonus.uid)}>
                        <FontAwesomeIcon icon={faTrashCan}/>
                    </Button>
                }
            </td>
        </tr>
    )
}