/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import { Button, Modal, Row } from "react-bootstrap"
import SwitchControl from "../../../../components/SwitchControl"
import { useReducer, useState } from "react"
import MyCompanyRequestOffBlackoutDates from "./MyCompanyRequestOffBlackoutDates";
import ControlStateProps from "../../../../state/ControlStateProps.tsx";
import CustomControl from "../../../../components/CustomControl.js";

export default function TimeOffSettings({settings, setSettingsKey, originalSettings}){
    const [showModal, setShowModal] = useState(false);

    return (
        <div style={{overflow: "hidden"}}>
            <Button style={{display: 'block', marginLeft: 'auto'}} variant={'outline-primary'} onClick={() => setShowModal(true)}>View/Edit Request-Off Blackout Date Ranges</Button>
            <Row style={{padding: 12}}>
                <CustomControl
                    title='Set number of days drivers can see when viewing their schedule in TTA App'
                    breakpoints={{lg: 12}}
                    type='number'
                    min={0}
                    max={365}
                    originalValue={originalSettings.driverSchedulePreviewDays} 
                    value={settings.driverSchedulePreviewDays}
                    setValue={(value) => setSettingsKey('driverSchedulePreviewDays', value)}
                />
                <SwitchControl
                    breakpoints={{lg: 12}}
                    title={'Allow time off approval as PTO with negative PTO balance'}
                    originalValue={originalSettings.allowNegativePto}
                    value={settings.allowNegativePto}
                    setValue={(value) => setSettingsKey('allowNegativePto', value)}   
                />
                <SwitchControl
                    breakpoints={{lg: 12}}
                    title='Limit the number of employees who can request time off on a given day'
                    originalValue={originalSettings.requestOffPerDayLimit ?? false}
                    value={settings.requestOffPerDayLimit ?? false}
                    setValue={() => setSettingsKey('requestOffPerDayLimit', settings.requestOffPerDayLimit == null ? 1 : null)} 
                />
                { settings.requestOffPerDayLimit != null &&
                    <CustomControl title='Maximum number of employees who can request time off on a given day' max={10} breakpoints={{lg: 12}} type='number' originalValue={originalSettings.requestOffPerDayLimit} value={settings.requestOffPerDayLimit} setValue={(value) => setSettingsKey('requestOffPerDayLimit', value)}/>
                }
                <SwitchControl
                    breakpoints={{lg: 12}}
                    title='Limit the number of employees who can request time off on a given day in each terminal'
                    originalValue={originalSettings.requestOffPerTerminalLimit ?? false}
                    value={settings.requestOffPerTerminalLimit ?? false}
                    setValue={() =>  setSettingsKey('requestOffPerTerminalLimit',  settings.requestOffPerTerminalLimit == null ? 1 : null)} 
                />
                { settings.requestOffPerTerminalLimit != null &&
                    <CustomControl title='Maximum number of employees who can request time off on per terminal' max={10} breakpoints={{lg: 12}} type='number' originalValue={originalSettings.requestOffPerTerminalLimit} value={settings.requestOffPerTerminalLimit} setValue={(value) => setSettingsKey('requestOffPerTerminalLimit', value)}/>
                }
            </Row>
            <Modal centered size='lg' show={showModal} onHide={() => setShowModal(false)}>
                <MyCompanyRequestOffBlackoutDates/>
            </Modal>
        </div>
    )
}