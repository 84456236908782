/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, { useEffect, useState } from "react";
import { Accordion, ButtonGroup, Col, Container, Dropdown, Row} from "react-bootstrap";
import LoadingWrapper from "../../../../components/LoadingWrapper.js";
import { ApiRequest } from "../../../../ApiManager.tsx";
import CustomButton from "../../../../components/CustomButton.js";
import './MyCompanySettings.css';
import PayrollSettings from "./PayrollSettings.js";
import TimeOffSettings from "./TimeOffSettings.js";
import OnboardingSettings from "./OnboardingSettings.js";
import TimeClockSettings from "./TimeClockSettings.js";
import Company from "../Models/Company.js";
import ProfileSettings from "./ProfileSettings.js";
import { Validation } from "../../../../validation.tsx";
import { useStateObjectWithValidation } from "../../../../hooks.tsx";

export default function MyCompanySettings({}){
    const [isLoading, setIsLoading] = useState(false);
    const [isUpdating, setIsUpdating] = useState(false);
    const [settings, setSettings, setSettingsKey, settingsVMs, settingsIsValid] = useStateObjectWithValidation(
        Company.initDefault(),
        {
            uid: Validation.none,
            name: Validation.nonEmptyString,
            ein: Validation.nonEmptyString,
            address: Validation.none,
            benefits: Validation.none,
            isEnabled: Validation.none,
            driverSchedulePreviewDays: Validation.notUndefined,
            companyAddressAllowClockIn: Validation.notUndefined,
            companyAddressAllowClockInRadius: Validation.notUndefined,
            enableGeofencing: Validation.notUndefined,
            restrictWhoSeesPay: Validation.notUndefined,
            showBcsPayrollHistory: Validation.notUndefined,
            payrollEnabled: Validation.notUndefined,
            bcPayrollEnabled: Validation.notUndefined,
            payrollPeriodLength: Validation.notUndefined,
            payrollPeriodStartDayIndex: Validation.notUndefined,
            payrollStartDate: Validation.notUndefined,
            requestOffPerDayLimit: Validation.notUndefined,
            requestOffPerDayPerTerminalLimit: Validation.notUndefined,
            allowNegativePto: Validation.notUndefined,
            autoIncentiveWageThreshold: Validation.none,
            autoIncentiveWageAmount: Validation.none,
            autoOvertimeThreshold: Validation.none,
            autoOvertimeAmount: Validation.none,
            includeAOsInPayroll: Validation.notUndefined,
            generatePayrollForEachTerminal: Validation.notUndefined,
            defaultOfferLetterForDriver: Validation.notUndefined,
            defaultOfferLetterForBc: Validation.notUndefined,
            defaultOfferLetterForAdmin: Validation.notUndefined,
            clockInReminder: Validation.notUndefined,
            require1EContact: Validation.notUndefined,
        }
    )
    const [originalSettings, setOriginalSettings] = useState(settings);
    const [offerLetters, setOfferLetters] = useState([]);
    
    useEffect(() => {
        loadData();
    }, [])

    function loadData(){
        new ApiRequest('myCompany', 'get', setIsLoading, (response) => {
            setSettings(Company.decode(response.company))
            setOriginalSettings(Company.decode(response.company))
            setOfferLetters(response.offerLetters);
        }).withNoAlertOnSuccess().send();
    }

    function handleSaveSettings(){
        new ApiRequest('myCompany', 'updateSettings', setIsUpdating, () => setOriginalSettings(Company.decode(settings.encode()))).withData({companySettings: settings.encode()}).send()
    }

    return (
        <LoadingWrapper isLoading={isLoading}>
            <h3 style={{textAlign: 'center', margin: '12px 0 24px 0', fontWeight: 'bold'}}>{settings.name}</h3>
            <Accordion alwaysOpen defaultActiveKey={['0', '1', '2', '3', '4']}>
                <Accordion.Item eventKey="0">
                    <Accordion.Header><b>Profile Settings</b></Accordion.Header>
                    <Accordion.Body>
                        <ProfileSettings settings={settings} setSettingsKey={setSettingsKey} settingsVMs={settingsVMs} originalSettings={originalSettings}/>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                    <Accordion.Header><b>Payroll Settings</b></Accordion.Header>
                    <Accordion.Body>
                        <PayrollSettings settings={settings} setSettingsKey={setSettingsKey} settingsVMs={settingsVMs} originalSettings={originalSettings} isUpdating={isUpdating} handleSaveSettings={handleSaveSettings}/>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                    <Accordion.Header><b>Time Clock Settings</b></Accordion.Header>
                    <Accordion.Body>
                        <TimeClockSettings settings={settings} setSettingsKey={setSettingsKey} settingsVMs={settingsVMs} originalSettings={originalSettings}/>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="3">
                    <Accordion.Header><b>Time Off Settings</b></Accordion.Header>
                    <Accordion.Body>
                        <TimeOffSettings settings={settings} setSettingsKey={setSettingsKey} settingsVMs={settingsVMs} originalSettings={originalSettings}/>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="4">
                    <Accordion.Header><b>Onboarding Settings</b></Accordion.Header>
                    <Accordion.Body>
                        <OnboardingSettings settings={settings} setSettingsKey={setSettingsKey} settingsVMs={settingsVMs} originalSettings={originalSettings} offerLetters={offerLetters} setOfferLetters={setOfferLetters}/>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
            <CustomButton variant={'outline-primary'} stateObject={settings} style={{width: '100%', marginTop: 12}} isLoading={isUpdating} onClick={handleSaveSettings}>Save Changes</CustomButton>
        </LoadingWrapper>
    )
}