/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/
import React, { useState } from 'react';
import moment from 'moment';
import Modal from 'react-bootstrap/Modal';
import CustomButton from '../../../../components/CustomButton';
import { Button, FloatingLabel, Form, ListGroup } from 'react-bootstrap';
import { getFakeUID } from '../../../../tools';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashCan} from '@fortawesome/free-solid-svg-icons';
import DateTimePicker from '../../../../components/DateTimePicker';
import { ApiRequest } from '../../../../ApiManager.tsx';

export default function HRTimePunchModal({selectedDate, usersTimeData, userToEditTime, handleSetUserTimeDataForSelectedDate, hideModal}) {
    const [isSubmitting, setIsSubmitting] = useState(false);

    const [localTimeData, setLocalTimeData] = useState(usersTimeData.map(t => {
        return {
            inTime: moment(t.inTime).format('HH:mm'),
            outTime: t.outTime ? moment(t.outTime).format('HH:mm') : '',
            id: getFakeUID()
        }
    }).sort((a, b) => {
        return a.inTime < b.inTime ? -1 : a.inTime > b.inTime ? 1 : 0;
    }));

    const handleSubmit = () => {
        const newTimeData = localTimeData.map((time) => {
            return {
                companyUserIdentifier: userToEditTime.companyUserIdentifier,
                inTime: `${selectedDate} ${time.inTime}`,
                outTime: time.outTime ? `${selectedDate} ${time.outTime}` : null
            }
        });

        new ApiRequest('hr', 'multipleTimeSubmit', setIsSubmitting, (response) => {
            handleSetUserTimeDataForSelectedDate(userToEditTime.companyUserIdentifier, response.updatedEntries);
            hideModal();
        }).withData({companyUserIdentifier: userToEditTime.companyUserIdentifier, date: selectedDate, timeData: newTimeData}).send();
    }


    const handleSetTimesheetInTime = (id, inTime) => {
        const newArray = Array.from(localTimeData);
        const entry = newArray.find(t => t.id == id);
        entry.inTime = inTime;
        setLocalTimeData(newArray);
    }

    const handleSetTimesheetOutTime = (id, outTime) => {
        const newArray = Array.from(localTimeData);
        const entry = newArray.find(t => t.id == id);
        entry.outTime = outTime;
        setLocalTimeData(newArray);
    }

    const handleDeleteTimesheet = (id) => {
        setLocalTimeData(localTimeData.filter(t => t.id != id));
    }


    const addEmptyTimeObject = () => {
        const emptyTimeObject = {
            id: getFakeUID(),
            inTime: localTimeData[localTimeData.length - 1]?.outTime ?? moment().format('HH:mm'),
        };
        setLocalTimeData([...localTimeData, emptyTimeObject]);
    };

    
   
    const usersPunches = localTimeData.map((time, index) => {
        return (
            <ListGroup.Item key={time.id}>
                <div style={{display: 'flex', gap: 12, alignItems: 'center'}}>
                    <DateTimePicker title='In-Punch' type='time' value={time.inTime} setValue={(newValue) => {handleSetTimesheetInTime(time.id, newValue)}} min={index > 0 ? localTimeData[index - 1].outTime : undefined} max={time.outTime ? time.outTime : undefined}/>
                    <DateTimePicker title='Out-Punch' type='time' value={time.outTime} setValue={(newValue) => {handleSetTimesheetOutTime(time.id, newValue)}} optional={index == localTimeData.length - 1} defaultDate={time.inTime} min={time.inTime} max={index < localTimeData.length - 1 ? localTimeData[index + 1].inTime : undefined}/>
                    <Button variant={'outline-primary'} style={{padding: '2px 6px 2px 6px', marginLeft: 'auto'}} onClick={() => {handleDeleteTimesheet(time.id)}}>
                        <FontAwesomeIcon icon={faTrashCan}/>
                    </Button>
                </div>
            </ListGroup.Item>
        );
    });


    return (
        <>
            <Modal.Header closeButton>
                <Modal.Title>{`Timesheet Entries on ${moment(selectedDate).format('MMM D')} for ${userToEditTime.firstName} ${userToEditTime.lastName}`}</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{display: 'flex', flexDirection: 'column', gap: 12}}>
                <CustomButton disabled={localTimeData.length > 0 && !localTimeData[localTimeData.length - 1].outTime} onClick={addEmptyTimeObject} style={{alignSelf: 'flex-end'}}>Add Timesheet Entry</CustomButton>
                <ListGroup>
                    {usersPunches}
                </ListGroup>
            </Modal.Body>
            <Modal.Footer>
                <CustomButton isLoading={isSubmitting} onClick={handleSubmit}>Submit Changes</CustomButton>
            </Modal.Footer>
                
        </>
    )
}