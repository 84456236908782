/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, { useState } from 'react';
import './NavigationBar.scss';
import Modal from 'react-bootstrap/Modal';
import Cookies from 'universal-cookie';
import LogoutModal from './LogoutModal';
import { ApiRequest } from '../../ApiManager.tsx';
import NavigationBarLarge from './NavigationBarLarge.js';
import NavigationBarMedium from './NavigationBarMedium.js';
import NavigationBarMobile from './NavigationBarMobile.js';

export default function NavigationBar({routes, auth, companies, handleCheckAuthentication}) {
    const [showLogoutModal, setShowLogoutModal] = useState(false);
    const userData = new Cookies().get('userData');
    const selectedCompany = new Cookies().get('userData').companyIdentifier;

    function handleStopShadowing(){
        new ApiRequest('admin', 'stopShadowing', () => {}, () => {
            handleCheckAuthentication();
        }).send();
    }

    return (
        <>
            <NavigationBarLarge auth={auth} userData={userData} companies={companies} setShowLogoutModal={setShowLogoutModal} handleStopShadowing={handleStopShadowing} routes={routes}/>
            <NavigationBarMedium auth={auth} userData={userData} companies={companies} setShowLogoutModal={setShowLogoutModal} handleStopShadowing={handleStopShadowing} routes={routes}/>
            <NavigationBarMobile auth={auth} userData={userData} companies={companies} setShowLogoutModal={setShowLogoutModal} handleStopShadowing={handleStopShadowing} routes={routes}/>
            <Modal show={showLogoutModal} onHide={() => {setShowLogoutModal(false)}} centered>
                <LogoutModal handleCheckAuthentication={handleCheckAuthentication}/>
            </Modal>
        </>
    )   
}
