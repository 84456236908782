/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import { useState } from "react"
import { ApiRequest } from "../../../../ApiManager.tsx";
import QuickTable from "../../../../components/QuickTable.js";
import { Button, Form, ListGroup, Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import TerminalEditorModal from "./TerminalEditorModal.js";
import LoadingWrapper from "../../../../components/LoadingWrapper.js";
import { addressToString } from "../../../../tools.js";
import "./MyCompanyTerminals.css";
import KeyValueRow from "../../../../components/KeyValueRow.js";
import SearchBar from "../../../../components/SearchBar.js";

export default function MyCompanyTerminals({}){
    const [isLoading, setIsLoading] = useState(false);
    const [terminals, setTerminals] = useState([]);
    const [selectedTerminal, setSelectedTerminal] = useState(undefined);
    const [showModal, setShowModal] = useState(false);
    const [search, setSearch] = useState('');

    useState(() => {
        loadData();
    }, []);

    function loadData(){
        new ApiRequest('myCompany', 'getTerminals', setIsLoading, (response) => setTerminals(response.terminals)).withNoAlertOnSuccess().send();
    }

    ///////////////////
    /// DESKTOP
    ///////////////////

    const terminalRows = terminals.filter(t => {
        return t.name.toLocaleLowerCase().includes(search.toLocaleLowerCase());
    }).map((t) => {
        return (
            <tr key={t.uid} style={{cursor: 'pointer'}} onClick={() => {setSelectedTerminal(t); setShowModal(true)}}>
                <td>{t.name}</td>
                <td>{addressToString(t.address)}</td>
                <td>{t.defaultLoadTime}</td>
                <td>{t.clockInRadius}</td>
            </tr>
        )
    });

    ///////////////////
    /// MOBILE
    ///////////////////

    const mobileTerminalRows = terminals.filter(t => {
        return t.name.toLocaleLowerCase().includes(search.toLocaleLowerCase());
    }).map(t => {
        return (
            <ListGroup.Item action key={t.uid}  onClick={() => {setSelectedTerminal(t); setShowModal(true);}}>
                <h6 style={{fontWeight: 'bold'}}>{t.name}</h6>
                <KeyValueRow style={{display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap', gap: 2, marginBottom: 4}} title={'Address'} value={addressToString(t.address)}/>
                <KeyValueRow title={'Default Load Time'} value={`${t.defaultLoadTime} min`}/>
                <KeyValueRow title={'Clock-In Radius'} value={`${t.clockInRadius} mi`}/>
            </ListGroup.Item>
        )
    })

    return (
        <LoadingWrapper isLoading={isLoading}>
            <Button style={{display: 'block', marginBottom: 12, marginLeft: 'auto'}} variant={'outline-primary'} onClick={() => {setSelectedTerminal(undefined); setShowModal(true);}}>Add Terminal(s)</Button>
            <div style={{marginBottom: 12}}>
                <SearchBar label='Search by name' value={search} setValue={setSearch}/>
            </div>
            <div className="terminal-list-desktop">
                <QuickTable hover headers={['Name', 'Address',  'Default Load Time (minutes)', 'Clock-In Radius (miles)']} rows={terminalRows}/>
            </div>
            <div  className="terminal-list-mobile">
                <ListGroup>
                    {mobileTerminalRows}
                </ListGroup>
            </div>
            <Modal centered size='lg' show={showModal} onHide={() => setShowModal(false)}>
                <TerminalEditorModal terminals={terminals} setTerminals={setTerminals} selectedTerminal={selectedTerminal} hideModal={() => setShowModal(false)}/>
            </Modal>
        </LoadingWrapper>
    )
}