/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, { useState } from "react";
import InputGroup from 'react-bootstrap/InputGroup';
import Button from 'react-bootstrap/Button';
import { bigToUsd } from "../../../../payrollTools.js";
import { Card, Dropdown, Modal } from "react-bootstrap";
import moment from "moment";
import HRUserPto from "../../../../../HumanResources/HRUserPTO/HRUserPto.js";
import { ptoAccrualTypeDictionary, usdFormatter } from "../../../../../../../tools.js";
import CustomToolTip from "../../../../../../../components/CustomToolTip.js";
import { ApiRequest } from "../../../../../../../ApiManager.tsx";
import { toast } from "react-toastify";
import CustomButton from "../../../../../../../components/CustomButton.js";
import { useModalToggle } from "../../../../../../../hooks.tsx";
import PayrollEntry from "../../../../Models/PayrollEntry.js";
import PayrollEntryPaySection from "../PayrollEntryPaySection.js";
import PayrollDecimalControl from "../../../PayrollDecimalControl.js";
import PayrollPtoItem from "./PayrollPtoItem.js";
import QuickTable from "../../../../../../../components/QuickTable.js";

export default function PayrollEmployeePtoEditor({entry, payrollPeriod, handleSetWeek, handleSet}) {
    const [showModal, setShowModal] = useState(false);
    const [selectedEmployee, _] = useState({companyUserIdentifier: entry.companyUserIdentifier});
    const [isLoading, setIsLoading] = useState(false);

    function handleRecalculatePto() {
        new ApiRequest('payroll', 'recalculateEntry', setIsLoading, (response) => {
            const payrollEntry = new PayrollEntry(response.payrollEntry);
            for (let i = 0; i < entry.weeks.length; i++) {
                handleSetWeek(i, 'pto', payrollEntry.weeks[i].pto);
            }
            toast.success(`Successfully recalculated PTO for ${entry.firstName} ${entry.lastName}`);
        }).withNoAlertOnSuccess().withData({companyUserIdentifier: entry.companyUserIdentifier, payrollIdentifier: payrollPeriod.uid}).send()
    }

    const ptoElements = entry.weeks[entry.weekIndex].pto.map((pto) => {
        return (
            <PayrollPtoItem key={pto.ptoIdentifier} pto={pto}/>
        )
    });

    const ptoAccrualTypeButtons = Object.entries(ptoAccrualTypeDictionary).map(([key, label]) => {
        return (
            <Dropdown.Item key={key} active={entry.ptoAccrualType == key} onClick={() => {handleSet(entry.companyUserIdentifier, 'ptoAccrualType', key)}}>{label}</Dropdown.Item>
        )
    })


    return (
        <PayrollEntryPaySection title='PTO'>
            <div style={{display: 'flex', flexDirection: 'column', gap: 6}}>
                { ptoElements.length > 0 && 
                    <div style={{overflowX: 'auto'}}>
                        <QuickTable noMargin noWrap size='sm' rows={ptoElements} headers={['Date', 'Pay Rate', 'Hours', 'Wages']} widths={[1, null, null, 1]}/>
                    </div>
                }
                <div style={{display: 'flex', gap: 6, flexWrap: 'wrap'}}>
                    <div style={{flex: 1, display: 'flex', alignItems: 'center'}}>
                        { ptoElements.length == 0 && 
                            <span style={{opacity: 0.5, fontWeight: 'bold', marginLeft: 6}}>None</span>
                        }
                    </div>
                    <InputGroup style={{width: 'auto', flexWrap: 'nowrap'}}>
                        <InputGroup.Text>Accrual</InputGroup.Text>
                        <PayrollDecimalControl lhDecimalPlaces={2} rhDecimalPlaces={3} value={entry.ptoAccrual} setValue={(newValue) => {handleSet(entry.companyUserIdentifier, 'ptoAccrual', newValue)}}/>
                        <InputGroup.Text>Hrs</InputGroup.Text>
                        <Dropdown>
                            <Dropdown.Toggle variant="outline-primary">{ptoAccrualTypeDictionary[entry.ptoAccrualType]}</Dropdown.Toggle>
                            <Dropdown.Menu>
                                {ptoAccrualTypeButtons}
                            </Dropdown.Menu>
                        </Dropdown>
                    </InputGroup>
                    <Button variant="outline-primary" onClick={() => {setShowModal(true)}}>View/Edit PTO</Button>
                    <InputGroup style={{width: 'auto'}}>
                        <CustomButton isLoading={isLoading} onClick={handleRecalculatePto}>Recalculate PTO</CustomButton>
                        <InputGroup.Text>
                            <CustomToolTip text="Recalculate PTO to import the current PTO data from our system into this employee's payroll entry."/>
                        </InputGroup.Text>
                    </InputGroup>
                </div>
            </div>
            <Modal show={showModal} onHide={() => {setShowModal(false)}} size='xl'>
                <Modal.Header closeButton>
                    <Modal.Title>{`PTO for ${entry.name()}`}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <HRUserPto selectedEmployee={selectedEmployee} startDateProp={payrollPeriod.periodStart} endDateProp={payrollPeriod.periodEnd}/>
                </Modal.Body>
            </Modal>
        </PayrollEntryPaySection>
    )
}

