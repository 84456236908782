/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React from "react";
import PayrollEntryPaySection from "../PayrollEntryPaySection.js";
import { Button, Form, Modal } from "react-bootstrap";
import PayrollMoneyControl from "../../../PayrollMoneyControl.js";
import QuickTable from "../../../../../../../components/QuickTable.js";
import { bigToUsd } from "../../../../payrollTools.js";
import PayrollOvertimeRateModal from "./PayrollOvertimeRateModal.js";
import { useModalToggle } from "../../../../../../../hooks.tsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle } from "@fortawesome/free-regular-svg-icons";


export default function PayrollOvertimeEditor({entry, handleSetWeek, disabled}) {
    const [modalIsVisible, showModal, hideModal] = useModalToggle();

    const hourlyRow = (
        <tr key={1} style={{verticalAlign: 'middle'}}>
            <td>Hourly</td>
            <td>
                <PayrollMoneyControl lhDecimalPlaces={3} value={entry.weeks[entry.weekIndex].hourlyWage} setValue={(value) => {handleSetWeek(entry.weekIndex, 'hourlyWage', value)}} disabled={!!disabled}/>
            </td>
            <td>
                40
            </td>
            <td style={{color: 'green', fontWeight: 'bold'}}>
                {bigToUsd(entry.weeks[entry.weekIndex].hourlyWages())}
            </td>
        </tr>
    )

    const overtimeRow = (
        <tr key={2} style={{verticalAlign: 'middle'}}>
            <td>Overtime</td>
            <td>
                <div style={{display: 'flex', alignItems: 'center', gap: 6}}>
                    {bigToUsd(entry.weeks[entry.weekIndex].overtimeRate())}
                    <Button onClick={showModal} variant="outline-primary" style={{padding: '3px 8px'}}>

                        <FontAwesomeIcon icon={faQuestionCircle}/>
                    </Button>
                </div>
            </td>
            <td>
                {entry.weeks[entry.weekIndex].overtimeHoursWorked().toString()}
            </td>
            <td style={{color: 'green', fontWeight: 'bold'}}>
                {bigToUsd(entry.weeks[entry.weekIndex].overtimeWages())}
            </td>
        </tr>
    )


    return (
        <PayrollEntryPaySection title='OVERTIME'>
            <div style={{display: 'flex', gap: 6, alignItems: 'center', marginBottom: 6}}>
                <span style={{fontWeight: 'bold'}}>Overtime Enabled</span>
                <Form.Check type='switch' checked={entry.weeks[entry.weekIndex].overtimeEnabled} onChange={() => {handleSetWeek(entry.weekIndex, 'overtimeEnabled', !entry.weeks[entry.weekIndex].overtimeEnabled)}} disabled={!!disabled}/>
            </div>
            { entry.weeks[entry.weekIndex].qualifiesForFLSA() && 
                <QuickTable size='sm' headers={['', 'Pay Rate', 'Hours Worked', 'Wages']} rows={[hourlyRow, overtimeRow]} noWrap widths={[1, null, 1, 1]}/>
            }
            <Modal show={modalIsVisible} onHide={hideModal} fullscreen='sm-down'>
                <PayrollOvertimeRateModal entry={entry}/>
            </Modal>
        </PayrollEntryPaySection>
    )
}