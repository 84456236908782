import React, { useEffect, useState } from 'react'
import { Button } from 'react-bootstrap';
import PersonalInfo from './PersonalInfo';
import PositionInfo from './PositionInfo';
import { ApiRequest } from '../../../ApiManager.tsx';
import LoadingWrapper from '../../../components/LoadingWrapper.js';

export default function Profile() {
  const [isLoading, setIsLoading] = useState(false);  
  const [activeTab, setActiveTab] = useState('Personal Details');
  const [user, setUser] = useState(null);

    async function loadData(){
        new ApiRequest('user', 'getProfile', setIsLoading, (response) => {
            setUser(response.user);
        }).withNoAlertOnSuccess().send()
    }

    useEffect(() => {
        loadData();
    }, [])

    const tabs = {
        'Personal Details': <PersonalInfo user={user}/>,
        'Position Details': <PositionInfo user={user} pay={user?.pay}/>,
    };

    const tabButtons = Object.keys(tabs).map((key) => {
        return (
            <Button 
                key={key}
                style={activeTab=== key ? {color: 'white'} : {color: 'var(--bs-primary)', backgroundColor: 'white'}} 
                onClick={() => setActiveTab(key)}
                >
                {key}
            </Button>
        )
    });

    return (
        <div className="page-wrapper" style={{padding: 12, flexDirection: 'column'}}>
            <LoadingWrapper>
                { user && 
                    <PersonalInfo user={user}/>
                }
                { user?.pay && 
                    <PositionInfo user={user}/>
                }
            </LoadingWrapper>
        </div>
    )
}
