/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React from "react";
import Table from 'react-bootstrap/Table';
import PayrollPeriodTotalsRow from "./PayrollPeriodTotalsRow";
import PayrollPeriodEntryRow from "./PayrollPeriodEntryRow";
import PayrollPeriodHeadersRow from "./PayrollPeriodHeadersRow";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle } from "@fortawesome/free-regular-svg-icons";


export default function PayrollPeriodTable({entries, originalEntries}) {

    const tableHeaders = PayrollPeriodHeadersRow(entries);

    const tableHeaderElements = tableHeaders.map((th, index) => {
        return (
            <th key={th} style={index === 0 ? {position: 'sticky', left: 0, backgroundColor: 'white'} : {}}>{th}</th>
        )
    })

    const entryRows = entries.map((entry) => {
        const entryRows = PayrollPeriodEntryRow(entry);
        const originalEntry = originalEntries?.find(e => e.companyUserIdentifier === entry.companyUserIdentifier);
        const originalEntryRows = originalEntry ? PayrollPeriodEntryRow(originalEntry) : undefined;

        const entryRowElements = entryRows.map((entryRow, index) => {
            const originalEntryRow = originalEntryRows && originalEntryRows.length > index ? originalEntryRows[index] : undefined;
            const entryRowElement = tableHeaders.map((th, index) => {

                const colValue = entryRow[th];

                let highlight = false;
                if (originalEntryRow) {
                    const originalColValue = originalEntryRow[th];
                    if (colValue !== originalColValue) {
                        highlight = true;
                    }
                }
    
                return (
                    <td key={th} style={{backgroundColor: highlight ? 'yellow' : 'white', ...(index === 0 ? {position: 'sticky', left: 0} : {})}}>
                        
                        {th === 'Notes' ? 
                            <OverlayTrigger placement='left' overlay={
                                <Popover style={{position: 'fixed'}}>
                                    <Popover.Header>Notes</Popover.Header>
                                    <Popover.Body style={{whiteSpace: 'pre-wrap'}}>{entryRow[th]}</Popover.Body>
                                </Popover>
                            }>
                                <FontAwesomeIcon icon={faQuestionCircle} style={{color: 'var(--bs-primary)'}}/>
                            </OverlayTrigger>
                            :
                            colValue
                        }
                    
                    </td>
                )
            })

            return (
                <tr key={index} style={{fontSize: 11, whiteSpace: 'nowrap'}}>
                    {entryRowElement}
                </tr>
            )
        })

        return (
            <React.Fragment key={`${entry.companyUserIdentifier}${entry.firstName}${entry.lastName}`}>
                {entryRowElements}
            </React.Fragment>
        )

    })

    const totals = PayrollPeriodTotalsRow(entries, tableHeaders);
    const originalTotals = originalEntries ? PayrollPeriodTotalsRow(originalEntries, tableHeaders) : undefined;
    const tableTotalsElements = tableHeaders.map((th, index) => {
        const colValue = totals[th];
        let highlight = false;
        if (originalTotals) {
            const originalColValue = originalTotals[th];
            if (colValue !== originalColValue) {
                highlight = true;
            }
        }

        return <td key={`${th}${index}`} style={{fontWeight: 'bold', backgroundColor: highlight ? 'yellow' : 'white', ...(index === 0 ? {position: 'sticky', left: 0} : {})}}>{colValue}</td>
    })
    
    return (
        <div style={{overflowX: 'auto'}}>
            <Table bordered striped size="sm" style={{marginBottom: 0}}>
                <thead>
                    <tr style={{whiteSpace: 'nowrap', fontSize: 12}}>
                        {tableHeaderElements}
                    </tr>
                </thead>
                <tbody>
                    {entryRows}
                    <tr style={{fontSize: 11, whiteSpace: 'nowrap'}}>
                        {tableTotalsElements}
                    </tr>
                </tbody>
            </Table>
        </div>
    )
}