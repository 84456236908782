/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import { faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { ListGroup, Modal } from "react-bootstrap";
import moment from "moment";
import CustomButton from "../../../../components/CustomButton";
import { ApiRequest } from "../../../../ApiManager.tsx";
import { toast } from "react-toastify";
import PayrollEntry from "../Models/PayrollEntry.js";
import { getPayrollVersionType } from "../payrollTools.js";

export default function PayrollVersionList({payrollVersions, selectedVersions, setSelectedVersions, payrollPeriod, setEntries, hideEditor}) {

    const [selectedVersion, setSelectionVersion] = useState(undefined);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const handleChangePayrollVersion = () => {
        new ApiRequest('payroll', 'getVersion', setIsSubmitting, (response) => {
            setSelectedVersions([response.payrollVersion]);
            setEntries(response.payrollVersion.entries.map(e => new PayrollEntry(e)));
            hideEditor();
            toast.success('Successfully imported payroll version');
        }).withData({payrollIdentifier: payrollPeriod.uid, payrollVersionIdentifier: selectedVersion}).withNoAlertOnSuccess().send();
    }

    const ttaSubmission = payrollVersions.find(v => v.uid == payrollPeriod.ttaSubmission);

    const ttaVersionList = payrollVersions.filter((v)=> v.adminIdentifier && v.uid != payrollPeriod.ttaSubmission).map((version)=>{
        return (
            <PayrollVersionListItem key={version.uid} version={version} selectedVersion={selectedVersion} setSelectionVersion={setSelectionVersion} payrollPeriod={payrollPeriod}/>
        )
    })

    const clientSubmission = payrollVersions.find(v => v.uid == payrollPeriod.clientSubmission);

    const aoVersionList = payrollVersions.filter((v)=> v.companyUserIdentifier && !v.isBc && v.uid != payrollPeriod.clientSubmission).map((version)=>{
        return (
            <PayrollVersionListItem key={version.uid} version={version} selectedVersion={selectedVersion} setSelectionVersion={setSelectionVersion} payrollPeriod={payrollPeriod}/>
        )
    })

    const bcSubmissionItems = payrollPeriod.versions.filter(v => v.uid == payrollPeriod.bcSubmission || payrollPeriod.terminalSubmissions?.some(ts => ts.uid == v.uid)).map((version)=>{
        return (
            <PayrollVersionListItem key={version.uid} version={version} selectedVersion={selectedVersion} setSelectionVersion={setSelectionVersion} payrollPeriod={payrollPeriod}/>
        )
    })

    const bcVersionList = payrollPeriod.versions.filter(v => v.isBc && v.uid != payrollPeriod.bcSubmission && !payrollPeriod.terminalSubmissions?.some(ts => ts.uid == v.uid)).map((version)=>{
        return (
            <PayrollVersionListItem key={version.uid} version={version} selectedVersion={selectedVersion} setSelectionVersion={setSelectionVersion} payrollPeriod={payrollPeriod}/>
        )
    })

    const systemGeneratedList = payrollPeriod.versions.filter(v => !v.companyUserIdentifier && !v.adminIdentifier).map((version) => {
        return (
            <PayrollVersionListItem key={version.uid} version={version} selectedVersion={selectedVersion} setSelectionVersion={setSelectionVersion} payrollPeriod={payrollPeriod}/>
        )
    })

    const importedVersionElements = selectedVersions.map((version) => {
        return (
            <PayrollVersionListItem key={version.uid} version={version} payrollPeriod={payrollPeriod}/>
        )
    })
    
    return (
        <>
            <Modal.Header closeButton>
                <Modal.Title>{'Payroll Versions'}</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{display: 'flex', flexDirection: 'column', gap: 12}}>
                <div style={{display:'flex', width:'100%', flexDirection:'column', padding: 6, backgroundColor: 'var(--bs-primary)', borderRadius: 6}}>
                    <p style={{textAlign: 'center', fontWeight: 'bold', color: 'white'}}>{selectedVersions.length > 1 ? 'The data loaded into the payroll editor is a combination of versions from different terminals' : 'Version Loaded into Editor'}</p>
                    <ListGroup>
                        {importedVersionElements}
                    </ListGroup>
                </div>
                
                { ttaSubmission &&
                    <PayrollVersionListSection title='TTA SUBMISSION'>
                        <PayrollVersionListItem key={ttaSubmission.uid} version={ttaSubmission} selectedVersion={selectedVersion} setSelectionVersion={setSelectionVersion} payrollPeriod={payrollPeriod}/>
                    </PayrollVersionListSection>
                }
                {ttaVersionList.length > 0 &&
                    <PayrollVersionListSection title='TTA SAVES'>
                        {ttaVersionList}
                    </PayrollVersionListSection>
                }
                {clientSubmission &&
                    <PayrollVersionListSection title='COMPANY SUBMISSION'>
                        <PayrollVersionListItem key={clientSubmission.uid} version={clientSubmission} selectedVersion={selectedVersion} setSelectionVersion={setSelectionVersion} payrollPeriod={payrollPeriod}/>
                    </PayrollVersionListSection>
                }
                {aoVersionList.length > 0 && 
                    <PayrollVersionListSection title='COMPANY SAVES'>
                        {aoVersionList}
                    </PayrollVersionListSection>
                }
                {bcSubmissionItems.length > 0 && 
                    <PayrollVersionListSection title='BC SUBMISSIONS'>
                        {bcSubmissionItems}
                    </PayrollVersionListSection>
                }
                {bcVersionList.length > 0 &&
                    <PayrollVersionListSection title='BC SAVES'>
                        {bcVersionList}
                    </PayrollVersionListSection>
                }
                <PayrollVersionListSection title='SYSTEM GENERATED'>
                    {systemGeneratedList}
                </PayrollVersionListSection>
            </Modal.Body>
            <Modal.Footer>
                <div>
                    <FontAwesomeIcon icon={faTriangleExclamation} style={{color: 'red', marginRight: 6}}/>
                    <span>Any unsaved changes will be lost when you import a version</span>
                </div>
                <CustomButton disabled={!selectedVersion} onClick={handleChangePayrollVersion} isLoading={isSubmitting}>
                    Import Version
                </CustomButton>
            </Modal.Footer>
        </>
    )
}


function PayrollVersionListSection({title, children}) {

    return (
        <div>
            <p style={{fontWeight: 'bold', opacity: 0.5, fontSize: 14, marginBottom: 6}}>{title}</p>
            <ListGroup>
                {children}
            </ListGroup>
        </div>
    )
}

function PayrollVersionListItem({version, selectedVersion, setSelectionVersion, payrollPeriod}) {
    const type = getPayrollVersionType(version, payrollPeriod);

    return (
        <ListGroup.Item action={!!setSelectionVersion} active={selectedVersion == version.uid} onClick={setSelectionVersion ? () => {setSelectionVersion(version.uid)} : undefined} style={{padding: '4px 8px'}}>
            <div style={{display:'flex', justifyContent:'space-between', gap: 6}}>
                <span>
                    {
                        version.companyUserIdentifier ? `${version.user.firstName} ${version.user.middleName ? `${version.user.middleName.substring(0, 1)}. ` : ''}${version.user.lastName}` 
                        : version.adminIdentifier ? 'TTA Admin'
                        : 'TTA Linehaul'
                    }
                </span>
                <span style={{whiteSpace: 'nowrap'}}>{type}</span>
            </div>
            <div style={{display:'flex', justifyContent:'space-between', gap: 6, fontSize: 12}}>
                <span>{payrollPeriod.terminalName ?? version.terminalName ?? 'All Terminals'}</span>
                <span>{moment(version.dateTime).format('MMM D [at] h:mm:ss A')}</span>
            </div>
        </ListGroup.Item>
    )
}