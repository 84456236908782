/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, { useState } from "react";
import Card from "react-bootstrap/Card";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { faWallet, faClock, faCalendarDays, faUserInjured, faFileSignature, faTruck } from "@fortawesome/free-solid-svg-icons";
import TimesheetReport from "./TimesheetReport/TimesheetReport";
import ReportCard from "./ReportCard";
import Modal from 'react-bootstrap/Modal';
import LoginReport from "./LoginReport/LoginReport";
import AcknowledgementReport from "./AcknowledgementsReport/AcknowledgementReport";

export default function Reports({}) {
    const [selectedReport, setSelectedReport] = useState(undefined);
    const reports = [
        {
            catagory: 'hr',
            title: 'Timesheet', 
            description: 'Employee Timesheet History', 
            icon: faCalendarDays,
            element: <TimesheetReport/>,
            modalSize: 'fullscreen'
        },
        {
            catagory: 'hr',
            title: 'Company Acknowledgements', 
            description: 'Employees Acknowledged Docs', 
            icon: faFileSignature,
            element: <AcknowledgementReport/>,
            modalSize: 'fullscreen'
        },
        {
            catagory: 'hr',
            title: 'Login Report', 
            description: 'Employee Login Dates and Times', 
            icon: faClock,
            element: <LoginReport/>,
            modalSize: 'fullscreen'
        },
    ]

    for (let i = 0; i < reports.length; i++) {
        reports[i].card = (
            <ReportCard 
                key={reports[i].title} 
                title={reports[i].title} 
                description={reports[i].description} 
                icon={reports[i].icon} 
                onClick={() => {setSelectedReport(reports[i].title)}} 
            />
        )
    }

    const hrCard = (
        <Card>
            <Card.Body>
                <Card.Title>Human Resources Reports</Card.Title>
                <div style={{display: 'flex', flexDirection: 'column', gap: 12, marginTop: 12}}>
                    {reports.filter(r => r.catagory === 'hr').map(r => r.card)}
                </div>
            </Card.Body>
        </Card>
    )

    const modals = reports.map((report) => {
        return (
            <Modal key={report.title} show={selectedReport === report.title} onHide={() => {setSelectedReport(undefined)}} fullscreen={report.modalSize === 'fullscreen'} size={report.modalSize === 'fullscreen' ? undefined : report.modalSize}>
                {report.element}
            </Modal>
        )
    })
    
    return (
        <div className="page-wrapper" style={{padding: 12}}>
            <Container fluid>
                <Row>
                    <Col lg={12} style={{marginBottom: 8}}>
                        {hrCard}
                    </Col>
                </Row>
            </Container>
            {modals}
        </div>
    )
}