/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import { validateUsd } from "../payrollTools";
import { encodeDecimal, getFakeUID } from "../../../../tools";

export default class NDBonus {

    uid;
    type;
    amount;
    isReoccurring;

    constructor(uid, type, amount = 0, isReoccurring) {
        this.uid = uid;
        this.type = type;
        this.amount = amount;
        this.isReoccurring = isReoccurring;
    }

    static decode(obj) {
        return new NDBonus(obj.uid ?? getFakeUID(), obj.type, obj.amount, !!obj.isReoccurring);
    }

    encode() {
        return {
            type: this.type,
            amount: encodeDecimal(this.amount, 3, 2, true),
            isReoccurring: this.isReoccurring,
        }
    }
    
    duplicate() {
        return new NDBonus(getFakeUID(), this.type, this.amount, this.isReoccurring);
    }

    getAmount() {
        return validateUsd(this.amount);
    }
    
    static ndBonusTypes = [
        'Bonus',
        'Safety Bonus',
        'Service Bonus',
        'Travel Wage'
    ]
}