/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import { bigToUsd } from "../payrollTools";
import { toFixedMax, usdFormatter, validateDecimal, validateInteger } from "../../../../tools";
import PayrollPeriodEntryPayRow from "./PayrollPeriodEntryPayRow";
import AdditionalPay from "../Models/AdditionalPay";
import DBonus from "../Models/DBonus";
import Deduction from "../Models/Deduction";
import NDBonus from "../Models/NDBonus";
import Big from "big.js";

export default function PayrollPeriodEntryRow(entry) {
    let rows = [];

    const entryColumnInclusion = entry.getColumnInclusion();
    
    if (entryColumnInclusion.includeOvertimeColumns) { //seperate out weeks if there is overtime
        entry.weeks.forEach((week) => {
            if (week.qualifiesForFLSA()) { //list out columns for OT if week has overtime
                const weekRow = {};
                weekRow['Hourly Rate'] = bigToUsd(week.hourlyRate());
                weekRow['Adjusted Hourly Rate'] = bigToUsd(week.adjustedHourlyRate());
                weekRow['Overtime Rate'] = bigToUsd(week.overtimeRate());
                weekRow['Hours Worked'] = week.getHoursWorked().toFixed(2);
                weekRow['Straight Time Hours'] = '40';
                weekRow['Overtime Hours'] = week.overtimeHoursWorked().toFixed(2);
                weekRow['Hourly Wages'] = bigToUsd(week.hourlyWages());
                weekRow['Overtime Wages'] = bigToUsd(week.overtimeWages());
                rows.push(weekRow);
            } else { //reduce pay from week if no overttime
                const reducedPay = [];
                week.pay.filter(p => ['ph', 'pd', 'py', 'pm', 'xd'].includes(p.payType)).forEach((pay) => {
                    const match = reducedPay.find(p => p.payType === pay.payType && p.payRate === pay.payRate);
                    if (match) {
                        match.unitsWorked = validateDecimal(match.unitsWorked) + validateDecimal(pay.unitsWorked);
                    } else {
                        reducedPay.push(pay.duplicate());
                    }
                })
                rows = rows.concat(reducedPay.map(p => PayrollPeriodEntryPayRow(p, week.qualifiesForFLSA())));
            }
        })
    } else { //reduce pay from all weeks combined
        const reducedPay = [];
        entry.weeks.forEach((week) => {
            week.pay.filter(p => ['ph', 'pd', 'py', 'pm', 'xd'].includes(p.payType)).forEach((pay) => {
                const match = reducedPay.find(p => p.payType === pay.payType && p.payRate === pay.payRate);
                if (match) {
                    match.unitsWorked = validateDecimal(match.unitsWorked) + validateDecimal(pay.unitsWorked);
                } else {
                    reducedPay.push(pay.duplicate());
                }
            })
        });
        rows = reducedPay.map(p => PayrollPeriodEntryPayRow(p, false));
    }

    const firstRow = rows[0];

    if (!firstRow.hasOwnProperty('Days Worked')) {
        firstRow['Days Worked'] = entry.weeks.reduce((prev, curr) => {
            return prev + validateInteger(curr.daysWorked);
        }, 0);
    }
    if (!firstRow.hasOwnProperty('Hours Worked')) {
        firstRow['Hours Worked'] = toFixedMax(entry.weeks.reduce((prev, curr) => {
            return prev + validateDecimal(curr.hoursWorked);
        }, 0.0), 3);
    }

    firstRow['Name'] = `${entry.name()}${!entry.previousEntry ? ` (NEW)` : ''}`;
    firstRow['Terminal'] = entry.terminalName;
    firstRow['BWC Code'] = entry.bwcCode;
    if (entryColumnInclusion.includePto) {
        firstRow['PTO Hours'] = entry.totalPtoHours();
    }
    if (entryColumnInclusion.includePto) {
        firstRow['PTO Wages'] = bigToUsd(entry.totalPtoWages());
    }
    if (entryColumnInclusion.includeHolidayWages) {
        firstRow['Holiday Wages'] = bigToUsd(entry.totalHolidayWages());
    }
    if (entryColumnInclusion.includeIncentiveWages) {
        firstRow['Incentive Wages'] = bigToUsd(entry.totalIncentiveWages());
    }
    if (entryColumnInclusion.includeStandByWages) {
        firstRow['Stand-By Wages'] = bigToUsd(entry.totalStandByWages());
    }
    if (entryColumnInclusion.includeAutoOvertime) {
        firstRow['Extra Day Pay'] = bigToUsd(entry.totalAutoOvertimeWages());
    }
    if (entryColumnInclusion.includeNighttimeDif) {
        firstRow['Night Differential'] = bigToUsd(entry.nighttimeDiffWages());
    }
    if (entryColumnInclusion.includeWeekendDif) {
        firstRow['Weekend Differential'] = bigToUsd(entry.weekendDiffWages());
    }


    DBonus.dBonusTypes.forEach((type) => {
        const total = entry.weeks.reduce((prev, curr) => {
            const totalForWeek = curr.dBonuses.filter(d => d.type == type).reduce((prevB, currB) => {
                return prevB.plus(new Big(currB.getAmount()))
            }, new Big('0.0'))
            return prev.plus(totalForWeek);
        }, new Big('0.0'))
        
        if (total > 0) {
            firstRow[type] = bigToUsd(total);
        }
    })

    NDBonus.ndBonusTypes.forEach((type) => {
        const total = entry.weeks.reduce((prev, curr) => {
            const totalForWeek = curr.ndBonuses.filter(d => d.type == type).reduce((prevB, currB) => {
                return prevB.plus(new Big(currB.getAmount()))
            }, new Big('0.0'))
            return prev.plus(totalForWeek);
        }, new Big('0.0'))
        
        if (total > 0) {
            firstRow[type] = bigToUsd(total);
        }
    })

    AdditionalPay.additionalPayTypes.forEach((apType) => {
        entry.getAllAdditionalPay().filter(ap => ap.type === apType).forEach((ap, index) => {
            firstRow[`${apType} #${index + 1}`] = usdFormatter.format(ap.getAmount());
        });
    })

    firstRow['Gross'] = bigToUsd(entry.gross());

    const filteredLoans = entry.getEnabledLoans().filter(t => !t.isTicket && t.getAmount(entry) > 0);
    for (let i = 0; i < filteredLoans.length; i++) {
        firstRow[`Loan #${i + 1}`] = usdFormatter.format(filteredLoans[i].getAmount());
    }

    const filteredTickets = entry.getEnabledLoans().filter(t => t.isTicket && t.getAmount(entry) > 0);
    for (let i = 0; i < filteredTickets.length; i++) {
        firstRow[`Fine Ticket Damage #${i + 1}`] = usdFormatter.format(filteredTickets[i].getAmount());
    }

    if (entryColumnInclusion.inlcudeChildSupport) {
        firstRow['Child Support'] = usdFormatter.format(entry.totalChildSupport())
    };
    if (entryColumnInclusion.includeMedical) {
        firstRow['Medical Insurance'] = usdFormatter.format(validateDecimal(entry.medical))
    };
    if (entryColumnInclusion.includeDental) {
        firstRow['Dental Insurance'] = usdFormatter.format(validateDecimal(entry.dental))
    };
    if (entryColumnInclusion.includeVision) {
        firstRow['Vision Insurance'] = usdFormatter.format(validateDecimal(entry.vision))
    };

    Deduction.deductionTypes.forEach((type) => {
        const total = entry.deductions.filter(d => d.type === type).reduce((prev, curr) => {
            return prev + curr.getAmount();
        }, 0.0);
        if (total > 0) {
            firstRow[type] = type === '401K (% of Gross)' || type === 'Other (% of Gross)' ? `${total}%` : usdFormatter.format(total);
        }
    })

    firstRow['Notes'] = getNotesWithLoansAndTickets(entry);
    
    return rows;
}

function getNotesWithLoansAndTickets(entry) {
    let str = '';
    if (entry.getEnabledLoans().length > 0) {
        str += 'Loans: ';
        entry.getEnabledLoans().forEach((l, index) => {
            if (index > 0) {
                str += ', ';
            }
            str += `${l.name} - ${usdFormatter.format(l.getAmount())}`
        });
        str += '\n';
    }
    // if (entry.getEnabledTickets().length > 0) {
    //     str += 'Fine Ticket Damage: ';
    //     entry.getEnabledTickets().forEach((t, index) => {
    //         if (index > 0) {
    //             str += ', ';
    //         }
    //         str += `${t.name} - ${usdFormatter.format(t.getAmountToPay(entry))} ${t.deductFromSafetyBonuses ? `(deducted from safety bonuses)` : ''}`
    //     });
    //     str += '\n';
    // }

    return str + entry.notes;
}