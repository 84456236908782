/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React from "react";
import Button from 'react-bootstrap/Button';
import { ButtonGroup } from "react-bootstrap";
import { toFixedMax } from "../../../../../../tools.js";
import PayrollEmployeeWagesEditor from "./Wages/PayrollEmployeeWagesEditor.js";
import PayrollEmployeeNondiscretionaryBonuses from "./NDBonuses/PayrollEmployeeNondiscretionaryBonuses.js";
import PayrollEmployeeAdditionalPayEditor from "./AdditionalPay/PayrollEmployeeAdditionalPayEditor.js";
import PayrollEmployeeHolidaysEditor from "./Holidays/PayrollEmployeeHolidaysEditor.js";
import PayrollEmployeePtoEditor from "./Pto/PayrollEmployeePtoEditor.js";
import PayrollEmployeeDiscretionaryBonuses from "./DBonuses/PayrollEmployeeDiscretionaryBonuses.js";
import { bigToUsd } from "../../../payrollTools.js";
import PayrollOvertimeEditor from "./Overtime/PayrollOvertimeEditor.js";
import moment from "moment";

export default function PayrollEmployeePayEditor({entry, handleSet, handleSetWeek, handleAddItemToAllEntries, payrollPeriod }) {

    const weekTabButtons = entry.weeks.map((_, i) => {
        const buttonStyle = entry.weekIndex == i ? {paddingLeft: 0, paddingRight: 0, backgroundColor: 'var(--bs-primary)', color: 'white'} : {paddingLeft: 0, paddingRight: 0};
        return (
            <Button variant="outline-primary" key={i} onClick={() => {handleSet(entry.companyUserIdentifier, 'weekIndex', i)}} style={buttonStyle}>
                {`Week ${i + 1} | ${moment(entry.periodStart).add(i, 'weeks').format('ddd D')} - ${moment(entry.periodStart).add(i, 'weeks').add(6, 'days').format('ddd D')}`}
            </Button>
        )
    })

    return (
        <div style={{padding: 6, display: 'flex', flexDirection: 'column', gap: 6, backgroundColor: 'rgb(250, 255, 250)'}}>
            <div style={{display: 'flex', gap: 12, alignItems: 'center'}}>
                <span style={{fontSize: 25, fontWeight: 'bold'}}>Pay</span>
                { entry.weeks.length > 1 && 
                    <ButtonGroup style={{flex: 1}}>{weekTabButtons}</ButtonGroup>
                }
            </div>
            <div style={{display: 'flex', justifyContent: 'space-evenly', fontWeight: 'bold', flexWrap: 'wrap', backgroundColor: 'lightgray', borderRadius: 6}}>
                <span>{`Days Worked: ${entry.weeks[entry.weekIndex].daysWorked}`}</span>
                <span>{`Hours Worked: ${toFixedMax(entry.weeks[entry.weekIndex].hoursWorked, 3)}`}</span>
            </div>
            <PayrollEmployeeWagesEditor entry={entry} handleSetWeek={handleSetWeek} payrollPeriod={payrollPeriod}/>
            <PayrollEmployeeNondiscretionaryBonuses entry={entry} handleSetWeek={handleSetWeek} handleAddItemToAllEntries={handleAddItemToAllEntries}/>
            <PayrollEmployeeAdditionalPayEditor entry={entry} handleSetWeek={handleSetWeek} handleAddItemToAllEntries={handleAddItemToAllEntries}/>
            <PayrollEmployeeHolidaysEditor entry={entry} handleSetWeek={handleSetWeek}/>
            <PayrollEmployeeDiscretionaryBonuses entry={entry} handleSetWeek={handleSetWeek} handleAddItemToAllEntries={handleAddItemToAllEntries}/>
            <PayrollEmployeePtoEditor entry={entry} payrollPeriod={payrollPeriod} handleSetWeek={handleSetWeek} handleSet={handleSet}/>
            <PayrollOvertimeEditor entry={entry} handleSetWeek={handleSetWeek}/>
            
            <div style={{display: 'flex', justifyContent: 'center', gap: 6, fontWeight: 'bold', fontSize: 18}}>
                <span>{`Sub Gross for Week ${entry.weekIndex + 1}:`}</span>
                <span style={{color: 'green', }}>{bigToUsd(entry.weeks[entry.weekIndex].getSubGross())}</span>
            </div>
        </div>
    )
}