/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import StateObject from "../../../state/StateObject.tsx";

export default class Incident extends StateObject {
    didOccur = null
    type;
    statement = "";

    constructor(type) {
        super();
        this.type = type;
    }

    encode() {
        return {
            type: this.type,
            statement: this.statement
        }
    }

    getPluralLabel() {
        return this.type == 'Incident' ? 'incidents' : this.type == 'Accident' ? 'accidents' : 'injuries';
    }

}